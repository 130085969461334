import { negate } from 'lodash/fp'

// Find and pull out a matching item from an array
// - return the matching item and all non-matching items

function yankBy(array, iteratee) {
  const matchingItem = array.find(iteratee)
  const rest = array.filter(negate(iteratee))
  return [matchingItem, rest]
}

export default yankBy
