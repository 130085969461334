import React from 'react'
// import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { formatDate } from 'utils'
import { ExternalLink } from 'components'
import { DownloadIcon } from 'components/icons/basic'

const propTypes = {
  attachment: Types.attachment.isRequired,
}

const defaultProps = {}

function ResourcesSidebarAttachment({ attachment }) {
  return (
    <div className="flex-vertical flex-start">
      <p>
        <strong>{attachment.name}</strong>
      </p>
      <span>
        <em>{`${formatDate(attachment.createdAt)} - ${
          attachment.userName
        }`}</em>
      </span>
      <ExternalLink
        className="link-primary no-underline"
        href={attachment.uploadUrl}
        aria-label={`Download ${attachment.name}`}
      >
        <span aria-hidden className="attachment-download-icon">
          <DownloadIcon />
        </span>
        <span className="underline-hover">
          <strong>Download</strong>
        </span>
      </ExternalLink>
    </div>
  )
}

ResourcesSidebarAttachment.propTypes = exact(propTypes)
ResourcesSidebarAttachment.defaultProps = defaultProps

export default pure(ResourcesSidebarAttachment)
