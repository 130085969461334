import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { lpForm } from 'lp-form'
import { modifyProps } from 'lp-hoc'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { SubmitButton } from 'lp-components'
import { CheckboxGroup, AutoSaveStatusIndicator } from 'components'
import * as Types from 'types'
import {
  replaceResources,
  formatObjectsToIds,
  parseIdsToObjects,
  persistSubmitSucceeded,
  serializeOptions,
} from 'utils'

const propTypes = {
  ...formPropTypes,
  resourceTypes: PropTypes.arrayOf(Types.resourceType).isRequired,
}

function ArtsProgrammingResourceForm({
  handleSubmit,
  resourceTypes,
  saved,
  submitting,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <AutoSaveStatusIndicator {...{ saveSucceeded: saved }} />
      <div className="floating-checkboxes">
        <Field
          name="requestedResources"
          label={false}
          component={CheckboxGroup}
          format={formatObjectsToIds('resourceTypeId')}
          parse={parseIdsToObjects('resourceTypeId')}
          options={serializeOptions(resourceTypes)}
        />
        <div className="button-wrapper">
          <SubmitButton {...{ pristine: saved, submitting }}>
            Save Response
          </SubmitButton>
        </div>
      </div>
    </form>
  )
}

ArtsProgrammingResourceForm.propTypes = propTypes

function modifyBeforeSubmit({ initialValues }) {
  return {
    beforeSubmit: ({ requestedResources, type }) => {
      const resources = replaceResources({
        old: initialValues.requestedResources,
        new: requestedResources,
      })
      return { resources: resources, type }
    },
  }
}

function modifyInitialValues({ initialValues }) {
  return {
    initialValues: {
      ...initialValues,
      type: Types.REQUEST_FORM_TYPE,
    },
  }
}

export default compose(
  modifyProps(modifyInitialValues),
  modifyProps(modifyBeforeSubmit),
  lpForm({
    name: 'school-arts-resource',
    enableReinitialize: true,
  }),
  persistSubmitSucceeded()
)(ArtsProgrammingResourceForm)
