import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import {
  Field,
  propTypes as formPropTypes,
  formValueSelector,
} from 'redux-form'
import { CheckboxWithText, HiddenLegend, CheckboxFieldset } from 'components'
import {
  SubmitButton,
  CheckboxGroup,
  InputError,
  ButtonArea,
} from 'lp-components'
import * as Types from 'types'
import { first, flow } from 'lodash'
import {
  getNoneTypeId,
  isOtherType,
  isNoneType,
  yankBy,
  parseIdsToObjects,
  formatObjectsToIds,
  persistSubmitSucceeded,
  orderLastBy,
  serializeOptions,
} from 'utils'

const propTypes = {
  ...formPropTypes,
  approachTypes: PropTypes.arrayOf(Types.approach).isRequired,
  approaches: PropTypes.arrayOf(Types.schoolApproach),
}

const defaultProps = {
  approaches: undefined,
}

const FORM_NAME = 'school-arts-approaches'

function ArtsApproachesForm({
  handleSubmit,
  approachTypes,
  submitting,
  saved,
  change,
  approaches,
  error,
  submitFailed,
}) {
  const [otherApproachType, definedApproachTypes] = useMemo(
    () => yankBy(approachTypes, isOtherType),
    [approachTypes]
  )
  const noneTypeId = useMemo(
    () => getNoneTypeId(approachTypes),
    [approachTypes]
  )
  const options = useMemo(
    () =>
      flow([orderLastBy(isNoneType), serializeOptions])(definedApproachTypes),
    [definedApproachTypes]
  )

  const filterValues = useCallback(
    (values) => {
      // Remove other values if None type was just selected
      if (first(values) === noneTypeId) {
        change('other.otherText', '')
        return [noneTypeId]
      }
      // Otherwise remove None type
      return values.filter((val) => val !== noneTypeId)
    },
    [noneTypeId, change]
  )
  const deselectNone = useCallback(() => {
    const rest = approaches.filter(
      ({ approachId }) => approachId !== noneTypeId
    )
    change('approaches', rest)
  }, [change, noneTypeId, approaches])
  return (
    <form onSubmit={handleSubmit} noValidate>
      <CheckboxFieldset invalid={!!error && submitFailed}>
        <Field
          name="approaches"
          labelComponent={HiddenLegend}
          component={CheckboxGroup}
          format={formatObjectsToIds('approachId')}
          parse={(values) =>
            parseIdsToObjects('approachId', filterValues(values))
          }
          options={options}
        />
        {otherApproachType && (
          <Field
            name="other.otherText"
            label={otherApproachType.displayName}
            placeholder="Description..."
            component={CheckboxWithText}
            className="CheckboxGroup full-width-other"
            onChange={deselectNone}
          />
        )}
        {error && submitFailed && <InputError error={error} touched invalid />}
      </CheckboxFieldset>
      <ButtonArea>
        <SubmitButton {...{ pristine: saved, submitting }}>
          Save Response
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

ArtsApproachesForm.propTypes = propTypes
ArtsApproachesForm.defaultProps = defaultProps

const selectFormValue = formValueSelector(FORM_NAME)

function mapStateToProps(state) {
  return {
    approaches: selectFormValue(state, 'approaches'),
  }
}

export default compose(
  connect(mapStateToProps),
  lpForm({
    name: FORM_NAME,
    enableReinitialize: true,
    constraints: {
      'other.otherText': {
        exclusion: {
          within: [' '],
          message: '^Description is required',
        },
      },
    },
  }),
  persistSubmitSucceeded()
)(ArtsApproachesForm)
