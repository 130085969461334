import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import * as Views from './views'
import Layout from './layout'

const Routes = (
  <Route path="profile" component={Layout}>
    <IndexRedirect to="organization" />
    <Route path="organization" component={Views.Organization} />
    <Route path="programs" component={Views.Programs} />
    <Route path="partnerships" component={Views.Partnerships} />
    <Route path="contacts" component={Views.Contacts} />
  </Route>
)

export default Routes
