import React from 'react'
import { Route, IndexRoute } from 'react-router'
import * as Views from './views'
import * as EducatorViews from '../views'
import Layout from './layout'
import CommunityOfPracticeLayout from './community-of-practice-layout'
import ResourcesLayout from './resources-layout'

const Routes = (
  <Route path="communities-of-practice" component={Layout}>
    <IndexRoute component={Views.CommunitiesOfPractice} />
    <Route path=":id" component={CommunityOfPracticeLayout}>
      <IndexRoute component={Views.Workspace} />
      <Route path="discussions/:postId" component={EducatorViews.Post} />
      <Route path="resources" component={ResourcesLayout}>
        <IndexRoute component={Views.ResourcesIndex} />
        <Route path=":folderId" component={Views.ResourcesFolder} />
      </Route>
    </Route>
  </Route>
)

export default Routes
