import React from 'react'

function InSchoolIcon() {
  return (
    <svg width="50px" height="50px" viewBox="0 0 50 50">
      <g id="Map" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="in-school-performance">
          <path
            d="M50,25 C50,38.8071187 38.8071187,50 25,50 C11.1928813,50 0,38.8071187 0,25 C0,11.1928813 11.1928813,0 25,0 C31.6304122,0 37.9892601,2.63392101 42.6776695,7.32233047 C47.366079,12.0107399 50,18.3695878 50,25 Z"
            fill="#204B81"
            className="fill"
            fillRule="nonzero"
          ></path>
          <polygon
            fill="#FFFFFF"
            fillRule="nonzero"
            points="29.8 15.06 29.8 12.02 31.5 12.01 31.47 11.35 24.8 5.54 18.12 11.35 18.12 12.01 19.79 12.02 19.79 15.05 24.79 11.29"
          ></polygon>
          <path
            d="M22.85,25.92 C22.13,25.92 21.73,27.01 21.73,27.01 L24,27.01 C24,27.01 23.56,25.92 22.85,25.92 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
          <path
            d="M19.06,30.24 C19.06,30.24 19.79,31.85 21.06,31.85 C22.33,31.85 23.06,30.25 23.06,30.25 C22.4159244,30.4768554 21.7421206,30.6082472 21.06,30.64 C20.3779667,30.600393 19.7048026,30.4657602 19.06,30.24 L19.06,30.24 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
          <path
            d="M19.26,25.92 C18.55,25.92 18.15,27.01 18.15,27.01 L20.38,27.01 C20.38,27.01 20,25.92 19.26,25.92 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
          <path
            d="M29.86,32 C30.58,32 30.98,30.89 30.98,30.89 L28.75,30.89 C28.75,30.89 29.15,32 29.86,32 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
          <path
            d="M28.11,34.65 C26.81,34.65 26.11,36.26 26.11,36.26 C26.7532103,36.0284699 27.4272174,35.8936684 28.11,35.86 C28.8122806,35.8939809 29.5060356,36.0286906 30.17,36.26 C30.17,36.26 29.41,34.65 28.11,34.65 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
          <path d="M25,50 C31.9480541,49.9988583 38.5806722,47.0993531 43.3,42 L6.7,42 C11.4193278,47.0993531 18.0519459,49.9988583 25,50 Z"></path>
          <path d="M12.67,25.42 L9.88,25.42 L9.88,24.34 L24.77,13.14 L39.68,24.33 L39.68,25.33 L36.76,25.33 L36.76,39.08 L45.66,39.08 C51.7061714,30.2064385 51.4107353,18.4630857 44.9260175,9.90477291 C38.4412998,1.34646014 27.2158267,-2.11509617 17.0374127,1.30487024 C6.85899869,4.72483665 0.0012509239,14.2623894 -3.71820699e-05,25 C-0.00868526566,30.0239609 1.50439595,34.932759 4.34,39.08 L12.67,39.08 L12.67,25.42 Z"></path>
          <path d="M25.64,23.27 L16.55,23.27 C16.55,23.27 15.74,33.89 21.05,33.89 C26.36,33.89 25.64,23.27 25.64,23.27 Z"></path>
          <path d="M26.9,28.19 C26.7718049,29.0931451 26.5643371,29.9832489 26.28,30.85 L27.39,30.85 C27.39,30.85 27,32 26.28,32 C26.1444751,31.9970957 26.012614,31.9554554 25.9,31.88 C25.5950444,32.6125646 25.1691093,33.2886522 24.64,33.88 C24.4504416,34.0847771 24.2429484,34.2721904 24.02,34.44 C24.57,36.78 25.72,38.9 28.09,38.9 C33.4,38.9 32.68,28.27 32.68,28.27 L26.9,28.19 Z"></path>
          <path
            d="M36.76,39.08 L36.76,25.35 L39.68,25.35 L39.68,24.35 L24.79,13.14 L9.9,24.34 L9.9,25.42 L12.69,25.42 L12.69,39.08 L4.34,39.08 C5.05484967,40.1092501 5.84359614,41.0851567 6.7,42 L43.3,42 C44.1595227,41.0595255 44.9483392,40.0567926 45.66,39 L36.76,39.08 Z M21.05,33.89 C15.74,33.89 16.55,23.27 16.55,23.27 L25.64,23.27 C25.64,23.27 26.36,33.89 21.05,33.89 Z M28.05,38.82 C25.68,38.82 24.53,36.7 23.98,34.36 C24.2029484,34.1921904 24.4104416,34.0047771 24.6,33.8 C25.1291093,33.2086522 25.5550444,32.5325646 25.86,31.8 C25.972614,31.8754554 26.1044751,31.9170957 26.24,31.92 C26.95,31.92 27.35,30.81 27.35,30.81 L26.28,30.81 C26.5643371,29.9432489 26.7718049,29.0531451 26.9,28.15 L32.68,28.15 C32.68,28.15 33.4,38.82 28.09,38.82 L28.05,38.82 Z"
            id="Shape"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default InSchoolIcon
