import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import circleCheckImage from 'images/circle-checkmark.svg'
import { HiddenLabel } from 'components'
import { kebabCase } from 'lodash'

const propTypes = {
  subDisciplinesOffered: PropTypes.arrayOf(Types.schoolSubDiscipline),
  option: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }),
}

const defaultProps = {}

const { IN_SCHOOL, OUT_OF_SCHOOL, BOTH } = Types.SUB_DISCIPLINE_OFFER_TYPES

const subDisciplineTags = (tag) => [BOTH].concat([tag])

function SubDisciplineTypeRow({ option, subDisciplinesOffered }) {
  const inSchool = subDisciplinesOffered.some((discipline) => {
    return subDisciplineTags(IN_SCHOOL).indexOf(discipline.tag) > -1
  })

  const outOfSchool = subDisciplinesOffered.some((discipline) => {
    return subDisciplineTags(OUT_OF_SCHOOL).indexOf(discipline.tag) > -1
  })

  function getHiddenLabelValue() {
    const subDisciplineText = `${option.key}`

    if (!inSchool && !outOfSchool) return `${subDisciplineText} - not offered`

    const offeringText = getOfferingText()
    return `${subDisciplineText} - offered ${offeringText}`
  }

  function getOfferingText() {
    const inSchoolText = kebabCase(IN_SCHOOL)
    const outOfSchoolText = kebabCase(OUT_OF_SCHOOL)

    if (inSchool && outOfSchool) return `${inSchoolText} and ${outOfSchoolText}`
    return inSchool ? inSchoolText : outOfSchoolText
  }

  return (
    <li>
      <HiddenLabel name={option.key} label={getHiddenLabelValue()} />
      <span aria-hidden>
        <p>{option.key}</p>
        <div>{inSchool && <img src={circleCheckImage} alt="" />}</div>
        <div>{outOfSchool && <img src={circleCheckImage} alt="" />}</div>
      </span>
    </li>
  )
}

SubDisciplineTypeRow.propTypes = propTypes
SubDisciplineTypeRow.defaultProps = defaultProps

export default SubDisciplineTypeRow
