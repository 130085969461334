import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as flashActions from 'redux-flash'
import { Spinner, ButtonArea } from 'lp-components'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { selectors as globalSelectors } from 'educator-portal-reducer'
import { ResourcesTable, ResourcesAttachmentModal } from '../components'
import { Modal } from 'components'
import { FolderForm } from '../forms'
import {
  createCopPaths,
  hasLoadedAssociationForCommunityOfPractice,
  displaySubmitFailure,
} from 'utils'

const propTypes = {
  communityOfPractice: Types.communityOfPractice.isRequired,
  fetchAttachments: PropTypes.func.isRequired,
  attachments: PropTypes.arrayOf(Types.attachment),
  fetchFolders: PropTypes.func.isRequired,
  folders: PropTypes.arrayOf(Types.indexFolder),
  isModerator: PropTypes.bool.isRequired,
  fetchAttachableOptions: PropTypes.func.isRequired,
  fetchFolderableOptions: PropTypes.func.isRequired,
  attachableOptions: PropTypes.arrayOf(Types.attachableOption),
  folderableOptions: PropTypes.arrayOf(Types.folderableOption),
  createOrUpdateAttachment: PropTypes.func.isRequired,
  createFolder: PropTypes.func.isRequired,
  deleteFolder: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
}

const defaultProps = {
  attachments: null,
  folders: null,
  attachableOptions: null,
  folderableOptions: null,
}

function ResourcesIndex({
  communityOfPractice,
  fetchAttachments,
  attachments,
  fetchFolders,
  folders,
  isModerator,
  fetchAttachableOptions,
  attachableOptions,
  fetchFolderableOptions,
  folderableOptions,
  createOrUpdateAttachment,
  createFolder,
  deleteFolder,
  deleteAttachment,
  flashSuccessMessage,
  flashErrorMessage,
}) {
  const communityOfPracticeId = parseInt(communityOfPractice.id)
  const { copResourcesIndexPath } = createCopPaths(communityOfPracticeId)
  const [showAttachmentModal, setShowAttachmentModal] = useState(false)
  const [showFolderModal, setShowFolderModal] = useState(false)
  // Make sure to clear `attachmentToEdit` when uploading a _new_ attachment
  const [attachmentToEdit, setAttachmentToEdit] = useState()

  const closeFolderModal = () => setShowFolderModal(false)

  const editAttachment = (attachment) => {
    setAttachmentToEdit(attachment)
    setShowAttachmentModal(true)
  }

  useEffect(() => {
    fetchAttachments({ communityOfPracticeId })
    fetchFolders({ communityOfPracticeId })
  }, [communityOfPracticeId])

  useEffect(() => {
    fetchAttachableOptions(communityOfPracticeId)
    fetchFolderableOptions(communityOfPracticeId)
  }, [communityOfPracticeId])

  const fetchFoldersAndOptions = () => {
    fetchFolders({ communityOfPracticeId })
    fetchAttachableOptions(communityOfPracticeId)
    fetchFolderableOptions(communityOfPracticeId)
  }

  const requestDeleteFolder = (folderId) => {
    deleteFolder(folderId)
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
        fetchFoldersAndOptions()
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }

  const requestDeleteAttachment = (attachmentId) => {
    deleteAttachment(attachmentId)
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
        fetchAttachments({ communityOfPracticeId })
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }

  const hasCorrectAttachments = hasLoadedAssociationForCommunityOfPractice(
    attachments,
    communityOfPracticeId,
    { collectionKey: 'attachableId' }
  )
  const hasCorrectFolders = hasLoadedAssociationForCommunityOfPractice(
    folders,
    communityOfPracticeId,
    { collectionKey: 'folderableId' }
  )

  const hasCorrectAttachableOptions =
    hasLoadedAssociationForCommunityOfPractice(
      attachableOptions,
      communityOfPracticeId
    )

  const hasCorrectFolderableOptions =
    hasLoadedAssociationForCommunityOfPractice(
      folderableOptions,
      communityOfPracticeId
    )

  const onSelectUploadResource = () => {
    setAttachmentToEdit(null)
    setShowAttachmentModal(true)
  }

  if (
    !hasCorrectAttachments ||
    !hasCorrectFolders ||
    !hasCorrectAttachableOptions ||
    !hasCorrectFolderableOptions
  )
    return <Spinner />

  return (
    <React.Fragment>
      <header className="cop-header">
        <h2>
          <strong>Resource Library</strong>
        </h2>
      </header>
      <section className="card">
        <ResourcesTable
          folders={folders}
          attachments={attachments}
          canModify={isModerator}
          editAttachment={editAttachment}
          deleteAttachment={requestDeleteAttachment}
          deleteFolder={requestDeleteFolder}
          copResourcesIndexPath={copResourcesIndexPath}
        />
        {isModerator && (
          <ButtonArea>
            <button
              className="link-secondary no-underline underline-hover"
              onClick={() => setShowFolderModal(true)}
            >
              +Add Another Folder
            </button>
            <button
              className="link-secondary no-underline underline-hover"
              onClick={onSelectUploadResource}
            >
              +Upload Resource
            </button>
          </ButtonArea>
        )}
      </section>
      {showAttachmentModal && (
        <ResourcesAttachmentModal
          onClose={() => {
            setShowAttachmentModal(false)
          }}
          createOrUpdateAttachment={createOrUpdateAttachment}
          onSubmitSuccess={() => {
            setShowAttachmentModal(false)
            flashSuccessMessage('New resource created!')
            fetchAttachments({ communityOfPracticeId })
          }}
          flashSuccessMessage={flashSuccessMessage}
          flashErrorMessage={flashErrorMessage}
          attachableOptions={attachableOptions}
          attachment={attachmentToEdit}
          initialAttachable={`${Types.FOLDERABLE_TYPE.COMMUNITY_OF_PRACTICE}_${communityOfPracticeId}`}
        />
      )}
      {showFolderModal && (
        <Modal onClose={closeFolderModal}>
          <h2>Add New Folder</h2>
          <FolderForm
            onSubmit={createFolder}
            onCancel={closeFolderModal}
            folderableOptions={folderableOptions}
            onSubmitFail={displaySubmitFailure}
            onSubmitSuccess={() => {
              setShowFolderModal(false)
              flashSuccessMessage('New folder created!')
              fetchFoldersAndOptions()
            }}
          />
        </Modal>
      )}
    </React.Fragment>
  )
}

ResourcesIndex.propTypes = propTypes
ResourcesIndex.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    communityOfPractice: selectors.communityOfPractice(state),
    attachments: selectors.attachments(state),
    folders: selectors.folders(state),
    isModerator: globalSelectors.isModerator(state),
    attachableOptions: selectors.attachableOptions(state),
    folderableOptions: selectors.folderableOptions(state),
  }
}

const mapDispatchToProps = {
  fetchAttachments: apiActions.fetchAttachments,
  fetchFolders: apiActions.fetchFolders,
  fetchAttachableOptions: apiActions.fetchAttachableOptions,
  fetchFolderableOptions: apiActions.fetchFolderableOptions,
  createOrUpdateAttachment: apiActions.createOrUpdateAttachment,
  createFolder: apiActions.createFolder,
  deleteFolder: apiActions.deleteFolder,
  deleteAttachment: apiActions.deleteAttachment,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  flashErrorMessage: flashActions.flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ResourcesIndex
)
