import { handleActions } from 'redux-actions'
import { handleResponse } from 'lp-redux-api'
import { selectorForSlice } from 'lp-redux-utils'
import { createSelector } from 'reselect'
import * as Types from 'types'
import * as apiActions from 'api-actions'
import { compose } from 'recompose'
import { set } from 'lodash/fp'

const reducerKey = 'search'
const slice = 'root.map.search'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchSearchResults]: handleResponse(
      (state, action) => {
        return set('searchResult', action.payload.data, state)
      },
      (state, action) => {
        return set(
          'searchResult',
          { results: [], count: 0, error: action.payload.data },
          state
        )
      }
    ),
    [apiActions.fetchSearchOptions]: (state, { payload: { data } }) => {
      if (!data || data.type !== Types.SEARCH_OPTIONS_TYPE.MAP) return state
      return compose(set('searchOptions', data.searchOptions))(state)
    },
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  searchResult: select('searchResult'),
  searchResults: select('searchResult.results', []),
  searchOptions: select('searchOptions', {}),
}

// Computed

selectors.totalSearchResults = createSelector(
  [selectors.searchResult],
  function (searchResult) {
    if (!searchResult) return 0
    return searchResult.count
  }
)

export { reducer, selectors, reducerKey }
