import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { ProgramForm } from '../forms'
import * as Types from 'types'
import { yankBy, isOtherType, getOtherTypeId } from 'utils'
import { get } from 'lodash'
import { ExpandableItem } from 'components'

const propTypes = {
  program: Types.program,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  setShowInfoModalWithContent: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  financialAssistanceTypes: PropTypes.arrayOf(Types.communityEnumerable)
    .isRequired,
  identityFrequencies: PropTypes.arrayOf(Types.communityEnumerable),
  approachTypes: PropTypes.arrayOf(Types.communityEnumerable).isRequired,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  isNewProgram: PropTypes.bool,
  grades: PropTypes.arrayOf(Types.grade).isRequired,
  programTypes: PropTypes.arrayOf(Types.programType).isRequired,
  outcomeTypes: PropTypes.arrayOf(Types.outcomeType).isRequired,
}

const defaultProps = {
  disabled: false,
  isNewProgram: false,
}

function ProgramBlade({
  program,
  onSubmit,
  onSubmitSuccess,
  onDelete,
  setShowInfoModalWithContent,
  disabled,
  grades,
  programTypes,
  outcomeTypes,
  financialAssistanceTypes,
  identityFrequencies,
  approachTypes,
  disciplines,
  isNewProgram,
}) {
  const [expandForm, setExpandForm] = useState(isNewProgram)
  const initialValues = program ? program : {}
  const [otherFinancialAssistanceType, definedFinancialAssistanceTypes] =
    useMemo(() => {
      const financialAssistanceTypes = get(
        initialValues,
        'financialAssistanceTypes',
        []
      )
      return yankBy(financialAssistanceTypes, isOtherType)
    }, [initialValues])
  const [otherOutcome, definedOutcomes] = useMemo(() => {
    const outcomes = get(initialValues, 'outcomes', [])
    return yankBy(outcomes, isOtherType)
  }, [initialValues])
  const [otherApproach, definedApproaches] = useMemo(() => {
    const approaches = get(initialValues, 'approaches', [])
    return yankBy(approaches, isOtherType)
  }, [initialValues])
  return (
    <ExpandableItem
      headerTitle={get(program, 'name', 'New Program')}
      startExpanded={expandForm}
    >
      <div className="expander-content">
        <ProgramForm
          name={program ? `partner-program-${program.id}` : 'partner-program'}
          initialValues={{
            ...initialValues,
            financialAssistanceTypes: definedFinancialAssistanceTypes,
            otherFinancialAssistanceType: {
              ...otherFinancialAssistanceType,
              financialAssistanceTypeId: getOtherTypeId(
                financialAssistanceTypes
              ),
            },
            outcomes: definedOutcomes,
            otherOutcome: {
              ...otherOutcome,
              outcomeTypeId: getOtherTypeId(outcomeTypes),
            },
            approaches: definedApproaches,
            otherApproach: {
              ...otherApproach,
              approachId: getOtherTypeId(approachTypes),
            },
          }}
          onSubmit={onSubmit}
          onSubmitSuccess={(params) => {
            setExpandForm(false)
            return onSubmitSuccess(params)
          }}
          onDelete={onDelete}
          setShowInfoModalWithContent={setShowInfoModalWithContent}
          disabled={disabled}
          grades={grades}
          programTypes={programTypes}
          outcomeTypes={outcomeTypes}
          financialAssistanceTypes={financialAssistanceTypes}
          identityFrequencies={identityFrequencies}
          approachTypes={approachTypes}
          disciplines={disciplines}
          isNewProgram={isNewProgram}
        />
      </div>
    </ExpandableItem>
  )
}

ProgramBlade.propTypes = propTypes

ProgramBlade.defaultProps = defaultProps

export default ProgramBlade
