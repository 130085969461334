import { first } from 'lodash'
import getCurrentDomain from './get-current-domain'
import getCommunitySubdomains from './get-community-subdomains'

function getCurrentSubdomain() {
  const currentDomain = getCurrentDomain()
  const subdomain = first(currentDomain.split('.'))
  if (!getCommunitySubdomains().includes(subdomain)) return null
  return subdomain
}

export default getCurrentSubdomain
