import {get, isEmpty} from "lodash";


// Looks through survey-

// return true: the question is present in the config file
// return false: the question is not present in config file
function showQuestion(community, questionName, responseOptions) {

    const isQuestionEnabled = !!get(
        community,
        `surveyQuestions.${questionName}`
    )
    if (!responseOptions) return isQuestionEnabled

    return isQuestionEnabled && !isEmpty(responseOptions)
}

export default showQuestion