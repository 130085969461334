import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import logoImage from 'images/logo.png'
import { MapDisplay } from 'components'
import * as Types from 'types'

const propTypes = {
  locations: PropTypes.arrayOf(Types.location),
  partnerId: PropTypes.number.isRequired,
}

function DashboardMap({ locations, partnerId, ...rest }) {
  return (
    <div className="card artlook-map">
      <div className="artlook-map-header">
        <img src={logoImage} alt="Artlook Map" />
        <Link
          to={`/partners/${partnerId}`}
          target="_blank"
          rel="noopener noreferrer"
          className="button-secondary"
        >
          View on artlook Map
        </Link>
      </div>
      <div className="map-display">
        <MapDisplay locations={locations} autoZoom {...rest} />
      </div>
    </div>
  )
}

DashboardMap.propTypes = propTypes

export default DashboardMap
