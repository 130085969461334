import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'utils'
import { fieldOptionsType } from 'lp-components'
import classnames from 'classnames'

const propTypes = {
  value: PropTypes.string,
  options: fieldOptionsType,
  onChange: PropTypes.func,
  className: PropTypes.string,
}

const defaultProps = {
  options: [],
  onChange: noop,
  className: '',
}

function SegmentedControl({ options, value, onChange, className, ...rest }) {
  const optionObjects = serializeOptions(options)
  return (
    <div className={classnames('toggle', className)} {...rest}>
      {optionObjects.map((option, i) => {
        const isPressed = value === option.value
        return (
          <button
            key={i}
            className={classnames({ 'is-active': isPressed })}
            onClick={() => onChange(option.value)}
            aria-pressed={isPressed}
          >
            <span>{option.key}</span>
          </button>
        )
      })}
    </div>
  )
}

// Copied from lp-components
function serializeOptions(optionArray) {
  return optionArray.map((option) => {
    return typeof option === 'string' ? { key: option, value: option } : option
  })
}

SegmentedControl.propTypes = propTypes

SegmentedControl.defaultProps = defaultProps

export default SegmentedControl
