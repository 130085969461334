import React from 'react'
import PropTypes from 'prop-types'
import { Paginator } from 'lp-components'
import { EmptySearchState, SearchResultsListItem, ErrorSearchState } from './'
import * as Types from 'types'
import { SEARCH_PAGE_SIZE } from 'config'

const propTypes = {
  results: PropTypes.arrayOf(Types.searchResult).isRequired,
  searchPage: PropTypes.number.isRequired,
  setSearchPage: PropTypes.func.isRequired,
  isFailedSearch: PropTypes.bool.isRequired,
  schoolYearFilter: PropTypes.number,
  resultsTotal: PropTypes.number,
  scrolledSearchResultItemId: PropTypes.string,
  selectedResultId: PropTypes.number,
}

const defaultProps = {
  searchPage: 1,
  selectedResultId: null,
}

function SearchResultsList({
  results,
  searchPage,
  setSearchPage,
  schoolYearFilter,
  resultsTotal,
  isFailedSearch,
  selectedResultId,
}) {
  const numPages = resultsTotal ? Math.ceil(resultsTotal / SEARCH_PAGE_SIZE) : 1

  if (results.length === 0)
    return (
      <div className="results-section">
        {isFailedSearch ? <ErrorSearchState /> : <EmptySearchState />}
      </div>
    )

  return (
    <div className="all-results">
      <ul className="result-blocks">
        {results.map((result, i) => {
          const isSelected = result.id === selectedResultId
          return (
            <SearchResultsListItem
              key={i}
              result={result}
              schoolYearFilter={schoolYearFilter}
              isSelected={isSelected}
            />
          )
        })}
        <Paginator value={searchPage} max={numPages} onChange={setSearchPage} />
      </ul>
    </div>
  )
}

SearchResultsList.propTypes = propTypes
SearchResultsList.defaultProps = defaultProps

export default SearchResultsList
