import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withHandlers } from 'recompose'
import { lpForm } from 'lp-form'
import { modifyProps } from 'lp-hoc'
import {
  Field,
  propTypes as formPropTypes,
  formValueSelector,
  SubmissionError,
} from 'redux-form'
import {
  CheckboxWithText,
  AutoSaveStatusIndicator,
  CheckboxFieldset,
} from 'components'
import { SubmitButton, CheckboxGroup, InputError } from 'lp-components'
import * as Types from 'types'
import { pickBy, isNumber, values, flow } from 'lodash'
import {
  removeOtherType,
  getOtherTypeId,
  getNoneTypeId,
  isNoneType,
  replaceResources,
  yankBy,
  parseIdsToObjects,
  formatObjectsToIds,
  persistSubmitSucceeded,
  filterDestroyed,
  orderLastBy,
  serializeOptions,
} from 'utils'

const propTypes = {
  ...formPropTypes,
  integrationTypes: PropTypes.arrayOf(Types.integrationType).isRequired,
  noneTypeId: PropTypes.number.isRequired,
  selectNoneOption: PropTypes.func.isRequired,
  deselectNoneOption: PropTypes.func.isRequired,
}

// Convert object with numerical values to an array of numbers
function objToArray(obj) {
  if (!obj) return []
  return values(pickBy(obj, isNumber))
}

function ArtsIntegrationForm({
  handleSubmit,
  integrationTypes,
  saved,
  submitting,
  selectNoneOption,
  deselectNoneOption,
  noneTypeId,
  integrations,
  error,
}) {
  const options = useMemo(() => {
    const definedIntegrationTypes = removeOtherType(integrationTypes)
    return flow([orderLastBy(isNoneType), serializeOptions])(
      definedIntegrationTypes
    )
  }, [integrationTypes])
  return (
    <form onSubmit={handleSubmit}>
      <AutoSaveStatusIndicator saveSucceeded={saved} />
      <CheckboxFieldset invalid={!!error}>
        <Field
          name="integrations"
          label={false}
          component={CheckboxGroup}
          format={formatObjectsToIds('integrationTypeId')}
          parse={parseIdsToObjects('integrationTypeId')}
          options={options}
          onChange={(value) => {
            // Deselect other values if None type is selected
            const arrayValue = objToArray(value)
            if (!arrayValue.includes(noneTypeId)) return
            const lastSelectedValue = arrayValue[0]
            const didSelectNone = lastSelectedValue === noneTypeId
            return didSelectNone
              ? selectNoneOption()
              : deselectNoneOption(arrayValue)
          }}
        />
        <Field
          name="otherText"
          label="Other (please describe):"
          placeholder="Description..."
          component={CheckboxWithText}
          onChange={() =>
            deselectNoneOption(
              formatObjectsToIds('integrationTypeId', integrations)
            )
          }
          className="CheckboxGroup full-width-other"
        />
      </CheckboxFieldset>
      {error && <InputError error={error} touched invalid />}
      <div className="button-wrapper">
        <SubmitButton submitting={submitting}>Save Response</SubmitButton>
      </div>
    </form>
  )
}

ArtsIntegrationForm.propTypes = propTypes

const select = formValueSelector('school-arts-integration')

function mapStateToProps(state) {
  return {
    integrations: select(state, 'integrations'),
  }
}

function getSpecialIds({ integrationTypes }) {
  return {
    otherTypeId: getOtherTypeId(integrationTypes),
    noneTypeId: getNoneTypeId(integrationTypes),
  }
}

function modifyInitialValues({ initialValues, otherTypeId }) {
  const [otherIntegration] = yankBy(
    initialValues.integrations,
    ({ integrationTypeId }) => integrationTypeId === otherTypeId
  )
  return {
    initialValues: {
      ...initialValues,
      otherText: otherIntegration ? otherIntegration.otherText : '',
    },
  }
}

function modifyBeforeSubmit({ otherTypeId, initialValues }) {
  return {
    beforeSubmit: ({ integrations, otherText }) => {
      const resources = replaceResources({
        old: initialValues.integrations,
        new: integrations,
      })
      if (otherText)
        resources.push({ integrationTypeId: otherTypeId, otherText })
      if (!filterDestroyed(resources).length)
        throw new SubmissionError({
          _error: 'You must select at least one option',
        })
      return { integrations: resources }
    },
  }
}

function modifyHandlers() {
  return {
    selectNoneOption:
      ({ change, noneTypeId }) =>
      () => {
        setTimeout(() =>
          change(
            'integrations',
            parseIdsToObjects('integrationTypeId', [noneTypeId]),
            0
          )
        )
        setTimeout(() => change('otherText', ''), 0)
      },
    deselectNoneOption:
      ({ change, noneTypeId }) =>
      (values) => {
        const otherOptions = values.filter((val) => val !== noneTypeId)
        setTimeout(() =>
          change(
            'integrations',
            parseIdsToObjects('integrationTypeId', otherOptions),
            0
          )
        )
      },
  }
}

export default compose(
  modifyProps(getSpecialIds),
  connect(mapStateToProps),
  modifyProps(modifyBeforeSubmit),
  modifyProps(modifyInitialValues),
  lpForm({
    name: 'school-arts-integration',
    enableReinitialize: true,
    constraints: {
      otherText: {
        exclusion: {
          within: [' '],
          message: '^Description is required',
        },
      },
    },
  }),
  withHandlers(modifyHandlers),
  persistSubmitSucceeded()
)(ArtsIntegrationForm)
