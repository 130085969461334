import { handleActions } from 'redux-actions'
import { isAdmin } from './auth'
import { compose } from 'recompose'
import { selectorForSlice } from 'lp-redux-utils'
import { createSelector } from 'reselect'
import { setOnSuccess, handleSuccess } from 'lp-redux-api'
import * as LS from 'local-storage'
import * as apiActions from 'api-actions'
import * as schoolActions from 'school-portal-actions'
import { set } from 'utils'
import { toNumber, startCase } from 'lodash'
import * as Types from 'types'

const reducerKey = 'global'
const slice = 'root.educatorPortal.global'

const initialState = {
  currentSchoolId: Number(LS.getCurrentSchoolId()),
  isAdmin: isAdmin(),
  postsCount: 0,
  postsPageCount: 0,
}

const reducer = handleActions(
  {
    // Need this set by school action when not set yet in LS (e.g., before sign in)
    [schoolActions.setCurrentSchoolId]: (state, { payload: id }) => {
      if (!id) return set('currentSchoolId', null, state)
      return set('currentSchoolId', toNumber(id), state)
    },
    [apiActions.fetchSchoolAuthorized]: setOnSuccess('school'),
    [apiActions.fetchCurrentEducatorUserDetails]: (
      state,
      { payload: { data } }
    ) => {
      if (!data) return state
      const bookmarks = [...data.postBookmarks, ...data.userBookmarks]
      return compose(
        set('userDetails', data),
        set('bookmarks', bookmarks)
      )(state)
    },
    [apiActions.createMembership]: setOnSuccess(
      'userDetails',
      ({ payload: { data } }) => data.userDetails
    ),
    [apiActions.deleteMembership]: setOnSuccess(
      'userDetails',
      ({ payload: { data } }) => data.userDetails
    ),
    [apiActions.createOrUpdateEducatorUserProfile]: setOnSuccess(
      'userDetails.profile'
    ),
    [apiActions.agreeToTerms]: setOnSuccess(
      'userDetails.agreedAt',
      ({ payload: { data } }) => data.agreedAt
    ),
    [apiActions.fetchPostTopics]: setOnSuccess('postTopics'),
    [apiActions.fetchPosts]: (state, { payload: { data } }) => {
      if (!data) return state
      const {
        results: { data: posts },
        count,
        totalPages,
      } = data
      return compose(
        set('posts', posts),
        set('postsCount', count),
        set('postsPageCount', totalPages)
      )(state)
    },
    [apiActions.fetchPost]: setOnSuccess('post'),
    [apiActions.fetchComments]: setOnSuccess('post.comments'),
    [apiActions.createComment]: handleSuccess((state, { payload }) =>
      setCommentsState({ state, payload })
    ),
    [apiActions.updateComment]: handleSuccess((state, { payload }) =>
      setCommentsState({ state, payload })
    ),
    [apiActions.archiveComment]: handleSuccess((state, { payload }) =>
      setCommentsState({ state, payload })
    ),
    [apiActions.removeBookmark]: setOnSuccess(
      'bookmarks',
      ({ payload: { data } }) => data.bookmarks
    ),
    [apiActions.addBookmark]: setOnSuccess(
      'bookmarks',
      ({ payload: { data } }) => data.bookmarks
    ),
    [apiActions.addLike]: handleSuccess((state, { payload }) =>
      setLikeState({ state, payload })
    ),
    [apiActions.destroyLike]: handleSuccess((state, { payload }) =>
      setLikeState({ state, payload })
    ),
    [apiActions.fetchSearchOptions]: (state, { payload: { data } }) => {
      if (!data || data.type !== Types.SEARCH_OPTIONS_TYPE.FORUM) return state
      return compose(set('searchOptions', data.searchOptions))(state)
    },
    [apiActions.fetchDirectoryUser]: setOnSuccess('directoryUser'),
    [apiActions.createFlag]: (state, { payload }) =>
      updateReviewableState({ state, payload }),
    [apiActions.unflag]: (state, { payload }) =>
      updateReviewableState({ state, payload }),
    [apiActions.disagreeWithFlag]: (state, { payload }) =>
      updateReviewableState({ state, payload }),
    [apiActions.fetchNotifications]: setOnSuccess('notifications'),
    [apiActions.markAllAsRead]: setOnSuccess('notifications'),
    [apiActions.deleteNotification]: setOnSuccess('notifications'),
    [apiActions.markAsRead]: setOnSuccess('notifications'),
    [apiActions.fetchNotificationPreferences]: setOnSuccess(
      'notificationPreferences'
    ),
    [apiActions.updateNotificationPreferences]: setOnSuccess(
      'notificationPreferences'
    ),
    [apiActions.fetchMentionSuggestions]: setOnSuccess(
      'userMentionSuggestions'
    ),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  currentSchoolId: select('currentSchoolId'),
  isAdmin: select('isAdmin'),
  school: select('school'),
  district: select('school.district'),
  userDetails: select('userDetails'),
  userId: select('userDetails.id'),
  postTopics: select('postTopics'),
  posts: select('posts'),
  postsCount: select('postsCount'),
  postsPageCount: select('postsPageCount'),
  post: select('post'),
  bookmarks: select('bookmarks', []),
  searchOptions: select('searchOptions'),
  directoryUser: select('directoryUser'),
  notifications: select('notifications'),
  notificationPreferences: select('notificationPreferences'),
  userMentionSuggestions: select('userMentionSuggestions'),
}

// Computed

selectors.isModerator = createSelector(
  [selectors.userDetails],
  function (userDetails) {
    if (!userDetails) return
    return Types.MODERATOR_ROLES.includes(userDetails.role)
  }
)

// Helpers

function swapPostState(state, newPost) {
  const index = state.posts.findIndex((post) => post.id === newPost.id)
  const updatedPosts = [...state.posts]
  const existingPost = updatedPosts[index]
  updatedPosts[index] = {
    ...existingPost,
    likes: newPost.likes,
  }
  return compose(set('posts', updatedPosts))(state)
}

function setLikeState({ state, payload: { data: likedItem } }) {
  if (!likedItem) return state
  if (likedItem.type === 'comment') return setCommentState(state, likedItem)
  const updatedPostsState = state.posts
    ? swapPostState(state, likedItem)
    : state
  return setPostState(updatedPostsState, likedItem)
}

function setCommentsState({ state, payload: { data: changedComments } }) {
  if (!changedComments) return state

  return compose(
    set('post.comments', changedComments),
    set('post.commentsCount', changedComments.length)
  )(state)
}

function replaceComment(comments, changedComment) {
  return comments.map((comment) => {
    if (comment.id !== changedComment.id) return comment
    return changedComment
  })
}

function getUpdatedComments(stateComments, changedComment) {
  // parent comment
  if (changedComment.commentableType === Types.COMMENTABLE.POST)
    return replaceComment(stateComments, changedComment)

  // threaded comment
  return stateComments.map((comment) => {
    if (comment.id !== changedComment.commentableId) return comment

    const threadedComments = [...comment.threadedComments]
    return {
      ...comment,
      threadedComments: replaceComment(threadedComments, changedComment),
    }
  })
}

function setCommentState(state, changedComment) {
  if (!state.post || !state.post.comments) return state

  const stateComments = [...state.post.comments]
  return compose(
    set('post.comments', getUpdatedComments(stateComments, changedComment))
  )(state)
}

function setPostState(state, changedPost) {
  if (!state.post) return state

  const updatedPost = {
    comments: state.post.comments,
    commentsCount: state.post.commentsCount,
    ...changedPost,
  }
  return compose(set('post', updatedPost))(state)
}

function setUserState(state, changedUser) {
  if (!state.directoryUser) return state

  const updatedUser = {
    ...state.directoryUser,
    flags: changedUser.flags,
  }
  return compose(set('directoryUser', updatedUser))(state)
}

const { POST, COMMENT, PROFILE } = Types.REVIEWABLE

const setStateFunctions = {
  [POST]: setPostState,
  [COMMENT]: setCommentState,
  [PROFILE]: setUserState,
}

function updateReviewableState({ state, payload: { data } }) {
  if (!data || !data.reviewable) return state

  const setReviewableState = setStateFunctions[startCase(data.reviewable.type)]
  if (!setReviewableState) return state

  return setReviewableState(state, data.reviewable)
}

export { reducer, reducerKey, selectors }
