import React, { useCallback } from 'react'
import * as Types from 'types'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { SubmitButton, ButtonArea } from 'lp-components'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { HiddenLabel, MultiAttachmentInput, AttachmentsLabel } from 'components'
import { MentionsInput } from '../components'
import { getDiscussionAttachmentUploadPath, useCommunity } from 'utils'
import classnames from 'classnames'
import { MAX_CHARACTER_LENGTH } from 'config'
import { isEmpty } from 'lodash'
import { ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'

const propTypes = {
  ...formPropTypes,
  forwardedRef: Types.ref,
  isEditing: PropTypes.bool,
  onCancel: PropTypes.func,
  placeholder: PropTypes.string,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  mentionSuggestions: PropTypes.arrayOf(Types.mentionSuggestion),
  isCommenting: PropTypes.bool,
}

const defaultProps = {
  forwardedRef: null,
  isEditing: false,
  onCancel: null,
  placeholder: 'Leave a Comment on this Discussion',
  isCommenting: false,
}

function CommentForm({
  handleSubmit,
  submitting,
  reset,
  forwardedRef,
  isEditing,
  onCancel,
  placeholder,
  flashSuccessMessage,
  flashErrorMessage,
  mentionSuggestions,
  isCommenting,
}) {
  const community = useCommunity()
  const communityId = community.id

  const validateContent = useCallback((content = '') => {
    if (content.length > MAX_CHARACTER_LENGTH)
      return `Comment must be less than ${MAX_CHARACTER_LENGTH} characters`

    const contentState = ContentState.createFromBlockArray(
      htmlToDraft(content).contentBlocks
    )
    const contentText = contentState.getPlainText()
    if (isEmpty(contentText)) return "Comment can't be blank"
  }, [])

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field
        name="content"
        label="Comment Content"
        labelComponent={HiddenLabel}
        component={MentionsInput}
        placeholder={placeholder}
        forwardedRef={forwardedRef}
        mentionSuggestions={mentionSuggestions}
        validate={validateContent}
        autoFocus={isCommenting}
      />
      <Field
        name="attachments"
        labelComponent={AttachmentsLabel}
        component={MultiAttachmentInput}
        uploadPath={getDiscussionAttachmentUploadPath(communityId, 'comment')}
        flashSuccessMessage={flashSuccessMessage}
        flashErrorMessage={flashErrorMessage}
        className="attachments-field"
      />
      <ButtonArea>
        <button
          type="button"
          className={classnames({
            'link-secondary': !isEditing,
            'button-grey-light': isEditing,
          })}
          onClick={onCancel || reset}
        >
          Cancel
        </button>
        <SubmitButton submitting={submitting}>
          {isEditing ? 'Save Changes' : 'Reply'}
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

CommentForm.propTypes = propTypes
CommentForm.defaultProps = defaultProps

export default compose(lpForm())(CommentForm)
