import React from 'react'
import PropTypes from 'prop-types'
import excelling from 'images/excelling.png'
import strong from 'images/strong.png'
import emerging from 'images/emerging.png'
import developing from 'images/developing.png'
import incomplete from 'images/incomplete.png'
import * as Types from 'types'

const propTypes = {
  description: PropTypes.string,
  level: PropTypes.oneOf(Types.CERTIFICATION_BADGE_TYPES),
}

const defaultProps = {
  description: '',
  level: Types.CERTIFICATION_INCOMPLETE,
}

const BadgeImages = {
  [Types.CERTIFICATION_EXCELLING]: excelling,
  [Types.CERTIFICATION_STRONG]: strong,
  [Types.CERTIFICATION_DEVELOPING]: developing,
  [Types.CERTIFICATION_EMERGING]: emerging,
  [Types.CERTIFICATION_INCOMPLETE]: incomplete,
}

const CertificationClass = {
  [Types.CERTIFICATION_EXCELLING]: 'cat1',
  [Types.CERTIFICATION_STRONG]: 'cat2',
  [Types.CERTIFICATION_DEVELOPING]: 'cat3',
  [Types.CERTIFICATION_EMERGING]: 'cat4',
  [Types.CERTIFICATION_INCOMPLETE]: 'cat5',
}

function CertificationBadge({ level, description }) {
  return (
    <div className={`certification ${CertificationClass[level]}`}>
      <img src={BadgeImages[level]} alt={`${level} Certification`} />
      <p>{description}</p>
    </div>
  )
}

CertificationBadge.propTypes = propTypes
CertificationBadge.defaultProps = defaultProps

export default CertificationBadge
