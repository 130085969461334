// Return array of attachments with only an `uploadUrl` attribute as expected by API

function serializeAttachments(attachments) {
  return attachments.map(({ src }) => {
    return {
      uploadUrl: src,
    }
  })
}

export default serializeAttachments
