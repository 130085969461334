import { isEmpty, isArray, omitBy } from 'lodash'

// If passed filter is an empty array, remove it to simplify the payload and the query params in the url

function isEmptyArray(value) {
  return isArray(value) && isEmpty(value)
}

function removeEmptyFilters(filters) {
  return omitBy(filters, isEmptyArray)
}

export default removeEmptyFilters
