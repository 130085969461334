import React from 'react'

function FieldTripIcon() {
  return (
    <svg viewBox="0 0 50 50">
      <path
        fill="#204A82"
        className="fill"
        d="M50,25A25,25,0,1,1,25,0,25,25,0,0,1,50,25Z"
      />
      <path
        fill="#FFFFFF"
        d="M36,31.12a3.27,3.27,0,1,0,3.26,3.26A3.26,3.26,0,0,0,36,31.12Z"
      />
      <path
        fill="#FFFFFF"
        d="M30.42,33.49l.15,0a5.47,5.47,0,0,1,10.79-.07,2.76,2.76,0,0,0,2-2.66V24c0-1.54-5.47-2.55-9.29-2.55h-1.5V16.33h1.5V14H32.48a2.76,2.76,0,0,0-2.64-2H3.61a25,25,0,0,0-2.3,5H4.64v5.9H.09C0,23.6,0,24.3,0,25a25,25,0,0,0,1.5,8.55H29.84A2.72,2.72,0,0,0,30.42,33.49ZM12.9,22.92H7V17H12.9Zm8.26,0H15.27V17h5.89Zm4.48,8.84H22.86V17h2.78ZM27.11,17H30v14.8H27.11Z"
      />
      <path
        fill="#FFFFFF"
        d="M4.6,39.45A26.71,26.71,0,0,0,7.06,42.4H42.94a26.71,26.71,0,0,0,2.46-2.95Z"
      />
    </svg>
  )
}

export default FieldTripIcon
