// API for interacting with localStorage and sessionStorage

// Auth

const PARTNER_AUTH_TOKEN_KEY = 'partner-portal-token'
const SCHOOL_AUTH_TOKEN_KEY = 'school-portal-token'

export function getPartnerAuthToken() {
  return getItem(PARTNER_AUTH_TOKEN_KEY)
}

export function setPartnerAuthToken(token, persist = true) {
  return setItem(PARTNER_AUTH_TOKEN_KEY, token, persist)
}

export function clearPartnerAuthToken() {
  return removeItem(PARTNER_AUTH_TOKEN_KEY)
}

export function getSchoolAuthToken() {
  return getItem(SCHOOL_AUTH_TOKEN_KEY)
}

export function setSchoolAuthToken(token, persist = true) {
  return setItem(SCHOOL_AUTH_TOKEN_KEY, token, persist)
}

export function clearSchoolAuthToken() {
  return removeItem(SCHOOL_AUTH_TOKEN_KEY)
}

// Current partner Id

const PARTNER_ID_KEY = 'current-partner-id'

export function getCurrentPartnerId() {
  return getItem(PARTNER_ID_KEY)
}

export function setCurrentPartnerId(id) {
  return setItem(PARTNER_ID_KEY, id)
}

export function clearCurrentPartnerId() {
  return removeItem(PARTNER_ID_KEY)
}

// Current school id

const SCHOOL_ID_KEY = 'current-school-id'

export function getCurrentSchoolId() {
  return getItem(SCHOOL_ID_KEY)
}

export function setCurrentSchoolId(id) {
  return setItem(SCHOOL_ID_KEY, id)
}

export function clearCurrentSchoolId() {
  return removeItem(SCHOOL_ID_KEY)
}

// Admin

const ADMIN_KEY = 'admin'

export function getIsAdmin() {
  return getItem(ADMIN_KEY)
}

export function setIsAdmin(id) {
  return setItem(ADMIN_KEY, id)
}

export function clearIsAdmin() {
  return removeItem(ADMIN_KEY)
}

// SSO

const SSO_REDIRECT_KEY = 'sso-redirect'

export function getSsoRedirect() {
  return getItem(SSO_REDIRECT_KEY)
}

export function setSsoRedirect(url) {
  return setItem(SSO_REDIRECT_KEY, url)
}

export function clearSsoRedirect() {
  return removeItem(SSO_REDIRECT_KEY)
}

/* HELPERS */

// Get item from localStorage, falling back to session storage
function getItem(key) {
  const item = localStorage.getItem(key) || sessionStorage.getItem(key)

  try {
    return JSON.parse(item)
  } catch (e) {
    return item
  }
}

// Set item in local storage, or session storage if specified
function setItem(key, value, persist = true) {
  const stringifiedValue = JSON.stringify(value)
  return persist
    ? localStorage.setItem(key, stringifiedValue)
    : sessionStorage.setItem(key, stringifiedValue)
}

// Remove item from local storage and session storage
function removeItem(key) {
  localStorage.removeItem(key)
  sessionStorage.removeItem(key)
}
