import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { lpForm } from 'lp-form'
import { Field, FieldArray, propTypes as formPropTypes } from 'redux-form'
import { SubmitButton, Checkbox, InputError, ButtonArea } from 'lp-components'
import { persistSubmitSucceeded } from 'utils'
import { some, filter, unionBy } from 'lodash'

const propTypes = {
  ...formPropTypes,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  approachTypes: PropTypes.arrayOf(Types.approach).isRequired,
}

const defaultProps = {}

function addNewDisciplineId(selectedDisciplines, disciplineId) {
  return unionBy(selectedDisciplines, [{ disciplineId }], 'disciplineId')
}

function removeDisciplineId(selectedDisciplines, disciplineIdToRemove) {
  return filter(
    selectedDisciplines,
    ({ disciplineId }) => disciplineId !== disciplineIdToRemove
  )
}

function ApproachField({ field, currentSelectionsForApproach, discipline }) {
  const approachId = currentSelectionsForApproach.approachId
  const updateDisciplineIds = useCallback(
    (fieldChecked) => {
      const selectedDisciplines =
        currentSelectionsForApproach.secondaryResource.disciplines
      if (fieldChecked)
        return addNewDisciplineId(selectedDisciplines, discipline.id)
      return removeDisciplineId(selectedDisciplines, discipline.id)
    },
    [currentSelectionsForApproach, discipline]
  )
  const isDisciplineChecked = useCallback(
    (selectedDisciplineIds) =>
      some(selectedDisciplineIds, { disciplineId: discipline.id }),
    [discipline]
  )
  return (
    <td>
      <Field
        name={field + '.secondaryResource.disciplines'}
        label={false}
        aria-labelledby={`${discipline.name} approach-${approachId}`}
        format={isDisciplineChecked}
        parse={updateDisciplineIds}
        component={Checkbox}
      />
    </td>
  )
}

function ApproachFields({ fields, disciplines }) {
  return disciplines.map((discipline) => (
    <tr key={discipline.id}>
      <td id={discipline.name}>{discipline.displayName}</td>
      {fields.map((field, i, fields) => (
        <ApproachField
          key={field}
          field={field}
          currentSelectionsForApproach={fields.get(i)}
          discipline={discipline}
        />
      ))}
    </tr>
  ))
}

function ArtsApproachesTableForm({
  handleSubmit,
  disciplines,
  approachTypes,
  submitting,
  error,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <table>
        <thead>
          <tr>
            <th aria-label="Discipline"></th>
            {approachTypes.map(({ id, displayName }) => (
              <th key={id} id={`approach-${id}`}>
                {displayName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <FieldArray
            name="disciplinesSelectedByApproach"
            disciplines={disciplines}
            component={ApproachFields}
            rerenderOnEveryChange
          />
        </tbody>
      </table>
      {error && <InputError error={error} touched invalid />}
      <ButtonArea>
        <SubmitButton submitting={submitting}>Save Response</SubmitButton>
      </ButtonArea>
    </form>
  )
}

ArtsApproachesTableForm.propTypes = propTypes
ArtsApproachesTableForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'school-arts-approaches-table',
  }),
  persistSubmitSucceeded()
)(ArtsApproachesTableForm)
