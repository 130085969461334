import React from 'react'
// import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { ContentBlock, FormBlock } from 'components'

const propTypes = {}

const defaultProps = {}

function ClaimAccountConfirmation() {
  return (
    <ContentBlock>
      <FormBlock>
        <div className="form-block-inner">
          <h2>Almost done</h2>
          <p>
            We just sent you an email from no-reply@artlookmap.com to set your
            password.
          </p>
          <p>
            <strong>Don't see it yet?</strong>
          </p>
          <p>
            Check your spam folder. It can sometimes take a few minutes to get
            to your inbox.
          </p>
        </div>
      </FormBlock>
    </ContentBlock>
  )
}

ClaimAccountConfirmation.propTypes = propTypes
ClaimAccountConfirmation.defaultProps = defaultProps

export default pure(ClaimAccountConfirmation)
