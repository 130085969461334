import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { useCommunity } from 'utils'
import { Link } from 'react-router'
import { ExternalLink } from 'components'

const propTypes = {}

const defaultProps = {}

function UpdateInfoNote() {
  const community = useCommunity()
  return (
    <section>
      <div className="content-block-container">
        <p className="neutral-callout">
          If you belong to this organization and would like to update your
          information, then please reach out to{' '}
          <ExternalLink href={`mailto:${community.partnerSupportEmail}`}>
            {community.partnerSupportEmail}
          </ExternalLink>{' '}
          or <Link to="/partner-portal/sign-in">sign in</Link>.
        </p>
      </div>
    </section>
  )
}

UpdateInfoNote.propTypes = exact(propTypes)
UpdateInfoNote.defaultProps = defaultProps

export default pure(UpdateInfoNote)
