import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import classnames from 'classnames'
import { BookmarkFilledIcon, BookmarkOutlineIcon } from 'components/icons/basic'

const propTypes = {
  isBookmarked: PropTypes.bool.isRequired,
  toggleBookmark: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
}

const defaultProps = {}

function BookmarkButton({
  isBookmarked,
  toggleBookmark,
  ariaLabel,
  ...buttonProps
}) {
  const { className, ...rest } = buttonProps
  return (
    <div className="bookmark">
      <button
        type="button"
        className={classnames('bookmark-button link-primary', className, {
          bookmarked: isBookmarked,
        })}
        onClick={toggleBookmark}
        aria-label={ariaLabel}
        {...rest}
      >
        {isBookmarked ? (
          <React.Fragment>
            <BookmarkFilledIcon aria-hidden />
            <strong>Bookmarked</strong>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <BookmarkOutlineIcon aria-hidden />
            <strong>Bookmark</strong>
          </React.Fragment>
        )}
      </button>
    </div>
  )
}

BookmarkButton.propTypes = propTypes
BookmarkButton.defaultProps = defaultProps

export default pure(BookmarkButton)
