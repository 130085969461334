import { handleActions } from 'redux-actions'
import { setOnSuccess } from 'lp-redux-api'
import { selectorForSlice } from 'lp-redux-utils'
import * as apiActions from 'api-actions'

const reducerKey = 'user'
const slice = 'root.schoolPortal.user'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchSchoolUserDetails]: setOnSuccess('userDetails'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  userDetails: select('userDetails'),
}

export { reducer, selectors, reducerKey }
