import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import AutoSuggest from 'react-autosuggest'
import SuggestionList from './suggestion-list'
import SuggestionListItem from './suggestion-list-item'
import SuggestionSectionTitle from './suggestion-section-title'
import { fieldPropTypes } from 'lp-components'
import { getSet, getSetPropTypes, modifyProps } from 'lp-hoc'
import { filterSuggestions } from './helpers'
import * as LocalTypes from './types'
import * as Types from 'types'

const propTypes = {
  ...fieldPropTypes,
  sections: PropTypes.arrayOf(LocalTypes.section),
  multiSection: PropTypes.bool,
  suggestionOptions: PropTypes.arrayOf(Types.searchSuggestion),
  ...getSetPropTypes('displayedSuggestions'),
}

const defaultProps = {
  suggestionOptions: [],
}

function AutoSuggestInput({
  input,
  multiSection,
  suggestionOptions,
  suggestions,
  setDisplayedSuggestions,
  ...rest
}) {
  return (
    <AutoSuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={({ value: rawValue }) => {
        const value = rawValue.trim()
        const filteredSuggestions = filterSuggestions(suggestionOptions, value)
        setDisplayedSuggestions(filteredSuggestions)
      }}
      onSuggestionsClearRequested={() => setDisplayedSuggestions(null)}
      getSuggestionValue={(suggestion) => suggestion.name}
      renderSuggestion={SuggestionListItem}
      renderSectionTitle={SuggestionSectionTitle}
      renderSuggestionsContainer={SuggestionList}
      inputProps={input}
      multiSection={multiSection}
      shouldRenderSuggestions={(rawValue) => {
        const value = rawValue.trim()
        return value.length >= 1
      }}
      {...rest}
    />
  )
}

AutoSuggestInput.propTypes = propTypes

AutoSuggestInput.defaultProps = defaultProps

function modify({
  suggestionOptions,
  displayedSuggestions,
  multiSection,
  compileSections,
  input,
}) {
  if (!suggestionOptions || !displayedSuggestions)
    return { suggestions: [], multiSection: false }
  return {
    suggestions: multiSection
      ? compileSections(displayedSuggestions, input)
      : displayedSuggestions,
  }
}

export default compose(
  getSet('displayedSuggestions'),
  modifyProps(modify)
)(AutoSuggestInput)
