import React from 'react'
import PropTypes from 'prop-types'
import { ScrollLink } from 'components'
import { getSet, getSetPropTypes } from 'lp-hoc'
import classnames from 'classnames'
import cscBadgeImg from 'images/map/dashboards/csc-badge.svg'
import dataImg from 'images/map/dashboards/data.svg'
import instructorsImg from 'images/map/dashboards/instructors.svg'
import programsImg from 'images/map/dashboards/programs.svg'
import schoolImg from 'images/map/dashboards/school-profile.svg'
import artsImg from 'images/map/dashboards/arts.svg'
import coursesImg from 'images/map/dashboards/apple.svg'

const propTypes = {
  hasResources: PropTypes.bool.isRequired,
  hasTeachers: PropTypes.bool.isRequired,
  hasPartners: PropTypes.bool.isRequired,
  hasCertification: PropTypes.bool.isRequired,
  hasSubDisciplines: PropTypes.bool.isRequired,
  hasCourses: PropTypes.bool.isRequired,
  hasDashboard: PropTypes.bool.isRequired,
  ...getSetPropTypes('currentTab'),
}

const defaultProps = {}

function SchoolTabs({
  currentTab,
  setCurrentTab,
  hasResources,
  hasTeachers,
  hasPartners,
  hasCertification,
  hasSubDisciplines,
  hasCourses,
  hasDashboard,
}) {
  return (
    <nav aria-label="school content">
      <ul className="header-tabs">
        <li className={classnames({ 'is-active': currentTab == 'details' })}>
          <ScrollLink
            to="details"
            smooth
            onClick={() => setCurrentTab('details')}
          >
            <img src={schoolImg} alt="" />
            <span>School Details</span>
          </ScrollLink>
        </li>
        {!hasCertification && hasDashboard && (
          <li
            className={classnames({ 'is-active': currentTab == 'dashboard' })}
          >
            <ScrollLink
              to="dashboard"
              smooth
              onClick={() => setCurrentTab('dashboard')}
            >
              <img src={dataImg} alt="" />
              <span>Data Dashboard</span>
            </ScrollLink>
          </li>
        )}
        {hasCertification && (
          <li className={classnames({ 'is-active': currentTab == 'csc' })}>
            <ScrollLink
              to="csc"
              smooth
              onClick={() => setCurrentTab('csc')}
              aria-label="Creative Schools Certification"
            >
              <img src={cscBadgeImg} alt="" />
              <span>CSC</span>
            </ScrollLink>
          </li>
        )}
        {hasResources && (
          <li
            className={classnames({ 'is-active': currentTab == 'interests' })}
          >
            <ScrollLink
              to="interests"
              smooth
              onClick={() => setCurrentTab('interests')}
            >
              <img src={dataImg} alt="" />
              <span>Interests</span>
            </ScrollLink>
          </li>
        )}
        {hasTeachers && (
          <li
            className={classnames({ 'is-active': currentTab == 'instructors' })}
          >
            <ScrollLink
              to="instructors"
              smooth
              onClick={() => setCurrentTab('instructors')}
            >
              <img src={instructorsImg} alt="" />
              <span>Arts Instructors</span>
            </ScrollLink>
          </li>
        )}
        {hasSubDisciplines && (
          <li
            className={classnames({
              'is-active': currentTab == 'subDisciplines',
            })}
          >
            <ScrollLink
              to="subDisciplines"
              smooth
              onClick={() => setCurrentTab('subDisciplines')}
              aria-label="Program Offerings"
            >
              <img src={artsImg} alt="" />
              <span>Program Offerings</span>
            </ScrollLink>
          </li>
        )}
        {hasCourses && (
          <li className={classnames({ 'is-active': currentTab == 'courses' })}>
            <ScrollLink
              to="courses"
              smooth
              onClick={() => setCurrentTab('courses')}
            >
              <img src={coursesImg} alt="" />
              <span>Courses</span>
            </ScrollLink>
          </li>
        )}
        {hasPartners && (
          <li className={classnames({ 'is-active': currentTab == 'partners' })}>
            <ScrollLink
              to="partners"
              smooth
              onClick={() => setCurrentTab('partners')}
              aria-label="Arts Partners"
            >
              <img src={programsImg} alt="" />
              <span>Arts Partners</span>
            </ScrollLink>
          </li>
        )}
      </ul>
    </nav>
  )
}

SchoolTabs.propTypes = propTypes

SchoolTabs.defaultProps = defaultProps

export default getSet('currentTab', {
  initialValues: { currentTab: 'details' },
})(SchoolTabs)
