import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { modifyProps } from 'lp-hoc'
import { selectors as globalSelectors } from 'school-portal-reducer'
import { RenderedHtml } from 'components'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { useCommunityText } from 'utils'
import { selectors } from '../reducer'
import { get } from 'lodash'
import { SCHOOL_PORTAL_ROUTE, SURVEY_ATTEMPT_STATES } from 'config'
import { format } from 'date-fns'
import * as Types from 'types'

const propTypes = {
  expirationDate: PropTypes.string,
  isSurveyAttemptInProgress: PropTypes.bool.isRequired,
  currentSchoolId: PropTypes.number.isRequired,
  surveyAttempt: Types.surveyAttempt,
  id: PropTypes.number,
  setSurveyAttemptState: PropTypes.func.isRequired,
  updateSurveyAttemptState: PropTypes.func.isRequired,
  surveySchoolYear: Types.schoolYear.isRequired,
}

function GetStarted({
  expirationDate,
  isSurveyAttemptInProgress,
  setSurveyAttemptState,
  currentSchoolId,
  surveyAttempt,
  updateSurveyAttemptState,
  surveySchoolYear,
}) {
  const t = useCommunityText()
  const surveySchoolYearNumber = surveySchoolYear.number
  return (
    <div className="content-block-container">
      <section className="card">
        <RenderedHtml addAttrs={['target']} className="survey-instructions">
          {t('survey.getStarted', { expirationDate, surveySchoolYearNumber })}
        </RenderedHtml>
        <Link
          to={`${SCHOOL_PORTAL_ROUTE}/survey/staffing-and-instruction`}
          onClick={() => {
            if (isSurveyAttemptInProgress) return
            return updateSurveyAttemptState(
              currentSchoolId,
              surveyAttempt.id,
              SURVEY_ATTEMPT_STATES.IN_PROGRESS
            ).then(setSurveyAttemptState(SURVEY_ATTEMPT_STATES.IN_PROGRESS))
          }}
          className="button-primary"
        >
          {isSurveyAttemptInProgress ? 'Resume' : 'Get Started'}
        </Link>
      </section>
    </div>
  )
}

GetStarted.propTypes = propTypes

function mapStateToProps(state) {
  return {
    currentSchoolId: globalSelectors.currentSchoolId(state),
    surveyAttempt: selectors.surveyAttempt(state),
    isSurveyAttemptInProgress: selectors.isSurveyAttemptInProgress(state),
    surveySchoolYear: globalSelectors.surveySchoolYear(state),
  }
}

const mapDispatchToProps = {
  updateSurveyAttemptState: apiActions.updateSurveyAttemptState,
  setSurveyAttemptState: actions.setSurveyAttemptState,
}

function modify({ surveyAttempt }) {
  if (!surveyAttempt) return {}
  return {
    expirationDate: format(
      get(surveyAttempt, 'survey.dueDate'),
      'dddd, MMMM D'
    ),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  modifyProps(modify)
)(GetStarted)
