import { FlagIcon, ThumbsUpIcon } from 'components/icons/basic'

// Takes in isFlagged and isModerator booleans and
// returns the correct icon and label for the flag action menu item

function flagActionIcon(hasFlags, isModerator) {
  if (!hasFlags || !isModerator) return FlagIcon

  return ThumbsUpIcon
}

function flagActionLabel(hasFlags, isModerator) {
  if (!hasFlags) return 'Flag as Inappropriate'

  if (!isModerator) return 'Flagged as Inappropriate'

  return 'Mark as Appropriate'
}

function flagActionIconAndLabel(hasFlags, isModerator) {
  return {
    icon: flagActionIcon(hasFlags, isModerator),
    label: flagActionLabel(hasFlags, isModerator),
  }
}

export default flagActionIconAndLabel
