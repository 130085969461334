import React, { useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import { Input, Select, SubmitButton } from 'lp-components'
import { Field, FormSection, formValueSelector } from 'redux-form'
import SearchFilterToolbar from './search-filter-toolbar'
import { get, isEmpty } from 'lodash'
import classnames from 'classnames'
import BodyClassName from 'react-body-classname'

const propTypes = {
  change: PropTypes.func.isRequired,
  enableRemoveAll: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  form: PropTypes.string.isRequired,
  searchOptions: PropTypes.object.isRequired,
  searchType: PropTypes.string,
  selectedFilters: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
  searchOnTypeChange: PropTypes.bool,
  searchOnYearChange: PropTypes.bool,
  hasShownSearchResults: PropTypes.bool,
}

const defaultProps = {
  enableRemoveAll: false,
  initialValues: {},
  selectedFilters: {},
  searchType: Types.SCHOOL_TYPE,
  searchOnTypeChange: false,
  searchOnYearChange: false,
  hasShownSearchResults: false,
}

function SearchForm({
  change,
  enableRemoveAll,
  handleSubmit,
  initialValues,
  form: formName,
  searchOptions,
  searchType,
  selectedFilters,
  submitting,
  searchOnTypeChange,
  searchOnYearChange,
  hasShownSearchResults,
}) {
  const [filtersVisibleOnMobile, setFiltersVisibleOnMobile] = useState(false)

  const appliedFilters = useMemo(() => {
    return get(initialValues, 'filters', {})
  }, [initialValues.filters])

  const removeAllFilters = useCallback(() => {
    change('filters', {})
    setTimeout(handleSubmit, 0)
  }, [])

  return (
    <BodyClassName className={filtersVisibleOnMobile ? 'no-scroll' : ''}>
      <form onSubmit={handleSubmit} noValidate role="search">
        <div className="search-form">
          <div className="select-options">
            <Field
              name="type"
              component={Select}
              label={false}
              aria-label="Search Type"
              options={[
                { key: 'Schools', value: Types.SCHOOL_TYPE },
                { key: 'Partners', value: Types.PARTNER_TYPE },
              ]}
              onChange={() => {
                change('filters', {})
                if (!searchOnTypeChange) return
                // Skip a tick to ensure that form values are updated
                return setTimeout(handleSubmit, 0)
              }}
              data-cy="search-type"
            />
            <Field
              name="schoolYear"
              component={Select}
              label={false}
              aria-label="School Year"
              options={searchOptions.schoolYears}
              onChange={() => {
                if (!searchOnYearChange) return
                setTimeout(handleSubmit, 0)
              }}
              data-cy="school-year"
              className="year"
            />
          </div>
          <div className="search-bar">
            <Field
              name="query"
              component={Input}
              label={false}
              aria-label="Enter search query (school, organization, program, or location)"
              placeholder="Search artlook by school, organization, program, or instructor"
            />
            <SubmitButton
              {...{ submitting }}
              style="alert"
              data-cy="search-button"
            >
              Search
            </SubmitButton>
          </div>
        </div>
        {!isEmpty(searchOptions) && (
          <section
            className={classnames('map-filters', {
              'is-active': filtersVisibleOnMobile,
            })}
          >
            <button
              type="button"
              className="link-black filter-toggle"
              aria-expanded={filtersVisibleOnMobile}
              aria-controls="filter-content"
              onClick={() => setFiltersVisibleOnMobile(true)}
            >
              Advanced Filters +
            </button>
            <div id="filter-content" className="filter-content-wrapper">
              <button
                type="button"
                className="link-black close"
                aria-label="Close Filters"
                onClick={() => setFiltersVisibleOnMobile(false)}
              >
                ×
              </button>
              <FormSection name="filters">
                <SearchFilterToolbar
                  filterOptions={searchOptions}
                  applyFilter={() => {
                    handleSubmit()
                    if (filtersVisibleOnMobile) setFiltersVisibleOnMobile(false)
                  }}
                  resetFilter={(filterName) => {
                    change('filters.' + filterName, '')
                    setTimeout(handleSubmit, 0)
                    if (filtersVisibleOnMobile) setFiltersVisibleOnMobile(false)
                  }}
                  selectedFilters={selectedFilters}
                  appliedFilters={appliedFilters}
                  searchType={searchType}
                  formName={formName}
                  removeAllFilters={() => {
                    removeAllFilters()
                    if (filtersVisibleOnMobile) setFiltersVisibleOnMobile(false)
                  }}
                  enableRemoveAll={enableRemoveAll}
                  hasShownSearchResults={hasShownSearchResults}
                />
              </FormSection>
            </div>
          </section>
        )}
      </form>
    </BodyClassName>
  )
}

SearchForm.propTypes = propTypes
SearchForm.defaultProps = defaultProps

function mapStateToProps(state, { name }) {
  const formSelector = formValueSelector(name)
  return {
    selectedFilters: formSelector(state, 'filters'),
    searchType: formSelector(state, 'type'),
  }
}

export default compose(
  connect(mapStateToProps),
  lpForm({ name: 'search-form' })
)(SearchForm)
