// Returns employment that is currently signed into from user employments

function getCurrentEmployment({
  employments,
  currentEmployableId,
  currentEmployableType,
}) {
  return employments.find(
    (e) =>
      e.employable.id === currentEmployableId &&
      e.employableType === currentEmployableType
  )
}

export default getCurrentEmployment
