import React from 'react'

function MediaArtsIcon() {
  return (
    <svg viewBox="0 0 67.75 67.75">
      <circle fill="#204A82" cx="33.88" cy="33.87" r="33.87" />
      <path
        fill="#FFFFFF"
        d="M77.71,36.51h-42a2,2,0,0,0-2,2v31a2,2,0,0,0,2,2h42a2,2,0,0,0,2-2v-31A2,2,0,0,0,77.71,36.51Zm-2,31h-38v-27h38Z"
        transform="translate(-22.85 -21.6)"
      />
      <polygon
        fill="#FFFFFF"
        points="38.85 54.91 38.85 51.91 28.86 51.91 28.86 54.91 23.81 54.91 23.81 58.91 44.12 58.91 44.12 54.91 38.85 54.91"
      />
      <polygon
        fill="#FFFFFF"
        points="40.46 33.71 27.24 33.73 33.8 23.69 40.46 33.71"
      />
      <polygon
        fill="#FFFFFF"
        points="40.6 30.33 40.6 30.33 40.6 30.33 40.6 30.33"
      />
      <polygon
        fill="#FFFFFF"
        points="40.46 33.73 27.24 33.73 33.8 23.69 40.46 33.73"
      />
      <path
        fill="#FFFFFF"
        d="M70.89,57.05a5.48,5.48,0,0,1-6.57,5.37,143.78,143.78,0,0,1-1-.3,5.52,5.52,0,0,1-.64-.34,2.07,2.07,0,0,1-.26-.14,5.78,5.78,0,0,1-.73-.57L61.62,61a6.29,6.29,0,0,1-.67-.76s0,0,0,0-.17-.26-.25-.4a4.12,4.12,0,0,1-.22-.42l0,0,0-.06-.12-.3a1.23,1.23,0,0,1-.08-.2l-.12-.42a6.18,6.18,0,0,1-.15-1.06H67l-3.59-5.41a6.33,6.33,0,0,1,.86-.24l.2,0a5.38,5.38,0,0,1,.89-.08A5.49,5.49,0,0,1,70.89,57.05Z"
        transform="translate(-22.85 -21.6)"
      />
      <polygon
        fill="#FFFFFF"
        points="40.46 33.73 27.24 33.73 33.8 23.69 40.46 33.73"
      />
      <path
        fill="#FFFFFF"
        d="M70.89,57.05a5.48,5.48,0,0,1-6.57,5.37,143.78,143.78,0,0,1-1-.3,5.52,5.52,0,0,1-.64-.34,2.07,2.07,0,0,1-.26-.14,5.78,5.78,0,0,1-.73-.57L61.62,61a6.29,6.29,0,0,1-.67-.76s0,0,0,0-.17-.26-.25-.4a2.8,2.8,0,0,1-.22-.42l0,0,0-.06-.12-.3a1.23,1.23,0,0,1-.08-.2l-.12-.42a6.18,6.18,0,0,1-.15-1.06H67l-3.59-5.41a6.33,6.33,0,0,1,.86-.24l.2,0a5.38,5.38,0,0,1,.89-.08A5.49,5.49,0,0,1,70.89,57.05Z"
        transform="translate(-22.85 -21.6)"
      />
      <polygon
        fill="#FFFFFF"
        points="29.66 35.75 29.66 39.96 19.49 39.96 19.49 29.79 27.43 29.79 23.53 35.75 29.66 35.75"
      />
    </svg>
  )
}

export default MediaArtsIcon
