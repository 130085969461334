import { get, toLower } from 'lodash'
const { SCHOOL_PORTAL_OVERRIDE } = process.env

function isSchoolPortalEnabled(communityConfig) {
  return (
    get(communityConfig, 'schoolPortalEnabled') ||
    toLower(SCHOOL_PORTAL_OVERRIDE) === 'true'
  )
}

export default isSchoolPortalEnabled
