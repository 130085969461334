import useCommunity from './use-community'
import useSurveyQuestions from './use-survey-questions'
import interpolateText from './interpolate-text'
import useCommunityText from './use-community-text'
import { get } from 'lodash'

// Provide survey question option object (along with the name) based on community config
function useCommunitySurveyQuestionOption(questionName) {
  const { surveyQuestions } = useCommunity()
  const surveyQuestionBank = useSurveyQuestions()
  const optionName = get(surveyQuestions, questionName)

  if (!optionName) return null

  const questionOption = get(
    surveyQuestionBank,
    `${questionName}.${optionName}`
  )
  const t = useCommunityText()
  const question = get(questionOption, 'question')
  const getQuestionText = (options = {}) => {
    return (
      t(`survey.${questionName}.question`, options) ||
      interpolateText(question, options)
    )
  }
  const description = get(questionOption, 'description')
  const getQuestionDescription = (options = {}) => {
    return (
      t(`survey.${questionName}.description`, options) ||
      interpolateText(description, options)
    )
  }

  return {
    optionName,
    getQuestionText,
    getQuestionDescription,
    ...questionOption,
  }
}

export default useCommunitySurveyQuestionOption
