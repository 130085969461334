import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { selectors as globalSchoolSelectors } from 'school-portal-reducer'
import NotificationEmptyState from './notification-empty-state'
import NotificationList from './notification-list'
import GearIcon from './icons/basic/gear-icon'

const propTypes = {
  openPreferencesModal: PropTypes.func.isRequired,
  handleMarkAllAsReadCTA: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(Types.notification).isRequired,
  unreadCount: PropTypes.number,
}

const defaultProps = {
  unreadCount: 0,
}

function NotificationCenter({
  openPreferencesModal,
  handleMarkAllAsReadCTA,
  notifications,
  unreadCount,
}) {
  const notificationCount = notifications.length

  return (
    <div className="notification-center">
      <div className="notification-center__header">
        <div className="header-top">
          <div className="header-title">
            <h3>Notifications</h3>
            {notificationCount > 0 && (
              <span className="notification-counter">{notificationCount}</span>
            )}
          </div>
          <button
            className="icon-button"
            onClick={() => openPreferencesModal(true)}
          >
            <GearIcon />
          </button>
        </div>
        {unreadCount > 0 && (
          <button
            className="link-primary header-cta"
            onClick={handleMarkAllAsReadCTA}
          >
            Mark all as read
          </button>
        )}
      </div>
      <div className="notification-center__body">
        {notificationCount ? (
          <NotificationList notifications={notifications} />
        ) : (
          <NotificationEmptyState />
        )}
      </div>
    </div>
  )
}

NotificationCenter.propTypes = propTypes
NotificationCenter.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    notifications: globalSchoolSelectors.notifications(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NotificationCenter
)
