import React from 'react'
import exact from 'prop-types-exact'
import { StarIcon } from 'components/icons'

const propTypes = {}

const defaultProps = {}

function OpenToPartnershipsIndicator() {
  return (
    <div className="flex-horizontal open-to-partnerships">
      <div className="star-icon">
        <StarIcon aria-hidden />
      </div>
      <p>
        <strong>Looking for a Partnership</strong>
      </p>
    </div>
  )
}

OpenToPartnershipsIndicator.propTypes = exact(propTypes)
OpenToPartnershipsIndicator.defaultProps = defaultProps

export default React.memo(OpenToPartnershipsIndicator)
