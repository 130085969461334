import { handleActions } from 'redux-actions'
import { setOnSuccess } from 'lp-redux-api'
import { selectorForSlice } from 'lp-redux-utils'
import { compose } from 'recompose'
import * as apiActions from 'api-actions'
import * as Types from 'types'
import { set } from 'utils'

const reducerKey = 'directory'
const slice = 'root.educatorPortal.directory'

const initialState = {
  usersCount: 0,
  directoryPageCount: 0,
}

const reducer = handleActions(
  {
    [apiActions.fetchDirectoryUsers]: (state, { payload: { data } }) => {
      if (!data) return state
      const {
        results: { data: users },
        count,
        totalPages,
      } = data
      return compose(
        set('users', users),
        set('usersCount', count),
        set('directoryPageCount', totalPages)
      )(state)
    },
    [apiActions.fetchSearchOptions]: (state, { payload: { data } }) => {
      if (!data || data.type !== Types.SEARCH_OPTIONS_TYPE.DIRECTORY)
        return state
      return compose(set('searchOptions', data.searchOptions))(state)
    },
    [apiActions.fetchCurrentEducatorUserDetails]: setOnSuccess(
      'bookmarks',
      ({ payload: { data } }) => data.userBookmarks
    ),
    [apiActions.removeBookmark]: setOnSuccess('bookmarks', getUserBookmarks),
    [apiActions.addBookmark]: setOnSuccess('bookmarks', getUserBookmarks),
    [apiActions.createFlag]: (state, { payload }) =>
      updateUserState({ state, payload }),
    [apiActions.unflag]: (state, { payload }) =>
      updateUserState({ state, payload }),
    [apiActions.disagreeWithFlag]: (state, { payload }) =>
      updateUserState({ state, payload }),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  users: select('users'),
  usersCount: select('usersCount'),
  directoryPageCount: select('directoryPageCount'),
  searchOptions: select('searchOptions'),
  bookmarks: select('bookmarks', []),
}

// Helper functions

function getUserBookmarks({ payload: { data } }) {
  return data.bookmarks.filter(
    ({ bookmarkableType }) => bookmarkableType === Types.BOOKMARKABLE.USER
  )
}

function setUserState({ state, changedProfile }) {
  if (!state.users) return state

  const users = [...state.users]
  const updatedUsers = users.map((user) => {
    if (user.profileId !== changedProfile.id) return user
    return {
      ...user,
      flags: changedProfile.flags,
    }
  })
  return compose(set('users', updatedUsers))(state)
}

function updateUserState({ state, payload: { data } }) {
  if (
    !data ||
    !data.reviewable ||
    data.reviewable.type !== Types.REVIEWABLE.PROFILE.toLowerCase()
  )
    return state

  return setUserState({ state, changedProfile: data.reviewable })
}

export { reducer, selectors, reducerKey }
