import { curry } from 'lodash/fp'

// Formats object to id

function formatObjectToId(key, object) {
  if (!object) return ''
  return object[key]
}

export default curry(formatObjectToId)
