import React from 'react'
import PropTypes from 'prop-types'
import { PORTAL } from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
// import { onMount } from 'lp-hoc'
import { ContentBlock, FormBlock, PortalSupport } from 'components'
import { ForgotPasswordForm } from '../forms'
import * as apiActions from 'api-actions'
import { PARTNER_PORTAL_ROUTE, PARTNER_PORTAL_PATH } from 'config'
import { useCommunity, displaySubmitFailure } from 'utils'

const propTypes = {
  forgotPasswordSuccess: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
}

function ForgotPassword({ forgotPasswordSuccess, forgotPassword }) {
  const community = useCommunity()
  return (
    <ContentBlock>
      <FormBlock>
        <div className="form-block-inner">
          <h2>Forgot Password</h2>
          <ForgotPasswordForm
            onSubmit={forgotPassword}
            onSubmitSuccess={forgotPasswordSuccess}
            onSubmitFail={displaySubmitFailure}
            initialValues={{ portal: PARTNER_PORTAL_PATH }}
          />
        </div>
      </FormBlock>
      <PortalSupport
        supportEmail={community.partnerSupportEmail}
        portalType={PORTAL.PARTNER_PORTAL}
      />
    </ContentBlock>
  )
}

ForgotPassword.propTypes = propTypes

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  forgotPassword: apiActions.forgotPassword,
  forgotPasswordSuccess: () =>
    push(PARTNER_PORTAL_ROUTE + '/forgot-password-confirmation'),
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ForgotPassword
)
