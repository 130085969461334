import React from 'react'

function SchoolIcon() {
  return (
    <svg width="83px" height="84px" viewBox="0 0 83 84" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <path
            className="fill"
            d="M82.9897593,41.6666667 C82.9897593,64.6785312 64.5905024,83.3333333 41.8938689,83.3333333 C19.1972353,83.3333333 0.797978455,64.6785312 0.797978455,41.6666667 C0.797978455,18.6548021 19.1972353,0 41.8938689,0 C52.7931767,0 63.2460772,4.38986835 70.9530517,12.2038841 C78.6600261,20.0178999 82.9897593,30.6159796 82.9897593,41.6666667 Z"
            fill="#003C7A"
            fillRule="nonzero"
          ></path>
          <polygon
            fill="#FFFFFF"
            fillRule="nonzero"
            points="49.1311398 24.2718447 49.1311398 19.363085 51.8685995 19.3469378 51.8202914 18.2812203 41.0797877 8.89967638 30.3231813 18.2812203 30.3231813 19.3469378 33.0123329 19.363085 33.0123329 24.2556974 41.063685 18.1843368"
          ></polygon>
          <path d="M20.827435,42.6264903 L16.2411387,42.6264903 L16.2411387,40.8153563 L40.7178235,22.0332267 L65.227385,40.7985866 L65.227385,42.4755624 L60.4273904,42.4755624 L60.4273904,65.5339806 L75.057511,65.5339806 C84.9964116,50.6532321 84.5107638,30.9599129 73.8509658,16.6078289 C63.1911678,2.25574495 44.7383561,-3.54920143 28.0067354,2.18599969 C11.2751148,7.92120082 0.00211743209,23.9154467 0,41.9221604 C-0.0142160118,50.3472216 2.47303798,58.5791574 7.13429967,65.5339806 L20.827435,65.5339806 L20.827435,42.6264903 Z"></path>
          <path d="M45.1913298,47.7346278 C44.9912729,49.2355988 44.6675059,50.7148957 44.2237792,52.1553821 L45.9560069,52.1553821 C45.9560069,52.1553821 45.3473863,54.0666105 44.2237792,54.0666105 C44.0122837,54.0617837 43.8065059,53.9925801 43.6307643,53.8671779 C43.1548612,55.0846547 42.4901614,56.2082702 41.6644518,57.1910533 C41.3686335,57.5313802 41.0448267,57.8428493 40.6969012,58.1217384 C41.5552122,62.0106727 43.3498625,65.5339806 47.0484027,65.5339806 C55.3350055,65.5339806 54.2113983,47.8675828 54.2113983,47.8675828 L45.1913298,47.7346278 Z"></path>
          <path
            d="M61.1983041,65.4767912 L61.1983041,42.3823749 L65.9915822,42.3823749 L65.9915822,40.7003344 L41.5491468,21.8446602 L17.1067114,40.683514 L17.1067114,42.5001177 L21.6865902,42.5001177 L21.6865902,65.4767912 L7.97978455,65.4767912 C9.1532343,67.2080315 10.4479881,68.8495461 11.8538039,70.3883495 L71.9339339,70.3883495 C73.3448692,68.8064332 74.6397381,67.1197959 75.8079532,65.342228 L61.1983041,65.4767912 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default SchoolIcon
