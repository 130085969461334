import { curry } from 'lodash/fp'

// Create array of objects from array of ids

function parseIdsToObjects(key, ids) {
  if (!ids) return []
  return ids.map((id) => ({ [key]: id }))
}

export default curry(parseIdsToObjects)
