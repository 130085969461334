import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as flashActions from 'redux-flash'
import { Spinner, ButtonArea } from 'lp-components'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { selectors as globalSelectors } from 'educator-portal-reducer'
import { ResourcesTable, ResourcesAttachmentModal } from '../components'
import {
  createCopPaths,
  hasLoadedAssociationForCommunityOfPractice,
} from 'utils'

const propTypes = {
  communityOfPractice: Types.communityOfPractice.isRequired,
  fetchFolder: PropTypes.func.isRequired,
  folder: Types.showFolder,
  isModerator: PropTypes.bool.isRequired,
  fetchAttachableOptions: PropTypes.func.isRequired,
  attachableOptions: PropTypes.arrayOf(Types.attachableOption),
  createOrUpdateAttachment: PropTypes.func.isRequired,
  deleteFolder: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  // provided by react router
  params: PropTypes.object.isRequired,
}

const defaultProps = {
  folder: null,
  attachableOptions: null,
}

function ResourcesFolder({
  communityOfPractice,
  fetchFolder,
  folder,
  isModerator,
  fetchAttachableOptions,
  attachableOptions,
  createOrUpdateAttachment,
  deleteFolder,
  deleteAttachment,
  flashSuccessMessage,
  flashErrorMessage,
  params: { folderId },
}) {
  const communityOfPracticeId = parseInt(communityOfPractice.id)
  const { copResourcesIndexPath } = createCopPaths(communityOfPracticeId)
  const [showAttachmentModal, setShowAttachmentModal] = useState(false)
  // Make sure to clear `attachmentToEdit` when uploading a _new_ attachment
  const [attachmentToEdit, setAttachmentToEdit] = useState()

  const editAttachment = (attachment) => {
    setAttachmentToEdit(attachment)
    setShowAttachmentModal(true)
  }

  useEffect(() => {
    fetchFolder({ communityOfPracticeId, folderId })
  }, [communityOfPracticeId, folderId])

  useEffect(() => {
    fetchAttachableOptions(communityOfPracticeId)
  }, [communityOfPracticeId])

  const requestDeleteFolder = (idToDelete) => {
    deleteFolder(idToDelete)
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
        fetchFolder({ communityOfPracticeId, folderId })
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }

  const requestDeleteAttachment = (attachmentId) => {
    deleteAttachment(attachmentId)
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
        fetchFolder({ communityOfPracticeId, folderId })
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }

  const hasCorrectAttachableOptions =
    hasLoadedAssociationForCommunityOfPractice(
      attachableOptions,
      communityOfPracticeId
    )

  const onSelectUploadResource = () => {
    setAttachmentToEdit(null)
    setShowAttachmentModal(true)
  }

  if (!folder || folder.id != folderId || !hasCorrectAttachableOptions)
    return <Spinner />

  const createHeading = () => {
    if (folder.folderableType !== Types.FOLDERABLE_TYPE.FOLDER)
      return `${folder.name}`
    return `${folder.folderableName} < ${folder.name}`
  }

  return (
    <React.Fragment>
      <header className="cop-header">
        <h2>
          <strong>{`Resource Library < ${createHeading()}`}</strong>
        </h2>
      </header>
      <section className="card">
        <ResourcesTable
          folders={folder.folders}
          attachments={folder.attachments}
          canModify={isModerator}
          editAttachment={editAttachment}
          deleteAttachment={requestDeleteAttachment}
          deleteFolder={requestDeleteFolder}
          copResourcesIndexPath={copResourcesIndexPath}
        />
        {isModerator && (
          <ButtonArea>
            <button
              className="link-secondary no-underline underline-hover"
              onClick={onSelectUploadResource}
            >
              +Upload Resource
            </button>
          </ButtonArea>
        )}
      </section>
      {showAttachmentModal && (
        <ResourcesAttachmentModal
          onClose={() => {
            setShowAttachmentModal(false)
          }}
          createOrUpdateAttachment={createOrUpdateAttachment}
          onSubmitSuccess={() => {
            setShowAttachmentModal(false)
            flashSuccessMessage('New resource created!')
            fetchFolder({ communityOfPracticeId, folderId })
          }}
          flashSuccessMessage={flashSuccessMessage}
          flashErrorMessage={flashErrorMessage}
          attachableOptions={attachableOptions}
          attachment={attachmentToEdit}
          initialAttachable={`${Types.FOLDERABLE_TYPE.FOLDER}_${folderId}`}
        />
      )}
    </React.Fragment>
  )
}

ResourcesFolder.propTypes = propTypes
ResourcesFolder.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    communityOfPractice: selectors.communityOfPractice(state),
    folder: selectors.folder(state),
    isModerator: globalSelectors.isModerator(state),
    attachableOptions: selectors.attachableOptions(state),
  }
}

const mapDispatchToProps = {
  fetchFolder: apiActions.fetchFolder,
  fetchAttachableOptions: apiActions.fetchAttachableOptions,
  createOrUpdateAttachment: apiActions.createOrUpdateAttachment,
  deleteFolder: apiActions.deleteFolder,
  deleteAttachment: apiActions.deleteAttachment,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  flashErrorMessage: flashActions.flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ResourcesFolder
)
