import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ExternalLink } from 'components'

const propTypes = {
  supportEmail: PropTypes.string,
  portalType: Types.portalType.isRequired,
}

const defaultProps = {
  supportEmail: '',
}

function PortalSupport({ supportEmail, portalType }) {
  if (!supportEmail) return null

  return (
    <React.Fragment>
      <br />
      <p className="support">
        Need help? Please contact&nbsp;
        <ExternalLink
          href={`mailto:${supportEmail}?subject=Login Issues&body=I'm having trouble accessing the ${portalType}`}
        >
          {supportEmail}
        </ExternalLink>
        .
      </p>
    </React.Fragment>
  )
}

PortalSupport.propTypes = exact(propTypes)
PortalSupport.defaultProps = defaultProps

export default pure(PortalSupport)
