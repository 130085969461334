import React from 'react'
// import PropTypes from 'prop-types'
import SegmentedControl from './segmented-control'
import { adaptToReduxForm } from 'utils'

const SegmentedControlInput = adaptToReduxForm()(SegmentedControl)

const operatorOptions = [
  { key: 'Any', value: 'or' },
  { key: 'All', value: 'and' },
]

function OperatorInput(props) {
  return <SegmentedControlInput {...{ options: operatorOptions, ...props }} />
}

export default OperatorInput
