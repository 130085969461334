import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { onMount, waitFor } from 'lp-hoc'
import { ContentBlock, FormBlock, PortalSupport } from 'components'
import { SignInForm } from '../forms'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { selectors as globalSelectors } from 'partner-portal-reducer'
import * as Types from 'types'
import { useCommunity, displaySubmitFailure } from 'utils'
import { PARTNER_PORTAL_PATH } from 'config'
import { get } from 'lodash'

const propTypes = {
  location: PropTypes.object.isRequired, // provided by react router
  signInSuccess: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  partnerOptions: PropTypes.arrayOf(Types.searchSuggestion),
}

const defaultProps = {
  partnerOptions: [],
}

function SignIn({ signInSuccess, partnerOptions, signIn, location }) {
  const community = useCommunity()
  const redirectUrl = get(location, 'state.redirectUrl')

  return (
    <ContentBlock>
      <FormBlock>
        <SignInForm
          onSubmit={
            // Pass params to onSubmitSuccess handler
            (params) =>
              signIn(params).then((response) => ({ ...params, ...response }))
          }
          partnerOptions={partnerOptions}
          onSubmitSuccess={(response) =>
            signInSuccess({ ...response, redirectUrl })
          }
          onSubmitFail={displaySubmitFailure}
          initialValues={{ portal: PARTNER_PORTAL_PATH }}
        />
      </FormBlock>
      <PortalSupport
        supportEmail={community.partnerSupportEmail}
        portalType={Types.PORTAL.PARTNER_PORTAL}
      />
    </ContentBlock>
  )
}

SignIn.propTypes = propTypes
SignIn.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    partnerOptions: globalSelectors.partnerOptions(state),
  }
}

const mapDispatchToProps = {
  signInSuccess: actions.signInSuccess,
  fetchPartnerOptions: apiActions.fetchPartnerOptions,
  signIn: apiActions.signIn,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  onMount('fetchPartnerOptions'),
  waitFor('partnerOptions')
)(SignIn)
