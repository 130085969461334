import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  message: PropTypes.string.isRequired,
}

function EmptyState({ message }) {
  return (
    <div className="empty-search">
      <p>{message}</p>
    </div>
  )
}

EmptyState.propTypes = propTypes

export default EmptyState
