import React from 'react'

function UnlockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#5B5F66" fillRule="nonzero" transform="translate(5 4)">
          <path d="M5 0c1.903 0 2.856 1.201 2.856 3.247v.988h1.286c.394 0 .857.457.858.847v5.506c0 .388-.306.804-.68.923l-.854.272A5.535 5.535 0 017.07 12H2.928c-.392 0-1.02-.098-1.394-.217l-.854-.272c-.374-.12-.68-.535-.68-.923V5.082c0-.39.32-.847.714-.847H1v.015h3v-.015h2.427v-1.27c0-1.02-.57-1.553-1.428-1.553-.509 0-.972.224-1.218.588H2.287C2.619.722 3.524 0 4.999 0z"></path>
        </g>
      </g>
    </svg>
  )
}

export default UnlockIcon
