import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useOnOutsideClick } from 'utils'
import ToolbarItem from './toolbar-item'
import classnames from 'classnames'
import { Manager, Reference, Popper } from 'react-popper'

const propTypes = {
  'aria-pressed': PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disableApply: PropTypes.bool,
  disableClear: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onApply: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  open: PropTypes.func.isRequired,
}

const defaultProps = {
  'aria-pressed': false,
  className: '',
  disableApply: false,
  disableClear: false,
}

const CLOSE_KEYS = ['27']
const NAVIGATION_KEYS = ['37', '39']

function ToolbarMenuItem({
  children,
  className,
  disableApply,
  disableClear,
  name,
  onApply,
  onClear,
  isOpen,
  open,
  close,
  'aria-pressed': ariaPressed,
  ...rest
}) {
  const refContainer = useOnOutsideClick(close)

  const maybeClose = useCallback(
    (e) => {
      const key = (e.which || e.keyCode || '').toString()
      if (!isOpen) return
      if (CLOSE_KEYS.includes(key) || NAVIGATION_KEYS.includes(key)) close(e)
    },
    [isOpen, close]
  )

  return (
    <div
      ref={refContainer}
      tabIndex="-1" // make focusable programmatically to allow key event listeners to be bound
      onKeyDown={maybeClose}
      className={classnames('toolbar-menu-item-container', className)}
    >
      <Manager>
        <Reference>
          {({ ref }) => (
            <ToolbarItem
              name={name}
              className="button-secondary-outline"
              aria-haspopup="true"
              aria-expanded={isOpen}
              onClick={(e) => {
                if (isOpen) return close(e)
                return open(e)
              }}
              aria-pressed={isOpen || ariaPressed}
              forwardedRef={ref}
              {...rest}
            />
          )}
        </Reference>
        {isOpen && (
          <Popper
            modifiers={{
              offset: { enabled: true, offset: '0, 40%' },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'viewport',
                padding: 25,
              },
            }}
            positionFixed={true}
            placement="bottom-start"
          >
            {({ ref, style, placement }) => (
              <div
                className="toolbar-menu-item-menu"
                ref={ref}
                style={style}
                data-placement={placement}
              >
                <div className="toolbar-menu-items-wrapper">{children}</div>
                <div className="toolbar-menu-item-actions">
                  <button
                    type="button"
                    className="link-primary"
                    onClick={() => {
                      onApply()
                      close()
                    }}
                    aria-disabled={disableApply}
                  >
                    Search
                  </button>
                  <button
                    type="button"
                    className="link-black"
                    onClick={() => {
                      onClear()
                      close()
                    }}
                    aria-disabled={disableClear}
                  >
                    Clear
                  </button>
                </div>
              </div>
            )}
          </Popper>
        )}
      </Manager>
    </div>
  )
}

ToolbarMenuItem.propTypes = propTypes
ToolbarMenuItem.defaultProps = defaultProps

export default ToolbarMenuItem
