import React from 'react'
// import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { modifyProps } from 'lp-hoc'
import {
  FieldArray,
  Field,
  arrayPush,
  propTypes as formPropTypes,
} from 'redux-form'
import { lpForm } from 'lp-form'
import {
  persistSubmitSucceeded,
  replaceResources,
  serializeOptions,
} from 'utils'
import { SubmitButton, Select } from 'lp-components'
import { startCase } from 'lodash'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

function disciplineForSubDiscipline(subDiscipline, disciplines) {
  return disciplines.find(({ subDisciplines }) =>
    subDisciplines.some(({ id }) => id === subDiscipline.subDisciplineId)
  )
}

function subDisciplineForId(subDisciplineId, subDisciplines) {
  return subDisciplines.find(({ id }) => id == subDisciplineId)
}

function SubDisciplineTagInput({ input: { value }, onClick, disciplineName }) {
  if (value.disciplineName !== disciplineName) return null
  return (
    <button type="button" onClick={onClick}>
      {value.displayName}
      <br />
      <span>{startCase(value.tag)}</span>
    </button>
  )
}

function SubDisciplineFields({ fields, disciplineName }) {
  return (
    <ul className="tags">
      <li />
      {fields.map((field, i) => (
        <li key={i}>
          <Field
            name={field}
            disciplineName={disciplineName}
            component={SubDisciplineTagInput}
            onClick={() => fields.remove(i)}
          />
        </li>
      ))}
    </ul>
  )
}

function RequestedDisciplinesForm({
  handleSubmit,
  submitSucceeded,
  pristine,
  submitting,
  disciplines,
  arrayPush,
  form,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <div className="row fields-with-tags">
        {disciplines.map(
          (
            {
              displayName: disciplineDisplayName,
              name: disciplineName,
              subDisciplines,
            },
            i
          ) => (
            <div key={i} className="col-2">
              <Select
                label={disciplineDisplayName}
                placeholder="Select"
                input={{
                  name: `select-${disciplineName}`,
                  value: '',
                  onChange: (e) => {
                    const subDisciplineId = e.target.value
                    const subDiscipline = subDisciplineForId(
                      subDisciplineId,
                      subDisciplines
                    )
                    return arrayPush(form, 'subDisciplines', {
                      subDisciplineId: subDiscipline.id,
                      displayName: subDiscipline.displayName,
                      disciplineName,
                    })
                  },
                }}
                meta={{}}
                options={serializeOptions(subDisciplines)}
              />
              <FieldArray
                name="subDisciplines"
                disciplineName={disciplineName}
                component={SubDisciplineFields}
              />
            </div>
          )
        )}
      </div>
      <div className="button-wrapper">
        <SubmitButton
          {...{ pristine: submitSucceeded && pristine, submitting }}
        >
          Save Response
        </SubmitButton>
      </div>
    </form>
  )
}

RequestedDisciplinesForm.propTypes = propTypes

RequestedDisciplinesForm.defaultProps = defaultProps

// Clear out old resources
function modifyBeforeSubmit({ initialValues }) {
  return {
    beforeSubmit: ({ subDisciplines, type }) => {
      const resources = replaceResources({
        old: initialValues.subDisciplines,
        new: subDisciplines,
      })
      return { subDisciplines: resources, type }
    },
  }
}

// Add discipline name to subdisciplines
function modifyInitialValues({ initialValues, disciplines }) {
  return {
    initialValues: {
      ...initialValues,
      type: Types.REQUEST_FORM_TYPE,
      subDisciplines: initialValues.subDisciplines.map((subDiscipline) => ({
        ...subDiscipline,
        disciplineName: disciplineForSubDiscipline(subDiscipline, disciplines)
          .name,
      })),
    },
  }
}

export default compose(
  modifyProps(modifyBeforeSubmit),
  modifyProps(modifyInitialValues),
  lpForm({
    name: 'school-disciplines',
    enableReinitialize: true,
  }),
  connect(null, { arrayPush }),
  persistSubmitSucceeded()
)(RequestedDisciplinesForm)
