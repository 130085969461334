import React from 'react'
import * as Types from 'types'
import { isEmpty } from 'lodash'

const propTypes = {
  program: Types.program.isRequired,
}

const defaultProps = {}

const displayTypes = (types, separator = ', ') => {
  if (isEmpty(types)) return <i>Information Not Provided</i>
  return types
    .map(({ displayName }) => displayName)
    .sort()
    .join(separator)
}

const displayGradeRange = (programGrades) => {
  if (isEmpty(programGrades)) return <i>Information Not Provided</i>

  const [first, second] = programGrades

  return first.gradeId === second.gradeId
    ? first.displayName
    : `${first.displayName} - ${second.displayName}`
}

function ProgramBlock({
  program: {
    id,
    name,
    description,
    subDisciplines,
    financialAssistanceTypes,
    outcomes,
    programGrades,
    programTypes,
  },
}) {
  return (
    <div key={id} className="banner-block program-block card">
      <div className="text-block">
        <h3>{name}</h3>
      </div>
      <div className="text-block">
        <p>{description}</p>
      </div>
      <div className="outcomes-block">
        <p>
          <strong>Program Type(s): </strong>
          {displayTypes(programTypes)}
        </p>
        <p>
          <strong>Sub Disciplines: </strong>
          {displayTypes(subDisciplines)}
        </p>
        <p>
          <strong>Qualities & Intended Outcomes: </strong>
          {displayTypes(outcomes)}
        </p>
        <p>
          <strong>Financial Support: </strong>
          {displayTypes(financialAssistanceTypes)}
        </p>
        <p>
          <strong>Program Grade Range: </strong>
          {displayGradeRange(programGrades)}
        </p>
      </div>
    </div>
  )
}

ProgramBlock.propTypes = propTypes
ProgramBlock.defaultProps = defaultProps

export default ProgramBlock
