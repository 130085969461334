import React, { useState, useCallback, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { waitFor, onMount } from 'lp-hoc'
import { Spinner } from 'lp-components'
import * as globalActions from 'partner-portal-actions'
import { SectionBlock, ErrorLoadState } from 'components'
import { PartnershipQuestionForm } from 'forms'
import { SchoolYearTabBar } from '../components'
import { PartnershipTableForm } from '../forms'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { selectors } from '../reducer'
import { selectors as apiSelectors } from 'lp-redux-api'
import { selectors as globalPartnerSelectors } from 'partner-portal-reducer'
import { selectors as globalSelectors } from 'global-reducer'
import * as Types from 'types'
import { displaySubmitFailure, useUnmount } from 'utils'
import * as flashActions from 'redux-flash'
import { reset, destroy } from 'redux-form'
import { mapValues, first, groupBy, get } from 'lodash'

const propTypes = {
  currentSchoolYear: Types.schoolYear.isRequired,
  destroyForms: PropTypes.func.isRequired,
  partner: Types.partner.isRequired,
  partnershipQuestion: Types.partnershipQuestion,
  schoolOptions: PropTypes.arrayOf(Types.searchSuggestion),
  setPartnerships: PropTypes.func.isRequired,
  setPartner: PropTypes.func.isRequired,
  updatePartner: PropTypes.func.isRequired,
  activeSchoolYears: PropTypes.arrayOf(Types.schoolYear).isRequired,
  programTypes: PropTypes.arrayOf(Types.programType),
  flashSuccessMessage: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  fetchPartnershipQuestion: PropTypes.func.isRequired,
  clearPartnershipQuestion: PropTypes.func.isRequired,
  isLoadingPartnershipQuestion: PropTypes.bool.isRequired,
  hasPartnershipQuestionLoadFailed: PropTypes.bool.isRequired,
}

const defaultProps = {
  updatedPartnerships: [],
}

function Partnerships({
  partner,
  partnershipQuestion,
  currentSchoolYear,
  destroyForms,
  schoolOptions,
  activeSchoolYears,
  programTypes,
  updatePartner,
  setPartner,
  flashSuccessMessage,
  resetForm,
  fetchPartnershipQuestion,
  clearPartnershipQuestion,
  isLoadingPartnershipQuestion,
  hasPartnershipQuestionLoadFailed,
}) {
  useEffect(() => {
    fetchPartnershipQuestion()

    return () => clearPartnershipQuestion()
  }, [])

  const [selectedSchoolYear, setSelectedSchoolYear] =
    useState(currentSchoolYear)
  const schoolYearsById = useMemo(() => {
    return mapValues(groupBy(activeSchoolYears, 'id'), first)
  }, [activeSchoolYears])
  // Allow partners to edit the current and previous school year
  const editableSchoolYearIds = useMemo(() => {
    return activeSchoolYears.slice(0, 2).map(({ id }) => id)
  }, [activeSchoolYears])

  const { id: partnerId, partnerships, openToPartnerships } = partner

  const updatePartnerForSchoolYear = useCallback(
    (params) => {
      return updatePartner(partnerId, selectedSchoolYear.id, params)
    },
    [partnerId, selectedSchoolYear.id]
  )

  const handleSuccess = (partner) => {
    setPartner(partner)
    flashSuccessMessage('Partnership information updated!')
  }

  // Do not destroy forms as the user navigates between school year tabs, only on unmount of the parent component
  useUnmount(() => {
    const tabFormNames = activeSchoolYears.map((year) => {
      return `partner-partnership-table-${year.id}`
    })
    destroyForms(...tabFormNames)
  })

  const partnershipsForSchoolYear = useMemo(() => {
    return partnerships.filter(
      ({ schoolYearId }) => schoolYearId === selectedSchoolYear.id
    )
  }, [partnerships, selectedSchoolYear])

  const previousPartnerships = useMemo(() => {
    // Not worth calculating previous partnerships for years that can't copy them over
    if (!editableSchoolYearIds.includes(selectedSchoolYear.id)) return []
    return partnerships.filter(({ schoolYearId }) => {
      const existingPosition = get(
        schoolYearsById,
        `${schoolYearId}.position`,
        0
      )
      return existingPosition < selectedSchoolYear.position
    })
  }, [partnerships, schoolYearsById, selectedSchoolYear, editableSchoolYearIds])

  const isEditable = editableSchoolYearIds.includes(selectedSchoolYear.id)

  if (isLoadingPartnershipQuestion) return <Spinner />

  return (
    <React.Fragment>
      {partnershipQuestion && (
        <section className="card">
          <PartnershipQuestionForm
            partnershipQuestion={partnershipQuestion}
            initialValues={{ openToPartnerships }}
            onSubmit={updatePartnerForSchoolYear}
            onSubmitSuccess={handleSuccess}
            onSubmitFail={displaySubmitFailure}
          />
        </section>
      )}
      {hasPartnershipQuestionLoadFailed && (
        <ErrorLoadState
          componentName="Looking for Partnerships form"
          headerAs="h2"
        />
      )}
      <div className="partnerships-year-select">
        <SchoolYearTabBar
          schoolYears={activeSchoolYears}
          schoolYear={selectedSchoolYear}
          onChange={setSelectedSchoolYear}
        />
        <SectionBlock>
          <PartnershipTableForm
            key={selectedSchoolYear.id} // important for triggering renders when switching tabs
            name={`partner-partnership-table-${selectedSchoolYear.id}`}
            programTypes={programTypes}
            initialValues={{ partnerships: partnershipsForSchoolYear }}
            onSubmit={updatePartnerForSchoolYear}
            onSubmitSuccess={(partner) => {
              resetForm(`partner-partnership-table-${selectedSchoolYear.id}`) // removes partnerships with _destroy flag
              handleSuccess(partner)
            }}
            onSubmitFail={displaySubmitFailure}
            disabled={!isEditable}
            previousPartnerships={previousPartnerships}
            schoolOptions={schoolOptions}
            schoolYears={activeSchoolYears}
            visibleSchoolYearId={selectedSchoolYear.id}
          />

          <div className="hint">
            <p>
              <strong>Tips</strong>
            </p>
            <p>
              Some schools have already reported their partnerships with us. If
              so, they will appear here. You may add or delete new partnerships
              created by you, but not partnerships reported to us by the
              schools. If you cannot find a specific school from our search box,
              please email us the school’s name and the types of arts
              programming you are providing to that school, and we will enter
              this information for you.
            </p>
          </div>
        </SectionBlock>
      </div>
    </React.Fragment>
  )
}

Partnerships.propTypes = propTypes
Partnerships.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    partner: globalPartnerSelectors.partner(state),
    partnershipQuestion: selectors.partnershipQuestion(state),
    programTypes: globalSelectors.orderedProgramTypes(state),
    currentPartnerId: globalPartnerSelectors.currentPartnerId(state),
    currentSchoolYear: globalSelectors.currentSchoolYear(state),
    activeSchoolYears: globalPartnerSelectors.orderedActiveSchoolYears(state),
    schoolOptions: globalPartnerSelectors.schoolOptions(state),
    isLoadingPartnershipQuestion: apiSelectors.isLoading(
      state,
      apiActions.fetchPartnershipQuestion
    ),
    hasPartnershipQuestionLoadFailed: apiSelectors.isFailure(
      state,
      apiActions.fetchPartnershipQuestion
    ),
  }
}

const mapDispatchToProps = {
  destroyForms: destroy,
  setPartnerships: actions.setPartnerships,
  fetchSchoolOptions: apiActions.fetchSchoolOptions,
  fetchPartnershipQuestion: apiActions.fetchPartnershipQuestion,
  clearPartnershipQuestion: actions.clearPartnershipQuestion,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  resetForm: reset,
  setPartner: globalActions.setPartner,
  updatePartner: apiActions.updatePartner,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  onMount('fetchSchoolOptions'),
  waitFor(['partner', 'schoolOptions'])
)(Partnerships)
