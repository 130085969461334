import isOtherType from './is-other-type'
import { negate } from 'lodash'

// Removes type with name "other" from array of types

function removeOtherType(types) {
  return types.filter(negate(isOtherType))
}

export default removeOtherType
