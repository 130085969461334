import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import * as apiActions from 'api-actions'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { modifyProps } from 'lp-hoc'
import { lpForm } from 'lp-form'
import {
  Field,
  propTypes as formPropTypes,
  formValueSelector,
} from 'redux-form'
import {
  Select,
  ButtonArea,
  Checkbox,
  SubmitButton,
  Spinner,
} from 'lp-components'
import { CheckboxGroup, HiddenLabel } from 'components'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors as globalSchoolSelectors } from 'school-portal-reducer'
import { isEmpty, map, startCase, lowerCase, omit, intersection } from 'lodash'
import {
  serializeOptions,
  formatObjectsToIds,
  parseIdsToObjects,
  filterUnchanged,
  useCommunity,
} from 'utils'

const propTypes = {
  fetchSchoolTypes: PropTypes.func.isRequired,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  schoolTypes: PropTypes.array,
  closePreferencesModal: PropTypes.func.isRequired,
  frequency: PropTypes.string.isRequired,
  allDisciplinesChecked: PropTypes.bool,
  allSchoolTypesChecked: PropTypes.bool,
  employmentPositions: PropTypes.arrayOf(PropTypes.string).isRequired,
  ...formPropTypes,
}

const defaultProps = {
  allDisciplinesChecked: false,
  allSchoolTypesChecked: false,
}

function NotificationsPreferencesForm({
  fetchSchoolTypes,
  handleSubmit,
  closePreferencesModal,
  frequency,
  disciplines,
  schoolTypes,
  submitting,
  initialValues,
  change,
  allDisciplinesChecked,
  allSchoolTypesChecked,
  employmentPositions,
}) {
  const community = useCommunity()
  const educatorEnabled = community.educatorEnabled

  const {
    authorizedSubscriptions,
    postCategories,
    postDisciplines,
    communityOfPracticePosts: initialCommunityOfPracticePosts,
    communityOfPracticeComments: initialCommunityOfPracticeComments,
    communityOfPracticeAttachments: initialCommunityOfPracticeAttachments,
    communityOfPracticeAnnouncements: initialCommunityOfPracticeAnnouncements,
  } = initialValues

  const startWithTaggedOptions = !isEmpty([
    ...postCategories,
    ...postDisciplines,
  ])
  const startWithActivityOptions =
    initialCommunityOfPracticePosts ||
    initialCommunityOfPracticeComments ||
    initialCommunityOfPracticeAttachments ||
    initialCommunityOfPracticeAnnouncements

  const [showTaggedOptions, setShowTaggedOptions] = useState(
    startWithTaggedOptions
  )
  const [showActivityOption, setShowActivityOption] = useState(
    startWithActivityOptions
  )

  const educatorPositions = intersection(
    employmentPositions,
    Types.EDUCATOR_POSITIONS
  )

  const showEducatorPreferences = educatorEnabled && !isEmpty(educatorPositions)
  const mapPositions = intersection(employmentPositions, Types.MAP_POSITIONS)

  useEffect(() => {
    // set initial value based on API initial values
    change('communityOfPracticeActivity', startWithActivityOptions)
  }, [startWithActivityOptions])

  useEffect(() => {
    // set initial value based on API initial values
    change('communityOfPracticeActivity', startWithActivityOptions)
  }, [startWithActivityOptions])

  useEffect(() => {
    fetchSchoolTypes()
  }, [])

  const frequencyOptions = map(Types.EMAIL_FREQUENCY, (value, key) => ({
    key: startCase(lowerCase(key)),
    value,
  }))

  const validateCoPActivitySelection = useCallback((value, allValues) => {
    const {
      communityOfPracticePosts,
      communityOfPracticeComments,
      communityOfPracticeAttachments,
      communityOfPracticeAnnouncements,
    } = allValues

    if (!value) return
    if (
      communityOfPracticePosts ||
      communityOfPracticeComments ||
      communityOfPracticeAttachments ||
      communityOfPracticeAnnouncements
    )
      return

    return 'At least one type of Community of Practice activity must be selected'
  }, [])

  const clearInterests = () => {
    change('allDisciplines', false)
    change('postDisciplines', [])
    change('allSchoolTypes', false)
    change('postCategories', [])
    change('commentsOnInterestedPosts', false)
  }

  const clearCoPActivity = () => {
    change('communityOfPracticePosts', false)
    change('communityOfPracticeComments', false)
    change('communityOfPracticeAttachments', false)
    change('communityOfPracticeAnnouncements', false)
  }

  if (!schoolTypes) return <Spinner />

  return (
    <div>
      <div>
        <h2>Email Notification Preferences</h2>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <Field
          name="emailFrequency"
          component={Select}
          options={frequencyOptions}
          onChange={() => {
            if (frequency === Types.EMAIL_FREQUENCY.NEVER) {
              setShowTaggedOptions(false)
              clearInterests()

              change('commentsOnOwnOrCommentedPosts', false)
              change('commentsOnBookmarkedPosts', false)

              setShowActivityOption(false)
              change('communityOfPracticeActivity', false)
              clearCoPActivity()
            }
          }}
          required
        />
        {frequency !== Types.EMAIL_FREQUENCY.NEVER && (
          <div className="preferences-wrapper">
            <legend>Send Me Email Notifications For:</legend>
            <div className="checkbox-container">
              {!isEmpty(mapPositions) && (
                <Field
                  name="partnershipMatches"
                  component={Checkbox}
                  label="New Partnership Matches"
                />
              )}
              {authorizedSubscriptions.includes(
                Types.SUBSCRIPTION_OPTIONS.NEW_PARTNERSHIPS.NAME
              ) && (
                <Field
                  name={Types.SUBSCRIPTION_OPTIONS.NEW_PARTNERSHIPS.NAME}
                  component={Checkbox}
                  label={Types.SUBSCRIPTION_OPTIONS.NEW_PARTNERSHIPS.LABEL}
                />
              )}
              {showEducatorPreferences && (
                <React.Fragment>
                  <Field
                    name="taggedWith"
                    value={showTaggedOptions}
                    checked={showTaggedOptions}
                    component={Checkbox}
                    label="New discussions tagged with..."
                    onChange={() => {
                      setShowTaggedOptions(!showTaggedOptions)
                      clearInterests()
                    }}
                  />
                  {showTaggedOptions && (
                    <div className="taggedOptions-wrapper">
                      <div className="taggedOptions-wrapper__top">
                        <div className="disciplines-wrapper">
                          <legend>Disciplines:</legend>
                          <Field
                            name="allDisciplines"
                            component={Checkbox}
                            onChange={(_, checked) => {
                              const newDisciplinesValue = checked
                                ? disciplines.map(({ id }) => ({
                                    disciplineId: id,
                                  }))
                                : []
                              change('postDisciplines', newDisciplinesValue)
                            }}
                          />
                          <Field
                            name="postDisciplines"
                            component={CheckboxGroup}
                            label="Specific disciplines"
                            labelComponent={HiddenLabel}
                            options={serializeOptions(disciplines)}
                            format={formatObjectsToIds('disciplineId')}
                            parse={parseIdsToObjects('disciplineId')}
                            disabled={allDisciplinesChecked}
                            required
                          />
                        </div>
                        <div className="schoolTypes-wrapper">
                          <legend>School Types:</legend>
                          <Field
                            name="allSchoolTypes"
                            component={Checkbox}
                            onChange={(_, checked) => {
                              const newCategoriesValue = checked
                                ? schoolTypes.map(({ id }) => ({
                                    schoolCategoryId: id,
                                  }))
                                : []
                              change('postCategories', newCategoriesValue)
                            }}
                          />
                          <Field
                            name="postCategories"
                            label="Specific school types"
                            labelComponent={HiddenLabel}
                            component={CheckboxGroup}
                            options={serializeOptions(schoolTypes)}
                            format={formatObjectsToIds('schoolCategoryId')}
                            parse={parseIdsToObjects('schoolCategoryId')}
                            disabled={allSchoolTypesChecked}
                            required
                          />
                        </div>
                      </div>
                      <div className="taggedOptions-wrapper__bottom">
                        <p className="taggedOptions-disc">
                          In order to be notified about matching discussions or
                          their comments, you must select at least one
                          Discipline and at least one School Type.
                        </p>
                        <Field
                          name="commentsOnInterestedPosts"
                          component={Checkbox}
                          label="New comments on these discussions."
                        />
                      </div>
                    </div>
                  )}

                  <Field
                    name="commentsOnOwnOrCommentedPosts"
                    component={Checkbox}
                    label="New comments in a discussion I started or have commented on."
                  />

                  <Field
                    name="postOrCommentLikes"
                    component={Checkbox}
                    label="When a discussion or comment I make is “liked”"
                  />

                  <Field
                    name="commentsOnBookmarkedPosts"
                    component={Checkbox}
                    label="New comments in a discussion I have bookmarked."
                  />

                  <Field
                    name="communityOfPracticeActivity"
                    component={Checkbox}
                    label="New activity in a Community of Practice I'm a member of."
                    onChange={() => {
                      setShowActivityOption(!showActivityOption)
                      clearCoPActivity()
                    }}
                    validate={validateCoPActivitySelection}
                  />
                  {showActivityOption && (
                    <div className="activity-options-wrapper">
                      <Field
                        name="communityOfPracticePosts"
                        component={Checkbox}
                        label="New discussions."
                      />
                      <Field
                        name="communityOfPracticeComments"
                        component={Checkbox}
                        label="New comments."
                      />
                      <Field
                        name="communityOfPracticeAttachments"
                        component={Checkbox}
                        label="New files uploaded."
                      />
                      <Field
                        name="communityOfPracticeAnnouncements"
                        component={Checkbox}
                        label="New moderator announcements."
                      />
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        )}

        <ButtonArea>
          <SubmitButton submitting={submitting}>Save</SubmitButton>
          <button
            type="button"
            className="button-grey-light"
            onClick={closePreferencesModal}
          >
            Cancel
          </button>
        </ButtonArea>
      </form>
    </div>
  )
}

NotificationsPreferencesForm.propTypes = propTypes
NotificationsPreferencesForm.defaultProps = defaultProps

function modifyBeforeSubmit({ initialValues }) {
  return {
    beforeSubmit: ({ postDisciplines, postCategories, ...rest }) => {
      const disciplines = filterUnchanged({
        initial: initialValues.postDisciplines,
        new: postDisciplines,
        key: 'disciplineId',
        skipDeepEquality: true,
      })
      const categories = filterUnchanged({
        initial: initialValues.postCategories,
        new: postCategories,
        key: 'schoolCategoryId',
        skipDeepEquality: true,
      })

      const params = omit(rest, [
        'taggedWith',
        'allDisciplines',
        'allSchoolTypes',
        'communityOfPracticeActivity',
      ])

      return {
        notificationSubscriptionPostDisciplinesAttributes: disciplines,
        notificationSubscriptionPostCategoriesAttributes: categories,
        ...params,
      }
    },
  }
}

const preferencesSelector = formValueSelector('NotificationsPreferencesForm')

function mapStateToProps(state) {
  return {
    frequency: preferencesSelector(state, 'emailFrequency'),
    allDisciplinesChecked: preferencesSelector(state, 'allDisciplines'),
    allSchoolTypesChecked: preferencesSelector(state, 'allSchoolTypes'),
    disciplines: globalSelectors.definedDisciplines(state),
    schoolTypes: globalSchoolSelectors.definedSchoolTypes(state),
  }
}

const mapDispatchToProps = {
  fetchSchoolTypes: apiActions.fetchSchoolTypes,
}

export default compose(
  modifyProps(modifyBeforeSubmit),
  lpForm({
    name: 'NotificationsPreferencesForm',
    constraints: {
      emailFrequency: { presence: true },
      postDisciplines: {
        presence: { message: '^At least one Discipline must be selected' },
      },
      postCategories: {
        presence: { message: '^At least one School Type must be selected' },
      },
    },
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(NotificationsPreferencesForm)
