import { sanitizeUrl } from '@braintree/sanitize-url'

const ALLOWED_SCHEME_PREFIXES = ['mailto:', 'tel:']
export const DEFAULT_SAFE_URL = 'about:blank'
export const DEFAULT_PROTOCOL = 'https://'

function sanitizeExternalUrl(url = '') {
  const sanitizedUrl = sanitizeUrl(url)

  if (
    sanitizedUrl === DEFAULT_SAFE_URL ||
    isRelativeUrl(sanitizedUrl) ||
    hasAllowedUrlScheme(sanitizedUrl) ||
    hasHttpProtocol(sanitizedUrl)
  )
    return sanitizedUrl

  return DEFAULT_PROTOCOL + sanitizedUrl
}

// ----- PRIVATE -----

function hasAllowedUrlScheme(url) {
  return ALLOWED_SCHEME_PREFIXES.some((prefix) =>
    url.toLowerCase().startsWith(prefix)
  )
}

function hasHttpProtocol(url) {
  return /^http(s?):\/\//.test(url)
}

function isRelativeUrl(url) {
  return url.startsWith('/')
}

export default sanitizeExternalUrl
