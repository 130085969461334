import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { waitFor } from 'lp-hoc'
import { SectionBlock, SectionHeader } from 'components'
import { ReportListItem } from '../components'
import { selectors as globalSelectors } from 'partner-portal-reducer'
import * as Types from 'types'

const propTypes = {
  partner: Types.partner.isRequired,
  reportYears: PropTypes.arrayOf(PropTypes.string).isRequired,
}

function Reports({ partner, reportYears }) {
  return (
    <SectionBlock>
      <SectionHeader>
        <h3>artlook Profile Report</h3>
        <p>
          The artlook Partners Profile report will summarize your current
          program offerings in schools and will serve as a useful resource for
          you to share with schools, arts liaisons, parents, funders, and board
          members to grow your programs. It is populated by the arts partnership
          data supplied on this site in conjunction with CPS data procured by
          Ingenuity’s data-sharing agreement with the District.
        </p>
        <p>
          The numbers shown on the artlook Partners Profile report sum the
          self-reported information entered by your organization (e.g. number of
          partnerships, programs) and are a quantitative roll-up of your
          services. This report does not assess quality of programs or
          partnerships. It is meant to be used for informational purposes only.
        </p>
      </SectionHeader>
      <ul className="reports-block">
        {reportYears.map((year, i) => (
          <ReportListItem key={i} years={year} id={partner.id} />
        ))}
      </ul>
    </SectionBlock>
  )
}

Reports.propTypes = propTypes

function mapStateToProps(state) {
  return {
    partner: globalSelectors.partner(state),
    reportYears: globalSelectors.reportYears(state),
  }
}

export default compose(connect(mapStateToProps), waitFor('partner'))(Reports)
