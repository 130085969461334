import { template, templateSettings } from 'lodash'

// Set the template to interpolate variables between two brackets (a la mustache)
templateSettings.interpolate = /{{([\s\S]+?)}}/g

// Returns interpolated string
function interpolateText(text, options) {
  return template(text)(options)
}

export default interpolateText
