import { isEmpty } from 'lodash'

// Returns array of resources with the "Other" option if it was populated

function combineOtherOption(definedResources, otherResource) {
  if (!otherResource || isEmpty(otherResource.otherText))
    return definedResources
  if (!definedResources) return [otherResource]
  return [...definedResources, otherResource]
}

export default combineOtherOption
