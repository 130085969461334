import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withHandlers } from 'recompose'
import { lpForm } from 'lp-form'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { modifyProps } from 'lp-hoc'
import { SubmitButton } from 'lp-components'
import {
  formatObjectsToIds,
  parseIdsToObjects,
  replaceResources,
  persistSubmitSucceeded,
  getNoneTypeId,
} from 'utils'
import { CheckboxGroup } from 'components'
import * as Types from 'types'
import { startCase, pickBy, isNumber, values } from 'lodash'

const propTypes = {
  ...formPropTypes,
  options: PropTypes.arrayOf(Types.formOptionType),
}

function serializeEngagementTypeOptions(engagementTypes) {
  return engagementTypes.map(({ id, name }) => ({
    key: startCase(name),
    value: id,
  }))
}

// Convert object with numerical values to an array of numbers
function objToArray(obj) {
  if (!obj) return []
  return values(pickBy(obj, isNumber))
}

function ArtsEngagementForm({
  handleSubmit,
  isSaving,
  engagementTypes,
  saved,
  submitting,
  noneTypeId,
  selectNoneOption,
  deselectNoneOption,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <div className="floating-checkboxes">
        <fieldset disabled={isSaving}>
          <Field
            name="engagements"
            label={false}
            component={CheckboxGroup}
            options={serializeEngagementTypeOptions(engagementTypes)}
            format={formatObjectsToIds('engagementTypeId')}
            parse={parseIdsToObjects('engagementTypeId')}
            onChange={(value) => {
              // Deselect other values if None type is selected
              const arrayValue = objToArray(value)
              if (!arrayValue.includes(noneTypeId)) return
              const lastSelectedValue = arrayValue[0]
              const didSelectNone = lastSelectedValue === noneTypeId
              return didSelectNone
                ? selectNoneOption()
                : deselectNoneOption(arrayValue)
            }}
          />
        </fieldset>
      </div>
      <div className="button-wrapper">
        <SubmitButton {...{ pristine: saved, submitting }}>
          Save Response
        </SubmitButton>
      </div>
    </form>
  )
}

ArtsEngagementForm.propTypes = propTypes

function getSpecialIds({ engagementTypes }) {
  return {
    // otherTypeId: getOtherTypeId(engagementTypes),
    noneTypeId: getNoneTypeId(engagementTypes),
  }
}

function modifyHandlers() {
  return {
    selectNoneOption:
      ({ change, noneTypeId }) =>
      () => {
        setTimeout(() =>
          change(
            'engagements',
            parseIdsToObjects('engagementTypeId', [noneTypeId]),
            0
          )
        )
      },
    deselectNoneOption:
      ({ change, noneTypeId }) =>
      (values) => {
        const otherOptions = values.filter((val) => val !== noneTypeId)
        setTimeout(() =>
          change(
            'engagements',
            parseIdsToObjects('engagementTypeId', otherOptions),
            0
          )
        )
      },
  }
}

function modifyBeforeSubmit({ initialValues }) {
  return {
    beforeSubmit: ({ engagements }) => {
      const resources = replaceResources({
        old: initialValues.engagements,
        new: engagements,
      })
      return { engagements: resources }
    },
  }
}

export default compose(
  modifyProps(getSpecialIds),
  modifyProps(modifyBeforeSubmit),
  lpForm({
    name: 'school-arts-engagement',
    enableReinitialize: true,
    constraints: {
      engagements: {
        presence: {
          message: '^You must select at least one option',
        },
      },
    },
  }),
  withHandlers(modifyHandlers),
  persistSubmitSucceeded()
)(ArtsEngagementForm)
