import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { formatDate } from 'utils'

const propTypes = {
  date: PropTypes.string.isRequired,
}

const defaultProps = {}

function PostedDate({ date }) {
  return (
    <span className="posted-date">
      <em>Posted {formatDate(date)}</em>
    </span>
  )
}

PostedDate.propTypes = exact(propTypes)
PostedDate.defaultProps = defaultProps

export default pure(PostedDate)
