import React from 'react'
import PropTypes from 'prop-types'
import SchoolSearchResult from './school-search-result'
import PartnerSearchResult from './partner-search-result'
import * as Types from 'types'
import { ScrollableListItem } from 'components'
import classnames from 'classnames'

const propTypes = {
  result: Types.searchResult.isRequired,
  isSelected: PropTypes.bool,
}

const defaultProps = {
  isSelected: false,
}

function SearchResultsListItem({ result, isSelected, ...rest }) {
  return (
    <ScrollableListItem
      name={`result-${result.id}`}
      className={classnames('result-block card', {
        highlight: isSelected,
      })}
      data-cy="search-result"
    >
      {result.type === Types.SCHOOL_TYPE ? (
        <SchoolSearchResult school={result} {...rest} />
      ) : (
        <PartnerSearchResult partner={result} {...rest} />
      )}
    </ScrollableListItem>
  )
}

SearchResultsListItem.propTypes = propTypes

SearchResultsListItem.defaultProps = defaultProps

export default SearchResultsListItem
