import React from 'react'

function MentoringIcon() {
  return (
    <svg viewBox="0 0 50 50">
      <path
        fill="#204A82"
        className="fill"
        d="M50,25A25,25,0,1,1,25,0,25,25,0,0,1,50,25Z"
      />
      <path
        fill="#FFFFFF"
        d="M35.35,24.94a1.46,1.46,0,1,1-1.46-1.46A1.46,1.46,0,0,1,35.35,24.94Z"
      />
      <path
        fill="#FFFFFF"
        d="M3.64,27h.88l.09.05.67-2.95s1.57-4.61,2.15-5.19h-1v-.79h-.9v.79a1.79,1.79,0,0,0-1.76,1.83v1.31H3.61a1.06,1.06,0,0,0-1,1.08v.15H3.82v.9H2.56v1.73A1.08,1.08,0,0,0,3.64,27Z"
      />
      <path
        fill="#FFFFFF"
        d="M17.27,27.83a.74.74,0,0,0,.14-1.13l-2.26-2.62-.8-1.23-.28,2.79,2.1,2C16.51,28,16.85,28.13,17.27,27.83Z"
      />
      <path
        fill="#FFFFFF"
        d="M6.78,24.08,5.69,28.89c-.2.95.22,1.13.37,1.2a.62.62,0,0,0,.51,0,1.15,1.15,0,0,0,.53-.74l.32-1L8,26.43,8.47,25l1.61-3.41a3,3,0,0,0,.18-.39c0-.09.06-.18.09-.27a1.91,1.91,0,0,0,0-1.19,1,1,0,0,0-.07-.15l-.09-.14a1,1,0,0,0-1-.27l-.13,0a2.24,2.24,0,0,0-.64.8L8,21l-.9,2.25Z"
      />
      <path
        fill="#FFFFFF"
        d="M10.57,17.57a2.38,2.38,0,1,0-2.38-2.38A2.38,2.38,0,0,0,10.57,17.57Z"
      />
      <path
        fill="#FFFFFF"
        d="M26.58,5.9l1.82,0V9.43l5.47-4.11,5.47,4.12V5.92l1.79,0a25,25,0,0,0-9.6-5l-5,4.32Z"
      />
      <path
        fill="#FFFFFF"
        d="M46.73,20.74h2.9c-.07-.39-.14-.78-.23-1.16L33.87,7.9,17.81,20v.85h3V39.14H13.47l1-6.07s-2.06-5.44-2.06-5.47L13.06,21a1.84,1.84,0,0,0,0-.33.15.15,0,0,0,0-.07,1.94,1.94,0,0,0-.07-.33v0a2.08,2.08,0,0,0-.12-.32h0a2.33,2.33,0,0,0-1.26-1.12h0l0,0a3.13,3.13,0,0,1,.15.32,3.27,3.27,0,0,1,0,2.13,3,3,0,0,1-.11.37,3.64,3.64,0,0,1-.24.54L9.84,25.5l-.42,1.37-.59,1.9,0,.13,2.62,4.55-.61,5.61v.08H6.31l3.25-5.27.16-.39L8.1,30.66a2.28,2.28,0,0,1-.81.72L7,32.86,3.83,38.29a25.8,25.8,0,0,0,2.93,3.8H43.24a25,25,0,0,0,3.49-4.73ZM35.67,39.14v-5a1.43,1.43,0,0,0,1-1.38V28.2a1.44,1.44,0,0,0-1.44-1.44h-.64v1.35h0a.84.84,0,0,1,.84.85v3a.84.84,0,0,1-.84.84H33.2a.85.85,0,0,1-.85-.84V29a.84.84,0,0,1,.8-.84V26.76h-.66a1.45,1.45,0,0,0-1.44,1.44v4.53a1.45,1.45,0,0,0,1.07,1.39v5H29V21.66h9.82V39.14Z"
      />
    </svg>
  )
}

export default MentoringIcon
