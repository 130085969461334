import React from 'react'
import PropTypes from 'prop-types'
import SuccessImage from 'images/checkmark-circle-green.svg'
import FailImage from 'images/cross-circle-red.svg'
// import WarnImage from 'images/warn-circle-grey.svg'

const propTypes = {
  saveSucceeded: PropTypes.bool,
  saveFailed: PropTypes.bool,
  isSaving: PropTypes.bool,
}

function AutoSaveStatusIndicator({ saveSucceeded, saveFailed, isSaving }) {
  if (saveSucceeded) return <IndicatorSucceeded />
  if (saveFailed) return <IndicatorFailed />
  if (isSaving) return <IndicatorIsSaving />
  return null
}

function IndicatorSucceeded() {
  return (
    <div className="autosave save-succeeded">
      <div className="text-block">
        <img src={SuccessImage} alt="Success Icon" />
        <p> Saved </p>
      </div>
    </div>
  )
}

function IndicatorFailed() {
  return (
    <div className="autosave save-failed">
      <div className="text-block">
        <img src={FailImage} alt="Failed Icon" />
        <p> Save Failed </p>
      </div>
    </div>
  )
}

function IndicatorIsSaving() {
  return (
    <div className="autosave is-saving">
      <div className="text-block">
        <div id="spinner"></div>
        <p> Saving </p>
      </div>
    </div>
  )
}

AutoSaveStatusIndicator.propTypes = propTypes

export default AutoSaveStatusIndicator
