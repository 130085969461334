import React, { useMemo } from 'react'
import * as Types from 'types'
import PropTypes from 'prop-types'
import { startCase, values } from 'lodash'

const propTypes = {
  highlightName: PropTypes.string.isRequired,
  highlightValue: PropTypes.string.isRequired,
}

const defaultProps = {}

function HighlightText({ highlightName, highlightValue }) {
  const showEllipsis = useMemo(
    () => values(Types.HIGHLIGHT_EXCERPT_NAMES).includes(highlightName),
    [highlightName]
  )

  return (
    <li>
      <strong>{startCase(highlightName)}: </strong>
      {showEllipsis && '...'}
      <span dangerouslySetInnerHTML={{ __html: highlightValue.trim() }} />
      {showEllipsis && '...'}
    </li>
  )
}
HighlightText.propTypes = propTypes

HighlightText.defaultProps = defaultProps

export default HighlightText
