import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import {
  SelectAllCheckboxGroup,
  FilterMenuItem,
  SearchableCheckboxGroups,
} from 'components'
import { CheckboxGroup } from 'lp-components'
import { mapValues, size, startCase } from 'lodash'
import { formatOptions, sortedAndFormattedOptions } from 'utils'

const propTypes = {
  filterOptions: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
}

const defaultProps = {}

function PartnerSearchFilterFields({
  filterOptions,
  formName,
  ...filterMenuProps
}) {
  const programDisciplinesOptionGroups = useMemo(() => {
    return mapValues(filterOptions.subDisciplines, sortedAndFormattedOptions)
  }, [filterOptions.subDisciplines])

  return (
    <React.Fragment>
      {size(filterOptions.disciplines) > 1 && (
        <FilterMenuItem name="partnerDisciplines" {...filterMenuProps}>
          <Field
            name="partnerDisciplines"
            options={formatOptions(filterOptions.disciplines)}
            component={CheckboxGroup}
            labelComponent={CheckboxGroupLegend}
          />
        </FilterMenuItem>
      )}
      {size(filterOptions.partnersCount) > 1 && (
        <FilterMenuItem
          name="schoolPartnersCount"
          displayName="Number of School Partners"
          {...filterMenuProps}
        >
          <Field
            name="schoolPartnersCount"
            options={formatOptions(filterOptions.partnersCount)}
            component={CheckboxGroup}
            label="Number of School Partners"
            labelComponent={CheckboxGroupLegend}
          />
        </FilterMenuItem>
      )}
      {size(filterOptions.programTypes) > 1 && (
        <FilterMenuItem name="programTypes" {...filterMenuProps}>
          <Field
            name="programTypes"
            options={formatOptions(filterOptions.programTypes)}
            component={CheckboxGroup}
            labelComponent={CheckboxGroupLegend}
          />
        </FilterMenuItem>
      )}
      {size(filterOptions.subDisciplines) > 1 && (
        <FilterMenuItem
          name="programDisciplines"
          {...filterMenuProps}
          className="large-width full-height"
        >
          <SearchableCheckboxGroups
            formName={formName}
            formPath="filters.programDisciplines"
            groupLabel="Program Disciplines"
            optionGroups={programDisciplinesOptionGroups}
            headingLevel="3"
            component={SelectAllCheckboxGroup}
            className="filter-search"
          />
        </FilterMenuItem>
      )}
      {size(filterOptions.gradesServed) > 1 && (
        <FilterMenuItem
          name="gradesServed"
          {...filterMenuProps}
          className="large-width full-height"
        >
          <Field
            name="gradesServed"
            options={formatOptions(filterOptions.gradesServed)}
            component={SelectAllCheckboxGroup}
            labelComponent={CheckboxGroupLegend}
          />
        </FilterMenuItem>
      )}
      {size(filterOptions.outcomeTypes) > 1 && (
        <FilterMenuItem
          name="outcomeTypes"
          displayName="Qualities and Intended Outcomes"
          {...filterMenuProps}
          className="large-width full-height"
        >
          <Field
            name="outcomeTypes"
            options={formatOptions(filterOptions.outcomeTypes)}
            component={CheckboxGroup}
            label="Qualities and Intended Outcomes"
            labelComponent={CheckboxGroupLegend}
          />
        </FilterMenuItem>
      )}
      {size(filterOptions.financialAssistanceTypes) > 1 && (
        <FilterMenuItem
          name="financialAssistanceTypes"
          displayName="Financial Support"
          {...filterMenuProps}
          className="large-width full-height"
        >
          <Field
            name="financialAssistanceTypes"
            options={formatOptions(filterOptions.financialAssistanceTypes)}
            component={CheckboxGroup}
            label="Financial Support"
            labelComponent={CheckboxGroupLegend}
          />
        </FilterMenuItem>
      )}
      {filterOptions.openToPartnerships && (
        <FilterMenuItem
          name="openToPartnerships"
          displayName="Looking for Partnerships?"
          isBooleanFilter
          {...filterMenuProps}
        >
          <Field
            name="openToPartnerships"
            component={CheckboxGroup}
            label="Looking for Partnerships?"
            labelComponent={CheckboxGroupLegend}
            options={formatOptions(filterOptions.openToPartnerships)}
          />
        </FilterMenuItem>
      )}
    </React.Fragment>
  )
}

function CheckboxGroupLegend({ label, name }) {
  return <legend className="visually-hidden">{label || startCase(name)}</legend>
}

PartnerSearchFilterFields.propTypes = propTypes
PartnerSearchFilterFields.defaultProps = defaultProps

export default PartnerSearchFilterFields
