import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { onMount } from 'lp-hoc'
import {
  ContentBlock,
  FormBlock,
  PortalSupport,
  ExternalLink,
} from 'components'
import { SignInForm } from '../forms'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { selectors as globalSelectors } from 'school-portal-reducer'
import * as Types from 'types'
import { SCHOOL_PORTAL_PATH } from 'config'
import { useCommunity, apiUrlBuilder, displaySubmitFailure } from 'utils'
import { get, isEmpty } from 'lodash'
import backArrow from 'images/back.svg'
import * as LS from 'local-storage'

const propTypes = {
  location: PropTypes.object.isRequired, // provided by react router
  signIn: PropTypes.func.isRequired,
  signInSuccess: PropTypes.func.isRequired,
  schoolOptions: PropTypes.arrayOf(Types.searchSuggestion),
  communityConfigSso: PropTypes.object,
}

const defaultProps = {
  schoolOptions: [],
  communityConfigSso: {},
}

function SignIn({
  signInSuccess,
  schoolOptions,
  signIn,
  communityConfigSso,
  location,
}) {
  const community = useCommunity()
  const { subdomain } = community
  const { href, title, text, logo } = communityConfigSso
  const ssoEnabled = !isEmpty(communityConfigSso)

  const [displayEmailLogin, setDisplayEmailLogin] = useState(true)

  useEffect(() => {
    setDisplayEmailLogin(!ssoEnabled)
  }, [ssoEnabled])

  if (!schoolOptions.length) return null

  const redirectUrl = get(location, 'state.redirectUrl')

  return (
    <ContentBlock>
      <FormBlock>
        <div className="form-block-inner">
          <h1>Sign in to your school account</h1>
          {ssoEnabled && (
            <div className="sign-in-options">
              {displayEmailLogin ? (
                <button
                  type="button"
                  onClick={() => setDisplayEmailLogin(false)}
                  className="link-black back"
                >
                  <img alt="" src={backArrow} />
                  Back to Sign In Options
                </button>
              ) : (
                <React.Fragment>
                  <p>
                    <ExternalLink
                      href={`${apiUrlBuilder(subdomain) + href}`}
                      title={title}
                      className="button-primary sso-login"
                      onClick={() => {
                        LS.setSsoRedirect(redirectUrl)
                        return true
                      }}
                    >
                      {logo && <img alt="" src={logo.secureUrl} />}
                      {text}
                    </ExternalLink>
                  </p>
                  <p>
                    <button
                      type="button"
                      className="button-primary-outline"
                      onClick={() => setDisplayEmailLogin(true)}
                      data-cy="sign-in-with-email"
                    >
                      Sign in with Email
                    </button>
                  </p>
                </React.Fragment>
              )}
            </div>
          )}
          {displayEmailLogin && (
            <SignInForm
              onSubmit={
                // Pass params to onSubmitSuccess handler
                (params) =>
                  signIn(params).then((response) => ({
                    ...params,
                    ...response,
                  }))
              }
              schoolOptions={schoolOptions}
              onSubmitSuccess={(response) =>
                signInSuccess({ ...response, redirectUrl })
              }
              onSubmitFail={displaySubmitFailure}
              initialValues={{ portal: SCHOOL_PORTAL_PATH }}
            />
          )}
        </div>
      </FormBlock>
      <PortalSupport
        supportEmail={community.schoolSupportEmail}
        portalType={Types.PORTAL.SCHOOL_PORTAL}
      />
    </ContentBlock>
  )
}

SignIn.propTypes = propTypes
SignIn.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    schoolOptions: globalSelectors.schoolOptions(state),
    communityConfigSso: globalSelectors.communityConfigSso(state),
  }
}

const mapDispatchToProps = {
  signInSuccess: actions.signInSuccess,
  fetchSchoolOptions: apiActions.fetchSchoolOptions,
  signIn: apiActions.signIn,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  onMount('fetchSchoolOptions')
)(SignIn)
