import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as actions from '../actions'

const propTypes = {
  signOut: PropTypes.func.isRequired,
}

const defaultProps = {}

/**
 * A view that forces the user to log out onMount. This is preferred over
 * dispatching a series of actions since those will not be stopped by nooping a
 * route change in the `onChange` handler of the app's parent route.
 *
 * The primary use case for this behavior is warning someone when they attempt
 * to log out with unsaved form changes.
 */
function SignOut({ signOut }) {
  useEffect(() => {
    signOut()
  }, [])

  return null
}

SignOut.propTypes = propTypes
SignOut.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  signOut: actions.signOut,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SignOut)
