import { get, isEqual, differenceBy } from 'lodash'
import { set } from 'lodash/fp'

// Filter out existing resources that were not removed or edited
function filterUnchanged({
  initial: initialResources = [],
  new: newResources = [],
  key = 'id',
  skipDeepEquality = false,
}) {
  const removedResources = differenceBy(initialResources, newResources, key)
  const newAndUpdatedResources = newResources.reduce((acc, resource) => {
    if (!get(resource, key)) return [...acc, resource]

    const existingInitialResource = initialResources.find(
      (initialResource) => get(initialResource, key) === get(resource, key)
    )

    if (
      existingInitialResource &&
      (skipDeepEquality || isEqual(existingInitialResource, resource))
    )
      return acc

    return [...acc, resource]
  }, [])

  return [...markForRemoval(removedResources), ...newAndUpdatedResources]
}

// ----- PRIVATE -----

function markForRemoval(resources) {
  return resources.map(set('_destroy', true))
}

export default filterUnchanged
