import React from 'react'
// import PropTypes from 'prop-types'
import CommunityLayout from '../../community-layout'

const propTypes = {}

const defaultProps = {}

// Template for adding custom content to append to a community's map homepage
function Example() {
  return <CommunityLayout>{/* Insert content here */}</CommunityLayout>
}

Example.propTypes = propTypes
Example.defaultProps = defaultProps

export default Example
