import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { InputLabel } from 'lp-components'

const propTypes = {
  instructions: PropTypes.string,
}

const defaultProps = {
  instructions: '',
}

function AutoSuggestInputLabel({ instructions, ...rest }) {
  return (
    <React.Fragment>
      <InputLabel {...rest} />
      {instructions && <p id="auto-suggest-instructions">{instructions}</p>}
    </React.Fragment>
  )
}

AutoSuggestInputLabel.propTypes = propTypes
AutoSuggestInputLabel.defaultProps = defaultProps

export default pure(AutoSuggestInputLabel)
