import { isArray } from 'lodash'

/**
 * @name filterCoursesByQuestionCategory
 * @param {Array} courses - List of courses
 * @param {String|Array} category - Name or list of names pertaining to the question's category(ies)
 * @returns {Array} - Returns list of courses that apply to the question category(ies)
 */

function filterCoursesByQuestionCategory(courses, category) {
  const categories = isArray(category) ? category : [category]
  return courses.filter(({ gradesOfferedTo }) => {
    return categories.includes(gradesOfferedTo)
  })
}

export default filterCoursesByQuestionCategory
