import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.node.isRequired,
  'aria-controls': PropTypes.string,
}

const defaultProps = {
  'aria-controls': null,
}

function Toolbar({ children, ...rest }) {
  const toolbarContainer = useRef(null)

  useEffect(() => {
    if (!toolbarContainer) return
    const firstToolbarItem =
      toolbarContainer.current.querySelector('.toolbar-item')

    if (!firstToolbarItem) return
    firstToolbarItem.setAttribute('tabindex', '0')
  }, [toolbarContainer])

  return (
    <div {...rest} role="toolbar" ref={toolbarContainer}>
      {children}
    </div>
  )
}

Toolbar.propTypes = propTypes
Toolbar.defaultProps = defaultProps

export default Toolbar
