// import { createAction } from 'redux-actions'
import { push } from 'react-router-redux'
import * as globalActions from 'partner-portal-actions'
import { PARTNER_PORTAL_ROUTE } from 'config'

export function signInSuccess({
  token,
  employerId,
  persistSession,
  portal, // eslint-disable-line no-unused-vars
  password, // eslint-disable-line no-unused-vars
  redirectUrl,
  ...userDetails
}) {
  return function (dispatch) {
    dispatch(globalActions.setAuthenticated({ token, persistSession }))
    dispatch(globalActions.setCurrentPartnerId(employerId))
    dispatch(globalActions.setUserDetails(userDetails))
    dispatch(push(redirectUrl || PARTNER_PORTAL_ROUTE))
  }
}
