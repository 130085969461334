import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { Link } from 'react-scroll'
import { startCase } from 'lodash'

const propTypes = {
  children: PropTypes.node.isRequired,
  'aria-label': PropTypes.string,
  to: PropTypes.string.isRequired,
}

const defaultProps = {
  'aria-label': '',
}

// Wrapper that includes a11y wins
function ScrollLink({ children, to, 'aria-label': ariaLabel, ...rest }) {
  const jumpToLabel = 'Jump to ' + formattedLabel({ ariaLabel, to })
  return (
    <Link href="" {...rest} to={to} aria-label={jumpToLabel}>
      {children}
    </Link>
  )
}

function formattedLabel({ to, ariaLabel }) {
  if (!ariaLabel) return startCase(to)
  return ariaLabel
}

ScrollLink.propTypes = propTypes
ScrollLink.defaultProps = defaultProps

export default pure(ScrollLink)
