import React from 'react'
import PropTypes from 'prop-types'
import { compose, withState } from 'recompose'
import AutoSuggestInput from './auto-suggest-input'
import AutoSuggestInputLabel from './auto-suggest-input-label'
import {
  fieldPropTypes,
  LabeledField,
  hasInputError,
  generateInputErrorId,
} from 'lp-components'
import * as Types from 'types'
import classnames from 'classnames'

const propTypes = {
  ...fieldPropTypes,
  instructions: PropTypes.string,
  suggestions: PropTypes.arrayOf(Types.searchSuggestion),
  internalValue: PropTypes.string.isRequired,
  setInternalValue: PropTypes.func.isRequired,
}

const defaultProps = {
  instructions: '',
  suggestions: [],
}

// Return a suggestion object that matches a string value
function getSuggestionForValue(suggestions, value) {
  if (!value) return
  return suggestions.find(({ name }) => name === value)
}

// Only return the id if AutoSuggestInputLabel is being used with instructions
function getDefaultDescribedById(labelComponent, instructions) {
  if (labelComponent || !instructions) return
  return 'auto-suggest-instructions'
}

function PartnerAutoSuggestInput({
  input: { name, value, onChange, onBlur },
  input,
  meta,
  suggestions,
  internalValue,
  setInternalValue,
  'aria-describedby': ariaDescribedBy,
  'aria-labelledby': ariaLabelledBy,
  'aria-label': ariaLabel,
  instructions,
  labelComponent,
  label,
  ...rest
}) {
  const allAriaDescribedBy = classnames(
    ariaDescribedBy || getDefaultDescribedById(labelComponent, instructions),
    { [generateInputErrorId(name)]: hasInputError(meta) }
  )
  return (
    <LabeledField
      {...{
        input,
        meta,
        name,
        instructions,
        labelComponent: labelComponent || AutoSuggestInputLabel,
        label,
        ...rest,
      }}
    >
      <AutoSuggestInput
        input={{
          // Use separate internal value
          id: name,
          name,
          value: internalValue,
          onChange: (e, { newValue, method }) => {
            // Ignore dropdown click
            if (method === 'click') return
            // Update internal value
            setInternalValue(newValue)
            // If new value matches a suggestion, call onChange with that suggestion
            const matchingSuggestion = getSuggestionForValue(
              suggestions,
              newValue
            )
            return onChange(matchingSuggestion || '')
          },
          onBlur: (e, { highlightedSuggestion }) => {
            const selectedValue = highlightedSuggestion || value
            // When leaving input, clear it unless there's a selected suggestion
            if (!selectedValue) setInternalValue('')
            return onBlur(selectedValue)
          },
          // return null instead of empty string to avoid rendering empty attribute
          'aria-describedby': allAriaDescribedBy || null,
          'aria-labelledby': ariaLabelledBy,
          'aria-label': ariaLabel,
        }}
        meta={{}}
        suggestionOptions={suggestions || []}
        onSuggestionSelected={(e, { suggestion, method }) => {
          if (method === 'enter') e.preventDefault()
          if (suggestion.placeholder) return
          // When selecting a suggestion, also update the internal state to the suggestion name
          setInternalValue(suggestion.name)
          onChange(suggestion)
        }}
        renderSuggestion={({ name }) => <a>{name}</a>}
        focusInputOnSuggestionClick={false}
        containerProps={{
          // input container needs an aria-label for compliance
          'aria-label': ariaLabel || label,
        }}
      />
    </LabeledField>
  )
}

PartnerAutoSuggestInput.propTypes = propTypes
PartnerAutoSuggestInput.defaultProps = defaultProps

export default compose(withState('internalValue', 'setInternalValue', ''))(
  PartnerAutoSuggestInput
)
