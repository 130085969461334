import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import ControlledExpandableItem from './controlled-expandable-item'

const propTypes = {
  children: PropTypes.node.isRequired,
  startExpanded: PropTypes.bool,
}

const defaultProps = {
  startExpanded: false,
}

function ExpandableItem({ children, startExpanded, ...rest }) {
  const [expanded, setExpanded] = useState(startExpanded)

  return (
    <ControlledExpandableItem
      expanded={expanded}
      setExpanded={setExpanded}
      {...rest}
    >
      {children}
    </ControlledExpandableItem>
  )
}

ExpandableItem.propTypes = propTypes
ExpandableItem.defaultProps = defaultProps

export default pure(ExpandableItem)
