import React from 'react'
import { Route } from 'react-router'
import * as Views from './views'
import Layout from './layout'

const Routes = (
  <Route component={Layout}>
    <Route path="dashboard" component={Views.Dashboard} />
  </Route>
)

export default Routes
