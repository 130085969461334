import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import blackCheck from 'images/black-check.svg'
import blackX from 'images/black-x.svg'
import { toggle, togglePropTypes } from 'lp-hoc'
import { compose } from 'redux'

const propTypes = {
  children: PropTypes.node,
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  hideExpand: PropTypes.bool,
  ...togglePropTypes('expanded'),
}

const defaultProps = {
  isChecked: false,
  hideExpand: false,
  label: '',
}

function StatsCheckListItem({
  children,
  isChecked,
  label,
  hideExpand,
  toggleExpanded,
  expanded,
}) {
  return (
    <li className={classnames({ cat4: !isChecked, cat1: isChecked })}>
      <div className="stat-block card">
        <div className="stat-inner">
          {isChecked ? (
            <span>
              <img src={blackCheck} alt="Yes" />
            </span>
          ) : (
            <span>
              <img src={blackX} alt="No" />
            </span>
          )}
          <p>{label}</p>
          {!hideExpand && (
            <button
              onClick={toggleExpanded}
              className="stat-trigger"
              aria-label={`Toggle ${label} Details`}
              aria-expanded={expanded}
              aria-controls={`stat-item-${isChecked}`}
            />
          )}
        </div>
        <div id={`stat-item-${isChecked}`}>
          {children && expanded && (
            <div className="stat-details">
              <div className="stat-content">{children}</div>
            </div>
          )}
        </div>
      </div>
    </li>
  )
}

StatsCheckListItem.propTypes = propTypes

StatsCheckListItem.defaultProps = defaultProps

export default compose(toggle('expanded'))(StatsCheckListItem)
