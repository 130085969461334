// Returns the first surveyQuestion that matches the provided survey page and display component values

function findQuestionByPageAndDisplayComponent(
  questions,
  surveyPage,
  displayComponent
) {
  if (!questions) return questions

  return questions.find(
    (question) =>
      question.surveyPage === surveyPage &&
      question.displayComponent === displayComponent
  )
}

export default findQuestionByPageAndDisplayComponent
