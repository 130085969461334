import React from 'react'
import PropTypes from 'prop-types'
import ExternalLink from './external-link'
import { capitalize, trim } from 'lodash'

const propTypes = {
  email: PropTypes.string.isRequired,
  resultName: PropTypes.string.isRequired,
  contactName: PropTypes.string,
  children: PropTypes.node,
}

const defaultProps = {
  contactName: '',
}

function salutation(name) {
  return trim(`Hi ${capitalize(name)}`)
}

// mailto links need percent encoding for special characters
function encodeSpecialCharacters(string) {
  return encodeURIComponent(string)
}

function SearchResultContactLink({ email, resultName, contactName, children }) {
  const formattedResultName = encodeSpecialCharacters(resultName)
  const emailBody = `${salutation(contactName)},%0A%0A
I came across the ${formattedResultName} profile on artlook Map and wanted to reach out.`

  return (
    <ExternalLink
      href={`mailto:${email}?subject=${formattedResultName} Inquiry&body=${emailBody}`}
    >
      {children}
    </ExternalLink>
  )
}

SearchResultContactLink.defaultProps = defaultProps
SearchResultContactLink.propTypes = propTypes

export default SearchResultContactLink
