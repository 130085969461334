import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as Types from 'types'
import { ContentBlock, ExternalLink } from 'components'
import * as flashActions from 'redux-flash'
import { Link } from 'react-router'
import { useCommunity } from 'utils'

const propTypes = {
  image: Types.image.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
}

const defaultProps = {}

function AccountNotFound({ flashErrorMessage }) {
  const { schoolSupportEmail } = useCommunity()
  useEffect(
    () =>
      flashErrorMessage(
        'No user found in our system matching your CPS account.'
      ),
    []
  )

  return (
    <ContentBlock>
      <h2>Account Not Found</h2>
      <p>
        If you believe there is an error with your account, please contact
        support:{' '}
        <ExternalLink href={`mailto:${schoolSupportEmail}`}>
          {schoolSupportEmail}
        </ExternalLink>
      </p>
      <p>You can attempt to sign in to the artlook school portal below.</p>
      <Link to={'/school-portal/sign-in'} className="button-primary">
        Sign In
      </Link>
    </ContentBlock>
  )
}

AccountNotFound.propTypes = propTypes
AccountNotFound.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  flashErrorMessage: flashActions.flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AccountNotFound
)
