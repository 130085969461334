import React from 'react'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { ProfileOverview, ProfileInfoList } from 'educator-portal-components'
import { getUserDisplayName } from 'utils'
import { pure } from 'recompose'

const propTypes = {
  user: Types.directoryUser.isRequired,
}

const defaultProps = {}

function FlaggedViewProfileDetails({ user }) {
  const {
    firstName,
    lastName,
    avatarUrl: profileImgUrl,
    schoolNames,
    email,
    pronouns,
  } = user
  const userDisplayName = getUserDisplayName({ firstName, lastName }, email)

  return (
    <div id="profile-details">
      <div className="text-block">
        <div className="flex-horizontal">
          <ProfileOverview
            userDisplayName={userDisplayName}
            pronouns={pronouns}
            profileImgUrl={profileImgUrl}
            schoolNames={schoolNames}
          />
        </div>
      </div>
      <div>
        <ProfileInfoList user={user} />
      </div>
    </div>
  )
}

FlaggedViewProfileDetails.propTypes = exact(propTypes)
FlaggedViewProfileDetails.defaultProps = defaultProps

export default pure(FlaggedViewProfileDetails)
