import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import classnames from 'classnames'
import { flagActionIconAndLabel } from 'utils'

const propTypes = {
  isFlagged: PropTypes.bool.isRequired,
  toggleFlag: PropTypes.func.isRequired,
  isModerator: PropTypes.bool.isRequired,
  userDisplayName: PropTypes.string.isRequired,
}

const defaultProps = {}

function FlagButton({
  isFlagged,
  toggleFlag,
  isModerator,
  userDisplayName,
  ...buttonProps
}) {
  const { className, ...rest } = buttonProps

  const { icon: ActionIcon, label } = flagActionIconAndLabel(
    isFlagged,
    isModerator
  )

  const ariaLabel = () => {
    if (!isFlagged) return `Flag ${userDisplayName}'s profile as inappropriate`

    if (isModerator) return `Mark ${userDisplayName}'s profile as appropriate`

    return `Undo flagging ${userDisplayName}'s profile as inappropriate`
  }

  return (
    <div className="flag">
      <button
        type="button"
        className={classnames('flag-button link-primary', className, {
          flagged: isFlagged,
        })}
        onClick={toggleFlag}
        aria-label={ariaLabel}
        {...rest}
      >
        <React.Fragment>
          <ActionIcon aria-hidden />
          <strong>{label}</strong>
        </React.Fragment>
      </button>
    </div>
  )
}

FlagButton.propTypes = propTypes
FlagButton.defaultProps = defaultProps

export default pure(FlagButton)
