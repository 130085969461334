import { mapValues, map, isObject, isArray } from 'lodash'

// Map an object or array
function mapCollection(collection, func) {
  const method = isArray(collection) ? map : mapValues
  return method(collection, func)
}

function recursiveDeepMap(collection, key, iteratee) {
  // Base case
  if (!isObject(collection)) return collection
  const mappedCollection = mapCollection(collection, (value, key) =>
    recursiveDeepMap(value, key, iteratee)
  )
  return iteratee(mappedCollection, key)
}

// A custom deep map that runs an iteratee on EVERY nested value of an object
// - not just the leaves.
// Also note that this function recurses before calling the iteratee.
function deepMap(collection, iteratee) {
  return recursiveDeepMap(collection, null, iteratee)
}

export default deepMap
