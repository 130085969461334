// Create array of key/value pair objects for use in field groups

function serializeOptions(collection, mappings = {}) {
  const { key: keyPath = 'displayName', value: valuePath = 'id' } = mappings
  return collection.map((item) => ({
    key: item[keyPath],
    value: item[valuePath],
  }))
}

export default serializeOptions
