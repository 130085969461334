import React from 'react'
import { Content } from 'components'
import { Spinner } from 'lp-components'

function VirtualLearningLibrary() {
  return (
    <Content className="iframe-content">
      <Spinner />
      <iframe
        title="Virtual & Online Programs"
        src="https://ingenuity-dataviz.shinyapps.io/virtual_programs/"
        width="100%"
        height="100%"
        className="full-iframe"
      ></iframe>
    </Content>
  )
}

export default VirtualLearningLibrary
