import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Button } from 'lp-components'

const propTypes = {
  canPost: PropTypes.bool.isRequired,
  join: PropTypes.func.isRequired,
  openPostForm: PropTypes.func.isRequired,
}

const defaultProps = {}

function NewPostButton({ canPost, join, openPostForm }) {
  if (!canPost)
    return (
      <button type="button" className="button-primary-outline" onClick={join}>
        Join Community to Start Discussion
      </button>
    )

  return (
    <div className="new-post-cta">
      <Button onClick={openPostForm}>Start New Discussion</Button>
    </div>
  )
}

NewPostButton.propTypes = exact(propTypes)
NewPostButton.defaultProps = defaultProps

export default pure(NewPostButton)
