import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { CustomQuestionForm } from '../forms'
import { get } from 'lodash'
import { SectionBlock } from 'components'

const propTypes = {
  surveyQuestions: PropTypes.arrayOf(Types.surveyQuestion).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitFail: PropTypes.func.isRequired,
  setShowInfoModalWithContent: PropTypes.func.isRequired,
  surveyAnswers: PropTypes.arrayOf(Types.surveyAnswer).isRequired,
}

const defaultProps = {}

function CustomQuestions({
  surveyQuestions,
  onSubmit,
  onSubmitSuccess,
  onSubmitFail,
  setShowInfoModalWithContent,
  surveyAnswers,
}) {
  const findAnswerBySurveyQuestionId = (questionId) => {
    return surveyAnswers.find((answer) => answer.surveyQuestionId == questionId)
  }

  return surveyQuestions.map((surveyQuestion) => {
    const customQuestionId = `custom-question-${surveyQuestion.id}`
    const surveyAnswer = findAnswerBySurveyQuestionId(surveyQuestion.id)

    return (
      <SectionBlock key={customQuestionId}>
        <h2 id={customQuestionId}>{surveyQuestion.body}</h2>
        {surveyQuestion.description && (
          <p>
            <button
              type="button"
              className="link-secondary"
              onClick={() =>
                setShowInfoModalWithContent(surveyQuestion.description)
              }
            >
              Click here for additional information.
            </button>
          </p>
        )}
        <CustomQuestionForm
          initialValues={surveyAnswer}
          onSubmit={onSubmit}
          onSubmitSuccess={onSubmitSuccess}
          onSubmitFail={onSubmitFail}
          name={customQuestionId}
          ariaLabelledby={customQuestionId}
          beforeSubmit={({ otherText }) => ({
            surveyQuestions: [
              {
                otherText,
                surveyQuestionId: surveyQuestion.id,
                id: get(surveyAnswer, 'id'),
              },
            ],
          })}
        />
      </SectionBlock>
    )
  })
}

CustomQuestions.propTypes = propTypes
CustomQuestions.defaultProps = defaultProps

export default CustomQuestions
