import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Button } from 'lp-components'

const propTypes = {
  isMember: PropTypes.bool.isRequired,
  join: PropTypes.func.isRequired,
  leave: PropTypes.func.isRequired,
}

const defaultProps = {}

function MembershipButton({ isMember, join, leave }) {
  if (!isMember) return <Button onClick={join}>Join Community</Button>

  return (
    <button
      type="button"
      className="link-secondary no-underline"
      onClick={leave}
    >
      <span className="subtract-icon">-</span>{' '}
      <span className="underline">Leave Community</span>
    </button>
  )
}

MembershipButton.propTypes = exact(propTypes)
MembershipButton.defaultProps = defaultProps

export default pure(MembershipButton)
