import { filter, isEmpty } from 'lodash'

function getInfoModalContent(enumerables) {
  const definedEnumerables = filter(
    enumerables,
    ({ definition }) => !isEmpty(definition)
  )
  return definedEnumerables
    .map(
      (enumerable) =>
        `<p key=${enumerable.id}>
      <strong>${enumerable.displayName}</strong> ${enumerable.definition}
    </p>`
    )
    .join('')
}

export default {
  getInfoModalContent,
}
