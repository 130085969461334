import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Link } from 'react-router'
import { isEmpty } from 'lodash'
import { ActionMenu } from 'components'
import ResourcesSidebarAttachment from './resources-sidebar-attachment'
import { AddIcon } from 'components/icons/basic'

const propTypes = {
  attachments: PropTypes.arrayOf(Types.attachment).isRequired,
  attachmentsCount: PropTypes.number.isRequired,
  canUploadAttachments: PropTypes.bool.isRequired,
  copResourcesIndexPath: PropTypes.string.isRequired,
  onSelectUploadAction: PropTypes.func.isRequired,
}

const defaultProps = {}

function ResourcesSidebar({
  attachments,
  attachmentsCount,
  canUploadAttachments,
  copResourcesIndexPath,
  onSelectUploadAction,
}) {
  const uploadAction = {
    label: 'Upload Resource',
    onSelect: onSelectUploadAction,
    icon: AddIcon,
  }
  return (
    <div className="card">
      <header className="flex-horizontal flex-space-between">
        <h3>Resources ({attachmentsCount})</h3>
        {canUploadAttachments && <ActionMenu actions={[uploadAction]} />}
      </header>
      {!isEmpty(attachments) ? (
        <ul className="attachments-list">
          {attachments.map((attachment) => (
            <li key={attachment.id}>
              <ResourcesSidebarAttachment attachment={attachment} />
            </li>
          ))}
        </ul>
      ) : (
        <p className="no-attachments">No Resources</p>
      )}
      {(canUploadAttachments || !isEmpty(attachments)) && (
        <div className="text-center view-all-link">
          <Link
            to={copResourcesIndexPath}
            className="link-secondary no-underline underline-hover"
          >
            Resource Library
          </Link>
        </div>
      )}
    </div>
  )
}

ResourcesSidebar.propTypes = exact(propTypes)
ResourcesSidebar.defaultProps = defaultProps

export default pure(ResourcesSidebar)
