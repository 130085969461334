import React from 'react'
import { Route, Router, browserHistory } from 'react-router'
import initializeStore from './store'
import { Provider as ReduxProvider } from 'react-redux'
import { syncHistoryWithStore } from 'react-router-redux'
import { routes as HomeRoutes } from './home'
import { routes as MapRoutes } from './map'
import { routes as PartnerPortalRoutes } from './partner-portal'
import { routes as SchoolPortalRoutes } from './school-portal'
import { routes as EducatorPortalRoutes } from './educator-portal'
import { routes as StyleguideRoutes } from './styleguide'
import {
  NotFound,
  CommunityProvider,
  SurveyQuestionsProvider,
} from 'components'
import {
  hasChangedForm,
  getCurrentCommunity,
  isMapEnabled,
  isSchoolPortalEnabled,
  isPartnerPortalEnabled,
  isEducatorEnabled,
} from 'utils'
import { isProduction } from 'config'
import GlobalLayout from './global-layout'
import { Home, VirtualLearningLibrary } from './home/components'

const SHOW_STYLEGUIDE = !isProduction() || process.env.DISPLAY_STYLEGUIDE

const store = initializeStore()
// Make the routing information available in the store
const history = syncHistoryWithStore(browserHistory, store)

const SCHOOL_SURVEY_FORMS = [
  'school-courses',
  'school-arts-instructor',
  'school-percent-access',
  'school-schedule',
  'school-upload-schedule',
  'school-arts-integration',
  'school-professional-development',
  'school-arts-space',
  'school-disciplines',
  'partner-partnership-table',
  'school-budget',
  'school-funding',
  'school-arts-resource',
  'school-program-resources',
  'school-disciplines',
]

const SCHOOL_FORMS = ['SchoolProfileForm']

const PARTNER_FORMS = [
  'partner-program',
  'partner-partnership-table',
  'partner-organization',
  'partner-organization-contacts',
]

const CONFIRMED_FORMS = [
  ...PARTNER_FORMS,
  ...SCHOOL_SURVEY_FORMS,
  ...SCHOOL_FORMS,
]
const CONFIRMATION_MESSAGE =
  'Do you want to leave this page? Changes you made will not be saved.'

// Confirm page transitions when there are incomplete forms
function onRouteChange(previousState, nextState, replace, callback) {
  const state = store.getState()
  if (hasChangedForm(state, CONFIRMED_FORMS)) {
    if (!window.confirm(CONFIRMATION_MESSAGE)) return
  }
  if (hasPathChanged(previousState.location, nextState.location)) {
    window.scrollTo(0, 0)
  }
  return callback()
}

// Ignore fragment (#) changes when comparing urls
function hasPathChanged(prevLocation, nextLocation) {
  return (
    prevLocation.pathname + prevLocation.search !==
    nextLocation.pathname + nextLocation.search
  )
}

// Confirm out-of-app transitions
window.onbeforeunload = (event) => {
  const state = store.getState()
  if (hasChangedForm(state, CONFIRMED_FORMS))
    return (event.returnValue = CONFIRMATION_MESSAGE)
}

// App has a single community determined via subdomain
const community = getCurrentCommunity()

const Routes = (
  <CommunityProvider community={community}>
    <SurveyQuestionsProvider>
      <ReduxProvider store={store}>
        <Router history={history}>
          <Route onChange={onRouteChange}>
            {!community && (
              <Route path="">
                <Route exact path="/" component={Home} />
                <Route path="/library" component={VirtualLearningLibrary} />
              </Route>
            )}
            {community && (
              <Route component={GlobalLayout}>
                {HomeRoutes}
                {isMapEnabled(community) && MapRoutes}
                {isSchoolPortalEnabled(community) && SchoolPortalRoutes}
                {isEducatorEnabled(community) && EducatorPortalRoutes}
                {isPartnerPortalEnabled(community) && PartnerPortalRoutes}
                {SHOW_STYLEGUIDE && StyleguideRoutes}
              </Route>
            )}
            <Route path="/not-found" component={NotFound} />
            <Route path="*" component={NotFound} />
          </Route>
        </Router>
      </ReduxProvider>
    </SurveyQuestionsProvider>
  </CommunityProvider>
)

export default Routes
