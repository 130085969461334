import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  initialValues: PropTypes.object.isRequired,
}

const defaultProps = {}

function CheckboxCell({ name, data: { id }, initialValues }) {
  const hasCheckmark = initialValues[id]
  return (
    <td>
      {hasCheckmark && (
        <div name={`${id}.${name}`} className="checkbox" aria-hidden>
          &#10003;
        </div>
      )}
      <span className="visually-hidden">{hasCheckmark ? 'Yes' : 'No'}</span>
    </td>
  )
}

CheckboxCell.propTypes = propTypes
CheckboxCell.defaultProps = defaultProps

export default CheckboxCell
