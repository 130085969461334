import React from 'react'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { Link } from 'react-router'
import { pure } from 'recompose'
import { SCHOOL_PORTAL_ROUTE, EDUCATOR_PORTAL_ROUTE } from 'config'
import schoolPortal from 'images/communities/Chicago/Educator/school-portal.png'
import educatorPortal from 'images/communities/Chicago/Educator/educator-portal.png'
import classnames from 'classnames'
import { get } from 'lodash'

const propTypes = {
  currentPortal: Types.portalType.isRequired,
  currentEmployment: Types.employer,
}

const defaultProps = {
  currentEmployment: null,
}

function PortalSelector({ currentPortal, currentEmployment }) {
  const position = get(currentEmployment, 'position')
  const hasSchoolAccess = position != Types.POSITION_TYPES.TEACHER
  const hasEducatorAccess = position != Types.POSITION_TYPES.PRINCIPAL

  return (
    <nav className="portal-select" aria-label="Portal select">
      <ul>
        {hasSchoolAccess && (
          <li>
            <Link
              to={SCHOOL_PORTAL_ROUTE}
              className={classnames({
                'selected-portal': currentPortal === Types.PORTAL.SCHOOL_PORTAL,
              })}
            >
              <img src={schoolPortal} alt="" />
              School
            </Link>
          </li>
        )}
        {hasEducatorAccess && (
          <li>
            <Link
              to={EDUCATOR_PORTAL_ROUTE}
              className={classnames({
                'selected-portal':
                  currentPortal === Types.PORTAL.EDUCATOR_PORTAL,
              })}
            >
              <img src={educatorPortal} alt="" />
              Educator
            </Link>
          </li>
        )}
      </ul>
    </nav>
  )
}

PortalSelector.propTypes = exact(propTypes)
PortalSelector.defaultProps = defaultProps

export default pure(PortalSelector)
