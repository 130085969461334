import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import * as Types from 'types'
import {
  SearchResultContactLink,
  HighlightText,
  OpenToPartnershipsIndicator,
} from 'components'
import { serializeLocation } from 'utils'
import { get } from 'lodash'
import locationImg from 'images/map/dashboards/location.svg'
import emailImg from 'images/map/dashboards/email.svg'

const propTypes = {
  school: Types.schoolSearchData.isRequired,
  schoolYearFilter: PropTypes.number,
}

const defaultProps = {}

const certificationPercents = {
  [Types.CERTIFICATION_EXCELLING]: '100',
  [Types.CERTIFICATION_STRONG]: '80',
  [Types.CERTIFICATION_DEVELOPING]: '60',
  [Types.CERTIFICATION_EMERGING]: '40',
  [Types.CERTIFICATION_INCOMPLETE]: '20',
}

function SchoolSearchResult({
  school,
  school: {
    id,
    name,
    displayName,
    disciplines,
    partnerCount,
    report,
    artsLiaison,
    highlights,
    openToPartnerships,
  },
  schoolYearFilter,
}) {
  const schoolUrl = `/schools/${id}`
  const address = get(serializeLocation(school.type, school), 'address')
  const anyHighlights = Object.keys(highlights).length > 0
  return (
    <div>
      <div className="text-block">
        <h3 data-cy="search-result-name">
          <Link to={`${schoolUrl}?year=${schoolYearFilter}`}>
            {displayName}
          </Link>
        </h3>
        <ul className="contact-details">
          <li>
            <img src={locationImg} alt="" />
            {address}
          </li>
          {artsLiaison.email && (
            <li>
              <img src={emailImg} alt="" />
              {artsLiaison.email}
            </li>
          )}
        </ul>
        {disciplines && (
          <p>
            <strong>Instructors Teaching: </strong>
            {disciplines.join(', ')}
          </p>
        )}
        {partnerCount && (
          <p>
            <strong>Number of Organization Partners: </strong>
            {partnerCount}
          </p>
        )}
        {anyHighlights && (
          <ul className="matched-terms">
            {Object.keys(highlights).map((key, i) => {
              return (
                <HighlightText
                  key={i}
                  highlightName={key}
                  highlightValue={highlights[key]}
                />
              )
            })}
          </ul>
        )}
      </div>
      <div className="result-details">
        {openToPartnerships && <OpenToPartnershipsIndicator />}
        {artsLiaison.email && (
          <SearchResultContactLink
            email={artsLiaison.email}
            resultName={name}
            contactName={artsLiaison.firstName}
          >
            <button
              aria-label={`Contact ${name}`}
              className="button-default-outline"
            >
              Contact School
            </button>
          </SearchResultContactLink>
        )}
        {report && (
          <div className="rating">
            <p>{report.level}</p>
            <div
              className={`status-block progress--circle progress--${
                certificationPercents[report.level]
              }`}
            ></div>
          </div>
        )}
      </div>
    </div>
  )
}

SchoolSearchResult.propTypes = propTypes

SchoolSearchResult.defaultProps = defaultProps

export default SchoolSearchResult
