import { set, omit, compact } from 'lodash/fp'

// Creates params for replacing nested attribute objects with a new set

function replaceResources({ old: oldResources = [], new: newResources = [] }) {
  return [
    ...compact(oldResources).map(set('_destroy', true)),
    ...compact(newResources).map(omit('id')),
  ]
}

export default replaceResources
