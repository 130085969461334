import React from 'react'
import * as Types from 'types'
import { pure } from 'recompose'
import MapDisplay from './map-display'

const propTypes = {
  location: Types.location,
}

const defaultProps = {
  location: null,
}

function ProfileMapDisplay({ location, ...rest }) {
  const { latitude, longitude } = location || {}
  const hasCoordinates = latitude && longitude

  if (!hasCoordinates)
    return (
      <div className="google-map no-coordinates">
        <p>Address currently unavailable</p>
      </div>
    )

  return (
    <MapDisplay
      noScroll
      locations={[location]}
      selectedLocation={location}
      {...rest}
    />
  )
}

ProfileMapDisplay.propTypes = propTypes
ProfileMapDisplay.defaultProps = defaultProps

export default pure(ProfileMapDisplay)
