import React from 'react'
import PropTypes from 'prop-types'
import { PortalHeader } from 'components'
import { useCommunity } from 'utils'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function Layout({ children }) {
  const {
    defaultBranding: { dashboardHeader },
  } = useCommunity()
  return (
    <div>
      <PortalHeader
        title="Communities of Practice"
        backgroundImage={dashboardHeader}
      />
      <div className="content-block-container">{children}</div>
    </div>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

export default Layout
