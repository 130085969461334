import convertToNestedAttributes from './convert-to-nested-attributes'
import addSchoolYearToParams from './add-school-year-to-params'
import toHasManyAttribute from './to-has-many-attribute'
import mapAllocations from './map-allocations'
import { flow } from 'lodash'
import { update, get, set } from 'lodash/fp'

function setDistrictFunding(partner) {
  const funding = get('detail.districtFunding', partner)
  if (!funding) return partner
  return set(
    'detail.districtFunding',
    Number(funding.fractional) / 100,
    partner
  )
}

// Serialize partner params

function serializePartnerParams(partner, schoolYear) {
  return flow(
    setDistrictFunding,
    toHasManyAttribute(['detail']),
    update('programs', (programs) =>
      programs ? programs.map(mapAllocations) : []
    ),
    mapAllocations,
    update('partnerships', (partnerships) =>
      partnerships ? partnerships.map(mapAllocations) : []
    ),
    (params) => addSchoolYearToParams(params, schoolYear),
    (params) => convertToNestedAttributes(params, { extraKeys: ['address'] })
  )(partner)
}

export default serializePartnerParams
