import React from 'react'
import PropTypes from 'prop-types'
import {
  StatsListMetricItem,
  StatsCheckListItem,
  StatsListListItem,
  CategoryExpandedContent,
} from '../components'

const propTypes = {
  category: PropTypes.object.isRequired,
}

const defaultProps = {}

function CertificationCategory({ category }) {
  const hideExpand = !category.expandedContent

  return (
    <>
      {category.displayComponent === 'indicator' && (
        <StatsListMetricItem
          stat={category.value}
          label={category.label}
          categoryValue={category.type}
          hideExpand={hideExpand}
        >
          {!hideExpand && (
            <CategoryExpandedContent content={category.expandedContent} />
          )}
        </StatsListMetricItem>
      )}
      {category.displayComponent === 'boolean' && (
        <StatsCheckListItem
          isChecked={category.value}
          label={category.label}
          hideExpand={hideExpand}
        >
          {!hideExpand && (
            <CategoryExpandedContent content={category.expandedContent} />
          )}
        </StatsCheckListItem>
      )}
      {category.displayComponent === 'list' && (
        <StatsListListItem
          content={category.value}
          label={category.label}
          hideExpand={hideExpand}
        >
          {!hideExpand && (
            <CategoryExpandedContent content={category.expandedContent} />
          )}
        </StatsListListItem>
      )}
    </>
  )
}

CertificationCategory.propTypes = propTypes
CertificationCategory.defaultProps = defaultProps

export default CertificationCategory
