/* eslint-disable no-console */
import { get } from 'lodash'
import interpolateText from './interpolate-text'

// Returns interpolated string
function createDisplayText(community) {
  const displayText = community.displayText
  if (!validConfiguration(displayText)) return () => ''

  return function t(key, values) {
    if (!key) console.warn('WARNING: an undefined display key was used.')
    return interpolateText(get(displayText, key, ''), values)
  }
}

// ------- HELPERS -------

function validConfiguration(displayText) {
  const valid = displayText && Object.keys(displayText).length > 0
  if (!valid) {
    console.warn(
      'WARNING: No display keys have been defined for this community. By default, comm specific text will not be loaded throughout the application.'
    )
  }

  return valid
}

export default createDisplayText
