import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { lpForm } from 'lp-form'
import { ButtonArea, SubmitButton } from 'lp-components'
import { PartnerAutoSuggestInput } from 'components'
import * as Types from 'types'
import { filterDestroyed } from 'utils'

const propTypes = {
  ...formPropTypes,
  schoolOptions: PropTypes.arrayOf(Types.searchSuggestion),
  existingPartnerships: PropTypes.arrayOf(Types.partnerPartnershipType),
}

function AddPartnerForm({
  handleSubmit,
  submitting,
  schoolOptions,
  existingPartnerships,
}) {
  const validateExistingPartnership = useCallback(
    (id) => {
      const activePartnerships = filterDestroyed(existingPartnerships)
      if (activePartnerships.some(({ schoolId }) => schoolId === Number(id)))
        return 'Partnership already exists with this school for the selected year'
    },
    [existingPartnerships]
  )

  return (
    <form onSubmit={handleSubmit}>
      <fieldset>
        <Field
          name="schoolId"
          label="Enter a School Name"
          component={PartnerAutoSuggestInput}
          suggestions={schoolOptions}
          parse={(option) => (option ? option.id : null)}
          validate={validateExistingPartnership}
        />
      </fieldset>
      <ButtonArea>
        <SubmitButton {...{ submitting, style: 'secondary' }}>
          Add School
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

AddPartnerForm.propTypes = propTypes

export default compose(
  lpForm({
    name: 'partner-add-partner',
    constraints: {
      schoolId: { presence: { message: '^School selection cannot be blank' } },
    },
  })
)(AddPartnerForm)
