import { CloudinaryFileInput } from 'lp-components'
import { compose, withProps } from 'recompose'

// Override our default one to use other env var names (legacy support)
export default compose(
  withProps(({ uploadPath = '' }) => {
    const timestamp = Date.now()
    return {
      cloudName: process.env.CLOUDINARY_CLOUD_NAME,
      bucket: `${process.env.CLOUDINARY_CLOUD_BUCKET}${uploadPath}/${timestamp}`,
    }
  })
)(CloudinaryFileInput)
