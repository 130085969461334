import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { pure } from 'recompose'
import { Searchable, EmptyState } from 'components'
import ExpandableCheckboxGroups from './expandable-checkbox-groups'
import { createFilterOptionGroupsCallback } from 'utils'
import { CheckboxGroup } from 'lp-components'
import { compact, isEmpty, map } from 'lodash'

const propTypes = {
  component: PropTypes.func,
  emptyMessage: PropTypes.string,
  formName: PropTypes.string.isRequired,
  formPath: PropTypes.string.isRequired,
  groupLabel: PropTypes.string.isRequired,
  headingLevel: PropTypes.string.isRequired,
  keepEmptyGroups: PropTypes.bool,
  onSearch: PropTypes.func,
  optionGroups: PropTypes.objectOf(PropTypes.arrayOf(Types.formOptionType))
    .isRequired,
}

const defaultProps = {
  component: CheckboxGroup,
  emptyMessage: 'Sorry, no filters match your search',
  keepEmptyGroups: false,
}

function SearchableCheckboxGroups({
  component,
  emptyMessage,
  formName,
  formPath,
  groupLabel,
  headingLevel,
  keepEmptyGroups,
  onSearch,
  optionGroups,
}) {
  const handleSearch = useCallback(
    onSearch ||
      createFilterOptionGroupsCallback(optionGroups, { keepEmptyGroups }),
    [onSearch, optionGroups, keepEmptyGroups]
  )

  const calculateGroupsToExpand = useCallback((results, query) => {
    return compact(
      map(results, (val, key) => {
        if (!query) return null
        return key
      })
    )
  }, [])

  return (
    <Searchable
      initialResults={optionGroups}
      onSearch={handleSearch}
      label={'Search ' + groupLabel}
    >
      {(results, query) => {
        if (isEmpty(results)) return <EmptyState message={emptyMessage} />
        return (
          <ExpandableCheckboxGroups
            formName={formName}
            formPath={formPath}
            headingLevel={headingLevel}
            optionGroups={results}
            component={component}
            preExpanded={calculateGroupsToExpand(results, query)}
          />
        )
      }}
    </Searchable>
  )
}

SearchableCheckboxGroups.propTypes = propTypes
SearchableCheckboxGroups.defaultProps = defaultProps

export default pure(SearchableCheckboxGroups)
