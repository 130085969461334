import React from 'react'
import { ExternalLink } from 'components'
import { useCommunity } from 'utils'

function RequestNameChangeLink() {
  const { partnerSupportEmail } = useCommunity()
  const subject = 'Partner Name Change Request'
  const emailBody = `Dear Admin,%0A%0A
    I would like to request a change to my organization's name on artlook Map.
    `

  return (
    <ExternalLink
      href={`mailto:${partnerSupportEmail}?subject=${subject}&body=${emailBody}`}
      className="link-black"
      alt="Request organization name change"
    >
      Request Change
    </ExternalLink>
  )
}

export default RequestNameChangeLink
