import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { lpForm } from 'lp-form'
import { modifyProps } from 'lp-hoc'
import {
  ScrollField as Field,
  HiddenLegend,
  CheckboxWithText,
  CheckboxFieldset,
  CheckboxGroup,
  RadioGroup,
} from 'components'
import RadioGroupWithOtherField from 'components/radio-with-other'
import { propTypes as formPropTypes } from 'redux-form'
import {
  ButtonArea,
  SubmitButton,
  Input,
  Textarea,
  Select,
} from 'lp-components'
import { PROGRAM_TYPES_MODAL_CONTENT, PROGRAM_TYPES_MODAL_HEADER } from 'config'
import danceImage from 'images/discipline-icons/dance.svg'
import literaryImage from 'images/discipline-icons/literary.svg'
import musicImage from 'images/discipline-icons/music.svg'
import theaterImage from 'images/discipline-icons/theater.svg'
import visualArtsImage from 'images/discipline-icons/visual-arts.svg'
import mediaArtsImage from 'images/discipline-icons/media-arts.svg'
import otherImage from 'images/discipline-icons/other.svg'
import { scroller } from 'react-scroll'
import {
  formatObjectsToIds,
  parseIdsToObjects,
  replaceResources,
  formatObjectToId,
  parseIdToObject,
  yankBy,
  isOtherType,
  combineOtherOption,
  serializeOptions,
  communityEnumerable,
  displaySubmitFailure,
} from 'utils'
import * as Types from 'types'
import { startCase, once, noop, first, keys, isEmpty, some } from 'lodash'

const propTypes = {
  ...formPropTypes,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  onDelete: PropTypes.func,
  setShowInfoModalWithContent: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  financialAssistanceTypes: PropTypes.arrayOf(Types.communityEnumerable)
    .isRequired,
  identityFrequencies: PropTypes.arrayOf(Types.communityEnumerable).isRequired,
  approachTypes: PropTypes.arrayOf(Types.communityEnumerable).isRequired,
  isNewProgram: PropTypes.bool.isRequired,
}

const defaultProps = {
  onSubmitSuccess: noop,
}

const DISCIPLINE_IMAGE_MAP = {
  dance: danceImage,
  literary_arts: literaryImage,
  music: musicImage,
  theatre: theaterImage,
  visual_arts: visualArtsImage,
  media_arts: mediaArtsImage,
  other: otherImage,
}

function gradeForId(grades, id) {
  return grades.find((grade) => grade.id == id)
}

const validateGradeOrder = once((grades) => {
  return function validate(value, { programGrades = [] }) {
    const [minGrade, maxGrade] = programGrades
    if (!minGrade || !maxGrade) return ''
    const [fullMinGrade, fullMaxGrade] = [minGrade, maxGrade].map(
      ({ gradeId }) => gradeForId(grades, gradeId)
    )
    if (fullMinGrade.position > fullMaxGrade.position)
      return 'Grades must be in correct order.'
  }
})

function ProgramForm({
  handleSubmit,
  pristine,
  submitting,
  onDelete,
  disciplines,
  outcomeTypes,
  setShowInfoModalWithContent,
  disabled,
  grades,
  programTypes,
  financialAssistanceTypes,
  identityFrequencies,
  approachTypes,
  onSubmitSuccess,
  onSubmitFail,
  form,
  isNewProgram,
}) {
  const uniqueId = (inputName) => `${form}-${inputName}`
  const [otherFinancialType, definedFinancialTypes] = useMemo(
    () => yankBy(financialAssistanceTypes, isOtherType),
    [financialAssistanceTypes]
  )
  const [otherOutcomeType, definedOutcomeTypes] = useMemo(
    () => yankBy(outcomeTypes, isOtherType),
    [outcomeTypes]
  )
  const [otherApproachType, definedApproachTypes] = useMemo(
    () => yankBy(approachTypes, isOtherType),
    [approachTypes]
  )
  const outcomesModalContent = useMemo(() => {
    return communityEnumerable.getInfoModalContent(outcomeTypes)
  }, [outcomeTypes])
  const approachTypesModalContent = useMemo(() => {
    return communityEnumerable.getInfoModalContent(approachTypes)
  }, [approachTypes])
  const hasOtherIdentityFrequency = some(identityFrequencies, isOtherType)
  return (
    <form onSubmit={handleSubmit}>
      {!disabled && (
        <ButtonArea>
          <SubmitButton {...{ pristine, submitting, style: 'secondary' }}>
            Save Program
          </SubmitButton>
          <button
            type="button"
            onClick={() => {
              if (!isNewProgram)
                return onDelete().then(onSubmitSuccess).catch(onSubmitFail)
              return onDelete()
            }}
            className="link-warn"
          >
            <span aria-hidden>×</span> Delete Program
          </button>
        </ButtonArea>
      )}
      <div className="row">
        <div className="col-6">
          <Field
            name="name"
            id={uniqueId('name')}
            component={Input}
            disabled={disabled}
          />
        </div>
        <div className="col-6">
          <div className="grade-block">
            <Field
              name="programGrades[0]"
              id={uniqueId('programGrades[0]')}
              placeholder="Choose"
              component={Select}
              options={serializeOptions(grades)}
              format={formatObjectToId('gradeId')}
              parse={parseIdToObject('gradeId')}
              label="Grades"
              aria-label="Start grade"
              disabled={disabled}
              validate={validateGradeOrder(grades)}
            />
            <p>through</p>
            <Field
              name="programGrades[1]"
              id={uniqueId('programGrades[1]')}
              placeholder="Choose"
              component={Select}
              validate={validateGradeOrder(grades)}
              options={serializeOptions(grades)}
              format={formatObjectToId('gradeId')}
              parse={parseIdToObject('gradeId')}
              label={false}
              aria-label="End grade"
              disabled={disabled}
            />
          </div>
        </div>
      </div>
      <Field
        name="description"
        id={uniqueId('description')}
        component={Textarea}
        className="description-textarea"
        disabled={disabled}
      />
      {!isEmpty(approachTypes) && (
        <CheckboxFieldset>
          <legend>
            <strong>
              Which of the following describes how your program approaches arts
              instruction to accomplish its objectives?
            </strong>
          </legend>
          <p>
            For a full list of definitions, please click{' '}
            <button
              className="link-secondary"
              type="button"
              onClick={() =>
                setShowInfoModalWithContent(approachTypesModalContent)
              }
            >
              here
            </button>
            .
          </p>
          <div className="response-option-blocks full-width">
            <div className="response-option-block">
              <Field
                name="approaches"
                id={uniqueId('approaches')}
                labelComponent={HiddenLegend}
                component={CheckboxGroup}
                format={formatObjectsToIds('approachId')}
                parse={parseIdsToObjects('approachId')}
                options={serializeOptions(definedApproachTypes)}
                disabled={disabled}
              />
              {otherApproachType && (
                <Field
                  name="otherApproach.otherText"
                  id={uniqueId('otherApproach.otherText')}
                  label={otherApproachType.displayName}
                  placeholder="Description..."
                  component={CheckboxWithText}
                  className="CheckboxGroup full-width-other"
                  disabled={disabled}
                />
              )}
            </div>
          </div>
        </CheckboxFieldset>
      )}
      <fieldset>
        <legend>
          <strong>Program Types</strong>
        </legend>
        <p>
          Select all that apply. For a full list of definitions, please click{' '}
          <button
            className="link-secondary"
            type="button"
            onClick={() =>
              setShowInfoModalWithContent(PROGRAM_TYPES_MODAL_CONTENT, {
                header: PROGRAM_TYPES_MODAL_HEADER,
              })
            }
          >
            here
          </button>
          .
        </p>
        <div className="response-option-blocks full-width">
          <div className="response-option-block">
            <Field
              name="programTypes"
              id={uniqueId('programTypes')}
              label={false}
              component={CheckboxGroup}
              format={formatObjectsToIds('programTypeId')}
              parse={parseIdsToObjects('programTypeId')}
              options={serializeOptions(programTypes)}
              disabled={disabled}
            />
          </div>
        </div>
      </fieldset>
      {!isEmpty(outcomeTypes) && (
        <CheckboxFieldset>
          <legend>
            <strong>
              The list below includes{' '}
              <button
                className="link-secondary"
                type="button"
                onClick={() =>
                  setShowInfoModalWithContent(outcomesModalContent)
                }
              >
                qualities and intended outcomes
              </button>{' '}
              that are sometimes the central focus of an arts education program.
              Please select any that are essential elements of this program.
            </strong>
          </legend>
          <div className="response-option-blocks full-width">
            <div className="response-option-block">
              <Field
                name="outcomes"
                id={uniqueId('outcomes')}
                labelComponent={HiddenLegend}
                component={CheckboxGroup}
                format={formatObjectsToIds('outcomeTypeId')}
                parse={parseIdsToObjects('outcomeTypeId')}
                options={serializeOptions(definedOutcomeTypes)}
                disabled={disabled}
              />
              {otherOutcomeType && (
                <Field
                  name="otherOutcome.otherText"
                  id={uniqueId('otherOutcome.otherText')}
                  label={otherOutcomeType.displayName}
                  placeholder="Description..."
                  component={CheckboxWithText}
                  className="CheckboxGroup full-width-other"
                  disabled={disabled}
                />
              )}
            </div>
          </div>
        </CheckboxFieldset>
      )}
      {!isEmpty(financialAssistanceTypes) && (
        <CheckboxFieldset>
          <legend>
            <strong>
              What financial support, if any, are you able to provide for this
              program or resource?
            </strong>
          </legend>
          <div className="response-option-blocks full-width">
            <div className="response-option-block">
              <Field
                name="financialAssistanceTypes"
                id={uniqueId('financialAssistanceTypes')}
                labelComponent={HiddenLegend}
                component={CheckboxGroup}
                format={formatObjectsToIds('financialAssistanceTypeId')}
                parse={parseIdsToObjects('financialAssistanceTypeId')}
                options={serializeOptions(definedFinancialTypes)}
                disabled={disabled}
              />
              {otherFinancialType && (
                <Field
                  name="otherFinancialAssistanceType.otherText"
                  id={uniqueId('otherFinancialAssistanceType.otherText')}
                  label={otherFinancialType.displayName}
                  placeholder="Description..."
                  component={CheckboxWithText}
                  className="CheckboxGroup full-width-other"
                  disabled={disabled}
                />
              )}
            </div>
          </div>
        </CheckboxFieldset>
      )}
      {!isEmpty(identityFrequencies) && (
        <fieldset>
          <legend id="identityFreq1">
            <strong>
              How often do teaching artists or program staff who identify as
              BIPOC directly deliver this program to students during the school
              year?
            </strong>
          </legend>
          <div
            className="response-option-blocks full-width"
            name="identityFrequencies"
          >
            <div className="response-option-block">
              {hasOtherIdentityFrequency ? (
                <RadioGroupWithOtherField
                  name="identityFrequencies[0]"
                  id={uniqueId('identityFrequencies[0]')}
                  labelComponent={HiddenLegend}
                  options={serializeOptions(identityFrequencies)}
                  disabled={disabled}
                  placeholder="Description..."
                  optionIdKey="identityFrequencyId"
                  ariaLabelledby="identityFreq1"
                />
              ) : (
                <Field
                  name="identityFrequencies[0]"
                  id={uniqueId('identityFrequencies[0]')}
                  labelComponent={HiddenLegend}
                  options={serializeOptions(identityFrequencies)}
                  disabled={disabled}
                  component={RadioGroup}
                  format={formatObjectToId('identityFrequencyId')}
                  parse={parseIdToObject('identityFrequencyId')}
                  ariaLabelledby="identityFreq1"
                />
              )}
            </div>
          </div>
        </fieldset>
      )}
      <fieldset>
        <legend>
          <strong>Arts discipline(s) addressed by this program:</strong>
        </legend>
        <div className="response-option-blocks">
          {disciplines.map(({ name, subDisciplines }, i) => (
            <div key={i} className="response-option-block">
              <p>
                <img
                  src={DISCIPLINE_IMAGE_MAP[name]}
                  alt=""
                  className="discipline-icon"
                />
                <strong>{startCase(name)}</strong>
              </p>
              <Field
                name="subDisciplines"
                id={uniqueId('subDisciplines')}
                label={false}
                component={CheckboxGroup}
                format={formatObjectsToIds('subDisciplineId')}
                parse={parseIdsToObjects('subDisciplineId')}
                options={serializeOptions(subDisciplines)}
                disabled={disabled}
              />
            </div>
          ))}
        </div>
      </fieldset>
    </form>
  )
}

ProgramForm.propTypes = propTypes
ProgramForm.defaultProps = defaultProps

function modifyBeforeSubmit({ initialValues }) {
  return {
    beforeSubmit: ({
      programTypes,
      outcomes,
      otherOutcome,
      subDisciplines,
      programGrades,
      financialAssistanceTypes,
      otherFinancialAssistanceType,
      identityFrequencies,
      approaches,
      otherApproach,
      ...rest
    }) => {
      const outcomesResources = replaceResources({
        old: combineOtherOption(
          initialValues.outcomes,
          initialValues.otherOutcome
        ),
        new: combineOtherOption(outcomes, otherOutcome),
      })
      const programTypesResources = replaceResources({
        old: initialValues.programTypes,
        new: programTypes,
      })
      const subDisciplinesResources = replaceResources({
        old: initialValues.subDisciplines,
        new: subDisciplines,
      })
      const programGradesResources = replaceResources({
        old: initialValues.programGrades,
        new: programGrades,
      })
      const financialAssistanceTypesResources = replaceResources({
        old: combineOtherOption(
          initialValues.financialAssistanceTypes,
          initialValues.otherFinancialAssistanceType
        ),
        new: combineOtherOption(
          financialAssistanceTypes,
          otherFinancialAssistanceType
        ),
      })
      const identityFrequenciesResources = replaceResources({
        old: initialValues.identityFrequencies,
        new: identityFrequencies,
      })
      const approachesResources = replaceResources({
        old: combineOtherOption(
          initialValues.approaches,
          initialValues.otherApproach
        ),
        new: combineOtherOption(approaches, otherApproach),
      })
      return {
        ...rest,
        programTypes: programTypesResources,
        outcomes: outcomesResources,
        subDisciplines: subDisciplinesResources,
        programGrades: programGradesResources,
        financialAssistanceTypes: financialAssistanceTypesResources,
        identityFrequencies: identityFrequenciesResources,
        approaches: approachesResources,
      }
    },
  }
}

export default compose(
  modifyProps(modifyBeforeSubmit),
  lpForm({
    enableReinitialize: true,
    constraints: {
      name: { presence: true },
      description: { presence: true },
      'programGrades.0': { presence: { message: '^Grade is required' } },
      'programGrades.1': { presence: { message: '^Grade is required' } },
      'otherFinancialAssistance.otherText': {
        exclusion: {
          within: [' '],
          message: '^Description is required',
        },
      },
      'otherOutcome.otherText': {
        exclusion: {
          within: [' '],
          message: '^Description is required',
        },
      },
      'otherApproach.otherText': {
        exclusion: {
          within: [' '],
          message: '^Description is required',
        },
      },
    },
    onSubmitFail: (params, dispatch, error, { syncErrors }) => {
      displaySubmitFailure(params, dispatch, error)
      const firstErrorField = first(keys(syncErrors))
      const { otherText } = syncErrors[firstErrorField]
      const firstErrorFieldName = otherText
        ? firstErrorField + '.otherText'
        : firstErrorField
      return scroller.scrollTo(firstErrorFieldName, { smooth: true })
    },
  })
)(ProgramForm)
