import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ActionMenu } from 'components'
import { TrashIcon, EditIcon } from 'components/icons/basic'

const propTypes = {
  editAttachment: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
}

const defaultProps = {}

function ResourcesTableAttachmentActionMenu({
  editAttachment,
  deleteAttachment,
}) {
  return (
    <ActionMenu
      actions={[
        {
          label: 'Edit File',
          icon: EditIcon,
          onSelect: editAttachment,
        },
        {
          label: 'Delete File',
          icon: TrashIcon,
          onSelect: deleteAttachment,
        },
      ]}
    />
  )
}

ResourcesTableAttachmentActionMenu.propTypes = exact(propTypes)
ResourcesTableAttachmentActionMenu.defaultProps = defaultProps

export default pure(ResourcesTableAttachmentActionMenu)
