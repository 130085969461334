import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { waitFor } from 'lp-hoc'
import { selectors as globalSelectors } from 'partner-portal-reducer'
import * as Types from 'types'
import { LoadingSpinner, Card, RenderedHtml } from 'components'
import { Stats } from '../components'
import { useCommunityText } from 'utils'

const propTypes = {
  partner: Types.partner.isRequired,
  reportYears: PropTypes.arrayOf(PropTypes.string).isRequired,
}

function Dashboard({ partner, reportYears }) {
  const t = useCommunityText()
  const dashboardText = t('partnerPortal.dashboard.welcomeMessage')

  return (
    <div>
      {dashboardText && (
        <Card>
          <div className="rendered-html">
            <RenderedHtml addAttrs={['target']}>{dashboardText}</RenderedHtml>
          </div>
        </Card>
      )}
      <Stats partner={partner} reportYears={reportYears} />
    </div>
  )
}

Dashboard.propTypes = propTypes

function mapStateToProps(state) {
  return {
    partner: globalSelectors.partner(state),
    reportYears: globalSelectors.reportYears(state),
  }
}

export default compose(
  connect(mapStateToProps),
  waitFor('partner', LoadingSpinner)
)(Dashboard)
