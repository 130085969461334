import React from 'react'

function PaperclipIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#4A4A4A" fillRule="nonzero" transform="rotate(-90 11.75 5.75)">
          <path d="M4.125 8C1.845 8 0 6.21 0 4s1.845-4 4.125-4H12c1.658 0 3 1.302 3 2.91 0 1.606-1.342 2.908-3 2.908H5.625C4.59 5.818 3.75 5.004 3.75 4s.84-1.818 1.875-1.818h5.625v1.09H5.625a.741.741 0 00-.75.728c0 .4.338.727.75.727H12c1.035 0 1.875-.814 1.875-1.818S13.035 1.091 12 1.091H4.125c-1.658 0-3 1.302-3 2.909 0 1.607 1.342 2.91 3 2.91h7.125V8H4.125z"></path>
        </g>
      </g>
    </svg>
  )
}

export default PaperclipIcon
