import logo from 'images/communities/Houston/logo.png'
import dashboardHeaderImage from 'images/communities/Houston/banner.jpg'
import loginBackgroundImg from 'images/communities/Houston/background-image.jpg'
import mapHeaderImage from 'images/communities/Houston/landing.jpg'
import favicon from 'images/communities/Houston/favicon.png'
import homeLogo from 'images/home/community-logos/houston.png'
import { SURVEY_QUESTIONS } from 'types'

const config = {
  subdomain: 'houston',
  name: 'Houston',
  position: 5,
  partnerSupportEmail: 'artlooksupport@artsconnecthouston.org',
  schoolSupportEmail: 'artlooksupport@artsconnecthouston.org',
  stylesheet: 'houston.scss',
  mapRedirectPath: '',
  organizationUrl: 'https://www.artsconnecthouston.org',
  faqUrl:
    'https://drive.google.com/file/d/1nTv22AqYmydKSDGeWjXRXY22bztUenrU/view?usp=sharing',
  mapEnabled: true,
  schoolPortalEnabled: true,
  partnerPortalEnabled: true,
  partnerProgramsEnabled: true,
  educatorEnabled: false,
  notificationsEnabled: true,
  notificationListSize: -1,
  dataDashboardEnabled: true,
  id: 10,
}

// ----- SEARCH -----
config.search = {
  options: {
    blockList: ['networks', 'creativeSchoolsCategory'],
  },
  schoolSearchFilters: [
    'network',
    'creativeSchoolsCategory',
    'instructorsTeaching',
    'schoolType',
    'organizationPartnersCount',
    'programsOffered',
    'disciplineInterests',
    'resourceProgramInterests',
    'coursesOffered',
    'studentDemographics',
    'schoolDistrict',
    'openToPartnerships',
  ],
  map: {
    center: { lat: 29.7594466, lng: -95.3702107 },
  },
}

// ----- BRANDING -----

config.defaultBranding = {
  className: 'hou-community',
  favicon,
  mapLogo: {
    src: logo,
    alt: "Houston's homepage",
    link: config.organizationUrl,
  },
  homeLogo: {
    src: homeLogo,
    alt: 'Arts Connect Houston',
  },
  communityLogo: {
    src: logo,
    alt: 'Artlook Schools',
  },
  dashboardHeader: {
    src: dashboardHeaderImage,
    alt: '',
  },
  loginBackgroundImg: {
    src: loginBackgroundImg,
    alt: '',
  },
  mapHero: {
    src: mapHeaderImage,
    alt: '',
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

config.displayText = {
  map: {
    title: 'artlook Map: Increasing Arts Education in Houston',
    searchHeader: 'Find Schools & Partners in Houston',
    welcomeSearchHeader: 'Explore Arts Education in Houston',
    welcomeHeader: 'artlook<sup>®</sup> Houston',
    welcomeMessage: `
      <p>
        artlook<sup>®</sup> is your one-stop-shop for information on arts education in Houston. With thousands of data points, artlook<sup>®</sup> provides the information you need to make strategic decisions about expanding the arts in Houston ISD.
      </p>
      <p>
        Developed in close coordination with Houston's arts educators, this platform is an important part of Arts Connect's collective vision: to ensure that all Houston students have access to a high quality arts education as a part of their complete education. artlook<sup>®</sup> is here to help you make new connections, tap into new opportunities, and find fresh insights through data.
      </p>
      <p>
        Start exploring artlook<sup>®</sup> today and help us expand arts education access, quality, and equity for every child, in every grade, in every school.
      </p>
    `,
  },
  tagline: "Let's increase equitable access to arts education in Houston",
  partnerPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> Houston</em> is YOUR gallery for arts education data</h3>
        <p>
          <em>artlook<sup>®</sup> Houston</em> is the only platform of its kind that combines data from Houston ISD schools and arts education program providers (like you!). The goal is to build direct connections between arts providers and schools to expand equitable access to quality arts education programming for EVERY student in our community.
        </p>
        <p>
          <strong>To get started, follow the links in the boxes below to share your organization's program and school partnership information.</strong>
        </p>
        <p>
          If you have any questions or need assistance, please contact <a href="mailto:${config.partnerSupportEmail}" target="_blank" rel="noopener noreferrer">${config.partnerSupportEmail}</a>.
        </p>
      `,
    },
  },
  schoolPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> Houston</em> is YOUR gallery for arts education data</h3>
        <p>
          <em>artlook<sup>®</sup> Houston</em> is the only platform of its kind that combines data from Houston ISD schools (like you!) and arts education program providers. The goal is to build direct connections between arts providers and schools to expand equitable access to quality arts education programming for EVERY student in our community.
        </p>
        <p>
          <strong>To get started, follow the link below to complete a brief survey about your school's arts education offerings and partnerships.</strong>
        </p>
        <p>
          If you have any questions or need assistance, please contact <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a>.
        </p>
      `,
    },
  },
  survey: {
    getStarted: `<h2>Welcome to the {{ surveySchoolYearNumber }} Creative Schools Survey!</h2>
    <p>Through this annual survey, you will be asked to share information about arts education staffing and instruction at your school, partnerships with arts partners (i.e. arts organizations), and how your school budgets and plans for the arts.</p>
    <p>As you work on the {{ surveySchoolYearNumber }} survey, please be sure to frequently save your responses to each question to avoid losing information. You are not required to complete your survey in one session; saving your responses as you go will allow you to return at any time to complete or edit your survey before submitting.</p>
    <p>Please feel free to reach out to <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a> with any questions!</p>
    <div class="callout">
      <p><strong>Benefits of completing the survey:</strong></p>
      <ul class="checkmark-list">
        <li><span>Completion makes your school eligible to receive dollars for arts education partnerships through the Arts Action Fund</span></li>
        <li><span>Use the artlook<sup>®</sup> Map to showcase your school and get connected to new arts education programs and resources, including virtual programs</span></li>
        <li><span>Help the HISD community advance towards excellent, equitable arts education for all students by providing data on needs and opportunities</span></li>
      </ul>
    </div>
    `,
    staffingQuestion: {
      modalMessage: `
        <p>
        <strong>Note on instructors:</strong> Restrict the list of instructors in your school to those teaching an arts course during the school day. Note that district-run/district-managed schools require arts instructors to hold a state certification or endorsement in one of the following arts disciplines: visual arts, media arts, music, theater, and/or dance. Charter schools, contract schools, and Pre-K-only schools do not have this requirement. If itinerant teachers teach at your school, please report those individuals as well.
        </p>
        <p>
        <strong>Note on part/full-time status:</strong> Please select Full Time if a teacher had full-time status at your school for the entire school year. If a teacher was part-time at your school for all or part of the year, or full-time for only part of the year, please select Part Time.
        </p>
        <p>
        <strong>Note on disciplines:</strong> The Primary Focus of each instructor is required. You may optionally list a Secondary Focus as well. Please also select the “Classroom/non-arts teacher?” button if the instructor is not primarily an arts instructor (e.g., non-arts, general education teacher teaching arts courses).
        </p>
        `,
      displayOptions: {
        showClassNumber: true,
      },
    },
    instructorQuestion: {
      displayOptions: {
        showInstructorEmail: true,
      },
    },
    scholarshipQuestion: {
      displayOptions: {
        showExample: true,
      },
    },
  },
}

// ----- SURVEY QUESTIONS CONFIG -----

const {
  APPROACHES,
  GOVERNANCE,
  OBSTACLES_SELECT,
  OBSTACLES_DESCRIBE,
  OBSTACLES_OVERCOME,
  DISTRICT_SPENDING,
  COMMUNITY_ENGAGEMENTS,
} = SURVEY_QUESTIONS

config.surveyQuestions = {
  approaches: APPROACHES.OPTIONS.PER_DISCIPLINE,
  governance: GOVERNANCE.OPTIONS.GENERAL,
  obstaclesSelect: OBSTACLES_SELECT.OPTIONS.GENERAL,
  obstaclesDescribe: OBSTACLES_DESCRIBE.OPTIONS.GENERAL,
  obstaclesOvercome: OBSTACLES_OVERCOME.OPTIONS.GENERAL,
  districtSpending: DISTRICT_SPENDING.OPTIONS.GENERAL,
  communityEngagement: COMMUNITY_ENGAGEMENTS.OPTIONS.GENERAL,
}

export default config
