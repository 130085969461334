import React from 'react'
import PropTypes from 'prop-types'
import { Modal as BaseModal } from 'lp-components'
import classnames from 'classnames'
import { useCommunity } from 'utils'
import { get } from 'lodash'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
}

const defaultProps = {
  className: '',
}

function Modal({ onClose, className, ...rest }) {
  const community = useCommunity()
  const communityClassName = get(community, 'defaultBranding.className')

  return (
    <BaseModal
      onClose={onClose}
      portalClassName={classnames('modal', className, communityClassName)}
      parentSelector={() => document.querySelector('main')}
      {...rest}
    />
  )
}

Modal.propTypes = propTypes
Modal.defaultProps = defaultProps

export default Modal
