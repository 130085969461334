import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import classnames from 'classnames'

const propTypes = {
  children: PropTypes.node.isRequired,
  invalid: PropTypes.bool.isRequired,
}

const defaultProps = {
  invalid: false,
}

// Wraps checkboxes that appear togther visually in the UI but are 2 separate fields
// Most common use case: a form with a CheckboxWithText input
function CheckboxFieldset({ invalid, children, ...rest }) {
  return (
    <fieldset
      className={classnames('checkboxes-fieldset', {
        'fieldset-wide-error': invalid,
      })}
      {...rest}
    >
      {children}
    </fieldset>
  )
}

CheckboxFieldset.propTypes = propTypes
CheckboxFieldset.defaultProps = defaultProps

export default pure(CheckboxFieldset)
