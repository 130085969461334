import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import {
  fieldPropTypesWithValue,
  omitLabelProps,
  LabeledField,
} from 'lp-components'
import Trix from 'trix' // eslint-disable-line no-unused-vars
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from 'react-trix-rte'
import { RTE_TOOLBAR_ACTIONS } from 'config'

const propTypes = {
  ...fieldPropTypesWithValue(PropTypes.string),
}

const defaultProps = {}

function RichTextInput(props) {
  const ref = useRef()
  const {
    input: { value, onChange, name },
    meta, // eslint-disable-line no-unused-vars
    className, // eslint-disable-line no-unused-vars
    ...rest
  } = omitLabelProps(props)

  return (
    <LabeledField {...props}>
      <ReactTrixRTEToolbar
        toolbarId="trix-announcement"
        toolbarActions={RTE_TOOLBAR_ACTIONS}
      />
      <ReactTrixRTEInput
        toolbarId="trix-announcement"
        trixInputRef={ref}
        onInitialize={() => {
          // clear out any value that was stored from the last time this was mounted (doesn't clear after submitting form)
          ref.current.editor.loadHTML(value)
        }}
        {...{
          id: name,
          name,
          onChange,
          value,
          ...rest,
        }}
      />
    </LabeledField>
  )
}

RichTextInput.propTypes = propTypes
RichTextInput.defaultProps = defaultProps

export default RichTextInput
