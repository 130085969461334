import React from 'react'
import PropTypes from 'prop-types'
import Content from './content'

const propTypes = {
  className: PropTypes.string,
}

function LoadingIndicator({ className }) {
  return <Content className={className}> Loading... </Content>
}

LoadingIndicator.propTypes = propTypes

export default LoadingIndicator
