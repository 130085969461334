import { isEmpty, first } from 'lodash'

// Check that the correct has many association collection has loaded based on the CoP ID

function hasLoadedAssociationForCommunityOfPractice(
  associatedCollection,
  communityOfPracticeId,
  { collectionKey = 'communityOfPracticeId' } = {}
) {
  if (!associatedCollection) return false
  // not _always_ true if empty, but more of an edge case as time goes on
  if (isEmpty(associatedCollection)) return true
  // CoP ID is 0 or null for the main Forum
  return [communityOfPracticeId, null].includes(
    first(associatedCollection)[collectionKey]
  )
}

export default hasLoadedAssociationForCommunityOfPractice
