import { combineReducers } from 'redux'
import {
  reducer as partnerReducer,
  reducerKey as partnerReducerKey,
} from './partners'
import {
  reducer as schoolReducer,
  reducerKey as schoolReducerKey,
} from './schools'
import {
  reducer as searchReducer,
  reducerKey as searchReducerKey,
} from './search'

const reducerKey = 'map'

const reducer = combineReducers({
  [partnerReducerKey]: partnerReducer,
  [schoolReducerKey]: schoolReducer,
  [searchReducerKey]: searchReducer,
})

export { reducer, reducerKey }
