import React from 'react'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import { Input, SubmitButton } from 'lp-components'
import {
  Field,
  FormSection,
  formValueSelector,
  propTypes as formPropTypes,
} from 'redux-form'
import { DirectoryFilterToolbar } from '../components'
import { isEmpty } from 'lodash'

const propTypes = {
  ...formPropTypes,
  filterOptions: Types.directoryFilterOptions.isRequired,
  selectedFilters: Types.directoryFilterSelections,
}

const defaultProps = {
  selectedFilters: {},
}

const FORM_NAME = 'educator-directory-search'

function DirectorySearchForm({
  filterOptions,
  selectedFilters,
  change,
  handleSubmit,
  initialValues,
  submitting,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate role="search">
      <div className="search-form">
        <div className="search-bar">
          <Field
            name="query"
            component={Input}
            label={false}
            aria-label="Enter search query (teacher or school name)"
            placeholder="Search directory by teacher or school name"
          />
          <SubmitButton
            {...{ submitting }}
            style="alert"
            data-cy="search-button"
          >
            Search
          </SubmitButton>
        </div>
      </div>
      {!isEmpty(filterOptions) && (
        <FormSection name="filters">
          <DirectoryFilterToolbar
            filterOptions={filterOptions}
            applyFilter={handleSubmit}
            resetFilter={(filterName) => {
              change(`filters.${filterName}`, '')
              setTimeout(handleSubmit, 0)
            }}
            selectedFilters={selectedFilters}
            appliedFilters={initialValues.filters}
          />
        </FormSection>
      )}
    </form>
  )
}

DirectorySearchForm.propTypes = propTypes
DirectorySearchForm.defaultProps = defaultProps

const formSelector = formValueSelector(FORM_NAME)

function mapStateToProps(state) {
  return {
    selectedFilters: formSelector(state, 'filters'),
  }
}

const mapDispatchToProps = {}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lpForm({ name: FORM_NAME })
)(DirectorySearchForm)
