import React from 'react'

function EditIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#5B5F66" fillRule="nonzero" transform="translate(4 4)">
          <path d="M11.805 2.695a.664.664 0 000-.94l-1.56-1.56a.664.664 0 00-.94 0L8.08 1.415l2.5 2.5M0 9.5V12h2.5l7.372-7.379-2.5-2.5L0 9.501z"></path>
        </g>
      </g>
    </svg>
  )
}

export default EditIcon
