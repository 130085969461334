import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import * as icons from './basic'
import { get, trim, startCase } from 'lodash'

const propTypes = {
  primaryFocusName: PropTypes.string,
}

const defaultProps = {}

function InstructorIcon({ primaryFocusName }) {
  // default to a generic icon when there is no primary focus or specific icon for a focus
  const Icon =
    get(icons, `${upperCamelCase(primaryFocusName)}Icon`) || icons.ProgramsIcon
  return <Icon />
}

// Transforms string to UpperCamelCase (e.g., visual_arts -> VisualArts)
function upperCamelCase(str = '') {
  const trimmed = trim(str)
  if (!trimmed) return ''

  return startCase(trimmed).replace(/\s+/, '')
}

InstructorIcon.propTypes = propTypes
InstructorIcon.defaultProps = defaultProps

export default pure(InstructorIcon)
