import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

function Checklist({ title, children }) {
  return (
    <div className="checklist-block">
      <p>
        <strong>{title}</strong>
      </p>
      <ul>{children}</ul>
    </div>
  )
}

Checklist.propTypes = propTypes

export default Checklist
