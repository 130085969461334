import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import ExternalLink from './external-link'
import { formatPhoneNumber } from 'utils'

const propTypes = {
  phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
}

const defaultProps = {}

function PhoneLink({ phoneNumber, ...rest }) {
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber)
  return (
    <ExternalLink href={'tel:' + formattedPhoneNumber} {...rest}>
      {formattedPhoneNumber}
    </ExternalLink>
  )
}

PhoneLink.propTypes = propTypes
PhoneLink.defaultProps = defaultProps

export default pure(PhoneLink)
