import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { selectors } from './reducer'
import { createCopPaths } from 'utils'
import * as apiActions from 'api-actions'
import { Spinner } from 'lp-components'

const propTypes = {
  communityOfPractice: Types.communityOfPractice.isRequired,
  folder: Types.showFolder,
  fetchFolder: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  params: PropTypes.object.isRequired,
}

const defaultProps = {
  folder: null,
}

function ResourcesLayout({
  communityOfPractice,
  fetchFolder,
  folder,
  children,
  params: { folderId },
}) {
  const communityOfPracticeId = parseInt(communityOfPractice.id)
  const { copWorkspacePath, copResourcesIndexPath } = createCopPaths(
    communityOfPracticeId
  )

  const hasCorrectFolder = folder && folder.id == folderId

  useEffect(() => {
    if (folderId) fetchFolder({ communityOfPracticeId, folderId })
  }, [communityOfPracticeId, folderId])

  if (folderId && !hasCorrectFolder) return <Spinner />

  const createPath = () => {
    if (!folderId) return copWorkspacePath
    if (folder.folderableType === Types.FOLDERABLE_TYPE.COMMUNITY_OF_PRACTICE)
      return copResourcesIndexPath
    return `${copResourcesIndexPath}/${folder.folderableId}`
  }

  const createLinkName = () => {
    if (!folderId) return communityOfPractice.name
    if (folder.folderableType === Types.FOLDERABLE_TYPE.COMMUNITY_OF_PRACTICE)
      return 'Resource Library'
    return folder.folderableName
  }

  return (
    <div>
      <Link className="link-secondary no-underline" to={createPath()}>
        ← <span className="underline">Return to {createLinkName()}</span>
      </Link>
      {children}
    </div>
  )
}

ResourcesLayout.propTypes = propTypes
ResourcesLayout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    communityOfPractice: selectors.communityOfPractice(state),
    folder: selectors.folder(state),
  }
}

const mapDispatchToProps = {
  fetchFolder: apiActions.fetchFolder,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ResourcesLayout
)
