import { get } from 'lodash'

/* Returns combined first and last name if profile data exists and names (or name) are populated
(see type 'profile' and 'forumUser'), otherwise returns provided email. */

function getUserDisplayName(profile, email) {
  if (!profile) return email
  const { name } = profile
  if (name) return name
  const firstName = get(profile, 'firstName') || ''
  const lastName = get(profile, 'lastName') || ''
  const fullName = `${firstName} ${lastName}`
  return fullName.trim() || email
}

export default getUserDisplayName
