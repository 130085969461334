import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { toggle, togglePropTypes } from 'lp-hoc'
import classnames from 'classnames'

const propTypes = {
  children: PropTypes.node,
  hideExpand: PropTypes.bool,
  label: PropTypes.string,
  stat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ...togglePropTypes('expanded'),
}

const defaultProps = {
  hideExpand: false,
  label: '',
  stat: 0,
}

function StatsListMetricItem({
  categoryValue,
  children,
  expanded,
  hideExpand,
  label,
  stat,
  toggleExpanded,
}) {
  return (
    <li
      className={classnames(`cat${categoryValue}`, {
        'active-trigger': !hideExpand && expanded,
      })}
    >
      <div className="stat-block card">
        <div className="stat-inner">
          <span>{stat}</span>
          <p>{label}</p>
          {!hideExpand && (
            <button
              onClick={toggleExpanded}
              className="stat-trigger"
              aria-label={`Toggle ${label} Details`}
              aria-expanded={expanded}
              aria-controls={`stat-item-${categoryValue}`}
            />
          )}
        </div>
        <div id={`stat-item-${categoryValue}`}>
          {children && expanded && (
            <div className="stat-details">
              <div className="stat-content">{children}</div>
            </div>
          )}
        </div>
      </div>
    </li>
  )
}

StatsListMetricItem.propTypes = propTypes

StatsListMetricItem.defaultProps = defaultProps

export default compose(toggle('expanded'))(StatsListMetricItem)
