import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { useCommunity } from 'utils'
import { ExternalLink } from 'components'

const propTypes = {}

const defaultProps = {}

function IncompleteSurveyNote() {
  const community = useCommunity()
  return (
    <section>
      <div className="content-block-container">
        <p className="neutral-callout">
          The information shown below is based on district / state school
          administrative data or on limited information collected from this
          school or a partnering arts organization. A more complete school arts
          profile will be available when the school submits its annual arts
          survey. For details about how to update your school's arts education
          information, please contact us at{' '}
          <ExternalLink href={`mailto:${community.schoolSupportEmail}`}>
            {community.schoolSupportEmail}
          </ExternalLink>
          .
        </p>
      </div>
    </section>
  )
}

IncompleteSurveyNote.propTypes = exact(propTypes)
IncompleteSurveyNote.defaultProps = defaultProps

export default pure(IncompleteSurveyNote)
