// If bookmarked is true (the filter is checked), return filters object with an `id` attribute of bookmarked IDs

function setBookmarkedFilter(filters, bookmarkedIds) {
  const { bookmarked, ...rest } = filters
  if (!bookmarked) return rest
  return {
    id: bookmarkedIds,
    ...rest,
  }
}

export default setBookmarkedFilter
