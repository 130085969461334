import React from 'react'

function ViewIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#5B5F66" fillRule="nonzero" transform="translate(3 5)">
          <path d="M7 .346c-3.182 0-5.9 2.073-7 5 1.1 2.927 3.818 5 7 5s5.9-2.073 7-5c-1.1-2.927-3.818-5-7-5zM7 8.68c-1.756 0-3.182-1.494-3.182-3.334S5.244 2.012 7 2.012s3.182 1.494 3.182 3.334S8.756 8.68 7 8.68zm0-5.334c-1.056 0-1.909.894-1.909 2 0 1.106.853 2 1.909 2s1.909-.894 1.909-2c0-1.106-.853-2-1.909-2z"></path>
        </g>
      </g>
    </svg>
  )
}

export default ViewIcon
