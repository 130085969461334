import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { pure } from 'recompose'
import { Field } from 'redux-form'
import { Searchable, HiddenLegend, EmptyState } from 'components'
import { CheckboxGroup } from 'lp-components'
import { isEmpty, toLower, trim, includes, startCase } from 'lodash'

const propTypes = {
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(Types.formOptionType).isRequired,
  component: PropTypes.func,
  emptyMessage: PropTypes.string,
  fieldLabel: PropTypes.string,
  onSearch: PropTypes.func,
}

const defaultProps = {
  component: CheckboxGroup,
  emptyMessage: 'Sorry, no filters match your search',
  fieldLabel: null,
  onSearch: null,
}

function SearchableCheckboxes({
  component,
  emptyMessage,
  fieldName,
  fieldLabel,
  onSearch,
  options,
}) {
  const filterOptions = useCallback(
    (query) => {
      // Short circuit logic if empty string
      const normalizedQuery = trim(toLower(query))
      if (isEmpty(normalizedQuery)) return options

      return options.filter((option) => {
        return includes(toLower(option.key), normalizedQuery)
      })
    },
    [options]
  )
  const label = fieldLabel || startCase(fieldName)

  return (
    <Searchable
      initialResults={options}
      onSearch={onSearch || filterOptions}
      label={'Search ' + label}
    >
      {(results) => {
        if (isEmpty(results)) return <EmptyState message={emptyMessage} />
        return (
          <Field
            name={fieldName}
            component={component}
            label={label}
            labelComponent={HiddenLegend}
            options={results}
          />
        )
      }}
    </Searchable>
  )
}

SearchableCheckboxes.propTypes = propTypes
SearchableCheckboxes.defaultProps = defaultProps

export default pure(SearchableCheckboxes)
