import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, HiddenSpan } from 'components'
import * as Types from 'types'
import { CopyPartnershipsForm } from '../forms'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  partnerships: PropTypes.arrayOf(Types.partnership),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  schoolYears: PropTypes.arrayOf(Types.schoolYear).isRequired,
}

const defaultProps = {
  partnerships: [],
}

function CopyPartnershipModal({
  onClose,
  partnerships,
  onSubmit,
  onSubmitSuccess,
  schoolYears,
}) {
  const [submitFailCount, setSubmitFailCount] = useState(0)
  return (
    <Modal onClose={onClose}>
      <h2>Copy Existing Partnership</h2>
      <div className="modal-content">
        <p>
          Copying partnerships from previous school years will copy both the
          partnership and the associated program types (e.g., field trip,
          residency, in-school performance, etc.) for that partnership for that
          school year.{' '}
        </p>
        <p>
          Because the program types that occurred between you and your partner
          may be different each year, you may see the same school or
          organization listed multiple times below (with different school
          years).{' '}
        </p>
        <p>
          You can always adjust your partnerships and program types for the
          current year after copying information from previous years.{' '}
        </p>
        {partnerships.length ? (
          <CopyPartnershipsForm
            partnerships={partnerships}
            onSubmit={onSubmit}
            onSubmitSuccess={onSubmitSuccess}
            onSubmitFail={() => {
              setSubmitFailCount((sfc) => sfc + 1)
            }}
            schoolYears={schoolYears}
          >
            {(error) => (
              <div role="alert" aria-atomic>
                {error && (
                  <p className="full-error-message">
                    <span className="visually-hidden">Form invalid: </span>
                    {/* The hidden count will force screen readers to announce
                     the error again even when it's exactly the same */}
                    {error} <HiddenSpan>{submitFailCount}</HiddenSpan>
                  </p>
                )}
              </div>
            )}
          </CopyPartnershipsForm>
        ) : (
          <p>
            <strong>No other partnerships found.</strong>
          </p>
        )}
      </div>
    </Modal>
  )
}

CopyPartnershipModal.propTypes = propTypes
CopyPartnershipModal.defaultProps = defaultProps

export default CopyPartnershipModal
