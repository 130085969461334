import React from 'react'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { Textarea, SubmitButton, ButtonArea } from 'lp-components'
import { persistSubmitSucceeded } from 'utils'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

function ObstaclesOvercomeForm({
  handleSubmit,
  submitSucceeded,
  pristine,
  submitting,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="overcome"
        label={false}
        aria-labelledby="overcome-question"
        component={Textarea}
      />
      <ButtonArea>
        <SubmitButton
          {...{ pristine: submitSucceeded && pristine, submitting }}
        >
          Save Response
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

ObstaclesOvercomeForm.propTypes = propTypes
ObstaclesOvercomeForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'school-obstacles-overcome',
    constraints: {
      overcome: {
        length: {
          maximum: 1000,
          message: '^Description is too long (maximum is 1000 characters)',
        },
      },
    },
  }),
  persistSubmitSucceeded()
)(ObstaclesOvercomeForm)
