import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { onError } from 'lp-hoc'
import { logException } from 'sentry'
import { Header, Footer } from 'components'
import {
  useCommunityText,
  useDocumentTitle,
  initializeAnonymousUserflow,
  useCommunity,
} from 'utils'

const propTypes = {
  children: PropTypes.node,
}

function Layout({ children }) {
  const t = useCommunityText()
  useDocumentTitle(t('map.title') || 'artlook Map')

  // Temporarily disable userflow on public facing sites
  // for every community but Baltimore
  const community = useCommunity()
  if (community.name === 'Baltimore') {
    initializeAnonymousUserflow()
  }

  return (
    <div className="map">
      <Header />
      <div id="content">
        {children}
        <Footer />
      </div>
    </div>
  )
}

Layout.propTypes = propTypes

function onComponentDidCatch(_, error, errorInfo) {
  return logException(error, errorInfo)
}

export default compose(onError(onComponentDidCatch))(Layout)
