import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Link } from 'react-router'
import classnames from 'classnames'
import { throttle } from 'lodash'

const SCROLL_OFFSET = 160 // top: 114; bottom: 180

const propTypes = {
  baseUrl: PropTypes.string.isRequired,
  message: PropTypes.string,
  schoolYears: PropTypes.arrayOf(Types.schoolYear).isRequired,
  selectedSchoolYearId: PropTypes.number,
}

const defaultProps = {
  message: '',
  selectedSchoolYearId: null,
}

function useEventListener(eventName, callback) {
  useEffect(() => {
    window.addEventListener(eventName, callback)
    return () => window.removeEventListener(eventName, callback)
  }, [])
}

function SchoolYearNav({
  baseUrl,
  message,
  schoolYears,
  selectedSchoolYearId,
}) {
  const [isScrolled, setIsScrolled] = useState(false)

  const onScroll = useCallback(
    throttle(() => {
      const windowHeight = window.outerHeight
      const scrollHeight = document.documentElement.offsetHeight

      // If there isn't much off screen content, don't activate window
      const isWindowScrollable =
        scrollHeight - windowHeight > SCROLL_OFFSET / 1.75
      return setIsScrolled(
        isWindowScrollable && window.pageYOffset > SCROLL_OFFSET
      )
    }, 100),
    []
  )

  useEventListener('scroll', onScroll)

  return (
    <nav
      className={classnames('school-year', { scrolled: isScrolled })}
      aria-label="school years"
    >
      {!!message && <p>{message}</p>}
      {schoolYears.map((schoolYear) => {
        const isActive = schoolYear.id === selectedSchoolYearId
        return (
          <Link
            key={schoolYear.id}
            className={classnames({
              'button-primary-outline': !isActive,
              'button-primary': isActive,
            })}
            to={`${baseUrl}?year=${schoolYear.id}`}
          >
            {schoolYear.number}
          </Link>
        )
      })}
    </nav>
  )
}

SchoolYearNav.propTypes = exact(propTypes)
SchoolYearNav.defaultProps = defaultProps

export default pure(SchoolYearNav)
