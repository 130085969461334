import React from 'react'

function GearIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#4A4A4A"
          fillRule="nonzero"
          d="M14.269 8.774c-.072-.073-.07-1.48 0-1.55 0 0 1.441-.843 1.455-.856a.999.999 0 00.24-.94c-.001-.007-.004-.013-.006-.02l-.503-1.215a1.003 1.003 0 00-.836-.521c-.021 0-1.662.439-1.662.439-.141-.024-1.066-1.037-1.07-1.097 0 0 .422-1.612.423-1.63a.99.99 0 00-.495-.833l-.018-.008-1.21-.502a.317.317 0 00-.02-.008c-.295-.093-.721.017-.937.241a220.1 220.1 0 00-.856 1.456c-.074.07-1.459.075-1.55-.005 0 0-.84-1.438-.854-1.451C6.154.05 5.73-.06 5.432.033a.26.26 0 00-.02.007l-1.21.503a.99.99 0 00-.512.84c0 .019.424 1.635.424 1.635-.001.037-.953 1.074-1.076 1.091 0 0-1.644-.437-1.664-.437a1.001 1.001 0 00-.83.521l-.502 1.22-.007.018a.993.993 0 00.245.942c.01.01 1.456.95 1.456.95.068.076.069 1.363-.01 1.453 0 0-1.437.84-1.45.853a.996.996 0 00-.234.958l.503 1.21.008.018c.143.277.504.494.83.494l1.637-.424c.13.002 1.089 1.04 1.093 1.1 0 0-.422 1.611-.423 1.63a.99.99 0 00.495.832l.018.008 1.21.503a.316.316 0 00.02.007c.295.094.721-.017.937-.24.013-.014.856-1.456.856-1.456.073-.072 1.458-.076 1.55.004 0 0 .84 1.438.854 1.451.162.168.435.276.695.276a.804.804 0 00.262-.042l1.21-.503a.99.99 0 00.512-.84c0-.019-.423-1.635-.423-1.635.001-.036.96-1.09 1.095-1.095 0 0 1.62.424 1.64.424.32 0 .683-.217.826-.495a.248.248 0 00.008-.017l.503-1.21a.996.996 0 00-.234-.958c-.014-.013-1.455-.855-1.455-.855zM7.999 11C6.347 11 5 9.654 5 8s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3z"
        ></path>
      </g>
    </svg>
  )
}

export default GearIcon
