import { useState, useCallback } from 'react'

// Create the foundational setup needed to dynamically populate an InfoModal component with
// content (including an optional header) and show/hide it by combining a couple state functions
function useDynamicInfoModal() {
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [infoModalContent, setInfoModalContent] = useState({
    content: '',
    header: '',
  })
  const setShowInfoModalWithContent = useCallback(
    (content, { header = '' } = {}) => {
      if (!content && !header) return setShowInfoModal(false)
      setInfoModalContent({ content, header })
      setShowInfoModal(true)
    },
    [setInfoModalContent, setShowInfoModal]
  )

  return [showInfoModal, infoModalContent, setShowInfoModalWithContent]
}

export default useDynamicInfoModal
