import { useEffect, useState } from 'react'
import { DEFAULT_DOCUMENT_TITLE } from 'config'

function getTitleName(titleFuncOrString, props) {
  return typeof titleFuncOrString === 'function'
    ? titleFuncOrString(props)
    : titleFuncOrString
}

function useDocumentTitle(titleFuncOrString) {
  const [priorTitle] = useState(document.title)
  return useEffect(() => {
    const newTitle = getTitleName(titleFuncOrString) || DEFAULT_DOCUMENT_TITLE
    document.title = newTitle

    // reset title on unmount
    return () => {
      document.title = priorTitle
    }
  }, [])
}

export default useDocumentTitle
