import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Field } from 'redux-form'
import { includes, get } from 'lodash'
import { Checkbox } from 'lp-components'

const propTypes = {
  programs: PropTypes.arrayOf(Types.program).isRequired,
  currentAccessiblePrograms: Types.accessiblePrograms.isRequired,
  handleOfferingChange: PropTypes.func.isRequired,
}

const defaultProps = {}

const { NONE, BOTH, IN_SCHOOL, OUT_OF_SCHOOL } =
  Types.SUB_DISCIPLINE_OFFER_TYPES

function oppositeOfferingType(offeringType) {
  return offeringType === IN_SCHOOL ? OUT_OF_SCHOOL : IN_SCHOOL
}

function offeringToBoolean(offering, offeringType) {
  return includes([BOTH, offeringType], offering)
}

function booleanToOffering(checked, offeringType, currentOffering) {
  if (checked) {
    if (currentOffering === NONE) return offeringType
    if (currentOffering !== offeringType && currentOffering !== BOTH)
      return BOTH
    return currentOffering
  }
  if (currentOffering === BOTH) return oppositeOfferingType(offeringType)
  if (currentOffering === offeringType) return NONE
  return currentOffering
}

function AccessibleProgramsTable({
  programs,
  currentAccessiblePrograms,
  handleOfferingChange,
}) {
  return (
    <table>
      <thead>
        <tr>
          <th aria-label="Programs"></th>
          <th id={IN_SCHOOL}>In-School</th>
          <th id={OUT_OF_SCHOOL}>Out-of-School</th>
        </tr>
      </thead>
      <tbody>
        {programs.map((program) => {
          const programTagKey = program.name + '.tag'
          const currentOffering = get(
            currentAccessiblePrograms,
            programTagKey,
            NONE
          )
          return (
            <tr
              key={program.id}
              role="group"
              aria-label={`Offerings for ${program.displayName}`}
            >
              <td id={program.name}>{program.displayName}</td>
              <td>
                <Field
                  name={programTagKey}
                  label={false}
                  aria-labelledby={`${program.name} ${IN_SCHOOL}`}
                  component={Checkbox}
                  format={(value) => offeringToBoolean(value, IN_SCHOOL)}
                  parse={(value) =>
                    booleanToOffering(value, IN_SCHOOL, currentOffering)
                  }
                  onChange={(e, newValue, prevValue, fieldName) =>
                    handleOfferingChange({
                      fieldName,
                      newOffering: newValue,
                      programName: program.name,
                      programId: program.id,
                    })
                  }
                />
              </td>
              <td>
                <Field
                  name={programTagKey}
                  label={false}
                  aria-labelledby={`${program.name} ${OUT_OF_SCHOOL}`}
                  component={Checkbox}
                  format={(value) => offeringToBoolean(value, OUT_OF_SCHOOL)}
                  parse={(value) =>
                    booleanToOffering(value, OUT_OF_SCHOOL, currentOffering)
                  }
                  onChange={(e, newValue, prevValue, fieldName) =>
                    handleOfferingChange({
                      fieldName,
                      newOffering: newValue,
                      programName: program.name,
                      programId: program.id,
                    })
                  }
                />
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

AccessibleProgramsTable.propTypes = exact(propTypes)
AccessibleProgramsTable.defaultProps = defaultProps

export default pure(AccessibleProgramsTable)
