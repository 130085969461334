import { format } from 'date-fns'

// Update expires at value to the format expected by the API

function formatAnnouncementDate(announcement) {
  const formattedDate = format(announcement.expiresAt, 'YYYY/MM/DD')
  return {
    ...announcement,
    expiresAt: formattedDate,
  }
}

export default formatAnnouncementDate
