import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { useOnOutsideClick } from 'utils'
import { Manager, Reference, Popper } from 'react-popper'
import classnames from 'classnames'

const propTypes = {
  ariaPressed: PropTypes.bool,
  buttonTitle: PropTypes.string.isRequired,
  buttonTitleComponent: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  firstItemRef: PropTypes.object,
  startExpanded: PropTypes.bool,
}

const defaultProps = {
  ariaPressed: null,
  buttonTitleComponent: DefaultButtonTitleComponent,
  className: '',
  firstItemRef: null,
  startExpanded: false,
}

const CLOSE_KEYS = ['27']

function DefaultButtonTitleComponent({ title }) {
  return title
}

// Widget with "smart" floating menu that follows the aria-authoring practices disclosure pattern
function DisclosurePopover({
  children,
  className,
  buttonTitle,
  buttonTitleComponent: ButtonTitleComponent,
  firstItemRef,
  startExpanded,
  ariaPressed,
}) {
  const [isOpen, setIsOpen] = useState(startExpanded)
  const containerRef = useOnOutsideClick(() => setIsOpen(false))
  const buttonRef = useRef(null)

  const maybeClose = (e) => {
    const key = (e.which || e.keyCode || '').toString()
    if (!isOpen) return
    if (CLOSE_KEYS.includes(key)) {
      setIsOpen(false)
      buttonRef.current.focus()
    }
  }

  useEffect(() => {
    if (isOpen && firstItemRef) {
      firstItemRef.current.focus()
    }
  }, [isOpen, firstItemRef])

  return (
    <div
      ref={containerRef}
      onKeyUp={maybeClose}
      className={classnames('disclosure-popover', className)}
    >
      <Manager>
        <Reference>
          {({ ref: registerRef }) => {
            return (
              <button
                type="button"
                ref={(e) => {
                  registerRef(e)
                  buttonRef.current = e
                }}
                onClick={() => setIsOpen(!isOpen)}
                aria-haspopup="true"
                aria-expanded={isOpen}
                aria-pressed={ariaPressed || null}
              >
                <ButtonTitleComponent title={buttonTitle} />
              </button>
            )
          }}
        </Reference>
        <div hidden={!isOpen || null} className="disclosure-popover-content">
          {isOpen && (
            <Popper placement="bottom-start">
              {({ ref, style, placement }) => (
                <div ref={ref} style={style} data-placement={placement}>
                  {children}
                </div>
              )}
            </Popper>
          )}
        </div>
      </Manager>
    </div>
  )
}

DisclosurePopover.propTypes = exact(propTypes)
DisclosurePopover.defaultProps = defaultProps

export default pure(DisclosurePopover)
