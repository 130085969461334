import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import {
  Input,
  Select,
  SubmitButton,
  fieldOptionsType,
  ButtonArea,
} from 'lp-components'
import { Field, propTypes as formPropTypes } from 'redux-form'
import {
  CheckboxGroup,
  AttachmentsLabel,
  MultiAttachmentInput,
  Legend,
} from 'components'
import { ComboBox, MentionsInput } from '../components'
import {
  parseIdsToObjects,
  formatObjectsToIds,
  getDiscussionAttachmentUploadPath,
  useCommunity,
} from 'utils'
import { MAX_CHARACTER_LENGTH } from 'config'
import { isEmpty } from 'lodash'
import { ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'

const propTypes = {
  ...formPropTypes,
  disciplineOptions: fieldOptionsType.isRequired,
  schoolTypeOptions: fieldOptionsType.isRequired,
  topicOptions: fieldOptionsType.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  mentionSuggestions: PropTypes.arrayOf(Types.mentionSuggestion),
}

const defaultProps = {}

function PostForm({
  disciplineOptions,
  schoolTypeOptions,
  topicOptions,
  handleSubmit,
  submitting,
  isEditing,
  onCancel,
  flashSuccessMessage,
  flashErrorMessage,
  mentionSuggestions,
}) {
  const community = useCommunity()
  const communityId = community.id

  const validateContent = useCallback((content = '') => {
    if (content.length > MAX_CHARACTER_LENGTH)
      return `Post must be less than ${MAX_CHARACTER_LENGTH} characters`

    const contentState = ContentState.createFromBlockArray(
      htmlToDraft(content).contentBlocks
    )
    const contentText = contentState.getPlainText()
    if (isEmpty(contentText)) return "Post can't be blank"
  }, [])

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field name="title" label="Discussion Thread Title" component={Input} />
      <Field
        name="content"
        label="Description"
        component={MentionsInput}
        mentionSuggestions={mentionSuggestions}
        validate={validateContent}
      />
      <Field
        name="disciplines"
        label="Discipline"
        className="horizontal post-discipline"
        labelComponent={Legend}
        component={CheckboxGroup}
        options={disciplineOptions}
        format={formatObjectsToIds('disciplineId')}
        parse={parseIdsToObjects('disciplineId')}
      />
      <Field
        name="category"
        label="School Type"
        component={Select}
        options={schoolTypeOptions}
        placeholder="Select"
      />
      <Field
        name="topics"
        label="Topic Tags"
        subLabel="Help other artlook Educator users find your discussion! Add topic tags to assist their search."
        component={ComboBox}
        options={topicOptions}
        isMulti={true}
        format={formatObjectsToIds('topicId')}
        parse={parseIdsToObjects('topicId')}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        blurInputOnSelect={false}
      />
      <Field
        name="attachments"
        labelComponent={AttachmentsLabel}
        component={MultiAttachmentInput}
        uploadPath={getDiscussionAttachmentUploadPath(communityId, 'post')}
        flashSuccessMessage={flashSuccessMessage}
        flashErrorMessage={flashErrorMessage}
        className="attachments-field keep-bottom-margin"
      />
      <ButtonArea>
        <SubmitButton {...{ submitting }}>
          {isEditing ? 'Save Changes' : 'Start Discussion'}
        </SubmitButton>
        <button type="button" className="button-grey-light" onClick={onCancel}>
          Cancel
        </button>
      </ButtonArea>
    </form>
  )
}

PostForm.propTypes = propTypes
PostForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'PostForm',
    constraints: {
      title: {
        presence: true,
        length: { maximum: 255 },
      },
      disciplines: {
        presence: true,
      },
      category: {
        presence: true,
      },
    },
  })
)(PostForm)
