import React from 'react'
// import PropTypes from 'prop-types'
// import * as Types from 'types'
import { Input } from 'lp-components'
import {
  generateInputErrorId,
  filterInvalidDOMProps,
} from 'lp-components/lib/utils'
import { fieldPropTypes } from 'lp-components/lib/forms/helpers'
import classnames from 'classnames'

const propTypes = {
  ...fieldPropTypes,
}

const defaultProps = {}

function InputWithWarnings(props) {
  const { className, ...rest } = props

  return (
    <>
      <Input {...props} />
      {!props.meta.error && props.meta.warning && (
        <span
          id={generateInputErrorId(props.name)}
          className={classnames(['warning-message', className])}
          {...filterInvalidDOMProps(rest)}
        >
          {props.meta.warning}
        </span>
      )}
    </>
  )
}

InputWithWarnings.propTypes = propTypes
InputWithWarnings.defaultProps - defaultProps

export default InputWithWarnings
