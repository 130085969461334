import React from 'react'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Input, SubmitButton, ButtonArea } from 'lp-components'
import { Field, propTypes as formPropTypes } from 'redux-form'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

function ClaimAccountForm({ error, handleSubmit, submitting }) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      {error && <p className="full-error-mesage">error</p>}
      <Field name="email" type="email" component={Input} />
      <ButtonArea>
        <SubmitButton submitting={submitting}>Submit</SubmitButton>
      </ButtonArea>
    </form>
  )
}

ClaimAccountForm.propTypes = propTypes
ClaimAccountForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'ClaimAccountForm',
    constraints: {
      email: { presence: true, email: true },
    },
  })
)(ClaimAccountForm)
