import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { modifyProps } from 'lp-hoc'
import * as Types from 'types'
import GoogleMap from 'google-map-react'
import { mapStyles as styles, useCommunity, mapBoundaryUrl } from 'utils'
import MapMarker from '../map-marker'
import { fitToLocations, findCenter } from './helpers'
import { compact, groupBy, map, flatten, first } from 'lodash'
import { scroller } from 'react-scroll'

const MAP_SIZE = { height: 500, width: 1000 }
const DEFAULT_ZOOM = 8

const propTypes = {
  locations: PropTypes.arrayOf(Types.location),
  autoZoom: PropTypes.bool,
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  height: PropTypes.number,
  hidePopup: PropTypes.bool,
  selectedLocation: Types.location,
  setSelectedLocation: PropTypes.func.isRequired,
  width: PropTypes.number,
  zoom: PropTypes.number,
  noScroll: PropTypes.bool,
}

const defaultProps = {
  locations: [],
  height: MAP_SIZE.height,
  autoZoom: false,
  hidePopup: false,
  selectedLocation: null,
  width: null,
  zoom: DEFAULT_ZOOM,
  noScroll: false,
}

function BaseMapDisplay({
  height,
  locations,
  selectedLocation,
  setSelectedLocation,
  hidePopup,
  width,
  noScroll,
}) {
  const hasCustomWidth = width !== MAP_SIZE.width

  const community = useCommunity()
  const { search } = community

  const boundaryUrl = mapBoundaryUrl(community.subdomain)
  const mapCenter = search.map.center
  const mapRadius = search.map.radius

  const { center, zoom } = useMemo(() => {
    return fitToLocations(locations, { height, width }, mapCenter, mapRadius)
  }, [locations])

  if (!center || !zoom) return null

  return (
    <div
      style={{
        height,
        overflow: 'hidden',
        width: hasCustomWidth ? width : '100%',
      }}
      className="google-map"
      data-cy="map-display"
    >
      <GoogleMap
        bootstrapURLKeys={{
          key: process.env.GOOGLE_MAPS_API_KEY,
        }}
        defaultCenter={mapCenter}
        center={findCenter(selectedLocation, center)}
        defaultZoom={DEFAULT_ZOOM}
        zoom={zoom}
        options={{ styles, clickableIcons: false, fullscreenControl: false }}
        onGoogleApiLoaded={({ map }) => {
          map.data.loadGeoJson(boundaryUrl)
          map.data.setStyle(() => ({ fillColor: 'gray', strokeWeight: 1 }))

          // Add title for a11y
          map
            .getDiv()
            .querySelector('iframe')
            .setAttribute('title', 'Google Maps Display')
        }}
        yesIWantToUseGoogleMapApiInternals
      >
        {locations.map((location, i) => (
          <MapMarker
            key={i}
            lat={location.latitude}
            lng={location.longitude}
            isSelected={
              !!selectedLocation &&
              location.resourceId === selectedLocation.resourceId
            }
            onClick={(selectedLocation) => {
              setSelectedLocation(selectedLocation)
              if (!selectedLocation || noScroll) return
              const elementId = `result-${selectedLocation.resourceId}`
              scroller.scrollTo(elementId, {
                containerId: 'search-results',
                smooth: true,
                offset: -15,
              })
            }}
            location={location}
            hidePopup={hidePopup}
          />
        ))}
      </GoogleMap>
    </div>
  )
}

BaseMapDisplay.propTypes = propTypes
BaseMapDisplay.defaultProps = defaultProps

function setDefaultSize({ height, width }) {
  return {
    height: height || MAP_SIZE.height,
    width: width || MAP_SIZE.width,
  }
}

function formatSingleLocations(locations) {
  return locations.map((location) => {
    return {
      ...location,
      latitude: Number(location.latitude),
      longitude: Number(location.longitude),
    }
  })
}

function formatGroupedLocations(locations) {
  const location = first(locations)
  return {
    ...location,
    latitude: Number(location.latitude),
    longitude: Number(location.longitude),
    entries: locations,
  }
}

function formatLatLong(values, key) {
  const filteredLocations = values.filter((location) => location.latitude)
  if (filteredLocations.length === 0) return
  if (key === Types.UNIQUE_LOCATIONS_KEY || filteredLocations.length === 1)
    return formatSingleLocations(filteredLocations)
  return formatGroupedLocations(filteredLocations)
}

function formatLocations({ locations }) {
  const groupedLocations = groupBy(compact(locations), (location) => {
    return !location.street ? location.zip : Types.UNIQUE_LOCATIONS_KEY
  })
  const formattedLocations = flatten(map(groupedLocations, formatLatLong))
  return {
    locations: compact(formattedLocations),
  }
}

export default compose(
  modifyProps(setDefaultSize),
  modifyProps(formatLocations)
)(BaseMapDisplay)
