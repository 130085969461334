// Returns disciplines with their subdisciplines filtered to remove those that are the same as their parent

function removeParentDisciplines(disciplines) {
  return disciplines.map((discipline) => {
    const { subDisciplines, name: disciplineName } = discipline
    const filteredSubDisciplines = subDisciplines.filter(
      (subDiscipline) => subDiscipline.name !== disciplineName
    )
    return { ...discipline, subDisciplines: filteredSubDisciplines }
  })
}

export default removeParentDisciplines
