import React from 'react'
import PropTypes from 'prop-types'
import { stringify } from 'query-string'
import { ExternalLink } from 'components'

const propTypes = {
  years: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
}

function ReportListItem({ years, id }) {
  const { API_PROTOCOL, API_HOST } = process.env
  const baseUrl = `${API_PROTOCOL}://${API_HOST}`
  const queryString = stringify({ organization_id: id, school_year: years })
  const reportUrl = `${baseUrl}/partner_report.pdf?${queryString}`

  return (
    <li>
      <p>
        <strong>artlook Profile for {years}: </strong>
      </p>
      <ExternalLink href={reportUrl} className="button-primary-outline">
        Download Report
      </ExternalLink>
    </li>
  )
}

ReportListItem.propTypes = propTypes

export default ReportListItem
