import React from 'react'
import PropTypes from 'prop-types'
import { Checklist, ChecklistItem } from 'components'

const propTypes = {
  resources: PropTypes.arrayOf(PropTypes.string).isRequired,
}

// Return a component with custom behavior based on available resources
function createChecklistItemComponent(resources) {
  // eslint-disable-next-line react/prop-types
  return function ResourceChecklistItem({ resource }) {
    return (
      <ChecklistItem checked={resources.includes(resource)}>
        {resource}
      </ChecklistItem>
    )
  }
}

function ResourcesChecklist({ resources }) {
  const ResourceChecklistItem = createChecklistItemComponent(resources)
  return (
    <div className="row">
      <div className="col-6 resource-block">
        <h4>Interested in the Following Arts Programming Resources</h4>
        <div className="row">
          <Checklist>
            <ResourceChecklistItem resource="Supplies and Equipment" />
            <ResourceChecklistItem resource="Dedicated Arts Budget" />
            <ResourceChecklistItem resource="Professional Development" />
            <ResourceChecklistItem resource="Curriculum and Lesson Planning Assistance" />
          </Checklist>
          <Checklist>
            <ResourceChecklistItem resource="Arts Integration" />
            <ResourceChecklistItem resource="Additional Staffing for the Arts" />
            <ResourceChecklistItem resource="Provide/receive Mentoring to/from Another School" />
          </Checklist>
        </div>
      </div>
      <div className="col-6 partner-blocks">
        <h4>Interested in the Following Arts Partnerships & Programs</h4>
        <div className="row">
          <Checklist title="Program Type">
            <ResourceChecklistItem resource="Residency (RESI)" />
            <ResourceChecklistItem resource="Out-of-School Time" />
            <ResourceChecklistItem resource="Field Trip" />
            <ResourceChecklistItem resource="In-School Performance" />
            <ResourceChecklistItem resource="Resources" />
            <ResourceChecklistItem resource="Professional Development" />
          </Checklist>
          <Checklist title="Disciplines">
            <ResourceChecklistItem resource="Dance" />
            <ResourceChecklistItem resource="Music" />
            <ResourceChecklistItem resource="Theater" />
            <ResourceChecklistItem resource="Visual Arts" />
            <ResourceChecklistItem resource="Media Arts" />
            <ResourceChecklistItem resource="Literary Arts" />
          </Checklist>
        </div>
      </div>
    </div>
  )
}

ResourcesChecklist.propTypes = propTypes

export default ResourcesChecklist
