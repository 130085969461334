import { RESOURCE_TYPES } from 'types'
import { get } from 'lodash'

// Returns true if a "resourceType" object has a name of "specific_interest".

function isSpecificInterestType(resourceType) {
  if (!resourceType) return false
  return get(resourceType, 'name') === RESOURCE_TYPES.SPECIFIC_INTEREST
}

export default isSpecificInterestType
