const schoolYears = {
  YEAR_19: '2018-19',
  YEAR_18: '2017-18',
  YEAR_17: '2016-17',
  YEAR_16: '2015-16',
  YEAR_15: '2014-15',
  YEAR_14: '2013-14',
  YEAR_13: '2012-13',
  YEAR_12: '2011-12',
  YEAR_11: '2010-11',
}

export default schoolYears
