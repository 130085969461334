import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { RenderedHtml } from 'components'

const propTypes = {
  content: PropTypes.string.isRequired,
  isEdited: PropTypes.bool.isRequired,
  isComment: PropTypes.bool,
}

const defaultProps = {
  isComment: false,
}

function PostUserContent({ content, isEdited, isComment }) {
  const className = isComment ? 'comment-content' : 'post-content'
  const renderedContent = isEdited ? content + '<em>(edited)</em>' : content

  return (
    <RenderedHtml tag="p" className={className + ' rich-text'}>
      {renderedContent}
    </RenderedHtml>
  )
}

PostUserContent.propTypes = exact(propTypes)
PostUserContent.defaultProps = defaultProps

export default pure(PostUserContent)
