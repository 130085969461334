import React from 'react'

function ProfessionalDevelopmentIcon() {
  return (
    <svg viewBox="0 0 50 50">
      <path
        fill="#204A82"
        className="fill"
        d="M50,25A25,25,0,1,1,25,0,25,25,0,0,1,50,25Z"
      />
      <path
        fill="#FFFFFF"
        d="M25,30.74a3.81,3.81,0,1,0-3.8-3.8A3.8,3.8,0,0,0,25,30.74Z"
      />
      <rect fill="#FFFFFF" x="17.27" y="41.95" width="1.65" height="1.55" />
      <path
        fill="#FFFFFF"
        d="M21.32,23H17.53v1.56h2.64A5.18,5.18,0,0,1,21.32,23Z"
      />
      <path
        fill="#FFFFFF"
        d="M35.09,28.87,32.16,21a1.56,1.56,0,0,0-2.89,1.16l2.44,6-3.9,3.86-5.2.95c-1,0-2,.87-2.72,1.31l-1.84,1.1,1.11-3a1.32,1.32,0,0,0-.73-1.79,1.62,1.62,0,0,0-1.95.78l-2.85,7.72,1.69,1.53,5.16-2.23V49.59A25.84,25.84,0,0,0,25,50a25.37,25.37,0,0,0,4.73-.45c0-6.74,0-12.69,0-12.7Z"
      />
      <path
        fill="#FFFFFF"
        d="M15,30.92l0-.06,0-.06a2.87,2.87,0,0,1,.82-1v-28a23.93,23.93,0,0,0-3.11,1.47V37Z"
      />
      <path
        fill="#FFFFFF"
        d="M15,42.52l-.74-.67-1.5-1.35v6.31a23.93,23.93,0,0,0,3.11,1.47V42.14Z"
      />
      <path
        fill="#FFFFFF"
        d="M17.53,4.11V5.67H33.11v7.9H17.53v1.56H33.11v4.51a3.19,3.19,0,0,1,.49.82h0v0l2.61,7V2.66a25.85,25.85,0,0,0-3.11-1.31V4.11Z"
      />
      <path
        fill="#FFFFFF"
        d="M35.34,31.28,33.85,33.5l-.37.55-.37.55V42H31.28v1.56h1.83v5.14a24.33,24.33,0,0,0,3.11-1.31V30Z"
      />
    </svg>
  )
}

export default ProfessionalDevelopmentIcon
