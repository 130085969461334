import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors as globalSelectors } from 'partner-portal-reducer'
import { PortalHeader } from 'components'
import * as Types from 'types'
import { PARTNER_PORTAL_ROUTE } from 'config'
import { useCommunity } from 'utils'

const propTypes = {
  partner: Types.partner,
  children: PropTypes.node,
}

const defaultProps = {}

function Layout({ children }) {
  const {
    defaultBranding: { dashboardHeader },
  } = useCommunity()
  return (
    <div>
      <PortalHeader
        title="Home"
        backgroundImage={dashboardHeader}
        links={[
          { name: 'Dashboard', path: PARTNER_PORTAL_ROUTE + '/dashboard' },
          {
            name: 'artlook Map Summary',
            path: PARTNER_PORTAL_ROUTE + '/summary',
          },
        ]}
      ></PortalHeader>
      <div className="content-block-container">{children}</div>
    </div>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    partner: globalSelectors.partner(state),
  }
}

export default compose(connect(mapStateToProps))(Layout)
