import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { Link } from 'react-router'
import { groupBy, map, sortBy } from 'lodash'
import { SCHOOL_PORTAL_ROUTE, PARTNER_PORTAL_ROUTE } from 'config'
import classnames from 'classnames'

const propTypes = {
  currentEmployment: Types.employer.isRequired,
  currentEmployableType: PropTypes.string.isRequired,
  employments: PropTypes.arrayOf(Types.employer).isRequired,
}

const defaultProps = {}

function serializeEmploymentGroups(employments) {
  const byEmploymentType = groupBy(employments, 'employableType')
  return map(byEmploymentType, (employmentGroup, type) => {
    return {
      name: type,
      employments: sortBy(employmentGroup, 'employable.name').map((e) => {
        return {
          employerName: e.employable.name,
          id: e.id,
        }
      }),
    }
  })
}

function getPortalRoute(employmentType) {
  if (employmentType === Types.EMPLOYABLE_TYPES.ORGANIZATION)
    return PARTNER_PORTAL_ROUTE
  return SCHOOL_PORTAL_ROUTE
}

function MultiAccountSwitch({
  currentEmployableType,
  employments,
  currentEmployment,
}) {
  const employmentGroups = useMemo(() => {
    return serializeEmploymentGroups(employments)
  }, [employments])

  return (
    <div id="multi-account-switch">
      <div id="employments">
        {employmentGroups.map((group) => (
          <div key={group.name}>
            <span className="employment-group-name" aria-hidden>
              {group.name}
            </span>
            <ul aria-label={`artlook account select - ${group.name}`}>
              {group.employments.map((employment) => {
                const isSelectedEmployment =
                  employment.id === currentEmployment.id
                return (
                  <li
                    key={employment.id}
                    className={classnames({
                      'selected-employment': isSelectedEmployment,
                    })}
                  >
                    <Link
                      to={`${getPortalRoute(
                        currentEmployableType
                      )}/switch-account/${employment.id}`}
                      aria-label={`${
                        isSelectedEmployment
                          ? 'Currenty signed in to'
                          : 'Sign in to'
                      } ${employment.employerName}`}
                    >
                      {employment.employerName}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}

MultiAccountSwitch.propTypes = propTypes
MultiAccountSwitch.defaultProps = defaultProps

export default MultiAccountSwitch
