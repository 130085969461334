import React from 'react'

function TrashIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#5B5F66" fillRule="nonzero" transform="translate(4.5 3)">
          <g>
            <path d="M.786 12.444C.786 13.3 1.493 14 2.357 14h6.286c.864 0 1.571-.7 1.571-1.556V3.111H.786v9.333zM11 .778H8.25L7.464 0H3.536L2.75.778H0v1.555h11V.778z"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default TrashIcon
