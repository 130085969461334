import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'lp-components'

const propTypes = {
  openPostForm: PropTypes.func.isRequired,
  isFiltered: PropTypes.bool.isRequired,
  canPost: PropTypes.bool.isRequired,
}

const defaultProps = {}

function EmptyLoadState({ openPostForm, isFiltered, canPost }) {
  return (
    <div className="empty-state">
      {isFiltered ? (
        <React.Fragment>
          <h3>No Discussions Found</h3>
          <p>Please try another search or different filters.</p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h3>No Discussions have been started yet</h3>
          {canPost ? (
            <Button onClick={openPostForm}>
              Start a new one and join the conversation!
            </Button>
          ) : (
            <p>Join this community in order to start a new one!</p>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

EmptyLoadState.propTypes = propTypes
EmptyLoadState.defaultProps = defaultProps

export default EmptyLoadState
