import { handleActions } from 'redux-actions'
import { setOnSuccess } from 'lp-redux-api'
import { selectorForSlice } from 'lp-redux-utils'
import { serializeLocation, set } from 'utils'
import { createSelector } from 'reselect'
import * as actions from './actions'
import * as Types from 'types'
import * as apiActions from 'api-actions'
import { orderBy } from 'lodash'

const reducerKey = 'schools'
const slice = 'root.map.schools'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchSchool]: setOnSuccess('school'),
    [actions.clearSchool]: (state) => set('school', null, state),
    [apiActions.fetchActiveAndPublishedSchoolYears]:
      setOnSuccess('schoolYears'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  school: select('school'),
  schoolYears: select('schoolYears'),
}

// Computed
selectors.partnerLocations = createSelector(
  [selectors.school],
  function (school) {
    if (!school || !school.partnerships) return []

    return school.partnerships.map(serializeLocation(Types.PARTNER_TYPE))
  }
)

selectors.currentSchoolLocation = createSelector(
  [selectors.school],
  function (school) {
    if (!school) return
    return serializeLocation(Types.SCHOOL_TYPE, school)
  }
)

// Order school years by most recent (DESC)
selectors.orderedSchoolYears = createSelector(
  [selectors.schoolYears],
  function (schoolYears) {
    if (!schoolYears) return
    return orderBy(schoolYears, 'number', 'desc')
  }
)

export { reducer, selectors, reducerKey }
