import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  AutoSuggestInput as BaseAutoSuggestInput,
  AutoSuggestInputLabel,
} from 'components'
import {
  fieldPropTypes,
  LabeledField,
  hasInputError,
  generateInputErrorId,
} from 'lp-components'
import * as Types from 'types'
import classnames from 'classnames'
import { isString } from 'lodash'

const propTypes = {
  ...fieldPropTypes,
  instructions: PropTypes.string,
  inputProps: PropTypes.object,
  suggestions: PropTypes.arrayOf(
    PropTypes.oneOfType([Types.searchSuggestion, PropTypes.string])
  ),
}

const defaultProps = {
  instructions: '',
  inputProps: {},
  suggestions: [],
}

// Return a suggestion object that matches a string value
function getSuggestionForValue(suggestions, value) {
  if (!value) return
  return suggestions.find(({ name }) => name === value)
}

// Only return the id if AutoSuggestInputLabel is being used with instructions
function getDefaultDescribedById(labelComponent, instructions) {
  if (labelComponent || !instructions) return
  return 'auto-suggest-instructions'
}

function serializeOption(option) {
  if (!isString(option)) return option
  return {
    id: option,
    name: option,
  }
}

function OptionalAutoSuggestInput({
  input: { name, value, onChange, onBlur },
  input,
  meta,
  suggestions,
  'aria-describedby': ariaDescribedBy,
  'aria-labelledby': ariaLabelledBy,
  'aria-label': ariaLabel,
  instructions,
  labelComponent,
  label,
  inputProps,
  ...rest
}) {
  const [internalValue, setInternalValue] = useState(value)
  const allAriaDescribedBy = classnames(
    ariaDescribedBy || getDefaultDescribedById(labelComponent, instructions),
    { [generateInputErrorId(name)]: hasInputError(meta) }
  )
  const suggestionOptions = suggestions.map(serializeOption)
  return (
    <LabeledField
      {...{
        input,
        meta,
        name,
        instructions,
        labelComponent: labelComponent || AutoSuggestInputLabel,
        label,
        ...rest,
      }}
    >
      <BaseAutoSuggestInput
        input={{
          // Use separate internal value
          id: name,
          name,
          value: internalValue,
          onChange: (e, { newValue, method }) => {
            // Ignore dropdown click
            if (method === 'click') return
            // Update internal value
            setInternalValue(newValue)
            // If new value matches a suggestion, call onChange with that suggestion
            const matchingSuggestion = getSuggestionForValue(
              suggestions,
              newValue
            )
            return onChange(matchingSuggestion || newValue)
          },
          onBlur: (e, { highlightedSuggestion }) => {
            const selectedValue = highlightedSuggestion || value
            return onBlur(selectedValue)
          },
          // return null instead of empty string to avoid rendering empty attribute
          'aria-describedby': allAriaDescribedBy || null,
          'aria-labelledby': ariaLabelledBy,
          'aria-label': ariaLabel,
          ...inputProps,
        }}
        meta={{}}
        suggestionOptions={suggestionOptions}
        onSuggestionSelected={(e, { suggestion, method }) => {
          if (method === 'enter') e.preventDefault()
          if (suggestion.placeholder) return
          // When selecting a suggestion, also update the internal state to the suggestion name
          setInternalValue(suggestion.name)
          onChange(suggestion)
        }}
        renderSuggestion={({ name }) => <span>{name}</span>}
        focusInputOnSuggestionClick={false}
        containerProps={{
          // input container needs an aria-label for compliance
          'aria-label': ariaLabel || label,
        }}
        {...rest}
      />
    </LabeledField>
  )
}

OptionalAutoSuggestInput.propTypes = propTypes
OptionalAutoSuggestInput.defaultProps = defaultProps

export default OptionalAutoSuggestInput
