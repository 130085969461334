/**
 * This function displays submit failures based on the onSubmitFail callback from Redux-Form
 * https://redux-form.com/7.4.2/docs/api/reduxform.md/#-code-onsubmitfail-function-code-optional-
 *
 * @name displaySubmitFailure
 * @param {Object} errors - Errors that caused submit to fail
 * @param {Function} dispatch - Redux dispatch function
 * @param {Object} submitError - Errors that caused submit to fail. If the only errors are related internally to redux-form validations, this will be null
 */

import { isDevelopment } from 'config'
import { flashErrorMessage } from 'redux-flash'
import getFormWideErrorMessage from './get-form-wide-error-message'

function displaySubmitFailure(errors, dispatch, submitError) {
  if (isDevelopment()) {
    // eslint-disable-next-line no-console
    console.error('Form Submission failed!', errors, submitError)
  }

  const message = getFormWideErrorMessage(errors, submitError)
  return dispatch(flashErrorMessage(message, { timeout: 7000 }))
}

export default displaySubmitFailure
