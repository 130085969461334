import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { ContentBlock, ExternalLink, Modal } from 'components'
import { Spinner } from 'lp-components'
import * as globalActions from 'school-portal-actions'
import { Link } from 'react-router'
import { useCommunity } from 'utils'
import { StaySignedInForm } from '../forms'
import * as LS from 'local-storage'

const propTypes = {
  token: PropTypes.string,
  employerId: PropTypes.string,
  setUserAndRedirect: PropTypes.func,
}

const defaultProps = {}

function SsoRedirect({ token, employerId, setUserAndRedirect }) {
  const { schoolSupportEmail } = useCommunity()
  const [isStaySignedInModalOpen, setIsStaySignedInModalOpen] = useState(false)
  const redirectUrl = LS.getSsoRedirect()

  const handleStaySignedInModal = (persist) => {
    setIsStaySignedInModalOpen(false)
    setUserAndRedirect({
      token,
      schoolId: employerId,
      persistSession: persist,
      redirectUrl: redirectUrl,
    })
    LS.clearSsoRedirect()
  }

  useEffect(() => {
    if (token) setIsStaySignedInModalOpen(true)
  }, [token])

  return (
    <ContentBlock>
      <div className="sso-redirect">
        {token ? (
          <React.Fragment>
            <Spinner />
            <h1>Redirecting to your account</h1>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h2>Invalid Authentication Request</h2>
            <p>
              If you believe there is an error with your account, please contact
              support:{' '}
              <ExternalLink href={`mailto:${schoolSupportEmail}`}>
                {schoolSupportEmail}
              </ExternalLink>
            </p>
            <p>
              You can attempt to sign in to the artlook school portal below.
            </p>
            <Link to={'/school-portal/sign-in'} className="button-primary">
              Sign In
            </Link>
          </React.Fragment>
        )}
        {isStaySignedInModalOpen && (
          <Modal onClose={() => handleStaySignedInModal(false)}>
            <h2>Stay signed in?</h2>
            <p>This is not recommended if you're using a public device.</p>
            <StaySignedInForm
              onSubmit={({ persist }) => {
                handleStaySignedInModal(persist)
              }}
            />
          </Modal>
        )}
      </div>
    </ContentBlock>
  )
}

SsoRedirect.propTypes = propTypes

SsoRedirect.defaultProps = defaultProps

const mapStateToProps = function (state) {
  const { locationBeforeTransitions } = state.routing
  const { query } = locationBeforeTransitions
  const { token, employerId } = query || {}
  return {
    token,
    employerId,
  }
}

const mapDispatchToProps = {
  setUserAndRedirect: globalActions.setUserAndRedirect,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SsoRedirect
)
