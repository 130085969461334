import React from 'react'

function WarningIcon(props) {
  return (
    <svg width="64px" height="64px" viewBox="0 0 64 64" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle fill="#c9c9c9" cx="32" cy="32" r="32"></circle>
        <path
          d="M35.1257143,36.7474815 L36,21 L30.0685714,21 L30.9428571,36.7474815 L35.1257143,36.7474815 Z M33.0171429,45 C33.9542857,45 34.6857143,44.7555406 35.2114286,44.2666219 C35.7371429,43.7777032 36,43.1087979 36,42.259906 C36,41.3787777 35.74,40.7018133 35.22,40.2290128 C34.7,39.7562122 33.9657143,39.519812 33.0171429,39.519812 C32.0342857,39.519812 31.2857143,39.7508395 30.7714286,40.2128946 C30.2571429,40.6749496 30,41.3572868 30,42.259906 C30,43.1302888 30.2628571,43.8045668 30.7885714,44.2827401 C31.3142857,44.7609134 32.0571429,45 33.0171429,45 Z"
          fill="#484848"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  )
}

export default WarningIcon
