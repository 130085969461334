import { sortBy, get } from 'lodash'

function serializeEmployerOptions(data, employableType) {
  return sortBy(
    get(data, 'employments', []).reduce(function (employments, employee) {
      const item = {
        key: employee.employable.name,
        value: employee.id,
      }
      if (
        !employableType ||
        employee.employableType.toLowerCase() === employableType
      )
        employments.push(item)
      return employments
    }, []),
    'key'
  )
}

export default serializeEmployerOptions
