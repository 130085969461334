import { isString } from 'lodash'
// Partner table helpers

// Programs are either under the key "programs" or "contentTypes"
// Make this backwards compatible until partner map is migrated
export function getProgramsForRow(row) {
  return row.programs || row.contentTypes || row.programTypes || []
}

// Returns true if the row contains the program
// Make this backwards compatible until partner map is migrated
export function hasProgram(row, programName) {
  return getProgramsForRow(row).some((program) => {
    if (isString(program)) return program === programName
    return program.displayName === programName
  })
}

// Returns true if any row contains the program
export function anyRowHasProgram(rows, program) {
  return rows.some((row) => hasProgram(row, program))
}

// A sort function for checkbox columns
// Ranks row B more highly if it contains the given program
export function compareCheckboxColumns(program) {
  return function sort(rowA, rowB) {
    return hasProgram(rowB, program) ? 1 : -1
  }
}
