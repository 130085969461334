import React from 'react'

function BellIcon({ white }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill={white ? '#FFF' : '#484848'}
          fillRule="nonzero"
          d="M7.5 19c1.031 0 1.875-.877 1.875-1.949h-3.75C5.625 18.123 6.469 19 7.5 19m5.625-5.846V8.282c0-2.991-1.537-5.495-4.219-6.158v-.662C8.906.652 8.278 0 7.5 0c-.778 0-1.406.653-1.406 1.462v.662c-2.69.663-4.219 3.157-4.219 6.158v4.872L0 15.103v.974h15v-.974l-1.875-1.95z"
          transform="translate(2.5 .6)"
        ></path>
      </g>
    </svg>
  )
}

export default BellIcon
