import { format } from 'date-fns'

// Return a date string in the preferred format used throughout the app

function formatDate(date) {
  if (!date) return date

  return format(date, 'MMMM DD, YYYY')
}

export default formatDate
