import { isDirty, hasSubmitSucceeded } from 'redux-form'
import keys from 'lodash/keys'

// Helper for matching form names, since some forms may have an appended id
const matchesAny = (str, arr) => arr.some((item) => str.includes(item))

// Returns true if there's a changed form in the state
function hasChangedForm(state, confirmedForms = []) {
  // Get all visible forms that match the forms we want to confirm
  const formNames = keys(state.form).filter((name) =>
    matchesAny(name, confirmedForms)
  )
  // Return true if a visible confirmed form is changed but not submitted
  return formNames.some(
    (formName) =>
      isDirty(formName)(state) && !hasSubmitSucceeded(formName)(state)
  )
}

export default hasChangedForm
