import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import * as Views from './views'
import Layout from './layout'

const Routes = (
  <Route path="survey" component={Layout}>
    <IndexRedirect to="get-started" />
    <Route path="get-started" component={Views.GetStarted} />
    <Route
      path="staffing-and-instruction"
      component={Views.StaffingAndInstruction}
    />
    <Route
      path="community-and-partnerships"
      component={Views.CommunityAndPartnerships}
    />
    <Route path="budget-and-planning" component={Views.BudgetAndPlanning} />
    <Route path="review-and-submit" component={Views.ReviewAndSubmit} />
    <Route
      path="complete-confirmation"
      component={Views.CompleteConfirmation}
    />
    <Route path="unavailable" component={Views.Unavailable} />
  </Route>
)

export default Routes
