import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ProfileAvatar } from 'educator-portal-components'

const propTypes = {
  children: PropTypes.node.isRequired,
  sectionId: PropTypes.string,
  userDisplayName: PropTypes.string.isRequired,
  profileImgUrl: PropTypes.string,
}

const defaultProps = {
  profileImgUrl: null,
  sectionId: null,
}

function NewComment({ userDisplayName, profileImgUrl, sectionId, children }) {
  return (
    <section id={sectionId} className="comment-new">
      <div className="card discussion-card">
        <div className="flex-horizontal">
          <ProfileAvatar
            imageUrl={profileImgUrl}
            userDisplayName={userDisplayName}
          />
          {children}
        </div>
      </div>
    </section>
  )
}

NewComment.propTypes = exact(propTypes)
NewComment.defaultProps = defaultProps

export default pure(NewComment)
