import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Comment, NewComment } from 'educator-portal-components'
import { CommentForm } from '../forms'
import classnames from 'classnames'
import { trimStart, isEmpty } from 'lodash'
import { displaySubmitFailure } from 'utils'
import { scroller } from 'react-scroll'

const propTypes = {
  comments: PropTypes.arrayOf(Types.comment).isRequired,
  copyCommentUrl: PropTypes.func,
  highlightedContainerId: PropTypes.string,
  highlightedRef: Types.ref,
  currentUserId: PropTypes.number.isRequired,
  requestArchiveComment: PropTypes.func.isRequired,
  requestUpdateComment: PropTypes.func.isRequired,
  showProfileDetails: PropTypes.func.isRequired,
  showUnfoundCommentError: PropTypes.func,
  currentUserDisplayName: PropTypes.string.isRequired,
  currentUserProfileImgUrl: PropTypes.string,
  createComment: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  isCommentingDisabled: PropTypes.bool.isRequired,
  commentableId: PropTypes.number.isRequired,
  commentableType: PropTypes.string.isRequired,
  commentFormPlaceholder: PropTypes.string,
  onSelectFlagAction: PropTypes.func.isRequired,
  isModerator: PropTypes.bool.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  mentionSuggestions: PropTypes.arrayOf(Types.mentionSuggestion),
  addLike: PropTypes.func.isRequired,
  destroyLike: PropTypes.func.isRequired,
}

const defaultProps = {
  copyCommentUrl: null,
  highlightedContainerId: null,
  highlightedRef: null,
  showUnfoundCommentError: null,
  commentFormPlaceholder: undefined,
  currentUserProfileImgUrl: null,
}

function CommentsList({
  comments,
  copyCommentUrl,
  highlightedContainerId,
  highlightedRef,
  showUnfoundCommentError,
  currentUserDisplayName,
  currentUserProfileImgUrl,
  createComment,
  resetForm,
  isCommentingDisabled,
  commentableId,
  commentableType,
  commentFormPlaceholder,
  flashSuccessMessage,
  flashErrorMessage,
  mentionSuggestions,
  ...rest
}) {
  useEffect(() => {
    if (
      !highlightedContainerId ||
      highlightedContainerId == Types.COMMENT_NEW_ID ||
      highlightedContainerId.includes(Types.MENTIONED_USER_ID_PREFIX)
    )
      return

    const commentId = trimStart(
      highlightedContainerId,
      Types.COMMENT_CONTAINER_ID_PREFIX
    )
    const matchedComment = comments.find(
      (comment) =>
        comment.id === parseInt(commentId) ||
        comment.threadedComments.find(
          (threadedComment) => threadedComment.id === parseInt(commentId)
        )
    )

    if (!matchedComment) return showUnfoundCommentError()

    toggleExpandedCommentThread(matchedComment.id, false)
    scroller.scrollTo('comment-' + matchedComment.id, { smooth: true })
  }, [comments, highlightedContainerId])

  const [expandedCommentThreadId, setExpandedCommentThreadId] = useState(null)
  const toggleExpandedCommentThread = (commentId, isExpanded) => {
    if (isExpanded) return setExpandedCommentThreadId(null)

    setExpandedCommentThreadId(commentId)
  }

  const isCommenting = highlightedContainerId === Types.COMMENT_NEW_ID
  const commentFormName = `${Types.COMMENT_FORM_NAME}-${commentableId}`

  return (
    <React.Fragment>
      {!isEmpty(comments) && (
        <section className="comments">
          <ul>
            {comments.map((comment) => {
              const containerId = `${Types.COMMENT_CONTAINER_ID_PREFIX}${comment.id}`
              const isHighlighted = containerId === highlightedContainerId

              return (
                <li
                  key={comment.id}
                  id={containerId}
                  tabIndex={isHighlighted ? -1 : null} // allows this element to be programmatically focused
                  ref={isHighlighted ? highlightedRef : null}
                  className={classnames('card discussion-card comment', {
                    highlight: isHighlighted,
                  })}
                >
                  <Comment
                    comment={comment}
                    onShare={() => copyCommentUrl(containerId)}
                    currentUserDisplayName={currentUserDisplayName}
                    currentUserProfileImgUrl={currentUserProfileImgUrl}
                    createComment={createComment}
                    resetForm={resetForm}
                    isCommentingDisabled={isCommentingDisabled}
                    isThreadExpanded={expandedCommentThreadId === comment.id}
                    toggleThread={(isExpanded) =>
                      toggleExpandedCommentThread(comment.id, isExpanded)
                    }
                    flashSuccessMessage={flashSuccessMessage}
                    flashErrorMessage={flashErrorMessage}
                    mentionSuggestions={mentionSuggestions}
                    {...rest}
                  />
                </li>
              )
            })}
          </ul>
        </section>
      )}
      {!isCommentingDisabled && (
        <NewComment
          sectionId={isCommenting ? Types.COMMENT_NEW_ID : null}
          userDisplayName={currentUserDisplayName}
          profileImgUrl={currentUserProfileImgUrl}
        >
          <CommentForm
            name={commentFormName}
            initialValues={{ attachments: [] }}
            onSubmit={({ content, attachments }) =>
              createComment(
                commentableId,
                commentableType,
                content,
                attachments
              )
            }
            onSubmitSuccess={() => resetForm(commentFormName)}
            onSubmitFail={displaySubmitFailure}
            isCommenting={isCommenting}
            placeholder={commentFormPlaceholder}
            flashSuccessMessage={flashSuccessMessage}
            flashErrorMessage={flashErrorMessage}
            mentionSuggestions={mentionSuggestions}
          />
        </NewComment>
      )}
    </React.Fragment>
  )
}

CommentsList.propTypes = exact(propTypes)
CommentsList.defaultProps = defaultProps

export default pure(CommentsList)
