import isSpecificInterestType from './is-specific-interest-type'
import { negate } from 'lodash'

// Removes type with name "specific_interest" from array of resourceTypes

function removeSpecificInterestType(resourceTypes) {
  return resourceTypes.filter(negate(isSpecificInterestType))
}

export default removeSpecificInterestType
