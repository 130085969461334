import React from 'react'
import PropTypes from 'prop-types'
import NavLink from './nav-link'
import CommunityBackgroundImage from './community-background-image'
import * as Types from 'types'
const propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ),
  backgroundImage: Types.image.isRequired,
  children: PropTypes.node,
}

const defaultProps = {}

function PortalHeader({ title, links, backgroundImage, children }) {
  return (
    <CommunityBackgroundImage
      className="portal-header home-portal-header"
      image={backgroundImage}
    >
      <header className="content-block-container">
        {children}
        <h1>{title}</h1>
      </header>
      {links && (
        <nav className="portal-subnav" aria-label="Secondary Navigation">
          <ul className="portal-subnav-container">
            {links.map(({ name, path, ...rest }, i) => (
              <li key={i}>
                <NavLink to={path} {...rest}>
                  {name}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </CommunityBackgroundImage>
  )
}

PortalHeader.propTypes = propTypes

PortalHeader.defaultProps = defaultProps

export default PortalHeader
