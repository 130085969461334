import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { SurveyQuestionsContext } from 'contexts'
import * as surveyQuestions from 'survey-questions'

const propTypes = {
  children: PropTypes.node,
}

const defaultProps = {}

// Provides survey questions for both partner and school portals
function SurveyQuestionsProvider({ children }) {
  return (
    <SurveyQuestionsContext.Provider value={surveyQuestions}>
      {children}
    </SurveyQuestionsContext.Provider>
  )
}

SurveyQuestionsProvider.propTypes = exact(propTypes)
SurveyQuestionsProvider.defaultProps = defaultProps

export default pure(SurveyQuestionsProvider)
