import React from 'react'

function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#5B5F66"
          fillRule="nonzero"
          stroke="#5B5F66"
          d="M14.93 15.54l-3.127-3.567a4.196 4.196 0 001.092-2.848c0-1.102-.41-2.138-1.157-2.917A3.84 3.84 0 008.948 5a3.837 3.837 0 00-2.792 1.208A4.195 4.195 0 005 9.125c0 1.102.41 2.138 1.156 2.917a3.84 3.84 0 002.791 1.208c.909 0 1.77-.318 2.468-.905l3.128 3.566a.257.257 0 00.372.017.284.284 0 00.016-.388h0zM5.526 9.124c0-1.971 1.535-3.575 3.42-3.575 1.887 0 3.422 1.604 3.422 3.575S10.833 12.7 8.947 12.7s-3.421-1.604-3.421-3.575z"
        ></path>
      </g>
    </svg>
  )
}

export default SearchIcon
