import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  title: PropTypes.string.isRequired,
}

function SuggestionSectionTitle({ title }) {
  return (
    <p>
      <strong>{title}</strong>
    </p>
  )
}

SuggestionSectionTitle.propTypes = propTypes

export default SuggestionSectionTitle
