import React from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  fieldPropTypesWithValue,
  fieldOptionsType,
  omitLabelProps,
  LabeledField,
  serializeOptions,
  replaceEmptyStringValue,
} from 'lp-components'
import { addToArray, removeFromArray } from 'lp-components/lib/utils'
import { without } from 'utils'
import classnames from 'classnames'
import { omit } from 'lodash'

const propTypes = {
  ...fieldPropTypesWithValue(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ),
  options: fieldOptionsType,
}

const defaultProps = {
  options: [],
}

const uniqueName = ({ name, option, id = '' }) => {
  if (!id) return `${name}.${option.value}`
  return `${id}.${option.value}`
}

function CheckboxGroup(props) {
  const {
    id,
    input: { value, onChange, name },
    meta, // eslint-disable-line no-unused-vars
    options,
    className,
    ...rest
  } = omitLabelProps(props)
  const optionObjects = serializeOptions(options)
  // Build change handler
  const handleChange = function (option) {
    return function (checked) {
      // Add or remove option value from array of values, depending on whether it's checked
      const noneOption = optionObjects.find((option) => option.none) || {}
      let newValueArray = []
      if (option.none) {
        newValueArray = checked ? [option.value] : []
      } else {
        newValueArray = checked
          ? without(addToArray([option.value], value), noneOption.value)
          : removeFromArray([option.value], value)
      }
      return onChange(newValueArray)
    }
  }
  const labelProps = omit(props, 'className')
  return (
    <LabeledField
      className={classnames('CheckboxGroup', className)}
      {...labelProps}
    >
      {optionObjects.map((option) => {
        return (
          <Checkbox // eslint-disable-line react/jsx-key
            {...{
              key: option.value,
              input: {
                name: uniqueName({ name, option, id }),
                value: value.includes(option.value),
                onChange: handleChange(option),
              },
              meta: {},
              label: option.key,
              ...rest,
            }}
          />
        )
      })}
    </LabeledField>
  )
}

CheckboxGroup.propTypes = propTypes
CheckboxGroup.defaultProps = defaultProps

export default replaceEmptyStringValue([])(CheckboxGroup)
