import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { getProfileInitials } from 'utils'

const propTypes = {
  imageUrl: PropTypes.string,
  userDisplayName: PropTypes.string.isRequired,
}

const defaultProps = {
  imageUrl: null,
}

function ProfileAvatar({ imageUrl, userDisplayName }) {
  return (
    <span className="image-circle">
      {imageUrl ? (
        <img className="profile-photo" src={imageUrl} alt="" />
      ) : (
        <span className="profile-initials">
          <strong>{getProfileInitials(userDisplayName)}</strong>
        </span>
      )}
    </span>
  )
}

ProfileAvatar.propTypes = exact(propTypes)
ProfileAvatar.defaultProps = defaultProps

export default pure(ProfileAvatar)
