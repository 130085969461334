import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { ButtonArea } from 'lp-components'
import { Modal } from 'components'

const propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

const defaultProps = {}

function ConfirmUnflagModal({ onClose, onConfirm }) {
  return (
    <Modal onClose={onClose}>
      <h2>Are you sure?</h2>
      <div className="modal-content">
        <p>
          Removing the flag means an artlook Educator Moderator is less likely
          to review this user's actions. Are you sure you'd like to remove your
          flag?
        </p>
        <ButtonArea>
          <button type="button" className="button-warn" onClick={onConfirm}>
            Remove
          </button>
          <button type="button" className="button-grey-light" onClick={onClose}>
            Cancel
          </button>
        </ButtonArea>
      </div>
    </Modal>
  )
}

ConfirmUnflagModal.propTypes = propTypes
ConfirmUnflagModal.defaultProps = defaultProps

export default pure(ConfirmUnflagModal)
