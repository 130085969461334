import isSpecificInterestType from './is-specific-interest-type'
import { get } from 'lodash'

// Returns id of the resource type with name "specific_interest" in an array of resource type objects

function getSpecificInterestTypeId(resourceTypes) {
  const specificInterestType = resourceTypes.find(isSpecificInterestType)
  return get(specificInterestType, 'id')
}

export default getSpecificInterestTypeId
