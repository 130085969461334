import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { lpForm } from 'lp-form'
import { ButtonArea, SubmitButton, CheckboxGroup } from 'lp-components'
import { formatObjectsToIds, deepMap } from 'utils'
import * as Types from 'types'
import { omit } from 'lodash/fp'
import { isPlainObject, uniqBy, size, noop } from 'lodash'

const propTypes = {
  ...formPropTypes,
  partnershipOptions: PropTypes.arrayOf(Types.formOptionType),
  children: PropTypes.func,
}

const defaultProps = {
  children: noop,
}

function schoolYearForPartnership(partnership, schoolYears) {
  const schoolYear = schoolYears.find(
    ({ id }) => id === partnership.schoolYearId
  )
  return schoolYear ? schoolYear.number : 'Unknown school year'
}

function serializePartnershipOptions({ partnerships, schoolYears }) {
  return partnerships.map((partnership) => {
    return {
      key: `${partnership.schoolName} (${schoolYearForPartnership(
        partnership,
        schoolYears
      )})`,
      value: partnership.id,
    }
  })
}

function removeIds(obj) {
  return deepMap(obj, (value) =>
    isPlainObject(value) ? omit('id', value) : value
  )
}

function removeSchoolYearIds(obj) {
  return deepMap(obj, (value) =>
    isPlainObject(value) ? omit('schoolYearId', value) : value
  )
}

function partnerIdsToPartnerships(partnershipIds, partnerships) {
  return partnershipIds.map((partnershipId) =>
    partnerships.find(({ id }) => id === partnershipId)
  )
}

function validatePartnerships(partnerships = []) {
  if (size(partnerships) === 0) return 'You must select at least one partner.'
  if (uniqBy(partnerships, 'schoolId').length < partnerships.length)
    return 'Only one of each partner can be added. Please remove the duplicates and try again'
}

function CopyPartnershipsForm({
  children,
  handleSubmit,
  submitting,
  partnerships,
  schoolYears,
  error,
  pristine,
  submitFailed,
}) {
  // Only show form wide error if field has been touched or submitted
  const showError = pristine && !submitFailed
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="partnerships"
        label={false}
        component={CheckboxGroup}
        options={serializePartnershipOptions({
          partnerships,
          schoolYears,
        })}
        format={(value) => formatObjectsToIds('id', value || [])}
        parse={(value) => {
          return partnerIdsToPartnerships(value, partnerships)
        }}
      />
      {children(showError ? '' : error)}
      <ButtonArea>
        <SubmitButton
          {...{ submitting, style: 'secondary' }}
          aria-label="Add Schools"
        >
          Add School(s)
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

CopyPartnershipsForm.propTypes = propTypes
CopyPartnershipsForm.defaultProps = defaultProps

// Make partnerships into "new" partnerships by removing their school year and id
const makePartnershipsNew = compose(removeSchoolYearIds, removeIds)

export default compose(
  lpForm({
    name: 'partner-copy-partnerships',
    beforeSubmit: ({ partnerships }) => ({
      partnerships: makePartnershipsNew(partnerships),
    }),
    validate: ({ partnerships }) => {
      const error = validatePartnerships(partnerships)
      return { _error: error }
    },
  })
)(CopyPartnershipsForm)
