import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { CommunityOfPracticeCard } from './'

const propTypes = {
  communitiesOfPractice: PropTypes.arrayOf(Types.communityOfPractice)
    .isRequired,
  sectionTitle: PropTypes.string.isRequired,
}

const defaultProps = {}

function CommunityOfPracticeSection({ communitiesOfPractice, sectionTitle }) {
  return (
    <div>
      <h2>
        <strong>{sectionTitle}</strong>
      </h2>
      <div className="card-grid">
        {communitiesOfPractice.map((cop) => (
          <CommunityOfPracticeCard key={cop.id} communityOfPractice={cop} />
        ))}
      </div>
    </div>
  )
}

CommunityOfPracticeSection.propTypes = propTypes
CommunityOfPracticeSection.defaultProps = defaultProps

export default CommunityOfPracticeSection
