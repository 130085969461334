import React from 'react'
// import PropTypes from 'prop-types'
// import { Link } from 'react-router'
// import logoImage from 'images/logo.png'
import { MapDisplay } from 'components'
import * as Types from 'types'

const propTypes = {
  location: Types.location,
}

function DashboardMap({ location, ...rest }) {
  const { latitude, longitude } = location
  const hasCoordinates = latitude && longitude
  return (
    <div className="map-display">
      {hasCoordinates ? (
        <MapDisplay
          locations={[location]}
          autoZoom
          height={300}
          width={300}
          {...rest}
        />
      ) : (
        <div style={{ height: '275px', width: '300px', background: 'grey' }}>
          No coordinates available at this time.
        </div>
      )}
    </div>
  )
}

DashboardMap.propTypes = propTypes

export default DashboardMap
