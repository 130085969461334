import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Spinner } from 'lp-components'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { selectors as globalSelectors } from 'educator-portal-reducer'
import { CommunityOfPracticeSection } from '../components'
import { isEmpty } from 'lodash'

const propTypes = {
  communitiesOfPractice: PropTypes.arrayOf(Types.communityOfPractice),
  userDetails: Types.user.isRequired,
  fetchCommunitiesOfPractice: PropTypes.func.isRequired,
}

const defaultProps = {
  communitiesOfPractice: null,
}

function CommunitiesOfPractice({
  communitiesOfPractice,
  userDetails,
  fetchCommunitiesOfPractice,
}) {
  useEffect(() => {
    fetchCommunitiesOfPractice()
  }, [])

  if (!communitiesOfPractice) return <Spinner />

  const memberCommunityOfPracticeIds = userDetails.memberships.map(
    (membership) => membership.communityOfPracticeId
  )

  const memberCops = []
  const exploreCops = []

  communitiesOfPractice.forEach((cop) => {
    memberCommunityOfPracticeIds.includes(cop.id)
      ? memberCops.push(cop)
      : exploreCops.push(cop)
  })

  return (
    <div id="community-of-practice-groups">
      {!isEmpty(memberCops) && (
        <CommunityOfPracticeSection
          communitiesOfPractice={memberCops}
          sectionTitle="Your Communities of Practice"
        />
      )}
      {!isEmpty(exploreCops) && (
        <CommunityOfPracticeSection
          communitiesOfPractice={exploreCops}
          sectionTitle="Explore Communities of Practice"
        />
      )}
    </div>
  )
}

CommunitiesOfPractice.propTypes = propTypes
CommunitiesOfPractice.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    communitiesOfPractice: selectors.communitiesOfPractice(state),
    userDetails: globalSelectors.userDetails(state),
  }
}

const mapDispatchToProps = {
  fetchCommunitiesOfPractice: apiActions.fetchCommunitiesOfPractice,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CommunitiesOfPractice
)
