import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Spinner } from 'lp-components'
import { ContentBlock, TermsForm } from 'components'
import * as apiActions from 'api-actions'
import { replace } from 'react-router-redux'
import { selectors as globalSelectors } from 'partner-portal-reducer'
import { displaySubmitFailure, useCommunity, useCommunityText } from 'utils'
import { PARTNER_PORTAL_ROUTE } from 'config'
import LayoutUnauthorized from './layout-unauthorized'
import { get } from 'lodash'

const propTypes = {
  agreeToTerms: PropTypes.func.isRequired,
  fetchUserDetails: PropTypes.func.isRequired,
  userDetails: Types.user,
  redirectToPortalDashboard: PropTypes.func.isRequired,
}

const defaultProps = {
  userDetails: null,
}

function Terms({
  agreeToTerms,
  fetchUserDetails,
  userDetails,
  redirectToPortalDashboard,
}) {
  const community = useCommunity()
  const t = useCommunityText()
  const terms = t('terms')

  useEffect(() => {
    if (terms && !userDetails) fetchUserDetails()
  }, [terms])

  useEffect(() => {
    if (!terms || get(userDetails, 'agreedAt')) redirectToPortalDashboard()
  }, [terms, userDetails])

  if (!userDetails) return <Spinner />

  return (
    <LayoutUnauthorized>
      <ContentBlock>
        <TermsForm
          terms={terms}
          supportEmail={community.partnerSupportEmail}
          logoutPath={PARTNER_PORTAL_ROUTE + '/sign-out'}
          onSubmit={agreeToTerms}
          onSubmitSuccess={redirectToPortalDashboard}
          onSubmitFail={displaySubmitFailure}
        />
      </ContentBlock>
    </LayoutUnauthorized>
  )
}

Terms.propTypes = propTypes
Terms.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    userDetails: globalSelectors.userDetails(state),
  }
}

const mapDispatchToProps = {
  agreeToTerms: apiActions.agreeToTerms,
  fetchUserDetails: apiActions.fetchCurrentPartnerUserDetails,
  redirectToPortalDashboard: () => replace(PARTNER_PORTAL_ROUTE),
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Terms)
