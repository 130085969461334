import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { camelCase, get, toSafeInteger, groupBy, isNil } from 'lodash'
import CoursesTable from './courses-table'
import { ExpandableItem } from 'components'
import { ArtDisciplineIcon } from 'components/icons'

const propTypes = {
  courseInformation: Types.courseInformation.isRequired,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  schoolCategory: PropTypes.string.isRequired,
}

function displaySurveySummaryValue(value, description) {
  if (isNil(value)) return ''
  return toSafeInteger(value) + ' ' + description
}

function CoursesSectionHeader({ title, discipline, coursesMeta }) {
  const { count, text, info } = coursesMeta
  return (
    <React.Fragment>
      <span className="image-block" aria-hidden>
        <ArtDisciplineIcon discipline={discipline.name} />
      </span>
      <span>{title}</span>
      <span className="section-header-details">
        <span>{`${count} ${text}`}</span>
        <span>
          {displaySurveySummaryValue(info.totalEnrollment, 'Students Enrolled')}
        </span>
      </span>
    </React.Fragment>
  )
}

const defaultProps = {}

function CoursesExpandableDetails({
  courseInformation,
  disciplines,
  schoolCategory,
}) {
  return (
    <div className="expandable-section">
      {disciplines.map((discipline) => {
        const disciplineNameKey = camelCase(discipline.name)
        const courseInformationForDiscipline = get(
          courseInformation,
          disciplineNameKey,
          {}
        )
        const coursesForDiscipline = get(
          courseInformationForDiscipline,
          'coursesData',
          []
        )
        const coursesBySchoolGrade = groupBy(
          coursesForDiscipline,
          'gradesOfferedTo'
        )
        const coursesCount = coursesForDiscipline.length
        const coursesText = coursesCount === 1 ? 'Course' : 'Courses'
        const disciplineHasCourses = coursesCount !== 0
        return (
          <ExpandableItem
            key={discipline.id}
            disabled={!disciplineHasCourses}
            headerComponent={CoursesSectionHeader}
            headerTitle={discipline.displayName}
            discipline={discipline}
            coursesMeta={{
              count: coursesCount,
              text: coursesText,
              info: courseInformationForDiscipline,
            }}
          >
            <CoursesTable
              coursesBySchoolGrade={coursesBySchoolGrade}
              schoolCategory={schoolCategory}
            />
          </ExpandableItem>
        )
      })}
    </div>
  )
}

CoursesExpandableDetails.propTypes = propTypes
CoursesExpandableDetails.defaultProps = defaultProps

export default CoursesExpandableDetails
