import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  ProfileOverview,
  BookmarkButton,
  FlagButton,
  FlaggedBanner,
  ProfileInfoList,
} from 'educator-portal-components'
import { getUserDisplayName } from 'utils'
import { pure } from 'recompose'
import { isEmpty } from 'lodash'
import classnames from 'classnames'

const propTypes = {
  user: Types.directoryUser.isRequired,
  isOwnProfile: PropTypes.bool.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  toggleBookmark: PropTypes.func.isRequired,
  onSelectFlagAction: PropTypes.func.isRequired,
  isModerator: PropTypes.bool.isRequired,
}

const defaultProps = {}

function ProfileDetails({
  user,
  isOwnProfile,
  isBookmarked,
  toggleBookmark,
  onSelectFlagAction,
  isModerator,
}) {
  const {
    profileId,
    firstName,
    lastName,
    avatarUrl: profileImgUrl,
    schoolNames,
    email,
    pronouns,
    flags,
  } = user
  const userDisplayName = getUserDisplayName({ firstName, lastName }, email)

  const hasFlags = !isEmpty(flags)

  return (
    <div id="profile-details">
      {hasFlags && <FlaggedBanner />}
      <div className="text-block">
        <div className="flex-horizontal">
          <ProfileOverview
            userDisplayName={userDisplayName}
            pronouns={pronouns}
            profileImgUrl={profileImgUrl}
            schoolNames={schoolNames}
          />
        </div>
      </div>
      <div className={classnames({ 'text-block': !isOwnProfile })}>
        <ProfileInfoList user={user} />
      </div>
      {!isOwnProfile && (
        <div className="action-items">
          <BookmarkButton
            isBookmarked={isBookmarked}
            toggleBookmark={toggleBookmark}
            ariaLabel={`Toggle profile bookmark on ${userDisplayName}`}
          />
          <FlagButton
            isFlagged={hasFlags}
            toggleFlag={() =>
              onSelectFlagAction(flags, profileId, Types.REVIEWABLE.PROFILE)
            }
            isModerator={isModerator}
            userDisplayName={userDisplayName}
          />
        </div>
      )}
    </div>
  )
}

ProfileDetails.propTypes = exact(propTypes)
ProfileDetails.defaultProps = defaultProps

export default pure(ProfileDetails)
