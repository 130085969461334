import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { pure } from 'recompose'
import { Announcement } from './'
import { isEmpty } from 'lodash'
import { BellIcon } from 'components/icons/basic'

const propTypes = {
  isModerator: PropTypes.bool.isRequired,
  openAnnouncementForm: PropTypes.func.isRequired,
  announcements: PropTypes.arrayOf(Types.announcement).isRequired,
  requestDeleteAnnouncement: PropTypes.func.isRequired,
  editOnSelect: PropTypes.func.isRequired,
}

const defaultProps = {}

function AnnouncementsList({
  isModerator,
  openAnnouncementForm,
  announcements,
  ...rest
}) {
  return (
    <div className="announcement-list">
      <header className="heading-text-wrapper">
        <div className="heading-text">
          <BellIcon />
          <h3>Announcements</h3>
        </div>
        {isModerator && (
          <button
            type="button"
            className="link-secondary no-underline"
            onClick={openAnnouncementForm}
          >
            + <span className="underline">Add Announcement</span>
          </button>
        )}
      </header>

      {isEmpty(announcements) ? (
        <div className="empty-state">
          <p>
            <strong>There are currently no announcements at this time</strong>
          </p>
        </div>
      ) : (
        <ul>
          {announcements.map((announcement) => {
            return (
              <li key={announcement.id} className="card-wrapper">
                <Announcement
                  announcement={announcement}
                  isModerator={isModerator}
                  {...rest}
                />
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

AnnouncementsList.propTypes = propTypes
AnnouncementsList.defaultProps = defaultProps

export default pure(AnnouncementsList)
