import useCommunity from './use-community'
import createDisplayText from './create-display-text'

// Provide a community text display func. Can be used similarly to `useTranslation` from "react-i18next"
function useCommunityText() {
  const community = useCommunity()
  return createDisplayText(community)
}

export default useCommunityText
