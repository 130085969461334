import React, { Suspense } from 'react'
// import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  useCommunity,
  isMapEnabled,
  isSchoolPortalEnabled,
  isPartnerPortalEnabled,
} from 'utils'
import { lazy } from '@loadable/component'
import Layout from '../layout'
import { get } from 'lodash'
import { CommunityPlaceholder } from '../components'
import CommunityLayout from '../community-layout'

const propTypes = {}

const defaultProps = {}

// Returns a generic home page if a specialized home page is not found
const CommunityHomePage = lazy(({ subdomain, mapEnabled }) => {
  return import(`./communities/${subdomain}.js`).catch(() => {
    if (!mapEnabled) return CommunityPlaceholder
    return CommunityLayout
  })
})

function Main() {
  const community = useCommunity()
  return (
    <Suspense fallback={null}>
      <Layout>
        <CommunityHomePage
          subdomain={get(community, 'subdomain')}
          mapEnabled={isMapEnabled(community)}
          schoolPortalEnabled={isSchoolPortalEnabled(community)}
          partnerPortalEnabled={isPartnerPortalEnabled(community)}
        />
      </Layout>
    </Suspense>
  )
}

Main.propTypes = propTypes
Main.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main)
