import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { InputLabel } from 'lp-components'
import { PaperclipIcon } from 'components/icons/basic'

const propTypes = {
  label: PropTypes.string,
}

const defaultProps = {
  label: 'Add Attachment(s)',
}

function AttachmentsLabel({ label, ...rest }) {
  return (
    <InputLabel {...rest}>
      <span className="attachment-icon" aria-hidden>
        <PaperclipIcon />
      </span>
      {label}
    </InputLabel>
  )
}

AttachmentsLabel.propTypes = propTypes
AttachmentsLabel.defaultProps = defaultProps

export default pure(AttachmentsLabel)
