import React from 'react'
// import PropTypes from 'prop-types'
import { pure } from 'recompose'

const propTypes = {}

const defaultProps = {}

function MapPlaceholderCard() {
  return (
    <div className="card">
      <svg
        width="84px"
        height="84px"
        viewBox="0 0 84 84"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d="M42,0 C65.1959595,0 84,18.8040405 84,42 C84,65.1959595 65.1959595,84 42,84 C18.8040405,84 0,65.1959595 0,42 C0,18.8040405 18.8040405,0 42,0 Z M42.4975198,17.0000008 C38.2538545,17.0000008 34.1840713,18.7110461 31.183812,21.756584 C28.1835527,24.802122 26.4986857,28.9325745 26.5000008,33.2389479 C26.5000008,45.0028715 35.7115958,59.4749642 40.1561776,65.7772459 C40.6973051,66.5446778 41.5700843,67.0000008 42.5000008,67.0000008 C43.4299157,67.0000008 44.3026949,66.5446778 44.8438224,65.7772459 C49.3082461,59.4749642 58.5000008,45.0028715 58.5000008,33.2389479 C58.5013148,28.9317022 56.8157669,24.8004865 53.8144346,21.7548037 C50.8131023,18.7091209 46.7420444,16.9986658 42.4975198,17.0000008 Z M42.4975198,24.6614015 C47.2698896,24.6614015 51.1386598,28.5873403 51.1386598,33.4302312 C51.1386598,38.2731221 47.2698896,42.1990609 42.4975198,42.1990609 C37.7251499,42.1990609 33.8563797,38.2731221 33.8563797,33.4302312 C33.8563797,28.5873403 37.7251499,24.6614015 42.4975198,24.6614015 Z"
            className="fill"
            fill="#4A4A4A"
          ></path>
        </g>
      </svg>
      <h2>Public Map</h2>
      <p>
        Provides free, unlimited access to unified and comprehensive arts
        education data for everyone in our community.
      </p>
      <span className="coming-soon">Coming Soon!</span>
    </div>
  )
}

MapPlaceholderCard.propTypes = propTypes
MapPlaceholderCard.defaultProps = defaultProps

export default pure(MapPlaceholderCard)
