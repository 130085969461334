import React, { useState, useCallback, useEffect } from 'react'
import { debounce, isNil } from 'lodash'
import PropTypes from 'prop-types'
import { LoadingContainer } from 'lp-components'
import { useUID } from 'react-uid'

const propTypes = {
  children: PropTypes.func,
  className: PropTypes.string,
  delay: PropTypes.number, // ms
  initialResults: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

const defaultProps = {
  delay: 500,
  initialResults: [],
  placeholder: 'Search',
  showLabel: false,
}

function Searchable({
  children,
  className,
  delay,
  initialResults,
  label,
  onSearch,
  placeholder,
  showLabel,
}) {
  const [searchQuery, setSearchQuery] = useState(null)
  const [results, setResults] = useState(initialResults)
  const [loading, setLoading] = useState(false)
  const id = 'searchable-' + useUID()

  const debouncedSearch = useCallback(
    debounce((query) => {
      setResults(onSearch(query))
      setLoading(false)
    }, delay),
    [onSearch]
  )

  // Search on all changes to search query (except on mount)
  useEffect(() => {
    if (!isNil(searchQuery)) debouncedSearch(searchQuery)
  }, [searchQuery])

  return (
    <div>
      <div className="search-bar">
        {showLabel && <label htmlFor={id}>{label}</label>}
        <input
          id={id}
          name="search"
          type="text"
          role="search"
          value={isNil(searchQuery) ? '' : searchQuery} // ensure component is treated as "controlled"
          onChange={(e) => {
            setLoading(true)
            setSearchQuery(e.target.value)
          }}
          autoComplete="off"
          placeholder={placeholder}
          aria-label={showLabel ? undefined : label}
          className={className}
        />
      </div>
      <LoadingContainer isLoading={loading}>
        {children(results, searchQuery)}
      </LoadingContainer>
    </div>
  )
}

Searchable.propTypes = propTypes
Searchable.defaultProps = defaultProps

export default Searchable
