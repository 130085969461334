// Set the CoP ID within post search filters

function setCommunityOfPracticeId(searchParams, communityOfPracticeId) {
  return {
    ...searchParams,
    filters: {
      ...searchParams.filters,
      // must be passed as an array to the backend
      communityOfPracticeId: [communityOfPracticeId],
    },
  }
}

export default setCommunityOfPracticeId
