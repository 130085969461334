import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ExpandableItem, ExternalLink } from 'components'
import {
  ProfileOverview,
  BookmarkButton,
  FlaggedBanner,
  FlagButton,
  FlagModal,
  ConfirmUnflagModal,
} from 'educator-portal-components'
import { getUserDisplayName, displaySubmitFailure } from 'utils'
import { isEmpty, get, find, first } from 'lodash'
import classnames from 'classnames'

const propTypes = {
  user: Types.directoryUser.isRequired,
  bookmarks: PropTypes.arrayOf(Types.bookmark).isRequired,
  addBookmark: PropTypes.func.isRequired,
  removeBookmark: PropTypes.func.isRequired,
  isModerator: PropTypes.bool.isRequired,
  requestCreateFlag: PropTypes.func.isRequired,
  requestRemoveFlag: PropTypes.func.isRequired,
  isOwnProfile: PropTypes.bool.isRequired,
}

const defaultProps = {}

function ProfileName({ name, pronouns }) {
  return (
    <h3>
      <strong>{name}</strong>
      {pronouns && (
        <span>
          <em> ({pronouns})</em>
        </span>
      )}
    </h3>
  )
}

function ProfileCardHeader({
  title: fullName,
  profileImgUrl,
  schoolNames,
  pronouns,
}) {
  return (
    <ProfileOverview
      userDisplayName={fullName}
      schoolNames={schoolNames}
      profileImgUrl={profileImgUrl}
      nameComponent={ProfileName}
      pronouns={pronouns}
    />
  )
}

function ProfileCard({
  user,
  bookmarks,
  addBookmark,
  removeBookmark,
  isModerator,
  requestCreateFlag,
  requestRemoveFlag,
  isOwnProfile,
}) {
  const {
    id,
    firstName,
    lastName,
    avatarUrl: profileImgUrl,
    schoolNames,
    email,
    disciplineNames,
    gradeNames,
    yearsOfExperience,
    pronouns,
    flags,
    profileId,
  } = user

  const bookmarkId = useMemo(() => {
    const matchingBookmark = find(bookmarks, {
      bookmarkableId: id,
      bookmarkableType: Types.BOOKMARKABLE.USER,
    })
    return get(matchingBookmark, 'id')
  }, [bookmarks, id])

  const isBookmarked = !!bookmarkId

  const toggleBookmark = useCallback(() => {
    if (!isBookmarked) return addBookmark(id)
    removeBookmark(bookmarkId)
  }, [isBookmarked, id, bookmarkId])

  const hasFlags = !isEmpty(flags)

  const toggleFlag = () => {
    if (!hasFlags) return setIsFlagModalOpen(true)
    if (!isModerator) return setIsConfirmUnflagModalOpen(true)

    requestRemoveFlag(first(flags).id)
  }

  const [isFlagModalOpen, setIsFlagModalOpen] = useState(false)
  const [isConfirmUnflagModalOpen, setIsConfirmUnflagModalOpen] =
    useState(false)

  const userDisplayName = getUserDisplayName({ firstName, lastName }, email)

  return (
    <React.Fragment>
      <div className="card">
        <div className="expandable-section">
          {hasFlags && <FlaggedBanner />}
          <ExpandableItem
            headerTitle={userDisplayName}
            headerComponent={ProfileCardHeader}
            profileImgUrl={profileImgUrl}
            schoolNames={schoolNames}
            pronouns={pronouns}
          >
            <div className={classnames({ 'text-block': !isOwnProfile })}>
              <ul className="profile-details-list">
                {email && (
                  <li>
                    <strong>Email:</strong>{' '}
                    <ExternalLink href={`mailto:${email}`}>
                      {email}
                    </ExternalLink>
                  </li>
                )}
                {!isEmpty(disciplineNames) && (
                  <li>
                    <strong>Disciplines:</strong> {disciplineNames.join(', ')}
                  </li>
                )}
                {!isEmpty(gradeNames) && (
                  <li className="no-break">
                    <strong>Grade Levels:</strong> {gradeNames.join(', ')}
                  </li>
                )}
                {yearsOfExperience && (
                  <li>
                    <strong>Years of Teaching Experience:</strong>{' '}
                    {yearsOfExperience}
                  </li>
                )}
              </ul>
            </div>
            {!isOwnProfile && (
              <div className="action-items">
                <BookmarkButton
                  isBookmarked={isBookmarked}
                  toggleBookmark={toggleBookmark}
                  ariaLabel={`Toggle profile bookmark on ${userDisplayName}`}
                />
                <FlagButton
                  isFlagged={hasFlags}
                  toggleFlag={toggleFlag}
                  isModerator={isModerator}
                  userDisplayName={userDisplayName}
                />
              </div>
            )}
          </ExpandableItem>
        </div>
      </div>
      {isFlagModalOpen && (
        <FlagModal
          onSubmit={({ reason }) =>
            requestCreateFlag({
              reviewableId: profileId,
              reason,
            })
          }
          onSubmitSuccess={() => setIsFlagModalOpen(false)}
          onClose={() => setIsFlagModalOpen(false)}
          onSubmitFail={displaySubmitFailure}
        />
      )}
      {isConfirmUnflagModalOpen && (
        <ConfirmUnflagModal
          onConfirm={() => {
            requestRemoveFlag(first(flags).id)
            setIsConfirmUnflagModalOpen(false)
          }}
          onClose={() => setIsConfirmUnflagModalOpen(false)}
        />
      )}
    </React.Fragment>
  )
}

ProfileCard.propTypes = exact(propTypes)
ProfileCard.defaultProps = defaultProps

export default pure(ProfileCard)
