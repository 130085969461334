import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ButtonArea } from 'lp-components'
import { ActionMenu, Modal, Attachments } from 'components'
import {
  ShareIcon,
  TrashIcon,
  EditIcon,
  CommentIcon,
} from 'components/icons/basic'
import {
  ProfileOverview,
  FlaggedBanner,
  PostUserContent,
  PostedDate,
  CommentsList,
  LikeButton,
} from 'educator-portal-components'
import { CommentForm } from '../forms'
import {
  displaySubmitFailure,
  getUserDisplayName,
  pluralize,
  flagActionIconAndLabel,
  serializeAttachmentsForFormField,
  getLikeId,
} from 'utils'
import { isEmpty, noop } from 'lodash'

const propTypes = {
  comment: Types.comment.isRequired,
  onShare: PropTypes.func,
  currentUserProfileImgUrl: PropTypes.string,
  requestArchiveComment: PropTypes.func.isRequired,
  showProfileDetails: PropTypes.func.isRequired,
  requestUpdateComment: PropTypes.func.isRequired,
  currentUserId: PropTypes.number.isRequired,
  currentUserDisplayName: PropTypes.string.isRequired,
  createComment: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  isCommentingDisabled: PropTypes.bool.isRequired,
  isThreadExpanded: PropTypes.bool.isRequired,
  toggleThread: PropTypes.func.isRequired,
  onSelectFlagAction: PropTypes.func.isRequired,
  isModerator: PropTypes.bool.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  mentionSuggestions: PropTypes.arrayOf(Types.mentionSuggestion),
  addLike: PropTypes.func.isRequired,
  destroyLike: PropTypes.func.isRequired,
}

const defaultProps = {
  onShare: noop,
  currentUserProfileImgUrl: null,
}

function Comment({
  comment,
  onShare,
  requestArchiveComment,
  showProfileDetails,
  requestUpdateComment,
  currentUserId,
  isThreadExpanded,
  toggleThread,
  isCommentingDisabled,
  onSelectFlagAction,
  isModerator,
  flashSuccessMessage,
  flashErrorMessage,
  mentionSuggestions,
  addLike,
  destroyLike,
  ...rest
}) {
  const {
    id,
    content,
    isEdited,
    createdAt,
    user: author,
    threadedComments,
    threadedCommentsCount,
    commentableType,
    flags,
    attachments,
    likes,
  } = comment
  const {
    id: authorId,
    name,
    schoolNames,
    profileImgUrl,
    pronouns,
    email,
  } = author
  const userDisplayName = getUserDisplayName({ name }, email)
  const onArchive = () => requestArchiveComment(id)

  const userLikeId = getLikeId(likes, currentUserId)
  const isLikedbyUser = !!userLikeId
  const likeCount = likes.length

  const [isEditFormOpen, setIsEditFormOpen] = useState(false)
  const [isArchiveWarningOpen, setIsArchiveWarningOpen] = useState(false)

  const isCommenter = authorId === currentUserId
  const hasThreadedComments = !isEmpty(threadedComments)

  const isParentComment = commentableType === Types.COMMENTABLE.POST
  const hasExpandableComments =
    isParentComment && (hasThreadedComments || !isCommentingDisabled)

  const numOfCommentsText = `${threadedCommentsCount} ${pluralize(
    'Comment',
    threadedCommentsCount
  )}`

  const hasFlags = !isEmpty(flags)

  const shareAction = {
    icon: ShareIcon,
    label: 'Share Comment',
    onSelect: onShare,
    actionProps: {
      'aria-label': `Share ${userDisplayName}'s comment`,
    },
  }

  const flagCommentAction = {
    ...flagActionIconAndLabel(hasFlags, isModerator),
    onSelect: () => onSelectFlagAction(flags, id, Types.REVIEWABLE.COMMENT),
  }

  const publicActions = isParentComment
    ? [shareAction, flagCommentAction]
    : [flagCommentAction]

  const commentActions = useMemo(() => {
    if (!isCommenter) return publicActions

    const archiveCommentAction = {
      icon: TrashIcon,
      label: 'Remove Comment',
      onSelect: hasThreadedComments
        ? () => setIsArchiveWarningOpen(true)
        : onArchive,
    }
    const editCommentAction = {
      icon: EditIcon,
      label: 'Edit Comment',
      onSelect: () => setIsEditFormOpen(true),
    }

    return [...publicActions, editCommentAction, archiveCommentAction]
  }, [publicActions, isCommenter, hasThreadedComments])

  const toggleLike = () => {
    if (!isLikedbyUser) return addLike(id, Types.LIKEABLE.COMMENT)
    destroyLike(userLikeId)
  }

  return (
    <React.Fragment>
      <div>
        {hasFlags && <FlaggedBanner />}
        <div className="text-block no-border">
          <ProfileOverview
            userDisplayName={userDisplayName}
            profileImgUrl={profileImgUrl}
            schoolNames={schoolNames}
            onProfileClick={() => showProfileDetails(authorId)}
            pronouns={pronouns}
          />
          <div className="flex-horizontal forum-action-menu">
            <PostedDate date={createdAt} />
            <ActionMenu actions={commentActions} />
          </div>
        </div>
      </div>
      <div>
        <PostUserContent content={content} isEdited={isEdited} isComment />
        {!isEmpty(attachments) && <Attachments attachments={attachments} />}
      </div>
      <div className="comment-card-activity">
        {hasExpandableComments && (
          <button
            onClick={() => toggleThread(isThreadExpanded)}
            className="comment-button"
            aria-label="Hide or reveal comment thread and reply form"
          >
            <CommentIcon aria-hidden />
            {hasThreadedComments ? numOfCommentsText : 'Comment'}
          </button>
        )}
        <LikeButton
          toggleLike={toggleLike}
          isLikedbyUser={isLikedbyUser}
          likeCount={likeCount}
          likeableType={Types.LIKEABLE.COMMENT}
          likeableId={id}
        />
      </div>
      {isThreadExpanded && (
        <div className="threaded-comments-wrapper">
          <CommentsList
            comments={threadedComments}
            currentUserId={currentUserId}
            requestArchiveComment={requestArchiveComment}
            requestUpdateComment={requestUpdateComment}
            showProfileDetails={showProfileDetails}
            isCommentingDisabled={isCommentingDisabled}
            commentableId={id}
            commentableType={Types.COMMENTABLE.COMMENT}
            commentFormPlaceholder={'Leave a Comment on this Thread'}
            onSelectFlagAction={onSelectFlagAction}
            isModerator={isModerator}
            flashSuccessMessage={flashSuccessMessage}
            flashErrorMessage={flashErrorMessage}
            mentionSuggestions={mentionSuggestions}
            addLike={addLike}
            destroyLike={destroyLike}
            {...rest}
          />
        </div>
      )}
      {isEditFormOpen && (
        <Modal onClose={() => setIsEditFormOpen(false)}>
          <h2>Edit Comment</h2>
          <CommentForm
            name={Types.COMMENT_FORM_NAME + '-edit'}
            onSubmit={({ content, attachments }) => {
              requestUpdateComment(id, content, attachments)
            }}
            onSubmitSuccess={() => {
              setIsEditFormOpen(false)
            }}
            initialValues={{
              content,
              id,
              attachments: serializeAttachmentsForFormField(attachments),
            }}
            onSubmitFail={displaySubmitFailure}
            onCancel={() => setIsEditFormOpen(false)}
            isEditing={isEditFormOpen}
            flashSuccessMessage={flashSuccessMessage}
            flashErrorMessage={flashErrorMessage}
            mentionSuggestions={mentionSuggestions}
          />
        </Modal>
      )}
      {isArchiveWarningOpen && (
        <Modal onClose={() => setIsArchiveWarningOpen(false)}>
          <h2>Remove this comment?</h2>
          <p>Removing this comment will also remove the threaded comments.</p>
          <ButtonArea>
            <button type="button" className="button-warn" onClick={onArchive}>
              Remove
            </button>
            <button
              type="button"
              className="button-grey-light"
              onClick={() => setIsArchiveWarningOpen(false)}
            >
              Cancel
            </button>
          </ButtonArea>
        </Modal>
      )}
    </React.Fragment>
  )
}

Comment.propTypes = exact(propTypes)
Comment.defaultProps = defaultProps

export default pure(Comment)
