import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  content: PropTypes.object.isRequired,
}

const defaultProps = {}

function CategoryExpandedContent(content) {
  const { note, excelling, strong, developing, emerging, incomplete } =
    content.content

  return (
    <div className="csc-details">
      {note && <p>{note}</p>}
      {excelling && (
        <ul>
          <li>
            <span />
            <h6>{incomplete.content}</h6>
            <p>{incomplete.label}</p>
          </li>
          <li>
            <span />
            <h6>{emerging.content}</h6>
            <p>{emerging.label}</p>
          </li>
          <li>
            <span />
            <h6>{developing.content}</h6>
            <p>{developing.label}</p>
          </li>
          <li>
            <span />
            <h6>{strong.content}</h6>
            <p>{strong.label}</p>
          </li>
          <li>
            <span />
            <h6>{excelling.content}</h6>
            <p>{excelling.label}</p>
          </li>
        </ul>
      )}
    </div>
  )
}

CategoryExpandedContent.propTypes = propTypes
CategoryExpandedContent.defaultProps = defaultProps

export default CategoryExpandedContent
