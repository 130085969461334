import React from 'react'
import PropTypes from 'prop-types'
import { flashMessageType } from 'redux-flash'
import FlashMessage from './flash'

/**
 * NOTE: Forked from lp-components to support custom flash component
 *
 * A component that displays multiple flash messages generated by [redux-flash](https://github.com/LaunchPadLab/redux-flash).
 * Most apps will need only one of these containers at the top level.
 * Will pass down any additional props to the inner `FlashMessage` components.
 *
 * @name FlashMessageContainer
 * @type Function
 * @param {Object} messages - The flash messages that will be displayed.
 * @param {Number} [limit] - Maximum number of concurrent messages to display
 * @example
 *
 * function MyApp ({ messages }) {
 *   return (
 *      <div>
 *         <FlashMessageContainer messages={ messages } />
 *         <RestOfTheApp />
 *      </div>
 *   )
 * }
 *
 */

const propTypes = {
  messages: PropTypes.arrayOf(flashMessageType).isRequired,
  limit: PropTypes.number,
}

const defaultProps = {
  limit: 5,
}

function FlashMessageContainer({ messages, limit, ...rest }) {
  const messagesToDisplay = messages.slice(0, limit)
  return (
    <div className="flash-message-container" role="alert" aria-atomic>
      {messagesToDisplay.map((message) => (
        <FlashMessage
          key={message.id}
          isError={message.isError}
          message={message.message}
          {...message.props}
          {...rest}
        />
      ))}
    </div>
  )
}

FlashMessageContainer.propTypes = propTypes
FlashMessageContainer.defaultProps = defaultProps

export default FlashMessageContainer
