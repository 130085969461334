import React from 'react'

function ProfessionalDevelopmentArtsIcon() {
  return (
    <svg viewBox="0 0 50 50">
      <path
        d="M50 25c0 13.807-11.193 25-25 25S0 38.807 0 25 11.193 0 25 0a25 25 0 0125 25z"
        fill="#204B81"
        className="fill"
      />
      <path
        d="M27.77 24.304A3.81 3.81 0 0125 30.74a3.8 3.8 0 01-3.747-4.443z"
        fill="#FFF"
      />
      <path
        d="M18.766 26.252a3.538 3.538 0 01-.629-1.171c-.059-.22-.071-.45-.035-.674.053-.311.19-.602.398-.844.26-.3.565-.562.903-.777a30.361 30.361 0 012.28-1.343l.132-.076-.61-.727.974-.575.566.847.143-.061c.788-.36 1.617-.627 2.47-.796.481-.103.977-.13 1.467-.077a2.207 2.207 0 011.855 1.372c.21.51.319 1.054.32 1.604.006.122.006.122-.117.163l-10.117 3.135z"
        fill="#FFF"
      />
      <path fill="#FFF" d="M17.27 41.95h1.65v1.55h-1.65z" />
      <path
        d="M35.09 28.87L32.16 21a1.56 1.56 0 00-2.89 1.16l2.44 6-3.9 3.86-5.2.95c-1 0-2 .87-2.72 1.31l-1.84 1.1 1.11-3a1.32 1.32 0 00-.73-1.79 1.62 1.62 0 00-1.95.78l-2.85 7.72 1.69 1.53 5.16-2.23v11.2A25.84 25.84 0 0025 50a25.37 25.37 0 004.73-.45v-12.7l5.36-7.98zM15 30.92v-.12a2.87 2.87 0 01.82-1v-28a23.93 23.93 0 00-3.11 1.47V37L15 30.92z"
        fill="#FFF"
      />
      <path
        d="M15 42.52l-.74-.67-1.5-1.35v6.31c1 .564 2.04 1.055 3.11 1.47v-6.14l-.87.38zM17.53 4.11v1.56h15.58v7.9H17.53v1.56h15.58v4.51c.204.247.369.524.49.82l2.61 7V2.66a25.85 25.85 0 00-3.11-1.31v2.76H17.53zM35.34 31.28l-1.49 2.22-.37.55-.37.55V42h-1.83v1.56h1.83v5.14a24.33 24.33 0 003.11-1.31V30l-.88 1.28z"
        fill="#FFF"
      />
    </svg>
  )
}

export default ProfessionalDevelopmentArtsIcon
