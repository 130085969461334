import { handleActions } from 'redux-actions'
// import { setOnSuccess } from 'lp-redux-api'
// import { selectorForSlice } from 'lp-redux-utils'

const reducerKey = 'user'
// const slice = 'root.partnerPortal.user'

const initialState = {}

const reducer = handleActions({}, initialState)

// const select = selectorForSlice(slice)

const selectors = {}

export { reducer, selectors, reducerKey }
