import React from 'react'
// import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { lpForm } from 'lp-form'
import { ButtonArea, SubmitButton, CheckboxGroup } from 'lp-components'
// import * as Types from 'types'
import { deepMap, formatObjectsToIds } from 'utils'
import { isPlainObject, omit } from 'lodash/fp'

const propTypes = {
  ...formPropTypes,
}

function schoolYearForProgram(program, schoolYears) {
  const schoolYear = schoolYears.find(({ id }) => id === program.schoolYearId)
  return schoolYear ? schoolYear.number : 'Unknown school year'
}

function serializeProgramOptions(programs, schoolYears) {
  return programs.map((program) => ({
    key: `${program.name} (${schoolYearForProgram(program, schoolYears)})`,
    value: program.id,
  }))
}

function removeIds(obj) {
  return deepMap(obj, (value) =>
    isPlainObject(value) ? omit('id', value) : value
  )
}

function removeSchoolYearIds(obj) {
  return deepMap(obj, (value) =>
    isPlainObject(value) ? omit('schoolYearId', value) : value
  )
}

function partnerIdsToPartnerships(partnershipIds, partnerships) {
  return partnershipIds.map((partnershipId) =>
    partnerships.find(({ id }) => id === partnershipId)
  )
}

function CopyProgramsForm({
  handleSubmit,
  invalid,
  pristine,
  submitting,
  error,
  programs,
  schoolYears,
}) {
  return (
    <form onSubmit={handleSubmit}>
      {error && (
        <p className="full-error-message">
          There was a problem copying the program
        </p>
      )}
      <Field
        name="programs"
        label={false}
        component={CheckboxGroup}
        options={serializeProgramOptions(programs, schoolYears)}
        format={formatObjectsToIds('id')}
        parse={(value) => {
          return partnerIdsToPartnerships(value, programs)
        }}
      />
      <ButtonArea>
        <SubmitButton
          {...{ invalid, pristine, submitting, style: 'secondary' }}
        >
          Add Program(s)
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

CopyProgramsForm.propTypes = propTypes

// Make programs into "new" programs by removing their school year and id
const makeProgramsNew = compose(removeSchoolYearIds, removeIds)

export default compose(
  lpForm({
    name: 'partner-copy-programs',
    beforeSubmit: ({ programs }) => ({ programs: makeProgramsNew(programs) }),
    constraints: {
      programs: { presence: true },
    },
  })
)(CopyProgramsForm)
