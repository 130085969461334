import * as Types from 'types'

const {
  STAFFING_AND_INSTRUCTION,
  COMMUNITY_AND_PARTNERSHIPS,
  BUDGET_AND_PLANNING,
} = Types.SURVEY_PAGES

function convertSurveyPageNameToNumber(name) {
  switch (name) {
    case STAFFING_AND_INSTRUCTION:
      return 'Page 1'
    case COMMUNITY_AND_PARTNERSHIPS:
      return 'Page 2'
    case BUDGET_AND_PLANNING:
      return 'Page 3'
  }
}

export default convertSurveyPageNameToNumber
