import { last } from 'lodash'

// Takes a url and grabs everything after the last '/'
// Returns a decoded version to convert URI percent encoding

function convertCloudinarySrcUrlToFilename(url) {
  return decodeURIComponent(last(url.split('/')))
}

export default convertCloudinarySrcUrlToFilename
