import React from 'react'

function DownloadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#0032A0" fillRule="nonzero" transform="translate(4 3)">
          <path d="M12 4.941H8.571V0H3.43v4.941H0l6 5.765 6-5.765zM0 12.353V14h12v-1.647H0z"></path>
        </g>
      </g>
    </svg>
  )
}

export default DownloadIcon
