import React from 'react'
import PropTypes from 'prop-types'
import {
  CommunityImage,
  ExternalLink,
  PortalNavAdditionalLogos,
} from 'components'
import { Link } from 'react-router'
import home from 'images/side-navigation/home.svg'
import map from 'images/side-navigation/map.svg'
import checkboxWindow from 'images/side-navigation/checkbox-window.svg'
import faq from 'images/side-navigation/faq.svg'
import { SCHOOL_PORTAL_ROUTE } from 'config'
import * as Types from 'types'
import { get } from 'lodash'
import classnames from 'classnames'

const propTypes = {
  community: PropTypes.object.isRequired,
  district: Types.district,
  isSurveyOpen: PropTypes.bool,
  surveySchoolYear: Types.schoolYear,
  currentSchoolYear: Types.schoolYear.isRequired,
  closeMobileNav: PropTypes.func.isRequired,
}

const defaultProps = {
  isSurveyOpen: false,
  district: null,
  surveySchoolYear: null,
}

function PortalPrimaryNav({
  community,
  district,
  isSurveyOpen,
  surveySchoolYear,
  currentSchoolYear,
  closeMobileNav,
}) {
  const { faqUrl, defaultBranding } = community
  const { communityLogo, secondarySideBySideLogo, thirdSideBySideLogo } =
    defaultBranding
  const surveySchoolYearNumber = get(surveySchoolYear, 'number')

  return (
    <nav className="side-navigation" aria-label="Primary navigation">
      <div className="side-navigation-inner">
        <div className="logo-block">
          <div
            className={classnames('logo-block-inner', {
              'side-by-side': secondarySideBySideLogo,
            })}
          >
            <CommunityImage image={communityLogo} />
            {secondarySideBySideLogo && (
              <div className="second-logo">
                <CommunityImage image={secondarySideBySideLogo} />
              </div>
            )}
            {thirdSideBySideLogo && (
              <div className="third-logo">
                <CommunityImage image={thirdSideBySideLogo} />
              </div>
            )}
          </div>
        </div>
        {surveySchoolYear !== undefined && (
          <ul>
            <li>
              <Link
                to={SCHOOL_PORTAL_ROUTE + '/dashboard'}
                onClick={closeMobileNav}
              >
                <img src={home} alt="" /> Home
              </Link>
            </li>
            <li>
              <Link
                to={SCHOOL_PORTAL_ROUTE + '/summary'}
                data-cy="profile-information"
                onClick={closeMobileNav}
              >
                <img src={checkboxWindow} alt="" />
                {currentSchoolYear.number} artlook Map Profile
              </Link>
            </li>
            {surveySchoolYearNumber && (
              <li>
                <Link
                  to={SCHOOL_PORTAL_ROUTE + '/survey'}
                  onClick={closeMobileNav}
                >
                  <img src={map} alt="" />
                  {`${surveySchoolYearNumber} Survey`}
                  {isSurveyOpen && <span className="badge">Open</span>}
                </Link>
              </li>
            )}
            {faqUrl && (
              <li>
                <ExternalLink href={faqUrl} onClick={closeMobileNav}>
                  <img src={faq} alt="" /> FAQ
                </ExternalLink>
              </li>
            )}
          </ul>
        )}
      </div>

      <PortalNavAdditionalLogos community={community} district={district} />
    </nav>
  )
}

PortalPrimaryNav.defaultProps = defaultProps
PortalPrimaryNav.propTypes = propTypes

export default React.memo(PortalPrimaryNav)
