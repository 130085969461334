import { EDUCATOR_PORTAL_ROUTE } from 'config'

// Return commonly used paths for the CoP views

function createCopPaths(communityOfPracticeId) {
  const allCopsPath = `${EDUCATOR_PORTAL_ROUTE}/communities-of-practice`
  const copWorkspacePath = `${allCopsPath}/${communityOfPracticeId}`
  // Posts are labeled as discussions in the UI
  const copPostRootPath = `${copWorkspacePath}/discussions`
  const copResourcesIndexPath = `${copWorkspacePath}/resources`

  return {
    allCopsPath,
    copWorkspacePath,
    copPostRootPath,
    copResourcesIndexPath,
  }
}

export default createCopPaths
