import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Tooltip } from 'components'
import { startCase, isEmpty } from 'lodash'

const propTypes = {
  category: PropTypes.string.isRequired,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  topics: PropTypes.arrayOf(Types.topicTag).isRequired,
}

const defaultProps = {}

function TopicTags({ tags }) {
  const isMobile = window.innerWidth <= 760

  const singleTags = isMobile ? tags.slice(0, 3) : tags
  const truncatedTags = isMobile ? tags.slice(3) : []

  return (
    <>
      {singleTags.map((tag) => (
        <li key={tag.id}>{tag.name}</li>
      ))}
      {!isEmpty(truncatedTags) && (
        <li key="truncated">
          +{truncatedTags.length}
          <Tooltip darkMode>
            {truncatedTags.map((tag) => tag.name).join(', ')}
          </Tooltip>
        </li>
      )}
    </>
  )
}

function PostTags({ category, disciplines, topics }) {
  const disciplineTags = disciplines.map((discipline) => ({
    id: discipline.id,
    name: discipline.displayName,
  }))
  const topicTags = topics.map((topic) => ({ id: topic.id, name: topic.name }))

  return (
    <div className="post-tags">
      <ul>
        <h4>
          <strong>School Type:</strong>
        </h4>
        <li>{startCase(category)}</li>
      </ul>
      <ul>
        <h4>
          <strong>Topic Tags:</strong>
        </h4>
        {<TopicTags tags={[...disciplineTags, ...topicTags]} />}
      </ul>
    </div>
  )
}

PostTags.propTypes = exact(propTypes)
PostTags.defaultProps = defaultProps

export default pure(PostTags)
