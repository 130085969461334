import React from 'react'
import { compose } from 'redux'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { lpForm } from 'lp-form'
import { ButtonArea, SubmitButton, Input } from 'lp-components'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

function AddPendingPartnerForm({ handleSubmit, submitting }) {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="organizationName"
        label="Organization Name"
        component={Input}
      />
      <Field
        name="organizationContactFirstName"
        label="Contact First Name - Optional"
        component={Input}
      />
      <Field
        name="organizationContactLastName"
        label="Contact Last Name - Optional"
        component={Input}
      />
      <Field
        name="organizationContactPhone"
        label="Contact Phone - Optional"
        component={Input}
      />
      <Field
        name="organizationContactEmail"
        label="Contact Email - Optional"
        component={Input}
      />
      <ButtonArea>
        <SubmitButton {...{ submitting, style: 'secondary' }}>
          Create Pending Partnership
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

AddPendingPartnerForm.propTypes = propTypes
AddPendingPartnerForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'school-add-pending-partner',
    constraints: {
      organizationName: { presence: true },
      organizationContactPhone: {
        format: {
          pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          message: 'must be a valid phone number',
        },
      },
      organizationContactEmail: {
        email: true,
      },
    },
  })
)(AddPendingPartnerForm)
