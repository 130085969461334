import React, { useCallback, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { connectParams } from 'lp-hoc'
import { Spinner, ButtonArea } from 'lp-components'
import { reset } from 'redux-form'
import { scroller } from 'react-scroll'
import { Modal } from 'components'
import {
  ShareIcon,
  TrashIcon,
  EditIcon,
  LockIcon,
  UnlockIcon,
} from 'components/icons/basic'
import NewPost from './new-post'
import {
  FlagModal,
  ViewPostCard,
  CommentsList,
  ProfileDetails,
  ConfirmUnflagModal,
} from 'educator-portal-components'
import { selectors as globalSelectors } from 'educator-portal-reducer'
import * as apiActions from 'api-actions'
import {
  clipboard,
  useOnOutsideClick,
  getUserDisplayName,
  displaySubmitFailure,
  flagActionIconAndLabel,
  getPostRootPath,
} from 'utils'
import * as flashActions from 'redux-flash'
import * as routerActions from 'react-router-redux'
import { first, isEmpty, get, find, some, last } from 'lodash'
import classnames from 'classnames'
import { NON_AFFILIATED_COP_ID } from 'config'

const propTypes = {
  postId: PropTypes.number.isRequired,
  fetchPost: PropTypes.func.isRequired,
  fetchComments: PropTypes.func.isRequired,
  post: Types.post,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired, // provided by react router
  push: PropTypes.func.isRequired,
  archivePost: PropTypes.func.isRequired,
  lockPost: PropTypes.func.isRequired,
  reopenPost: PropTypes.func.isRequired,
  archiveComment: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  currentUserId: PropTypes.number.isRequired,
  userDetails: Types.user.isRequired,
  isModerator: PropTypes.bool.isRequired,
  createComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  addBookmark: PropTypes.func.isRequired,
  removeBookmark: PropTypes.func.isRequired,
  addLike: PropTypes.func.isRequired,
  destroyLike: PropTypes.func.isRequired,
  bookmarks: PropTypes.arrayOf(Types.bookmark).isRequired,
  fetchUser: PropTypes.func.isRequired,
  selectedUser: Types.directoryUser,
  createFlag: PropTypes.func.isRequired,
  unflag: PropTypes.func.isRequired,
  disagreeWithFlag: PropTypes.func.isRequired,
  userMentionSuggestions: PropTypes.arrayOf(Types.mentionSuggestion),
  fetchMentionSuggestions: PropTypes.func.isRequired,
}

const defaultProps = {
  post: null,
  userProfile: null,
  selectedUser: null,
}

function checkMembershipStatus(memberships, communityOfPracticeId) {
  // only applies to CoP Posts, defaults to true for general Forum
  if (!communityOfPracticeId || communityOfPracticeId === NON_AFFILIATED_COP_ID)
    return true

  return some(memberships, { communityOfPracticeId })
}

function Post({
  postId,
  fetchPost,
  fetchComments,
  post,
  flashSuccessMessage,
  flashErrorMessage,
  location,
  push,
  archivePost,
  lockPost,
  reopenPost,
  archiveComment,
  redirect,
  currentUserId,
  userDetails,
  isModerator,
  createComment,
  updateComment,
  resetForm,
  addBookmark,
  removeBookmark,
  addLike,
  destroyLike,
  bookmarks,
  fetchUser,
  selectedUser,
  createFlag,
  unflag,
  disagreeWithFlag,
  userMentionSuggestions,
  fetchMentionSuggestions,
}) {
  const { profile: userProfile, email: userEmail, memberships } = userDetails
  const fetchPostAndComments = useCallback(() => {
    // Fetch comments after post to avoid required prop errors
    fetchPost(postId)
      .then((post) => {
        fetchComments(postId)
        setIsLocked(post.state === Types.POST_STATES.LOCKED)
      })
      .catch(() => redirect('/not-found'))
  }, [postId])

  useEffect(() => {
    fetchPostAndComments()
    fetchMentionSuggestions()
  }, [fetchPostAndComments])

  const rootPath = getPostRootPath(location.pathname)
  // Posts are labeled as discussions in the UI
  const postPath = `${rootPath}discussions/${postId}`
  const postUrl = window.origin + postPath
  const [focusedContainerId, setFocusedContainerId] = useState(null)
  const [isArchiveConfirmOpen, setIsArchiveConfirmOpen] = useState(false)
  const [isPostFormOpen, setIsPostFormOpen] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [isProfileDetailsOpen, setIsProfileDetailsOpen] = useState(false)
  const focusedRef = useOnOutsideClick(() => push(postPath))
  const [isLocked, setIsLocked] = useState(false)
  const [isFlagModalOpen, setIsFlagModalOpen] = useState(false)
  const [idToUnflag, setIdToUnflag] = useState(null)
  const [reviewableId, setReviewableId] = useState(null)
  const [reviewableType, setReviewableType] = useState(null)

  const copyPostUrl = useCallback(() => {
    clipboard
      .writeText(postUrl)
      .then(() => {
        flashSuccessMessage('The discussion link was copied to your clipboard.')
      })
      .catch((err) => {
        const message =
          err.message ||
          'Oops, unable to copy the discussion link to your clipboard.'
        flashErrorMessage(message)
      })
  }, [postUrl])

  const copyCommentUrl = useCallback(
    (commentContainerId) => {
      clipboard
        .writeText(`${postUrl}#${commentContainerId}`)
        .then(() => {
          flashSuccessMessage('The comment link was copied to your clipboard.')
        })
        .catch((err) => {
          const message =
            err.message ||
            'Oops, unable to copy the comment link to your clipboard.'
          flashErrorMessage(message)
        })
    },
    [postUrl]
  )

  const requestArchivePost = useCallback(() => {
    archivePost(postId)
      .then(() => {
        flashSuccessMessage('Your discussion was successfully removed.')
        redirect(rootPath)
      })
      .catch((err) => {
        setIsArchiveConfirmOpen(false)
        const message = err.message || 'Oops, unable to remove your discussion.'
        flashErrorMessage(message)
      })
  }, [postId])

  const requestLockPost = useCallback(() => {
    lockPost(postId)
      .then(() => {
        flashSuccessMessage(
          'Commenting has been turned off for this discussion.'
        )
        setIsLocked(true)
      })
      .catch((err) => {
        const message =
          err.message ||
          'Oops, unable to turn off commenting for this discussion'
        flashErrorMessage(message)
      })
  }, [postId])

  const requestReopenPost = useCallback(() => {
    reopenPost(postId)
      .then(() => {
        flashSuccessMessage(
          'Commenting has been turned on for this discussion.'
        )
        setIsLocked(false)
      })
      .catch((err) => {
        const message =
          err.message ||
          'Oops, unable to turn on commenting for this discussion'
        flashErrorMessage(message)
      })
  }, [postId])

  const getBookmarkId = useCallback(
    (bookmarkableId, bookmarkableType) => {
      const matchingBookmark = find(bookmarks, {
        bookmarkableId,
        bookmarkableType,
      })
      return get(matchingBookmark, 'id')
    },
    [bookmarks]
  )

  const postBookmarkId = getBookmarkId(postId, Types.BOOKMARKABLE.POST)
  const selectedUserBookmarkId = getBookmarkId(
    selectedUserId,
    Types.BOOKMARKABLE.USER
  )
  const isPostBookmarked = !!postBookmarkId
  const isSelectedUserBookmarked = !!selectedUserBookmarkId

  const requestAddBookmark = useCallback(
    (type) => {
      const bookmarkableId =
        type === Types.BOOKMARKABLE.POST ? postId : selectedUserId
      addBookmark({ bookmarkableId, bookmarkableType: type })
        .then(({ successMessage }) => {
          flashSuccessMessage(successMessage)
        })
        .catch(({ errorMessage }) => {
          flashErrorMessage(errorMessage)
        })
    },
    [postId, selectedUserId]
  )
  const requestRemoveBookmark = useCallback(
    (type) => {
      const bookmarkId =
        type === Types.BOOKMARKABLE.POST
          ? postBookmarkId
          : selectedUserBookmarkId
      removeBookmark({ bookmarkId, bookmarkableType: type })
        .then(({ successMessage }) => {
          flashSuccessMessage(successMessage)
        })
        .catch(({ errorMessage }) => {
          flashErrorMessage(errorMessage)
        })
    },
    [postBookmarkId, selectedUserBookmarkId]
  )

  const requestAddLike = useCallback((likeableId, likeableType) => {
    addLike({
      likeableId,
      likeableType,
    }).catch(({ errorMessage }) => {
      flashErrorMessage(errorMessage)
    })
  }, [])

  const requestDestroyLike = useCallback((id) => {
    destroyLike({ id }).catch(({ errorMessage }) => {
      flashErrorMessage(errorMessage)
    })
  }, [])

  const requestArchiveComment = useCallback((commentId) => {
    archiveComment(commentId)
      .then(() => {
        flashSuccessMessage('Your comment was successfully removed.')
      })
      .catch((err) => {
        const message = err.message || 'Oops, unable to remove your comment.'
        flashErrorMessage(message)
      })
  }, [])

  const requestUpdateComment = useCallback(
    (commentId, content, attachments) => {
      updateComment(commentId, content, attachments)
        .then(() => {
          flashSuccessMessage('Your comment was successfully edited!')
        })
        .catch((err) => {
          const message = err.message || 'Oops, unable to edit your comment.'
          flashErrorMessage(message)
        })
    },
    []
  )

  const requestCreateFlag = useCallback(
    ({ reviewableId, reviewableType, reason }) => {
      createFlag({ reviewableId, reviewableType, reason })
        .then(({ successMessage }) => {
          flashSuccessMessage(successMessage)
        })
        .catch(({ errorMessage }) => {
          flashErrorMessage(errorMessage)
        })
    },
    []
  )

  const requestUnflag = useCallback((flagId) => {
    unflag(flagId)
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }, [])

  const requestDisagreeWithFlag = useCallback((flagId) => {
    disagreeWithFlag(flagId)
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }, [])

  useEffect(() => {
    const elementId = location.hash.slice(1)
    setFocusedContainerId(elementId)
  }, [location.hash])

  useEffect(() => {
    if (!focusedContainerId) return
    if (focusedContainerId.includes(Types.MENTIONED_USER_ID_PREFIX)) {
      const userId = last(focusedContainerId.split('-'))
      setSelectedUserId(parseInt(userId))
      setIsProfileDetailsOpen(true)
    }
  }, [focusedContainerId])

  useEffect(() => {
    if (selectedUserId) fetchUser(selectedUserId)
  }, [selectedUserId])
  const hasSelectedUserLoaded =
    selectedUser && selectedUser.id === selectedUserId

  const hasCorrectPost = post && post.id === postId
  const hasCorrectComments = useMemo(() => {
    if (!post || !post.comments) return false
    if (isEmpty(post.comments)) return true
    return first(post.comments).postId === postId
  }, [post, postId])
  const isOriginalPoster = hasCorrectPost && post.user.id === currentUserId

  useEffect(() => {
    if (hasCorrectComments && focusedContainerId && focusedRef.current) {
      scroller.scrollTo(focusedContainerId, { smooth: true })
      /* For shared comments, focus the comment's container so that
      the next tabbable element is the first tabbable item within
      the comment, not the skip - nav */
      focusedRef.current.focus()
    }
  }, [hasCorrectComments, focusedContainerId, focusedRef])

  useEffect(() => {
    // make sure profile details modal is closed when opening flag modal
    if (isProfileDetailsOpen && isFlagModalOpen) setIsProfileDetailsOpen(false)
  }, [isProfileDetailsOpen, isFlagModalOpen])

  if (!hasCorrectPost) return <Spinner />

  const isCoPMember = checkMembershipStatus(
    memberships,
    post.communityOfPracticeId
  )

  const setReviewableAndOpenModal = (reviewableId, reviewableType) => {
    setReviewableId(reviewableId)
    setReviewableType(reviewableType)
    setIsFlagModalOpen(true)
  }

  const hasFlags = !isEmpty(post.flags)

  const requestRemoveFlag = (flagId) => {
    if (!isModerator) return requestUnflag(flagId)

    return requestDisagreeWithFlag(flagId)
  }

  const onSelectFlagAction = (flags, reviewableId, reviewableType) => {
    if (isEmpty(flags))
      return setReviewableAndOpenModal(reviewableId, reviewableType)
    if (!isModerator) return setIdToUnflag(first(flags).id)

    requestRemoveFlag(first(flags).id)
  }

  const sharePostAction = {
    icon: ShareIcon,
    label: 'Share Discussion',
    onSelect: copyPostUrl,
  }

  const flagPostAction = {
    ...flagActionIconAndLabel(hasFlags, isModerator),
    onSelect: () =>
      onSelectFlagAction(post.flags, postId, Types.REVIEWABLE.POST),
  }

  const publicActions = [sharePostAction, flagPostAction]

  const lockPostAction = {
    icon: LockIcon,
    label: 'Lock Commenting',
    onSelect: requestLockPost,
  }

  const unlockPostAction = {
    icon: UnlockIcon,
    label: 'Unlock Commenting',
    onSelect: requestReopenPost,
  }

  const moderatorActions = isLocked ? [unlockPostAction] : [lockPostAction]

  const editPostAction = {
    icon: EditIcon,
    label: 'Edit Discussion',
    onSelect: () => setIsPostFormOpen(true),
  }

  const archivePostAction = {
    icon: TrashIcon,
    label: 'Remove Discussion',
    onSelect: () => setIsArchiveConfirmOpen(true),
  }

  const posterActions = [editPostAction, archivePostAction]

  const getPostActions = () => {
    const actions = [...publicActions]

    if (isModerator) {
      actions.push(...moderatorActions)
    }

    if (isOriginalPoster) {
      actions.push(...posterActions)
    }

    return actions
  }

  return (
    <div className="post">
      <Link className="link-secondary no-underline" to={rootPath}>
        ← <span className="underline">Return to All Discussions</span>
      </Link>
      <ViewPostCard
        post={post}
        postPath={postPath}
        actions={getPostActions()}
        showProfileDetails={() => {
          setSelectedUserId(post.user.id)
          setIsProfileDetailsOpen(true)
        }}
        isBookmarked={isPostBookmarked}
        toggleBookmark={() => {
          if (isPostBookmarked)
            return requestRemoveBookmark(Types.BOOKMARKABLE.POST)
          return requestAddBookmark(Types.BOOKMARKABLE.POST)
        }}
        hasFlags={hasFlags}
        addLike={requestAddLike}
        destroyLike={requestDestroyLike}
        userDetails={userDetails}
      />
      {!hasCorrectComments ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <CommentsList
            comments={post.comments}
            copyCommentUrl={copyCommentUrl}
            highlightedContainerId={focusedContainerId}
            highlightedRef={focusedRef}
            currentUserId={currentUserId}
            requestArchiveComment={requestArchiveComment}
            requestUpdateComment={requestUpdateComment}
            showProfileDetails={(userId) => {
              setSelectedUserId(userId)
              setIsProfileDetailsOpen(true)
            }}
            showUnfoundCommentError={() => {
              flashErrorMessage("Sorry, we can't find that comment.")
            }}
            createComment={createComment}
            resetForm={resetForm}
            isCommentingDisabled={isLocked || (!isModerator && !isCoPMember)}
            currentUserDisplayName={getUserDisplayName(userProfile, userEmail)}
            currentUserProfileImgUrl={get(userProfile, 'avatarUrl')}
            commentableId={postId}
            commentableType={Types.COMMENTABLE.POST}
            onSelectFlagAction={onSelectFlagAction}
            isModerator={isModerator}
            flashSuccessMessage={flashSuccessMessage}
            flashErrorMessage={flashErrorMessage}
            mentionSuggestions={userMentionSuggestions}
            addLike={requestAddLike}
            destroyLike={requestDestroyLike}
          />
          {isLocked ? (
            <p className="text-center">
              <em>Commenting has been turned off for this discussion.</em>
            </p>
          ) : (
            <React.Fragment>
              {!isModerator && !isCoPMember && (
                <p className="text-center">
                  <em>
                    You must be a member of this Community to comment on this
                    discussion.
                  </em>
                </p>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {isArchiveConfirmOpen && (
        <Modal onClose={() => setIsArchiveConfirmOpen(false)}>
          <h2>Are you sure?</h2>
          <div className="modal-content">
            <p>
              By removing your discussion, you will also be removing any
              comments on your discussion.
            </p>
            <ButtonArea>
              <button
                type="button"
                className="button-warn"
                onClick={requestArchivePost}
              >
                Remove
              </button>
              <button
                type="button"
                className="button-grey-light"
                onClick={() => setIsArchiveConfirmOpen(false)}
              >
                Cancel
              </button>
            </ButtonArea>
          </div>
        </Modal>
      )}
      {isPostFormOpen && (
        <Modal onClose={() => setIsPostFormOpen(false)}>
          <NewPost
            postToEdit={post}
            onSubmitSuccess={() => {
              fetchPostAndComments()
              setIsPostFormOpen(false)
              flashSuccessMessage('Your discussion was edited successfully!')
            }}
            onCancel={() => setIsPostFormOpen(false)}
            communityOfPracticeId={post.communityOfPracticeId}
          />
        </Modal>
      )}
      {isProfileDetailsOpen && hasSelectedUserLoaded && (
        <Modal
          onClose={() => setIsProfileDetailsOpen(false)}
          className={classnames('show-flash', {
            'is-flagged': !isEmpty(selectedUser.flags),
          })}
        >
          <div className="discussion-card">
            <ProfileDetails
              user={selectedUser}
              isOwnProfile={selectedUserId === currentUserId}
              isBookmarked={isSelectedUserBookmarked}
              toggleBookmark={() => {
                if (isSelectedUserBookmarked)
                  return requestRemoveBookmark(Types.BOOKMARKABLE.USER)
                return requestAddBookmark(Types.BOOKMARKABLE.USER)
              }}
              onSelectFlagAction={onSelectFlagAction}
              isModerator={isModerator}
            />
          </div>
        </Modal>
      )}
      {isFlagModalOpen && (
        <FlagModal
          onSubmit={({ reason }) =>
            requestCreateFlag({ reviewableId, reviewableType, reason })
          }
          onSubmitSuccess={() => setIsFlagModalOpen(false)}
          onClose={() => setIsFlagModalOpen(false)}
          onSubmitFail={displaySubmitFailure}
        />
      )}
      {idToUnflag && (
        <ConfirmUnflagModal
          onConfirm={() => {
            requestRemoveFlag(idToUnflag)
            setIdToUnflag(null)
          }}
          onClose={() => setIdToUnflag(null)}
        />
      )}
    </div>
  )
}

Post.propTypes = propTypes
Post.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    post: globalSelectors.post(state),
    currentUserId: globalSelectors.userId(state),
    userDetails: globalSelectors.userDetails(state),
    isModerator: globalSelectors.isModerator(state),
    bookmarks: globalSelectors.bookmarks(state),
    selectedUser: globalSelectors.directoryUser(state),
    userMentionSuggestions: globalSelectors.userMentionSuggestions(state),
  }
}

const mapDispatchToProps = {
  fetchPost: apiActions.fetchPost,
  fetchComments: apiActions.fetchComments,
  archivePost: apiActions.archivePost,
  lockPost: apiActions.lockPost,
  reopenPost: apiActions.reopenPost,
  createComment: apiActions.createComment,
  updateComment: apiActions.updateComment,
  archiveComment: apiActions.archiveComment,
  addBookmark: apiActions.addBookmark,
  removeBookmark: apiActions.removeBookmark,
  addLike: apiActions.addLike,
  destroyLike: apiActions.destroyLike,
  fetchUser: apiActions.fetchDirectoryUser,
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  push: routerActions.push,
  redirect: routerActions.replace,
  resetForm: reset,
  createFlag: apiActions.createFlag,
  unflag: apiActions.unflag,
  disagreeWithFlag: apiActions.disagreeWithFlag,
  fetchMentionSuggestions: apiActions.fetchMentionSuggestions,
}

export default compose(
  connectParams('postId'),
  connect(mapStateToProps, mapDispatchToProps)
)(Post)
