import React from 'react'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Field, FormSection, propTypes as formPropTypes } from 'redux-form'
import { Input, SubmitButton } from 'lp-components'

const propTypes = {
  ...formPropTypes,
}

function ArtsLiaisonForm({
  handleSubmit,
  submitSucceeded,
  pristine,
  submitting,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate id="school-arts-liaison">
      <FormSection name="contacts">
        <span className="row">
          <Field className="col-3" name="firstName" component={Input} />
          <Field className="col-3" name="lastName" component={Input} />
          <Field className="col-2" name="phone" type="tel" component={Input} />
          <Field
            className="col-3"
            name="email"
            type="email"
            component={Input}
          />
        </span>
      </FormSection>
      <div className="button-wrapper">
        <SubmitButton
          {...{ pristine: submitSucceeded && pristine, submitting }}
        >
          Save Response
        </SubmitButton>
      </div>
    </form>
  )
}
ArtsLiaisonForm.propTypes = propTypes

export default compose(
  lpForm({
    name: 'school-arts-liason',
    enableReinitialize: true,
    constraints: {
      'contacts.firstName': {
        presence: true,
      },
      'contacts.lastName': {
        presence: true,
      },
      'contacts.phone': {
        presence: true,
        format: {
          pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          message: 'must be a valid phone number',
        },
      },
      'contacts.email': { presence: true, email: true },
    },
  })
)(ArtsLiaisonForm)
