import React from 'react'

function FolderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#4A4A4A" fillRule="nonzero" transform="translate(2 4)">
          <path d="M6.4 0H1.6C.712 0 0 .723 0 1.625v9.75C0 12.269.72 13 1.6 13h12.8c.88 0 1.6-.731 1.6-1.625V3.25c0-.902-.72-1.625-1.6-1.625H8L6.4 0z"></path>
        </g>
      </g>
    </svg>
  )
}

export default FolderIcon
