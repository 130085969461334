import * as communities from '../../communityConfigs/'
import getCurrentSubdomain from './get-current-subdomain'
import { values } from 'lodash'

/* eslint-disable no-console */

const COMMUNITY_DOMAIN_KEY = 'communityDomain'
const COMMUNITY_OVERRIDE_KEY = 'communityConfig'

// Return the correct community for the current subdomain
export function getCurrentCommunity() {
  const subdomainToSearch = getSubdomainToSearch()
  const community = getCommunity(subdomainToSearch)

  if (community) {
    if (community.subdomain !== subdomainToSearch)
      logCommunityOverrideWarning(subdomainToSearch, community.subdomain)
    return community
  }

  console.warn(
    `WARNING: No community found for subdomain '${subdomainToSearch}'. Make sure to specify a domain via localStorage if you need to test out an individual community.`
  )
  return null
}

// ----- PRIVATE -----

// Allow subdomain configuration to be decoupled from data
function getSubdomainToSearch() {
  const currentSubdomain = getCurrentSubdomain()
  const overrideDomain =
    !!process.env.COMMUNITY_OVERRIDE &&
    localStorage.getItem(COMMUNITY_OVERRIDE_KEY)

  return overrideDomain || currentSubdomain
}

function getCommunity(subdomainToSearch) {
  return values(communities).find(
    ({ subdomain }) => subdomain === subdomainToSearch
  )
}

function logCommunityOverrideWarning(currentSubdomain, actualSubdomain) {
  if (currentSubdomain)
    return console.warn(
      `WARNING: ${currentSubdomain} community configuration is being overridden by ${actualSubdomain}. To change this behavior, modify or clear the '${COMMUNITY_OVERRIDE_KEY}' value in localStorage.`
    )

  return console.warn(
    `WARNING: Community configuration is currently set to ${actualSubdomain}. To change this behavior, modify or clear the ${COMMUNITY_DOMAIN_KEY} and/or ${COMMUNITY_OVERRIDE_KEY} value in localStorage.`
  )
}

export default getCurrentCommunity
