import { get } from 'lodash'

function getCloudinaryUploadErrorMessage(error) {
  const baseMessage = 'File upload failed. '
  const responseError = get(
    error,
    'response.error.message',
    'Please review accepted formats and try again.'
  )
  const specificMessage = responseError.includes('size too large')
    ? 'File size too large. Maximum is 10MB.'
    : responseError

  return baseMessage + specificMessage
}

export default getCloudinaryUploadErrorMessage
