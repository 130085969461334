import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import BellIcon from './icons/basic/bell-icon'

const propTypes = {}

const defaultProps = {}

function NotificationEmptyState() {
  return (
    <div className="notification-container">
      <div className="notification-container__empty-icon">
        <BellIcon white />
      </div>
      <div className="notification-container__empty-body">
        <p className="notification-container__empty-title">
          You don't have any Notifications
        </p>
        <p className="notification-container__empty-message">
          Once you have Notifications they will appear here
        </p>
      </div>
    </div>
  )
}

NotificationEmptyState.propTypes = exact(propTypes)
NotificationEmptyState.defaultProps = defaultProps

export default React.memo(NotificationEmptyState)
