import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import * as flashActions from 'redux-flash'
import { PostForm } from '../forms'
import { selectors as globalEducatorSelectors } from 'educator-portal-reducer'
import { selectors as globalSelectors } from 'global-reducer'
import {
  getFormWideErrorMessage,
  serializeOptions,
  serializeAttachmentsForFormField,
} from 'utils'
import { startCase } from 'lodash'
import { HiddenSpan } from 'components'
import { Spinner } from 'lp-components'

const propTypes = {
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  fetchPostTopics: PropTypes.func.isRequired,
  topics: PropTypes.arrayOf(Types.topicTag),
  createOrUpdatePost: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  postToEdit: Types.post,
  communityOfPracticeId: PropTypes.number,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  userMentionSuggestions: PropTypes.arrayOf(Types.mentionSuggestion),
  fetchMentionSuggestions: PropTypes.func.isRequired,
}

const defaultProps = {
  topics: null,
  postToEdit: null,
  communityOfPracticeId: null,
}

function serializePostForEdit(post) {
  if (!post) return { attachments: [] }

  const { disciplines, topics, attachments } = post
  const disciplineIds = disciplines.map(({ id }) => ({ disciplineId: id }))
  const topicIds = topics.map(({ id }) => ({ topicId: id }))
  return {
    ...post,
    disciplines: disciplineIds,
    topics: topicIds,
    attachments: serializeAttachmentsForFormField(attachments),
  }
}

function NewPost({
  disciplines,
  fetchPostTopics,
  topics,
  createOrUpdatePost,
  onCancel,
  postToEdit,
  onSubmitSuccess,
  communityOfPracticeId,
  flashSuccessMessage,
  flashErrorMessage,
  userMentionSuggestions,
  fetchMentionSuggestions,
}) {
  const [formWideError, setFormWideError] = useState(null)
  const [submitFailCount, setSubmitFailCount] = useState(0)
  const containerRef = useRef()

  useEffect(() => {
    // Use stale-while-replace strategy (i.e., always fetch new topics but don't prevent form from loading)
    fetchPostTopics()
  }, [])

  useEffect(() => {
    fetchMentionSuggestions()
  }, [])

  const schoolTypeOptions = Types.SCHOOL_TYPES.map((type) => ({
    key: startCase(type),
    value: type,
  }))

  const isEditing = !!postToEdit
  const hasLoaded = !!topics && !!userMentionSuggestions

  return (
    <div ref={containerRef}>
      <h2>{isEditing ? 'Edit Discussion' : 'Start New Discussion'}</h2>
      <div role="alert" aria-atomic>
        {formWideError && (
          <p className="full-error-message">
            {formWideError} <HiddenSpan>{submitFailCount}</HiddenSpan>
          </p>
        )}
      </div>
      {hasLoaded ? (
        <PostForm
          initialValues={serializePostForEdit(postToEdit)}
          disciplineOptions={serializeOptions(disciplines)}
          schoolTypeOptions={schoolTypeOptions}
          topicOptions={serializeOptions(topics, { key: 'name' })}
          isEditing={isEditing}
          onCancel={onCancel}
          onSubmit={(post) =>
            createOrUpdatePost(post, post.id, communityOfPracticeId)
          }
          onSubmitSuccess={onSubmitSuccess}
          onSubmitFail={(errors, _, submitError) => {
            const msg = getFormWideErrorMessage(errors, submitError)
            setFormWideError(msg)
            setSubmitFailCount((sfc) => sfc + 1)
            containerRef.current.scrollIntoView({ behavior: 'smooth' }) // reset view to top of form
          }}
          flashSuccessMessage={flashSuccessMessage}
          flashErrorMessage={flashErrorMessage}
          mentionSuggestions={userMentionSuggestions}
        />
      ) : (
        <Spinner />
      )}
    </div>
  )
}

NewPost.propTypes = propTypes
NewPost.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    disciplines: globalSelectors.definedDisciplines(state),
    topics: globalEducatorSelectors.postTopics(state),
    userMentionSuggestions:
      globalEducatorSelectors.userMentionSuggestions(state),
  }
}

const mapDispatchToProps = {
  fetchPostTopics: apiActions.fetchPostTopics,
  createOrUpdatePost: apiActions.createOrUpdatePost,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  flashErrorMessage: flashActions.flashErrorMessage,
  fetchMentionSuggestions: apiActions.fetchMentionSuggestions,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(NewPost)
