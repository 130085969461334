import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as Types from 'types'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors as globalPartnerSelectors } from 'partner-portal-reducer'
import { Spinner } from 'lp-components'
import { PortalHeader } from 'components'
import { PARTNER_PORTAL_ROUTE } from 'config'
import { useCommunity } from 'utils'

const propTypes = {
  children: PropTypes.node,
  activeSchoolYears: PropTypes.arrayOf(Types.schoolYear),
  currentSchoolYear: Types.schoolYear.isRequired,
}

const defaultProps = {
  children: null,
  activeSchoolYears: null,
}

function Layout({ children, activeSchoolYears, currentSchoolYear }) {
  const {
    defaultBranding: { dashboardHeader },
    partnerProgramsEnabled,
  } = useCommunity()

  const links = [
    {
      name: 'Organization',
      path: PARTNER_PORTAL_ROUTE + '/profile/organization',
    },
    {
      name: 'School Partnerships',
      path: PARTNER_PORTAL_ROUTE + '/profile/partnerships',
    },
    {
      name: 'Contacts',
      path: PARTNER_PORTAL_ROUTE + '/profile/contacts',
    },
  ]

  if (partnerProgramsEnabled) {
    links.splice(1, 0, {
      name: 'Programs',
      path: PARTNER_PORTAL_ROUTE + '/profile/programs',
    })
  }

  const hasLoaded = !!activeSchoolYears
  return (
    <div>
      <PortalHeader
        title={`${currentSchoolYear.number} artlook Map Profile`}
        backgroundImage={dashboardHeader}
        links={links}
      />
      <div className="content-block-container">
        {!hasLoaded ? <Spinner /> : <React.Fragment>{children}</React.Fragment>}
      </div>
    </div>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    activeSchoolYears: globalPartnerSelectors.orderedActiveSchoolYears(state),
    currentSchoolYear: globalSelectors.currentSchoolYear(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
