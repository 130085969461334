import { get } from 'lodash'

// Returns true if a "type" object has a name of "other".

function isOtherType(type) {
  if (!type) return false
  return get(type, 'name') === 'other'
}

export default isOtherType
