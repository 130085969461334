import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import {
  Menu,
  MenuItems,
  MenuPopover,
  MenuButton,
  MenuItem,
} from '@reach/menu-button'
import { positionRight } from '@reach/popover'

const propTypes = {
  actions: PropTypes.arrayOf(Types.menuAction).isRequired,
  buttonLabel: PropTypes.node,
}

const defaultProps = {
  buttonLabel: <span aria-label="Actions">...</span>,
}

function ActionMenu({ actions, buttonLabel, ...rest }) {
  return (
    <Menu>
      <MenuButton className="action-menu-button link-secondary">
        {buttonLabel}
      </MenuButton>
      <MenuPopover position={positionRight} {...rest}>
        <MenuItems>
          {actions.map(({ label, onSelect, icon: Icon, actionProps = {} }) => (
            <MenuItem key={label} onSelect={onSelect} {...actionProps}>
              {Icon && (
                <span className="action-menu-icon" aria-hidden>
                  <Icon />
                </span>
              )}
              {label}
            </MenuItem>
          ))}
        </MenuItems>
      </MenuPopover>
    </Menu>
  )
}

ActionMenu.propTypes = propTypes
ActionMenu.defaultProps = defaultProps

export default ActionMenu
