import React from 'react'

function ArtsIntegrationIcon() {
  return (
    <svg viewBox="0 0 50 50">
      <path
        fill="#204A82"
        className="fill"
        d="M50,25A25,25,0,1,1,25,0,25,25,0,0,1,50,25Z"
      />
      <path
        fill="#FFFFFF"
        d="M24.36,50,25,50l.83,0V44.37a11.82,11.82,0,0,1-1.47-.58Z"
      />
      <path
        fill="#FFFFFF"
        d="M34.21,48.24c.5-.2,1-.41,1.47-.64V43.68a12,12,0,0,1-1.47.61Z"
      />
      <path
        fill="#FFFFFF"
        d="M35.68,25.65V38h5.93c-.07.14-.16.28-.24.41a13.2,13.2,0,0,0,0-13.36c.12.2.23.4.34.6Z"
      />
      <path
        fill="#FFFFFF"
        d="M34.21,39.48H25.83v4.89h0a13.11,13.11,0,0,0,9.85-.69,12.48,12.48,0,0,1-1.46.61Z"
      />
      <path
        fill="#FFFFFF"
        d="M19.13,39.48a13.39,13.39,0,0,0,5.23,4.31V39.48Z"
      />
      <path fill="#FFFFFF" d="M35.68,43.68a13.32,13.32,0,0,0,5-4.2h-5Z" />
      <path fill="#FFFFFF" d="M40.81,24.18a13.27,13.27,0,0,0-5.13-4.38v4.38Z" />
      <path
        fill="#FFFFFF"
        d="M31.72,27.3c1.22,1.88,2.11,5.82,2.49,7.74V25.65H30.35A10.44,10.44,0,0,1,31.72,27.3Z"
      />
      <path
        fill="#FFFFFF"
        d="M25.83,32.64V38h8.38v-.69l-.83-.38C33.15,36.83,28.09,34.54,25.83,32.64Z"
      />
      <path
        fill="#FFFFFF"
        d="M28.58,24.18h5.63v-5a13.29,13.29,0,0,0-4.3-.73,13.11,13.11,0,0,0-4.08.65v3.47l.31.14A16.94,16.94,0,0,1,28.58,24.18Z"
      />
      <path
        fill="#FFFFFF"
        d="M22.19,25.65H18.12A13.23,13.23,0,0,0,18.2,38h6.16V31A13.1,13.1,0,0,1,22.25,26Z"
      />
      <path
        fill="#FFFFFF"
        d="M13.73,8.16l-4.9,4.4A93.61,93.61,0,0,0,23,25.57a1.51,1.51,0,0,0,1.56.08,1.47,1.47,0,0,0,.56-2A95.11,95.11,0,0,0,13.73,8.16Z"
      />
      <path
        fill="#FFFFFF"
        d="M14.83,7.17h0L16,8.51V1.69c-.5.19-1,.4-1.48.62V6.8h0Z"
      />
      <path
        fill="#FFFFFF"
        d="M18.76,24.19H16V21.82c-.51-.45-1-.91-1.48-1.36v3.73H6.12V11.84h.09l-.62-.69-.78-.88c-.37.51-.73,1-1.07,1.57h.91V24.19H0c0,.27,0,.54,0,.81s0,.45,0,.67H4.65V38h-1q.47.75,1,1.47h0v0c.46.64.95,1.27,1.47,1.87V39.49H14.5v8.2c.48.22,1,.43,1.48.62V39.49h3.16A12.54,12.54,0,0,1,18.21,38H16V25.67h2.13a13,13,0,0,1,.8-1.35l-.11-.09ZM14.5,38H6.12V25.67H14.5Z"
      />
      <path
        fill="#FFFFFF"
        d="M50,25.67c0-.22,0-.45,0-.67s0-.54,0-.81H45.54V11.84h.72a24.94,24.94,0,0,0-2.2-3v1.54H35.68v-8c-.48-.23-1-.44-1.47-.64v8.61H25.83V0L25,0l-.64,0V10.37H17.53l1.2,1.47h5.63v7.78l0,.06a12.67,12.67,0,0,1,1.44-.57V11.84h8.38v7.35a12,12,0,0,1,1.47.61v-8h8.38V24.19H40.82a12.27,12.27,0,0,1,.88,1.48h2.36V38H41.61a14.09,14.09,0,0,1-.94,1.47h3.39v1.67A24.49,24.49,0,0,0,46.35,38h-.81V25.67Z"
      />
      <path
        fill="#FFFFFF"
        d="M25.23,26.94a2.89,2.89,0,0,1-1.1.35,11.11,11.11,0,0,0,2.2,3.8,9.42,9.42,0,0,0,1.74,1.35,2.61,2.61,0,0,0,2.33-.26,2.23,2.23,0,0,0,1-2,9.88,9.88,0,0,0-1-2.1,12.12,12.12,0,0,0-3.77-3.4A2.93,2.93,0,0,1,25.23,26.94Z"
      />
      <path
        fill="#FFFFFF"
        d="M11,8.67l1.8-1.61L10.58,4.65l0,0A24.87,24.87,0,0,0,5.73,9.08l2.11,2.38Z"
      />
    </svg>
  )
}

export default ArtsIntegrationIcon
