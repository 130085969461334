import React from 'react'
import { Card, Content, Header } from 'components'

function NotFound() {
  return (
    <div className="page-not-found">
      <Header />
      <Content id="content">
        <div className="content-block-container">
          <Card>
            <h1>Page Not Found</h1>
            <p>Looks like the page you were looking for doesn't exist.</p>
            <a href="javascript:history.back()" className="button-alert">
              Go Back
            </a>
          </Card>
        </div>
      </Content>
    </div>
  )
}

export default NotFound
