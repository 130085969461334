import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { CheckboxGroup, Checkbox } from 'lp-components'
import { HiddenLegend, FilterMenuItem } from 'components'
import { isEmpty } from 'lodash'
import { Field } from 'redux-form'
import { formatOptions } from 'utils'

const propTypes = {
  filterOptions: Types.directoryFilterOptions.isRequired,
  applyFilter: PropTypes.func.isRequired,
}

const defaultProps = {}

function DirectoryFilterFields({
  filterOptions,
  applyFilter,
  ...filterMenuProps
}) {
  return (
    <React.Fragment>
      {!isEmpty(filterOptions.neighborhoods) && (
        <FilterMenuItem
          name="neighborhoods"
          displayName="Neighborhood"
          applyFilter={applyFilter}
          {...filterMenuProps}
          className="large-width full-height"
        >
          <Field
            name="neighborhoods"
            component={CheckboxGroup}
            labelComponent={HiddenLegend}
            options={formatOptions(filterOptions.neighborhoods)}
          />
        </FilterMenuItem>
      )}
      {!isEmpty(filterOptions.disciplines) && (
        <FilterMenuItem
          name="disciplineIds"
          displayName="Discipline"
          applyFilter={applyFilter}
          {...filterMenuProps}
          className="large-width full-height"
        >
          <Field
            name="disciplineIds"
            component={CheckboxGroup}
            labelComponent={HiddenLegend}
            options={formatOptions(filterOptions.disciplines)}
          />
        </FilterMenuItem>
      )}
      {!isEmpty(filterOptions.gradesServed) && (
        <FilterMenuItem
          name="gradeIds"
          displayName="Grade Level"
          applyFilter={applyFilter}
          {...filterMenuProps}
          className="large-width full-height"
        >
          <Field
            name="gradeIds"
            component={CheckboxGroup}
            labelComponent={HiddenLegend}
            options={formatOptions(filterOptions.gradesServed)}
          />
        </FilterMenuItem>
      )}
      <div className="bookmark-filter">
        <Field
          name={Types.BOOKMARK_FILTER_NAME}
          component={Checkbox}
          onChange={() => {
            setTimeout(applyFilter, 0)
          }}
        />
      </div>
    </React.Fragment>
  )
}

DirectoryFilterFields.propTypes = propTypes
DirectoryFilterFields.defaultProps = defaultProps

export default DirectoryFilterFields
