import React, { useEffect, useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import * as flashActions from 'redux-flash'
import { Spinner } from 'lp-components'
import * as apiActions from 'api-actions'
import * as routerActions from 'react-router-redux'
import { selectors } from '../reducer'
import { selectors as apiSelectors } from 'lp-redux-api'
import { selectors as globalSelectors } from 'educator-portal-reducer'
import { Modal } from 'components'
import {
  MembershipButton,
  NewPostButton,
  AnnouncementsList,
  ResourcesSidebar,
  ResourcesAttachmentModal,
} from '../components'
import NewPost from '../../views/new-post'
import NewAnnouncement from './new-announcement'
import { Discussions } from 'educator-portal-components'
import { DISCUSSIONS_PAGE_SIZE } from 'config'
import {
  parseSearchQueryObject,
  buildSearchQueryString,
  useCommunity,
  setDiscussionsSortType,
  getBookmarkedIdsByType,
  setCommunityOfPracticeId,
  hasLoadedAssociationForCommunityOfPractice,
  createCopPaths,
} from 'utils'
import { get, find, isEmpty, size } from 'lodash'

const propTypes = {
  communityOfPractice: Types.communityOfPractice.isRequired,
  posts: PropTypes.arrayOf(Types.post),
  announcements: PropTypes.arrayOf(Types.announcement),
  searchOptions: Types.postsFilterOptions,
  district: Types.district,
  fetchAnnouncements: PropTypes.func.isRequired,
  userDetails: Types.user.isRequired,
  joinCommunity: PropTypes.func.isRequired,
  leaveCommunity: PropTypes.func.isRequired,
  postsCount: PropTypes.number.isRequired,
  fetchPosts: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasLoadFailed: PropTypes.bool.isRequired,
  postsPageCount: PropTypes.number.isRequired,
  addBookmark: PropTypes.func.isRequired,
  removeBookmark: PropTypes.func.isRequired,
  addLike: PropTypes.func.isRequired,
  destroyLike: PropTypes.func.isRequired,
  bookmarks: PropTypes.arrayOf(Types.bookmark).isRequired,
  fetchSearchOptions: PropTypes.func.isRequired,
  fetchAttachableOptions: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  createOrUpdateAttachment: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  isModerator: PropTypes.bool.isRequired,
  deleteAnnouncement: PropTypes.func.isRequired,
  fetchCommunityOfPractice: PropTypes.func.isRequired,
  attachableOptions: PropTypes.arrayOf(Types.attachableOption),
  // provided by react router
  location: PropTypes.object.isRequired,
}

const defaultProps = {
  posts: null,
  announcements: null,
  searchOptions: null,
  district: null,
  attachableOptions: null,
}

function Workspace({
  communityOfPractice,
  fetchAnnouncements,
  announcements,
  userDetails,
  joinCommunity,
  leaveCommunity,
  posts,
  postsCount,
  fetchPosts,
  isLoading,
  hasLoadFailed,
  postsPageCount,
  addBookmark,
  removeBookmark,
  addLike,
  destroyLike,
  bookmarks,
  searchOptions,
  fetchSearchOptions,
  fetchAttachableOptions,
  district,
  flashSuccessMessage,
  flashErrorMessage,
  createOrUpdateAttachment,
  push,
  isModerator,
  deleteAnnouncement,
  attachableOptions,
  fetchCommunityOfPractice,
  location: { query: queryHash },
}) {
  const communityOfPracticeId = parseInt(communityOfPractice.id)
  const {
    allCopsPath,
    copWorkspacePath,
    copPostRootPath,
    copResourcesIndexPath,
  } = createCopPaths(communityOfPracticeId)

  const hasCorrectPosts = hasLoadedAssociationForCommunityOfPractice(
    posts,
    communityOfPracticeId
  )
  const hasCorrectAnnouncements = hasLoadedAssociationForCommunityOfPractice(
    announcements,
    communityOfPracticeId
  )

  const hasCorrectAttachableOptions =
    hasLoadedAssociationForCommunityOfPractice(
      attachableOptions,
      communityOfPracticeId
    )

  const {
    query = '',
    filters = {},
    page = 1,
    sortType = Types.LAST_ACTIVITY_SORT_TYPE,
  } = useMemo(() => parseSearchQueryObject(queryHash), [queryHash])

  const searchPosts = useCallback(
    (params, { updateUrl = true } = {}) => {
      setShowSpinner(updateUrl || !hasCorrectPosts) // show spinner if new search or first load
      const paramsWithSort = setDiscussionsSortType(params)
      if (updateUrl) {
        push({
          pathname: copWorkspacePath,
          search: buildSearchQueryString(paramsWithSort),
        })
      }
      // always pass in CoP ID filter
      const paramsWithCoPId = setCommunityOfPracticeId(
        paramsWithSort,
        communityOfPracticeId
      )
      return fetchPosts(
        paramsWithCoPId,
        getBookmarkedIdsByType(bookmarks, Types.BOOKMARKABLE.POST)
      ).then(() => setShowSpinner(false))
    },
    [fetchPosts, hasCorrectPosts, bookmarks]
  )

  const community = useCommunity()
  const blockList = useMemo(
    () => get(community, 'search.forumFilters.blockList', []),
    [community]
  )

  useEffect(() => {
    searchPosts({ query, filters, page, sortType }, { updateUrl: false })
  }, [])

  useEffect(() => {
    fetchAnnouncements(communityOfPracticeId)
  }, [communityOfPracticeId])

  useEffect(() => {
    fetchAttachableOptions(communityOfPracticeId)
  }, [communityOfPracticeId])

  useEffect(() => {
    if (!searchOptions && district)
      fetchSearchOptions(
        { blockList },
        Types.SEARCH_OPTIONS_TYPE.FORUM,
        district.id
      )
  }, [searchOptions, blockList, district])

  const [isPostFormOpen, setIsPostFormOpen] = useState(false)
  const [isAnnouncementFormOpen, setIsAnnouncementFormOpen] = useState(false)
  const [showSpinner, setShowSpinner] = useState(!hasCorrectPosts)
  const [announcementToEdit, setAnnouncementToEdit] = useState(null)
  const [showAttachmentModal, setShowAttachmentModal] = useState(false)

  const closeAnnouncementForm = () => setIsAnnouncementFormOpen(false)
  const openAttachmentModal = () => setShowAttachmentModal(true)

  const postsCountText = useMemo(() => {
    if (isLoading && showSpinner) return '-'
    if (postsCount === 1 || postsCount <= DISCUSSIONS_PAGE_SIZE)
      return postsCount
    return `${size(posts)} of ${postsCount}`
  }, [posts, postsCount, isLoading, showSpinner])

  const membership = find(userDetails.memberships, { communityOfPracticeId })
  const membershipId = get(membership, 'id')
  const isMember = !!membership
  const canPost = isMember || isModerator
  const hasAppliedFilters = !isEmpty(query) || !isEmpty(filters)
  const displayAnnouncements = isModerator || !isEmpty(announcements)

  const requestJoinCommunity = () => {
    joinCommunity({ communityOfPracticeId })
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }

  const requestLeaveCommunity = () => {
    leaveCommunity({ membershipId })
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }

  const requestAddBookmark = (postId) => {
    addBookmark({
      bookmarkableId: postId,
      bookmarkableType: Types.BOOKMARKABLE.POST,
    })
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }

  const requestRemoveBookmark = (bookmarkId) => {
    removeBookmark({ bookmarkId, bookmarkableType: Types.BOOKMARKABLE.POST })
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }

  const requestAddLike = useCallback((likeableId) => {
    addLike({
      likeableId,
      likeableType: Types.LIKEABLE.POST,
    }).catch(({ errorMessage }) => {
      flashErrorMessage(errorMessage)
    })
  }, [])

  const requestDestroyLike = useCallback((id) => {
    destroyLike({ id }).catch(({ errorMessage }) => {
      flashErrorMessage(errorMessage)
    })
  }, [])

  const requestDeleteAnnouncement = (announcementId) => {
    deleteAnnouncement({ communityOfPracticeId, announcementId })
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }

  const setAnnouncementAndOpenForm = (announcement) => {
    setAnnouncementToEdit(announcement)
    setIsAnnouncementFormOpen(true)
  }

  if (
    !announcements ||
    !hasCorrectAnnouncements ||
    !hasCorrectAttachableOptions
  )
    return <Spinner />

  return (
    <div>
      <Link className="link-secondary no-underline" to={allCopsPath}>
        ← <span className="underline">Return to Communities of Practice</span>
      </Link>
      <div className="flex-horizontal flex-space-between cop-header">
        <h2>
          <strong>{communityOfPractice.name}</strong>
        </h2>
        <MembershipButton
          isMember={isMember}
          join={requestJoinCommunity}
          leave={requestLeaveCommunity}
        />
      </div>
      <div className="flex-horizontal cop-main">
        <div className="cop-left">
          {displayAnnouncements && (
            <AnnouncementsList
              isModerator={isModerator}
              openAnnouncementForm={() => setAnnouncementAndOpenForm(null)}
              announcements={announcements}
              requestDeleteAnnouncement={requestDeleteAnnouncement}
              editOnSelect={setAnnouncementAndOpenForm}
            />
          )}
          <div id="community-of-practice-discussions" className="text-center">
            <div className="welcome-message">
              <h3>Discussions</h3>
              <p>{communityOfPractice.description}</p>
            </div>
            <NewPostButton
              canPost={canPost}
              join={requestJoinCommunity}
              openPostForm={() => setIsPostFormOpen(true)}
            />
          </div>
          {!searchOptions ? (
            <Spinner />
          ) : (
            <Discussions
              posts={posts}
              numPages={postsPageCount}
              resultsPage={page}
              searchOptions={searchOptions}
              query={query}
              filters={filters}
              searchPosts={searchPosts}
              showSpinner={showSpinner}
              postsCount={postsCount}
              postsCountText={postsCountText}
              sortType={sortType}
              openPostForm={() => setIsPostFormOpen(true)}
              hasLoadFailed={hasLoadFailed}
              isFiltered={hasAppliedFilters}
              bookmarks={bookmarks}
              addBookmark={requestAddBookmark}
              removeBookmark={requestRemoveBookmark}
              addLike={requestAddLike}
              destroyLike={requestDestroyLike}
              postRootPath={copPostRootPath}
              canPost={canPost}
              userDetails={userDetails}
            />
          )}
        </div>
        <div className="cop-right resources">
          <ResourcesSidebar
            attachments={communityOfPractice.attachments}
            attachmentsCount={communityOfPractice.attachmentsCount}
            canUploadAttachments={isModerator}
            copResourcesIndexPath={copResourcesIndexPath}
            onSelectUploadAction={openAttachmentModal}
          />
        </div>
      </div>
      {isPostFormOpen && (
        <Modal onClose={() => setIsPostFormOpen(false)}>
          <NewPost
            onSubmitSuccess={({ id: postId }) => {
              push(`${copPostRootPath}/${postId}`)
              flashSuccessMessage('New discussion created!')
            }}
            onCancel={() => setIsPostFormOpen(false)}
            communityOfPracticeId={communityOfPracticeId}
          />
        </Modal>
      )}
      {isAnnouncementFormOpen && (
        <Modal onClose={closeAnnouncementForm}>
          <NewAnnouncement
            onSubmitSuccess={() => {
              closeAnnouncementForm()
              flashSuccessMessage('New announcement created!')
            }}
            onCancel={closeAnnouncementForm}
            communityOfPracticeId={communityOfPracticeId}
            announcementToEdit={announcementToEdit}
          />
        </Modal>
      )}
      {showAttachmentModal && (
        <ResourcesAttachmentModal
          onClose={() => setShowAttachmentModal(false)}
          createOrUpdateAttachment={createOrUpdateAttachment}
          onSubmitSuccess={() => {
            setShowAttachmentModal(false)
            flashSuccessMessage('New resource created!')
            fetchCommunityOfPractice(communityOfPracticeId)
          }}
          flashSuccessMessage={flashSuccessMessage}
          flashErrorMessage={flashErrorMessage}
          attachableOptions={attachableOptions}
        />
      )}
    </div>
  )
}

Workspace.propTypes = propTypes
Workspace.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    isLoading: apiSelectors.isLoading(state, apiActions.fetchPosts),
    hasLoadFailed: apiSelectors.isFailure(state, apiActions.fetchPosts),
    userDetails: globalSelectors.userDetails(state),
    posts: globalSelectors.posts(state),
    postsCount: globalSelectors.postsCount(state),
    postsPageCount: globalSelectors.postsPageCount(state),
    bookmarks: globalSelectors.bookmarks(state),
    searchOptions: globalSelectors.searchOptions(state),
    district: globalSelectors.district(state),
    isModerator: globalSelectors.isModerator(state),
    communityOfPractice: selectors.communityOfPractice(state),
    announcements: selectors.announcements(state),
    attachableOptions: selectors.attachableOptions(state),
  }
}

const mapDispatchToProps = {
  fetchAnnouncements: apiActions.fetchAnnouncements,
  joinCommunity: apiActions.createMembership,
  leaveCommunity: apiActions.deleteMembership,
  fetchPosts: apiActions.fetchPosts,
  deleteAnnouncement: apiActions.deleteAnnouncement,
  addBookmark: apiActions.addBookmark,
  addLike: apiActions.addLike,
  destroyLike: apiActions.destroyLike,
  removeBookmark: apiActions.removeBookmark,
  fetchSearchOptions: apiActions.fetchSearchOptions,
  push: routerActions.push,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  flashErrorMessage: flashActions.flashErrorMessage,
  createOrUpdateAttachment: apiActions.createOrUpdateAttachment,
  fetchAttachableOptions: apiActions.fetchAttachableOptions,
  fetchCommunityOfPractice: apiActions.fetchCommunityOfPractice,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Workspace)
