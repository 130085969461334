import { isString } from 'lodash/fp'
import { compact } from 'lodash'

function toAddressString(address) {
  if (isString(address)) return address
  const { street, city, state, zip } = address
  const stateAndZip = compact([state, zip]).join(' ')
  return compact([
    street,
    city,
    stateAndZip,
  ]).join(', ')
}

export default toAddressString
