import React from 'react'
import { Route, IndexRoute } from 'react-router'
import * as Views from './views'
import Layout from './layout'

const Routes = (
  <Route component={Layout} path="profile">
    <IndexRoute component={Views.Profile} />
  </Route>
)

export default Routes
