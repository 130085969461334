import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'

function mapStateToProps(state, { form }) {
  return {
    values: getFormValues(form)(state),
  }
}

function withFormValues() {
  return compose(connect(mapStateToProps))
}

export default withFormValues
