import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { waitFor, modifyProps, onMount } from 'lp-hoc'
import { OrganizationForm } from '../forms'
import * as apiActions from 'api-actions'
import * as Types from 'types'
import { selectors as globalPartnerSelectors } from 'partner-portal-reducer'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors } from '../reducer'
import * as globalActions from 'partner-portal-actions'
import * as flashActions from 'redux-flash'
import { displaySubmitFailure } from 'utils'

const propTypes = {
  partner: Types.partner.isRequired,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  leadershipCharacteristics: PropTypes.arrayOf(Types.communityEnumerable)
    .isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  partnerLocation: Types.location.isRequired,
  setPartner: PropTypes.func.isRequired,
  updatePartner: PropTypes.func.isRequired,
}

const defaultProps = {}

function Organization({
  flashSuccessMessage,
  flashErrorMessage,
  partnerLocation,
  updatePartner,
  setPartner,
  partner,
  disciplines,
  leadershipCharacteristics,
}) {
  return (
    <OrganizationForm
      initialValues={partner}
      onSubmit={updatePartner}
      onSubmitSuccess={(partner) => {
        setPartner(partner)
        flashSuccessMessage('Organization information updated!')
      }}
      onSubmitFail={displaySubmitFailure}
      partnerLocation={partnerLocation}
      disciplines={disciplines}
      leadershipCharacteristics={leadershipCharacteristics}
      flashErrorMessage={flashErrorMessage}
      flashSuccessMessage={flashSuccessMessage}
      partnerId={partner.id}
    />
  )
}

Organization.propTypes = propTypes
Organization.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    partner: globalPartnerSelectors.partner(state),
    disciplines: globalSelectors.disciplines(state),
    leadershipCharacteristics: selectors.leadershipCharacteristics(state),
    partnerLocation: globalPartnerSelectors.currentPartnerLocation(state),
    currentSchoolYear: globalPartnerSelectors.currentSchoolYear(state),
  }
}

const mapDispatchToProps = {
  fetchLeadershipCharacteristics: apiActions.fetchLeadershipCharacteristics,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  flashErrorMessage: flashActions.flashErrorMessage,
  setPartner: globalActions.setPartner,
  updatePartner: apiActions.updatePartner,
}

function modify({ partner, currentSchoolYear, updatePartner }) {
  return {
    updatePartner: (params) =>
      updatePartner(partner.id, currentSchoolYear, params),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  onMount('fetchLeadershipCharacteristics'),
  waitFor(['partner', 'leadershipCharacteristics']),
  modifyProps(modify)
)(Organization)
