import React from 'react'
// import PropTypes from 'prop-types'
import { Content } from 'components'

const propTypes = {}

const defaultProps = {}

function SearchLoadingIndicator() {
  return <Content> Loading search results... </Content>
}

SearchLoadingIndicator.propTypes = propTypes

SearchLoadingIndicator.defaultProps = defaultProps

export default SearchLoadingIndicator
