import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import Tippy from '@tippyjs/react'
import classnames from 'classnames'

const propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  darkMode: PropTypes.bool,
  iconClassName: PropTypes.string,
}

const defaultProps = {
  icon: '?',
  darkMode: false,
  iconClassName: '',
}

function Tooltip({ children, darkMode, icon, iconClassName, ...rest }) {
  return (
    <Tippy content={children} {...rest}>
      <i
        tabIndex="0"
        className={classnames(
          'tooltip-icon',
          { 'tooltip-dark': darkMode },
          iconClassName
        )}
      >
        {icon && icon}
      </i>
    </Tippy>
  )
}

Tooltip.propTypes = exact(propTypes)
Tooltip.defaultProps = defaultProps

export default pure(Tooltip)
