import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import * as Types from 'types'
import { TabBar } from 'lp-components'

const propTypes = {
  schoolYears: PropTypes.arrayOf(Types.schoolYear).isRequired,
  schoolYear: Types.schoolYear.isRequired,
  onChange: PropTypes.func,
}

const defaultProps = {
  value: null,
  onChange: noop,
}

function SchoolYearTabBar({ schoolYears, schoolYear, onChange }) {
  const serializedOptions = useMemo(() => {
    return schoolYears.map(({ id, number }) => ({ key: number, value: id }))
  }, [schoolYears])

  const selectYear = useCallback(
    (selectedId) => {
      return onChange(schoolYears.find(({ id }) => id === selectedId))
    },
    [schoolYears]
  )

  return (
    <TabBar
      className="year-block"
      options={serializedOptions}
      value={schoolYear.id}
      onChange={selectYear}
      activeClassName="is-active"
    />
  )
}

SchoolYearTabBar.propTypes = propTypes
SchoolYearTabBar.defaultProps = defaultProps

export default SchoolYearTabBar
