import { handleActions } from 'redux-actions'
import { setOnSuccess } from 'lp-redux-api'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
import * as apiActions from 'api-actions'
import * as actions from './actions'

const reducerKey = 'summary'
const slice = 'root.schoolPortal.summary'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchPartnershipQuestion]: setOnSuccess('partnershipQuestion'),
    [actions.clearPartnershipQuestion]: unsetState('partnershipQuestion'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  partnershipQuestion: select('partnershipQuestion'),
}

export { reducer, selectors, reducerKey }
