import * as Types from 'types'

// Returns middle for middle school; elementary for elementary and combo
function getKThroughEightGradeOffered(category) {
  return category === Types.MIDDLE_SCHOOL_TYPE
    ? Types.MIDDLE_SCHOOL_TYPE
    : Types.ELEMENTARY_SCHOOL_TYPE
}

export default getKThroughEightGradeOffered
