import React from 'react'
// import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { SurveyStepNav } from '../components'

const propTypes = {}

function CompleteConfirmation() {
  return (
    <section>
      <SurveyStepNav currentStepIndex={4} />
      <section className="content-block-container">
        <div className="card survey-complete">
          <h3 className="checkmark-heading">Survey Complete!</h3>
          <p>
            Thank you for completing this year's survey! Your responses help us
            paint a more comprehensive picture of arts education and can help us
            connect public schools such as yourselves with quality arts
            programs.
          </p>
        </div>
      </section>
    </section>
  )
}

CompleteConfirmation.propTypes = propTypes

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CompleteConfirmation
)
