import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'components'
import CalculationForm from '../forms/calculation-form'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
}

const defaultProps = {}

const initialValues = {
  lengthOfPeriod: '0',
  durationOption: 'full_year',
  frequencyOption: 'daily',
  meetingType: 1,
  rotationType: 2,
  result: 0,
}

function MinutesPerWeekCalculatorModal({
  onClose,
  onSubmit,
  isOpen,
  onSubmitSuccess,
  ...rest
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} {...rest}>
      <h2>Average Weekly Minutes of Instruction Calculator</h2>
      <div className="modal-content">
        <p id="modal-description">
          The average weekly minutes for K-8 classes are calculated across the
          entire school year. You can use this tool to help estimate the average
          weekly minutes of instruction when a class met for only part of the
          year or periodically in a rotation.
        </p>
        <p>
          Please select the answers below that best fit the class schedule to
          estimate the average weekly minutes of instruction for the year.
        </p>

        <CalculationForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          onSubmitSuccess={onSubmitSuccess}
        />
      </div>
    </Modal>
  )
}

MinutesPerWeekCalculatorModal.propTypes = propTypes
MinutesPerWeekCalculatorModal.defaultProps = defaultProps

export default MinutesPerWeekCalculatorModal
