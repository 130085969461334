import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import * as Views from './views'

const Routes = (
  <Route path="styleguide">
    <IndexRedirect to="all" />
    <Route path="all" component={Views.All} />
    <Route path="sandbox" component={Views.Sandbox} />
  </Route>
)

export default Routes
