import React from 'react'
import { IndexRoute, Route } from 'react-router'
import * as Views from './views'
import Layout from './layout'

const Routes = (
  <Route path="/">
    <IndexRoute component={Views.Main} />
    <Route component={Layout}>
      <Route path="faq" component={Views.Faq} />
      <Route path="contact" component={Views.Contact} />
    </Route>
  </Route>
)

export default Routes
