import useCommunityText from './use-community-text'
import pluralize from './pluralize'
import interpolateText from './interpolate-text'
import { DEFAULT_CLASS_MONIKER } from 'config'
import { startCase } from 'lodash'

// Find and replace class(es) with the community specific class moniker (ex. course(s)) and match case
function useClassMoniker() {
  const t = useCommunityText()
  const classMoniker = t('survey.courses.classMoniker') || DEFAULT_CLASS_MONIKER
  const classesMoniker = pluralize(classMoniker, 2)

  return function classText(text) {
    // must append with 'moniker' because 'class' is reserved
    return interpolateText(text, {
      classMoniker,
      classesMoniker,
      ClassMoniker: startCase(classMoniker),
      ClassesMoniker: startCase(classesMoniker),
    })
  }
}

export default useClassMoniker
