import React from 'react'
import exact from 'prop-types-exact'
import { pure } from 'recompose'

const propTypes = {}

const defaultProps = {}

function EmptyState() {
  return (
    <div>
      <p>
        There are currently no items flagged for your review. Thank you for
        moderating our platform!
      </p>
    </div>
  )
}

EmptyState.propTypes = exact(propTypes)
EmptyState.defaultProps = defaultProps

export default pure(EmptyState)
