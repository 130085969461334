import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ActionMenu } from 'components'
import { ViewIcon, TrashIcon } from 'components/icons/basic'
import {
  ProfileOverview,
  PostUserContent,
  PostedDate,
} from 'educator-portal-components'
import { getUserDisplayName } from 'utils'
import { EDUCATOR_PORTAL_ROUTE } from 'config'

const propTypes = {
  flaggedItem: Types.comment.isRequired,
  requestAgreeWithFlag: PropTypes.func.isRequired,
  markAppropriateAction: Types.menuAction.isRequired,
  navigateTo: PropTypes.func.isRequired,
  showProfileDetails: PropTypes.func.isRequired,
}

const defaultProps = {}

function FlaggedComment({
  flaggedItem: comment,
  requestAgreeWithFlag,
  markAppropriateAction,
  navigateTo,
  showProfileDetails,
}) {
  const {
    id,
    content,
    isEdited,
    createdAt,
    user,
    postId,
    commentableType,
    commentableId,
  } = comment
  const { id: userId, name, schoolNames, profileImgUrl, pronouns, email } = user
  const userDisplayName = getUserDisplayName({ name }, email)

  const postPath = `${EDUCATOR_PORTAL_ROUTE}/forum/discussions/${postId}`
  // we cannot go directly to a threaded comment, so go to parent comment when applicable
  const commentId =
    commentableType === Types.COMMENTABLE.COMMENT ? commentableId : id
  const commentPath = `${postPath}#${Types.COMMENT_CONTAINER_ID_PREFIX}${commentId}`

  const viewCommentAction = {
    icon: ViewIcon,
    label: 'View Comment',
    onSelect: () => navigateTo(commentPath),
  }
  const archiveCommentAction = {
    icon: TrashIcon,
    label: 'Remove Comment',
    onSelect: requestAgreeWithFlag,
  }

  const actions = [
    viewCommentAction,
    archiveCommentAction,
    markAppropriateAction,
  ]

  return (
    <React.Fragment>
      <div>
        <div className="text-block no-border">
          <ProfileOverview
            userDisplayName={userDisplayName}
            profileImgUrl={profileImgUrl}
            schoolNames={schoolNames}
            onProfileClick={() => showProfileDetails(userId)}
            pronouns={pronouns}
          />
          <div className="flex-horizontal forum-action-menu">
            <PostedDate date={createdAt} />
            <ActionMenu actions={actions} />
          </div>
        </div>
      </div>
      <div>
        <PostUserContent content={content} isEdited={isEdited} isComment />
      </div>
    </React.Fragment>
  )
}

FlaggedComment.propTypes = exact(propTypes)
FlaggedComment.defaultProps = defaultProps

export default pure(FlaggedComment)
