import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { AnnouncementForm } from '../forms'
import { getFormWideErrorMessage } from 'utils'
import { HiddenSpan } from 'components'

const propTypes = {
  createOrUpdateAnnouncement: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  communityOfPracticeId: PropTypes.number.isRequired,
  announcementToEdit: Types.announcement,
}

const defaultProps = {
  announcementToEdit: null,
}

function NewAnnouncement({
  createOrUpdateAnnouncement,
  onCancel,
  announcementToEdit,
  onSubmitSuccess,
  communityOfPracticeId,
}) {
  const [formWideError, setFormWideError] = useState(null)
  const [submitFailCount, setSubmitFailCount] = useState(0)

  const isEditing = !!announcementToEdit

  return (
    <div>
      <h2>{isEditing ? 'Edit Announcement' : 'Add Announcement'}</h2>
      <p>
        This announcement will be visible to all members of this Community of
        Practice.
      </p>
      <div role="alert" aria-atomic>
        {formWideError && (
          <p className="full-error-message">
            {formWideError} <HiddenSpan>{submitFailCount}</HiddenSpan>
          </p>
        )}
      </div>
      <AnnouncementForm
        initialValues={announcementToEdit}
        isEditing={isEditing}
        onCancel={onCancel}
        onSubmit={(announcement) =>
          createOrUpdateAnnouncement(
            announcement,
            announcement.id,
            communityOfPracticeId
          )
        }
        onSubmitSuccess={onSubmitSuccess}
        onSubmitFail={(errors, _, submitError) => {
          const msg = getFormWideErrorMessage(errors, submitError)
          setFormWideError(msg)
          setSubmitFailCount((sfc) => sfc + 1)
        }}
      />
    </div>
  )
}

NewAnnouncement.propTypes = propTypes
NewAnnouncement.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  createOrUpdateAnnouncement: apiActions.createOrUpdateAnnouncement,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NewAnnouncement
)
