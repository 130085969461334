import { sortBy, snakeCase } from 'lodash'
import { formatOptions } from 'utils'

const sortedAndFormattedOptions = (options, groupName) => {
  const filteredOptions = options.filter(
    (option) => option.key !== snakeCase(groupName)
  )
  const sortedOptions = sortBy(filteredOptions, 'displayKey')
  return formatOptions(sortedOptions)
}

export default sortedAndFormattedOptions
