import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { onError } from 'lp-hoc'
import { logException } from 'sentry'
import {
  useCommunity,
  useDocumentTitle,
  useCommunityText,
  isMapEnabled,
  // initializeAnonymousUserflow,
} from 'utils'

const propTypes = {
  children: PropTypes.node,
}

const defaultProps = {}

function Layout({ children, ...rest }) {
  const community = useCommunity()
  const { mapRedirectPath } = community
  const t = useCommunityText()

  // Block Community users from map if disabled
  if (!isMapEnabled(community) && mapRedirectPath) {
    location.pathname = mapRedirectPath
    return null
  }

  useDocumentTitle(t('map.title') || 'artlook Map: Increasing Arts Education')

  // Temporarily disable userflow on public facing sites
  // initializeAnonymousUserflow()

  return <div {...rest}>{children}</div>
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

function onComponentDidCatch(_, error, errorInfo) {
  return logException(error, errorInfo)
}

export default compose(onError(onComponentDidCatch))(Layout)
