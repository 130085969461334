import React from 'react'

function TheatreIcon() {
  return (
    <svg viewBox="0 0 67.75 67.75">
      <circle fill="#204A82" cx="33.88" cy="33.88" r="33.88" />
      <path
        fill="#FFFFFF"
        d="M60.18,36.56H34.76s-2.27,29.7,12.58,29.7S60.18,36.56,60.18,36.56Zm-21,10S40.35,44,42.34,44s3.12,2.62,3.12,2.62Zm8.24,14c-3.64,0-5.69-4.48-5.69-4.48a20.72,20.72,0,0,0,5.75,1.1,19.58,19.58,0,0,0,5.63-1.09S51.11,60.54,47.47,60.54Zm1.78-14S50.38,44,52.37,44s3.11,2.62,3.11,2.62Z"
        transform="translate(-22.69 -21.58)"
      />
      <path
        fill="#FFFFFF"
        d="M79.84,50.32H61.54a39.77,39.77,0,0,1-2,7.92l5.53,0S64,60.86,62,60.86A3.53,3.53,0,0,1,59.24,59a18.36,18.36,0,0,1-3.41,5.58c-.22.24-.45.45-.68.66C56.45,72.62,59.6,80,67,80,81.86,80,79.84,50.32,79.84,50.32ZM67,71.76a19.58,19.58,0,0,0-5.63,1.09s2.05-4.48,5.69-4.48,5.69,4.48,5.69,4.48A20.72,20.72,0,0,0,67,71.76Zm5-10.9c-2,0-3.12-2.62-3.12-2.62l6.23,0S74,60.86,72,60.86Z"
        transform="translate(-22.69 -21.58)"
      />
    </svg>
  )
}

export default TheatreIcon
