import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { replace } from 'react-router-redux'
import * as apiActions from 'api-actions'
import { selectors as globalSelectors } from 'school-portal-reducer'
import { selectors } from './reducer'
import { Spinner } from 'lp-components'
import { PortalHeader } from 'components'
import { useCommunity } from 'utils'
import { SCHOOL_PORTAL_ROUTE } from 'config'
import * as Types from 'types'
import classnames from 'classnames'

const propTypes = {
  children: PropTypes.node,
  disableSurvey: PropTypes.bool.isRequired,
  surveyAttempt: Types.surveyAttempt,
  isSurveyClosed: PropTypes.bool,
  isSurveyAttemptComplete: PropTypes.bool,
  redirect: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  surveySchoolYear: Types.schoolYear,
  school: Types.school,
  fetchApproaches: PropTypes.func.isRequired,
  fetchIntegrationTypes: PropTypes.func.isRequired,
  fetchGovernanceTypes: PropTypes.func.isRequired,
  approaches: PropTypes.arrayOf(Types.communityEnumerable),
  integrationTypes: PropTypes.arrayOf(Types.integrationType),
  governanceTypes: PropTypes.arrayOf(Types.communityEnumerable),
}

const defaultProps = {
  children: null,
  surveySchoolYear: null,
  school: null,
  surveyAttempt: null,
  isSurveyClosed: false,
  isSurveyAttemptComplete: false,
}

function Layout({
  children,
  disableSurvey,
  surveyAttempt,
  isSurveyAttemptComplete,
  isSurveyClosed,
  redirect,
  location,
  surveySchoolYear,
  school,
  fetchApproaches,
  fetchIntegrationTypes,
  fetchGovernanceTypes,
  approaches,
  integrationTypes,
  governanceTypes,
}) {
  useEffect(() => {
    if (surveyAttempt) {
      if (isSurveyClosed) {
        redirect('unavailable')
      } else if (isSurveyAttemptComplete) {
        redirect('complete-confirmation')
      }
    }
  }, [surveyAttempt, location.pathname])

  useEffect(() => {
    fetchApproaches()
    fetchIntegrationTypes()
    fetchGovernanceTypes()
  }, [])

  const {
    defaultBranding: { dashboardHeader },
  } = useCommunity()
  const hasLoaded = !!(
    school &&
    surveyAttempt &&
    surveySchoolYear &&
    approaches &&
    integrationTypes &&
    governanceTypes
  )

  if (!hasLoaded) return <Spinner />

  const hasBottomCTABar = !(
    isSurveyClosed ||
    isSurveyAttemptComplete ||
    location.pathname.includes('get-started')
  )

  return (
    <div className={classnames('survey', { 'with-cta-bar': hasBottomCTABar })}>
      <PortalHeader
        title={`${surveySchoolYear.number} artlook Survey`}
        backgroundImage={dashboardHeader}
      />
      <fieldset disabled={disableSurvey}>{children}</fieldset>
    </div>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    disableSurvey: globalSelectors.disableSurvey(state),
    surveyAttempt: selectors.surveyAttempt(state),
    isSurveyClosed: !selectors.isSurveyOpen(state),
    isSurveyAttemptComplete: selectors.isSurveyAttemptComplete(state),
    approaches: selectors.approaches(state),
    integrationTypes: selectors.integrationTypes(state),
    governanceTypes: selectors.governanceTypes(state),
    surveySchoolYear: globalSelectors.surveySchoolYear(state),
    school: globalSelectors.school(state),
  }
}

const mapDispatchToProps = {
  redirect: (path) => replace(`${SCHOOL_PORTAL_ROUTE}/survey/${path}`),
  fetchIntegrationTypes: apiActions.fetchIntegrationTypes,
  fetchApproaches: apiActions.fetchApproaches,
  fetchGovernanceTypes: apiActions.fetchGovernanceTypes,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
