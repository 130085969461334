import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as flashActions from 'redux-flash'
import { onError } from 'lp-hoc'
import { logException } from 'sentry'
import {
  Content,
  FlashMessageContainer,
  ExternalLink,
  AccountMenu,
  Modal,
  RenderedHtml,
  PortalSelector,
  FooterLegalese,
  NotificationsMenu,
} from 'components'
import * as apiActions from 'api-actions'
import { replace } from 'react-router-redux'
import { selectors as globalSelectors } from 'educator-portal-reducer'
import {
  updateDocumentTitle,
  useCommunity,
  useCommunityText,
  getCurrentEmployment,
  isSchoolPortalEnabled,
  displaySubmitFailure,
  distinctEmploymentPositions,
  initializeAuthenticatedUserflow,
} from 'utils'
import PortalPrimaryNav from './portal-primary-nav'
import { Spinner } from 'lp-components'
import { EDUCATOR_PORTAL_ROUTE, SCHOOL_PORTAL_ROUTE } from 'config'
import { kebabCase } from 'lodash'
import { NotificationsPreferencesForm } from 'forms'
import classnames from 'classnames'
import menuIcon from 'images/menu.svg'

const propTypes = {
  currentSchoolId: PropTypes.number.isRequired,
  children: PropTypes.node,
  district: Types.district,
  fetchSchool: PropTypes.func.isRequired,
  fetchUserDetails: PropTypes.func.isRequired,
  flashMessages: PropTypes.arrayOf(flashActions.flashMessageType),
  isAdmin: PropTypes.bool,
  redirectToTerms: PropTypes.func.isRequired,
  userDetails: Types.user,
  redirectToSignOut: PropTypes.func.isRequired,
  redirectToSchoolPortal: PropTypes.func.isRequired,
  isModerator: PropTypes.bool,
  fetchNotifications: PropTypes.func.isRequired,
  markAllAsRead: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(Types.notification),
  fetchNotificationPreferences: PropTypes.func.isRequired,
  updateNotificationPreferences: PropTypes.func.isRequired,
  notificationPreferences: Types.notificationPreferences,
}

const defaultProps = {
  children: null,
  flashMessages: [],
  isAdmin: false,
  district: null,
  userDetails: null,
  isModerator: false,
}

function LayoutAuthorized({
  children,
  currentSchoolId,
  district,
  flashMessages,
  isAdmin,
  fetchSchool,
  fetchUserDetails,
  userDetails,
  redirectToTerms,
  redirectToSignOut,
  redirectToSchoolPortal,
  isModerator,
  fetchNotifications,
  markAllAsRead,
  notifications,
  fetchNotificationPreferences,
  notificationPreferences,
  updateNotificationPreferences,
}) {
  const community = useCommunity()
  const t = useCommunityText()
  const terms = t('terms')
  const [showTermsModal, setShowTermsModal] = useState(false)
  const [showPreferencesModal, setShowPreferencesModal] = useState(false)
  const [holdForRedirect, setHoldForRedirect] = useState(!!terms)
  const showNotificationsMenu = community.name === 'Chicago'

  useEffect(() => {
    fetchSchool(currentSchoolId)
  }, [currentSchoolId])

  useEffect(() => {
    fetchNotifications()
  }, [])

  useEffect(() => {
    if (userDetails?.notificationSubscriptionId) {
      fetchNotificationPreferences(userDetails.notificationSubscriptionId)
    }
  }, [userDetails?.notificationSubscriptionId])

  useEffect(() => {
    if (!userDetails) fetchUserDetails()
  }, [])

  useEffect(() => {
    if (terms && userDetails) {
      if (!userDetails.agreedAt) {
        redirectToTerms()
      } else {
        setHoldForRedirect(false)
      }
    }
  }, [userDetails, terms])

  const currentEmployment = useMemo(() => {
    if (!userDetails) return
    return getCurrentEmployment({
      employments: userDetails.employments,
      currentEmployableId: currentSchoolId,
      currentEmployableType: Types.EMPLOYABLE_TYPES.SCHOOL,
    })
  }, [userDetails, currentSchoolId])

  const employmentPositions = userDetails
    ? distinctEmploymentPositions(userDetails.employments)
    : []

  const hasLoaded = !!userDetails

  // if a current employment wasn't found, the user is not correctly signed in
  if (hasLoaded && !currentEmployment) redirectToSignOut()

  const schoolPortalEnabled = useMemo(
    () => isSchoolPortalEnabled(community),
    [community]
  )
  // If the current employment is a principal redirect them to the School Portal as they don't have access to aE
  if (
    schoolPortalEnabled &&
    currentEmployment &&
    currentEmployment.position === Types.POSITION_TYPES.PRINCIPAL
  )
    redirectToSchoolPortal()

  initializeAuthenticatedUserflow(userDetails)

  const [navVisibleOnMobile, setNavVisibleOnMobile] = useState(false)
  const closeMobileNav = () => {
    setNavVisibleOnMobile(false)
    return true
  }

  return (
    <div className={kebabCase(Types.PORTAL.EDUCATOR_PORTAL)}>
      <a href="#content" id="skip-nav-link">
        Skip to Main Content
      </a>
      <button
        type="button"
        className="nav-toggle"
        aria-expanded={navVisibleOnMobile}
        aria-controls="side-navigation-content"
        onClick={() => setNavVisibleOnMobile(true)}
        aria-label="Open Navigation"
      >
        <img src={menuIcon} alt="" />
      </button>
      <div
        className={classnames('nav-container', {
          'is-active': navVisibleOnMobile,
        })}
      >
        <button
          type="button"
          className="close"
          aria-label="Close Navigation"
          onClick={closeMobileNav}
        >
          ×
        </button>
        <PortalSelector
          currentPortal={Types.PORTAL.EDUCATOR_PORTAL}
          currentEmployment={currentEmployment}
        />
        <PortalPrimaryNav
          community={community}
          district={district}
          isModerator={isModerator}
          closeMobileNav={closeMobileNav}
        />
      </div>
      {!hasLoaded || holdForRedirect ? (
        <Spinner />
      ) : (
        <div className="portal-select-enabled">
          {isAdmin && (
            <div className="flash-message admin failure">
              <p>You are viewing the portal as an admin</p>
            </div>
          )}
          <FlashMessageContainer messages={flashMessages} />
          <Content id="content" className="portal-wrapper">
            {showNotificationsMenu && notificationPreferences && (
              <NotificationsMenu
                openPreferencesModal={(show) => setShowPreferencesModal(show)}
                notifications={notifications}
                markAllAsRead={markAllAsRead}
              />
            )}
            <AccountMenu
              currentEmployment={currentEmployment}
              currentEmployableType={Types.EMPLOYABLE_TYPES.SCHOOL}
              employments={userDetails.employments}
              userProfile={userDetails.profile}
              userEmail={userDetails.email}
            />
            {children}
            <footer className="portal-footer">
              <FooterLegalese
                hasTerms={!!terms}
                openTermsModal={() => setShowTermsModal(true)}
              />
              <p className="contact">
                Email{' '}
                <ExternalLink href={`mailto:${community.schoolSupportEmail}`}>
                  {community.schoolSupportEmail}
                </ExternalLink>{' '}
                with questions.
              </p>
            </footer>
            {showTermsModal && (
              <Modal onClose={() => setShowTermsModal(false)}>
                <h2>Terms & Conditions</h2>
                <div className="modal-content">
                  <RenderedHtml>{terms}</RenderedHtml>
                </div>
              </Modal>
            )}
            {showPreferencesModal && (
              <Modal onClose={() => setShowPreferencesModal(false)}>
                <NotificationsPreferencesForm
                  employmentPositions={employmentPositions}
                  initialValues={notificationPreferences}
                  onSubmit={(params) =>
                    updateNotificationPreferences(
                      notificationPreferences.id,
                      params
                    )
                  }
                  onSubmitSuccess={() => setShowPreferencesModal(false)}
                  onSubmitFail={displaySubmitFailure}
                  closePreferencesModal={() => setShowPreferencesModal(false)}
                />
              </Modal>
            )}
          </Content>
        </div>
      )}
    </div>
  )
}

LayoutAuthorized.propTypes = propTypes
LayoutAuthorized.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentSchoolId: globalSelectors.currentSchoolId(state),
    district: globalSelectors.district(state),
    flashMessages: flashActions.getFlashMessages(state),
    isAdmin: globalSelectors.isAdmin(state),
    school: globalSelectors.school(state),
    userDetails: globalSelectors.userDetails(state),
    isModerator: globalSelectors.isModerator(state),
    notifications: globalSelectors.notifications(state),
    notificationPreferences: globalSelectors.notificationPreferences(state),
  }
}

const mapDispatchToProps = {
  fetchSchool: apiActions.fetchSchoolAuthorized,
  fetchUserDetails: apiActions.fetchCurrentEducatorUserDetails,
  fetchNotifications: apiActions.fetchNotifications,
  fetchNotificationPreferences: apiActions.fetchNotificationPreferences,
  updateNotificationPreferences: apiActions.updateNotificationPreferences,
  markAllAsRead: apiActions.markAllAsRead,
  redirectToTerms: () => replace(EDUCATOR_PORTAL_ROUTE + '/terms'),
  redirectToSignOut: () => replace(SCHOOL_PORTAL_ROUTE + '/sign-out'),
  redirectToSchoolPortal: () => replace(SCHOOL_PORTAL_ROUTE),
}

function updateTitle({ school }) {
  const title = school ? `${school.name} - ` : ''
  return `${title}artlook Educator | artlook`
}

function onComponentDidCatch(_, error, errorInfo) {
  return logException(error, errorInfo)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  updateDocumentTitle(updateTitle),
  onError(onComponentDidCatch)
)(LayoutAuthorized)
