import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import ResourcesTabCardIcon from './resources-tab-card-icon'
import whiteCheckmarkImage from 'images/white-check.svg'
import classnames from 'classnames'
import { find } from 'lodash'

const propTypes = {
  artsProgrammingResources: PropTypes.arrayOf(Types.requestedInterest)
    .isRequired,
  allResourceTypes: PropTypes.arrayOf(Types.resourceType).isRequired,
  className: PropTypes.string,
}

const defaultProps = {
  className: '',
}

function resourceRequested(requestedResources, resourceTypeId) {
  return find(requestedResources, { resourceTypeId })
}

function ResourcesTabContent({
  artsProgrammingResources,
  allResourceTypes,
  className,
}) {
  return (
    <div className={classnames('colored-block card-grid', className)}>
      {allResourceTypes.map(({ id, displayName, name, definition }) => {
        return (
          <div
            key={id}
            className={classnames('stat-card', 'card', {
              'none-selected': !resourceRequested(artsProgrammingResources, id),
            })}
          >
            <div className="stat-inner-with-image">
              <div className="image-block">
                <ResourcesTabCardIcon resourceTypeName={name} />
                <span>
                  <img src={whiteCheckmarkImage} alt="Checkmark" />
                </span>
              </div>
              <div className="stack-text">
                <p>{displayName}</p>
                {definition && (
                  <span className="small-definition">{definition}</span>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

ResourcesTabContent.propTypes = propTypes
ResourcesTabContent.defaultProps = defaultProps

export default ResourcesTabContent
