import { flow } from 'lodash'
import mapAllocations from './map-allocations'
import addSchoolYearToParams from './add-school-year-to-params'
import convertToNestedAttributes from './convert-to-nested-attributes'

function serializeProfileParams(profile, schoolYear) {
  return flow(
    mapAllocations,
    (params) => addSchoolYearToParams(params, schoolYear),
    convertToNestedAttributes
  )(profile)
}

export default serializeProfileParams
