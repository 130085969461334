import React from 'react'
import { Content, ExternalLink } from 'components'
import certificationsImage from 'images/certifications.png'
import homeHero from 'images/map/landing/home-hero.jpg'

function Faq() {
  return (
    <Content>
      <header className="map-page-header">
        <div className="content-block-container">
          <div className="text-block">
            <h1>artlook Map FAQs</h1>
          </div>
        </div>
        <div
          className="image-block"
          style={{ background: `url(${homeHero}) no-repeat center` }}
        ></div>
      </header>

      <div className="search-block home-block">
        <div className="content-block-container">
          <div className="text-only-block card">
            <h3>General Questions</h3>
            <h4>
              What is artlook<sup>TM</sup> Map?
            </h4>
            <p>
              Ingenuity's <i>artlook</i> Map is an interactive tool which allows
              users to search for arts education data from public schools and
              arts organizations in Chicago. Broader map searches can be
              conducted as well, allowing users to explore arts education data
              by community area, zip code, CPS network, Creative Schools
              Certification, and more. This new version of <i>artlook</i> Map
              contains advanced filter options, additional mapping features, and
              more data than ever before.
            </p>

            <h4>Who is the audience and what is the purpose of artlook Map?</h4>
            <p>
              <i>artlook</i> Map is intended to help Arts Liaisons, school
              teachers and administrators, arts organizations, policymakers, and
              analysts identify strengths and opportunities of arts education
              across the district and make strategic decisions as they plan for
              the arts. By gaining a better understanding of the arts programs
              that currently exist, these users can better:
            </p>
            <ul>
              <li>
                Coordinate the distribution of arts resources and services
              </li>
              <li>Identify gaps in arts education and needs in schools</li>
              <li>
                Track and share results to increase the availability of arts
                programming
              </li>
              <li>
                Advocate for equitable access to arts education in schools and
                neighborhoods
              </li>
            </ul>

            <h4>What content is available through the artlook Map?</h4>
            <p>
              The data that are available on <i>artlook</i> Map are shared with
              Ingenuity by CPS schools as well as arts organizations and
              individual teaching artists. These data provide insight on arts
              staffing and instruction, budgeting and planning, and
              partnerships.
            </p>

            <p>
              While data are available from school year 2011-12 to the present,
              note that the Creative Schools Survey has only been administered
              from school year 2012-13 to the present. The most robust data sets
              will come from years in which the Creative Schools Certification
              was administered. The arts partners that are shown on the Map have
              either been reported by schools and/or partners, or in some cases,
              the partner contacted Ingenuity and asked to be added to the map.
              If you would like to have your organization added to{' '}
              <i>artlook</i> Map, please contact artlook@ingenuity-inc.org.
            </p>

            <h4>Where do these data come from?</h4>
            <p>
              Ingenuity has two data collection portals, through which we
              collect data for <i>artlook</i> Map.
            </p>
            <p>
              Arts organizations share their data on educational programs and
              school partnerships through the <i>artlook</i> Partners portal.
              Arts organizations can log in to their portal at any time and
              update their data by navigating to{' '}
              <ExternalLink href="http://www.artlookpartners.com">
                www.artlookpartners.com
              </ExternalLink>
              .
            </p>
            <p>
              Data from CPS schools is collected through the <i>artlook</i>{' '}
              Schools portal. Schools can only access the portal while they are
              opening each spring during the Creative Schools Survey. School can
              take part in the survey by navigating to{' '}
              <ExternalLink href="http://www.artlookschools.com">
                www.artlookschools.com
              </ExternalLink>{' '}
              and sharing their data on staffing, instruction, and community
              arts partners. Each school's Arts Liaison is the individual
              responsible for reporting these data each year. Therefore, in
              order to take part in the Creative Schools Survey, a school
              principal must nominate an Arts Liaison. To find out more about
              the Creative Schools Survey and/or nominating an Arts Liaison for
              your school, contact{' '}
              <ExternalLink href="mailto:artlook@ingenuity-inc.org">
                artlook@ingenuity-inc.org
              </ExternalLink>
              .
            </p>

            <h4>What is an Arts Liaison?</h4>
            <p>
              Arts Liaisons serve as the on-site school arts champion to lead
              arts programming and opportunities for their school communities,
              to connect students to resources in the arts, and to inspire their
              school community toward high-quality engagement of the arts. Arts
              Liaisons are nominated by principals. Additionally, each CPS
              Network has one or two Lead Arts Liaisons. These individuals
              provide support as their schools work to expand the arts, and
              assist in completing the Creative Schools Certification. See the
              resources section below for a list of Arts Liaisons and Lead Arts
              Liaisons.
            </p>

            <h4>What is the Creative Schools Survey?</h4>
            <p>
              The Creative Schools Survey is an annual survey that is
              administered by Ingenuity, which collects data on arts programming
              in CPS schools in the areas of staffing and instruction, budgeting
              and planning, and partnerships. The data is entered by each
              school's Arts Liaison and informs the school's category along a
              continuum that is reflected on schools' annual progress report.
              The five categories along the Creative Schools Certification
              continuum range from "Incomplete Data" to "Excelling."
            </p>
            <img src={certificationsImage} />
            <p>
              Schools are encouraged to use the Creative Schools Certification
              to help them:
            </p>
            <ul>
              <li>Make strategic decisions about arts programming</li>
              <li>
                Identify the resources and supports necessary to grow arts
                instruction
              </li>
              <li>
                Connect to professional development opportunities for principals
                and teachers
              </li>
              <li>Become eligible for the Creative Schools Fund</li>
              <li>
                Advance the District's goal of offering every CPS student a high
                quality arts education
              </li>
            </ul>

            <h4>How often is artlook Map updated?</h4>
            <p>
              Arts partners can update their data via the <i>artlook</i>{' '}
              Partners portal at any time, and the changes will be reflected on{' '}
              <i>artlook</i> Map within 24 hours. School data is updated
              annually based on the Creative Schools Survey.
            </p>

            <h4>How can my organization establish a profile on artlook Map?</h4>
            <p>
              If your organization is not currently on <i>artlook</i> Map,
              please email{' '}
              <ExternalLink href="mailto:artlook@ingenuity-inc.org">
                artlook@ingenuiity-inc.org
              </ExternalLink>
              . Note that <i>artlook</i> Map only tracks data for organizations
              working within the Chicago Public Schools.
            </p>

            <h4>
              How can I make changes to my school's or organization's data?
            </h4>
            <p>
              Arts organizations can update their data at any time by navigating
              to{' '}
              <ExternalLink href="http://www.artlookpartners.com">
                www.artlookpartners.com
              </ExternalLink>
              . Schools can update their data by navigating to{' '}
              <ExternalLink href="http://www.artlookschools.com">
                www.artlookschools.com
              </ExternalLink>
              . However, schools can only update their profiles while the annual
              Creative Schools Survey is open. This is typically from April to
              June each year.
            </p>

            <p>
              If you are with a school or organization and have forgotten your
              login credentials, please email{' '}
              <ExternalLink href="mailto:artlook@ingenuity-inc.org">
                artlook@ingenuity-inc.org
              </ExternalLink>
              .
            </p>

            <br></br>
            <br></br>
            <h3>Questions on Conducting Searches</h3>

            <h4>
              Conducting a search for an individual school or organization:
            </h4>
            <p>
              To find data on an individual school or organization navigate to
              the <i>artlook</i> Map home page. Type the name of the school or
              organization in the search bar. Choose the desired school year.
              Click "Search". Your search results will show the school or
              organization you searched for along with all their corresponding
              partnerships for the chosen school year. Click on "Partner
              Details" or "School Details" within the Search Results list to
              explore more data.
            </p>
            <p>
              As you type the name of a school or organization in the search bar
              you will notice that a list appears containing all of the schools
              and/or organizations that match what you have typed. Before
              clicking "Search" you will are required to choose an entry from
              the dropdown list.
            </p>

            <h4>Conducting an advanced search based on certain criteria:</h4>
            <p>
              To find data based on certain criteria navigate to the{' '}
              <i>artlook </i>Map home page. Near the bottom of the page you will
              find several search filters. First, use the "Show Me" column to
              choose whether you would like to search for "CPS Schools" or "Arts
              Partners" and your desired school year. Next, whether you would
              like to search the whole district or if you would like to filter
              your search by CPS Network, Zip Code, Ward, or Community Area. You
              can also filter by arts discipline and/or school type. Click
              "Search".
            </p>
            <p>
              The next page will display all the search results based on the
              filters you provided. You can filter your search further or modify
              your search by clicking on the "Show Filters" tab near the bottom
              of the Search Results list.
            </p>

            <h4>
              I found an arts partner that has 0 programs listed, but still
              lists school partnerships. How is this possible?
            </h4>
            <p>
              Each arts partner's "Partner Details" page has a Data Dashboard
              containing high-level data points for the arts partner. Some data
              dashboards may show that the arts partner offers 0 programs, but
              that they also have one or more Chicago Public School Partners.
              Programs refers to the educational programs the organization
              offers and Chicago Public School Partners is the count of schools
              the organization worked with for the given school year. These data
              are reported separately. Therefore, an arts organization's page
              may show that they have 0 programs if they have yet to provide
              program details within the <i>artlook</i> Partners portal.
            </p>
          </div>
        </div>
      </div>
    </Content>
  )
}

export default Faq
