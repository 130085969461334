import React from 'react'
import PropTypes from 'prop-types'
import manageFocus from './manage-focus'
import classnames from 'classnames'
import { noop } from 'lodash'
import { filterInvalidDOMProps } from 'lp-components/lib/utils'

const propTypes = {
  className: PropTypes.string,
  forwardedRef: PropTypes.func,
  name: PropTypes.string.isRequired,
  nameComponent: PropTypes.func,
  onClick: PropTypes.func,
}

const defaultProps = {
  className: '',
  forwardedRef: null,
  nameComponent: DefaultNameComponent,
  onClick: noop,
}

function DefaultNameComponent({ name }) {
  return name
}

function ToolbarItem({
  forwardedRef,
  name,
  nameComponent: NameComponent,
  className,
  onClick,
  ...rest
}) {
  return (
    <button
      type="button"
      className={classnames('toolbar-item', className)}
      onClick={onClick}
      tabIndex="-1"
      onKeyDown={manageFocus}
      ref={forwardedRef}
      {...filterInvalidDOMProps(rest)}
    >
      <NameComponent name={name} {...rest} />
    </button>
  )
}

ToolbarItem.propTypes = propTypes
ToolbarItem.defaultProps = defaultProps

export default ToolbarItem
