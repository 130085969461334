import React from 'react'
import { Route, IndexRoute } from 'react-router'
import * as Views from './views'
import Layout from './layout'

const Routes = (
  <Route path="directory" component={Layout}>
    <IndexRoute component={Views.Directory} />
  </Route>
)

export default Routes
