import { get, toLower } from 'lodash'
const { EDUCATOR_OVERRIDE } = process.env

function isEducatorEnabled(communityConfig) {
  return (
    get(communityConfig, 'educatorEnabled') ||
    toLower(EDUCATOR_OVERRIDE) === 'true'
  )
}

export default isEducatorEnabled
