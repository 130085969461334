import React, { useState } from 'react'
import PropTypes from 'prop-types'
import whiteCheckmarkImage from 'images/white-check.svg'
import classnames from 'classnames'
import { ArtDisciplineIcon } from 'components/icons'

const propTypes = {
  arts: PropTypes.arrayOf(PropTypes.string).isRequired,
  displayName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  controlledId: PropTypes.string.isRequired,
}

const defaultProps = {}

function ArtStatCard({ arts, displayName, type, controlledId }) {
  const [detailsActive, setDetailsActive] = useState(false)
  const hasArts = arts.length > 0
  return (
    <div
      className={classnames('stat-card', 'card', {
        'none-selected': !hasArts,
        'active-trigger': detailsActive,
      })}
    >
      <div className="stat-inner-with-image">
        <div className="image-block">
          <ArtDisciplineIcon discipline={type} />
          <span>
            <img src={whiteCheckmarkImage} alt="Checkmark" />
          </span>
        </div>
        <p>{displayName}</p>
        {hasArts && (
          <button
            onClick={() => setDetailsActive(!detailsActive)}
            className="stat-trigger"
            aria-label={`Toggle ${displayName} details`}
            aria-expanded={detailsActive}
            aria-controls={controlledId}
          />
        )}
      </div>
      <div id={controlledId}>
        {detailsActive && (
          <div className="stat-details">
            <div className="stat-content">
              <ul>
                {arts && arts.map((art, idx) => <li key={idx}>{art}</li>)}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

ArtStatCard.propTypes = propTypes
ArtStatCard.defaultProps = defaultProps

export default ArtStatCard
