import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors as globalSchoolSelectors } from 'school-portal-reducer'
import { Spinner } from 'lp-components'
import { PortalHeader } from 'components'
import { SCHOOL_PORTAL_ROUTE } from 'config'
import { useCommunity } from 'utils'

const propTypes = {
  children: PropTypes.node,
  school: Types.school,
  detail: Types.detail,
  currentSchoolYear: Types.schoolYear.isRequired,
}

const defaultProps = {
  children: null,
  school: null,
  detail: null,
}

function Layout({ children, school, detail, currentSchoolYear }) {
  const {
    defaultBranding: { dashboardHeader },
  } = useCommunity()

  const hasLoaded = !!(school && detail)
  return (
    <div>
      <PortalHeader
        title={`${currentSchoolYear.number} artlook Map Profile`}
        backgroundImage={dashboardHeader}
        links={[
          {
            name: 'Overview',
            path: SCHOOL_PORTAL_ROUTE + '/summary/overview',
          },
          {
            name: 'School Partnerships',
            path: SCHOOL_PORTAL_ROUTE + '/summary/partnerships',
          },
          {
            name: 'Interests',
            path: SCHOOL_PORTAL_ROUTE + '/summary/interests',
          },
        ]}
      />
      <div className="content-block-container">
        {!hasLoaded ? <Spinner /> : <React.Fragment>{children}</React.Fragment>}
      </div>
    </div>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    detail: globalSchoolSelectors.currentDetail(state),
    school: globalSchoolSelectors.school(state),
    currentSchoolYear: globalSelectors.currentSchoolYear(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
