import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { pure } from 'recompose'
import { Field } from 'redux-form'
import { format, parse, isOtherOption } from './helpers'
import RadioGroupWithOtherOption from './radio-group-with-other-option'
import { isEmpty, isNil, trim } from 'lodash'

const propTypes = {
  optionIdKey: PropTypes.string.isRequired,
  otherValidationError: PropTypes.string,
  options: PropTypes.arrayOf(Types.formOptionType).isRequired,
}

const defaultProps = {
  otherValidationError: 'Description is required',
}

// Wraps a customized redux-form Field with included helpers
function RadioGroupWithOtherField({
  optionIdKey,
  otherValidationError,
  options,
  ...rest
}) {
  const otherOptionId = useMemo(
    () => options.find(isOtherOption).value,
    [options]
  )
  const validateOther = useCallback(
    (value) => {
      if (!value) return
      const { otherText } = value
      if (!isNil(otherText) && isEmpty(trim(otherText)))
        return otherValidationError
    },
    [otherValidationError]
  )
  return (
    <Field
      component={RadioGroupWithOtherOption}
      format={format(optionIdKey, otherOptionId)}
      parse={parse(optionIdKey, otherOptionId)}
      validate={validateOther}
      options={options}
      {...rest}
    />
  )
}

RadioGroupWithOtherField.propTypes = propTypes
RadioGroupWithOtherField.defaultProps = defaultProps

export default pure(RadioGroupWithOtherField)
