import { isArray, isPlainObject, reduce } from 'lodash'
import isBlank from './is-blank'

function deepCount(collection) {
  if (!isPlainObject(collection)) return count(collection)

  return reduce(
    collection,
    (acc, v) => {
      return acc + deepCount(v)
    },
    0
  )
}

// ----- PRIVATE -----
function count(value) {
  if (isBlank(value)) return 0
  if (isArray(value)) return value.length

  return 1
}

export default deepCount
