import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { waitFor } from 'lp-hoc'
import { selectors as globalSelectors } from 'partner-portal-reducer'
import * as Types from 'types'
import { LoadingSpinner } from 'components'
import { DashboardMap } from '../components'

const propTypes = {
  partner: Types.partner,
  locations: PropTypes.arrayOf(Types.location),
}

const defaultProps = {
  locations: [],
}

function Summary({ partner, locations }) {
  return <DashboardMap locations={locations} partnerId={partner.id} />
}

Summary.propTypes = propTypes
Summary.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    partner: globalSelectors.partner(state),
    locations: globalSelectors.allLocations(state),
  }
}

export default compose(
  connect(mapStateToProps),
  waitFor('partner', LoadingSpinner)
)(Summary)
