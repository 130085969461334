import isOtherType from './is-other-type'

// Returns id of the type with name "other" in an array of types

function getOtherTypeId(types) {
  const otherType = types.find(isOtherType)
  return otherType ? otherType.id : null
}

export default getOtherTypeId
