import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import * as Types from 'types'
import { ExternalLink } from 'components'

const propTypes = {
  location: Types.location.isRequired,
  onClose: PropTypes.func.isRequired,
}

function PartnerPopup({ location, onClose }) {
  const locations = location.entries || [location]
  return (
    <div className="pop-up" data-cy="map-marker-pop-up">
      <button className="close" onClick={onClose}>
        ×
      </button>
      <div className="text-block">
        {locations.map((location) => {
          return (
            <div className="location" key={location.resourceId}>
              <h5 data-cy="map-marker-pop-up-name">
                <Link to={`/partners/${location.resourceId}`}>
                  {location.name}
                </Link>
              </h5>
              <p>{location.address}</p>
              <div className="details">
                <ExternalLink
                  href={`/partners/${location.resourceId}`}
                  className="button-default-outline"
                >
                  View Partner
                </ExternalLink>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

PartnerPopup.propTypes = propTypes

export default PartnerPopup
