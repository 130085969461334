/**
 * @name wrapConsoleObject
 * @returns undefined - Sets logging statements to noops, depending on the logging level set for the environment.
 */

import { noop } from 'lodash'

/* eslint-disable no-console */

const { LOGGING_LEVEL } = process.env
const LOGGING_LEVELS = ['trace', 'debug', 'info', 'warn', 'error']

function wrapConsoleObject() {
  const allowedIndex = LOGGING_LEVELS.indexOf(LOGGING_LEVEL)

  // Do not wrap any console objects when running in development or if no level has been defined
  if (isDevelopment() || allowedIndex === -1) return

  // Disable logging for levels that do not (at least) meet the defined threshold
  LOGGING_LEVELS.forEach((level, index) => {
    if (index >= allowedIndex) return

    // Equate 'log' with the info level
    if (level === 'info') console.log = noop
    console[level] = noop
  })
}

function isDevelopment() {
  return process.env.NODE_ENV !== 'production'
}

// Invoke immediately
wrapConsoleObject()
