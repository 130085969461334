import React from 'react'

function ResidencyIcon() {
  return (
    <svg width="82px" height="82px" viewBox="0 0 50 50">
      <path
        fill="#204A82"
        className="fill"
        d="M50,25A25,25,0,1,1,25,0,25,25,0,0,1,50,25Z"
      />
      <path
        fill="#204A82"
        className="fill"
        d="M25,50a24.94,24.94,0,0,0,18.3-8H6.7A24.94,24.94,0,0,0,25,50Z"
      />
      <path
        fill="#204A82"
        className="fill"
        d="M12.67,25.42H9.88l0-1.08,14.89-11.2L39.68,24.33v1H36.76V39.08h8.9A25,25,0,1,0,0,25,24.87,24.87,0,0,0,4.34,39.08h8.33Z"
      />
      <path
        fill="#FFFFFF"
        d="M36.76,39.08V25.35h2.92v-1L24.79,13.14,9.9,24.34l0,1.08h2.79V39.08H4.34A25.85,25.85,0,0,0,6.7,42H43.3a25.85,25.85,0,0,0,2.36-3Z"
      />
      <polygon
        fill="#FFFFFF"
        points="29.8 15.06 29.8 12.02 31.5 12.01 31.47 11.35 24.8 5.54 18.12 11.35 18.12 12.01 19.79 12.02 19.79 15.06 24.79 11.29 29.8 15.06"
      />
      <path
        fill="#204A82"
        className="fill"
        d="M26.11,25.77a3.7,3.7,0,0,0,1.51-.92,4.42,4.42,0,0,0,1-2.57A4.5,4.5,0,0,0,26,23.5a3.41,3.41,0,0,0-1,1.38l-.48-2.19L23.13,23l.6,2.74h-.44c-2.69,0-4.87,1.51-4.87,4.77s2.26,7,4.87,7h2.3c2.39,0,4.87-3.77,4.87-7A4.39,4.39,0,0,0,26.11,25.77Z"
      />
    </svg>
  )
}

export default ResidencyIcon
