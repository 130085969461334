import React from 'react'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { Textarea, SubmitButton, ButtonArea } from 'lp-components'
import { persistSubmitSucceeded } from 'utils'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

function ObstaclesDescriptionForm({
  handleSubmit,
  submitSucceeded,
  pristine,
  submitting,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="description"
        label={false}
        aria-labelledby="describe-question"
        component={Textarea}
      />
      <ButtonArea>
        <SubmitButton
          {...{ pristine: submitSucceeded && pristine, submitting }}
        >
          Save Response
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

ObstaclesDescriptionForm.propTypes = propTypes
ObstaclesDescriptionForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'school-obstacles-description',
    constraints: {
      description: { length: { maximum: 1000 } },
    },
  }),
  persistSubmitSucceeded()
)(ObstaclesDescriptionForm)
