import { handleActions } from 'redux-actions'
import { selectorForSlice, setState } from 'lp-redux-utils'
import { createSelector } from 'reselect'
import * as LS from 'local-storage'
import * as apiActions from 'api-actions'
import { setOnSuccess } from 'lp-redux-api'
import { isAdmin, isAuthenticated } from './auth'
import * as actions from './global-actions'
import * as Types from 'types'
import { set, serializeLocation, without } from 'utils'
import { years } from 'select-options'
import { update, sortBy } from 'lodash/fp'
import { orderBy, toNumber } from 'lodash'

const reducerKey = 'global'
const slice = 'root.partnerPortal.global'

const initialState = {
  currentPartnerId: LS.getCurrentPartnerId(),
  isAdmin: isAdmin(),
  isAuthenticated: isAuthenticated(),
}

const reducer = handleActions(
  {
    [actions.clearPartner]: () => initialState,
    [actions.setCurrentPartnerId]: (state, { payload: id }) => {
      LS.clearCurrentPartnerId()
      if (!id) return set('currentPartnerId', null, state)
      LS.setCurrentPartnerId(id)
      return set('currentPartnerId', toNumber(id), state)
    },
    [actions.setAuthenticated]: (
      state,
      { payload: { token, persistSession } }
    ) => {
      LS.clearPartnerAuthToken()
      if (!token) return set('isAuthenticated', false, state)
      LS.setPartnerAuthToken(token, persistSession)
      return set('isAuthenticated', true, state)
    },
    [actions.setIsAdmin]: (state, { payload: isAdmin }) => {
      LS.clearIsAdmin()
      if (!isAdmin) return set('isAdmin', false, state)
      LS.setIsAdmin(true)
      return set('isAdmin', true, state)
    },
    [actions.setPartner]: setState('partner'),
    [actions.setPrimaryContact]: setState('partner.contact'),
    [apiActions.fetchPartnerAuthorized]: setOnSuccess('partner'),
    [apiActions.fetchPartnerOptions]: setOnSuccess('partnerOptions'),
    [apiActions.fetchCurrentPartnerUserDetails]: setOnSuccess('userDetails'),
    [apiActions.fetchSchoolOptions]: setOnSuccess('schoolOptions', (action) => {
      return action.payload.data.activeSchools
    }),
    [apiActions.fetchActiveSchoolYears]: setOnSuccess('activeSchoolYears'),
    [actions.setUserDetails]: setState('userDetails'),
    [apiActions.agreeToTerms]: setOnSuccess(
      'userDetails.agreedAt',
      ({ payload: { data } }) => data.agreedAt
    ),
    [apiActions.fetchNotifications]: setOnSuccess('notifications'),
    [apiActions.markAllAsRead]: setOnSuccess('notifications'),
    [apiActions.deleteNotification]: setOnSuccess('notifications'),
    [apiActions.markAsRead]: setOnSuccess('notifications'),
    [apiActions.fetchNotificationPreferences]: setOnSuccess(
      'notificationPreferences'
    ),
    [apiActions.updateNotificationPreferences]: setOnSuccess(
      'notificationPreferences'
    ),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  activeSchoolYears: select('activeSchoolYears'),
  currentPartnerId: select('currentPartnerId'),
  // currentSchoolYear: select('currentSchoolYear'),
  isAdmin: select('isAdmin'),
  isAuthenticated: select('isAuthenticated'),
  gradeOptions: select('selectOptions.gradeOptions'),
  outcomeOptions: select('selectOptions.outcomeOptions'),
  unsortedPartner: select('partner'),
  partnerOptions: select('partnerOptions'),
  programTypeOptions: select('selectOptions.programTypeOptions'),
  programDisciplineOptions: select('selectOptions.programDisciplineOptions'),
  schoolOptions: select('schoolOptions'),
  selectOptions: select('selectOptions'),
  userDetails: select('userDetails'),
  notifications: select('notifications'),
  notificationPreferences: select('notificationPreferences'),
}

// Computed

selectors.partner = createSelector(
  [selectors.unsortedPartner],
  function (unsortedPartner) {
    if (!unsortedPartner) return null
    return sortPartner(unsortedPartner)
  }
)

selectors.partnerships = createSelector(
  [selectors.partner],
  function (partner) {
    if (!partner) return []
    return partner.partnerships
  }
)

selectors.currentSchoolYear = createSelector(
  [selectors.partner],
  function (partner) {
    if (!partner) return null
    return partner.detail.schoolYearId
  }
)

selectors.partnerLocations = createSelector(
  [selectors.partnerships],
  function (partnerships) {
    return partnerships.map(serializeLocation(Types.SCHOOL_TYPE))
  }
)

selectors.currentPartnerLocation = createSelector(
  [selectors.partner],
  function (partner) {
    if (!partner) return null
    return serializeLocation(Types.PARTNER_TYPE, partner)
  }
)

selectors.allLocations = createSelector(
  [selectors.partnerLocations, selectors.currentPartnerLocation],
  function (partnerLocations, currentPartnerLocation) {
    return [...partnerLocations, currentPartnerLocation]
  }
)

selectors.reportYears = createSelector(
  [selectors.currentSchoolYear],
  function (currentSchoolYear) {
    return without(years, currentSchoolYear)
  }
)

// Order school years by most recent (DESC)
selectors.orderedActiveSchoolYears = createSelector(
  [selectors.activeSchoolYears],
  function (schoolYears) {
    if (!schoolYears) return
    return orderBy(schoolYears, 'number', 'desc')
  }
)

// Helpers

function sortPartner(partner) {
  return update('partnerships', sortBy('schoolName'), partner)
}

export { reducer, reducerKey, selectors }
