import { isNan } from 'lodash'

// Convert decimal number to percent string
// Eg .025 -> 2.5%
function numberToPercent(num, { decimalPlaces = 0 } = {}) {
  if (isNan(num) || num < 0 || num > 1) return '-'
  return (num * 100).toFixed(decimalPlaces) + '%'
}

export default numberToPercent
