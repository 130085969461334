import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Toolbar from 'components/toolbar'
import PostsFilterFields from './posts-filter-fields'
import { get, isEqual, isEmpty, mapValues } from 'lodash'
import { deepCount } from 'utils'
import classnames from 'classnames'

const propTypes = {
  filterOptions: PropTypes.object.isRequired,
  applyFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  appliedFilters: PropTypes.object.isRequired,
  selectedFilters: PropTypes.object.isRequired,
}

const defaultProps = {}

function PostsFilterToolbar({ selectedFilters, appliedFilters, ...rest }) {
  const isFilterUnchanged = useCallback(
    (filterName) =>
      isEqual(
        get(appliedFilters, filterName),
        get(selectedFilters, filterName)
      ),
    [appliedFilters, selectedFilters]
  )

  const isFilterEmpty = useCallback(
    (filterName) => isEmpty(get(selectedFilters, filterName)),
    [selectedFilters]
  )

  const appliedFilterCounts = useMemo(
    () => mapValues(appliedFilters, deepCount),
    [appliedFilters]
  )

  const getAppliedFilterCount = useCallback(
    (filterName) => get(appliedFilterCounts, filterName),
    [appliedFilterCounts]
  )

  const [openFilterId, setOpenFilterId] = useState(null)

  const closeFilter = useCallback(
    (id) => {
      if (id !== openFilterId) return
      return setOpenFilterId(null)
    },
    [openFilterId]
  )

  return (
    <div
      className={classnames('filter-content', {
        'filter-open': !!openFilterId,
      })}
    >
      <Toolbar
        className="filter-wrapper filters"
        aria-label="Discussions Filtering"
        aria-controls="discussion-posts"
        data-cy="discussion-filters"
      >
        <p>
          <strong>Filter By:</strong>
        </p>
        <PostsFilterFields
          getAppliedFilterCount={getAppliedFilterCount}
          isFilterEmpty={isFilterEmpty}
          isFilterUnchanged={isFilterUnchanged}
          openFilterId={openFilterId}
          openFilter={setOpenFilterId}
          closeFilter={closeFilter}
          {...rest}
        />
      </Toolbar>
    </div>
  )
}

PostsFilterToolbar.propTypes = propTypes
PostsFilterToolbar.defaultProps = defaultProps

export default PostsFilterToolbar
