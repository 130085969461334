import React from 'react'
// import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { SubmitButton, ButtonArea, Checkbox } from 'lp-components'
import { Field, propTypes as formPropTypes } from 'redux-form'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

function StaySignedInForm({ handleSubmit, submitting }) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field name="persist" label="Keep me signed in" component={Checkbox} />
      <ButtonArea>
        <SubmitButton submitting={submitting}>Continue</SubmitButton>
      </ButtonArea>
    </form>
  )
}

StaySignedInForm.propTypes = propTypes
StaySignedInForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'stay-signed-in',
    constraints: {},
  })
)(StaySignedInForm)
