import React from 'react'
import PropTypes from 'prop-types'
import ReactCSSTransition from 'react-transition-group/CSSTransition'
import classnames from 'classnames'

const propTypes = {
  isError: PropTypes.bool,
  message: PropTypes.string,
  timeout: PropTypes.number,
  className: PropTypes.string,
}

function Flash({ timeout = 3000, isError, message, className }) {
  const status = isError ? 'failure' : 'success'
  return (
    <ReactCSSTransition
      in={true}
      timeout={{
        enter: 500,
        exit: timeout,
      }}
      classNames="flash"
    >
      <div className={classnames('flash-message', className, status)}>
        <p>{message}</p>
      </div>
    </ReactCSSTransition>
  )
}

Flash.propTypes = propTypes

export default Flash
