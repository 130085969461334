import React from 'react'
import PropTypes from 'prop-types'
import SchoolPopup from './school-popup'
import PartnerPopup from './partner-popup'
import * as Types from 'types'
import classnames from 'classnames'

const propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  location: Types.location.isRequired,
  hidePopup: PropTypes.bool.isRequired,
}

const defaultProps = {}

function MapMarker({ isSelected, onClick, location, hidePopup }) {
  const { type } = location
  const Popup = type === Types.SCHOOL_TYPE ? SchoolPopup : PartnerPopup
  return (
    <div>
      <button
        type="button"
        className={classnames('map-marker', {
          school: type === Types.SCHOOL_TYPE,
        })}
        onClick={() => onClick(location)}
        data-cy="map-marker"
      />
      {!hidePopup && isSelected && !!location.resourceId && (
        <Popup location={location} onClose={() => onClick(null)} />
      )}
    </div>
  )
}

MapMarker.propTypes = propTypes
MapMarker.defaultProps = defaultProps

export default MapMarker
