import * as LS from 'local-storage'

// Some routes can be authorized with either a school or partner portal auth token
// Return whichever one is available

function getPortalAuthToken() {
  return LS.getSchoolAuthToken() || LS.getPartnerAuthToken()
}

export default getPortalAuthToken
