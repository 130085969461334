import * as LS from 'local-storage'

// Auth helpers for school portal

export function isAuthenticated() {
  return !!LS.getSchoolAuthToken()
}

export function isAdmin() {
  return !!LS.getIsAdmin()
}
