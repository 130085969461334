import { compose } from 'redux'
import { onMount, onUnmount, onUpdate, getSet } from '@launchpadlab/lp-hoc'

function getTitleName(titleFuncOrString, props) {
  return typeof titleFuncOrString === 'function'
    ? titleFuncOrString(props)
    : titleFuncOrString
}

export default function updateDocumentTitle(titleFuncOrString) {
  return (WrappedComponent) =>
    compose(
      getSet('priorTitle'),
      onMount(({ setPriorTitle, ...rest }) => {
        setPriorTitle(document.title)
        document.title = getTitleName(titleFuncOrString, rest)
      }),
      onUpdate(
        (props) => (document.title = getTitleName(titleFuncOrString, props))
      ),
      onUnmount(({ priorTitle }) => (document.title = priorTitle))
    )(WrappedComponent)
}
