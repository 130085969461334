import React, { forwardRef } from 'react'
import Select, { components } from 'react-select'
import { useUID } from 'react-uid'
import { get } from 'lodash'

const Option = (props) => {
  const innerProps = {
    ...props.innerProps,
    className: 'combo-box-option',
    role: 'option',
    'aria-disabled': props.isDisabled,
    'aria-selected': props.isSelected,
  }
  return (
    <div>
      <components.Option {...props} innerProps={innerProps}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

const MenuList = (props) => {
  const innerProps = {
    ...props.innerProps,
    id: props.selectProps.menuId,
    className: props.selectProps.menuClass,
    role: 'listbox',
    ...(props.isMulti ? { 'aria-multiselectable': true } : {}),
  }
  return <components.MenuList {...props} innerProps={innerProps} />
}

const Input = (props) => {
  // aria-label and aria-labelledby are already spread by the existing props if provided
  const isExpanded = props.selectProps.menuIsOpen
  const ariaProps = {
    role: props.selectProps.isSearchable ? 'combobox' : 'listbox',
    'aria-autocomplete': 'list',
    'aria-expanded': isExpanded,
    'aria-describedby': get(props.selectProps, 'aria-describedby'),
    ...(isExpanded ? { 'aria-controls': props.selectProps.menuId } : {}),
  }

  return <components.Input {...props} {...ariaProps} />
}

// At this time, ARIA support won't be added as the authors are concerned
// that they can't meet everyone's use case. They've opted for keyboard support
// and live regions instead. We are adding ARIA support by overriding a few
// components using the components API.
// References:
// https://github.com/JedWatson/react-select/pull/4322
// https://github.com/JedWatson/react-select/issues/2456

function AriaSelect(props, ref) {
  const uniqueMenuId = 'combo-box-menu-' + useUID()
  return (
    <Select
      ref={ref}
      menuId={uniqueMenuId}
      menuClass="combo-box-menu"
      className="combo-box-option"
      components={{
        Option,
        MenuList,
        Input,
      }}
      {...props}
    />
  )
}

export default forwardRef(AriaSelect)
