import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'components'
import RenderedHtml from './rendered-html'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string,
  content: PropTypes.string,
}

const defaultProps = {
  header: '',
  content: '',
}

function InfoModal({ onClose, header, content }) {
  return (
    <Modal onClose={onClose}>
      {header && <h2>{header}</h2>}
      <div className="modal-content info-modal">
        <RenderedHtml>{content}</RenderedHtml>
      </div>
    </Modal>
  )
}

InfoModal.propTypes = propTypes
InfoModal.defaultProps = defaultProps

export default InfoModal
