import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import { AuthorizedRoute } from 'components'
import LayoutAuthorized from './layout-authorized'
import { routes as HomeRoutes } from './home'
import { routes as ForumRoutes } from './forum'
import { routes as DirectoryRoutes } from './directory'
import { routes as ProfileRoutes } from './profile'
import { routes as ModeratorRoutes } from './moderator'
import { routes as CopRoutes } from './community-of-practice'
import { isAuthenticated } from './auth'
import Terms from './terms'
import { SCHOOL_PORTAL_ROUTE, EDUCATOR_PORTAL_ROUTE } from 'config'

const Routes = (
  <Route path={EDUCATOR_PORTAL_ROUTE}>
    <IndexRedirect to="home" />
    <AuthorizedRoute
      authFunction={isAuthenticated}
      // Educator is currently only available to users employed at a school
      redirect={SCHOOL_PORTAL_ROUTE + '/sign-in'}
    >
      <Route path={EDUCATOR_PORTAL_ROUTE + '/terms'} component={Terms} />
      <Route component={LayoutAuthorized}>
        {HomeRoutes}
        {ForumRoutes}
        {DirectoryRoutes}
        {ProfileRoutes}
        {ModeratorRoutes}
        {CopRoutes}
      </Route>
    </AuthorizedRoute>
  </Route>
)

export default Routes
