import React from 'react'
import { Route, IndexRoute } from 'react-router'
import * as Views from './views'
import * as EducatorViews from '../views'
import Layout from './layout'

const Routes = (
  <Route path="forum" component={Layout}>
    <IndexRoute component={Views.Forum} />
    {/* Posts are labeled as discussions in the UI */}
    <Route path="discussions/:postId" component={EducatorViews.Post} />
  </Route>
)

export default Routes
