import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import {
  Field,
  propTypes as formPropTypes,
  formValueSelector,
  FormSection,
} from 'redux-form'
import { SubmitButton, Input } from 'lp-components'
import { AutoSaveStatusIndicator } from 'components'
import { modifyProps } from 'lp-hoc'
// import { RadioInput } from '../components'
import { numberToPercent, persistSubmitSucceeded } from 'utils'
import { get } from 'lodash/fp'

const propTypes = {
  coursePercentAccess: PropTypes.string.isRequired,
  courseEnrollmentLabel: PropTypes.string,
  ...formPropTypes,
}

const defaultProps = {
  courseEnrollmentLabel: null,
}

function validateCourseEnrollment(value, formValues) {
  const enrollment = parseInt(
    get('participation.selfReportedEnrollment', formValues)
  )
  const val = parseInt(value)
  if (!val && val !== 0) return 'Field is required'
  if (val < 0) return 'Cannot be less than 0'
  if (val > enrollment) return 'Cannot be greater than total enrollment'
}

function validateSelfReportedEnrollment(value, formValues) {
  const courseEnrollment = parseInt(
    get('participation.courseEnrollment', formValues)
  )
  const val = parseInt(value)
  if (!val && val !== 0) return 'Field is required'
  if (val <= 0) return 'Cannot be less than or equal to 0'
  if (val < courseEnrollment) return 'Cannot be less than course enrollment'
}

function PercentAccessForm({
  coursePercentAccess,
  handleSubmit,
  saved,
  submitting,
  courseEnrollmentLabel,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <AutoSaveStatusIndicator saveSucceeded={saved} />
      <FormSection name="participation">
        <ul className="percent-access">
          <li>
            <Field
              name="courseEnrollment"
              label={false}
              type="number"
              component={Input}
              validate={validateCourseEnrollment}
            />
            <span>
              <label htmlFor="participation.courseEnrollment">
                {courseEnrollmentLabel || 'Students who took an arts course'}
              </label>
            </span>
          </li>
          <li>
            <span className="big-text">/</span>
          </li>
          <li>
            <Field
              name="selfReportedEnrollment"
              label={false}
              type="number"
              component={Input}
              validate={validateSelfReportedEnrollment}
            />
            <span>
              <label htmlFor="participation.selfReportedEnrollment">
                Total elementary and middle school students enrolled at your
                school
              </label>
            </span>
          </li>
          <li>
            <span className="big-text">=</span>
          </li>
          <li>
            <span className="big-text">{coursePercentAccess}</span>
            <p>Percent Access</p>
          </li>
        </ul>
      </FormSection>
      <div className="button-wrapper">
        <SubmitButton {...{ pristine: saved, submitting }}>
          Save Response
        </SubmitButton>
      </div>
    </form>
  )
}

PercentAccessForm.propTypes = propTypes
PercentAccessForm.defaultProps = defaultProps

const select = formValueSelector('school-percent-access')

function mapStateToProps(state) {
  return {
    courseEnrollment: select(state, 'participation.courseEnrollment'),
    selfReportedEnrollment: select(
      state,
      'participation.selfReportedEnrollment'
    ),
  }
}

function modify({ courseEnrollment, selfReportedEnrollment }) {
  return {
    coursePercentAccess: numberToPercent(
      courseEnrollment / selfReportedEnrollment
    ),
  }
}

// Convert participations to array before sending
function beforeSubmit({ participation }) {
  return {
    participations: [participation],
  }
}

export default compose(
  connect(mapStateToProps),
  modifyProps(modify),
  lpForm({
    name: 'school-percent-access',
    enableReinitialize: true,
    beforeSubmit,
  }),
  persistSubmitSucceeded()
)(PercentAccessForm)
