import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withHandlers } from 'recompose'
import { lpForm } from 'lp-form'
import {
  Field,
  propTypes as formPropTypes,
  formValueSelector,
  SubmissionError,
} from 'redux-form'
import {
  persistSubmitSucceeded,
  formatObjectsToIds,
  parseIdsToObjects,
  replaceResources,
  getNoneTypeId,
  getOtherTypeId,
  yankBy,
  filterDestroyed,
  serializeOptions,
  isOtherType,
} from 'utils'
import { CheckboxGroup, CheckboxWithText, CheckboxFieldset } from 'components'
import { InputError, SubmitButton } from 'lp-components'
import { modifyProps } from 'lp-hoc'
import { values, pickBy, isNumber } from 'lodash'

const propTypes = {
  ...formPropTypes,
  fundingTypes: PropTypes.arrayOf(Types.communityEnumerable).isRequired,
  ariaLabelledby: PropTypes.string.isRequired,
}

const defaultProps = {}

// Convert object with numerical values to an array of numbers
function objToArray(obj) {
  if (!obj) return []
  return values(pickBy(obj, isNumber))
}

function FundingForm({
  handleSubmit,
  isSaving,
  fundingTypes,
  saved,
  submitting,
  noneTypeId,
  selectNoneOption,
  deselectNoneOption,
  fundings,
  error,
  ariaLabelledby,
}) {
  const [otherFundingType, definedFundingTypes] = yankBy(
    fundingTypes,
    isOtherType
  )

  return (
    <form onSubmit={handleSubmit}>
      <CheckboxFieldset invalid={!!error} disabled={isSaving}>
        <Field
          name="fundings"
          aria-labelledby={ariaLabelledby}
          label={false}
          component={CheckboxGroup}
          options={serializeOptions(definedFundingTypes)}
          format={formatObjectsToIds('fundingTypeId')}
          parse={parseIdsToObjects('fundingTypeId')}
          onChange={(value) => {
            // Deselect other values if None type is selected
            const arrayValue = objToArray(value)
            if (!arrayValue.includes(noneTypeId)) return
            const lastSelectedValue = arrayValue[0]
            const didSelectNone = lastSelectedValue === noneTypeId
            return didSelectNone
              ? selectNoneOption()
              : deselectNoneOption(arrayValue)
          }}
        />
        {otherFundingType && (
          <Field
            name="otherText"
            label={otherFundingType.displayName}
            placeholder="Description..."
            component={CheckboxWithText}
            onChange={() =>
              deselectNoneOption(formatObjectsToIds('fundingTypeId', fundings))
            }
            className="CheckboxGroup full-width-other"
          />
        )}
      </CheckboxFieldset>
      {error && <InputError error={error} touched invalid />}
      <div className="button-wrapper">
        <SubmitButton {...{ pristine: saved, submitting }}>
          Save Response
        </SubmitButton>
      </div>
    </form>
  )
}

FundingForm.propTypes = propTypes
FundingForm.defaultProps = defaultProps

const select = formValueSelector('school-funding')

function mapStateToProps(state) {
  return {
    fundings: select(state, 'fundings'),
  }
}

function getSpecialIds({ fundingTypes }) {
  return {
    noneTypeId: getNoneTypeId(fundingTypes),
    otherTypeId: getOtherTypeId(fundingTypes),
  }
}

function modifyInitialValues({ initialValues }) {
  const [otherFunding, fundings] = yankBy(initialValues.fundings, isOtherType)
  return {
    initialValues: {
      ...initialValues,
      fundings,
      otherText: otherFunding ? otherFunding.otherText : '',
    },
  }
}

function modifyBeforeSubmit({ initialValues, otherTypeId }) {
  return {
    beforeSubmit: ({ fundings, otherText }) => {
      const resources = replaceResources({
        old: initialValues.fundings,
        new: fundings,
      })
      if (otherText) resources.push({ fundingTypeId: otherTypeId, otherText })
      if (!filterDestroyed(resources).length)
        throw new SubmissionError({
          _error: 'You must select at least one option',
        })
      return { fundings: resources }
    },
  }
}

function modifyHandlers() {
  return {
    selectNoneOption:
      ({ change, noneTypeId }) =>
      () => {
        setTimeout(() =>
          change(
            'fundings',
            parseIdsToObjects('fundingTypeId', [noneTypeId]),
            0
          )
        )
        setTimeout(() => change('otherText', ''), 0)
      },
    deselectNoneOption:
      ({ change, noneTypeId }) =>
      (values) => {
        const otherOptions = values.filter((val) => val !== noneTypeId)
        setTimeout(() =>
          change(
            'fundings',
            parseIdsToObjects('fundingTypeId', otherOptions),
            0
          )
        )
      },
  }
}

export default compose(
  connect(mapStateToProps),
  modifyProps(getSpecialIds),
  modifyProps(modifyBeforeSubmit),
  modifyProps(modifyInitialValues),
  lpForm({
    name: 'school-funding',
    enableReinitialize: true,
    constraints: {
      otherText: {
        exclusion: {
          within: [' '],
          message: '^Description is required',
        },
      },
    },
  }),
  withHandlers(modifyHandlers),
  persistSubmitSucceeded()
)(FundingForm)
