import React from 'react'
import PropTypes from 'prop-types'
import { ScrollLink } from 'components'
import { getSet, getSetPropTypes } from 'lp-hoc'
import classnames from 'classnames'
import profileImg from 'images/map/dashboards/profile.svg'
import dataImg from 'images/map/dashboards/data.svg'
import schoolImg from 'images/map/dashboards/schools.svg'
import artsImg from 'images/map/dashboards/arts.svg'

const propTypes = {
  ...getSetPropTypes('currentTab'),
  hasSchools: PropTypes.bool,
  hasPrograms: PropTypes.bool,
}

const defaultProps = {
  hasSchools: false,
  hasPrograms: false,
}

function PartnerTabs({ currentTab, setCurrentTab, hasSchools, hasPrograms }) {
  return (
    <nav aria-label="partner content">
      <ul className="header-tabs">
        <li className={classnames({ 'is-active': currentTab == 'profile' })}>
          <ScrollLink
            to="details"
            smooth
            onClick={() => setCurrentTab('profile')}
          >
            <img src={profileImg} alt="" />
            <span>Partner Details</span>
          </ScrollLink>
        </li>
        <li className={classnames({ 'is-active': currentTab == 'details' })}>
          <ScrollLink
            to="data-dashboard"
            smooth
            onClick={() => setCurrentTab('details')}
          >
            <img src={dataImg} alt="" />
            <span>Data Dashboard</span>
          </ScrollLink>
        </li>
        {hasSchools && (
          <li className={classnames({ 'is-active': currentTab == 'partners' })}>
            <ScrollLink
              to="partners"
              smooth
              onClick={() => setCurrentTab('partners')}
            >
              <img src={schoolImg} alt="" />
              <span>School Partners</span>
            </ScrollLink>
          </li>
        )}
        {hasPrograms && (
          <li className={classnames({ 'is-active': currentTab == 'programs' })}>
            <ScrollLink
              to="programs"
              smooth
              onClick={() => setCurrentTab('programs')}
            >
              <img src={artsImg} alt="" />
              <span>Programs</span>
            </ScrollLink>
          </li>
        )}
      </ul>
    </nav>
  )
}

PartnerTabs.propTypes = propTypes

PartnerTabs.defaultProps = defaultProps

export default getSet('currentTab', {
  initialValues: {
    currentTab: 'details',
  },
})(PartnerTabs)
