import { curry, isString } from 'lodash/fp'
import { formatObjectToId, parseIdToObject } from 'utils'
// Radio group with other option helpers

// Format the passed in value so it can be ready by the field
function formatForRadioGroupWithOther(key, otherTypeId, value) {
  if (!value) return
  const isOther = value[key] === otherTypeId
  // Instead of undefined, return empty string so other stays selected
  if (isOther) return value.otherText || ''
  return formatObjectToId(key, value)
}

export const format = curry(formatForRadioGroupWithOther)

// Parse the returned value from the field to get it ready for submission
function parseForRadioGroupWithOther(key, otherTypeId, value) {
  const isOther = isString(value)
  const id = isOther ? otherTypeId : value
  const idObject = parseIdToObject(key, id)
  if (!isOther) return idObject
  return { ...idObject, otherText: value }
}

export const parse = curry(parseForRadioGroupWithOther)

export function isOtherOption(option) {
  return option.key.toLowerCase().includes('other')
}
