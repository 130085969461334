import React from 'react'

function FlagIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className="flag-icon"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#5B5F66"
          fillRule="nonzero"
          d="M6.89333333 1.41176471L6.6 0 0 0 0 12 1.46666667 12 1.46666667 7.05882353 5.57333333 7.05882353 5.86666667 8.47058824 11 8.47058824 11 1.41176471z"
          transform="translate(5 5)"
        ></path>
      </g>
    </svg>
  )
}

export default FlagIcon
