import { combineReducers } from 'redux'
import {
  reducer as accountReducer,
  reducerKey as accountReducerKey,
} from './account'
import {
  reducer as surveyReducer,
  reducerKey as surveyReducerKey,
} from './survey'
import {
  reducer as dashboardReducer,
  reducerKey as dashboardReducerKey,
} from './dashboard'
import { reducer as userReducer, reducerKey as userReducerKey } from './user'
import {
  reducer as summaryReducer,
  reducerKey as summaryReducerKey,
} from './summary'
import {
  reducer as globalReducer,
  reducerKey as globalReducerKey,
} from './global-reducer'

const reducerKey = 'schoolPortal'

// Combine reducers
const reducer = combineReducers({
  [accountReducerKey]: accountReducer,
  [surveyReducerKey]: surveyReducer,
  [userReducerKey]: userReducer,
  [globalReducerKey]: globalReducer,
  [dashboardReducerKey]: dashboardReducer,
  [summaryReducerKey]: summaryReducer,
})

export { reducer, reducerKey }
