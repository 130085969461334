import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'components'
import { CopyProgramsForm } from '../forms'
import * as Types from 'types'
import { isEmpty } from 'lodash'

const propTypes = {
  programs: PropTypes.arrayOf(Types.program),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  schoolYears: PropTypes.arrayOf(Types.schoolYear).isRequired,
  onClose: PropTypes.func.isRequired,
}

const defaultProps = {
  programs: [],
}

function CopyProgramModal({
  programs,
  onSubmit,
  onSubmitSuccess,
  schoolYears,
  onClose,
}) {
  return (
    <Modal onClose={onClose}>
      <h2>Copy Existing Program</h2>
      <div className="modal-content">
        <p>
          Please note: in the list below, you may see a program with the same
          name listed more than once, but with different school years. This is
          because the same program’s content or focus may vary from year to
          year, but keep the same name.{' '}
        </p>
        <p>
          For example, a program called “World Music” may change its focus from
          African Drumming to American Blues from one year to the next (ex.
          2011-12 and 2012-13). The associated content from the copied school
          year is also copied, not just the program name.
        </p>
        {isEmpty(programs) ? (
          <p>
            <strong>No other programs found.</strong>
          </p>
        ) : (
          <CopyProgramsForm
            programs={programs}
            schoolYears={schoolYears}
            onSubmit={onSubmit}
            onSubmitSuccess={onSubmitSuccess}
          />
        )}
      </div>
    </Modal>
  )
}

CopyProgramModal.propTypes = propTypes
CopyProgramModal.defaultProps = defaultProps

export default CopyProgramModal
