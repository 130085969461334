import React from 'react'
import { compose } from 'redux'
// import { connect } from 'react-redux'
// import PropTypes from 'prop-types'
import { SubmitButton, Select } from 'lp-components'
import { lpForm } from 'lp-form'
import { Field, propTypes as formPropTypes } from 'redux-form'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

const grades = [
  'pk',
  'k',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
]

function GradesServedForm({ handleSubmit, pristine, submitting }) {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="grades"
        placeholder="select"
        component={Select}
        options={grades}
      />
      <SubmitButton {...{ pristine, submitting }}>Submit</SubmitButton>
    </form>
  )
}
GradesServedForm.propTypes = propTypes

GradesServedForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'school-disciplines',
    enableReinitialize: true,
  })
)(GradesServedForm)
