import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { selectors } from '../map/search/reducer'
import { routerActions } from 'react-router-redux'
import { buildSearchQueryString, useCommunity, useCommunityText } from 'utils'
import { Content, Header, Footer, RenderedHtml } from 'components'
import { SchoolCard, PartnerCard } from './components'
import { get } from 'lodash'
import { selectors as globalSelectors } from 'global-reducer'
import { SearchForm } from '../map/search/forms'

const propTypes = {
  children: PropTypes.node,
  fetchSearchOptions: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  searchOptions: PropTypes.object,
  mostRecentPublishedSchoolYear: Types.schoolYear.isRequired,
  schoolPortalEnabled: PropTypes.bool.isRequired,
  partnerPortalEnabled: PropTypes.bool.isRequired,
}

const defaultProps = {
  children: null,
  searchOptions: null,
}

function CommunityHome({
  children,
  push,
  fetchSearchOptions,
  searchOptions,
  mostRecentPublishedSchoolYear,
  schoolPortalEnabled,
  partnerPortalEnabled,
}) {
  const community = useCommunity()
  const t = useCommunityText()
  const homeHero = get(community, 'defaultBranding.mapHero.src')
  const homeHeroAttribution = get(
    community,
    'defaultBranding.mapHero.attribution'
  )

  const blockList = useMemo(() => {
    return get(community, 'search.options.blockList', [])
  }, [community])

  useEffect(() => {
    fetchSearchOptions({ blockList })
  }, [])

  const search = useCallback((params) => {
    return push({
      pathname: '/search',
      search: buildSearchQueryString(params),
    })
  })

  const tagline = t('map.tagline') || t('tagline')

  return (
    <React.Fragment>
      <div className="map">
        <Header />
        <Content id="content">
          <header className="map-page-header">
            <div className="content-block-container">
              <div className="text-block">
                <RenderedHtml tag="h1">{tagline}</RenderedHtml>
              </div>
            </div>
            {homeHero && (
              <div className="map-hero-container">
                <div
                  className="image-block"
                  style={{ background: `url(${homeHero}) no-repeat center` }}
                />
                {homeHeroAttribution && (
                  <span className="image-attribution">
                    {homeHeroAttribution}
                  </span>
                )}
              </div>
            )}
          </header>
          <section className="search-block-widget">
            <div className="content-block-container card">
              <svg
                width="82px"
                height="82px"
                viewBox="0 0 82 82"
                version="1.1"
                className="location-image"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="Group-Copy" transform="translate(3.000000, 3.000000)">
                    <circle
                      stroke="#FFFFFF"
                      strokeWidth="6"
                      fill="#000"
                      cx="38"
                      cy="38"
                      r="38"
                    ></circle>
                    <path
                      d="M38,19 C45.1797017,19 51,25.1670054 51,32.7743921 C51,40.3817788 39.3,57.5682978 38,57.5682978 C36.7,57.5682978 25,40.3817788 25,32.7743921 C25,25.1670054 30.8202983,19 38,19 Z M38.2,27 C35.3281193,27 33,29.4668022 33,32.5097568 C33,35.5527115 35.3281193,38.0195137 38.2,38.0195137 C41.0718807,38.0195137 43.4,35.5527115 43.4,32.5097568 C43.4,29.4668022 41.0718807,27 38.2,27 Z"
                      fill="#FFFFFF"
                    ></path>
                  </g>
                </g>
              </svg>{' '}
              <h2>{t('map.welcomeSearchHeader')}</h2>
              <div className="search-form">
                <SearchForm
                  name="index-search-formm"
                  searchOptions={searchOptions}
                  initialValues={{
                    type: Types.SCHOOL_TYPE,
                    schoolYear: get(mostRecentPublishedSchoolYear, 'id'),
                  }}
                  onSubmit={search}
                />
              </div>
            </div>
          </section>
          <section className="cards-block-container">
            <SchoolCard schoolPortalEnabled={schoolPortalEnabled} />
            <PartnerCard partnerPortalEnabled={partnerPortalEnabled} />
          </section>
          <section className="landing-content-block-container">
            <div className="colored-block grey-block">
              <div className="inner">
                <RenderedHtml tag="h2">{t('map.welcomeHeader')}</RenderedHtml>
                <RenderedHtml>{t('map.welcomeMessage')}</RenderedHtml>
              </div>
            </div>
          </section>
          {/* Community Specific Content */}
          {children}
        </Content>
        <Footer hideLogins />
      </div>
    </React.Fragment>
  )
}

CommunityHome.propTypes = propTypes
CommunityHome.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    searchOptions: selectors.searchOptions(state),
    mostRecentPublishedSchoolYear:
      globalSelectors.mostRecentPublishedSchoolYear(state),
  }
}

const mapDispatchToProps = {
  push: routerActions.push,
  fetchSearchOptions: apiActions.fetchSearchOptions,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CommunityHome
)
