import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Input, Select, SubmitButton, ButtonArea } from 'lp-components'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { SingleAttachmentInput, AttachmentsLabel } from 'components'
import { useCommunity, getResourceAttachmentUploadPath } from 'utils'

const propTypes = {
  ...formPropTypes,
  isEditing: PropTypes.bool.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  attachableOptions: PropTypes.arrayOf(Types.attachableOption).isRequired,
  onCancel: PropTypes.func.isRequired,
}

const defaultProps = {}

function AttachmentForm({
  handleSubmit,
  submitting,
  isEditing,
  flashSuccessMessage,
  flashErrorMessage,
  attachableOptions,
  onCancel,
}) {
  const community = useCommunity()
  const communityId = community.id

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field name="name" label="Resource Name" component={Input} required />
      <Field
        name="attachable"
        label="Upload To"
        placeholder="Select"
        component={Select}
        options={attachableOptions}
        required
      />
      {!isEditing && (
        <Field
          name="attachment"
          labelComponent={AttachmentsLabel}
          component={SingleAttachmentInput}
          uploadPath={getResourceAttachmentUploadPath(communityId)}
          label="Add File"
          flashSuccessMessage={flashSuccessMessage}
          flashErrorMessage={flashErrorMessage}
          className="attachment-field keep-bottom-margin"
          required
        />
      )}
      <ButtonArea>
        <SubmitButton {...{ submitting }}>
          {isEditing ? 'Save Changes' : 'Publish'}
        </SubmitButton>
        <button type="button" className="button-grey-light" onClick={onCancel}>
          Cancel
        </button>
      </ButtonArea>
    </form>
  )
}

AttachmentForm.propTypes = propTypes
AttachmentForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'attachment',
    constraints: {
      name: {
        presence: true,
      },
      attachable: {
        presence: { message: '^Select where the file should be uploaded' },
      },
    },
  })
)(AttachmentForm)
