import React from 'react'

function OutOfSchoolIcon() {
  return (
    <svg viewBox="0 0 50 50">
      <path fill="#FFFFFF" d="M25,0A25,25,0,1,0,50,25,25,25,0,0,0,25,0Z" />
      <path
        fill="#204A82"
        className="fill"
        d="M8,18.89l.2-.21a4.8,4.8,0,0,1-.85-1L3.26,19.73l-3,1.49A26.07,26.07,0,0,0,0,25c0,.8,0,1.58.11,2.36l4.31-4.6Z"
      />
      <path
        fill="#204A82"
        className="fill"
        d="M25,50a24.94,24.94,0,0,0,18.3-8H6.72A24.9,24.9,0,0,0,25,50Z"
      />
      <path
        fill="#204A82"
        className="fill"
        d="M.83,31.36l5.24-7.23,3.11-4.29.25-.34.21.1a4.08,4.08,0,0,0,.67.23h0l-.09.54-1,5.32L7.3,36.33l-.52,2.75h3.84l.12-2.34L11.37,26l.32-5.4,0-.53a5.83,5.83,0,0,0,.84-.09l1.18,4.19.7,2.46,2-1.54-.73-1.64-1.73-4a5.34,5.34,0,0,0,.84-.56l1.86,2L19,23.19l.79-.59V20.85L18,19.47l-2.14-1.66h0a5.46,5.46,0,0,0,.48-.85.88.88,0,0,0,.05-.15l2.11.79v-.3l.51-.45,1.14-1-3.42-.41a4.9,4.9,0,0,0-.05-1.38l1.41-.23L23.37,13l.65-.56.95-.9v2L20,18v.49h1.25v3l2.54-1.9L25,18.65V20.5L14,28.79v.54h2.1v9.74h6.2V30.34H25v8.74H27.7V30.34H25V20.47l11.06,8.32v.49h-2.2v9.8h11.8a25,25,0,1,0-41.31,0h0A25.09,25.09,0,0,1,.83,31.36Z"
      />
      <path
        fill="#FFFFFF"
        d="M15.56,11.72a5,5,0,0,1,.78,1.3l1.38-.48,5.35-1.94L25,9.88V4.6l-9.41,7.12Z"
      />
      <path
        fill="#FFFFFF"
        d="M12.05,9.89l.09-6.32a25.49,25.49,0,0,0-3,2.14l1,3.24L10.47,10a4.66,4.66,0,0,1,1.18-.16Z"
      />
      <path
        fill="#FFFFFF"
        d="M8.22,11.22a4.89,4.89,0,0,1,1.13-.79l-.48-1L7.63,7A24.7,24.7,0,0,0,5.86,8.94l2.36,2.27Z"
      />
      <path
        fill="#FFFFFF"
        d="M4.42,22.76,8,18.89l.2-.21a4.8,4.8,0,0,1-.85-1L3.26,19.73l-3,1.49A26.07,26.07,0,0,0,0,25c0,.8,0,1.58.11,2.36Z"
      />
      <path
        fill="#FFFFFF"
        d="M6.76,16.28a5.18,5.18,0,0,1-.18-1.48l-3.12-.27-1.11-.09A24.79,24.79,0,0,0,.94,18.25l1.49-.5Z"
      />
      <path
        fill="#FFFFFF"
        d="M6.81,13.45A3.24,3.24,0,0,1,7,12.93a4.83,4.83,0,0,1,.33-.64L4.54,10.64c-.45.64-.86,1.29-1.25,2l.47.11Z"
      />
      <path
        fill="#FFFFFF"
        d="M14.65,10.86,23.24.08a24.61,24.61,0,0,0-7.69,1.78l-2.21,8.31h0a1.75,1.75,0,0,1,.32.12A4.82,4.82,0,0,1,14.65,10.86Z"
      />
      <circle fill="#FFFFFF" cx="38.84" cy="14.85" r="3.6" />
      <polygon
        fill="#FFFFFF"
        points="24.99 18.63 25.01 18.62 28.77 21.44 28.77 18.47 30.05 18.46 30.02 17.96 24.99 13.58 24.99 16.45 24.99 18.63"
      />
      <path
        fill="#FFFFFF"
        d="M8.35,13.52a3.6,3.6,0,0,1,3.3-2.17,3.69,3.69,0,0,1,1.43.29,3.61,3.61,0,0,1-1.43,6.91,3.56,3.56,0,0,1-1.43-.3A3.6,3.6,0,0,1,8.35,13.52Z"
      />
      <path
        fill="#FFFFFF"
        d="M33.86,39.08v-9.8h2.2v-.49L25,20.47v9.87H27.7v8.73H25V30.34H22.25v8.74h-6.2V29.34H14v-.54L25,20.5V18.65l-1.22.92-2.54,1.9v-3H20V18l5-4.38v-2l-.95.9-.65.56-5.31.82-1.41.23a4.9,4.9,0,0,1,.05,1.38l3.42.41-1.14,1-.51.45v.3l-2.11-.79a.88.88,0,0,1-.05.15,5.46,5.46,0,0,1-.48.85h0L18,19.47l1.77,1.38V22.6l-.79.59-2.22-2.36-1.86-2a5.34,5.34,0,0,1-.84.56l1.73,4L16.49,25l-2,1.54-.7-2.46-1.18-4.19a5.83,5.83,0,0,1-.84.09l0,.53L11.37,26l-.63,10.79-.12,2.34H6.78l.52-2.75L9.24,25.69l1-5.32.09-.54h0a4.08,4.08,0,0,1-.67-.23l-.21-.1-.25.34L6.07,24.13.83,31.36a25.09,25.09,0,0,0,3.53,7.72h0c.35.5.71,1,1.09,1.47s.84,1,1.28,1.48H43.3a25.63,25.63,0,0,0,2.36-3Z"
      />
    </svg>
  )
}

export default OutOfSchoolIcon
