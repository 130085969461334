import { combineReducers } from 'redux'
import { reducer as homeReducer, reducerKey as homeReducerKey } from './home'
import {
  reducer as profileReducer,
  reducerKey as profileReducerKey,
} from './profile'
import { reducer as forumReducer, reducerKey as forumReducerKey } from './forum'
import {
  reducer as directoryReducer,
  reducerKey as directoryReducerKey,
} from './directory'
import {
  reducer as moderatorReducer,
  reducerKey as moderatorReducerKey,
} from './moderator'
import {
  reducer as communityOfPracticeReducer,
  reducerKey as communityOfPracticeReducerKey,
} from './community-of-practice'
import {
  reducer as globalReducer,
  reducerKey as globalReducerKey,
} from './global-reducer'

const reducerKey = 'educatorPortal'

// Combine reducers
const reducer = combineReducers({
  [homeReducerKey]: homeReducer,
  [profileReducerKey]: profileReducer,
  [globalReducerKey]: globalReducer,
  [directoryReducerKey]: directoryReducer,
  [forumReducerKey]: forumReducer,
  [moderatorReducerKey]: moderatorReducer,
  [communityOfPracticeReducerKey]: communityOfPracticeReducer,
})

export { reducer, reducerKey }
