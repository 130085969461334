import React from 'react'
import { Route } from 'react-router'
import * as Views from './views'
import Layout from './layout'

const Routes = (
  <Route component={Layout}>
    <Route path="sign-out" component={Views.SignOut} />
    <Route path="switch-account/:id" component={Views.SwitchAccount} />
  </Route>
)

export default Routes
