import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { lpForm } from 'lp-form'
import { Field, propTypes as formPropTypes, FormSection } from 'redux-form'
import { persistSubmitSucceeded } from 'utils'
import { SubmitButton, ButtonArea,  MaskedInput } from 'lp-components'
import { AutoSaveStatusIndicator } from 'components'
import { memoize } from 'lodash'

const propTypes = {
    ...formPropTypes,
    ariaLabelledby: PropTypes.string.isRequired,
    threshold: PropTypes.object.isRequired,
}

const defaultProps = {}

function validateBudget(value) {
    if (typeof value == 'string') {
        if (value.includes('.'))
            return 'Funding must be a whole number. Decimal values are not allowed'
    }
}

function warnFundingAmount(value, threshold) {
    if (!value) return
    const maxDistictFundingDollars = Math.round(
        Number(threshold.maxDistrictFundingCents) / 100
    )
    if (value > maxDistictFundingDollars)
        return `Value should be less than $${maxDistictFundingDollars}`
}

const warnCourses = memoize((threshold) => {
    if (!threshold) return
    return function warn(value) {
        return warnFundingAmount(value, threshold)
    }
})

function BudgetForm({
                        handleSubmit,
                        saved,
                        isSaving,
                        submitting,
                        ariaLabelledby,
                        threshold,
                        change,
                    }) {
    return (
        <form onSubmit={handleSubmit}>
            <AutoSaveStatusIndicator {...{ saveSucceeded: saved }} />
            <fieldset disabled={isSaving}>
                <FormSection name="detail">
                    <Field
                        name="districtFunding.fractional"
                        label={false}
                        validate={validateBudget}
                        component={MaskedInput}
                        className="dollar-amount col-4"
                        aria-labelledby={ariaLabelledby}
                        warn={warnCourses(threshold)}
                        maskOptions={{
                            numeral: true,
                            numeralPositiveOnly: true,
                            rawValueTrimPrefix: true,
                        }}
                        onChange={(e) => {
                            // return the raw value without mask formatting
                            e.preventDefault()
                            change("detail.districtFunding.fractional", e.target.rawValue)
                        }}
                        onBlur={(e) => e.preventDefault()}
                    />
                </FormSection>
            </fieldset>
            <ButtonArea>
                <SubmitButton {...{ pristine: saved, submitting }}>
                    Save Response
                </SubmitButton>
            </ButtonArea>
        </form>
    )
}

BudgetForm.propTypes = propTypes
BudgetForm.defaultTypes = defaultProps

export default compose(
    lpForm({
        name: 'school-budget',
        enableReinitialize: true,
    }),
    persistSubmitSucceeded()
)(BudgetForm)
