import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { pure } from 'recompose'
import { RenderedHtml, ActionMenu } from 'components'
import { PostedDate } from 'educator-portal-components'
import { EditIcon, TrashIcon } from 'components/icons/basic'

const propTypes = {
  announcement: Types.announcement.isRequired,
  isModerator: PropTypes.bool.isRequired,
  requestDeleteAnnouncement: PropTypes.func.isRequired,
  editOnSelect: PropTypes.func.isRequired,
}

const defaultProps = {}

function Announcement({
  announcement,
  requestDeleteAnnouncement,
  editOnSelect,
  isModerator,
}) {
  const { id, content, createdAt } = announcement

  const announcementActions = [
    {
      icon: EditIcon,
      label: 'Edit Announcement',
      onSelect: () => editOnSelect(announcement),
    },
    {
      icon: TrashIcon,
      label: 'Delete Announcement',
      onSelect: () => requestDeleteAnnouncement(id),
    },
  ]

  return (
    <div className="card callout-card">
      <div className="text-block rich-text">
        <RenderedHtml>{content}</RenderedHtml>
        <PostedDate date={createdAt} />
      </div>
      {isModerator && <ActionMenu actions={announcementActions} />}
    </div>
  )
}

Announcement.propTypes = propTypes
Announcement.defaultProps = defaultProps

export default pure(Announcement)
