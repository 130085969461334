import React from 'react'
import lock from 'images/lock.svg'
import dualLogo from 'images/artlook-and-ingenuity.png'

function Sandbox() {
  return (
    <div>
      <nav className="side-navigation">
        <div>
          <p>Artlook + Ingenuity</p>
        </div>
        <ul>
          <li>
            <a href="">Dashboard</a>
          </li>
          <li>
            <a href="">Organization</a>
          </li>
          <li>
            <a href="">Programs</a>
          </li>
          <li>
            <a href="">School Partnerships</a>
          </li>
          <li>
            <a href="">Account</a>
          </li>
          <li>
            <a href="">Logout</a>
          </li>
        </ul>
      </nav>

      <main className="content portal-wrapper">
        <div className="portal-header">
          <div className="content-block-container">
            <h1>Reports</h1>
          </div>
        </div>
        <div className="portal-subnav">
          <nav className="content-block-container">
            <ul>
              <li className="is-active">
                <a>Reports</a>
              </li>
              <li>
                <a>Feedback</a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="content-block-container">
          <section className="section-block">
            <div className="section-header">
              <h3>artlook Profile Report</h3>
              <p>
                The artlook Partners Profile report will summarize your current
                program offerings in schools and will serve as a useful resource
                for you to share with schools, arts liaisons, parents, funders,
                and board members to grow your programs. It is populated by the
                arts partnership data supplied on this site in conjunction with
                CPS data procured by Ingenuity’s data-sharing agreement with the
                District.
              </p>
              <p>
                The numbers shown on the artlook Partners Profile report sum the
                self-reported information entered by your organization (e.g.
                number of partnerships, programs) and are a quantitative roll-up
                of your services. This report does not assess quality of
                programs or partnerships. It is meant to be used for
                informational purposes only.
              </p>
            </div>
            <ul className="reports-block">
              <li>
                <p>
                  <strong>artlook Profile for 2011-12: </strong>
                </p>
                <a className="button-primary-outline">Download Report</a>
              </li>
              <li>
                <p>
                  <strong>artlook Profile for 2012-13: </strong>
                </p>
                <a className="button-primary-outline">Download Report</a>
              </li>
              <li>
                <p>
                  <strong>artlook Profile for 2013-14: </strong>
                </p>
                <a className="button-primary-outline">Download Report</a>
              </li>
              <li>
                <p>
                  <strong>artlook Profile for 2014-15: </strong>
                </p>
                <a className="button-primary-outline">Download Report</a>
              </li>
              <li>
                <p>
                  <strong>artlook Profile for 2015-16: </strong>
                </p>
                <a className="button-primary-outline">Download Report</a>
              </li>
            </ul>
          </section>
          <section className="section-block">
            <div className="section-header">
              <h3>Feedback</h3>
              <p>
                Please note any special circumstances, feedback or other
                considerations that we should know about your survey responses
                below.
              </p>
            </div>
            <fieldset>
              <textarea name="message"></textarea>
            </fieldset>
            <div className="button-area">
              <button className="button-secondary">Submit Feedback</button>
            </div>
          </section>
        </div>
      </main>

      <main className="content portal-wrapper">
        <div className="portal-header">
          <div className="content-block-container">
            <h1>Organization Details</h1>
          </div>
        </div>
        <div className="portal-subnav">
          <nav className="content-block-container">
            <ul>
              <li>
                <a>Organization</a>
              </li>
              <li>
                <a>Mission</a>
              </li>
              <li>
                <a>Location</a>
              </li>
              <li>
                <a>Staff Contact</a>
              </li>
              <li>
                <a>Disciplines</a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="content-block-container">
          <section className="section-block">
            <div className="section-header">
              <h3>Organization</h3>
            </div>
            <div className="row">
              <fieldset className="col-6">
                <label>
                  Organization Name{' '}
                  <img src={lock} alt="lock" className="lock" />
                </label>
                <span className="input-link">
                  <a href="" className="link-yellow">
                    Request Change
                  </a>
                </span>
                <input type="text" name="" disabled />
              </fieldset>
              <fieldset className="col-6">
                <label>Website Url</label>
                <input type="text" name="" />
              </fieldset>
            </div>
          </section>

          <section className="section-block">
            <div className="section-header">
              <h3>Mission</h3>
            </div>
            <div>
              <fieldset>
                <label>What's your organization's mission?</label>
                <textarea name="message"></textarea>
              </fieldset>
            </div>
          </section>

          <section className="section-block">
            <div className="section-header">
              <h3>Location</h3>
            </div>
            <div className="location-block">
              <div className="address-block">
                <div className="address-form-block">
                  <fieldset>
                    <label>Street Address</label>
                    <input type="text" name="" />
                  </fieldset>
                  <div className="row">
                    <fieldset className="col-5">
                      <label>City</label>
                      <input type="text" name="" />
                    </fieldset>
                    <fieldset className="col-3">
                      <label>State</label>
                      <input type="text" name="" />
                    </fieldset>
                    <fieldset className="col-4">
                      <label>Zip Code</label>
                      <input type="tel" name="" />
                    </fieldset>
                  </div>
                </div>
                <div className="location-details-block">
                  <p>
                    <strong>Community Area: </strong>Loop
                  </p>
                  <p>
                    <strong>Congressional District: </strong>District 7
                  </p>
                  <p>
                    <strong>Ward: </strong>Ward 42
                  </p>
                  <p>
                    <strong>CPS Network (2014-15): </strong>Network 6
                  </p>
                </div>
              </div>
              <div className="map-block">Image of map goes here</div>
            </div>
          </section>

          <section className="section-block">
            <div className="section-header">
              <h3>Staff Contact</h3>
            </div>
            <div className="contact-block">
              <h4>Primary Contact</h4>
              <p>
                Staff member responsible for managing the data for the “After
                School Matters” artlook Partners account.
              </p>
              <div className="contact-fields">
                <fieldset>
                  <label>First Name</label>
                  <input type="text" name="" />
                </fieldset>
                <fieldset>
                  <label>Last Name</label>
                  <input type="text" name="" />
                </fieldset>
                <fieldset>
                  <label>Title</label>
                  <input type="text" name="" />
                </fieldset>
                <fieldset>
                  <label>Phone Number</label>
                  <input type="tel" name="" />
                </fieldset>
                <fieldset>
                  <label>Email</label>
                  <input type="email" name="" />
                </fieldset>
              </div>

              <h4>Additional Contact</h4>
              <p>Receives Artlook and Ingenuity emails.</p>
              <div className="contact-fields">
                <fieldset>
                  <label>First Name</label>
                  <input type="text" name="" />
                </fieldset>
                <fieldset>
                  <label>Last Name</label>
                  <input type="text" name="" />
                </fieldset>
                <fieldset>
                  <label>Title</label>
                  <input type="text" name="" />
                </fieldset>
                <fieldset>
                  <label>Phone Number</label>
                  <input type="tel" name="" />
                </fieldset>
                <fieldset>
                  <label>Email</label>
                  <input type="email" name="" />
                </fieldset>
              </div>
            </div>
          </section>

          <section className="section-block">
            <div className="section-header">
              <h3>
                Which arts disciplines are addressed by your school programs?
              </h3>
              <p>Please check all that apply.</p>
            </div>
            <div></div>
          </section>
        </div>
      </main>

      <main className="content portal-wrapper">
        <div className="portal-header">
          <div className="content-block-container">
            <h1>Programs for 2016-17</h1>
          </div>
        </div>
        <div className="content-block-container">
          <section className="section-block">
            <div className="section-header">
              <h3>List All the Programs You Offer</h3>
              <p>
                Please list all programs your organization offers. Enter
                overarching organization programs (programs such as residencies,
                in-school performances, field trips, etc.) – not an individual
                school’s programs. For example, if you provide a dance residency
                named “Rhythms Around the World” to many schools, you only need
                to enter this program one time, not for each school you’re
                providing the residency to.
              </p>
            </div>
            <div></div>
          </section>
        </div>
      </main>

      <main className="content portal-wrapper">
        <div className="portal-header">
          <div className="content-block-container">
            <h1>School Partnerships for 2016-17</h1>
          </div>
        </div>
        <div className="content-block-container">
          <section className="section-block">
            <div className="section-header">
              <h3>List All Your School Partnerships</h3>
              <p>
                List all schools you worked with at any point during the chosen
                school year. For a list of program definitions, please click
                here or hover your mouse over the names of the program types
                shown in the table header.
              </p>
            </div>
            <div>
              <div className="button-area many-buttons">
                <button className="button-primary-outline">
                  + Add New Partner
                </button>
                <button className="button-primary-outline">
                  Copy partners from other school years
                </button>
              </div>
            </div>
            <div className="hint">
              <p>
                <strong>Tips</strong>
              </p>
              <p>
                Some schools have already reported their partnerships with us.
                If so, they will appear here. You may add or delete new
                partnerships created by you, but not partnerships reported to us
                by the schools. If you cannot find a specific school from our
                search box, please email us the school’s name and the types of
                arts programming you are providing to that school, and we will
                enter this information for you. Please note that only CPS
                schools are tracked in the artlook Partners system. Private and
                non-CPS schools cannot be added.
              </p>
            </div>
          </section>
        </div>
      </main>

      <main className="content portal-wrapper">
        <div className="portal-header">
          <div className="content-block-container">
            <h1>Account</h1>
          </div>
        </div>
        <div className="content-block-container">
          <section className="section-block">
            <div className="section-header">
              <h3>Change Password</h3>
            </div>
            <div className="portal-form">
              <fieldset>
                <label>Current Password</label>
                <input type="email" name="" />
              </fieldset>
              <fieldset>
                <label>New Password</label>
                <input type="email" name="" />
              </fieldset>
              <fieldset>
                <label>Confirm Password</label>
                <input type="email" name="" />
              </fieldset>
              <div className="button-area">
                <button className="button-secondary">Change Password</button>
              </div>
            </div>
          </section>
        </div>
      </main>

      <main className="authentication">
        <div className="image-block">
          <img
            src={dualLogo}
            alt="Artlook + Ingenuity"
            className="logo-block"
          />
          <h1>
            Let’s increase arts education access, equity, and quality in Chicago
            Public Schools.
          </h1>
        </div>
        <div className="content-block">
          <div className="form-block">
            <h1>Forgot Password</h1>
            <fieldset>
              <label>Organization's Name</label>
              <input type="text" name="" />
            </fieldset>
            <div className="button-area">
              <button className="button-secondary">
                Send Reset Instructions
              </button>
            </div>
          </div>
        </div>
      </main>

      <main className="authentication">
        <div className="image-block">
          <img
            src={dualLogo}
            alt="Artlook + Ingenuity"
            className="logo-block"
          />
          <h1>
            Let’s increase arts education access, equity, and quality in Chicago
            Public Schools.
          </h1>
        </div>
        <div className="content-block">
          <div className="form-block">
            <h1>Reset Password</h1>
            <fieldset>
              <label>New Password</label>
              <input type="text" name="" />
            </fieldset>
            <fieldset>
              <label>Confirm New Password</label>
              <input type="text" name="" />
            </fieldset>
            <div className="button-area">
              <button className="button-secondary">Reset Password</button>
            </div>
          </div>
        </div>
      </main>

      <main className="authentication">
        <div className="image-block">
          <img
            src={dualLogo}
            alt="Artlook + Ingenuity"
            className="logo-block"
          />
          <h1>
            Let’s increase arts education access, equity, and quality in Chicago
            Public Schools.
          </h1>
        </div>
        <div className="content-block">
          <div className="form-block">
            <h1>Sign In</h1>
            <fieldset>
              <label>Organization's Name</label>
              <input type="text" name="" />
            </fieldset>
            <fieldset>
              <label>Password</label>
              <span className="input-link">
                <a href="" className="link-yellow">
                  Forgot Password?
                </a>
              </span>
              <input type="text" name="" />
            </fieldset>
            <fieldset className="checkbox">
              <input
                type="checkbox"
                id="checkbox"
                checked="checked"
                value="true"
              />
              <label htmlFor="checkbox">Keep me signed in</label>
            </fieldset>
            <div className="button-area">
              <button className="button-secondary">Sign In</button>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Sandbox
