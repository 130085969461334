import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { ControlledExpandableItem } from 'components/expandable-item'
import { useOnOutsideClick } from 'utils'
import NotificationCenter from './notification-center'
import BellIcon from './icons/basic/bell-icon'

const propTypes = {
  openPreferencesModal: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(Types.notification).isRequired,
  markAllAsRead: PropTypes.func.isRequired,
}

const defaultProps = {
  notifications: [],
}

// This will be moved to utils
const displayBadgeIndicator = (notificationArray) => {
  return notificationArray.filter(
    (notification) => notification.isUnread === true
  ).length
}

function NotificationsButton({ unreadCount }) {
  return (
    <React.Fragment>
      <BellIcon white />
      {unreadCount > 0 && (
        <span className="notifications-indicator">{unreadCount}</span>
      )}
    </React.Fragment>
  )
}

function NotificationsMenu({
  openPreferencesModal,
  notifications,
  markAllAsRead,
}) {
  const [expanded, setExpanded] = useState(false)
  const ref = useOnOutsideClick(() => setExpanded(false))
  const unreadCount = displayBadgeIndicator(notifications)

  const handleMarkAllAsReadCTA = () => {
    const notificationIds = notifications.flatMap(
      (notification) => notification.notificationIds
    )
    markAllAsRead(notificationIds)
  }

  return (
    <div>
      <div className="notifications-menu">
        <ControlledExpandableItem
          headerComponent={NotificationsButton}
          expanded={expanded}
          setExpanded={setExpanded}
          forwardRef={ref}
          unreadCount={unreadCount}
        >
          <NotificationCenter
            openPreferencesModal={openPreferencesModal}
            handleMarkAllAsReadCTA={handleMarkAllAsReadCTA}
            unreadCount={unreadCount}
          />
        </ControlledExpandableItem>
      </div>
    </div>
  )
}

NotificationsMenu.propTypes = propTypes
NotificationsMenu.defaultProps = defaultProps

export default React.memo(NotificationsMenu)
