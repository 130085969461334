import { applyMiddleware, compose, createStore, combineReducers } from 'redux'
import { routerReducer, routerMiddleware } from 'react-router-redux'
import { browserHistory } from 'react-router'
import { reducer as formReducer } from 'redux-form'
import { reducer as modalReducer } from 'redux-modal'
import {
  reducer as flashReducer,
  middleware as flashMiddleware,
} from 'redux-flash'
import { middleware as apiMiddleware } from 'api'
import { reducer as apiReducer } from 'lp-redux-api'
import { reducer as rootReducer, reducerKey as rootKey } from './reducer'
import thunkMiddleware from 'redux-thunk'

function initializeStore(initialState = {}) {
  // Combine reducers
  const reducer = combineReducers({
    form: formReducer,
    modal: modalReducer,
    [rootKey]: rootReducer,
    routing: routerReducer,
    api: apiReducer,
    flash: flashReducer,
  })
  // Add support for the Redux Dev Tools in chrome
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  // Compose enhancers
  const enhancers = composeEnhancers(
    applyMiddleware(
      apiMiddleware,
      flashMiddleware(),
      routerMiddleware(browserHistory),
      thunkMiddleware
    )
  )
  // Initialize store
  const store = createStore(reducer, initialState, enhancers)
  // Make store available globally
  window.store = store

  return store
}

export default initializeStore
