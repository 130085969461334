import { isEmpty, negate } from 'lodash'

// Remove empty values from an array

const isFull = negate(isEmpty)

function filterEmpty(arr) {
  if (!arr) return arr
  return arr.filter(isFull)
}

export default filterEmpty
