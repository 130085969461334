import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Spinner } from 'lp-components'
import * as apiActions from 'api-actions'
import { selectors } from './reducer'

const propTypes = {
  fetchCommunityOfPractice: PropTypes.func.isRequired,
  communityOfPractice: Types.communityOfPractice,
  children: PropTypes.node.isRequired,
  // provided by react router
  params: PropTypes.object.isRequired,
}

const defaultProps = {
  communityOfPractice: null,
}

function CommunityOfPracticeLayout({
  fetchCommunityOfPractice,
  communityOfPractice,
  children,
  params: { id },
}) {
  const communityOfPracticeId = parseInt(id)

  useEffect(() => {
    fetchCommunityOfPractice(communityOfPracticeId)
  }, [communityOfPracticeId])

  if (!communityOfPractice || communityOfPractice.id !== communityOfPracticeId)
    return <Spinner />

  return children
}

CommunityOfPracticeLayout.propTypes = propTypes
CommunityOfPracticeLayout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    communityOfPractice: selectors.communityOfPractice(state),
  }
}

const mapDispatchToProps = {
  fetchCommunityOfPractice: apiActions.fetchCommunityOfPractice,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CommunityOfPracticeLayout
)
