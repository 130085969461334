import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { pure } from 'recompose'
import { Select, Spinner } from 'lp-components'
import { PostsList } from '../components'
import { PostsSearchForm } from '../forms'
import { pluralize, displaySubmitFailure } from 'utils'
import { isEmpty } from 'lodash'
import classnames from 'classnames'

const propTypes = {
  searchOptions: Types.postsFilterOptions.isRequired,
  query: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  searchPosts: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool.isRequired,
  postsCount: PropTypes.number.isRequired,
  postsCountText: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  sortType: PropTypes.string.isRequired,
  // check PostsList for "rest" of required props
}

const defaultProps = {}

function Discussions({
  searchOptions,
  query,
  filters,
  searchPosts,
  showSpinner,
  postsCount,
  postsCountText,
  sortType,
  ...rest
}) {
  const [filtersVisibleOnMobile, setFiltersVisibleOnMobile] = useState(false)

  return (
    <React.Fragment>
      <button
        type="button"
        className="link-black filter-toggle advanced-filters"
        aria-expanded={filtersVisibleOnMobile}
        aria-controls="filter-content"
        onClick={() => setFiltersVisibleOnMobile(true)}
      >
        Advanced Filters +
      </button>
      <div
        id="educator-search"
        className={classnames({
          'is-active': filtersVisibleOnMobile,
        })}
      >
        <button
          type="button"
          className="link-black close"
          aria-label="Close Filters"
          onClick={() => setFiltersVisibleOnMobile(false)}
        >
          ×
        </button>
        <PostsSearchForm
          filterOptions={searchOptions}
          initialValues={{
            query,
            filters,
          }}
          onSubmit={searchPosts}
          onSubmitFail={displaySubmitFailure}
        />
      </div>
      <div>
        <div className="flex-horizontal flex-space-between flex-center">
          <div>
            <span
              role="status"
              className="visually-hidden"
              aria-live="polite"
              aria-atomic="true"
            >
              {showSpinner
                ? 'Loading'
                : `Loading completed with ${postsCount} ${pluralize(
                    'discussion',
                    postsCount
                  )}`}
            </span>
            <p>
              <strong>
                Showing {postsCountText} {pluralize('Discussion', postsCount)}
              </strong>
            </p>
          </div>
          {!showSpinner && (
            <div className="discussions-sort">
              <Select
                name="resultsSortType"
                className="sort"
                label="Sorted by:"
                options={
                  isEmpty(query)
                    ? Types.DEFAULT_POST_SORT_OPTIONS
                    : Types.SEARCH_POST_SORT_OPTIONS
                }
                input={{ value: sortType, name: 'resultsSortType' }}
                onChange={(e) =>
                  searchPosts({
                    query,
                    filters,
                    sortType: e.target.value,
                  })
                }
                meta={{}}
              />
            </div>
          )}
        </div>
        {showSpinner ? (
          <Spinner />
        ) : (
          <div id="discussion-posts">
            <PostsList
              setResultsPage={(newPage) => {
                return searchPosts({
                  query,
                  filters,
                  page: newPage,
                  sortType,
                })
              }}
              {...rest}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

Discussions.propTypes = propTypes
Discussions.defaultProps = defaultProps

export default pure(Discussions)
