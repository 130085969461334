import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { pure } from 'recompose'
import { get } from 'lodash'

const propTypes = {
  remove: PropTypes.func.isRequired,
  value: PropTypes.string,
  image: Types.image,
}

const defaultProps = {
  value: null,
  image: null,
}

function RemovableThumbnail({ value, image, remove }) {
  // do not render if image has been removed (destroyed)
  if (!value || get(image, '_destroy')) return null

  return (
    <div className="image-preview">
      <div className="image-thumbnail">
        <img src={value} alt="" />
      </div>
      <div className="text-block">
        <p>{get(image, 'filename', 'Uploaded Logo or Photo')}</p>
        <button
          type="button"
          className="button-warn button-small"
          onClick={remove}
        >
          Remove
        </button>
      </div>
    </div>
  )
}

RemovableThumbnail.propTypes = propTypes
RemovableThumbnail.defaultProps = defaultProps

export default pure(RemovableThumbnail)
