import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { pure } from 'recompose'
import { isEmpty } from 'lodash'
import { communityEnumerable } from 'utils'
import CommunityQuestion from './community-question'

const propTypes = {
  children: PropTypes.func.isRequired,
  enumerableOptions: PropTypes.arrayOf(Types.communityEnumerable),
  setShowInfoModalWithContent: PropTypes.func.isRequired,
  questionName: PropTypes.string.isRequired,
  surveySchoolYearNumber: PropTypes.string,
}

const defaultProps = {
  surveySchoolYearNumber: null,
}

function CommunityEnumerableQuestion({
  children,
  enumerableOptions,
  setShowInfoModalWithContent,
  questionName,
  surveySchoolYearNumber,
}) {
  return (
    <CommunityQuestion
      questionName={questionName}
      surveySchoolYearNumber={surveySchoolYearNumber}
    >
      {({ questionOption: { optionName, infoLinkText }, ariaLabelledby }) => {
        const modalContent = useMemo(() => {
          return communityEnumerable.getInfoModalContent(enumerableOptions)
        }, [enumerableOptions])
        return (
          <React.Fragment>
            {!isEmpty(modalContent) && (
              <button
                type="button"
                className="link-secondary"
                onClick={() => setShowInfoModalWithContent(modalContent)}
              >
                {infoLinkText}
              </button>
            )}
            {children(optionName, { ariaLabelledby })}
          </React.Fragment>
        )
      }}
    </CommunityQuestion>
  )
}

CommunityEnumerableQuestion.propTypes = propTypes
CommunityEnumerableQuestion.defaultProps = defaultProps

export default pure(CommunityEnumerableQuestion)
