import * as Types from 'types'
import { isEmpty } from 'lodash'

// Set the default sort type for discussions based on query status, if one has not already been set

function getSortType(searchParams) {
  const { sortType, query } = searchParams
  return (
    sortType ||
    (isEmpty(query) ? Types.NEWEST_SORT_TYPE : Types.RELEVANCE_SORT_TYPE)
  )
}

function setDiscussionsSortType(searchParams) {
  const sortType = getSortType(searchParams)
  return { ...searchParams, sortType }
}

export default setDiscussionsSortType
