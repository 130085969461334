import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Modal } from 'components'
import { AttachmentForm } from '../forms'
import { displaySubmitFailure, serializeAttachmentForFormField } from 'utils'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  createOrUpdateAttachment: PropTypes.func.isRequired,
  attachment: Types.attachment,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  attachableOptions: PropTypes.arrayOf(Types.attachableOption).isRequired,
  initialAttachable: PropTypes.string,
  onSubmitFail: PropTypes.func,
  onSubmitSuccess: PropTypes.func.isRequired,
}

const defaultProps = {
  attachment: null,
  initialAttachable: null,
  onSubmitFail: displaySubmitFailure,
}

function ResourcesAttachmentModal({
  onClose,
  attachment,
  createOrUpdateAttachment,
  initialAttachable,
  ...rest
}) {
  const isEditing = !!attachment

  const formatInitialValues = () => {
    const initialValues = {
      attachable: initialAttachable,
      attachment: serializeAttachmentForFormField(attachment),
    }

    if (!isEditing) return initialValues

    return {
      ...initialValues,
      id: attachment.id,
      name: attachment.name,
    }
  }

  return (
    <Modal onClose={onClose}>
      <h2>{isEditing ? 'Update Resource' : 'Add New Resource'}</h2>
      <AttachmentForm
        initialValues={formatInitialValues()}
        isEditing={isEditing}
        onSubmit={createOrUpdateAttachment}
        onCancel={onClose}
        {...rest}
      />
    </Modal>
  )
}

ResourcesAttachmentModal.propTypes = exact(propTypes)
ResourcesAttachmentModal.defaultProps = defaultProps

export default pure(ResourcesAttachmentModal)
