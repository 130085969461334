import userflow from 'userflow.js'

function initializeAuthenticatedUserflow(userDetails) {
  if (window.process.env.NODE_ENV !== 'production' || !userDetails) return

  userflow.init(process.env.USERFLOW_TOKEN)
  userflow.identify(userDetails.id, {
    email: userDetails.email,
  })
}

export default initializeAuthenticatedUserflow
