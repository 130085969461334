import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  containerProps: PropTypes.object.isRequired,
  children: PropTypes.node,
}

function SuggestionList({ containerProps, children }) {
  return (
    <div {...containerProps} aria-label="List of suggestions">
      <div className="autocomplete is-active">{children}</div>
    </div>
  )
}

SuggestionList.propTypes = propTypes

export default SuggestionList
