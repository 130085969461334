import { startCase, isObject } from 'lodash'

function formatOptions(options = []) {
  return options.map((option) => {
    if (!isObject(option)) return startCase(option)
    const key = option.displayKey || startCase(option.key)
    return { key: key, value: option.value }
  })
}

export default formatOptions
