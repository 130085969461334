import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { Link } from 'react-router'
import { pure } from 'recompose'
import {
  BookmarkButton,
  PostTags,
  LikeButton,
} from 'educator-portal-components'
import { HighlightText } from 'components'
import { CommentIcon } from 'components/icons/basic'
import { pluralize, formatDate, getLikeId } from 'utils'
import { get, find, isEmpty, map } from 'lodash'

const propTypes = {
  post: Types.post.isRequired,
  postRootPath: PropTypes.string.isRequired,
  addBookmark: PropTypes.func.isRequired,
  removeBookmark: PropTypes.func.isRequired,
  addLike: PropTypes.func.isRequired,
  destroyLike: PropTypes.func.isRequired,
  bookmarks: PropTypes.arrayOf(Types.bookmark).isRequired,
  userDetails: Types.user.isRequired,
}

const defaultProps = {}

function ListPostCard({
  post,
  postRootPath,
  addBookmark,
  removeBookmark,
  addLike,
  destroyLike,
  bookmarks,
  userDetails,
}) {
  const {
    id,
    title,
    createdAt,
    lastActiveAt,
    disciplines,
    topics,
    commentsCount,
    category,
    highlights,
    likes,
  } = post

  const { id: currentUserId } = userDetails

  const userLikeId = getLikeId(likes, currentUserId)

  const isLikedbyUser = !!userLikeId
  const likeCount = likes.length

  const postPath = `${postRootPath}/${id}`

  const bookmarkId = useMemo(() => {
    const matchingBookmark = find(bookmarks, {
      bookmarkableId: id,
      bookmarkableType: Types.BOOKMARKABLE.POST,
    })
    return get(matchingBookmark, 'id')
  }, [bookmarks, id])

  const isBookmarked = !!bookmarkId

  const toggleBookmark = useCallback(() => {
    if (!isBookmarked) return addBookmark(id)
    removeBookmark(bookmarkId)
  }, [isBookmarked, id, bookmarkId])

  const toggleLike = () => {
    if (!isLikedbyUser) return addLike(id)
    destroyLike(userLikeId)
  }

  const numOfCommentsText = `${commentsCount} ${pluralize(
    'Comment',
    commentsCount
  )}`
  const commentAriaLabel = useMemo(() => {
    const baseLabel = `Click this link to comment on ${title}`
    if (!commentsCount) return baseLabel
    return `${numOfCommentsText}. ${baseLabel}`
  }, [title, commentsCount, numOfCommentsText])

  return (
    <React.Fragment>
      <div className="result-block card discussion-card">
        <div>
          <div className="text-block">
            <div>
              <h3>
                <Link to={postPath}>{title}</Link>
              </h3>
              <p>
                Posted on {formatDate(createdAt)} /{' '}
                <em>Last Activity {formatDate(lastActiveAt)}</em>
              </p>
              <PostTags
                category={category}
                disciplines={disciplines}
                topics={topics}
              />
            </div>
            <div>
              <BookmarkButton
                isBookmarked={isBookmarked}
                toggleBookmark={toggleBookmark}
                ariaLabel={`Toggle discussion bookmark on ${title}`}
              />
            </div>
          </div>
          <div className="bottom-actions-container">
            <Link
              to={postPath + '#comment-new'}
              className="comment-button"
              aria-label={commentAriaLabel}
            >
              <CommentIcon aria-hidden />
              {commentsCount ? numOfCommentsText : 'Comment'}
            </Link>
            <LikeButton
              toggleLike={toggleLike}
              isLikedbyUser={isLikedbyUser}
              likeCount={likeCount}
              likeableType={Types.LIKEABLE.POST}
              likeableId={id}
            />
          </div>
        </div>
      </div>
      {!isEmpty(highlights) && (
        <div className="result-block card discussion-card post-highlight">
          <ul className="matched-terms">
            {map(highlights, (value, name) => {
              return (
                <HighlightText
                  key={`${name}-${value}`}
                  highlightName={name}
                  highlightValue={value}
                />
              )
            })}
          </ul>
        </div>
      )}
    </React.Fragment>
  )
}

ListPostCard.propTypes = propTypes
ListPostCard.defaultProps = defaultProps

export default pure(ListPostCard)
