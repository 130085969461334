import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import ArtStatCard from './art-stat-card'
import classnames from 'classnames'
import { compact, groupBy, map } from 'lodash'
import { orderAscendingToOtherByKey } from 'utils'

const propTypes = {
  artsDisciplines: PropTypes.arrayOf(PropTypes.object),
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  className: PropTypes.string,
}

const defaultProps = {
  className: '',
}

function ArtsDisciplinesTabContent({
  artsDisciplines,
  disciplines,
  className,
}) {
  // Group subdisciplines by discipline for display
  const disciplinesWithSubDisciplines = useMemo(() => {
    const groupedByDiscipline = groupBy(artsDisciplines, 'disciplineId')

    const mappedDisciplines = disciplines.map((discipline) => {
      const requestedSubDisciplines = groupedByDiscipline[discipline.id] || []

      return {
        ...discipline,
        subDisciplineNames: map(
          orderAscendingToOtherByKey(requestedSubDisciplines),
          'displayName'
        ),
      }
    })

    return compact(mappedDisciplines)
  }, [artsDisciplines, disciplines])

  return (
    <div className={classnames('colored-block', className)}>
      {disciplinesWithSubDisciplines.map((discipline) => (
        <ArtStatCard
          key={discipline.id}
          controlledId={`art-stat-${discipline.id}`}
          arts={discipline.subDisciplineNames}
          displayName={discipline.displayName}
          type={discipline.name}
        />
      ))}
    </div>
  )
}

ArtsDisciplinesTabContent.propTypes = propTypes
ArtsDisciplinesTabContent.defaultProps = defaultProps

export default ArtsDisciplinesTabContent
