import { isArray, map, isObject, curry } from 'lodash'

const curriedFindByValue = curry(findByValue)

export function format(options, value) {
  if (!value) return value
  if (isArray(value)) return value.map(curriedFindByValue(options))
  return findByValue(options, value)
}

export function normalize(value) {
  if (!value) return value
  if (isArray(value)) return map(value, 'value')
  return value.value
}

// Support options passed in as optionGroup objects or primitives
export function serializeOptions(options) {
  return options.map((option) => {
    if (isObject(option)) return { value: option.value, label: option.key }
    return { value: option, label: option }
  })
}

// ----- PRIVATE -----
function findByValue(options, value) {
  return options.find((option) => value === option.value)
}
