import React from 'react'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { SCHOOL_PORTAL_ROUTE } from 'config'
import { selectors as globalSchoolSelectors } from 'school-portal-reducer'
import { get } from 'lodash'

const propTypes = {
  surveySchoolYear: Types.schoolYear,
}

const defaultProps = {
  surveySchoolYear: null,
}

function Unavailable({ surveySchoolYear }) {
  const surveySchoolYearNumber = get(surveySchoolYear, 'number', 'School Year')
  return (
    <div className="content-block-container">
      <section className="card">
        <h3>The Survey is Coming Soon!</h3>
        <p>
          The survey for your community is not yet available but is on its way.
          Please note that once the survey is available an icon (
          <span className="badge inline">Open</span>) will appear next to the{' '}
          <strong>{surveySchoolYearNumber} Survey</strong> link in the left-hand
          sidebar.
        </p>
        <p>
          In the meantime, please review your school's details and make sure
          that the information is accurate and up to date.
        </p>
        <Link to={`${SCHOOL_PORTAL_ROUTE}/summary`} className="button-primary">
          Update School Details
        </Link>
      </section>
    </div>
  )
}

Unavailable.propTypes = propTypes
Unavailable.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    surveySchoolYear: globalSchoolSelectors.surveySchoolYear(state),
  }
}

export default compose(connect(mapStateToProps))(Unavailable)
