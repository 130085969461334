import { upperFirst, omit, pick, flatMapDeep } from 'lodash'
import toSingular from './to-singular'

const ALLOCATION_TYPES = [
  'engagements',
  'spaces',
  'schedules',
  'fundings',
  // 'programs',
  'resources',
  'integrations',
  'subDisciplines',
  'disciplines',
  'programTypes',
  'outcomes',
  'approaches',
  'obstacles',
  'standards',
  'governances',
  'financialAssistanceTypes',
  'identityFrequencies',
  'leadershipCharacteristics',
  'surveyQuestions',
]

function getResourceableIdKey(type) {
  // Some are different for some reason
  if (type === 'subDisciplines') return 'subDisciplineId'
  if (type === 'disciplines') return 'disciplineId'
  if (type === 'programTypes') return 'programTypeId'
  if (type === 'approaches') return 'approachId'
  if (type === 'obstacles') return 'obstacleId'
  if (type === 'standards') return 'standardId'
  if (type === 'governances') return 'governanceId'
  if (type === 'financialAssistanceTypes') return 'financialAssistanceTypeId'
  if (type === 'identityFrequencies') return 'identityFrequencyId'
  if (type === 'leadershipCharacteristics') return 'leadershipCharacteristicId'
  if (type === 'surveyQuestions') return 'surveyQuestionId'

  return toSingular(type) + 'TypeId'
}

function getResourceableId(resource, type) {
  const key = getResourceableIdKey(type)
  const resourceableId = resource[key]
  if (!resourceableId)
    throw new Error('resourceableId not found for key: ' + key)
  return resourceableId
}

function getResourceableType(type) {
  if (type === 'subDisciplines') return 'SubDiscipline'
  if (type === 'disciplines') return 'Discipline'
  if (type === 'programTypes') return 'ProgramType'
  if (type === 'approaches') return 'Approach'
  if (type === 'obstacles') return 'Obstacle'
  if (type === 'standards') return 'Standard'
  if (type === 'governances') return 'Governance'
  if (type === 'financialAssistanceTypes') return 'FinancialAssistanceType'
  if (type === 'identityFrequencies') return 'IdentityFrequency'
  if (type === 'leadershipCharacteristics') return 'LeadershipCharacteristic'
  if (type === 'surveyQuestions') return 'SurveyQuestion'

  return upperFirst(toSingular(type)) + 'Type'
}

function appendSecondaryResource(secondaryResource, allocationRecord) {
  const secondaryResourceAllocations =
    mapAllocations(secondaryResource).allocations
  return secondaryResourceAllocations.map(
    ({ resourceableId, resourceableType }) => ({
      ...allocationRecord,
      secondaryResourceableId: resourceableId,
      secondaryResourceableType: resourceableType,
    })
  )
}

function toAllocationRecord(resource, type) {
  const baseRecord = pick(resource, [
    'id',
    '_destroy',
    'schoolYearId',
    'otherText',
    'tag',
    'focus',
  ])
  const standardAllocationRecord = {
    ...baseRecord,
    resourceableId: getResourceableId(resource, type),
    resourceableType: getResourceableType(type),
  }
  const { secondaryResource } = resource
  if (!secondaryResource) return standardAllocationRecord
  return appendSecondaryResource(secondaryResource, standardAllocationRecord)
}

// Map some nested attributes to "allocations" objects

function mapAllocations(resource, { key = 'allocations' } = {}) {
  try {
    const allocationResources = pick(resource, ALLOCATION_TYPES)
    const allocations = flatMapDeep(allocationResources, (value, key) =>
      value.map((resource) => toAllocationRecord(resource, key))
    )
    const newResource = omit(resource, ALLOCATION_TYPES)
    return { ...newResource, [key]: allocations }
  } catch (e) {
    console.error(e) // eslint-disable-line
  }
}

export default mapAllocations
