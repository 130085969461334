import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { useUID } from 'react-uid'

const propTypes = {
  headerTitle: PropTypes.string,
  headerComponent: PropTypes.func,
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  forwardRef: PropTypes.object,
  headingLevel: PropTypes.string,
}

const defaultProps = {
  headerTitle: '',
  headerComponent: null,
  disabled: false,
  forwardRef: null,
  headingLevel: '3',
}

function DefaultHeader({ title }) {
  return <span>{title}</span>
}

function ControlledExpandableItem({
  headerTitle,
  headerComponent,
  expanded,
  setExpanded,
  disabled,
  children,
  forwardRef,
  headingLevel,
  ...rest
}) {
  const id = useUID()
  const Header = headerComponent || DefaultHeader
  return (
    <div className="expandable-section-item" ref={forwardRef}>
      <div className="expandable-section-header">
        <div role="heading" aria-level={headingLevel}>
          <button
            type="button"
            aria-expanded={expanded}
            onClick={() => {
              if (disabled) return
              setExpanded(!expanded)
            }}
            aria-controls={`${id}-container`}
            disabled={disabled}
          >
            <Header title={headerTitle} {...rest} />
          </button>
        </div>
      </div>
      <div id={`${id}-container`} className="expandable-section-details">
        {expanded && children}
      </div>
    </div>
  )
}

ControlledExpandableItem.propTypes = propTypes
ControlledExpandableItem.defaultProps = defaultProps

export default pure(ControlledExpandableItem)
