import React from 'react'
import { pure } from 'recompose'
import { FlagIcon } from 'components/icons/basic'

const propTypes = {}

const defaultProps = {}

function FlaggedBanner() {
  return (
    <div className="flagged-banner">
      <FlagIcon />
      <span>Flagged as Inappropriate</span>
    </div>
  )
}

FlaggedBanner.propTypes = propTypes
FlaggedBanner.defaultProps = defaultProps

export default pure(FlaggedBanner)
