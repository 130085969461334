import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import * as Views from './views'
import Layout from './layout'

const Routes = (
  <Route path="summary" component={Layout}>
    <IndexRedirect to="overview" />
    <Route path="overview" component={Views.Overview} />
    <Route path="partnerships" component={Views.Partnerships} />
    <Route path="interests" component={Views.Interests} />
  </Route>
)

export default Routes
