import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import CommunityEnumerableQuestion from './community-enumerable-question'
import { ArtsApproachesTableForm, ArtsApproachesForm } from '../forms'
import {
  replaceResources,
  yankBy,
  isOtherType,
  getOtherTypeId,
  filterDestroyed,
  combineOtherOption,
} from 'utils'
import { filter, isEmpty } from 'lodash'
import { SubmissionError } from 'redux-form'

const propTypes = {
  approachTypes: PropTypes.arrayOf(Types.approach).isRequired,
  approaches: PropTypes.arrayOf(Types.schoolApproach),
  disciplines: PropTypes.arrayOf(Types.discipline),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitFail: PropTypes.func.isRequired,
  setShowInfoModalWithContent: PropTypes.func.isRequired,
  surveySchoolYearNumber: PropTypes.string.isRequired,
}

const defaultProps = {
  approaches: [],
  disciplines: [],
}

const { APPROACHES } = Types.SURVEY_QUESTIONS
const { GENERAL, PER_DISCIPLINE } = APPROACHES.OPTIONS

function filterUnselectedApproaches(disciplinesSelectedByApproach) {
  return filter(
    disciplinesSelectedByApproach,
    ({ secondaryResource: { disciplines } }) => !isEmpty(disciplines)
  )
}

function groupSelectedDisciplineIds(approaches) {
  return approaches.map(({ secondaryResourceable }) => ({
    disciplineId: secondaryResourceable.id,
  }))
}

function groupSelectedDisciplinesByApproach(approaches, approachTypes) {
  return approachTypes.map((approachType) => {
    const matchingApproaches = filter(approaches, {
      name: approachType.name,
    })
    return {
      approachId: approachType.id,
      secondaryResource: {
        disciplines: groupSelectedDisciplineIds(matchingApproaches),
      },
    }
  })
}

function ApproachesQuestion({
  approachTypes,
  approaches,
  disciplines,
  onSubmit,
  onSubmitSuccess,
  onSubmitFail,
  setShowInfoModalWithContent,
  surveySchoolYearNumber,
}) {
  const formatForSubmit = useCallback(
    (newApproaches) =>
      replaceResources({
        old: approaches,
        new: newApproaches,
      }),
    [approaches]
  )
  return (
    <CommunityEnumerableQuestion
      questionName={APPROACHES.NAME}
      enumerableOptions={approachTypes}
      setShowInfoModalWithContent={setShowInfoModalWithContent}
      surveySchoolYearNumber={surveySchoolYearNumber}
    >
      {(optionName) => {
        if (optionName === PER_DISCIPLINE) {
          const disciplinesSelectedByApproach =
            groupSelectedDisciplinesByApproach(approaches, approachTypes)
          return (
            <ArtsApproachesTableForm
              disciplines={disciplines}
              approachTypes={approachTypes}
              initialValues={{ disciplinesSelectedByApproach }}
              beforeSubmit={({ disciplinesSelectedByApproach }) => ({
                approaches: formatForSubmit(
                  filterUnselectedApproaches(disciplinesSelectedByApproach)
                ),
              })}
              onSubmit={onSubmit}
              onSubmitSuccess={onSubmitSuccess}
              onSubmitFail={onSubmitFail}
            />
          )
        }
        if (optionName === GENERAL) {
          const [otherApproach, definedApproaches] = yankBy(
            approaches,
            isOtherType
          )
          return (
            <ArtsApproachesForm
              approachTypes={approachTypes}
              initialValues={{
                other: {
                  ...otherApproach,
                  approachId: getOtherTypeId(approachTypes),
                },
                approaches: definedApproaches,
              }}
              beforeSubmit={({ other, approaches }) => {
                const resources = formatForSubmit(
                  combineOtherOption(approaches, other)
                )
                if (isEmpty(filterDestroyed(resources)))
                  throw new SubmissionError({
                    _error: 'You must select at least one option',
                  })
                return {
                  approaches: resources,
                }
              }}
              onSubmit={onSubmit}
              onSubmitSuccess={onSubmitSuccess}
              onSubmitFail={onSubmitFail}
            />
          )
        }
      }}
    </CommunityEnumerableQuestion>
  )
}

ApproachesQuestion.propTypes = exact(propTypes)
ApproachesQuestion.defaultProps = defaultProps

export default pure(ApproachesQuestion)
