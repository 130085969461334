import React from 'react'

function HeartFilledIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="43"
      viewBox="0 0 48 43"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#B90817"
          fillRule="nonzero"
          d="M24.001 4.144A14.081 14.081 0 0014.01 0C9.967 0 5.929 1.724 3.083 5.174c-4.7 5.7-3.854 14.188 1.363 19.417L22 42.17c.553.554 1.277.829 2 .829.72 0 1.444-.275 1.998-.83l17.555-17.58c5.217-5.227 6.062-13.716 1.362-19.416A14.089 14.089 0 0033.991 0a14.07 14.07 0 00-9.99 4.144z"
        ></path>
      </g>
    </svg>
  )
}

export default HeartFilledIcon
