import React from 'react'
import { WarningIcon } from 'components/icons/basic'
import { ExternalLink } from 'components'
import { useCommunity } from 'utils'

function ErrorFetchState() {
  const community = useCommunity()

  return (
    <div className="empty-state">
      <WarningIcon />
      <h3>Sorry, we can’t load the Directory right now.</h3>
      <p>
        There seems to be a technical issue that is preventing Directory
        profiles from loading. Please try again later. If you continue to have
        issues, please let us know by contacting{' '}
        <ExternalLink href={`mailto:${community.schoolSupportEmail}`}>
          {community.schoolSupportEmail}
        </ExternalLink>
        .
      </p>
    </div>
  )
}

export default ErrorFetchState
