import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { Link } from 'react-router'

const propTypes = {
  schoolPortalEnabled: PropTypes.bool.isRequired,
}

const defaultProps = {}

function SchoolCard({ schoolPortalEnabled }) {
  return (
    <div className="card">
      <svg
        width="85px"
        height="84px"
        viewBox="0 0 85 84"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group-4-Copy" transform="translate(-2.000000, 0.000000)">
            <g
              id="in-school-performance"
              transform="translate(0.048780, 0.000000)"
            >
              <path
                d="M86.3507463,42 C86.3507463,65.1959595 67.4564945,84 44.1492537,84 C20.842013,84 1.94776119,65.1959595 1.94776119,42 C1.94776119,18.8040405 20.842013,0 44.1492537,0 C55.3417854,0 66.0759002,4.4249873 73.9902153,12.3015152 C81.9045304,20.1780431 86.3507463,30.8609074 86.3507463,42 Z"
                className="fill"
                fill="#4A4A4A"
                fillRule="nonzero"
              ></path>
              <polygon
                fill="#FFFFFF"
                fillRule="nonzero"
                points="51.0833612 24.5538462 51.0833612 19.60181 53.8880597 19.5855204 53.838565 18.5104072 42.834248 9.04615385 31.8134328 18.5104072 31.8134328 19.5855204 34.5686366 19.60181 34.5686366 24.5375566 42.8177498 18.4126697"
              ></polygon>
              <path
                d="M40.22799,43.9384615 C38.9924058,43.9384615 38.3059701,45.2307692 38.3059701,45.2307692 L42.2014925,45.2307692 C42.2014925,45.2307692 41.4464133,43.9384615 40.22799,43.9384615 Z"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <path
                d="M33.1119403,50.4 C33.1119403,50.4 34.2968284,52.9846154 36.358209,52.9846154 C38.4195896,52.9846154 39.6044776,50.4160535 39.6044776,50.4160535 C38.5590565,50.7802362 37.4653823,50.9911661 36.358209,51.0421405 C35.2511773,50.9785574 34.1585416,50.7624244 33.1119403,50.4 L33.1119403,50.4 Z"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <path
                d="M33.7524597,43.9384615 C32.5121812,43.9384615 31.8134328,45.2307692 31.8134328,45.2307692 L35.7089552,45.2307692 C35.7089552,45.2307692 35.0451442,43.9384615 33.7524597,43.9384615 Z"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <path
                d="M49.2953092,58.1538462 C47.2164179,58.1538462 46.0970149,60.7384615 46.0970149,60.7384615 C47.1256028,60.3667744 48.2034393,60.1503707 49.2953092,60.0963211 C50.4183592,60.1508724 51.5277755,60.3671288 52.5895522,60.7384615 C52.5895522,60.7384615 51.3742004,58.1538462 49.2953092,58.1538462 Z"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <path d="M43.5,84 C55.3322625,83.9981557 66.6273497,79.3143397 74.6641791,71.0769231 L12.3358209,71.0769231 C20.3726503,79.3143397 31.6677375,83.9981557 43.5,84 Z"></path>
              <path d="M22.0370089,42.8695706 L17.3273276,42.8695706 L17.3273276,41.0481085 L42.4625083,22.1588725 L67.6314501,41.0312431 L67.6314501,42.7177821 L62.7023213,42.7177821 L62.7023213,65.9076923 L77.7260358,65.9076923 C87.9323226,50.9420854 87.4336094,31.1364638 76.487031,16.7025361 C65.5404526,2.26860848 46.5912051,-3.56944098 29.4094539,2.1984655 C12.2277028,7.96637197 0.651428129,24.051826 0.649253731,42.1612242 C0.634655266,50.6343299 3.18882382,58.9132088 7.97548736,65.9076923 L22.0370089,65.9076923 L22.0370089,42.8695706 Z"></path>
              <path
                d="M63.4565387,66.1083 L63.4565387,42.7455621 L68.4117337,42.7455621 L68.4117337,41.0439789 L43.1436332,21.9692308 L17.8755328,41.0269631 L17.8755328,42.864673 L22.6101198,42.864673 L22.6101198,66.1083 L8.44029851,66.1083 C9.65338738,67.8596547 10.991878,69.5202411 12.4451821,71.0769231 L74.5548179,71.0769231 C76.0134146,69.4766274 77.3520241,67.7703939 78.5597015,65.9721734 L63.4565387,66.1083 Z"
                id="Shape"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </svg>
      <h2>School Portal</h2>
      <p>
        Allows schools to report details of their current arts staffing and
        instruction as well as the areas they would like to strengthen.
      </p>
      {schoolPortalEnabled ? (
        <Link
          className="button-primary"
          to="/school-portal"
          aria-label="School Portal Login"
        >
          Login
        </Link>
      ) : (
        <span className="coming-soon">Coming Soon!</span>
      )}
    </div>
  )
}

SchoolCard.propTypes = propTypes
SchoolCard.defaultProps = defaultProps

export default pure(SchoolCard)
