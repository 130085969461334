import React from 'react'
// import PropTypes from 'prop-types'
import { LabeledField, omitLabelProps } from '@launchpadlab/lp-components'

function getOptionValue(value, options) {
  const matchingOption = options.find((option) => option.value == value)
  return matchingOption ? matchingOption.key : ''
}

// eslint-disable-next-line
function ReadOnlyCellInput(props) {
  const {
    input: { value },
    options,
  } = omitLabelProps(props)
  const displayValue = options ? getOptionValue(value, options) : value
  return (
    <div className="read-only-cell-input">
      <LabeledField {...props}>{displayValue}</LabeledField>
    </div>
  )
}

export default ReadOnlyCellInput
