import React from 'react'

function EmptySearchState() {
  return (
    <div className="empty-state">
      <h3>No Results Found</h3>
      <p>Please try another search.</p>
    </div>
  )
}

export default EmptySearchState
