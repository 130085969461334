import React from 'react'
import PropTypes from 'prop-types'
import { ScrollElement } from 'react-scroll'

const propTypes = {
  children: PropTypes.node,
  parentBindings: PropTypes.object.isRequired,
}

const defaultProps = {}

// custom scroll element used with react-scroll
function ScrollableListItem({ children, parentBindings, ...rest }) {
  return (
    <li
      {...rest}
      ref={(el) => {
        parentBindings.domNode = el
      }}
    >
      {children}
    </li>
  )
}

ScrollableListItem.propTypes = propTypes
ScrollableListItem.defaultProps = defaultProps

export default ScrollElement(ScrollableListItem)
