import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { TrashIcon, ViewIcon } from 'components/icons/basic'
import { ActionMenu, ExternalLink } from 'components'

const propTypes = {
  notification: Types.notification.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  deleteAllNotifications: PropTypes.func.isRequired,
  markAsRead: PropTypes.func.isRequired,
  markAllAsRead: PropTypes.func.isRequired,
}

const defaultProps = {
  notification: {},
}

function NotificationListItem({
  notification,
  deleteNotification,
  deleteAllNotifications,
  markAsRead,
  markAllAsRead,
}) {
  const {
    id,
    content,
    createdAt,
    linkUrl,
    isUnread,
    notificationableType,
    notificationIds,
  } = notification

  const isLikeNotification =
    notificationableType === Types.NOTIFICATIONABLE_TYPES.LIKE

  const handleDeleteNotification = () => {
    isLikeNotification
      ? deleteAllNotifications(notificationIds)
      : deleteNotification(id)
  }

  const handleMarkAsRead = () => {
    isLikeNotification ? markAllAsRead(notificationIds) : markAsRead(id)
  }

  const isPartnershipMatch = notificationableType == 'PartnershipMatch'
  const Link = isPartnershipMatch ? ExternalLink : 'a'

  const actions = [
    {
      label: 'Delete',
      icon: TrashIcon,
      onSelect: () => handleDeleteNotification(notification),
    },
  ]

  if (isUnread) {
    actions.unshift({
      label: 'Mark As Read',
      icon: ViewIcon,
      onSelect: () => handleMarkAsRead(notification),
    })
  }

  return (
    <div className="notification-list-item">
      <div className="notification-list-item__content-info">
        <div className="list-item-indicator">
          {isUnread && <div className="indicator-icon"></div>}
        </div>
        <div className="list-item-data">
          <div className="data-title">
            <Link
              href={linkUrl}
              onClick={() => handleMarkAsRead(notification.id)}
              className="data-title__name"
            >
              {content}
            </Link>
          </div>
        </div>
        <div className="list-item-menu">
          <ActionMenu actions={actions} portal={false} />
        </div>
      </div>
      <div className="notification-list-item__date-time-info">
        <span>
          <i>{moment(createdAt).format('MMMM Do, YYYY')}</i>
        </span>
      </div>
    </div>
  )
}

NotificationListItem.propTypes = propTypes
NotificationListItem.defaultProps = defaultProps

function mapStateToProps(/*state*/) {
  return {}
}

const mapDispatchToProps = {
  deleteNotification: apiActions.deleteNotification,
  deleteAllNotifications: apiActions.deleteAllNotifications,
  markAsRead: apiActions.markAsRead,
  markAllAsRead: apiActions.markAllAsRead,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NotificationListItem
)
