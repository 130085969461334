import React from 'react'
import { isNil, toSafeInteger, lowerCase, startCase, kebabCase } from 'lodash'
import PropTypes from 'prop-types'
import * as Types from 'types'

const propTypes = {
  coursesBySchoolGrade: Types.coursesBySchoolGrade.isRequired,
  schoolCategory: PropTypes.string.isRequired,
}

const defaultProps = {}

function displaySurveyValue(value, state, defaultValue = '') {
  return state === Types.COURSE_STATES.APPROVED ||
    state === Types.COURSE_STATES.RESPONDENT_SAVED
    ? isNil(value)
      ? defaultValue
      : toSafeInteger(value)
    : undefined
}

function AvgMinutesTable({ courses }) {
  return (
    <table>
      <thead>
        <tr>
          <th>Class Number</th>
          <th>Class Name</th>
          <th>Average Minutes per Week</th>
        </tr>
      </thead>
      <tbody>
        {courses.map((course) => {
          return (
            <tr key={course.id}>
              <td>{course.classNumber}</td>
              <td>{course.name}</td>
              <td>
                {displaySurveyValue(
                  course.averageMinutesPerWeek,
                  course.state,
                  Types.MISSING_SURVEY_VALUE
                )}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

function InstructionalDepthTable({ courses }) {
  return (
    <table>
      <thead>
        <tr>
          <th>Class Number</th>
          <th>Class Name</th>
          <th>Instructional Depth</th>
        </tr>
      </thead>
      <tbody>
        {courses.map((course) => {
          return (
            <tr key={course.id}>
              <td>{course.classNumber}</td>
              <td>{course.name}</td>
              <td>{startCase(course.instructionalDepth)}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

function CoursesTable({ coursesBySchoolGrade, schoolCategory }) {
  const schoolGrades = [
    Types.ELEMENTARY_SCHOOL_TYPE,
    Types.MIDDLE_SCHOOL_TYPE,
    Types.HIGH_SCHOOL_TYPE,
  ]
  return schoolGrades.map((grade) => {
    const courses = coursesBySchoolGrade[grade]
    if (!courses) return null
    return (
      <div
        className={`table-block scrollable-table ${kebabCase(grade)}-courses`}
        key={grade}
      >
        {schoolCategory === Types.COMBO_SCHOOL_TYPE && (
          <h4 className="table-category-heading">{`${lowerCase(
            grade
          )} grade levels`}</h4>
        )}
        {grade === Types.HIGH_SCHOOL_TYPE ? (
          <InstructionalDepthTable courses={courses} />
        ) : (
          <AvgMinutesTable courses={courses} />
        )}
      </div>
    )
  })
}

CoursesTable.propTypes = propTypes
CoursesTable.defaultProps = defaultProps

export default CoursesTable
