// import { set } from 'lodash/fp'
import deepMap from './deep-map'

const IGNORED_KEYS = ['focuses', 'logos', 'applicableGrades']

// Most nested attributes on the school/partner require a school year to be passed in.

function addSchoolYearToParams(params, schoolYear) {
  return deepMap(params, (value, key) =>
    Array.isArray(value) && !IGNORED_KEYS.includes(key)
      ? value.map((resource) => ({ schoolYearId: schoolYear, ...resource })) // if resource already has a school year, don't overwrite
      : value
  )
}

export default addSchoolYearToParams
