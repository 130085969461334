import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ActionMenu } from 'components'
import {
  ProfileOverview,
  PostTags,
  PostUserContent,
  PostedDate,
} from 'educator-portal-components'
import { ViewIcon, TrashIcon } from 'components/icons/basic'
import { getUserDisplayName } from 'utils'
import { EDUCATOR_PORTAL_ROUTE } from 'config'

const propTypes = {
  flaggedItem: Types.post.isRequired,
  requestAgreeWithFlag: PropTypes.func.isRequired,
  markAppropriateAction: Types.menuAction.isRequired,
  navigateTo: PropTypes.func.isRequired,
  showProfileDetails: PropTypes.func.isRequired,
}

const defaultProps = {}

function FlaggedPost({
  flaggedItem: post,
  requestAgreeWithFlag,
  markAppropriateAction,
  navigateTo,
  showProfileDetails,
}) {
  const {
    id,
    title,
    content,
    disciplines,
    topics,
    createdAt,
    user,
    category,
    isEdited,
  } = post
  const { id: userId, name, schoolNames, profileImgUrl, pronouns, email } = user
  const postPath = `${EDUCATOR_PORTAL_ROUTE}/forum/discussions/${id}`

  const viewPostAction = {
    icon: ViewIcon,
    label: 'View Full Discussion',
    onSelect: () => navigateTo(postPath),
  }
  const archivePostAction = {
    icon: TrashIcon,
    label: 'Remove Discussion',
    onSelect: requestAgreeWithFlag,
  }

  const actions = [viewPostAction, archivePostAction, markAppropriateAction]

  return (
    <React.Fragment>
      <div className="text-block">
        <ProfileOverview
          userDisplayName={getUserDisplayName({ name }, email)}
          profileImgUrl={profileImgUrl}
          schoolNames={schoolNames}
          onProfileClick={() => showProfileDetails(userId)}
          pronouns={pronouns}
        />
        <div className="flex-vertical">
          <div className="flex-horizontal forum-action-menu">
            <PostedDate date={createdAt} />
            <ActionMenu actions={actions} />
          </div>
        </div>
      </div>
      <div>
        <h3>{title}</h3>
        <PostUserContent content={content} isEdited={isEdited} />
        <PostTags
          category={category}
          disciplines={disciplines}
          topics={topics}
        />
      </div>
    </React.Fragment>
  )
}

FlaggedPost.propTypes = exact(propTypes)
FlaggedPost.defaultProps = defaultProps

export default pure(FlaggedPost)
