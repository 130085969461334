import { isNil, isEmpty, isObject } from 'lodash'

// Returns true when a form value is "present"
// (i.e., not an empty string, array, object, null, or undefined)
function hasPresentValue(value) {
  if (Array.isArray(value) && value.length) return value.every(hasPresentValue)
  if (isObject(value)) return !isEmpty(value)

  return value !== '' && !isNil(value)
}

export default hasPresentValue
