import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import SubDisciplineTypeRow from './sub-discipline-type-row'
import classnames from 'classnames'

const propTypes = {
  subDisciplinesOffered: PropTypes.arrayOf(Types.schoolSubDiscipline)
    .isRequired,
  subDisciplineOptions: PropTypes.arrayOf(Types.subDiscipline).isRequired,
  className: PropTypes.string,
}

const defaultProps = {
  className: '',
}

const getSubDisciplinesOffered = (subdisciplines, typeId) => {
  return subdisciplines.filter(
    ({ subDisciplineId }) => subDisciplineId === typeId
  )
}

function SchoolDisciplineTypeTabContent({
  className,
  subDisciplineOptions,
  subDisciplinesOffered,
}) {
  return (
    <div className={classnames('colored-block', 'table-content', className)}>
      <div className="in-out-school-headers">
        <div className="in-out-school-header">
          <p>In-School</p>
          <p>Out-of-School</p>
        </div>
        <div className="in-out-school-header">
          <p>In-School</p>
          <p>Out-of-School</p>
        </div>
      </div>
      <ul className="in-out-school-block">
        {subDisciplineOptions.map((option) => (
          <SubDisciplineTypeRow
            key={option.id}
            option={{
              key: option.displayName,
              value: option.id,
            }}
            subDisciplinesOffered={getSubDisciplinesOffered(
              subDisciplinesOffered,
              option.id
            )}
          />
        ))}
      </ul>
    </div>
  )
}

SchoolDisciplineTypeTabContent.propTypes = propTypes
SchoolDisciplineTypeTabContent.defaultProps = defaultProps

export default SchoolDisciplineTypeTabContent
