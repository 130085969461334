import React from 'react'
import PropTypes from 'prop-types'
import { ExternalLink } from 'components'

const propTypes = {
  title: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  subdomain: PropTypes.string.isRequired,
  schoolPortalEnabled: PropTypes.bool,
  partnerPortalEnabled: PropTypes.bool,
  mapPortalEnabled: PropTypes.bool,
}

const defaultProps = {
  schoolPortalEnabled: false,
  partnerPortalEnabled: false,
  mapPortalEnabled: false,
}

function CommunityCard({
  title,
  imgSrc,
  imgAlt,
  subdomain,
  schoolPortalEnabled,
  partnerPortalEnabled,
  mapPortalEnabled,
}) {
  return (
    <li className="community-card">
      <h3>{title}</h3>
      <div className="logo-image">
        <img src={imgSrc} alt={imgAlt} />
      </div>
      <div className="text-block">
        <ul>
          {schoolPortalEnabled ? (
            <li>
              <ExternalLink
                href={`https://${subdomain}.artlookmap.com/school-portal/sign-in`}
                aria-label={title + ' School Portal'}
              >
                School Portal
              </ExternalLink>
            </li>
          ) : (
            <li>
              <span className="link-placeholder">School Portal</span>
              <span className="label">Coming Soon</span>
            </li>
          )}
          {partnerPortalEnabled ? (
            <li>
              <ExternalLink
                href={`https://${subdomain}.artlookmap.com/partner-portal/sign-in`}
                aria-label={title + ' Partner Portal'}
              >
                Partner Portal
              </ExternalLink>
            </li>
          ) : (
            <li>
              <span className="link-placeholder">Partner Portal</span>
              <span className="label">Coming Soon</span>
            </li>
          )}
          {mapPortalEnabled ? (
            <li>
              <ExternalLink
                href={`https://${subdomain}.artlookmap.com/`}
                aria-label={title + ' Public Map'}
              >
                Public Map
              </ExternalLink>
            </li>
          ) : (
            <li>
              <span className="link-placeholder">Public Map</span>
              <span className="label">Coming Soon</span>
            </li>
          )}
        </ul>
      </div>
    </li>
  )
}

CommunityCard.propTypes = propTypes
CommunityCard.defaultProps = defaultProps

export default CommunityCard
