import { curry, isString } from 'lodash/fp'
import legacySerializeLocation from './legacy-serialize-location'
import toAddressString from './to-address-string'
import * as Types from 'types'
import isBlank from './is-blank'

// Transforms a school or partner into a location object
// Picks certain attributes, renames id to resourceId and adds type attrib
function serializeLocation(type, obj) {
  const address = getAttribute(obj, type, 'address')
  if (!address) return null
  // Fallback to legacy API function if address is a string
  if (isString(address)) return legacySerializeLocation(type, obj)
  const {
    longitude,
    latitude,
    street,
    state,
    zip,
    city,
    congressionalDistrict,
    ward,
    network,
    communityArea,
  } = address
  return {
    type,
    resourceId: getIdAttribute(obj, type),
    name: getAttribute(obj, type, 'name'),
    address: toAddressString({ street, state, zip, city }),
    street,
    zip,
    longitude: getCoordinateAttribute(longitude),
    latitude: getCoordinateAttribute(latitude),
    congressionalDistrict,
    ward,
    network,
    communityArea,
  }
}

// ----- PRIVATE -----

// Helper for getting attributes from partnership objects.
// Checks to see if the attribute exists outright before checking type specific fields
function getAttribute(obj, type, name) {
  if (obj[name]) return obj[name]
  return type === Types.SCHOOL_TYPE
    ? obj[`${Types.SCHOOL_TYPE}${capitalizeFirstLetter(name)}`]
    : obj[`${Types.PARTNER_TYPE}${capitalizeFirstLetter(name)}`]
}

function capitalizeFirstLetter(str = '') {
  return str[0].toUpperCase() + str.slice(1)
}

// Helper for getting the appropriate id attribute (works for partnerships and individual schools/orgs)
function getIdAttribute(obj, type) {
  // If the object is not a partnership, then simply return the id of the school or partner
  if (obj.type !== Types.PARTNERSHIP_TYPE) return obj.id

  return type === Types.SCHOOL_TYPE ? obj.schoolId : obj.organizationId
}

// Helper for converting a location coordinate safely (avoids Number(null) -> 0)
function getCoordinateAttribute(coordinate) {
  if (isBlank(coordinate)) return null
  return Number(coordinate)
}

export default curry(serializeLocation)
