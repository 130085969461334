import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { WarningIcon } from 'components/icons/basic'
import { ExternalLink } from 'components'
import { useCommunity } from 'utils'

const propTypes = {
  componentName: PropTypes.string.isRequired,
  headerAs: PropTypes.string.isRequired, // set the correct hierarchical header level
}

const defaultProps = {}

function ErrorLoadState({ componentName, headerAs: Header }) {
  const community = useCommunity()

  return (
    <div className="empty-state">
      <WarningIcon aria-hidden />
      <Header>Sorry, we can't load the {componentName} right now.</Header>
      <p>
        There seems to be a technical issue that is preventing the{' '}
        {componentName} from loading. Please refresh the page or try again
        later. If you continue to have issues, please let us know by contacting{' '}
        <ExternalLink href={`mailto:${community.schoolSupportEmail}`}>
          {community.schoolSupportEmail}
        </ExternalLink>
        .
      </p>
    </div>
  )
}

ErrorLoadState.propTypes = exact(propTypes)
ErrorLoadState.defaultProps = defaultProps

export default React.memo(ErrorLoadState)
