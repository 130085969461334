import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Link } from 'react-router'
import { FolderIcon } from 'components/icons/basic'

const propTypes = {
  folder: Types.indexFolder.isRequired,
  copResourcesIndexPath: PropTypes.string.isRequired,
  children: PropTypes.node,
}

const defaultProps = {
  children: null,
}

function ResourcesTableFolder({ folder, copResourcesIndexPath, children }) {
  return (
    <tr className="row-with-folder">
      <td>
        <Link
          className="link-primary no-underline"
          to={`${copResourcesIndexPath}/${folder.id}`}
        >
          <div className="flex-horizontal folder-name">
            <FolderIcon />
            <div>
              <p className="underline-hover">
                <strong>{folder.name}</strong>
              </p>
            </div>
          </div>
        </Link>
      </td>
      <td></td>
      <td></td>
      {children}
    </tr>
  )
}

ResourcesTableFolder.propTypes = exact(propTypes)
ResourcesTableFolder.defaultProps = defaultProps

export default pure(ResourcesTableFolder)
