import { get, isString } from 'lodash'

/**
 * This function determines the error message to display for submit failures,
 * closely based on the behavior of Redux-Form's onSubmitFail
 * https://redux-form.com/7.4.2/docs/api/reduxform.md/#-code-onsubmitfail-function-code-optional-
 *
 * @name displaySubmitFailure
 * @param {Object} errors - Errors that caused submit to fail
 * @param {Object} submitError - Errors that caused submit to fail. If the only errors are related internally to redux-form validations, this will be null
 */
function getFormWideErrorMessage(errors, submitError) {
  const hasValidationErrors = !submitError
  if (hasValidationErrors && errors)
    return get(
      errors,
      '_error',
      'Please review the information provided and correct all form errors.'
    )
  return getErrorMessage(errors)
}

// ----- PRIVATE -----
function getErrorMessage(errors = {}) {
  if (isString(errors)) return errors

  const { message, _error } = errors
  if (!_error && !message)
    return 'An error occurred while saving details. Please contact customer support'

  return message || _error
}

export default getFormWideErrorMessage
