import React from 'react'

function BudgetIcon() {
  return (
    <svg viewBox="0 0 50 50">
      <circle
        fill="#204A82"
        cx="25"
        cy="25"
        r="25"
        transform="translate(-0.32 49.68) rotate(-89.27)"
      />
      <path
        fill="#FFFFFF"
        d="M44.07,38.93V37.71H33.41v1.22H30.28V37.71H19.62v1.22H16.35V37.71H5.69v1.22H4.21a25.52,25.52,0,0,0,2.32,3H43.46a25.44,25.44,0,0,0,2.31-3Z"
      />
      <rect fill="#FFFFFF" x="5.69" y="34.79" width="10.66" height="1.48" />
      <rect fill="#FFFFFF" x="5.69" y="31.86" width="10.66" height="1.48" />
      <rect fill="#FFFFFF" x="19.62" y="34.81" width="10.66" height="1.48" />
      <rect fill="#FFFFFF" x="19.62" y="31.9" width="10.66" height="1.48" />
      <rect fill="#FFFFFF" x="19.62" y="28.99" width="10.66" height="1.48" />
      <rect fill="#FFFFFF" x="19.62" y="26.09" width="10.66" height="1.48" />
      <rect fill="#FFFFFF" x="19.62" y="23.18" width="10.66" height="1.48" />
      <rect fill="#FFFFFF" x="19.62" y="20.27" width="10.66" height="1.48" />
      <rect fill="#FFFFFF" x="33.41" y="34.81" width="10.66" height="1.48" />
      <rect fill="#FFFFFF" x="33.41" y="31.9" width="10.66" height="1.48" />
      <rect fill="#FFFFFF" x="33.41" y="28.99" width="10.66" height="1.48" />
      <rect fill="#FFFFFF" x="33.41" y="26.09" width="10.66" height="1.48" />
      <path
        fill="#FFFFFF"
        d="M21.71,10.33a3.11,3.11,0,0,0,1,.53,9,9,0,0,0,1.23.36l.7.16v2a10.44,10.44,0,0,1-1.72-.28,7,7,0,0,1-1.54-.58H21v2.12a7.1,7.1,0,0,0,1.68.47,11.6,11.6,0,0,0,1.88.2v2.59h1.26V15.25a4.64,4.64,0,0,0,2.74-1,2.68,2.68,0,0,0,1-2.11,2.24,2.24,0,0,0-.77-1.81,5.27,5.27,0,0,0-2.42-1l-.58-.12V7.41a6.65,6.65,0,0,1,1.46.24,5.09,5.09,0,0,1,1.2.5h.32V6.05a7.57,7.57,0,0,0-1.41-.37,11.46,11.46,0,0,0-1.57-.18V3.82H24.58V5.51a5.2,5.2,0,0,0-2.74.93,2.28,2.28,0,0,0-1,1.85,2.91,2.91,0,0,0,.22,1.21A2.38,2.38,0,0,0,21.71,10.33Zm4.1,1.21a2.65,2.65,0,0,1,.78.26.73.73,0,0,1,0,1.26,2,2,0,0,1-.82.28Zm-2-3.89a2,2,0,0,1,.86-.24V9.09a2.4,2.4,0,0,1-1-.33.61.61,0,0,1-.25-.53A.64.64,0,0,1,23.83,7.65Z"
      />
    </svg>
  )
}

export default BudgetIcon
