import { compose, mapKeys, assign, pick, curry } from 'lodash/fp'

// Let's get functional!

// Renames a single key of an object
const renameKey = curry((oldName, newName, obj) =>
  mapKeys((key) => (key === oldName ? newName : key), obj)
)

// Transforms a school or partner into a location object
// Picks certain attribs, renames id to resourceId and adds type attrib
const serializeLocation = curry((type, obj) =>
  compose(
    assign({ type }),
    renameKey('id', 'resourceId'),
    pick(['id', 'name', 'address', 'latitude', 'longitude'])
  )(obj)
)

export default serializeLocation
