import React from 'react'

function VisualArtsIcon() {
  return (
    <svg viewBox="0 0 67.75 67.75">
      <circle fill="#204A82" cx="33.88" cy="33.88" r="33.88" />
      <path
        fill="#FFFFFF"
        d="M75.85,74.28V70.41H37.19v3.87h3L38,83.8a33.45,33.45,0,0,0,3.62,2.08l2.7-11.61H55v7.24h3V74.28H68.62L71.35,86A33.85,33.85,0,0,0,75,84l-2.26-9.71Z"
        transform="translate(-22.74 -21.65)"
      />
      <path
        fill="#FFFFFF"
        d="M53.6,34.27,55,28.34v5.93h3v-5.7l1.33,5.7h4.11L60.53,21.88a33,33,0,0,0-8.17,0L49.49,34.27Z"
        transform="translate(-22.74 -21.65)"
      />
      <rect fill="#FFFFFF" x="15.14" y="16.62" width="37.14" height="28.14" />
    </svg>
  )
}

export default VisualArtsIcon
