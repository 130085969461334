import { handleActions } from 'redux-actions'
import { selectorForSlice } from 'lp-redux-utils'
import { setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import { createSelector } from 'reselect'
import { set, formatDate } from 'utils'
import { compose } from 'recompose'
import { reduce, first, last, isEmpty } from 'lodash'

const reducerKey = 'moderator'
const slice = 'root.educatorPortal.moderator'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchFlaggedItems]: setOnSuccess('flaggedItems'),
    [apiActions.fetchDirectoryUser]: setOnSuccess('directoryUser'),
    [apiActions.agreeWithFlag]: (state, { payload }) =>
      updateFlaggedItemsState({ state, payload }),
    [apiActions.disagreeWithFlag]: (state, { payload }) =>
      updateFlaggedItemsState({ state, payload }),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  flaggedItems: select('flaggedItems'),
  directoryUser: select('directoryUser'),
}

// Computed

selectors.flaggedItemsGroupedByDate = createSelector(
  [selectors.flaggedItems],
  function (flaggedItems) {
    if (!flaggedItems) return

    return reduce(
      flaggedItems,
      (result, flaggedItem) => {
        const flagDate = formatDate(first(flaggedItem.flags).updatedAt)
        const lastDateGroup = last(result)

        if (!lastDateGroup || formatDate(lastDateGroup.date) !== flagDate) {
          const newDateGroup = { date: flagDate, flaggedItems: [flaggedItem] }
          result.push(newDateGroup)
          return result
        }

        lastDateGroup.flaggedItems.push(flaggedItem)
        return result
      },
      []
    )
  }
)

// Helpers

function removeClosedFlagsItem(itemToRemove, state) {
  return state.flaggedItems.filter((item) => {
    // if flagged item is a user, check on the profile ID
    const id = item.profileId || item.id
    return id !== itemToRemove.id
  })
}

function updateFlaggedItemsState({ state, payload: { data } }) {
  if (!data || !data.reviewable || !state.flaggedItems) return state

  // make sure flags were actually closed (only open ones are returned)
  if (!isEmpty(data.reviewable.flags)) return state

  return compose(
    set('flaggedItems', removeClosedFlagsItem(data.reviewable, state))
  )(state)
}

export { reducer, selectors, reducerKey }
