import React from 'react'
import { CheckboxGroup, InputLabel } from 'lp-components'
import DropdownSelect from './dropdown-select'

function DropdownCheckboxGroup(props) {
  const {
    input: { name, value },
    label,
    selectedValues,
  } = props
  const val = value === '' ? [] : value
  return (
    <fieldset>
      <InputLabel {...{ label, name }} />
      <DropdownSelect selectedValues={selectedValues || val}>
        <CheckboxGroup {...props} label={false} />
      </DropdownSelect>
    </fieldset>
  )
}

DropdownCheckboxGroup.propTypes = CheckboxGroup.propTypes

export default DropdownCheckboxGroup
