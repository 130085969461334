import { curry } from 'lodash/fp'

// Create object from id

function parseIdToObject(key, id) {
  if (!id) return id
  return { [key]: id }
}

export default curry(parseIdToObject)
