import { handleActions } from 'redux-actions'
import { setOnSuccess } from 'lp-redux-api'
import { selectorForSlice, setState } from 'lp-redux-utils'
import { createSelector } from 'reselect'
import * as apiActions from 'api-actions'
import * as actions from './actions'
import { get, find, isEmpty } from 'lodash'
import { SURVEY_STATES, SURVEY_ATTEMPT_STATES } from 'config'
import { isOtherType, orderLastBy } from 'utils'
import { OBSTACLES, FREE_TEXT_QUESTION_OBSTACLES } from 'types'

const reducerKey = 'survey'
const slice = 'root.schoolPortal.survey'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchApproaches]: setOnSuccess('approaches'),
    [apiActions.fetchObstacleTypes]: setOnSuccess('obstacles'),
    [apiActions.fetchStandards]: setOnSuccess('standards'),
    [apiActions.fetchGovernanceTypes]: setOnSuccess('governanceTypes'),
    [apiActions.fetchSpaceTypes]: setOnSuccess('spaceTypes'),
    [apiActions.fetchIntegrationTypes]: setOnSuccess('integrationTypes'),
    [apiActions.fetchEngagementTypes]: setOnSuccess('engagementTypes'),
    [apiActions.fetchScheduleTypes]: setOnSuccess('scheduleTypes'),
    [apiActions.fetchSurveyAttempt]: setOnSuccess('surveyAttempt'),
    [apiActions.fetchFundingTypes]: setOnSuccess('fundingTypes'),
    [apiActions.fetchResourceTypes]: setOnSuccess('resourceTypes'),
    [actions.setSurveyAttemptState]: setState('surveyAttempt.state'),
    [apiActions.fetchSurveyAttemptReview]: setOnSuccess('surveyAttempt.review'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  approaches: select('approaches'),
  obstacles: select('obstacles'),
  standards: select('standards'),
  governanceTypes: select('governanceTypes'),
  spaceTypes: select('spaceTypes'),
  integrationTypes: select('integrationTypes'),
  engagementTypes: select('engagementTypes'),
  scheduleTypes: select('scheduleTypes'),
  surveyAttempt: select('surveyAttempt'),
  surveyAttemptReview: select('surveyAttempt.review'),
  fundingTypes: select('fundingTypes'),
  resourceTypes: select('resourceTypes'),
  surveyQuestions: select('surveyAttempt.surveyQuestions'),
}

// Computed

selectors.isSurveyOpen = createSelector(
  [selectors.surveyAttempt],
  function (surveyAttempt) {
    return get(surveyAttempt, 'survey.state') === SURVEY_STATES.PUBLISHED
  }
)

selectors.isSurveyAttemptInProgress = createSelector(
  [selectors.surveyAttempt],
  function (surveyAttempt) {
    if (!surveyAttempt) return
    return get(surveyAttempt, 'state') === SURVEY_ATTEMPT_STATES.IN_PROGRESS
  }
)

selectors.isSurveyAttemptComplete = createSelector(
  [selectors.surveyAttempt],
  function (surveyAttempt) {
    if (!surveyAttempt) return
    return get(surveyAttempt, 'state') === SURVEY_ATTEMPT_STATES.COMPLETE
  }
)

selectors.tableObstacleTypes = createSelector(
  [selectors.obstacles],
  function (obstacles) {
    if (!obstacles) return
    const obstaclesForTable = obstacles.filter(
      ({ name }) => !FREE_TEXT_QUESTION_OBSTACLES.includes(name)
    )
    if (isEmpty(obstaclesForTable)) return
    return orderLastBy(isOtherType, obstaclesForTable)
  }
)

selectors.describeObstacleType = createSelector(
  [selectors.obstacles],
  function (obstacles) {
    if (!obstacles) return
    return find(obstacles, { name: OBSTACLES.ADDITIONAL_INFORMATION })
  }
)

selectors.overcomeObstacleType = createSelector(
  [selectors.obstacles],
  function (obstacles) {
    if (!obstacles) return
    return find(obstacles, { name: OBSTACLES.WHAT_WORKED_WELL })
  }
)

export { reducer, selectors, reducerKey }
