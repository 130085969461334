import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import DOMPurify from 'dompurify'

const propTypes = {
  children: PropTypes.string,
  tag: PropTypes.string,
  addAttrs: PropTypes.array,
}

const defaultProps = {
  tag: 'div',
  addAttrs: [],
}

function RenderedHtml({ children, tag: Tag, addAttrs, ...rest }) {
  if (!children) return null

  const temporary_attr = 'data-temp-href-target'

  DOMPurify.addHook('beforeSanitizeAttributes', function (node) {
    if (node.tagName === 'A' && node.target === '_blank') {
      node.setAttribute(temporary_attr, node.getAttribute('target'))
    }
  })

  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    if (node.tagName === 'A' && node.hasAttribute(temporary_attr)) {
      node.setAttribute('target', node.getAttribute(temporary_attr))
      node.setAttribute('rel', 'noopener')
      node.removeAttribute(temporary_attr)
    }
  })

  return (
    <Tag
      {...rest}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(children, {
          ADD_ATTR: addAttrs,
          FORBID_ATTR: ['style'],
        }),
      }}
    />
  )
}

RenderedHtml.propTypes = propTypes
RenderedHtml.defaultProps = defaultProps

export default pure(RenderedHtml)
