import React from 'react'

function LockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#5B5F66"
          fillRule="nonzero"
          d="M9.142 4.235H7.856v-.988C7.856 1.2 6.903 0 4.999 0 3.096 0 2.143 1.202 2.143 3.247v.988H.714c-.394 0-.714.457-.714.847v5.506c0 .388.306.804.68.923l.854.272c.374.12 1.002.217 1.394.217h4.143c.393 0 1.02-.098 1.395-.217l.854-.272c.374-.12.68-.535.68-.923V5.082c0-.39-.464-.847-.858-.847zM3.57 2.965c0-1.02.57-1.553 1.429-1.553.858 0 1.428.533 1.428 1.553v1.27H3.57v-1.27z"
          transform="translate(5 4)"
        ></path>
      </g>
    </svg>
  )
}

export default LockIcon
