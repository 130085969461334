import React from 'react'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { Textarea, SubmitButton } from 'lp-components'

const propTypes = {
  ...formPropTypes,
}

function ProgramsResourcesInterestForm({
  handleSubmit,
  submitSucceeded,
  pristine,
  submitting,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field
        name="otherText"
        label="Tell us more about your school's interests"
        component={Textarea}
      />
      <div className="button-wrapper">
        <SubmitButton
          {...{ pristine: submitSucceeded && pristine, submitting }}
        >
          Save Response
        </SubmitButton>
      </div>
    </form>
  )
}

ProgramsResourcesInterestForm.propTypes = propTypes

export default compose(
  lpForm({
    name: 'school-programs-resources-interest',
    enableReinitialize: true,
  })
)(ProgramsResourcesInterestForm)
