import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  fieldPropTypesWithValue,
  omitLabelProps,
  LabeledField,
} from 'lp-components'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

const propTypes = {
  mentionSuggestions: PropTypes.array,
  autoFocus: PropTypes.bool,
  ...fieldPropTypesWithValue(PropTypes.string),
}

const defaultProps = {
  mentionSuggestions: [],
  autoFocus: false,
}

function MentionsInput(props) {
  const {
    input: { value, onChange, name },
    autoFocus,
    mentionSuggestions,
    ...rest
  } = omitLabelProps(props)

  const contentBlock = htmlToDraft(value)
  const contentState =
    contentBlock &&
    ContentState.createFromBlockArray(contentBlock.contentBlocks)
  const initialEditorState = contentState
    ? EditorState.createWithContent(contentState)
    : EditorState.createEmpty()

  const [editorState, setEditorState] = useState(initialEditorState)

  if (!value && editorState.getCurrentContent().hasText()) {
    // If the form value is blank but the editor state still has a value, it needs to be cleared using the .push() method
    // https://github.com/draft-js-plugins/draft-js-plugins/blob/master/FAQ.md#draft-js-plugin-decorators-are-removed-after-creating-a-new-editorstate
    setEditorState(
      EditorState.push(editorState, ContentState.createFromText(''))
    )
  }

  const setEditorReference = (ref) => {
    if (!ref || !autoFocus) return
    ref.focus()
  }

  return (
    <LabeledField {...props}>
      <Editor
        editorState={editorState}
        onEditorStateChange={(val) => setEditorState(val)}
        onChange={(val) => {
          return onChange(draftToHtml(val))
        }}
        editorRef={setEditorReference}
        toolbarClassName="mentions-input-toolbar"
        wrapperClassName="mentions-input-wrapper"
        editorClassName="mentions-input-editor"
        toolbar={{
          options: ['inline', 'link', 'blockType', 'list', 'history'],
          inline: {
            options: ['bold', 'italic', 'strikethrough'],
          },
          blockType: {
            options: ['Normal', 'H1'],
          },
        }}
        mention={{
          separator: ' ',
          trigger: '@',
          suggestions: mentionSuggestions,
        }}
        {...{
          id: name,
          name,
          ...rest,
        }}
      />
    </LabeledField>
  )
}

MentionsInput.propTypes = propTypes
MentionsInput.defaultProps = defaultProps

export default MentionsInput
