import * as Types from 'types'

// Create a sort object with type and direction.
// If sorting oldest to newest, use newest sort type, but switch the direction

function serializePostsSort(sortType) {
  if (sortType !== Types.OLDEST_SORT_TYPE)
    return { type: sortType, direction: 'desc' }
  return { type: Types.NEWEST_SORT_TYPE, direction: 'asc' }
}

export default serializePostsSort
