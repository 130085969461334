import userflow from 'userflow.js'

function initializeAnonymousUserflow() {
  if (window.process.env.NODE_ENV !== 'production') return

  userflow.init(process.env.USERFLOW_TOKEN)
  userflow.identifyAnonymous()
}

export default initializeAnonymousUserflow
