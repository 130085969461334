import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { getFormWideErrorMessage } from 'utils'
import { Modal, HiddenSpan } from 'components'
import { AddPartnerForm } from '../forms'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  existingPartnerships: PropTypes.arrayOf(Types.partnerPartnershipType),
  schoolOptions: PropTypes.arrayOf(Types.searchSuggestion),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
}

const defaultProps = {
  existingPartnerships: [],
  schoolOptions: [],
}

function AddPartnershipModal({
  onClose,
  existingPartnerships,
  schoolOptions,
  onSubmit,
  onSubmitSuccess,
}) {
  const [formWideError, setFormWideError] = useState(null)
  const [submitFailCount, setSubmitFailCount] = useState(0)
  return (
    <Modal onClose={onClose}>
      <h2>Add New Partnership</h2>
      <div className="modal-content">
        <div role="alert" aria-atomic>
          {formWideError && (
            <p className="full-error-message">
              {/* The hidden count will force screen readers to announce
               the error again even when it's exactly the same */}
              {formWideError} <HiddenSpan>{submitFailCount}</HiddenSpan>
            </p>
          )}
        </div>
        <AddPartnerForm
          schoolOptions={schoolOptions}
          onSubmit={onSubmit}
          onSubmitFail={(errors, _, submitError) => {
            setSubmitFailCount((sfc) => sfc + 1)
            const msg = getFormWideErrorMessage(errors, submitError)
            setFormWideError(msg)
          }}
          onSubmitSuccess={onSubmitSuccess}
          existingPartnerships={existingPartnerships}
        />
      </div>
    </Modal>
  )
}

AddPartnershipModal.propTypes = propTypes
AddPartnershipModal.defaultProps = defaultProps

export default AddPartnershipModal
