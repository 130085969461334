import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { useCommunitySurveyQuestionOption } from 'utils'

const propTypes = {
  children: PropTypes.func.isRequired,
  questionName: PropTypes.string.isRequired,
  surveySchoolYearNumber: PropTypes.string,
}

const defaultProps = {
  surveySchoolYearNumber: null,
}

function CommunityQuestion({ children, questionName, surveySchoolYearNumber }) {
  const questionOption = useCommunitySurveyQuestionOption(questionName)
  if (!questionOption) return null

  const { description, getQuestionDescription, getQuestionText } =
    questionOption
  return (
    <React.Fragment>
      <h2 id={questionName}>{getQuestionText({ surveySchoolYearNumber })}</h2>
      {description && (
        <p>{getQuestionDescription({ surveySchoolYearNumber })}</p>
      )}
      {children({ questionOption, ariaLabelledby: questionName })}
    </React.Fragment>
  )
}

CommunityQuestion.propTypes = propTypes
CommunityQuestion.defaultProps = defaultProps

export default pure(CommunityQuestion)
