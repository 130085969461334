import React from 'react'
import { Link } from 'react-router'
import { useCommunityText, useCommunity } from 'utils'
import { Card, RenderedHtml } from 'components'
import {
  ResidencyIcon,
  PartnershipsIcon,
  LiteraryArtsIcon,
} from 'components/icons'
import { get } from 'lodash'
import { EDUCATOR_PORTAL_ROUTE } from 'config'

const propTypes = {}

const defaultProps = {}

function Home() {
  const community = useCommunity()
  const t = useCommunityText()
  const communityOfPracticeEnabled = get(
    community,
    'educator.communityOfPracticeEnabled'
  )
  return (
    <React.Fragment>
      <Card>
        <div className="rendered-html">
          <RenderedHtml>{t('educatorPortal.welcomeMessage')}</RenderedHtml>
        </div>
      </Card>
      <div className="to-do-blocks full-width">
        <Card>
          <div className="value-block">
            <div className="value">
              <ResidencyIcon />
            </div>
          </div>
          <div className="text-block">
            <h3>Forum</h3>
            <p>
              Engage with other teachers to discuss various topics and share
              knowledge.
            </p>
            <Link
              to={EDUCATOR_PORTAL_ROUTE + '/forum'}
              className="button-primary button-small"
            >
              Engage
            </Link>
          </div>
        </Card>
        <Card>
          <div className="value-block">
            <div className="value">
              <PartnershipsIcon />
            </div>
          </div>
          <div className="text-block">
            <h3>Directory</h3>
            <p>
              View and connect with arts educators across the district using the
              artlook platform.
            </p>
            <Link
              to={EDUCATOR_PORTAL_ROUTE + '/directory'}
              className="button-primary button-small"
            >
              View Directory
            </Link>
          </div>
        </Card>
        {communityOfPracticeEnabled && (
          <Card>
            <div className="value-block">
              <div className="value">
                <LiteraryArtsIcon />
              </div>
            </div>
            <div className="text-block">
              <h3>Communities of Practice</h3>
              <p>
                Engage with other teachers to discuss various topics and share
                curricular resources.
              </p>
              <Link
                to={EDUCATOR_PORTAL_ROUTE + '/communities-of-practice'}
                className="button-primary button-small"
              >
                View Communities of Practice
              </Link>
            </div>
          </Card>
        )}
      </div>
    </React.Fragment>
  )
}

Home.propTypes = propTypes
Home.defaultProps = defaultProps

export default Home
