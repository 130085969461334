import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { Link } from 'react-router'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ActionMenu, Attachments } from 'components'
import {
  ProfileOverview,
  BookmarkButton,
  FlaggedBanner,
  PostTags,
  PostUserContent,
  PostedDate,
  LikeButton,
} from 'educator-portal-components'
import { CommentIcon } from 'components/icons/basic'
import { getUserDisplayName, pluralize, getLikeId } from 'utils'
import { isEmpty } from 'lodash'

const propTypes = {
  post: Types.post.isRequired,
  actions: PropTypes.arrayOf(Types.menuAction).isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  toggleBookmark: PropTypes.func.isRequired,
  showProfileDetails: PropTypes.func.isRequired,
  hasFlags: PropTypes.bool.isRequired,
  postPath: PropTypes.string.isRequired,
  addLike: PropTypes.func.isRequired,
  destroyLike: PropTypes.func.isRequired,
  userDetails: Types.user.isRequired,
}

const defaultProps = {}

function ViewPostCard({
  post,
  actions,
  isBookmarked,
  toggleBookmark,
  showProfileDetails,
  hasFlags,
  postPath,
  addLike,
  destroyLike,
  userDetails,
}) {
  const {
    id,
    title,
    content,
    disciplines,
    topics,
    createdAt,
    commentsCount,
    user: author,
    category,
    isEdited,
    attachments,
    likes,
  } = post
  const { name, schoolNames, profileImgUrl, pronouns, email } = author
  const { id: currentUserId } = userDetails

  const userLikeId = getLikeId(likes, currentUserId)

  const isLikedbyUser = !!userLikeId
  const likeCount = likes.length

  const toggleLike = () => {
    if (!isLikedbyUser) return addLike(id, Types.LIKEABLE.POST)
    destroyLike(userLikeId)
  }

  const numOfCommentsText = `${commentsCount} ${pluralize(
    'Comment',
    commentsCount
  )}`
  const commentAriaLabel = useMemo(() => {
    const baseLabel = `Click this link to comment on ${title}`
    if (!commentsCount) return baseLabel
    return `${numOfCommentsText}. ${baseLabel}`
  }, [title, commentsCount, numOfCommentsText])

  return (
    <section className="post-card">
      <div className="card discussion-card">
        {hasFlags && <FlaggedBanner />}
        <div className="text-block">
          <ProfileOverview
            userDisplayName={getUserDisplayName({ name }, email)}
            profileImgUrl={profileImgUrl}
            schoolNames={schoolNames}
            onProfileClick={showProfileDetails}
            pronouns={pronouns}
          />
          <div className="flex-vertical">
            <div className="flex-horizontal forum-action-menu">
              <PostedDate date={createdAt} />
              <ActionMenu actions={actions} />
            </div>
            <div>
              <BookmarkButton
                isBookmarked={isBookmarked}
                toggleBookmark={toggleBookmark}
                ariaLabel={`Toggle discussion bookmark`}
              />
            </div>
          </div>
        </div>
        <div className="text-block">
          <div>
            <h3>{title}</h3>
            <PostUserContent content={content} isEdited={isEdited} />
            {!isEmpty(attachments) && <Attachments attachments={attachments} />}
            <PostTags
              category={category}
              disciplines={disciplines}
              topics={topics}
            />
          </div>
        </div>
        <div className="bottom-actions-container">
          <Link
            to={postPath + '#comment-new'}
            className="comment-button"
            aria-label={commentAriaLabel}
          >
            <CommentIcon aria-hidden />
            {commentsCount ? numOfCommentsText : 'Comment'}
          </Link>
          <LikeButton
            toggleLike={toggleLike}
            isLikedbyUser={isLikedbyUser}
            likeCount={likeCount}
            likeableType={Types.LIKEABLE.POST}
            likeableId={id}
          />
        </div>
      </div>
    </section>
  )
}

ViewPostCard.propTypes = exact(propTypes)
ViewPostCard.defaultProps = defaultProps

export default pure(ViewPostCard)
