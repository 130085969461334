import React from 'react'

function MusicIcon() {
  return (
    <svg viewBox="0 0 67.75 67.75">
      <circle fill="#204A82" cx="33.88" cy="33.87" r="33.87" />
      <path
        fill="#FFFFFF"
        d="M77.08,36.6,52.86,44.8V68.36a6.36,6.36,0,0,0-1.05-.09,7,7,0,0,0-2.07.31c-3.07.94-4.95,3.73-4.18,6.23a4.83,4.83,0,0,0,4.89,3.13,7,7,0,0,0,2.07-.31A6.58,6.58,0,0,0,56,75.2a4.51,4.51,0,0,0,.88-2.87V47.67l16.22-5.49V61.36a6.4,6.4,0,0,0-1.07-.1,7,7,0,0,0-2.07.31c-3.07.94-4.95,3.73-4.18,6.23a4.83,4.83,0,0,0,4.89,3.13,7,7,0,0,0,2.07-.31,6.58,6.58,0,0,0,3.46-2.42,4.62,4.62,0,0,0,.88-2.47h0Z"
        transform="translate(-22.77 -21.59)"
      />
      <path
        fill="#FFFFFF"
        d="M36.29,46.61a7,7,0,0,0-2.07.31,6.58,6.58,0,0,0-3.46,2.42A4.32,4.32,0,0,0,30,53.15a4.83,4.83,0,0,0,4.88,3.13A7,7,0,0,0,37,56a6.58,6.58,0,0,0,3.46-2.42,4.61,4.61,0,0,0,.88-2.47h0V25.24a33.68,33.68,0,0,0-4,2.38V46.71A6.42,6.42,0,0,0,36.29,46.61Z"
        transform="translate(-22.77 -21.59)"
      />
    </svg>
  )
}

export default MusicIcon
