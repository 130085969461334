import React, { useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, formValueSelector } from 'redux-form'
import { lpForm } from 'lp-form'
import {
  ReadOnlyCellInput,
  NestedAttributesFieldArray,
  SectionHeader,
  InfoModal,
} from 'components'
import { sortBy, uniqBy, noop, groupBy, size, get } from 'lodash'
import * as Types from 'types'
import { SubmitButton } from 'lp-components'
import { filterDestroyed, pluralize } from 'utils'
import { AddPartnershipModal, CopyPartnershipModal } from '../components'
import * as flashActions from 'redux-flash'
import {
  PROGRAM_TYPES_MODAL_CONTENT,
  PROGRAM_TYPES_MODAL_HEADER,
  SUCCESS_FLASH_MODAL_CLOSE_DELAY,
} from 'config'
import classnames from 'classnames'

const propTypes = {
  change: PropTypes.func.isRequired,
  programTypes: PropTypes.arrayOf(Types.programType).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  flashSuccessMessage: PropTypes.func.isRequired,
  partnerships: PropTypes.arrayOf(Types.partnerPartnershipType),
  previousPartnerships: PropTypes.arrayOf(Types.partnership),
  schoolOptions: PropTypes.arrayOf(Types.searchSuggestion),
  schoolYears: PropTypes.arrayOf(Types.schoolYear).isRequired,
  submitting: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  visibleSchoolYearId: PropTypes.number.isRequired,
}

const defaultProps = {
  error: '',
  disabled: false,
  partnerships: [],
  previousPartnerships: [],
}

function hasProgramTypes(programTypes, programType) {
  return (
    programTypes &&
    programTypes
      .filter((p) => !p._destroy)
      .some((p) => p.programTypeId === programType.id)
  )
}

function destroyProgramsWithType(programs, programType) {
  return programs.map((program) => {
    if (program.programTypeId === programType.id)
      return { ...program, _destroy: true }
    return program
  })
}

function createProgramWithType(programs, programType) {
  return [...programs, { programTypeId: programType.id }]
}

function validatePartnerships(partnerships = []) {
  const activePartnerships = filterDestroyed(partnerships)

  if (uniqBy(activePartnerships, 'schoolId').length < activePartnerships.length)
    return 'Only one of each partner can be added. Please remove the duplicate(s)'

  if (
    activePartnerships.some(
      (partnership) => !filterDestroyed(partnership.programTypes).length
    )
  )
    return 'Each partner must have at least one program type selected'
}

function useActiveSchoolCallback(schoolOptions) {
  const activeSchoolMap = useMemo(
    () => groupBy(schoolOptions, 'id'),
    [schoolOptions]
  )
  return useCallback(
    (schoolId) => {
      return !!activeSchoolMap[schoolId]
    },
    [activeSchoolMap]
  )
}

function ProgramInput({ input: { value, onChange }, programTypes, disabled }) {
  return (
    <React.Fragment>
      {programTypes.map((programType, i) => {
        const checked = hasProgramTypes(value, programType)
        return (
          <td key={i}>
            <input
              type="checkbox"
              disabled={disabled}
              checked={checked}
              onChange={() => {
                const newValue = checked
                  ? destroyProgramsWithType(value, programType)
                  : createProgramWithType(value, programType)
                return onChange(newValue)
              }}
              aria-label={programType.displayName}
            />
          </td>
        )
      })}
    </React.Fragment>
  )
}

function PartnershipFields({ fields, programTypes, disabled }) {
  return (
    <tbody>
      {fields.map((field, i) => {
        const partnership = fields.get(i)
        return (
          <tr
            key={partnership.id || i}
            role="group"
            aria-label={`Progam types provided by ${partnership.organizationName}`}
          >
            <td>
              {!disabled && (
                <button
                  onClick={() => fields.destroy(i)}
                  className="remove-row"
                  alt={`Remove ${partnership.schoolName} partnership`}
                  type="button"
                >
                  ×
                </button>
              )}
              <Field
                name={field + '.schoolName'}
                label={false}
                component={ReadOnlyCellInput}
              />
            </td>
            <Field
              name={field + '.programTypes'}
              component={ProgramInput}
              programTypes={programTypes}
              disabled={disabled}
            />
          </tr>
        )
      })}
    </tbody>
  )
}

function PartnershipTableForm({
  change,
  disabled,
  error,
  flashSuccessMessage,
  handleSubmit,
  partnerships,
  programTypes,
  submitting,
  submitFailed,
  schoolOptions,
  previousPartnerships,
  schoolYears,
  visibleSchoolYearId,
}) {
  const isActiveSchool = useActiveSchoolCallback(schoolOptions)

  // Determine what partnerships exist with _active_ schools that have not already been added for the current year
  const remainingPreviousPartnerships = useMemo(() => {
    const eligiblePartnerships = previousPartnerships.filter((partnership) => {
      return (
        isActiveSchool(partnership.schoolId) &&
        !partnerships.some(({ schoolId }) => schoolId === partnership.schoolId)
      )
    })

    return eligiblePartnerships
  }, [previousPartnerships.length, partnerships.length, isActiveSchool])

  const visibleYear = useMemo(() => {
    return schoolYears.find(({ id }) => id === visibleSchoolYearId)
  }, [schoolYears, visibleSchoolYearId])

  const [showInfoModal, setShowInfoModal] = useState(false)
  const [showAddPartnershipModal, setShowAddPartnershipModal] = useState(false)
  const [showCopyPartnershipModal, setShowCopyPartnershipModal] =
    useState(false)

  return (
    <SectionHeader>
      <h2>List All Your School Partnerships</h2>
      <p>
        List all schools you worked with at any point during the chosen school
        year. For a list of program definitions, please click{' '}
        <button
          type="button"
          className="link-black"
          onClick={() => setShowInfoModal(true)}
        >
          here
        </button>
        .
      </p>
      {!disabled && (
        <div className="button-area many-buttons">
          <button
            type="button"
            onClick={() => setShowAddPartnershipModal(true)}
            className="button-primary-outline"
          >
            <span aria-hidden="true">+</span> Add New Partner
          </button>
          <button
            type="button"
            onClick={() => setShowCopyPartnershipModal(true)}
            className="button-primary-outline"
          >
            Copy partners from other school years
          </button>
        </div>
      )}

      <div className="portal partner-partnerships">
        {partnerships && partnerships.length > 0 ? (
          <form onSubmit={disabled ? noop : handleSubmit} disabled={disabled}>
            <table>
              <thead>
                <tr>
                  <th>School Name</th>
                  {programTypes.map((programType, i) => (
                    <th key={i}>{programType.displayName}</th>
                  ))}
                </tr>
              </thead>
              <NestedAttributesFieldArray
                name="partnerships"
                component={PartnershipFields}
                programTypes={programTypes}
                disabled={disabled}
              />
            </table>
            {submitFailed && error && (
              <div className="partnership-table-errors">
                <p className="error-message">{error}</p>
              </div>
            )}
            {/* Submit button is required when a form exists */}
            <div className={classnames({ 'visually-hidden': disabled })}>
              <SubmitButton submitting={submitting} disabled={disabled}>
                Save
              </SubmitButton>
            </div>
          </form>
        ) : (
          <p>
            No partnerships reported for the{' '}
            {get(visibleYear, 'number', 'selected')} school year.
          </p>
        )}
      </div>
      {showAddPartnershipModal && (
        <AddPartnershipModal
          onClose={() => setShowAddPartnershipModal(false)}
          onSubmit={({ schoolId }) => {
            const schoolName = schoolOptions.find(
              ({ id }) => id === Number(schoolId)
            ).name
            const newPartnership = { schoolId, schoolName, programTypes: [] }
            change(
              'partnerships',
              sortBy([...partnerships, newPartnership], 'schoolName')
            )
            return newPartnership
          }}
          onSubmitSuccess={({ schoolName }) => {
            setShowAddPartnershipModal(false)
            // Delay to allow modal to close before making announcement (otherwise it will be ignored by VoiceOver)
            setTimeout(
              () =>
                flashSuccessMessage(
                  `Partnership with ${schoolName} added for the ${visibleYear.number} school year`
                ),
              SUCCESS_FLASH_MODAL_CLOSE_DELAY
            )
          }}
          schoolOptions={schoolOptions}
          existingPartnerships={partnerships}
        />
      )}
      {showCopyPartnershipModal && (
        <CopyPartnershipModal
          onClose={() => setShowCopyPartnershipModal(false)}
          onSubmit={({ partnerships: newPartnerships }) => {
            change(
              'partnerships',
              sortBy([...partnerships, ...newPartnerships], 'schoolName')
            )
            return newPartnerships
          }}
          onSubmitSuccess={(partnerships) => {
            setShowCopyPartnershipModal(false)
            const numNewPartnerships = size(partnerships)
            setTimeout(
              () =>
                flashSuccessMessage(
                  `${numNewPartnerships} ${pluralize(
                    'partnership',
                    numNewPartnerships
                  )} copied over for the ${visibleYear.number} school year`
                ),
              SUCCESS_FLASH_MODAL_CLOSE_DELAY
            )
          }}
          partnerships={remainingPreviousPartnerships}
          schoolYears={schoolYears}
        />
      )}
      {showInfoModal && (
        <InfoModal
          onClose={() => setShowInfoModal(false)}
          content={PROGRAM_TYPES_MODAL_CONTENT}
          header={PROGRAM_TYPES_MODAL_HEADER}
        />
      )}
    </SectionHeader>
  )
}

PartnershipTableForm.propTypes = propTypes
PartnershipTableForm.defaultProps = defaultProps

function mapStateToProps(state, { name }) {
  return {
    partnerships: formValueSelector(name)(state, 'partnerships'),
  }
}

const mapDispatchToProps = {
  flashSuccessMessage: flashActions.flashSuccessMessage,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lpForm({
    submitFilters: {
      allow: ['id', 'partnerships'],
    },
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false, // allow switching between year tabs without losing in-progress data
    validate: ({ partnerships }) => {
      const error = validatePartnerships(partnerships)
      return { _error: error }
    },
  })
)(PartnershipTableForm)
