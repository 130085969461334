import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { Link } from 'react-router'
import { ControlledExpandableItem } from 'components/expandable-item'
import { useOnOutsideClick, getUserDisplayName } from 'utils'
import MultiAccountSwitch from './multi-account-switch'
import { SCHOOL_PORTAL_ROUTE, PARTNER_PORTAL_ROUTE } from 'config'

const propTypes = {
  currentEmployment: Types.employer.isRequired,
  currentEmployableType: PropTypes.string.isRequired,
  employments: PropTypes.arrayOf(Types.employer),
  userProfile: Types.profile,
  userEmail: PropTypes.string.isRequired,
}

const defaultProps = {
  employments: [],
  userProfile: null,
}

function generateBaseUrl(employableType) {
  if (employableType === Types.EMPLOYABLE_TYPES.SCHOOL)
    return SCHOOL_PORTAL_ROUTE
  return PARTNER_PORTAL_ROUTE
}

function AccountMenu({
  userProfile,
  userEmail,
  employments,
  currentEmployableType,
  currentEmployment,
}) {
  const [expanded, setExpanded] = useState(false)
  const ref = useOnOutsideClick(() => setExpanded(false))

  return (
    <div id="account-menu">
      <ControlledExpandableItem
        headerTitle={getUserDisplayName(userProfile, userEmail)}
        expanded={expanded}
        setExpanded={setExpanded}
        forwardRef={ref}
      >
        <MultiAccountSwitch
          currentEmployment={currentEmployment}
          currentEmployableType={currentEmployableType}
          employments={employments}
        />
        <div className="logout">
          <Link to={generateBaseUrl(currentEmployableType) + '/sign-out'}>
            Logout
          </Link>
        </div>
      </ControlledExpandableItem>
    </div>
  )
}

AccountMenu.propTypes = propTypes
AccountMenu.defaultProps = defaultProps

export default AccountMenu
