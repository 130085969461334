import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { DisclosurePopover, HiddenLegend } from 'components'
import { isEmpty } from 'lodash'

const propTypes = {
  menuTitle: PropTypes.string.isRequired,
  menuTitleComponent: PropTypes.func,
  inputComponent: PropTypes.func.isRequired,
}

const defaultProps = {
  menuTitleComponent: null,
}

function ExpandableMenuInput({
  menuTitle,
  menuTitleComponent: MenuTitleComponent,
  inputComponent: InputComponent,
  ...inputProps
}) {
  const hasValue = !isEmpty(inputProps.input.value)
  const buttonTitleComponent = (props) => (
    <MenuTitleComponent {...inputProps} {...props} />
  )

  return (
    <div>
      <label>{inputProps.label}</label>
      <DisclosurePopover
        buttonTitle={menuTitle}
        buttonTitleComponent={buttonTitleComponent}
        ariaPressed={hasValue}
      >
        <InputComponent {...inputProps} labelComponent={HiddenLegend} />
      </DisclosurePopover>
    </div>
  )
}

ExpandableMenuInput.propTypes = propTypes
ExpandableMenuInput.defaultProps = defaultProps

export default pure(ExpandableMenuInput)
