import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { SectionBlock, SectionHeader } from 'components'
import { Spinner } from 'lp-components'
import { ProfileForm } from '../forms'
import * as flashActions from 'redux-flash'
import * as apiActions from 'api-actions'
import { selectors as globalEducatorSelectors } from 'educator-portal-reducer'
import { selectors as globalSelectors } from 'global-reducer'
import { displaySubmitFailure, isNoneType } from 'utils'
import { negate } from 'lodash'

const propTypes = {
  createOrUpdateEducatorUserProfile: PropTypes.func.isRequired,
  currentSchoolYear: Types.schoolYear.isRequired,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  fetchGrades: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  grades: PropTypes.arrayOf(Types.grade),
  school: Types.school,
  userDetails: Types.user.isRequired,
}

const defaultProps = {
  grades: null,
  school: null,
}

function Profile({
  currentSchoolYear,
  disciplines,
  fetchGrades,
  flashErrorMessage,
  flashSuccessMessage,
  grades,
  school,
  createOrUpdateEducatorUserProfile,
  userDetails,
}) {
  const {
    id: profileId,
    firstName,
    lastName,
    pronouns,
    yearsOfExperience,
    avatarUrl,
    disciplines: profileDisciplines = [],
    grades: profileGrades = [],
  } = userDetails.profile || {}

  const displayDisciplines = useMemo(() => {
    return disciplines.filter(negate(isNoneType))
  }, [disciplines])

  useEffect(() => {
    if (!grades) fetchGrades()
  }, [grades])

  const hasLoaded = !!(grades && school)

  if (!hasLoaded) return <Spinner />

  return (
    <div>
      <SectionBlock>
        <SectionHeader>
          <h3>Profile Details</h3>
        </SectionHeader>
        <ProfileForm
          flashErrorMessage={flashErrorMessage}
          flashSuccessMessage={flashSuccessMessage}
          // eslint-disable-next-line no-unused-vars
          onSubmit={({ id, school, ...values }) => {
            return createOrUpdateEducatorUserProfile(
              profileId,
              currentSchoolYear.id,
              values
            )
          }}
          onSubmitSuccess={() => {
            flashSuccessMessage('Profile updated successfully!')
          }}
          onSubmitFail={displaySubmitFailure}
          initialValues={{
            school: school.name,
            id: profileId,
            firstName,
            lastName,
            yearsOfExperience,
            avatarUrl,
            pronouns,
            disciplines: profileDisciplines,
            grades: profileGrades,
          }}
          disciplines={displayDisciplines}
          grades={grades}
        />
      </SectionBlock>
    </div>
  )
}

Profile.propTypes = propTypes
Profile.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentSchoolYear: globalSelectors.currentSchoolYear(state),
    disciplines: globalSelectors.disciplines(state),
    grades: globalSelectors.grades(state),
    school: globalEducatorSelectors.school(state),
    userDetails: globalEducatorSelectors.userDetails(state),
  }
}

const mapDispatchToProps = {
  createOrUpdateEducatorUserProfile:
    apiActions.createOrUpdateEducatorUserProfile,
  fetchGrades: apiActions.fetchGrades,
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Profile)
