import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import classnames from 'classnames'
import { useUID } from 'react-uid'
import { sanitizeExternalUrl } from 'utils'

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
}

const defaultProps = {
  className: '',
}

function ExternalLink({ children, className, href, ...rest }) {
  const descriptionUid = 'new-window-' + useUID()
  return (
    <React.Fragment>
      <a
        className={classnames('external-link', className)}
        target="_blank"
        rel="noopener noreferrer"
        href={sanitizeExternalUrl(href)}
        aria-describedby={descriptionUid}
        {...rest}
      >
        {children}
      </a>
      <span id={descriptionUid} hidden className="visually-hidden">
        Opens in a new window
      </span>
    </React.Fragment>
  )
}
ExternalLink.propTypes = propTypes
ExternalLink.defaultProps = defaultProps

export default pure(ExternalLink)
