import React from 'react'
import PropTypes from 'prop-types'
import {
  CommunityImage,
  ExternalLink,
  PortalNavAdditionalLogos,
} from 'components'
import { Link } from 'react-router'
import home from 'images/side-navigation/home.svg'
import forum from 'images/side-navigation/connect.svg'
import directory from 'images/side-navigation/directory.svg'
import account from 'images/side-navigation/user.svg'
import flagged from 'images/side-navigation/flagged.svg'
import communityOfPractice from 'images/side-navigation/community.svg'
import faq from 'images/side-navigation/faq.svg'
import { EDUCATOR_PORTAL_ROUTE } from 'config'
import * as Types from 'types'
import { get } from 'lodash'

const propTypes = {
  community: PropTypes.object.isRequired,
  district: Types.district,
  isModerator: PropTypes.bool.isRequired,
  closeMobileNav: PropTypes.func.isRequired,
}

const defaultProps = {
  district: null,
}

function PortalPrimaryNav({
  community,
  district,
  isModerator,
  closeMobileNav,
}) {
  const {
    faqUrlEducator: communityFaqUrl,
    howToVideos,
    defaultBranding,
  } = community
  const educatorLogo = get(defaultBranding, 'educatorLogo')
  const educatorSponsorLogo = get(defaultBranding, 'educatorSponsorLogo')

  const communityOfPracticeEnabled = get(
    community,
    'educator.communityOfPracticeEnabled'
  )

  return (
    <nav className="side-navigation" aria-label="Primary navigation">
      <div className="side-navigation-inner">
        <div className="logo-block">
          <div className="logo-block-inner">
            <CommunityImage image={educatorLogo} />
            <CommunityImage image={educatorSponsorLogo} />
          </div>
        </div>
        <ul>
          <li>
            <Link to={EDUCATOR_PORTAL_ROUTE + '/home'} onClick={closeMobileNav}>
              <img src={home} alt="" /> Home
            </Link>
          </li>
          <li>
            <Link
              to={EDUCATOR_PORTAL_ROUTE + '/forum'}
              onClick={closeMobileNav}
            >
              <img src={forum} alt="" /> Forum
            </Link>
          </li>
          {communityOfPracticeEnabled && (
            <li>
              <Link
                to={EDUCATOR_PORTAL_ROUTE + '/communities-of-practice'}
                onClick={closeMobileNav}
              >
                <img src={communityOfPractice} alt="" /> Communities of Practice
              </Link>
            </li>
          )}
          <li>
            <Link
              to={EDUCATOR_PORTAL_ROUTE + '/directory'}
              onClick={closeMobileNav}
            >
              <img src={directory} alt="" /> Directory
            </Link>
          </li>
          {isModerator && (
            <li>
              <Link
                to={EDUCATOR_PORTAL_ROUTE + '/flagged'}
                onClick={closeMobileNav}
              >
                <img src={flagged} alt="" /> Flagged
              </Link>
            </li>
          )}
          <li>
            <Link
              to={EDUCATOR_PORTAL_ROUTE + '/profile'}
              onClick={closeMobileNav}
            >
              <img src={account} alt="" /> Profile
            </Link>
          </li>
          <li>
            <ExternalLink href={howToVideos} onClick={closeMobileNav}>
              <img src={faq} alt="" /> How to Videos
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href={communityFaqUrl} onClick={closeMobileNav}>
              <img src={faq} alt="" /> FAQ
            </ExternalLink>
          </li>
        </ul>
      </div>

      <PortalNavAdditionalLogos community={community} district={district} />
    </nav>
  )
}

PortalPrimaryNav.propTypes = propTypes
PortalPrimaryNav.defaultProps = defaultProps

export default React.memo(PortalPrimaryNav)
