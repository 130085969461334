import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import { AuthorizedRoute, UnauthorizedRoute } from 'components'
import LayoutAuthorized from './layout-authorized'
import LayoutUnauthorized from './layout-unauthorized'
import { routes as AccountRoutes } from './account'
import { routes as DashboardRoutes } from './dashboard'
import { routes as ProfileRoutes } from './profile'
import { routes as ReportsRoutes } from './reports'
// import { routes as QualityFrameworkRoutes } from './quality-framework'
import { routes as UserRoutes } from './user'
import { isAuthenticated } from './auth'
import AdminRedirect from './admin-redirect'
import Terms from './terms'
import { PARTNER_PORTAL_ROUTE } from 'config'

const Routes = (
  <Route path={PARTNER_PORTAL_ROUTE}>
    <IndexRedirect to="dashboard" />
    <Route path="admin-redirect" component={AdminRedirect} />

    {/* Authorized Routes */}
    <AuthorizedRoute
      authFunction={isAuthenticated}
      redirect={PARTNER_PORTAL_ROUTE + '/sign-in'}
    >
      <Route path={PARTNER_PORTAL_ROUTE + '/terms'} component={Terms} />
      <Route component={LayoutAuthorized}>
        {AccountRoutes}
        {DashboardRoutes}
        {ProfileRoutes}
        {ReportsRoutes}
        {/* { QualityFrameworkRoutes } */}
      </Route>
    </AuthorizedRoute>

    {/* Unauthorized Routes */}
    <UnauthorizedRoute
      authFunction={isAuthenticated}
      redirect={PARTNER_PORTAL_ROUTE}
      component={LayoutUnauthorized}
    >
      {UserRoutes}
    </UnauthorizedRoute>
  </Route>
)

export default Routes
