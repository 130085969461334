import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import {
  ArtsIntegrationIcon,
  BudgetIcon,
  InstructorsIcon,
  MentoringIcon,
  ProgramsIcon,
  VisualArtsIcon,
  ProfessionalDevelopmentIcon,
  ProfessionalDevelopmentArtsIcon,
} from 'components/icons/basic'

const propTypes = {
  resourceTypeName: PropTypes.string.isRequired,
}

const defaultProps = {}

const resourceIcons = {
  [Types.RESOURCE_TYPES.SUPPLIES_AND_EQUIPMENT]: <VisualArtsIcon />,
  [Types.RESOURCE_TYPES.DEDICATED_ARTS_BUDGET]: <BudgetIcon />,
  [Types.RESOURCE_TYPES.ADDITIONAL_STAFFING]: <InstructorsIcon />,
  [Types.RESOURCE_TYPES.MENTORSHIP_EXCHANGE]: <MentoringIcon />,
  [Types.RESOURCE_TYPES.INTEGRATION]: <ArtsIntegrationIcon />,
  [Types.RESOURCE_TYPES.CURRICULUM_ASSISTANCE]: <ProgramsIcon />,
  [Types.RESOURCE_TYPES.PROFESSIONAL_DEVELOPMENT]: (
    <ProfessionalDevelopmentIcon />
  ),
  [Types.RESOURCE_TYPES.PROFESSIONAL_DEVELOPMENT_ARTS]: (
    <ProfessionalDevelopmentArtsIcon />
  ),
}

function ResourcesTabCardIcon({ resourceTypeName }) {
  return resourceIcons[resourceTypeName] || null
}

ResourcesTabCardIcon.propTypes = exact(propTypes)
ResourcesTabCardIcon.defaultProps = defaultProps

export default pure(ResourcesTabCardIcon)
