import React from 'react'
import { compose } from 'redux'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { ButtonArea, SubmitButton, Input } from 'lp-components'
import { lpForm } from 'lp-form'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

function ResetPasswordForm({ handleSubmit, submitting, error }) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      {error && (
        <p className="full-error-message">
          There was a problem resetting your password, please check your email
          for new password reset instructions and try again.
        </p>
      )}
      <Field name="password" component={Input} type="password" />
      <Field
        name="passwordConfirmation"
        label="Confirm Password"
        component={Input}
        type="password"
      />
      <ButtonArea>
        <SubmitButton submitting={submitting}>Submit</SubmitButton>
      </ButtonArea>
    </form>
  )
}

ResetPasswordForm.propTypes = propTypes
ResetPasswordForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'partner-portal-reset-password',
    constraints: {
      password: { presence: true, length: { minimum: 6 } },
      passwordConfirmation: { presence: true, equality: 'password' },
    },
  })
)(ResetPasswordForm)
