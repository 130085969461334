import React from 'react'
import * as PropTypes from 'prop-types'
import * as Types from 'types'
import { pure } from 'recompose'
import { ExpandableItem } from 'components'
import { FlagIcon } from 'components/icons/basic'
import { formatDate } from 'utils'

const propTypes = {
  flags: PropTypes.arrayOf(Types.flag).isRequired,
  reviewableType: PropTypes.string.isRequired,
}

const defaultProps = {}

function FlaggedHeader({ title }) {
  return (
    <React.Fragment>
      <FlagIcon />
      <span>{title}</span>
    </React.Fragment>
  )
}

function FlaggedBannerExpandable({ flags, reviewableType }) {
  return (
    <div className="flagged-banner expandable">
      <ExpandableItem
        headerTitle={'Flagged as Inappropriate'}
        headerComponent={FlaggedHeader}
      >
        <div>
          {flags.map((flag) => {
            return (
              <div key={flag} className="flag-description">
                <p>
                  <strong>{flag.profileName}</strong> flagged this{' '}
                  {reviewableType.toLowerCase()} because {`"${flag.reason}"`}
                </p>
                <p className="flag-date">
                  <em>Flagged {formatDate(flag.updatedAt)}</em>
                </p>
              </div>
            )
          })}
        </div>
      </ExpandableItem>
    </div>
  )
}

FlaggedBannerExpandable.propTypes = propTypes
FlaggedBannerExpandable.defaultProps = defaultProps

export default pure(FlaggedBannerExpandable)
