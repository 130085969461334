import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ExternalLink } from 'components'
import { formatDate } from 'utils'

const propTypes = {
  attachment: Types.attachment.isRequired,
  children: PropTypes.node,
}

const defaultProps = {
  children: null,
}

function ResourcesTableAttachment({ attachment, children }) {
  return (
    <tr>
      <td>
        <ExternalLink
          className="link-primary no-underline"
          href={attachment.uploadUrl}
          aria-label={`Download ${attachment.name}`}
        >
          <span className="underline-hover">
            <strong>{attachment.name}</strong>
          </span>
        </ExternalLink>
      </td>
      <td>{formatDate(attachment.createdAt)}</td>
      <td>{attachment.userName}</td>
      {children}
    </tr>
  )
}

ResourcesTableAttachment.propTypes = exact(propTypes)
ResourcesTableAttachment.defaultProps = defaultProps

export default pure(ResourcesTableAttachment)
