import React from 'react'
import * as Types from 'types'
import { ExternalLink } from 'components'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { isEmpty } from 'lodash'

const propTypes = {
  user: Types.directoryUser.isRequired,
}

const defaultProps = {}

function ProfileInfoList({ user }) {
  const { email, disciplineNames, gradeNames, yearsOfExperience } = user
  return (
    <ul className="profile-details-list">
      {email && (
        <li>
          <strong>Email:</strong>{' '}
          <ExternalLink href={`mailto:${email}`}>{email}</ExternalLink>
        </li>
      )}
      {!isEmpty(disciplineNames) && (
        <li>
          <strong>Disciplines:</strong> {disciplineNames.join(', ')}
        </li>
      )}
      {!isEmpty(gradeNames) && (
        <li className="no-break">
          <strong>Grade Levels:</strong> {gradeNames.join(', ')}
        </li>
      )}
      {yearsOfExperience && (
        <li>
          <strong>Years of Teaching Experience:</strong> {yearsOfExperience}
        </li>
      )}
    </ul>
  )
}

ProfileInfoList.propTypes = exact(propTypes)
ProfileInfoList.defaultProps = defaultProps

export default pure(ProfileInfoList)
