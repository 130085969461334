import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { pure } from 'recompose'
import { isEmpty } from 'lodash'
import { communityEnumerable } from 'utils'
import { SectionBlock } from 'components'

const propTypes = {
  children: PropTypes.func.isRequired,
  surveyQuestion: Types.surveyQuestion.isRequired,
  enumerableOptions: PropTypes.arrayOf(Types.communityEnumerable).isRequired,
  setShowInfoModalWithContent: PropTypes.func.isRequired,
}

const defaultProps = {}

function DynamicQuestion({
  children,
  surveyQuestion,
  enumerableOptions,
  setShowInfoModalWithContent,
}) {
  const modalContent = useMemo(() => {
    return communityEnumerable.getInfoModalContent(enumerableOptions)
  }, enumerableOptions)
  const ariaLabelledby = `survey-question-${surveyQuestion.id}`

  return (
    <SectionBlock>
      <h2 id={ariaLabelledby}>{surveyQuestion.body}</h2>
      <p>{surveyQuestion.description}</p>
      {!isEmpty(modalContent) && (
        <button
          type="button"
          className="link-secondary"
          onClick={() => setShowInfoModalWithContent(modalContent)}
        >
          {/*Potentially made dynamic/DB backed in the future*/}
          Click here for additional information and definitions.
        </button>
      )}
      {children(enumerableOptions, { ariaLabelledby })}
    </SectionBlock>
  )
}

DynamicQuestion.propTypes = propTypes
DynamicQuestion.defaultProps = defaultProps

export default pure(DynamicQuestion)
