import { handleActions } from 'redux-actions'
import { setOnSuccess } from 'lp-redux-api'
import { selectorForSlice } from 'lp-redux-utils'
import * as apiActions from 'api-actions'

const reducerKey = 'communityOfPractice'
const slice = 'root.educatorPortal.communityOfPractice'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchCommunitiesOfPractice]: setOnSuccess(
      'communitiesOfPractice'
    ),
    [apiActions.fetchCommunityOfPractice]: setOnSuccess('communityOfPractice'),
    [apiActions.fetchAnnouncements]: setOnSuccess('announcements'),
    [apiActions.deleteAnnouncement]: setOnSuccess(
      'announcements',
      ({ payload: { data } }) => data.announcements
    ),
    [apiActions.createOrUpdateAnnouncement]: setOnSuccess('announcements'),
    [apiActions.fetchAttachments]: setOnSuccess('attachments'),
    [apiActions.fetchFolders]: setOnSuccess('folders'),
    [apiActions.fetchFolder]: setOnSuccess('folder'),
    [apiActions.fetchAttachableOptions]: setOnSuccess('attachableOptions'),
    [apiActions.fetchFolderableOptions]: setOnSuccess('folderableOptions'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  communitiesOfPractice: select('communitiesOfPractice'),
  communityOfPractice: select('communityOfPractice'),
  announcements: select('announcements'),
  attachments: select('attachments'),
  folders: select('folders'),
  folder: select('folder'),
  attachableOptions: select('attachableOptions'),
  folderableOptions: select('folderableOptions'),
}

export { reducer, selectors, reducerKey }
