import { fitBounds } from 'google-map-react/utils'
import first from 'lodash/first'
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import remove from 'lodash/remove'

// lat/lng bounds of Chicago
// const MIN_LATITUDE = 41.645869
// const MAX_LATITUDE = 42.021388
// const MIN_LONGITUDE = -87.940738
// const MAX_LONGITUDE = -87.530124

const minValue = (arr, prop) => minBy(arr, prop)[prop]
const maxValue = (arr, prop) => maxBy(arr, prop)[prop]

// Add this value to lats/longs to account for map marker size
const BOUNDS_BUFFER = 0.0004

// Get bounds containing locations
function getBoundsForLocations(locations) {
  const minLng = minValue(locations, 'longitude') - BOUNDS_BUFFER
  const maxLng = maxValue(locations, 'longitude') + BOUNDS_BUFFER
  const minLat = minValue(locations, 'latitude') - BOUNDS_BUFFER
  const maxLat = maxValue(locations, 'latitude') + BOUNDS_BUFFER
  return {
    nw: { lat: maxLat, lng: minLng },
    se: { lat: minLat, lng: maxLng },
  }
}

// Get center and zoom for single location
function fitToLocation(location) {
  return {
    zoom: 10,
    center: { lat: location.latitude, lng: location.longitude },
  }
}

// returns true if location is within the min/max lat/lng bounds
// radius argument unit is in miles
function isWithinBounds(location, center, radius = 50) {
  const { lat, lng } = center
  const latitudeOffset = 0.014389 * radius
  const longitudeOffset = 0.019326 * radius
  const MIN_LATITUDE = lat - latitudeOffset
  const MAX_LATITUDE = lat + latitudeOffset
  const MIN_LONGITUDE = lng - longitudeOffset
  const MAX_LONGITUDE = lng + longitudeOffset

  const withinBounds =
    location.latitude >= MIN_LATITUDE &&
    location.latitude <= MAX_LATITUDE &&
    location.longitude >= MIN_LONGITUDE &&
    location.longitude <= MAX_LONGITUDE

  if (!withinBounds)
    console.warn(`Location ${location.name} is not within bounds`) // eslint-disable-line
  return withinBounds
}

// Get center and zoom props to scale map to show given locations
export function fitToLocations(locations, size, mapCenter, radius) {
  // use only locations within min/max lat/lng bounds
  const locationsWithinBounds = remove([...locations], (location) =>
    isWithinBounds(location, mapCenter, radius)
  )
  if (locationsWithinBounds.length === 1)
    return fitToLocation(first(locationsWithinBounds))
  if (!locationsWithinBounds.length) return {}
  const bounds = getBoundsForLocations(locationsWithinBounds)
  const { center, zoom } = fitBounds(bounds, size)
  return { center, zoom }
}

export function findCenter(selectedLocation, currentCenter) {
  return selectedLocation
    ? { lat: selectedLocation.latitude, lng: selectedLocation.longitude }
    : currentCenter
}
