import React, { useEffect, useState } from 'react'
import { useUID } from 'react-uid'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { FormSection, Field, formValueSelector } from 'redux-form'
import { get, isEmpty, last, map, size, startCase } from 'lodash'
import { CheckboxGroup } from 'lp-components'
import classnames from 'classnames'
import { set } from 'lodash/fp'

const propTypes = {
  component: PropTypes.func,
  formName: PropTypes.string.isRequired,
  formPath: PropTypes.string.isRequired,
  headingLevel: PropTypes.string.isRequired,
  optionGroups: PropTypes.object.isRequired,
  preExpanded: PropTypes.array,
  pristine: PropTypes.bool,
  selectedValues: PropTypes.object,
}

const defaultProps = {
  component: CheckboxGroup,
  preExpanded: [],
  selectedValues: null,
}

function DefaultHeaderComponent({ header, headingLevel }) {
  return <span aria-level={headingLevel}>{header}</span>
}

function Expandable({
  children,
  header,
  headerComponent = null,
  expanded,
  setExpanded,
  disabled = false,
  className,
}) {
  const id = useUID()
  const HeaderComponent = headerComponent || DefaultHeaderComponent

  return (
    <div className="expandable-section-item">
      <div className="expandable-section-header">
        <button
          type="button"
          aria-expanded={expanded}
          onClick={() => {
            if (disabled) return
            setExpanded(!expanded)
          }}
          aria-controls={`${id}-container`}
          aria-disabled={disabled}
          className={className}
        >
          <HeaderComponent header={header} />
        </button>
      </div>
      <div
        id={`${id}-container`}
        className={classnames('expandable-section-details', {
          collapsed: !expanded,
        })}
      >
        {expanded && children}
      </div>
    </div>
  )
}

function ExpandableCheckboxGroups({
  component: Component,
  formPath,
  headingLevel,
  optionGroups,
  selectedValues,
  preExpanded,
}) {
  const [expandedGroups, setExpandedGroups] = useState({})
  useEffect(() => {
    if (isEmpty(preExpanded)) return setExpandedGroups({})

    const groupsToExpand = preExpanded.reduce((acc, val) => {
      acc[val] = true
      return acc
    }, {})
    return setExpandedGroups(groupsToExpand)
  }, [preExpanded])

  const formSection = last(formPath.split('.'))
  return (
    <FormSection name={formSection}>
      <div className="expandable-section">
        {map(optionGroups, (options, groupName) => {
          if (size(options) === 0) return null

          const sectionTitle = startCase(groupName)
          const expanded = expandedGroups[groupName]

          return (
            <Expandable
              key={groupName}
              header={sectionTitle}
              headingLevel={headingLevel}
              expanded={expanded}
              setExpanded={() =>
                setExpandedGroups(set(groupName, !expanded, expandedGroups))
              }
              className={classnames({
                'values-selected': !isEmpty(get(selectedValues, groupName)),
              })}
            >
              <Field
                name={groupName}
                component={Component}
                label={sectionTitle}
                labelComponent={HiddenCheckboxGroupLegend}
                options={options}
              />
            </Expandable>
          )
        })}
      </div>
    </FormSection>
  )
}

function HiddenCheckboxGroupLegend({ label, name }) {
  return <legend className="visually-hidden">{label || startCase(name)}</legend>
}

ExpandableCheckboxGroups.propTypes = propTypes
ExpandableCheckboxGroups.defaultProps = defaultProps

function mapStateToProps(state, { formName, formPath }) {
  return {
    selectedValues: formValueSelector(formName)(state, formPath),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ExpandableCheckboxGroups
)
