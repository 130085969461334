import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { modifyProps } from 'lp-hoc'
import { SortableTable, TableColumn as Column } from 'lp-components'
import * as Types from 'types'
import { CheckboxCell } from '../partner-table'
import { compareCheckboxColumns, anyRowHasProgram, hasProgram } from './helpers'

const propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  nameColumn: PropTypes.shape({
    name: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired,
  }),
  initialSortColumn: PropTypes.string.isRequired,
  type: PropTypes.oneOf([Types.PARTNER_TYPE, Types.SCHOOL_TYPE]),
  programNames: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const defaultProps = {
  rows: [],
}

function PartnerTable({ rows, columns, initialSortColumn }) {
  return (
    <div className="scrollable-table">
      <SortableTable data={rows} initialColumn={initialSortColumn}>
        {columns.map(({ name, label, component, ...rest }) => {
          return (
            <Column
              key={name}
              name={name}
              label={label}
              component={component}
              {...rest}
            />
          )
        })}
      </SortableTable>
    </div>
  )
}

PartnerTable.propTypes = propTypes
PartnerTable.defaultProps = defaultProps

function modify({ rows, nameColumn, type, programNames }) {
  // Create program column definitions
  const programColumns = programNames.map((programName) => ({
    name: programName,
    label: programName,
    component: CheckboxCell,
    sortFunc: compareCheckboxColumns(programName),
    // enable sorting if any row has the program
    disabled: !anyRowHasProgram(rows, programName),
    initialValues: getInitialValues(rows, programName),
    type,
  }))
  return {
    rows,
    columns: [nameColumn, ...programColumns],
    initialSortColumn: nameColumn.name,
  }
}

// Build initialValues by looking at the programs/programTypes in each row
function getInitialValues(rows, programName) {
  const initialValues = {}
  rows.forEach((row) => {
    const rowHasProgram = hasProgram(row, programName)
    initialValues[row.id] = rowHasProgram
  })
  return initialValues
}

export default compose(modifyProps(modify))(PartnerTable)
