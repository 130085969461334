import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { startCase } from 'lodash'
import { filterInvalidDOMProps } from 'lp-components/lib/utils'

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

const defaultProps = {
  label: '',
}

function HiddenLegend({ label, name, ...rest }) {
  return (
    <legend className="visually-hidden" {...filterInvalidDOMProps(rest)}>
      {label || startCase(name)}
    </legend>
  )
}

HiddenLegend.propTypes = propTypes
HiddenLegend.defaultProps = defaultProps

export default pure(HiddenLegend)
