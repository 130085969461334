import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { onMount, waitFor } from 'lp-hoc'
import * as apiActions from 'api-actions'
import { selectors as globalSelectors } from './reducer'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function GlobalLayout({ children }) {
  return <React.Fragment>{children}</React.Fragment>
}

GlobalLayout.propTypes = propTypes
GlobalLayout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    disciplines: globalSelectors.disciplines(state),
    resourceTypes: globalSelectors.resourceTypes(state),
    schoolYears: globalSelectors.schoolYears(state),
    programTypes: globalSelectors.orderedProgramTypes(state),
  }
}

const mapDispatchToProps = {
  fetchDisciplines: apiActions.fetchDisciplines,
  fetchResourceTypes: apiActions.fetchResourceTypes,
  fetchSchoolYears: apiActions.fetchSchoolYears,
  fetchProgramTypes: apiActions.fetchProgramTypes,
}

const fetchAppWideData = ({
  fetchDisciplines,
  fetchResourceTypes,
  fetchSchoolYears,
  fetchProgramTypes,
}) => {
  fetchDisciplines()
  fetchResourceTypes()
  fetchSchoolYears()
  fetchProgramTypes()
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  onMount(fetchAppWideData),
  waitFor(['disciplines', 'resourceTypes', 'schoolYears', 'programTypes'])
)(GlobalLayout)
