import { castArray, clone } from 'lodash'
import { curry } from 'lodash/fp'
import pluralize from './pluralize'

// Converts a given key or keys of an object to an array attribute,
// pluralizing the key and putting the value in an array
// E.g. toHasManyAttribute('name', { name: 'Bob' }) -> { names: ['Bob'] }

function toHasManyAttribute(keys, obj) {
  const newObj = clone(obj)
  const allKeys = castArray(keys)
  allKeys.forEach((key) => {
    const value = newObj[key]
    if (value === undefined) return
    newObj[pluralize(key)] = [value]
    delete newObj[key]
  })
  return newObj
}

export default curry(toHasManyAttribute)
