import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { BLANK_VALUE } from 'types'
import {
  Input,
  fieldPropTypes,
  fieldOptionsType,
  omitLabelProps,
  LabeledField,
  serializeOptions,
} from 'lp-components'
import { orderLastBy } from 'utils'
import { isString, isNumber } from 'lodash'
import classnames from 'classnames'
import { isOtherOption } from './helpers'

const propTypes = {
  ...fieldPropTypes,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }),
  options: fieldOptionsType,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  // Only use ariaLabelledby OR ariaLabel, not both
  ariaLabelledby: PropTypes.string,
  ariaLabel: PropTypes.string,
}

const defaultProps = {
  options: [],
  disabled: false,
  placeholder: '',
  ariaLabelledby: null,
  ariaLabel: null,
}

const uniqueId = ({ name, option, id = '' }) => {
  if (!id) return `${name}.${option.value}`
  return `${id}.${option.value}`
}

function RadioGroupWithOtherOption({
  options,
  disabled,
  placeholder,
  ariaLabelledby,
  ariaLabel,
  ...props
}) {
  const {
    id,
    input: { value, onChange, name },
    meta: { form },
    ...rest
  } = omitLabelProps(props)
  const getOtherValue = useCallback(() => {
    if (!value || isNumber(value) || value === BLANK_VALUE) return ''
    return value
  }, [value])
  const optionObjects = serializeOptions(options)
  const orderedOptionObjects = useMemo(
    () => orderLastBy(isOtherOption, optionObjects),
    [optionObjects]
  )
  const otherTextName = form + '-' + name
  return (
    <LabeledField className="RadioGroup" {...props}>
      <fieldset
        role="radiogroup"
        aria-labelledby={ariaLabelledby}
        aria-label={ariaLabel}
      >
        {orderedOptionObjects.map((option) => {
          const isOther = isOtherOption(option)
          return (
            <span
              key={option.key}
              className={classnames({ 'full-width-other': isOther })}
            >
              <Input
                {...{
                  type: 'radio',
                  input: {
                    name, // all radio inputs must share the same name
                    value: '',
                    onChange: () => {
                      if (!isOther) return onChange(option.value)
                      return onChange(BLANK_VALUE)
                    },
                  },
                  id: uniqueId({ name, option, id }), // override Input default behavior to assign id to input: { name }
                  meta: {},
                  checked: isOther ? isString(value) : value === option.value,
                  label: option.key,
                  className: 'radio-button',
                  disabled,
                  ...rest,
                }}
              />
              {isOther && (
                <Input
                  placeholder={placeholder}
                  label={false}
                  aria-label={option.key}
                  input={{
                    name: otherTextName,
                    value: getOtherValue(),
                    onChange,
                  }}
                  meta={{}}
                  className="radio-text-field"
                  disabled={disabled}
                />
              )}
            </span>
          )
        })}
      </fieldset>
    </LabeledField>
  )
}

RadioGroupWithOtherOption.propTypes = propTypes
RadioGroupWithOtherOption.defaultProps = defaultProps

export default RadioGroupWithOtherOption
