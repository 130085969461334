import React from 'react'

function HeartOutlineIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="43"
      viewBox="0 0 48 43"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#494848"
          fillRule="nonzero"
          d="M24.001 4.144A14.081 14.081 0 0014.01 0C9.967 0 5.929 1.724 3.083 5.174c-4.7 5.7-3.854 14.188 1.363 19.417L22 42.17c.553.554 1.277.829 2 .829.72 0 1.444-.275 1.998-.83l17.555-17.58c5.217-5.227 6.062-13.716 1.362-19.416A14.089 14.089 0 0033.991 0a14.07 14.07 0 00-9.99 4.144zM40.56 8.78c2.735 3.317 2.294 8.503-1 11.805L24 36.167 8.443 20.585C5.145 17.282 4.705 12.097 7.44 8.78a8.495 8.495 0 016.568-3.12c2.264 0 4.301.535 6.096 2.489L24 11.68l3.852-3.532C30.16 6.114 31.727 5.66 33.99 5.66A8.496 8.496 0 0140.56 8.78z"
        ></path>
      </g>
    </svg>
  )
}

export default HeartOutlineIcon
