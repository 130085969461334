import { curry } from 'lodash/fp'

// Formats array of objects to an array of their ids

function formatObjectsToIds(key, objects) {
  if (!objects) return []
  return objects.map((object) => object[key])
}

export default curry(formatObjectsToIds)
