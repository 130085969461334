import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import { AuthorizedRoute, UnauthorizedRoute } from 'components'
import LayoutUnauthorized from './layout-unauthorized'
import LayoutAuthorized from './layout-authorized'
import { routes as AccountRoutes } from './account'
import { routes as DashboardRoutes } from './dashboard'
// import { routes as ReportsRoutes } from './reports'
import { routes as SurveyRoutes } from './survey'
import { routes as UserRoutes } from './user'
import { routes as SummaryRoutes } from './summary'
import { isAuthenticated } from './auth'
import AdminRedirect from './admin-redirect'
import Terms from './terms'
import { SCHOOL_PORTAL_ROUTE } from 'config'

const Routes = (
  <Route path={SCHOOL_PORTAL_ROUTE}>
    <IndexRedirect to="dashboard" />
    <Route path="admin-redirect" component={AdminRedirect} />

    {/* Authorized Routes */}
    <AuthorizedRoute
      authFunction={isAuthenticated}
      redirect={SCHOOL_PORTAL_ROUTE + '/sign-in'}
    >
      <Route path={SCHOOL_PORTAL_ROUTE + '/terms'} component={Terms} />
      <Route component={LayoutAuthorized}>
        {AccountRoutes}
        {DashboardRoutes}
        {SummaryRoutes}
        {/* {ReportsRoutes} */}
        {SurveyRoutes}
      </Route>
    </AuthorizedRoute>

    {/* Unauthorized Routes */}
    <UnauthorizedRoute
      authFunction={isAuthenticated}
      redirect={SCHOOL_PORTAL_ROUTE}
      component={LayoutUnauthorized}
    >
      {UserRoutes}
    </UnauthorizedRoute>
  </Route>
)

export default Routes
