import React from 'react'

function StarIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <path
          stroke="#FFF"
          strokeWidth="1.5"
          d="M10 13.875L5.44466429 16.2698817 6.314656 11.1974409 2.629312 7.60511829 7.72233215 6.86505915 10 2.25 12.2776679 6.86505915 17.370688 7.60511829 13.685344 11.1974409 14.5553357 16.2698817z"
        ></path>
      </g>
    </svg>
  )
}

export default StarIcon
