import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { ContentBlock, FormBlock } from 'components'
import * as apiActions from 'api-actions'
import * as routerActions from 'react-router-redux'
import { ClaimAccountForm } from '../forms'
import { SCHOOL_PORTAL_ROUTE } from 'config'
import { displaySubmitFailure } from 'utils'

const propTypes = {
  claimAccount: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
}

const defaultProps = {}

function ClaimAccount({ claimAccount, push }) {
  return (
    <ContentBlock>
      <FormBlock>
        <div className="form-block-inner">
          <h2>Create your account</h2>
          <p>We'll have you set up in no time!</p>
          <p>First, tell us your work or school email address.</p>
          <ClaimAccountForm
            onSubmit={claimAccount}
            onSubmitSuccess={() => {
              return push(SCHOOL_PORTAL_ROUTE + '/claim-account-confirmation')
            }}
            onSubmitFail={displaySubmitFailure}
          />
        </div>
      </FormBlock>
    </ContentBlock>
  )
}

ClaimAccount.propTypes = propTypes
ClaimAccount.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  claimAccount: apiActions.claimAccount,
  push: routerActions.push,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ClaimAccount
)
