import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { persistSubmitSucceeded } from 'utils'
import { AccessibleProgramsTable, DisciplineSectionHeader } from '../components'
import { Searchable, EmptyState } from 'components'
import { ControlledExpandableItem } from 'components/expandable-item'
import { SubmitButton, ButtonArea } from 'lp-components'
import { lpForm } from 'lp-form'
import {
  FormSection,
  propTypes as formPropTypes,
  formValueSelector,
} from 'redux-form'
import { compact, filter, isEmpty, includes, toLower, trim } from 'lodash'

const propTypes = {
  ...formPropTypes,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  currentAccessibleProgramsByDiscipline: Types.accessibleProgramsByDiscipline,
}

const defaultProps = {
  currentAccessibleProgramsByDiscipline: {},
}

const FORM_NAME = 'school-accessible-programs'

function AccessibleProgramsForm({
  disciplines,
  handleSubmit,
  submitSucceeded,
  pristine,
  submitting,
  change,
  currentAccessibleProgramsByDiscipline,
}) {
  const [expandedItems, setExpandedItems] = useState({})

  const handleOfferingChange = useCallback(
    ({ fieldName, newOffering, programName, programId }) => {
      // don't make any additonal form data changes if offering has been removed
      if (newOffering === Types.SUB_DISCIPLINE_OFFER_TYPES.NONE) return
      // otherwise set additional values on the sub-discipline that will be needed to submit
      const formFieldToChange = fieldName.replace('.tag', '')
      change(formFieldToChange + '.name', programName)
      change(formFieldToChange + '.subDisciplineId', programId)
    },
    [change]
  )

  const handleSearch = useCallback(
    (query) => {
      if (isEmpty(query)) {
        setExpandedItems({})
        return disciplines
      }

      const normalizedQuery = trim(toLower(query))
      const matchedDisciplines = disciplines.map((discipline) => {
        const matchedSubDisciplines = filter(
          discipline.subDisciplines,
          (subdiscipline) => {
            return includes(toLower(subdiscipline.displayName), normalizedQuery)
          }
        )
        if (isEmpty(matchedSubDisciplines)) return null
        return { ...discipline, subDisciplines: matchedSubDisciplines }
      })
      const compactedMatchedDisciplines = compact(matchedDisciplines)
      setExpandedItems(
        compactedMatchedDisciplines.reduce((myExpandedItems, discipline) => {
          return { ...myExpandedItems, [discipline.id]: true }
        }, {})
      )

      return compactedMatchedDisciplines
    },
    [disciplines]
  )

  return (
    <form onSubmit={handleSubmit}>
      <Searchable
        initialResults={disciplines}
        onSearch={handleSearch}
        placeholder="Search for arts programs or select from the lists below"
        label="Search"
      >
        {(results) => {
          if (isEmpty(results))
            return <EmptyState message="Sorry, no programs match your search" />
          return (
            <div className="expandable-section">
              {results.map((discipline) => {
                const {
                  id,
                  displayName,
                  subDisciplines: programs,
                  name,
                } = discipline
                const currentAccessiblePrograms =
                  currentAccessibleProgramsByDiscipline[name]
                const accessibleProgramsCount = filter(
                  currentAccessiblePrograms,
                  (program) =>
                    program.tag !== Types.SUB_DISCIPLINE_OFFER_TYPES.NONE
                ).length
                return (
                  <ControlledExpandableItem
                    key={id}
                    expanded={!!expandedItems[id]}
                    setExpanded={(isExpanded) =>
                      setExpandedItems({ ...expandedItems, [id]: isExpanded })
                    }
                    headerComponent={DisciplineSectionHeader}
                    headerTitle={displayName}
                    programsCount={programs.length}
                    accessibleProgramsCount={accessibleProgramsCount}
                  >
                    <FormSection name={'programsByDiscipline.' + name}>
                      <AccessibleProgramsTable
                        programs={programs}
                        currentAccessiblePrograms={currentAccessiblePrograms}
                        handleOfferingChange={handleOfferingChange}
                      />
                    </FormSection>
                  </ControlledExpandableItem>
                )
              })}
            </div>
          )
        }}
      </Searchable>
      <ButtonArea>
        <SubmitButton
          {...{ pristine: submitSucceeded && pristine, submitting }}
        >
          Save Response
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

AccessibleProgramsForm.propTypes = propTypes
AccessibleProgramsForm.defaultProps = defaultProps

const selectFormValue = formValueSelector(FORM_NAME)

function mapStateToProps(state) {
  return {
    currentAccessibleProgramsByDiscipline: selectFormValue(
      state,
      'programsByDiscipline'
    ),
  }
}

export default compose(
  connect(mapStateToProps),
  lpForm({
    name: FORM_NAME,
  }),
  persistSubmitSucceeded()
)(AccessibleProgramsForm)
