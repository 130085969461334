import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import whiteCheckmarkImage from 'images/white-check.svg'
import classnames from 'classnames'
import ProgramsTabCardIcon from './programs-tab-card-icon'
import { removeOtherType } from 'utils'

const propTypes = {
  artsProgrammingResources: PropTypes.arrayOf(Types.requestedInterest)
    .isRequired,
  allProgramTypes: PropTypes.arrayOf(Types.programType).isRequired,
  className: PropTypes.string,
}

const defaultProps = {
  className: '',
}

function ProgramsTabContent({
  artsProgrammingResources,
  className,
  allProgramTypes,
}) {
  const allSpecificProgramTypes = removeOtherType(allProgramTypes)
  const requestedProgramNames = useMemo(
    () => artsProgrammingResources.map((resource) => resource.name),
    [artsProgrammingResources]
  )

  return (
    <div className={classnames('colored-block card-grid', className)}>
      {allSpecificProgramTypes.map(({ id, displayName, name, definition }) => (
        <div
          key={id}
          className={classnames('stat-card', 'card', {
            'none-selected': !requestedProgramNames.includes(name),
          })}
        >
          <div className="stat-inner-with-image">
            <div className="image-block">
              <ProgramsTabCardIcon programName={name} />
              <span>
                <img src={whiteCheckmarkImage} alt="Checkmark" />
              </span>
            </div>
            <div className="stack-text">
              <p>{displayName}</p>
              {definition && (
                <span className="small-definition">{definition}</span>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

ProgramsTabContent.propTypes = propTypes
ProgramsTabContent.defaultProps = defaultProps

export default ProgramsTabContent
