import React from 'react'
import { Route } from 'react-router'
import Layout from './layout'
import { routes as SchoolRoutes } from './schools'
import { routes as SearchRoutes } from './search'
import { routes as PartnerRoutes } from './partners'

const Routes = (
  <Route component={Layout}>
    {SearchRoutes}
    {SchoolRoutes}
    {PartnerRoutes}
  </Route>
)

export default Routes
