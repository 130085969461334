import { values } from 'lodash'
import { schoolYears } from '../utils'

export const years = values(schoolYears)

export const teacherSubjects = [
  { key: 'Dance', value: 'Dance' },
  { key: 'Music', value: 'Music' },
  { key: 'Theater', value: 'Theater' },
  { key: 'Visual Arts', value: 'Visual Arts' },
  { key: 'Literary Arts', value: 'Literary Arts' },
  { key: 'Media Arts', value: 'Media Arts' },
  { key: 'No Arts Instructors', value: 'None', none: true },
]

export const partnerDisciplines = [
  'Dance',
  'Music',
  'Theater',
  'Visual Arts',
  'Literary Arts',
  'Media Arts',
]

export const networks = [
  'Network 1',
  'Network 2',
  'Network 3',
  'Network 4',
  'Network 5',
  'Network 6',
  'Network 7',
  'Network 8',
  'Network 9',
  'Network 10',
  'Network 11',
  'Network 12',
  'Network 13',
  'AUSL',
  'Charter',
  'Contract',
  'Military',
  'Options',
  'OS4',
]

export const zipCodes = [
  '60601',
  '60602',
  '60603',
  '60604',
  '60605',
  '60606',
  '60607',
  '60608',
  '60609',
  '60610',
  '60611',
  '60612',
  '60613',
  '60614',
  '60615',
  '60616',
  '60617',
  '60618',
  '60619',
  '60620',
  '60621',
  '60622',
  '60623',
  '60624',
  '60625',
  '60626',
  '60628',
  '60629',
  '60630',
  '60631',
  '60632',
  '60633',
  '60634',
  '60636',
  '60637',
  '60638',
  '60639',
  '60640',
  '60641',
  '60642',
  '60643',
  '60643',
  '60644',
  '60645',
  '60646',
  '60647',
  '60649',
  '60651',
  '60652',
  '60653',
  '60654',
  '60655',
  '60656',
  '60657',
  '60659',
  '60660',
  '60661',
  '60666',
  '60707',
  '60827',
]

export const wards = [
  'Ward 1',
  'Ward 2',
  'Ward 3',
  'Ward 4',
  'Ward 5',
  'Ward 6',
  'Ward 7',
  'Ward 8',
  'Ward 9',
  'Ward 10',
  'Ward 11',
  'Ward 12',
  'Ward 13',
  'Ward 14',
  'Ward 15',
  'Ward 16',
  'Ward 17',
  'Ward 18',
  'Ward 19',
  'Ward 20',
  'Ward 21',
  'Ward 22',
  'Ward 23',
  'Ward 24',
  'Ward 25',
  'Ward 26',
  'Ward 27',
  'Ward 28',
  'Ward 29',
  'Ward 30',
  'Ward 31',
  'Ward 32',
  'Ward 33',
  'Ward 34',
  'Ward 35',
  'Ward 36',
  'Ward 37',
  'Ward 38',
  'Ward 39',
  'Ward 40',
  'Ward 41',
  'Ward 42',
  'Ward 43',
  'Ward 44',
  'Ward 45',
  'Ward 46',
  'Ward 47',
  'Ward 48',
  'Ward 49',
  'Ward 50',
]

export const communityAreas = [
  'Community Area',
  'Albany Park',
  'Archer Heights',
  'Armour Square',
  'Ashburn',
  'Auburn Gresham',
  'Austin',
  'Avalon Park',
  'Avondale',
  'Belmont Cragin',
  'Beverly',
  'Bridgeport',
  'Brighton Park',
  'Burnside',
  'Calumet Heights',
  'Chatham',
  'Chicago Lawn',
  'Clearing',
  'Douglas',
  'Dunning',
  'East Garfield Park',
  'East Side',
  'Edgewater',
  'Edison Park',
  'Englewood',
  'Forest Glen',
  'Fuller Park',
  'Gage Park',
  'Garfield Ridge',
  'Grand Boulevard',
  'Greater Grand Crossing',
  'Hegewisch',
  'Hermosa',
  'Humboldt Park',
  'Hyde Park',
  'Irving Park',
  'Jefferson Park',
  'Kenwood',
  'Lake View',
  'Lincoln Park',
  'Lincoln Square',
  'Logan Square',
  'Loop',
  'Lower West Side',
  'Mckinley Park',
  'Montclare',
  'Morgan Park',
  'Mount Greenwood',
  'Near North Side',
  'Near South Side',
  'Near West Side',
  'New City',
  'North Center',
  'North Lawndale',
  'North Park',
  'Norwood Park',
  "O'Hare",
  'Oakland',
  'Portage Park',
  'Pullman',
  'Riverdale',
  'Rogers Park',
  'Roseland',
  'South Chicago',
  'South Deering',
  'South Lawndale',
  'South Shore',
  'Uptown',
  'Washington Heights',
  'Washington Park',
  'West Elsdon',
  'West Englewood',
  'West Garfield Park',
  'West Lawn',
  'West Pullman',
  'West Ridge',
  'West Town',
  'Woodlawn',
]

export const certificationLevels = [
  'Excelling',
  'Strong',
  'Developing',
  'Emerging',
  'Incomplete',
]

export const governances = [
  'ALOP',
  'Alternative',
  'Charter',
  'Contract',
  'District',
  'Performance',
  'Public',
  'Regular',
  'SAFE',
]

export const programTypes = [
  'Field Trip',
  'Out-of-School Time',
  'Professional Development',
  'Residency (RESI)',
  'Resources',
  'In-School Performance',
  'Other',
]

export const schoolTypes = ['Elementary', 'Middle School', 'High School']
