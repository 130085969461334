// Autosuggest helpers
import orderBy from 'lodash/orderBy'

// Like indexOf, but ignores case and punctuation.
// Returns index + 1 so we can use it for a falsey check.
function match(bodyText, queryText) {
  const normalizedBodyText = stripPunctuation(bodyText.toLowerCase())
  const normalizedQueryText = stripPunctuation(queryText.toLowerCase())
  return normalizedBodyText.indexOf(normalizedQueryText) + 1
}

function stripPunctuation(text = '') {
  return text.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '').replace(/\s{2,}/g, ' ')
}

export function filterSuggestions(suggestions, value, limit = 20) {
  if (!value) return suggestions.slice(0, limit + 1)

  // Filter out non-matched suggestions
  const filtered = suggestions.filter((suggestion) =>
    match(suggestion.name, value)
  )
  // Compile suggestions into objects with "priority"
  const prioritized = filtered.map((suggestion) => ({
    suggestion,
    priority: match(suggestion.name, value),
  }))
  // Order suggestions by priority
  const ordered = orderBy(prioritized, 'priority')
  // Deserialize prioritization objects
  const deserialized = ordered.map(({ suggestion }) => suggestion)
  // Limit results
  const limited = deserialized.slice(0, limit + 1)
  return limited
}

// Splits text by the first occurrence of a delimiter
export function splitText(text, delimiter) {
  const delimiterStart = match(text, delimiter) - 1
  const delimiterEnd = delimiterStart + delimiter.length
  const startText = text.slice(0, delimiterStart)
  const middleText = text.slice(delimiterStart, delimiterEnd)
  const endText = text.slice(delimiterEnd)
  return [startText, middleText, endText]
}
