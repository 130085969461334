import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { lpForm } from 'lp-form'
import { Field, FieldArray } from 'redux-form'
import { Input, SubmitButton } from 'lp-components'
import { validateNested } from 'utils'
import { SectionBlock, SectionHeader } from 'components'
import { startCase } from 'lodash'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

const defaultProps = {}

function ContactFields({ fields }) {
  return (
    <React.Fragment>
      {fields.map((prefix, idx) => {
        const contact = fields.get(idx)
        return (
          <SectionBlock key={idx}>
            <SectionHeader>
              <h3 id={`contact-${idx}`}>{startCase(contact.position)}</h3>
            </SectionHeader>
            <fieldset
              className="contact-fields outer"
              aria-labelledby={`contact-${idx}`}
            >
              <Field name={`${prefix}.firstName`} component={Input} />
              <Field name={`${prefix}.lastName`} component={Input} />
              <Field
                name={`${prefix}.title`}
                label="Job Title"
                component={Input}
              />
              <Field name={`${prefix}.phone`} component={Input} />
              <Field name={`${prefix}.email`} component={Input} />
            </fieldset>
          </SectionBlock>
        )
      })}
    </React.Fragment>
  )
}

function OrganizationContactsForm({ handleSubmit, submitting }) {
  return (
    <form onSubmit={handleSubmit}>
      <FieldArray name="contacts" component={ContactFields} />
      <SubmitButton submitting={submitting}>Save</SubmitButton>
    </form>
  )
}

OrganizationContactsForm.propTypes = propTypes
OrganizationContactsForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'partner-organization-contacts',
    enableReinitialize: true,
    submitFilters: {
      allow: ['id', 'contacts'],
    },
    validate: validateNested({
      'contacts[].firstName': { presence: true },
      'contacts[].lastName': { presence: true },
      'contacts[].phone': {
        presence: true,
        format: {
          pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          message: 'must be a valid phone number',
        },
      },
      'contacts[].email': { presence: true, email: true },
    }),
  })
)(OrganizationContactsForm)
