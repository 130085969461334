import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import {
  fieldPropTypesWithValue,
  omitLabelProps,
  LabeledField,
} from 'lp-components'
import { CloudinaryFileInput, RemovableAttachments } from 'components'
import {
  convertCloudinarySrcUrlToFilename,
  getCloudinaryUploadErrorMessage,
} from 'utils'
import { get, last, noop } from 'lodash'

const propTypes = {
  ...fieldPropTypesWithValue(PropTypes.arrayOf(Types.attachmentUpload)),
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
}

const defaultProps = {}

function MultiAttachmentInput(props) {
  const {
    input: { value: attachments, onChange, name },
    className, // eslint-disable-line no-unused-vars
    flashSuccessMessage,
    flashErrorMessage,
    ...rest
  } = omitLabelProps(props)

  const handleSuccess = ({ secureUrl }) => {
    const filename = convertCloudinarySrcUrlToFilename(secureUrl)
    flashSuccessMessage('Attachment successfully uploaded')
    return onChange([...attachments, { src: secureUrl, filename }])
  }

  const handleFailure = (err) => {
    const message = getCloudinaryUploadErrorMessage(err)
    flashErrorMessage(message)
  }

  const handleRemove = (srcToRemove) => {
    flashSuccessMessage('Attachment successfully removed')
    return onChange(attachments.filter(({ src }) => src !== srcToRemove))
  }

  return (
    <LabeledField {...props}>
      <CloudinaryFileInput
        {...{
          id: name,
          onUploadSuccess: handleSuccess,
          onUploadFailure: handleFailure,
          previewComponent: RemovableAttachments,
          remove: handleRemove,
          attachments,
          input: {
            name,
            value: get(last(attachments), 'src', ''),
            onChange: noop, // use onUploadSuccess/Failure instead
          },
          label: false, // use label created by Redux Field
          ...rest,
        }}
      />
    </LabeledField>
  )
}

MultiAttachmentInput.propTypes = propTypes
MultiAttachmentInput.defaultProps = defaultProps

export default MultiAttachmentInput
