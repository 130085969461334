import { mapKeys, curry } from 'lodash/fp'

// Let's get functional!

// Renames a single key of an object
const renameKey = curry((oldName, newName, obj) =>
  mapKeys((key) => (key === oldName ? newName : key), obj)
)

export default renameKey
