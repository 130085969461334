import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import ResourcesTableFolderActionMenu from './resources-table-folder-action-menu'
import ResourcesTableAttachmentActionMenu from './resources-table-attachment-action-menu'
import ResourcesTableFolder from './resources-table-folder'
import ResourcesTableAttachment from './resources-table-attachment'
import { isEmpty } from 'lodash'

const propTypes = {
  folders: PropTypes.arrayOf(Types.indexFolder).isRequired,
  attachments: PropTypes.arrayOf(Types.attachment).isRequired,
  canModify: PropTypes.bool.isRequired,
  editAttachment: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  deleteFolder: PropTypes.func.isRequired,
  copResourcesIndexPath: PropTypes.string.isRequired,
}

const defaultProps = {}

function ResourcesTable({
  folders,
  attachments,
  canModify,
  editAttachment,
  deleteAttachment,
  deleteFolder,
  copResourcesIndexPath,
}) {
  return (
    <table className="resources-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Created At</th>
          <th>Created By</th>
          {canModify && <th aria-label="Action menu"></th>}
        </tr>
      </thead>
      <tbody>
        {folders.map((folder) => (
          <ResourcesTableFolder
            key={folder.id}
            folder={folder}
            copResourcesIndexPath={copResourcesIndexPath}
          >
            {canModify && (
              <td className="action-menu">
                <ResourcesTableFolderActionMenu
                  deleteFolder={() => deleteFolder(folder.id)}
                />
              </td>
            )}
          </ResourcesTableFolder>
        ))}
        {attachments.map((attachment) => (
          <ResourcesTableAttachment key={attachment.id} attachment={attachment}>
            {canModify && (
              <td>
                <ResourcesTableAttachmentActionMenu
                  editAttachment={() => editAttachment(attachment)}
                  deleteAttachment={() => deleteAttachment(attachment.id)}
                />
              </td>
            )}
          </ResourcesTableAttachment>
        ))}
        {isEmpty(folders) && isEmpty(attachments) && (
          <tr>
            <td>No Resources</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

ResourcesTable.propTypes = exact(propTypes)
ResourcesTable.defaultProps = defaultProps

export default pure(ResourcesTable)
