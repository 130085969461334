import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { lpForm } from 'lp-form'
import { Field, propTypes as formPropTypes, FormSection } from 'redux-form'
import { ButtonArea, SubmitButton } from 'lp-components'
import { RadioInput } from '../components'
import { persistSubmitSucceeded } from 'utils'

const propTypes = {
  ...formPropTypes,
  ariaLabelledby: PropTypes.string.isRequired,
}

const defaultProps = {}

function ProfessionalDevelopmentForm({
  handleSubmit,
  saved,
  submitting,
  ariaLabelledby,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <FormSection name="detail">
        <Field
          name="professionalDevelopment"
          component={RadioInput}
          className="field radio-buttons"
          ariaLabelledby={ariaLabelledby}
        />
      </FormSection>
      <br />
      <ButtonArea>
        <SubmitButton {...{ pristine: saved, submitting }}>
          Save Response
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

ProfessionalDevelopmentForm.propTypes = propTypes
ProfessionalDevelopmentForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'school-professional-development',
    enableReinitialize: true,
  }),
  persistSubmitSucceeded()
)(ProfessionalDevelopmentForm)
