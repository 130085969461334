import React from 'react'
import { FieldArray } from 'redux-form'
import { cloneDeep as copy, find, findLast, isNumber } from 'lodash'

// An extension of the redux-form FieldArray that adds a fields.destroy(index) method.
// If called, this method will set { _destroy: true } on the given field.
// Additionally, fields that have been destroyed will be skipped by fields.map().

/* eslint react/prop-types: 0 */

function NestedAttributesFieldArray({ component, ...rest }) {
  return (
    <FieldArray
      {...{
        component: renderArray,
        _wrappedComponent: component,
        rerenderOnEveryChange: true,
        ...rest,
      }}
    />
  )
}

function renderArray({ fields, _wrappedComponent: Wrapped, ...rest }) {
  return <Wrapped {...{ fields: modifyFields(fields), ...rest }} />
}

function modifyFields(passedFields) {
  const fields = copy(passedFields)
  // add destroy method
  fields.destroy = function (index) {
    const fieldValues = fields.get(index)
    fields.remove(index)
    fields.insert(index, { ...fieldValues, _destroy: true })
  }
  // modify map method to skip destroyed fields
  const oldMap = fields.map
  fields.getNameIndexPairs = function () {
    return oldMap((name, index) => ({ name, index })).filter(({ index }) => {
      const field = fields.get(index)
      return !(field && field._destroy)
    })
  }
  fields.map = function (iteratee) {
    return fields
      .getNameIndexPairs()
      .map(({ name, index }, position) => iteratee(name, index, position))
  }
  // modify length to use new map
  Object.defineProperty(fields, 'length', {
    get: () => fields.getNameIndexPairs().length,
  })
  fields.moveBack = function (index) {
    const prevItemIndex = findPrevItemIndex(fields, index)
    if (isNumber(prevItemIndex)) return fields.swap(index, prevItemIndex)
  }
  fields.moveForward = function (index) {
    const nextItemIndex = findNextItemIndex(fields, index)
    if (isNumber(nextItemIndex)) return fields.swap(index, nextItemIndex)
  }
  return fields
}

// HELPERS

function findPrevItemIndex(fields, index) {
  const fieldIndices = fields.map((name, i) => i)
  return findLast(fieldIndices, (i) => i < index)
}

function findNextItemIndex(fields, index) {
  const fieldIndices = fields.map((name, i) => i)
  return find(fieldIndices, (i) => i > index)
}

export default NestedAttributesFieldArray
