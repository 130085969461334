import isNoneType from './is-none-type'

// Returns id of the type with name "none" in an array of types

function getNoneTypeId(types) {
  const noneType = types.find(isNoneType)
  return noneType ? noneType.id : null
}

export default getNoneTypeId
