import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { lpForm } from 'lp-form'
import { modifyProps } from 'lp-hoc'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { CheckboxWithText, CheckboxFieldset } from 'components'
import { InputError, SubmitButton, CheckboxGroup } from 'lp-components'
import * as Types from 'types'
import {
  getOtherTypeId,
  formatObjectsToIds,
  parseIdsToObjects,
  replaceResources,
  yankBy,
  removeOtherType,
  persistSubmitSucceeded,
} from 'utils'

const propTypes = {
  ...formPropTypes,
  scheduleTypes: PropTypes.arrayOf(Types.scheduleType).isRequired,
}

const SCHEDULE_LABELS = {
  daily: 'Daily (the same classes are held every day in the same sequence)',
  rotating:
    'Rotating (the same classes are held every day but in differing sequences)',
  alternating:
    'Alternating (your class schedule alternates over a specified period of time such as daily, weekly, quarterly, etc.)',
}

function serializeScheduleTypeOptions(scheduleTypes) {
  return scheduleTypes.map(({ id, name }) => ({
    key: SCHEDULE_LABELS[name],
    value: id,
  }))
}

function ScheduleForm({
  handleSubmit,
  saved,
  submitting,
  scheduleTypes,
  error,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <CheckboxFieldset invalid={!!error}>
        <Field
          name="schedules"
          label={false}
          component={CheckboxGroup}
          format={formatObjectsToIds('scheduleTypeId')}
          parse={parseIdsToObjects('scheduleTypeId')}
          options={serializeScheduleTypeOptions(removeOtherType(scheduleTypes))}
        />
        <Field
          name="otherText"
          label="Other (please describe):"
          placeholder="Description..."
          component={CheckboxWithText}
          className="CheckboxGroup full-width-other"
        />
      </CheckboxFieldset>
      {error && <InputError error={error} touched invalid />}
      <div className="button-wrapper">
        <SubmitButton {...{ pristine: saved, submitting }}>
          Save Response
        </SubmitButton>
      </div>
    </form>
  )
}

ScheduleForm.propTypes = propTypes

function modifyOtherTypeId({ scheduleTypes }) {
  return {
    otherTypeId: getOtherTypeId(scheduleTypes),
  }
}

function modifyInitialValues({ initialValues, otherTypeId }) {
  const [otherSchedule, schedules] = yankBy(
    initialValues.schedules,
    ({ scheduleTypeId }) => scheduleTypeId === otherTypeId
  )
  return {
    initialValues: {
      ...initialValues,
      schedules,
      otherText: otherSchedule ? otherSchedule.otherText : '',
    },
  }
}

function modifyBeforeSubmit({ otherTypeId, initialValues }) {
  return {
    beforeSubmit: ({ schedules, otherText }) => {
      const resources = replaceResources({
        old: initialValues.schedules,
        new: schedules,
      })
      if (otherText) resources.push({ scheduleTypeId: otherTypeId, otherText })

      return { schedules: resources }
    },
  }
}

export default compose(
  modifyProps(modifyOtherTypeId),
  modifyProps(modifyBeforeSubmit),
  modifyProps(modifyInitialValues),
  lpForm({
    name: 'school-schedule',
    enableReinitialize: true,
    constraints: {
      otherText: {
        exclusion: {
          within: [' '],
          message: '^Description is required',
        },
      },
    },
  }),
  persistSubmitSucceeded()
)(ScheduleForm)
