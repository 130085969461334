import { useEffect, useRef } from 'react'

// Invoke a function when user "clicks" outside of an element
// Note: keyboard-only users are supported by listening to 'keyup'
function useOnOutsideClick(func) {
  const refContainer = useRef(null)

  const handleOutsideClick = (e) => {
    if (
      !refContainer ||
      !refContainer.current ||
      refContainer.current.contains(e.target)
    )
      return
    func(e)
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('keyup', handleOutsideClick)
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('keyup', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
      document.removeEventListener('keyup', handleOutsideClick)
    }
  }, [func])

  return refContainer
}

export default useOnOutsideClick
