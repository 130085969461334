import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { fieldPropTypes } from 'lp-components'
import { merge } from 'lodash'

const propTypes = {
  ...merge(fieldPropTypes, { input: Types.input }),
  className: PropTypes.string.isRequired,
  // Only use ariaLabelledby OR ariaLabel, not both
  ariaLabel: PropTypes.string,
  ariaLabelledby: PropTypes.string,
}

const defaultProps = {
  ariaLabel: null,
  ariaLabelledby: null,
}

function RadioInput({ input, className, ariaLabel, ariaLabelledby }) {
  return (
    <fieldset
      className={className}
      role="radiogroup"
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledby}
    >
      <label className="radio-button">
        <input
          {...input}
          type="radio"
          value={true}
          checked={String(input.value) === 'true'}
        />
        <span>Yes</span>
      </label>
      <label className="radio-button">
        <input
          {...input}
          type="radio"
          value={false}
          checked={String(input.value) === 'false'}
        />
        <span>No</span>
      </label>
    </fieldset>
  )
}

RadioInput.propTypes = propTypes
RadioInput.defaultProps = defaultProps

export default RadioInput
