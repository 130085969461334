import React from 'react'

function AddIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#4A4A4A"
          fillRule="nonzero"
          d="M10.7017303 13.1818182L10.7017303 10.7170294 13.1818182 10.7170294 13.1818182 9.31498079 10.7017303 9.31498079 10.7017303 6.81818182 9.29826971 6.81818182 9.29826971 9.31498079 6.81818182 9.31498079 6.81818182 10.7170294 9.29826971 10.7170294 9.29826971 13.1818182z"
        ></path>
        <circle cx="10" cy="10" r="7" stroke="#4A4A4A"></circle>
      </g>
    </svg>
  )
}

export default AddIcon
