import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { PortalHeader } from 'components'
import { useCommunity } from 'utils'
import { selectors as globalSchoolSelectors } from 'school-portal-reducer'
import * as Types from 'types'
import { Spinner } from 'lp-components'

const propTypes = {
  children: PropTypes.node,
  school: Types.school,
}

const defaultProps = {
  school: null,
}

function Layout({ children, school }) {
  const {
    defaultBranding: { dashboardHeader },
  } = useCommunity()

  if (!school) return <Spinner />

  const { displayName } = school

  return (
    <div>
      <PortalHeader
        title={`${displayName} Home`}
        backgroundImage={dashboardHeader}
      />
      <div className="content-block-container">{children}</div>
    </div>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    school: globalSchoolSelectors.school(state),
  }
}

export default compose(connect(mapStateToProps, null))(Layout)
