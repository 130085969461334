import { createAction } from 'redux-actions'

export const setCurrentProgramYearTab = createAction(
  'SET_CURRENT_PROGRAM_YEAR_TAB'
)
export const setCurrentPartnershipYearTab = createAction(
  'SET_CURRENT_PARTNERSHIP_YEAR_TAB'
)
export const setPartnerships = createAction('SET_PARTNERSHIPS')
export const setPrograms = createAction('SET_PROGRAMS')
export const setAdditionalContact = createAction('SET_ADDITIONAL_CONTACT')
export const clearPartnershipQuestion = createAction(
  'CLEAR_PARTNERSHIP_QUESTION'
)
