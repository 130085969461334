import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ActionMenu } from 'components'
import { TrashIcon } from 'components/icons/basic'

const propTypes = {
  deleteFolder: PropTypes.func.isRequired,
}

const defaultProps = {}

function ResourcesTableFolderActionMenu({ deleteFolder }) {
  return (
    <ActionMenu
      actions={[
        {
          label: 'Delete Folder',
          icon: TrashIcon,
          onSelect: deleteFolder,
        },
      ]}
    />
  )
}

ResourcesTableFolderActionMenu.propTypes = exact(propTypes)
ResourcesTableFolderActionMenu.defaultProps = defaultProps

export default pure(ResourcesTableFolderActionMenu)
