import React from 'react'

function LiteraryArtsIcon() {
  return (
    <svg viewBox="0 0 50 50" width="82px" height="82px">
      <circle fill="#204A82" cx="25" cy="25" r="25" />
      <polygon
        fill="#FFFFFF"
        points="30.28 7.56 24.09 11.11 21.86 11.12 28.05 7.57 30.28 7.56"
      />
      <polygon
        fill="#FFFFFF"
        points="14.67 17.57 9.05 17.57 15.6 13.8 15.6 12.37 6.49 17.65 6.49 41.29 14.63 41.29 15.6 40.73 15.6 17.05 14.67 17.57"
      />
      <polygon
        fill="#FFFFFF"
        points="14.24 16.86 15.6 16.09 15.6 14.81 12 16.87 14.24 16.86"
      />
      <polygon
        fill="#FFFFFF"
        points="34.52 11.79 34.52 6.19 24.53 11.82 18.9 11.82 26.55 7.41 26.55 5.98 16.35 11.89 16.35 41.29 24.49 41.29 25.4 40.76 25.4 17.09 34.52 11.79"
      />
      <path
        fill="#204A82"
        className="fill"
        d="M10.56,33a.31.31,0,0,0-.13-.16.6.6,0,0,0-.2,0,.32.32,0,0,0-.16,0,.28.28,0,0,0-.13.15.73.73,0,0,0,0,.23v.49h.71v-.49A.61.61,0,0,0,10.56,33Z"
      />
      <polygon
        fill="#204A82"
        className="fill"
        points="11.36 36.38 10.14 36.79 11.36 37.21 11.36 36.38"
      />
      <path
        fill="#204A82"
        className="fill"
        d="M12,32.79a.35.35,0,0,0-.15-.18.4.4,0,0,0-.22,0,.44.44,0,0,0-.25.06.42.42,0,0,0-.13.21,1,1,0,0,0,0,.28v.57H12v-.59A.73.73,0,0,0,12,32.79Z"
      />
      <path
        fill="#204A82"
        className="fill"
        d="M8,19.08V39.81h6.1V19.08Zm4.71,16.85-.68.24v1.25l.68.24v.87L9.28,37.29v-1L12.66,35Zm0-1.39H9.28V33.23a4.33,4.33,0,0,1,0-.6.93.93,0,0,1,.12-.38A.64.64,0,0,1,9.69,32a.66.66,0,0,1,.37-.1.81.81,0,0,1,.44.13.91.91,0,0,1,.29.37h0a.93.93,0,0,1,.27-.53.77.77,0,0,1,.55-.19.85.85,0,0,1,.43.1.76.76,0,0,1,.32.27,1.22,1.22,0,0,1,.22.44,2.56,2.56,0,0,1,.06.61Zm0-4.37a1.63,1.63,0,0,1-.33.54,1.46,1.46,0,0,1-.55.36,2.26,2.26,0,0,1-.77.13,2.15,2.15,0,0,1-.73-.12,1.89,1.89,0,0,1-.56-.35,1.62,1.62,0,0,1-.35-.55,2.08,2.08,0,0,1-.12-.71,2.29,2.29,0,0,1,0-.39,1.65,1.65,0,0,1,.06-.32,1.69,1.69,0,0,1,.1-.27c0-.08.06-.16.09-.22h.82v.1l-.12.16-.15.22a1.11,1.11,0,0,0-.12.27.81.81,0,0,0-.05.31,1.29,1.29,0,0,0,.05.35.87.87,0,0,0,.2.31,1,1,0,0,0,.34.22,1.54,1.54,0,0,0,.52.08,1.4,1.4,0,0,0,.53-.09.92.92,0,0,0,.33-.23.75.75,0,0,0,.18-.31,1,1,0,0,0,.05-.33A1.17,1.17,0,0,0,12,29a1.82,1.82,0,0,0-.13-.28,1.91,1.91,0,0,0-.14-.21l-.13-.16v-.09h.81l.1.24a1.06,1.06,0,0,1,.09.25,2,2,0,0,1,.07.31,2.1,2.1,0,0,1,0,.4A2.28,2.28,0,0,1,12.62,30.17Z"
      />
      <path
        fill="#204A82"
        className="fill"
        d="M17.83,13.26V39.79h6.1V13.26Zm4.72,25.2H22v-.71H20.17v.71h-.55a3,3,0,0,0,0-.31.61.61,0,0,0-.07-.25.37.37,0,0,0-.14-.18.52.52,0,0,0-.23-.07V36.9H22v-.7h.59Zm0-3.12H22c-.14-.21-.3-.41-.46-.61a6.42,6.42,0,0,0-.43-.49,2,2,0,0,0-.46-.39.8.8,0,0,0-.4-.12.43.43,0,0,0-.35.15.61.61,0,0,0-.13.43,1.23,1.23,0,0,0,.09.44,1.94,1.94,0,0,0,.22.43v.07h-.76a4.43,4.43,0,0,1-.14-.48,3.38,3.38,0,0,1-.07-.63,1.51,1.51,0,0,1,.27-1,.92.92,0,0,1,.76-.33,1.17,1.17,0,0,1,.6.16,2.16,2.16,0,0,1,.6.49,4.25,4.25,0,0,1,.35.41l.23.3V32.68h.64Zm0-3.7a3.7,3.7,0,0,1-.15.49h-.74V32a2.19,2.19,0,0,0,.2-.46,1.66,1.66,0,0,0,.09-.5c0-.09,0-.18,0-.29a.67.67,0,0,0-.1-.26.35.35,0,0,0-.14-.13.37.37,0,0,0-.23,0,.39.39,0,0,0-.23.06.42.42,0,0,0-.11.18.77.77,0,0,0,0,.27v.48h-.61v-.19a3.11,3.11,0,0,0,0-.32,1.14,1.14,0,0,0,0-.23.31.31,0,0,0-.12-.15.41.41,0,0,0-.21-.05.25.25,0,0,0-.16.05.36.36,0,0,0-.1.14.52.52,0,0,0-.06.2,1.62,1.62,0,0,0,0,.21,1.56,1.56,0,0,0,.07.46,1.94,1.94,0,0,0,.22.49V32h-.74a2.62,2.62,0,0,1-.14-.5,2.88,2.88,0,0,1-.07-.65,3.18,3.18,0,0,1,0-.57,1.44,1.44,0,0,1,.15-.39.81.81,0,0,1,.27-.28.76.76,0,0,1,.38-.09.84.84,0,0,1,.5.17.77.77,0,0,1,.28.46h0c0-.07,0-.15.07-.24A.72.72,0,0,1,21,29.7a.62.62,0,0,1,.22-.17.66.66,0,0,1,.34-.07,1,1,0,0,1,.44.09,1,1,0,0,1,.36.29,1.57,1.57,0,0,1,.22.45,2.33,2.33,0,0,1,.07.63A3.73,3.73,0,0,1,22.56,31.64Z"
      />
      <path
        fill="#FFFFFF"
        d="M34.32,17.45H28.69L36.34,13V11.6l-10.2,5.92v.26h0V41.29h8.14l10-5.89V11.81ZM38.13,32.7l-.51-.2-.54,1.22.32.67-.38.86L35.5,31.89l.43-1,2.59.91Zm1.58-3.63-1.15.12-.17.4.75.78-.37.85-2.05-2.13.62-1.44a5.15,5.15,0,0,1,.25-.48.86.86,0,0,1,.26-.3.48.48,0,0,1,.31-.12.5.5,0,0,1,.32.18,1.18,1.18,0,0,1,.3.56,2.47,2.47,0,0,1,0,.73L40.16,28Zm.94-2.17L39,25.18l-.45,1-.4-.42,1.27-2.92.4.41-.45,1L41,26.05Zm1.62-3.59a4.49,4.49,0,0,1-.35.67,3,3,0,0,1-.37.46l-.49-.51,0-.1a1.46,1.46,0,0,0,.46-.38,2.15,2.15,0,0,0,.34-.56c0-.05.05-.12.09-.21a1.4,1.4,0,0,0,.06-.24,1.85,1.85,0,0,0,0-.23A.32.32,0,0,0,42,22a.11.11,0,0,0-.15,0,.49.49,0,0,0-.2.19l-.22.34c-.08.12-.15.23-.23.33a.86.86,0,0,1-.49.36.49.49,0,0,1-.44-.18,1.27,1.27,0,0,1-.29-.91,3.11,3.11,0,0,1,.29-1.25,3.91,3.91,0,0,1,.32-.61,2.62,2.62,0,0,1,.34-.45l.47.49,0,.09a1.5,1.5,0,0,0-.38.32,2,2,0,0,0-.33.53c0,.07,0,.14-.08.22a1.44,1.44,0,0,0,0,.24.5.5,0,0,0,0,.21.3.3,0,0,0,.07.16c.06.06.11.07.17,0a1,1,0,0,0,.25-.29c.06-.11.13-.21.19-.31l.22-.3a.88.88,0,0,1,.46-.34.45.45,0,0,1,.42.18,1.3,1.3,0,0,1,.31.93A3.13,3.13,0,0,1,42.27,23.31Z"
      />
      <polygon
        fill="#FFFFFF"
        points="37.15 32.31 36.24 31.95 36.79 33.13 37.15 32.31"
      />
      <path
        fill="#FFFFFF"
        d="M38.16,27.88a.33.33,0,0,0-.16-.09.24.24,0,0,0-.16.09.7.7,0,0,0-.12.18l-.12.26-.13.31.55.57.11-.26a2.82,2.82,0,0,0,.14-.35,1,1,0,0,0,0-.29,1.2,1.2,0,0,0,0-.23A.44.44,0,0,0,38.16,27.88Z"
      />
      <polygon
        fill="#FFFFFF"
        points="40.18 13.19 33.87 16.75 31.5 16.74 37.7 13.2 40.18 13.19"
      />
      <polygon
        fill="#204A82"
        className="fill"
        points="30.91 36.38 29.69 36.79 30.91 37.21 30.91 36.38"
      />
      <path
        fill="#204A82"
        className="fill"
        d="M27.6,18.92V39.81h6V18.92Zm4.61,17-.68.24v1.25l.68.24v.87l-3.38-1.24v-1L32.21,35Zm0-3.58L31,33.27v.41h1.24v.86H28.83V33.08a3.94,3.94,0,0,1,0-.52,1,1,0,0,1,.15-.4.76.76,0,0,1,.29-.3.81.81,0,0,1,.46-.12,1,1,0,0,1,.61.18,1.3,1.3,0,0,1,.39.49l1.45-1.13Zm0-2.17H29.48v1.06h-.65v-3h.65v1.06h2.73Zm0-2.85a3.29,3.29,0,0,1-.19.58H31.2v-.09a2.11,2.11,0,0,0,.33-.61,1.9,1.9,0,0,0,.12-.64,2.06,2.06,0,0,0,0-.21.87.87,0,0,0,0-.21.69.69,0,0,0-.1-.17.29.29,0,0,0-.19-.06.26.26,0,0,0-.19.09.81.81,0,0,0-.12.28l-.09.4a2.34,2.34,0,0,1-.1.4,1.31,1.31,0,0,1-.37.62,1,1,0,0,1-.59.18.92.92,0,0,1-.78-.42,1.84,1.84,0,0,1-.29-1.09,3.68,3.68,0,0,1,.06-.66,3.29,3.29,0,0,1,.16-.57h.78v.1a1.89,1.89,0,0,0-.38,1.1,1.55,1.55,0,0,0,0,.22.83.83,0,0,0,.06.21.4.4,0,0,0,.1.15.26.26,0,0,0,.16.07.25.25,0,0,0,.21-.11,1.33,1.33,0,0,0,.13-.38c0-.13.05-.25.07-.36s.06-.24.1-.37a1.21,1.21,0,0,1,.35-.57.8.8,0,0,1,.57-.19,1,1,0,0,1,.81.42,1.92,1.92,0,0,1,.31,1.16A3.17,3.17,0,0,1,32.2,27.33Z"
      />
      <path
        fill="#204A82"
        className="fill"
        d="M30.25,32.82a.48.48,0,0,0-.16-.13.5.5,0,0,0-.23,0,.36.36,0,0,0-.21.05.31.31,0,0,0-.15.18.65.65,0,0,0,0,.2,2.44,2.44,0,0,0,0,.28v.32h.91v-.27a3.16,3.16,0,0,0,0-.35A.43.43,0,0,0,30.25,32.82Z"
      />
    </svg>
  )
}

export default LiteraryArtsIcon
