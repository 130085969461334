/**
 * @name isIgnoredType
 * @param {Object} type - The resource
 * @returns {Boolean} - Returns logic for ignoring certain resourceable types from Map UI pages
 */

import isNoneType from './is-none-type'
import isOtherType from './is-other-type'

function isIgnoredType(type) {
  return isNoneType(type) || isOtherType(type)
}

export default isIgnoredType
