import React from 'react'
import { compose } from 'redux'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { ButtonArea, SubmitButton, Input } from 'lp-components'
import { lpForm } from 'lp-form'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

function ForgotPasswordForm({ handleSubmit, submitting, error }) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      {error && <p className="full-error-message">{error}</p>}
      <Field name="email" type="email" component={Input} />
      <ButtonArea>
        <SubmitButton submitting={submitting}>Submit</SubmitButton>
      </ButtonArea>
    </form>
  )
}

ForgotPasswordForm.propTypes = propTypes
ForgotPasswordForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'school-portal-forgot-password',
    constraints: {
      email: { presence: true, email: true },
    },
  })
)(ForgotPasswordForm)
