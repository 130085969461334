import React from 'react'
import logo from 'images/logo-grey.png'
import searchIcon from 'images/search.svg'
import certified from 'images/certified.svg'
import Cat1 from 'images/Cat1.png'
import Cat2 from 'images/Cat2.png'
import Cat3 from 'images/Cat3.png'
import Cat4 from 'images/Cat4.png'
import Cat5 from 'images/Cat5.png'
import Success from 'images/checkmark-circle-green.svg'
import Fail from 'images/cross-circle-red.svg'
import Warn from 'images/warn-circle-grey.svg'

function All() {
  return (
    <div>
      <header className="navigation styleguide">
        <a href="/" className="logo link-white-base">
          <img role="presentation" src={logo} />
        </a>
      </header>

      <nav className="side-navigation-styleguide">
        <h5>Styleguide</h5>
        <ul>
          <li>
            <a href="#typography">Typography</a>
          </li>
          <li>
            <a href="#form-inputs">Form Inputs</a>
          </li>
          <li>
            <a href="#buttons">Buttons</a>
          </li>
          <li>
            <a href="#loading">Spinner</a>
          </li>
          <li>
            <a href="#modal">Modal</a>
          </li>
          <li>
            <a href="#empty-state">Empty State</a>
          </li>
          <li>
            <a href="#nav-bar">Nav Bar</a>
          </li>
          <li>
            <a href="#layout">Layout</a>
          </li>
          <li>
            <a href="#four-oh-four">404</a>
          </li>
          <li>
            <a href="#pagination">Pagination</a>
          </li>
          <li>
            <a href="#autosave">Autosave</a>
          </li>
          <li>
            <a href="#warning">Warning</a>
          </li>
        </ul>
      </nav>

      <main className="content with-sidebar styleguide">
        <div className="content-block-container">
          <h3 className="code-header" id="typography">
            Typography
          </h3>
          <div className="card">
            <h1>H1 - Header One</h1>
            <h2>H2 - Header Two</h2>
            <h3>H3 - Header Three</h3>
            <h4>H4 - Header Four</h4>
            <h5>H5 - Header Five</h5>
            <h6>H6 - Header Six</h6>
            <p>p — Some paragraph text</p>
            <a href="#">a — Some link text</a>
            <xmp>
              <u>Underline text</u>
            </xmp>
          </div>

          <h3 className="code-header" id="form-inputs">
            Form Inputs
          </h3>
          <h5>Input</h5>
          <div className="card styleguide-fields">
            <fieldset>
              <label>Label</label>
              <input type="text" name="" placeholder="Placeholder Text" />
            </fieldset>

            <xmp>
              <fieldset>
                <label>Label</label>
                <input type="text" name="" placeholder="Placeholder Text" />
              </fieldset>
            </xmp>
            <br></br>
            <fieldset>
              <label>
                Label <i>(optional)</i>
              </label>
              <input type="text" name="" placeholder="Placeholder Text" />
            </fieldset>

            <xmp>
              <fieldset>
                <label>
                  Label <i>(optional)</i>
                </label>
                <input type="text" name="" placeholder="Placeholder Text" />
              </fieldset>
            </xmp>
            <br></br>

            <fieldset className="error">
              <label>Label</label>
              <input type="text" name="" placeholder="Placeholder Text" />
              <span className="error-message">Label can't be blank</span>
            </fieldset>

            <xmp>
              <fieldset className="error">
                <label>Label</label>
                <input type="text" name="" placeholder="Placeholder Text" />
                <span className="error-message">Label can't be blank</span>
              </fieldset>
            </xmp>
            <br></br>
            <fieldset>
              <label className="tooltip-trigger">Label</label>
              <div className="tooltip-content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent pharetra nunc eros, ut sollicitudin est iaculis vel.
                  Vivamus eleifend vulputate dui, sed tincidunt enim placerat
                  at.
                </p>
              </div>
              <input type="text" name="" placeholder="Placeholder Text" />
            </fieldset>

            <xmp>
              <fieldset>
                <label className="tooltip-trigger">Label</label>
                <div className="tooltip-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Praesent pharetra nunc eros, ut sollicitudin est iaculis
                    vel. Vivamus eleifend vulputate dui, sed tincidunt enim
                    placerat at.
                  </p>
                </div>
                <input type="text" name="" placeholder="Placeholder Text" />
              </fieldset>
            </xmp>
            <br></br>
            <fieldset>
              <label className="tooltip-trigger">Label</label>
              <div className="tooltip-content is-active">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent pharetra nunc eros, ut sollicitudin est iaculis vel.
                  Vivamus eleifend vulputate dui, sed tincidunt enim placerat
                  at.
                </p>
              </div>
              <input type="text" name="" placeholder="Placeholder Text" />
            </fieldset>

            <xmp>
              <fieldset>
                <label className="tooltip-trigger">Label</label>
                <div className="tooltip-content is-active">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Praesent pharetra nunc eros, ut sollicitudin est iaculis
                    vel. Vivamus eleifend vulputate dui, sed tincidunt enim
                    placerat at.
                  </p>
                </div>
                <input type="text" name="" placeholder="Placeholder Text" />
              </fieldset>
            </xmp>
            <br></br>
            <fieldset>
              <label>Label</label>
              <span>
                <a href="" className="link-primary">
                  Assign to Me
                </a>
              </span>
              <input type="text" name="" placeholder="Placeholder Text" />
            </fieldset>

            <xmp>
              <fieldset>
                <label>Label</label>
                <span>
                  <a href="" className="link-primary">
                    Assign to Me
                  </a>
                </span>
                <input type="text" name="" placeholder="Placeholder Text" />
              </fieldset>
            </xmp>
          </div>

          <h5>Textarea</h5>
          <div className="card styleguide-fields">
            <fieldset className="with-character-count">
              <label>Label</label>
              <span className="character-count">0/500 characters</span>
              <textarea
                name="message"
                placeholder="Placeholder Text"
              ></textarea>
            </fieldset>

            <xmp>
              <fieldset className="with-character-count">
                <label>Label</label>
                <span className="character-count">0/500 characters</span>
                <textarea
                  name="message"
                  placeholder="Placeholder Text"
                ></textarea>
              </fieldset>
            </xmp>
            <br></br>
            <fieldset className="with-character-count">
              <label>
                Label <i>(some text here)</i>
              </label>
              <span className="character-count">0/500 characters</span>
              <textarea
                name="message"
                placeholder="Placeholder Text"
              ></textarea>
            </fieldset>

            <xmp>
              <fieldset className="with-character-count">
                <label>
                  Label <i>(some text here)</i>
                </label>
                <span className="character-count">0/500 characters</span>
                <textarea
                  name="message"
                  placeholder="Placeholder Text"
                ></textarea>
              </fieldset>
            </xmp>
            <br></br>
            <fieldset className="error with-character-count">
              <label>Label</label>
              <span className="character-count">0/500 characters</span>
              <textarea
                name="message"
                placeholder="Placeholder Text"
              ></textarea>
              <span className="error-message">Label can't be blank</span>
            </fieldset>

            <xmp>
              <fieldset className="error with-character-count">
                <label>Label</label>
                <span className="character-count">0/500 characters</span>
                <textarea
                  name="message"
                  placeholder="Placeholder Text"
                ></textarea>
                <span className="error-message">Label can't be blank</span>
              </fieldset>
            </xmp>
            <br></br>
            <fieldset className="with-character-count">
              <label>Label</label>
              <span className="character-count">0/500 characters</span>
              <textarea
                name="message"
                placeholder="Placeholder Text"
              ></textarea>
            </fieldset>

            <xmp>
              <fieldset className="with-character-count">
                <label>Label</label>
                <span className="character-count">0/500 characters</span>
                <textarea
                  name="message"
                  placeholder="Placeholder Text"
                ></textarea>
              </fieldset>
            </xmp>
            <br></br>
            <fieldset className="with-character-count">
              <label className="tooltip-trigger">Label</label>
              <div className="tooltip-content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent pharetra nunc eros, ut sollicitudin est iaculis vel.
                  Vivamus eleifend vulputate dui, sed tincidunt enim placerat
                  at.
                </p>
              </div>
              <span className="character-count">0/500 characters</span>
              <textarea
                name="message"
                placeholder="Placeholder Text"
              ></textarea>
            </fieldset>

            <xmp>
              <fieldset className="with-character-count">
                <label className="tooltip-trigger">Label</label>
                <div className="tooltip-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Praesent pharetra nunc eros, ut sollicitudin est iaculis
                    vel. Vivamus eleifend vulputate dui, sed tincidunt enim
                    placerat at.
                  </p>
                </div>
                <span className="character-count">0/500 characters</span>
                <textarea
                  name="message"
                  placeholder="Placeholder Text"
                ></textarea>
              </fieldset>
            </xmp>
            <br></br>
            <fieldset className="with-character-count">
              <label className="tooltip-trigger">Label</label>
              <div className="tooltip-content is-active">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent pharetra nunc eros, ut sollicitudin est iaculis vel.
                  Vivamus eleifend vulputate dui, sed tincidunt enim placerat
                  at.
                </p>
              </div>
              <span className="character-count">0/500 characters</span>
              <textarea
                name="message"
                placeholder="Placeholder Text"
              ></textarea>
            </fieldset>

            <xmp>
              <fieldset className="with-character-count">
                <label className="tooltip-trigger">Label</label>
                <div className="tooltip-content is-active">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Praesent pharetra nunc eros, ut sollicitudin est iaculis
                    vel. Vivamus eleifend vulputate dui, sed tincidunt enim
                    placerat at.
                  </p>
                </div>
                <span className="character-count">0/500 characters</span>
                <textarea
                  name="message"
                  placeholder="Placeholder Text"
                ></textarea>
              </fieldset>
            </xmp>
          </div>

          <h5>Select</h5>
          <div className="card styleguide-fields">
            <fieldset>
              <label>Label</label>
              <select name="time">
                <option value="morning">Morning</option>
                <option value="afternoon">Afternoon</option>
                <option value="evening">Evening</option>
              </select>
            </fieldset>

            <xmp>
              <fieldset>
                <label>Label</label>
                <select name="time">
                  <option value="morning">Morning</option>
                  <option value="afternoon">Afternoon</option>
                  <option value="evening">Evening</option>
                </select>
              </fieldset>
            </xmp>
            <br></br>

            <fieldset>
              <label>
                Label <i>(optional)</i>
              </label>
              <select name="time">
                <option value="morning">Morning</option>
                <option value="afternoon">Afternoon</option>
                <option value="evening">Evening</option>
              </select>
            </fieldset>

            <xmp>
              <fieldset>
                <label>
                  Label <i>(optional)</i>
                </label>
                <select name="time">
                  <option value="morning">Morning</option>
                  <option value="afternoon">Afternoon</option>
                  <option value="evening">Evening</option>
                </select>
              </fieldset>
            </xmp>
            <br></br>

            <fieldset className="error">
              <label>Label</label>
              <select name="time">
                <option value="morning">Morning</option>
                <option value="afternoon">Afternoon</option>
                <option value="evening">Evening</option>
              </select>
              <span className="error-message">Label can't be blank</span>
            </fieldset>

            <xmp>
              <fieldset className="error">
                <label>Label</label>
                <select name="time">
                  <option value="morning">Morning</option>
                  <option value="afternoon">Afternoon</option>
                  <option value="evening">Evening</option>
                </select>
                <span className="error-message">Label can't be blank</span>
              </fieldset>
            </xmp>
            <br></br>
            <fieldset>
              <label className="tooltip-trigger">Label</label>
              <div className="tooltip-content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent pharetra nunc eros, ut sollicitudin est iaculis vel.
                  Vivamus eleifend vulputate dui, sed tincidunt enim placerat
                  at.
                </p>
              </div>
              <select name="time">
                <option value="morning">Morning</option>
                <option value="afternoon">Afternoon</option>
                <option value="evening">Evening</option>
              </select>
            </fieldset>

            <xmp>
              <fieldset>
                <label className="tooltip-trigger">Label</label>
                <div className="tooltip-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Praesent pharetra nunc eros, ut sollicitudin est iaculis
                    vel. Vivamus eleifend vulputate dui, sed tincidunt enim
                    placerat at.
                  </p>
                </div>
                <select name="time">
                  <option value="morning">Morning</option>
                  <option value="afternoon">Afternoon</option>
                  <option value="evening">Evening</option>
                </select>
              </fieldset>
            </xmp>
            <br></br>
            <fieldset>
              <label className="tooltip-trigger">Label</label>
              <div className="tooltip-content is-active">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent pharetra nunc eros, ut sollicitudin est iaculis vel.
                  Vivamus eleifend vulputate dui, sed tincidunt enim placerat
                  at.
                </p>
              </div>
              <select name="time">
                <option value="morning">Morning</option>
                <option value="afternoon">Afternoon</option>
                <option value="evening">Evening</option>
              </select>
            </fieldset>

            <xmp>
              <fieldset>
                <label className="tooltip-trigger">Label</label>
                <div className="tooltip-content is-active">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Praesent pharetra nunc eros, ut sollicitudin est iaculis
                    vel. Vivamus eleifend vulputate dui, sed tincidunt enim
                    placerat at.
                  </p>
                </div>
                <select name="time">
                  <option value="morning">Morning</option>
                  <option value="afternoon">Afternoon</option>
                  <option value="evening">Evening</option>
                </select>
              </fieldset>
            </xmp>
          </div>

          <h5>Checkbox</h5>
          <div className="card styleguide-fields">
            <fieldset className="checkbox">
              <input type="checkbox" id="checkbox" />
              <label htmlFor="checkbox">Checkbox label</label>
            </fieldset>
            <xmp>
              <fieldset className="checkbox">
                <input
                  type="checkbox"
                  id="checkbox"
                  checked="checked"
                  value="true"
                />
                <label htmlFor="checkbox">Checkbox label</label>
              </fieldset>
            </xmp>
          </div>

          <h3 className="code-header" id="buttons">
            Buttons
          </h3>
          <div className="card">
            <button className="button-primary">Submit</button>
            <button className="button-primary in-progress">Submit</button>
            <button className="button-primary is-disabled">Submit</button>

            <xmp>
              button-primary button-primary in-progress button-primary
              is-disabled
            </xmp>
            <br></br>
            <button className="button-primary-outline">Submit</button>
            <button className="button-primary-outline in-progress">
              Submit
            </button>
            <button className="button-primary-outline is-disabled">
              Submit
            </button>

            <xmp>
              button-primary-outline button-primary-outline in-progress
              button-primary-outline is-disabled
            </xmp>
            <br></br>
            <button className="button-alert">Submit</button>
            <button className="button-alert in-progress">Submit</button>
            <button className="button-alert is-disabled">Submit</button>

            <xmp>
              button-alert button-alert in-progress button-alert is-disabled
            </xmp>
            <br></br>
            <button className="button-alert-outline">Submit</button>
            <button className="button-alert-outline in-progress">Submit</button>
            <button className="button-alert-outline is-disabled">Submit</button>

            <xmp>
              button-alert-outline button-alert-outline in-progress
              button-alert-outline is-disabled
            </xmp>
            <br></br>
            <button className="button-warn">Submit</button>
            <button className="button-warn in-progress">Submit</button>
            <button className="button-warn is-disabled">Submit</button>

            <xmp>
              button-warn button-warn in-progress button-warn is-disabled
            </xmp>
            <br></br>
            <button className="button-secondary">Submit</button>
            <button className="button-secondary in-progress">Submit</button>
            <button className="button-secondary is-disabled">Submit</button>

            <xmp>
              button-secondary button-secondary in-progress button-secondary
              is-disabled
            </xmp>
            <br></br>
            <button className="button-secondary-outline">Submit</button>
            <button className="button-secondary-outline in-progress">
              Submit
            </button>
            <button className="button-secondary-outline is-disabled">
              Submit
            </button>

            <xmp>
              button-secondary-outline button-secondary-outline in-progress
              button-secondary-outline is-disabled
            </xmp>
            <br></br>
            <button className="button-secondary">Submit</button>
            <button className="button-secondary in-progress">Submit</button>
            <button className="button-secondary is-disabled">Submit</button>

            <xmp>
              button-secondary button-secondary in-progress button-secondary
              is-disabled
            </xmp>
          </div>

          <h3 className="code-header" id="loading">
            Spinner
          </h3>
          <div className="card">
            <div id="spinner"></div>
            <xmp>
              <div id="spinner"></div>
            </xmp>
          </div>

          <h3 className="code-header" id="loading">
            Table
          </h3>
          <div className="card">
            <table className="sortable-table">
              <colgroup>
                <col></col>
                <col></col>
                <col></col>
                <col></col>
                <col></col>
                <col></col>
                <col></col>
                <col></col>
              </colgroup>
              <thead>
                <tr>
                  <th className="sortable">Name</th>
                  <th className="sortable">Field Trip</th>
                  <th className="sortable">Residency (RESI)</th>
                  <th className="sortable">Professional Development</th>
                  <th className="sortable">Resources</th>
                  <th className="sortable">Out-of-School Time</th>
                  <th className="sortable">In-School Performance</th>
                  <th className="sortable">Other</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Harold Washington Cultural Center</td>
                  <td>
                    <div className="checked"></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                </tr>
                <tr>
                  <td>Steppenwolf Theatre Company</td>
                  <td>
                    <div className="checked"></div>
                  </td>
                  <td>
                    <div className="checked"></div>
                  </td>
                  <td>
                    <div className="checked"></div>
                  </td>
                  <td>
                    <div className="checked"></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                </tr>
                <tr>
                  <td>Gallery 37</td>
                  <td>
                    <div className=""></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                  <td>
                    <div className="checked"></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                </tr>
                <tr>
                  <td>The Field Museum</td>
                  <td>
                    <div className="checked"></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                  <td>
                    <div className="checked"></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                </tr>
                <tr>
                  <td>Chicago Youth Centers</td>
                  <td>
                    <div className=""></div>
                  </td>
                  <td>
                    <div className="checked"></div>
                  </td>
                  <td>
                    <div className=""></div>
                  </td>
                  <td>
                    <div className="checked"></div>
                  </td>
                  <td>
                    <div className="checked"></div>
                  </td>
                  <td>
                    <div className="checked"></div>
                  </td>
                  <td>
                    <div className="checked"></div>
                  </td>
                </tr>
              </tbody>
            </table>
            <xmp></xmp>
          </div>

          <h3 className="code-header" id="modal">
            Modal
          </h3>
          <div className="card modal-card">
            <div className="modal">
              <div className="modal-fade-screen">
                <div className="card modal-inner">
                  <h2>Modal Header</h2>
                  <div className="modal-close">×</div>
                  <div className="modal-content">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Praesent pharetra nunc eros, ut sollicitudin est iaculis
                      vel. Vivamus eleifend vulputate dui, sed tincidunt enim
                      placerat at.
                    </p>
                    <a href="#" className="button-primary">
                      Add
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <xmp>
              <div className="modal">
                <div className="modal-fade-screen is-active">
                  <div className="card modal-inner">
                    <h2>Modal Header</h2>
                    <div className="modal-close">×</div>
                    <div className="modal-content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Praesent pharetra nunc eros, ut sollicitudin est iaculis
                        vel. Vivamus eleifend vulputate dui, sed tincidunt enim
                        placerat at.
                      </p>
                      <a href="#" className="button-primary">
                        Add
                      </a>
                      <a href="#" className="button-primary">
                        Add
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </xmp>
          </div>

          <h3 className="code-header" id="empty-state">
            Empty State
          </h3>
          <div className="card">
            <div className="empty-state">
              <p>Some empty state text here.</p>
              <a href="#" className="button-primary">
                Button
              </a>
            </div>
            <xmp>
              <div className="empty-state">
                <img role="presentation" src="../../assets/twitter.svg" />
                <p>Some empty state text here.</p>
                <a href="#" className="button-primary">
                  Button
                </a>
              </div>
            </xmp>
          </div>
          <h3 className="code-header" id="nav-bar">
            Nav Bar
          </h3>
          <div className="card styleguide-header">
            <header className="navigation">
              <a href="/" className="logo link-white-base">
                <img role="presentation" src={logo} />
              </a>
              <nav>
                <div className="search-block">
                  <img role="presentation" src={searchIcon} />
                  <fieldset>
                    <input
                      type="text"
                      name=""
                      placeholder="Search for School or Partner"
                    />
                  </fieldset>
                </div>
              </nav>
            </header>
          </div>

          <h3 className="code-header" id="four-oh-four">
            404
          </h3>
          <div className="card">
            <main className="content centered">
              <div className="four-oh-four">
                <h5>404 Error</h5>
                <h1>Page Not Found</h1>
                <p>Sorry, but the page you were looking for does not exist.</p>
              </div>
            </main>
            <xmp>
              <main className="content centered">
                <div className="four-oh-four">
                  <h5>404 Error</h5>
                  <h1>Page Not Found</h1>
                  <p>
                    Sorry, but the page you were looking for does not exist.
                  </p>
                </div>
              </main>
            </xmp>
          </div>

          <h3 className="code-header" id="pagination">
            Pagination
          </h3>
          <div className="card">
            <div className="pagination">
              <ul>
                <li className="active">
                  <a>1</a>
                </li>
                <li className="">
                  <a>2</a>
                </li>
                <li className="">
                  <a>3</a>
                </li>
                <li className="">
                  <a>4</a>
                </li>
                <li>
                  <span className="delimiter">...</span>
                </li>
                <li className="">
                  <a>9</a>
                </li>
                <li className="next">
                  <a>Next</a>
                </li>
              </ul>
            </div>
            <xmp>
              <div className="pagination">
                <ul>
                  <li className="active">
                    <a>1</a>
                  </li>
                  <li className="">
                    <a>2</a>
                  </li>
                  <li className="">
                    <a>3</a>
                  </li>
                  <li className="">
                    <a>4</a>
                  </li>
                  <li>
                    <span className="delimiter">...</span>
                  </li>
                  <li className="">
                    <a>9</a>
                  </li>
                  <li className="next">
                    <a>Next</a>
                  </li>
                </ul>
              </div>
            </xmp>
          </div>

          <h3 className="code-header" id="results">
            Search Results
          </h3>
          <div className="card">
            <main className="content">
              <div className="card">
                <ul className="result-blocks">
                  <li className="result-block">
                    <div className="text-block">
                      <h3>
                        <a href="">
                          Chicago Arts Partnerships in Education (CAPE)
                        </a>
                      </h3>
                      <p>228 S. Wabash Avenue, Suite 500 Chicago, IL 60604</p>
                      <p>
                        <strong>Disciplines:</strong> Dance, Media Arts, Music,
                        Other, Theater, Visual Arts
                      </p>
                      <p>
                        <strong>Program(s):</strong> Field Trip, Other,
                        Out-of-School Time, Professional Development, Residency,
                        Resources
                      </p>
                    </div>
                    <div className="result-details">
                      <button className="button-yellow-outline">
                        Contact Partner
                      </button>
                      <img
                        className="certified"
                        src={certified}
                        alt="Certified Logo"
                      />
                    </div>
                  </li>
                  <li className="result-block">
                    <div className="text-block">
                      <h3>
                        <a href="">
                          Chicago Arts Partnerships in Education (CAPE)
                        </a>
                      </h3>
                      <p>228 S. Wabash Avenue, Suite 500 Chicago, IL 60604</p>
                      <p>
                        <strong>Disciplines:</strong> Dance, Media Arts, Music,
                        Other, Theater, Visual Arts
                      </p>
                      <p>
                        <strong>Program(s):</strong> Field Trip, Other,
                        Out-of-School Time, Professional Development, Residency,
                        Resources
                      </p>
                    </div>
                    <div className="result-details">
                      <button className="button-yellow-outline">
                        Contact Partner
                      </button>
                      <div></div>
                    </div>
                  </li>
                  <li className="result-block">
                    <div className="text-block">
                      <h3>
                        <a href="">Daniel Boone Elementary School</a>
                      </h3>
                      <p>228 S. Wabash Avenue, Suite 500 Chicago, IL 60604</p>
                      <p>
                        <strong>Instructors Teaching:</strong> Music, Visual
                        Arts
                      </p>
                      <p>
                        <strong>Partner Disciplines: </strong> Dance, Music,
                        Other, Theater, Visual Arts
                      </p>
                    </div>
                    <div className="result-details">
                      <button className="button-yellow-outline">
                        Contact School
                      </button>
                      <div className="rating">
                        <p>Excelling</p>
                        <div className="status-block progress--circle progress--100"></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </main>
            <xmp>
              <main className="content centered not-logged-in">
                <div className="card">
                  <h2>Sign In</h2>
                  <fieldset>
                    <label>Email</label>
                    <input type="text" name="" />
                  </fieldset>
                  <fieldset>
                    <label>Password</label>
                    <input type="password" name="" />
                  </fieldset>
                  <div className="button-area">
                    <button className="button-primary">Sign In</button>
                    <a href="">Forgot Password</a>
                  </div>
                </div>
              </main>
            </xmp>
          </div>

          <h3 className="code-header" id="browse">
            Browse
          </h3>
          <div className="card">
            <h3>Expanded Versions</h3>
            <div className="card ">
              <div className="close">×</div>
              <ul className="tabs">
                <li className="is-active">
                  <a href="">CPS Schools</a>
                </li>
                <li>
                  <a href="">Arts Partners</a>
                </li>
              </ul>
              <div>
                <fieldset>
                  <label>Year</label>
                  <div className="dropdown-select">
                    <div className="select-input">
                      <p>2016-17</p>
                    </div>
                    <div className="options is-active">
                      <ul className="scroll-box">
                        <li className="is-active">
                          <a href="">2016-17</a>
                        </li>
                        <li>
                          <a href="">2015-16</a>
                        </li>
                        <li>
                          <a href="">2014-15</a>
                        </li>
                        <li>
                          <a href="">2013-14</a>
                        </li>
                        <li>
                          <a href="">2012-13</a>
                        </li>
                        <li>
                          <a href="">2011-12</a>
                        </li>
                        <li>
                          <a href="">2010-11</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </fieldset>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <fieldset>
                  <label>Location</label>
                  <div className="dropdown-select">
                    <div className="select-input">
                      <p>Network 2, Ward 4, Albany Park</p>
                    </div>
                    <div className="options checkboxes is-active">
                      <ul className="scroll-box">
                        <li className="expander is-active">
                          <a href="#" className="expander-trigger is-active">
                            CPS Network
                          </a>
                          <ul className="expander-content">
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="Network1" />
                                <label htmlFor="Network1">Network 1</label>
                              </fieldset>
                            </li>
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="Network2" />
                                <label htmlFor="Network2">Network 2</label>
                              </fieldset>
                            </li>
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="Network3" />
                                <label htmlFor="Network3">Network 3</label>
                              </fieldset>
                            </li>
                          </ul>
                        </li>
                        <li className="expander">
                          <a href="#" className="expander-trigger">
                            Zipcode
                          </a>
                          <ul className="expander-content">
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="60601" />
                                <label htmlFor="60601">60601</label>
                              </fieldset>
                            </li>
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="60602" />
                                <label htmlFor="60602">60602</label>
                              </fieldset>
                            </li>
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="60603" />
                                <label htmlFor="60603">60603</label>
                              </fieldset>
                            </li>
                          </ul>
                        </li>
                        <li className="expander">
                          <a href="#" className="expander-trigger">
                            Ward
                          </a>
                          <ul className="expander-content">
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="Ward1" />
                                <label htmlFor="Ward1">Ward 1</label>
                              </fieldset>
                            </li>
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="Ward2" />
                                <label htmlFor="Ward2">Ward 2</label>
                              </fieldset>
                            </li>
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="Ward3" />
                                <label htmlFor="Ward3">Ward 3</label>
                              </fieldset>
                            </li>
                          </ul>
                        </li>
                        <li className="expander">
                          <a href="#" className="expander-trigger">
                            Community Area
                          </a>
                          <ul className="expander-content">
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="AlbanyPark" />
                                <label htmlFor="Albany Park">Albany Park</label>
                              </fieldset>
                            </li>
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="ArcherHeights" />
                                <label htmlFor="ArcherHeights">
                                  Archer Heights
                                </label>
                              </fieldset>
                            </li>
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="ArmourSquare" />
                                <label htmlFor="ArmourSquare">
                                  Armour Square
                                </label>
                              </fieldset>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="WholeDistrict" />
                            <label htmlFor="WholeDistrict">
                              Whole District
                            </label>
                          </fieldset>
                        </li>
                      </ul>
                    </div>
                  </div>
                </fieldset>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <fieldset>
                  <label>Creative Schools Category</label>
                  <div className="dropdown-select">
                    <div className="select-input">
                      <p>1: Excelling, 2: Strong</p>
                    </div>
                    <div className="options checkboxes is-active">
                      <ul className="scroll-box">
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Excelling" />
                            <label htmlFor="Excelling">1: Excelling</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Strong" />
                            <label htmlFor="Strong">2: Strong</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Developing" />
                            <label htmlFor="Developing">3: Developing</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Emerging" />
                            <label htmlFor="Emerging">4: Emerging</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Incomplete Data" />
                            <label htmlFor="Incomplete Data">
                              5: Incomplete Data
                            </label>
                          </fieldset>
                        </li>
                      </ul>
                    </div>
                  </div>
                </fieldset>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <fieldset>
                  <label>School Type</label>
                  <div className="dropdown-select">
                    <div className="select-input">
                      <p>Elementary</p>
                    </div>
                    <div className="options checkboxes is-active">
                      <ul className="scroll-box">
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Elementary" />
                            <label htmlFor="Elementary">Elementary</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Middle School" />
                            <label htmlFor="Middle School">Middle School</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="High School" />
                            <label htmlFor="High School">High School</label>
                          </fieldset>
                        </li>
                      </ul>
                    </div>
                  </div>
                </fieldset>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <fieldset>
                  <label>Instructors Teaching</label>
                  <div className="dropdown-select">
                    <div className="select-input">
                      <p>All Dance, Music</p>
                    </div>
                    <div className="options checkboxes is-active">
                      <ul className="scroll-box">
                        <li>
                          <ul className="toggle">
                            <li className="is-active">Any</li>
                            <li>All</li>
                          </ul>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Dance" />
                            <label htmlFor="Dance">Dance</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Music" />
                            <label htmlFor="Music">Music</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Theater" />
                            <label htmlFor="Theater">Theater</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="VisualArts" />
                            <label htmlFor="VisualArts">Visual Arts</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="MediaArts" />
                            <label htmlFor="MediaArts">Media Arts</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="LiteraryArts" />
                            <label htmlFor="LiteraryArts">Literary Arts</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="NoArtsInstructors" />
                            <label htmlFor="NoArtsInstructors">
                              No Arts Instructors
                            </label>
                          </fieldset>
                        </li>
                      </ul>
                    </div>
                  </div>
                </fieldset>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <fieldset>
                  <label>Partner Disciplines</label>
                  <div className="dropdown-select">
                    <div className="select-input">
                      <p>Any Dance, Visual Arts</p>
                    </div>
                    <div className="options checkboxes is-active">
                      <ul className="scroll-box">
                        <li>
                          <ul className="toggle">
                            <li className="is-active">Any</li>
                            <li>All</li>
                          </ul>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Dance" />
                            <label htmlFor="Dance">Dance</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Music" />
                            <label htmlFor="Music">Music</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Theater" />
                            <label htmlFor="Theater">Theater</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="VisualArts" />
                            <label htmlFor="VisualArts">Visual Arts</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="MediaArts" />
                            <label htmlFor="MediaArts">Media Arts</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="LiteraryArts" />
                            <label htmlFor="LiteraryArts">Literary Arts</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="NoArtsInstructors" />
                            <label htmlFor="NoArtsInstructors">
                              No Arts Instructors
                            </label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Other" />
                            <label htmlFor="Other">Other</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="NoPartners" />
                            <label htmlFor="NoPartners">No Partners</label>
                          </fieldset>
                        </li>
                      </ul>
                    </div>
                  </div>
                </fieldset>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <fieldset>
                  <label>Governance</label>
                  <div className="dropdown-select">
                    <div className="select-input">
                      <p>Neighborhood, Contract</p>
                    </div>
                    <div className="options checkboxes is-active">
                      <ul className="scroll-box">
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Neighborhood" />
                            <label htmlFor="Neighborhood">Neighborhood</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Alternative" />
                            <label htmlFor="Alternative">Alternative</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Contract" />
                            <label htmlFor="Contract">Contract</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Charter" />
                            <label htmlFor="Charter">Charter</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Performance" />
                            <label htmlFor="Performance">Performance</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="ausl" />
                            <label htmlFor="ausl">AUSL</label>
                          </fieldset>
                        </li>
                      </ul>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            <h3>Closed State</h3>
            <div className="card browse-options">
              <div className="close">×</div>
              <ul className="tabs">
                <li className="is-active">
                  <a href="">CPS Schools</a>
                </li>
                <li>
                  <a href="">Arts Partners</a>
                </li>
              </ul>
              <div>
                <fieldset>
                  <label>Year</label>
                  <div className="dropdown-select">
                    <div className="select-input">
                      <p>2016-17</p>
                    </div>
                    <div className="options">
                      <ul className="scroll-box">
                        <li className="is-active">
                          <a href="">2016-17</a>
                        </li>
                        <li>
                          <a href="">2015-16</a>
                        </li>
                        <li>
                          <a href="">2014-15</a>
                        </li>
                        <li>
                          <a href="">2013-14</a>
                        </li>
                        <li>
                          <a href="">2012-13</a>
                        </li>
                        <li>
                          <a href="">2011-12</a>
                        </li>
                        <li>
                          <a href="">2010-11</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <label>Location</label>
                  <div className="dropdown-select">
                    <div className="select-input">
                      <p>Network 2, Ward 4</p>
                    </div>
                    <div className="options checkboxes">
                      <ul className="scroll-box">
                        <li className="expander is-active">
                          <a href="#" className="expander-trigger is-active">
                            CPS Network
                          </a>
                          <ul className="expander-content">
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="Network1" />
                                <label htmlFor="Network1">Network 1</label>
                              </fieldset>
                            </li>
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="Network2" />
                                <label htmlFor="Network2">Network 2</label>
                              </fieldset>
                            </li>
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="Network3" />
                                <label htmlFor="Network3">Network 3</label>
                              </fieldset>
                            </li>
                          </ul>
                        </li>
                        <li className="expander">
                          <a href="#" className="expander-trigger">
                            Zipcode
                          </a>
                          <ul className="expander-content">
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="60601" />
                                <label htmlFor="60601">60601</label>
                              </fieldset>
                            </li>
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="60602" />
                                <label htmlFor="60602">60602</label>
                              </fieldset>
                            </li>
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="60603" />
                                <label htmlFor="60603">60603</label>
                              </fieldset>
                            </li>
                          </ul>
                        </li>
                        <li className="expander">
                          <a href="#" className="expander-trigger">
                            Ward
                          </a>
                          <ul className="expander-content">
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="Ward1" />
                                <label htmlFor="Ward1">Ward 1</label>
                              </fieldset>
                            </li>
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="Ward2" />
                                <label htmlFor="Ward2">Ward 2</label>
                              </fieldset>
                            </li>
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="Ward3" />
                                <label htmlFor="Ward3">Ward 3</label>
                              </fieldset>
                            </li>
                          </ul>
                        </li>
                        <li className="expander">
                          <a href="#" className="expander-trigger">
                            Community Area
                          </a>
                          <ul className="expander-content">
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="AlbanyPark" />
                                <label htmlFor="Albany Park">Albany Park</label>
                              </fieldset>
                            </li>
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="ArcherHeights" />
                                <label htmlFor="ArcherHeights">
                                  Archer Heights
                                </label>
                              </fieldset>
                            </li>
                            <li>
                              <fieldset className="checkbox">
                                <input type="checkbox" id="ArmourSquare" />
                                <label htmlFor="ArmourSquare">
                                  Armour Square
                                </label>
                              </fieldset>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="WholeDistrict" />
                            <label htmlFor="WholeDistrict">
                              Whole District
                            </label>
                          </fieldset>
                        </li>
                      </ul>
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <label>Creative Schools Category</label>
                  <div className="dropdown-select">
                    <div className="select-input">
                      <p>1: Excelling, 2: Strong</p>
                    </div>
                    <div className="options checkboxes">
                      <ul className="scroll-box">
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Excelling" />
                            <label htmlFor="Excelling">1: Excelling</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Strong" />
                            <label htmlFor="Strong">2: Strong</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Developing" />
                            <label htmlFor="Developing">3: Developing</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Emerging" />
                            <label htmlFor="Emerging">4: Emerging</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Incomplete Data" />
                            <label htmlFor="Incomplete Data">
                              5: Incomplete Data
                            </label>
                          </fieldset>
                        </li>
                      </ul>
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <label>School Type</label>
                  <div className="dropdown-select">
                    <div className="select-input">
                      <p>Elementary</p>
                    </div>
                    <div className="options checkboxes">
                      <ul className="scroll-box">
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Elementary" />
                            <label htmlFor="Elementary">Elementary</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Middle School" />
                            <label htmlFor="Middle School">Middle School</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="High School" />
                            <label htmlFor="High School">High School</label>
                          </fieldset>
                        </li>
                      </ul>
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <label>Instructors Teaching</label>
                  <div className="dropdown-select">
                    <div className="select-input">
                      <p>All Dance, Music</p>
                    </div>
                    <div className="options checkboxes">
                      <ul className="scroll-box">
                        <li>
                          <ul className="toggle">
                            <li className="is-active">Any</li>
                            <li>All</li>
                          </ul>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Dance" />
                            <label htmlFor="Dance">Dance</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Music" />
                            <label htmlFor="Music">Music</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Theater" />
                            <label htmlFor="Theater">Theater</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="VisualArts" />
                            <label htmlFor="VisualArts">Visual Arts</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="MediaArts" />
                            <label htmlFor="MediaArts">Media Arts</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="LiteraryArts" />
                            <label htmlFor="LiteraryArts">Literary Arts</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="NoArtsInstructors" />
                            <label htmlFor="NoArtsInstructors">
                              No Arts Instructors
                            </label>
                          </fieldset>
                        </li>
                      </ul>
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <label>Partner Disciplines</label>
                  <div className="dropdown-select">
                    <div className="select-input">
                      <p>Any Dance, Visual Arts</p>
                    </div>
                    <div className="options checkboxes">
                      <ul className="scroll-box">
                        <li>
                          <ul className="toggle">
                            <li className="is-active">Any</li>
                            <li>All</li>
                          </ul>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Dance" />
                            <label htmlFor="Dance">Dance</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Music" />
                            <label htmlFor="Music">Music</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Theater" />
                            <label htmlFor="Theater">Theater</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="VisualArts" />
                            <label htmlFor="VisualArts">Visual Arts</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="MediaArts" />
                            <label htmlFor="MediaArts">Media Arts</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="LiteraryArts" />
                            <label htmlFor="LiteraryArts">Literary Arts</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="NoArtsInstructors" />
                            <label htmlFor="NoArtsInstructors">
                              No Arts Instructors
                            </label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Other" />
                            <label htmlFor="Other">Other</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="NoPartners" />
                            <label htmlFor="NoPartners">No Partners</label>
                          </fieldset>
                        </li>
                      </ul>
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <label>Governance</label>
                  <div className="dropdown-select">
                    <div className="select-input">
                      <p>Neighborhood, Contract</p>
                    </div>
                    <div className="options checkboxes">
                      <ul className="scroll-box">
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Neighborhood" />
                            <label htmlFor="Neighborhood">Neighborhood</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Alternative" />
                            <label htmlFor="Alternative">Alternative</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Contract" />
                            <label htmlFor="Contract">Contract</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Charter" />
                            <label htmlFor="Charter">Charter</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="Performance" />
                            <label htmlFor="Performance">Performance</label>
                          </fieldset>
                        </li>
                        <li>
                          <fieldset className="checkbox">
                            <input type="checkbox" id="ausl" />
                            <label htmlFor="ausl">AUSL</label>
                          </fieldset>
                        </li>
                      </ul>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="button-area">
                <button className="button-yellow">Search</button>
                <a href="">Reset</a>
              </div>
            </div>
            <xmp></xmp>
          </div>

          <h3 className="code-header" id="map-pop">
            Map Pop-up
          </h3>
          <div className="card">
            <div className="pop-up">
              <div className="close">×</div>
              <div className="text-block">
                <h5>Chicago Arts Partnerships in Education (CAPE)</h5>
                <p>228 S. Wabash Avenue, Suite 500 Chicago, IL 60604</p>
                <div className="details">
                  <a href="#" className="button-yellow-outline">
                    Contact Partner
                  </a>
                  <img
                    className="certified"
                    src={certified}
                    alt="Certified Logo"
                  />
                </div>
              </div>
            </div>

            <br></br>
            <xmp>
              <div className="pop-up">
                <div className="close">×</div>
                <div className="text-block">
                  <h5>Chicago Arts Partnerships in Education (CAPE)</h5>
                  <p>228 S. Wabash Avenue, Suite 500 Chicago, IL 60604</p>
                  <div className="details">
                    <a href="#" className="button-yellow-outline">
                      Contact Partner
                    </a>
                    <img
                      className="certified"
                      src={certified}
                      alt="Certified Logo"
                    />
                  </div>
                </div>
              </div>
            </xmp>

            <div className="pop-up">
              <div className="close">×</div>
              <div className="text-block">
                <h5>Academy for Global Citizenship Elementary School</h5>
                <p>228 S. Wabash Avenue, Suite 500 Chicago, IL 60604</p>
                <div className="details">
                  <a href="#" className="button-yellow-outline">
                    Contact School
                  </a>
                  <div className="rating">
                    <p>Excelling</p>
                    <div className="status-block progress--circle progress--100"></div>
                  </div>
                </div>
              </div>
            </div>

            <br></br>
            <xmp>
              <div className="pop-up">
                <div className="close">×</div>
                <div className="text-block">
                  <h5>Academy for Global Citizenship Elementary School</h5>
                  <p>228 S. Wabash Avenue, Suite 500 Chicago, IL 60604</p>
                  <div className="details">
                    <a href="#" className="button-yellow-outline">
                      Contact School
                    </a>
                    <div className="rating">
                      <p>Excelling</p>
                      <div className="status-block progress--circle progress--1f00"></div>
                    </div>
                  </div>
                </div>
              </div>
            </xmp>
          </div>

          <h3 className="code-header" id="modal">
            Category Images
          </h3>
          <div className="card category-card">
            <img src={Cat1} alt="Excelling Certified Logo" />
            <img src={Cat2} alt="Strong Certified Logo" />
            <img src={Cat3} alt="Developing Certified Logo" />
            <img src={Cat4} alt="Emerging Certified Logo" />
            <img src={Cat5} alt="Incomplete Data Certified Logo" />
            <xmp>
              <img src={Cat1} alt="Excelling Certified Logo" />
              <img src={Cat2} alt="Strong Certified Logo" />
              <img src={Cat3} alt="Developing Certified Logo" />
              <img src={Cat4} alt="Emerging Certified Logo" />
              <img src={Cat5} alt="Incomplete Data Certified Logo" />
            </xmp>
          </div>

          <h3 className="code-header" id="autosave">
            Auto Save
          </h3>
          <div className="card">
            <div className="autosave save-succeeded">
              <div className="text-block">
                <img src={Success} alt="Success Icon" />
                <p>Saved</p>
              </div>
            </div>
            <xmp>
              <div className="autosave save-succeeded">
                <div className="text-block">
                  <img src={Success} alt="Success Icon" />
                  <p>Saved</p>
                </div>
              </div>
            </xmp>
          </div>
          <div className="card">
            <div className="autosave save-failed">
              <div className="text-block">
                <img src={Fail} alt="Failed Icon" />
                <p>Save Failed</p>
              </div>
            </div>
            <xmp>
              <div className="autosave save-failed">
                <div className="text-block">
                  <img src={Fail} alt="Failed Icon" />
                  <p>Save Failed</p>
                </div>
              </div>
            </xmp>
          </div>
          <div className="card">
            <div className="autosave is-saving">
              <div className="text-block">
                <div id="spinner"></div>
                <p>Saving</p>
              </div>
            </div>
            <xmp>
              <div className="autosave is-saving">
                <div className="text-block">
                  <div id="spinner"></div>
                  <p>Saving</p>
                </div>
              </div>
            </xmp>
          </div>
          <div className="card">
            <div className="autosave changed">
              <div className="text-block">
                <img src={Warn} alt="Warn Icon" />
                <p>Unsaved</p>
              </div>
            </div>
            <xmp>
              <div className="autosave changed">
                <div className="text-block">
                  <img src={Warn} alt="Warn Icon" />
                  <p>Unsaved</p>
                </div>
              </div>
            </xmp>
          </div>

          <section className="card">
            <h2>Welcome to the 2018-19 Creative Schools Survey!</h2>
            <p>
              Through this annual survey, you will be asked to share information
              about arts education staffing and instruction at your school,
              partnerships with external program providers (i.e., arts
              partners), and how your school budgets and plans for the arts.
            </p>
            <p>
              As you work on the survey, your progress will be saved
              automatically, so you can return and pick up where you left off
              any time.
            </p>
            <p>
              <strong>
                Please submit your survey no later than: Friday, May 17
              </strong>
            </p>
            <p>
              Please feel free to reach out to artlookschools@ingenuity-inc.org
              with any questions!
            </p>
            <div className="callout">
              <p>
                <strong>Benefits of completing the survey:</strong>
              </p>
              <ul className="checkmark-list">
                <li>
                  Become eligible for thousands of dollars in arts education
                  support through the <a href="">Creative Schools Fund</a>
                </li>
                <li>
                  Use <a href="">artlook Map</a> to showcase your school and get
                  connected to arts education programs and resources
                </li>
                <li>
                  Earn a Creative Schools Certification, which will appear on
                  your school’s public-facing <a href="">CPS School Profile</a>
                </li>
              </ul>
            </div>
            <a href="#" className="button-secondary">
              Get Started
            </a>
          </section>

          <section className="progress-bar">
            <ul>
              <li className="is-complete">
                <a>
                  <span />
                  <p>
                    <strong>Staffing & Instruction</strong>
                  </p>
                </a>
              </li>
              <li className="is-active">
                <a>
                  <span />
                  <p>
                    <strong>Community & Partnerships</strong>
                  </p>
                </a>
              </li>
              <li>
                <a>
                  <span />
                  <p>
                    <strong>Budget & Planning</strong>
                  </p>
                </a>
              </li>
              <li>
                <a>
                  <span />
                  <p>
                    <strong>Review & Submit</strong>
                  </p>
                </a>
              </li>
            </ul>
          </section>

          <section className="card">
            <h2>Percent Access</h2>
            <p>
              We’ve collected some information from the school district about
              the percentage of students at your school who take arts courses to
              deteremine your school’s percent access score.
            </p>
            <ul className="percent-access">
              <li>
                <span>X</span>
                <p>Students who took an arts course</p>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>
                <span>X</span>
                <p>Total students enrolled</p>
              </li>
              <li>
                <span>=</span>
              </li>
              <li>
                <span>X%</span>
                <p>Percent Access</p>
              </li>
            </ul>
          </section>

          <section className="card">
            <h2>Who teaches the arts courses at your school?</h2>
            <p>
              Please list the instructors that teach arts courses during the
              school day at your school. Do not include individuals from outside
              your school, such as teaching artists. All responses will be
              reviewed for accuracy. <a href="">Click here</a> for additional
              tips and guidelines for reporting arts teachers.
            </p>
            <div className="scrollable-table">
              <div className="form-table">
                <div className="form-table-heading">
                  <div className="form-table-row">
                    <div className="form-table-head">Instructor First Name</div>
                    <div className="form-table-head">Instructor Last Name</div>
                    <div className="form-table-head">Full Time</div>
                    <div className="form-table-head">Part Time</div>
                    <div className="form-table-head">Primary Focus</div>
                    <div className="form-table-head">Secondary Focus</div>
                    <div className="form-table-head">Email</div>
                    <div
                      className="form-table-head"
                      style={{ width: 70 }}
                    ></div>
                    <div
                      className="form-table-head"
                      style={{ width: 20 }}
                    ></div>
                  </div>
                </div>
                <div className="form-table-body">
                  <div className="form-table-row">
                    <div className="form-table-cell">Kelsea</div>
                    <div className="form-table-cell">Cadore</div>
                    <div className="form-table-cell">
                      <input
                        type="radio"
                        id="radio1"
                        name=""
                        value="one"
                        checked
                      />
                    </div>
                    <div className="form-table-cell">
                      <input type="radio" id="radio1" name="" value="two" />
                    </div>
                    <div className="form-table-cell">Visual Art</div>
                    <div className="form-table-cell">None</div>
                    <div className="form-table-cell">k.condore@hila.edu</div>
                    <div className="form-table-cell">
                      <button className="button-grey-light">Edit</button>
                    </div>
                    <div className="form-table-cell">
                      <button className="remove-row">×</button>
                    </div>
                  </div>
                  <div className="form-table-row">
                    <div className="form-table-cell">
                      <input type="text" name="" />
                    </div>
                    <div className="form-table-cell">
                      <input type="text" name="" />
                    </div>
                    <div className="form-table-cell">
                      <input
                        type="radio"
                        id="radio2"
                        name=""
                        value="one"
                        checked
                      />
                    </div>
                    <div className="form-table-cell">
                      <input type="radio" id="radio2" name="" value="two" />
                    </div>
                    <div className="form-table-cell">
                      <select name="discipline">
                        <option value="">Music</option>
                        <option value="">Dance</option>
                        <option value="">Theatre</option>
                        <option value="">Visual Arts</option>
                        <option value="">Media Arts</option>
                        <option value="">Literary Arts</option>
                      </select>
                    </div>
                    <div className="form-table-cell">
                      <select name="discipline">
                        <option value="">Music</option>
                        <option value="">Dance</option>
                        <option value="">Theatre</option>
                        <option value="">Visual Arts</option>
                        <option value="">Media Arts</option>
                        <option value="">Literary Arts</option>
                      </select>
                    </div>
                    <div className="form-table-cell">
                      <input type="email" name="" />
                    </div>
                    <div className="form-table-cell">
                      <button className="button-primary">Save</button>
                    </div>
                    <div className="form-table-cell">
                      <button className="remove-row">×</button>
                    </div>
                  </div>
                  <div className="form-table-row">
                    <div className="form-table-cell">Mary</div>
                    <div className="form-table-cell">Murphy</div>
                    <div className="form-table-cell">
                      <input type="radio" id="radio3" name="" value="one" />
                    </div>
                    <div className="form-table-cell">
                      <input
                        type="radio"
                        id="radio3"
                        name=""
                        value="two"
                        checked
                      />
                    </div>
                    <div className="form-table-cell">Visual Art</div>
                    <div className="form-table-cell">None</div>
                    <div className="form-table-cell">m.murphy@hila.edu</div>
                    <div className="form-table-cell">
                      <button className="button-grey-light">Edit</button>
                    </div>
                    <div className="form-table-cell">
                      <button className="remove-row">×</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="link-black">
              + Add Another Arts Class{' '}
              <span>
                (If you are unable to add a class, please contact
                artlookschools@ingenuity-inc.org.)
              </span>
            </button>
          </section>

          <footer className="page-footer">
            <p>© 2019 Ingenuity. All Rights Reserved.</p>
            <p>Email artlookschools@ingenuity-inc.org with questions</p>
          </footer>

          <div className="sticky-buttons">
            <button className="link-black">Back</button>
            <div className="continue">
              <div className="autosave save-succeeded">
                <div className="text-block">
                  <img
                    src="/static/media/checkmark-circle-green.2edbae7b.svg"
                    alt="Success Icon"
                  />
                  <p>Progress Saved</p>
                </div>
              </div>
              <button className="button-secondary">Continue</button>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <fieldset>
                <label>Music</label>
                <select name="time">
                  <option value="">Select</option>
                  <option value="">Concert Band: In-School</option>
                  <option value="">Concert Band: Out-of-School</option>
                  <option value="">Guitar: In-School</option>
                  <option value="">Guitar: Out-of-School</option>
                </select>
              </fieldset>
              <ul className="tags">
                <li>
                  <button>
                    Concert Band: <br />
                    <span>In-School</span>
                  </button>
                </li>
                <li>
                  <button>
                    Guitar: <br />
                    <span>Out-of-School</span>
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-2">
              <fieldset>
                <label>Music</label>
                <select name="time">
                  <option value="">Select</option>
                  <option value="">Concert Band: In-School</option>
                  <option value="">Concert Band: Out-of-School</option>
                  <option value="">Guitar: In-School</option>
                  <option value="">Guitar: Out-of-School</option>
                </select>
              </fieldset>
              <ul className="tags">
                <li>
                  <button>
                    Concert Band: <br />
                    <span>In-School</span>
                  </button>
                </li>
                <li>
                  <button>
                    Guitar: <br />
                    <span>Out-of-School</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <section id="warning">
          <div className="neutral-callout">
            <p>
              It looks like this missing information is due to an incomplete
              survey.
            </p>
          </div>
        </section>
        <br /> <br /> <br /> <br />
      </main>
    </div>
  )
}

export default All
