import React from 'react'
import * as PropTypes from 'prop-types'
import * as Types from 'types'
import { pure } from 'recompose'
import FlaggedBannerExpandable from './flagged-banner-expandable'
import FlaggedPost from './flagged-post'
import FlaggedComment from './flagged-comment'
import FlaggedProfile from './flagged-profile'
import { ThumbsUpIcon } from 'components/icons/basic'
import classnames from 'classnames'
import { first } from 'lodash'

const propTypes = {
  item: PropTypes.oneOfType([Types.post, Types.comment, Types.directoryUser])
    .isRequired,
  agreeWithFlag: PropTypes.func.isRequired,
  disagreeWithFlag: PropTypes.func.isRequired,
  showProfileDetails: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
}

const defaultProps = {}

const { POST, COMMENT, PROFILE } = Types.REVIEWABLE
const flaggedItemType = {
  [POST]: FlaggedPost,
  [COMMENT]: FlaggedComment,
  [PROFILE]: FlaggedProfile,
}

function FlaggedItemWrapper({
  item,
  agreeWithFlag,
  disagreeWithFlag,
  showProfileDetails,
  flashSuccessMessage,
  flashErrorMessage,
  push,
}) {
  // Moderator decision is applied to all open flags, so just grab the first one
  const flagId = first(item.flags).id

  const requestAgreeWithFlag = () => {
    agreeWithFlag(flagId)
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }

  const requestDisagreeWithFlag = () => {
    disagreeWithFlag(flagId)
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }

  const markAppropriateAction = {
    icon: ThumbsUpIcon,
    label: 'Mark as Appropriate',
    onSelect: requestDisagreeWithFlag,
  }

  const FlaggedItem = flaggedItemType[item.reviewableType]

  return (
    <div
      className={classnames('card discussion-card', {
        comment: item.reviewableType === COMMENT,
      })}
    >
      <FlaggedBannerExpandable
        flags={item.flags}
        reviewableType={item.reviewableType}
      />
      <FlaggedItem
        flaggedItem={item}
        navigateTo={push}
        requestAgreeWithFlag={requestAgreeWithFlag}
        markAppropriateAction={markAppropriateAction}
        showProfileDetails={showProfileDetails}
      />
    </div>
  )
}

FlaggedItemWrapper.propTypes = propTypes
FlaggedItemWrapper.defaultProps = defaultProps

export default pure(FlaggedItemWrapper)
