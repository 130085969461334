import React from 'react'
// import PropTypes from 'prop-types'
import {
  LabeledField,
  omitLabelProps,
  Checkbox,
  Input,
  hasInputError,
  generateInputErrorId,
} from 'lp-components'
import { HiddenLabel } from 'components'
import { BLANK_VALUE } from 'types'

function CheckboxWithText(props) {
  const {
    input: { value, onChange, name },
    meta,
    placeholder,
    disabled = false,
  } = omitLabelProps(props)
  const fieldName = meta.form + '-' + name
  const checked = !!value
  return (
    <LabeledField className="CheckboxGroup" {...props} label={false}>
      <Checkbox
        label={props.label} //eslint-disable-line
        input={{
          value: checked,
          onChange: (val) => (val ? onChange(BLANK_VALUE) : onChange('')),
          name: fieldName,
        }}
        meta={{}}
        disabled={disabled}
      />
      <Input
        placeholder={placeholder}
        labelComponent={HiddenLabel}
        label={props.label}
        input={{
          name: fieldName + '-input',
          value: value === BLANK_VALUE ? '' : value,
          onChange,
        }}
        meta={{}}
        className="checkbox-text-field"
        disabled={disabled}
        aria-describedby={
          hasInputError(meta) ? generateInputErrorId(name) : null
        }
      />
    </LabeledField>
  )
}

export default CheckboxWithText
