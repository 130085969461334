import React from 'react'
import { pure } from 'recompose'
import * as Types from 'types'
import ExternalLink from './external-link'

const propTypes = {
  image: Types.image.isRequired,
}

const defaultProps = {}

function CommunityImage({ image, ...rest }) {
  const { src, alt, link } = image
  const Wrapper = link ? ExternalLink : React.Fragment
  const wrapperProps = link ? { href: link } : {}

  return (
    <Wrapper {...wrapperProps}>
      <img src={src} alt={alt || ''} {...rest} />
    </Wrapper>
  )
}

CommunityImage.propTypes = propTypes
CommunityImage.defaultProps = defaultProps

export default pure(CommunityImage)
