import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { SectionBlock, SectionHeader } from 'components'
import { OrganizationPositionContactForm } from '../forms'
import * as Types from 'types'
import * as globalActions from 'partner-portal-actions'
import * as apiActions from 'api-actions'
import { selectors as globalSelectors } from 'partner-portal-reducer'
import * as flashActions from 'redux-flash'
import { Spinner } from 'lp-components'
import { orderBy } from 'lodash'
import { displaySubmitFailure } from 'utils'

const propTypes = {
  currentSchoolYear: PropTypes.number,
  flashSuccessMessage: PropTypes.func.isRequired,
  partner: Types.partner,
  updatePartner: PropTypes.func.isRequired,
  setPartner: PropTypes.func.isRequired,
}

const defaultProps = {
  currentSchoolYear: null,
  partner: null,
}

function Contacts({
  currentSchoolYear,
  flashSuccessMessage,
  partner,
  setPartner,
  updatePartner,
}) {
  if (!partner || !currentSchoolYear) return <Spinner />

  const updatePartnerContacts = useCallback(
    ({ contact }) => {
      return updatePartner(partner.id, currentSchoolYear, {
        contacts: [contact],
      })
    },
    [partner, currentSchoolYear]
  )

  const contacts = useMemo(() => {
    // Find the _first_ contact with the position (workaround for orgs with duplicative data)
    const contactsToShow = partner.positions.map((position) => {
      const contact = partner.contacts.find(
        (contact) => contact.positionId === position.id
      )
      return contact || { positionId: position.id, position: position.name }
    })

    return orderBy(contactsToShow, 'position')
  }, [partner.contacts])

  return (
    <div>
      <SectionBlock>
        <SectionHeader>
          <h2> Organization Contacts </h2>
        </SectionHeader>
        <p>
          Let us know how to get in touch with you by providing contact
          information! The individual you choose as your education contact will
          be listed on artlook<sup>®</sup> Map as the point person at your
          organization for facilitating education and school partnerships. This
          individual will also receive any requested password resets for your
          organization.
        </p>
      </SectionBlock>

      {contacts.map((contact) => (
        <OrganizationPositionContactForm
          key={contact.positionId}
          name={`partner-organization-position-${contact.positionId}`}
          onSubmit={updatePartnerContacts}
          onSubmitSuccess={(partner) => {
            setPartner(partner)
            return flashSuccessMessage('Contact information updated!')
          }}
          onSubmitFail={displaySubmitFailure}
          initialValues={{ contact }}
        />
      ))}
    </div>
  )
}

Contacts.propTypes = propTypes
Contacts.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentSchoolYear: globalSelectors.currentSchoolYear(state),
    partner: globalSelectors.partner(state),
  }
}

const mapDispatchToProps = {
  flashSuccessMessage: flashActions.flashSuccessMessage,
  setPartner: globalActions.setPartner,
  updatePartner: apiActions.updatePartner,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Contacts)
