import { createAction } from 'redux-actions'
import { push } from 'react-router-redux'
import { PARTNER_PORTAL_ROUTE } from 'config'

// Global actions

export const clearPartner = createAction('CLEAR_PORTAL_PARTNER')
export const setIsAdmin = createAction('SET_ADMIN')
export const setAuthenticated = createAction('SET_PARTNER_AUTHENTICATED')
export const setCurrentPartnerId = createAction('SET_CURRENT_PARTNER_ID')
export const setPartner = createAction('SET_PARTNER')
export const setPrimaryContact = createAction('SET_PRIMARY_CONTACT')
export const setUserDetails = createAction('SET_USER_DETAILS')

export function setAdminUserAndRedirect(token, organizationId) {
  return function (dispatch) {
    dispatch(setAuthenticated({ token }))
    dispatch(setIsAdmin(true))
    dispatch(setCurrentPartnerId(organizationId))
    dispatch(push(PARTNER_PORTAL_ROUTE))
  }
}
