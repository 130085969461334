import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import * as Types from 'types'
import classnames from 'classnames'

const propTypes = {
  schoolYears: PropTypes.arrayOf(Types.schoolYear).isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
}

const defaultProps = {
  value: null,
  onChange: noop,
}

function SchoolYearTabBar({ schoolYears, value, onChange }) {
  return (
    <ul className="year-block">
      {schoolYears.map(({ number, id }, i) => (
        <li
          key={i}
          className={classnames({ 'is-active': id === value })}
          onClick={() => onChange(id)}
        >
          <a> {number} </a>
        </li>
      ))}
    </ul>
  )
}

SchoolYearTabBar.propTypes = propTypes
SchoolYearTabBar.defaultProps = defaultProps

export default SchoolYearTabBar
