import React from 'react'
import { Content, ExternalLink } from 'components'
import homeHero from 'images/map/landing/home-hero.jpg'

function Contact() {
  return (
    <Content>
      <header className="map-page-header">
        <div className="content-block-container">
          <div className="text-block">
            <h1>Contact Us</h1>
          </div>
        </div>
        <div
          className="image-block"
          style={{ background: `url(${homeHero}) no-repeat center` }}
        ></div>
      </header>

      <div className="search-block home-block">
        <div className="content-block-container">
          <div className="text-only-block card">
            <div className="row">
              <p className="col-8">
                Please send any inquiries to info@artlookmap.com. Ingenuity
                hopes that artlook Map will serve as a reflective planning tool
                for various stakeholders, so please feel free to contact us with
                any questions on our data collection, community arts
                partnerships, etc. If you would like to learn more about how to
                use the data on artlook Map to inform the design and
                distribution of your arts programs, please consider attending
                our Institute & Summit series.
              </p>
              <div className="col-4">
                <p>
                  <strong>Ingenuity</strong>
                  <br />
                  440 N. Wells Street
                  <br />
                  Suite 505
                  <br />
                  Chicago, Illinois 60654{' '}
                </p>
                <p>
                  <strong>312-583-7459</strong>
                </p>
                <ExternalLink href="mailto:info@artlookmap.com">
                  <strong>info@artlookmap.com</strong>
                </ExternalLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Content>
  )
}

export default Contact
