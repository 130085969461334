import { mapValues, omitBy, isEmpty, isPlainObject } from 'lodash'
import { stringify } from 'query-string'
import isBlank from './is-blank'

function buildSearchQueryString(params) {
  const queryHash = omitBlankNested(params)
  if (isEmpty(queryHash)) return ''

  const safeQueryHash = stringifyObjects(queryHash)

  return '?' + stringify(safeQueryHash)
}

// ----- PRIVATE -----

// Filter out "blank" values to mitigate URL bloat
function omitBlankNested(obj) {
  return omitBlank(
    mapValues(obj, (value) => {
      if (isBlank(value)) return
      if (!isPlainObject(value)) return value

      const nested = omitBlankNested(value)
      return isBlank(nested) ? undefined : nested
    })
  )
}

function omitBlank(obj) {
  return omitBy(obj, isBlank)
}

// query-string doesn't support nesting, so use JSON.stringify for objects
function stringifyObjects(obj) {
  return mapValues(obj, (value) => {
    if (!isPlainObject(value)) return value
    return JSON.stringify(value)
  })
}

export default buildSearchQueryString
