import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose, withProps } from 'recompose'
import { lpForm } from 'lp-form'
import { Input, CheckboxGroup, SubmitButton } from 'lp-components'
import {
  CloudinaryFileInput,
  RemovableThumbnail,
  SelectAllCheckboxGroup,
} from 'components'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { CLOUD_RESIZE_PRESET } from 'config'
import { isString, size } from 'lodash'
import { OptionalAutoSuggestInput, ExpandableMenuInput } from '../components'
import {
  filterUnchanged,
  serializeOptions,
  formatObjectsToIds,
  parseIdToObject,
  pluralize,
  getCloudinaryUploadErrorMessage,
  convertCloudinarySrcUrlToFilename,
} from 'utils'

const propTypes = {
  ...formPropTypes,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  grades: PropTypes.arrayOf(Types.grade).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

const defaultProps = {}

const PRONOUN_OPTIONS = ['he/him/his', 'she/her/hers', 'they/them/theirs']

function parseOption(option) {
  if (!option) return ''
  if (isString(option)) return option
  return option.id
}

// Select the existing object to avoid creating duplicates; else create a new object
function parseIdsToObjects(objects, key) {
  return (ids) => {
    return ids.map((id) => {
      const object = objects.find((obj) => obj[key] === id)
      return object || parseIdToObject(key, id)
    })
  }
}

function CollectionInputMenuTitle({ title, input: { value } }) {
  const count = size(value)
  if (!count) return <span>{title}</span>

  const baseDisplay = `${title}: ${count}`
  return (
    <span aria-label={`${baseDisplay} ${pluralize('option', count)} selected`}>
      {baseDisplay}
    </span>
  )
}

function ProfileForm({
  change,
  handleSubmit,
  submitting,
  flashSuccessMessage,
  flashErrorMessage,
  initialValues,
  disciplines,
  grades,
}) {
  const [avatarImage, setAvatarImage] = useState(() => {
    if (!initialValues.avatarUrl) return null
    const filename = convertCloudinarySrcUrlToFilename(initialValues.avatarUrl)
    return {
      src: initialValues.avatarUrl,
      filename,
    }
  })
  const serializedDisciplineOptions = serializeOptions(disciplines)
  const serializedGradeOptions = serializeOptions(grades)
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <Field
          name="firstName"
          label="First Name (or preferred first name)"
          component={Input}
          required
          className="col-4"
        />
        <Field name="lastName" component={Input} required className="col-4" />
        <Field name="school" component={Input} disabled className="col-4" />
      </div>
      <div className="row">
        <div className="col-4">
          <Field
            name="disciplines"
            label="What disciplines do you teach? (optional)"
            component={ExpandableMenuInput}
            inputComponent={CheckboxGroup}
            menuTitle="Select Arts Disciplines"
            menuTitleComponent={CollectionInputMenuTitle}
            options={serializedDisciplineOptions}
            format={formatObjectsToIds('disciplineId')}
            parse={parseIdsToObjects(initialValues.disciplines, 'disciplineId')}
          />
        </div>
        <div className="col-4">
          <Field
            name="grades"
            label="Grade Levels (optional)"
            component={ExpandableMenuInput}
            inputComponent={SelectAllCheckboxGroup}
            menuTitle="Select Grade Levels"
            menuTitleComponent={CollectionInputMenuTitle}
            options={serializedGradeOptions}
            format={formatObjectsToIds('gradeId')}
            parse={parseIdsToObjects(initialValues.grades, 'gradeId')}
          />
        </div>
        <Field
          name="yearsOfExperience"
          label="Years of Teaching Experience (optional)"
          component={Input}
          type="number"
          className="col-4"
          min={0}
        />
      </div>
      <div className="row">
        <Field
          name="avatarUrl"
          accept=".png,.jpg,.jpeg"
          label="Profile Picture (optional)"
          component={CloudinaryFileInput}
          uploadPath={`/schools/users/profile-pictures/${initialValues.id}`}
          uploadPreset={CLOUD_RESIZE_PRESET}
          onUploadSuccess={({ secureUrl }) => {
            const filename = convertCloudinarySrcUrlToFilename(secureUrl)
            setAvatarImage({ src: secureUrl, filename })
            flashSuccessMessage(
              'Profile picture successfully uploaded. Make sure to save your changes.'
            )
          }}
          onUploadFailure={(err) => {
            const message = getCloudinaryUploadErrorMessage(err)
            flashErrorMessage(message)
          }}
          previewComponent={RemovableThumbnail}
          remove={() => {
            change('avatarUrl', '')
            setAvatarImage(null)
            flashSuccessMessage(
              'Existing profile picture removed. Make sure to save your changes.'
            )
          }}
          image={avatarImage}
          className="col-4"
        />
        <Field
          name="pronouns"
          label="Pronouns (optional)"
          component={OptionalAutoSuggestInput}
          parse={parseOption}
          inputProps={{
            placeholder: 'Third Person Pronouns (e.g., she/her/hers)',
          }}
          suggestions={PRONOUN_OPTIONS}
          shouldRenderSuggestions={() => true}
          className="col-4"
        />
      </div>
      <SubmitButton submitting={submitting}>Save Profile</SubmitButton>
    </form>
  )
}

ProfileForm.propTypes = propTypes
ProfileForm.defaultProps = defaultProps

export default compose(
  withProps(({ initialValues }) => {
    return {
      beforeSubmit: ({ disciplines, grades, ...values }) => {
        return {
          disciplines: filterUnchanged({
            initial: initialValues.disciplines,
            new: disciplines,
          }),
          applicableGrades: filterUnchanged({
            initial: initialValues.grades,
            new: grades,
          }),
          ...values,
        }
      },
    }
  }),
  lpForm({
    name: 'SchoolProfileForm',
    constraints: {
      firstName: {
        presence: true,
      },
      lastName: {
        presence: true,
      },
      yearsOfExperience: {
        numericality: {
          onlyInteger: true,
          notInteger: '^Years of teaching experience must be a whole number',
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo:
            '^Years of teaching experience must be greater than or equal to 0',
        },
      },
    },
  })
)(ProfileForm)
