import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as routerActions from 'react-router-redux'
import * as flashActions from 'redux-flash'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { Spinner } from 'lp-components'
import { Modal } from 'components'
import {
  FlaggedItemWrapper,
  EmptyState,
  FlaggedViewProfileDetails,
} from '../components'
import { isEmpty } from 'lodash'

const propTypes = {
  fetchFlaggedItems: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  flaggedItemsGroupedByDate: PropTypes.arrayOf(Types.flaggedItemsWithDate),
  selectedUser: Types.directoryUser,
  agreeWithFlag: PropTypes.func.isRequired,
  disagreeWithFlag: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
}

const defaultProps = {
  flaggedItemsGroupedByDate: null,
  selectedUser: null,
}

function Flagged({
  fetchFlaggedItems,
  fetchUser,
  flaggedItemsGroupedByDate,
  selectedUser,
  ...itemActions
}) {
  useEffect(() => {
    fetchFlaggedItems()
  }, [])

  const [isProfileDetailsOpen, setIsProfileDetailsOpen] = useState(false)
  const fetchUserAndShowProfile = (userId) =>
    fetchUser(userId).then(() => setIsProfileDetailsOpen(true))

  if (!flaggedItemsGroupedByDate) return <Spinner />

  if (isEmpty(flaggedItemsGroupedByDate)) return <EmptyState />

  return (
    <div>
      {flaggedItemsGroupedByDate.map(({ date, flaggedItems }) => {
        return (
          <div key={date} className="flagged-date-group">
            <p className="flagged-date">{date}</p>
            <div>
              {flaggedItems.map((item) => {
                return (
                  <FlaggedItemWrapper
                    key={item.reviewableType + item.id}
                    item={item}
                    showProfileDetails={fetchUserAndShowProfile}
                    {...itemActions}
                  />
                )
              })}
            </div>
          </div>
        )
      })}
      {isProfileDetailsOpen && selectedUser && (
        <Modal onClose={() => setIsProfileDetailsOpen(false)}>
          <div className="discussion-card">
            <FlaggedViewProfileDetails user={selectedUser} />
          </div>
        </Modal>
      )}
    </div>
  )
}

Flagged.propTypes = propTypes

Flagged.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    flaggedItemsGroupedByDate: selectors.flaggedItemsGroupedByDate(state),
    selectedUser: selectors.directoryUser(state),
  }
}

const mapDispatchToProps = {
  fetchFlaggedItems: apiActions.fetchFlaggedItems,
  fetchUser: apiActions.fetchDirectoryUser,
  agreeWithFlag: apiActions.agreeWithFlag,
  disagreeWithFlag: apiActions.disagreeWithFlag,
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  push: routerActions.push,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Flagged)
