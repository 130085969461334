import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { ActionMenu } from 'components'
import { ProfileOverview, ProfileInfoList } from 'educator-portal-components'
import { SearchIcon, TrashIcon } from 'components/icons/basic'
import { getUserDisplayName } from 'utils'
import { pure } from 'recompose'
import { EDUCATOR_PORTAL_ROUTE } from 'config'

const propTypes = {
  flaggedItem: Types.directoryUser.isRequired,
  requestAgreeWithFlag: PropTypes.func.isRequired,
  markAppropriateAction: Types.menuAction.isRequired,
  navigateTo: PropTypes.func.isRequired,
}

const defaultProps = {}

function FlaggedProfile({
  flaggedItem: user,
  requestAgreeWithFlag,
  markAppropriateAction,
  navigateTo,
}) {
  const {
    id,
    firstName,
    lastName,
    avatarUrl: profileImgUrl,
    schoolNames,
    email,
    pronouns,
  } = user
  const userDisplayName = getUserDisplayName({ firstName, lastName }, email)
  const searchForumWithUserPath = encodeURI(
    `${EDUCATOR_PORTAL_ROUTE}/forum?filters={"userId":[${id}]}`
  )

  const searchUserContentAction = {
    icon: SearchIcon,
    label: 'Search User Content',
    onSelect: () => navigateTo(searchForumWithUserPath),
  }
  const suspendUserAction = {
    icon: TrashIcon,
    label: 'Suspend User',
    onSelect: requestAgreeWithFlag,
  }

  const actions = [
    searchUserContentAction,
    suspendUserAction,
    markAppropriateAction,
  ]

  return (
    <div id="profile-details">
      <div className="text-block">
        <ProfileOverview
          userDisplayName={userDisplayName}
          pronouns={pronouns}
          profileImgUrl={profileImgUrl}
          schoolNames={schoolNames}
        />
        <div className="flex-vertical">
          <div className="flex-horizontal forum-action-menu">
            <ActionMenu actions={actions} />
          </div>
        </div>
      </div>
      <div>
        <ProfileInfoList user={user} />
      </div>
    </div>
  )
}

FlaggedProfile.propTypes = propTypes
FlaggedProfile.defaultProps = defaultProps

export default pure(FlaggedProfile)
