import React from 'react'
import { WarningIcon } from 'components/icons/basic'
import { ExternalLink } from 'components'
import { useCommunity } from 'utils'

function ErrorSearchState() {
  const community = useCommunity()

  return (
    <div className="empty-state">
      <WarningIcon />
      <h3>Sorry, we can’t load your results right now.</h3>
      <p>
        There seems to be a technical issue that is preventing your results from
        loading. Please run your search again or try again later. If you
        continue to have issues, please let us know by contacting{' '}
        <ExternalLink href={`mailto:${community.schoolSupportEmail}`}>
          {community.schoolSupportEmail}
        </ExternalLink>
        .
      </p>
    </div>
  )
}

export default ErrorSearchState
