import { get } from 'lodash'

// Returns true if a "type" object has a name of "none".

function isNoneType(type) {
  if (!type) return false
  return get(type, 'name') === 'none'
}

export default isNoneType
