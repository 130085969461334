import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { lpForm } from 'lp-form'
import { modifyProps } from 'lp-hoc'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { SubmitButton } from 'lp-components'
import { CheckboxGroup, CheckboxWithText } from 'components'
import * as Types from 'types'
import {
  replaceResources,
  formatObjectsToIds,
  parseIdsToObjects,
  persistSubmitSucceeded,
  yankBy,
  getOtherTypeId,
  serializeOptions,
  isOtherType,
} from 'utils'

const propTypes = {
  ...formPropTypes,
  programTypes: PropTypes.arrayOf(Types.resourceType).isRequired,
}

function PartnerProgramTypeForm({
  handleSubmit,
  programTypes,
  submitSucceeded,
  pristine,
  submitting,
}) {
  const [otherProgramType, definedProgramTypes] = useMemo(
    () => yankBy(programTypes, isOtherType),
    [programTypes]
  )
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="requestedProgramTypes"
          label={false}
          component={CheckboxGroup}
          format={formatObjectsToIds('programTypeId')}
          parse={parseIdsToObjects('programTypeId')}
          options={serializeOptions(definedProgramTypes)}
        />
        <Field
          name="otherText"
          label={otherProgramType.displayName}
          placeholder="Description..."
          component={CheckboxWithText}
        />
        <div className="button-wrapper">
          <SubmitButton
            {...{ pristine: submitSucceeded && pristine, submitting }}
          >
            Save Response
          </SubmitButton>
        </div>
      </div>
    </form>
  )
}

PartnerProgramTypeForm.propTypes = propTypes

function modifyBeforeSubmit({ otherTypeId, initialValues }) {
  return {
    beforeSubmit: ({ requestedProgramTypes, otherText, type }) => {
      const resources = replaceResources({
        old: initialValues.requestedProgramTypes,
        new: requestedProgramTypes,
      })
      if (otherText) resources.push({ programTypeId: otherTypeId, otherText })
      return { programTypes: resources, type }
    },
  }
}

function modifyOtherTypeId({ programTypes }) {
  return {
    otherTypeId: getOtherTypeId(programTypes),
  }
}

function modifyInitialValues({ initialValues, otherTypeId }) {
  const [otherRequestedProgramType, requestedProgramTypes] = yankBy(
    initialValues.requestedProgramTypes,
    ({ programTypeId }) => programTypeId === otherTypeId
  )
  return {
    initialValues: {
      ...initialValues,
      requestedProgramTypes,
      otherText: otherRequestedProgramType
        ? otherRequestedProgramType.otherText
        : '',
      type: Types.REQUEST_FORM_TYPE,
    },
  }
}

export default compose(
  modifyProps(modifyOtherTypeId),
  modifyProps(modifyBeforeSubmit),
  modifyProps(modifyInitialValues),
  lpForm({
    name: 'partner-program-type',
    enableReinitialize: true,
    constraints: {
      otherText: {
        exclusion: {
          within: [' '],
          message: '^Description is required',
        },
      },
    },
  }),
  persistSubmitSucceeded()
)(PartnerProgramTypeForm)
