import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import * as Types from 'types'
import certified from 'images/certified.svg'
import {
  SearchResultContactLink,
  HighlightText,
  OpenToPartnershipsIndicator,
} from 'components'
import { serializeLocation } from 'utils'
import { get, uniq, startCase } from 'lodash'
import locationImg from 'images/map/dashboards/location.svg'
import emailImg from 'images/map/dashboards/email.svg'

const propTypes = {
  partner: Types.partnerSearchData.isRequired,
  schoolYearFilter: PropTypes.number,
}

const defaultProps = {}

function PartnerSearchResult({
  partner,
  partner: {
    id,
    name,
    disciplines,
    contact,
    isCertified,
    highlights,
    openToPartnerships,
  },
  schoolYearFilter,
}) {
  const partnerUrl = `/partners/${id}`
  const address = get(serializeLocation(partner.type, partner), 'address')
  const anyHighlights = Object.keys(highlights).length > 0
  const mappedDisciplines = uniq(disciplines.map((d) => startCase(d.name)))
    .sort()
    .join(', ')
  return (
    <div>
      <div className="text-block">
        <h3 data-cy="search-result-name">
          <Link to={`${partnerUrl}?year=${schoolYearFilter}`}>{name}</Link>
        </h3>
        <ul className="contact-details">
          {address && (
            <li>
              <img src={locationImg} alt="" />
              {address}
            </li>
          )}
          {contact && contact.email && (
            <li>
              <img src={emailImg} alt="" />
              {contact.email}
            </li>
          )}
        </ul>
        {mappedDisciplines.length > 0 && <p>Provides: {mappedDisciplines}</p>}
        {anyHighlights && (
          <ul className="matched-terms">
            {Object.keys(highlights).map((key, i) => {
              return (
                <HighlightText
                  key={i}
                  highlightName={key}
                  highlightValue={highlights[key]}
                />
              )
            })}
          </ul>
        )}
      </div>
      <div className="result-details">
        {openToPartnerships && <OpenToPartnershipsIndicator />}
        {contact && (
          <SearchResultContactLink
            email={contact.email}
            resultName={name}
            contactName={contact.firstName}
          >
            <button
              aria-label={`Contact ${name}`}
              className="button-default-outline"
            >
              Contact Partner
            </button>
          </SearchResultContactLink>
        )}
        {isCertified && (
          <img className="certified" src={certified} alt="Certified Logo" />
        )}
      </div>
    </div>
  )
}

PartnerSearchResult.propTypes = propTypes

PartnerSearchResult.defaultProps = defaultProps

export default PartnerSearchResult
