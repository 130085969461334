import { push } from 'react-router-redux'
import * as globalActions from 'partner-portal-actions'
import { PARTNER_PORTAL_ROUTE } from 'config'

export function signOut() {
  return function (dispatch) {
    dispatch(clearDetails())
    dispatch(push(PARTNER_PORTAL_ROUTE))
  }
}

export function clearDetails() {
  return function (dispatch) {
    dispatch(globalActions.setAuthenticated({ token: null }))
    dispatch(globalActions.setIsAdmin(false))
    dispatch(globalActions.setCurrentPartnerId(null))
    dispatch(globalActions.clearPartner())
  }
}
