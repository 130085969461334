import React, { useState, useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { selectors as globalSelectors } from 'educator-portal-reducer'
import { selectors as apiSelectors } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import * as flashActions from 'redux-flash'
import * as routerActions from 'react-router-redux'
import { ProfilesList } from '../components'
import { DirectorySearchForm } from '../forms'
import { Spinner } from 'lp-components'
import { DIRECTORY_PAGE_SIZE, EDUCATOR_PORTAL_ROUTE } from 'config'
import {
  pluralize,
  useCommunity,
  displaySubmitFailure,
  buildSearchQueryString,
  parseSearchQueryObject,
} from 'utils'
import { get, map, size } from 'lodash'
import classnames from 'classnames'

const propTypes = {
  users: PropTypes.arrayOf(Types.directoryUser),
  usersCount: PropTypes.number.isRequired,
  directoryPageCount: PropTypes.number.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  fetchSearchOptions: PropTypes.func.isRequired,
  searchOptions: Types.directoryFilterOptions,
  isLoading: PropTypes.bool.isRequired,
  hasLoadFailed: PropTypes.bool.isRequired,
  addBookmark: PropTypes.func.isRequired,
  removeBookmark: PropTypes.func.isRequired,
  createFlag: PropTypes.func.isRequired,
  unflag: PropTypes.func.isRequired,
  disagreeWithFlag: PropTypes.func.isRequired,
  bookmarks: PropTypes.arrayOf(Types.bookmark).isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  isModerator: PropTypes.bool.isRequired,
  currentUserId: PropTypes.number.isRequired,
}

const defaultProps = {
  users: null,
  searchOptions: null,
}

function Directory({
  users,
  usersCount,
  directoryPageCount,
  fetchUsers,
  fetchSearchOptions,
  searchOptions,
  isLoading,
  hasLoadFailed,
  addBookmark,
  removeBookmark,
  createFlag,
  unflag,
  disagreeWithFlag,
  bookmarks,
  flashSuccessMessage,
  flashErrorMessage,
  location: { query: queryHash },
  push,
  isModerator,
  currentUserId,
}) {
  const community = useCommunity()
  const blockList = useMemo(
    () => get(community, 'search.directoryFilters.blockList', []),
    [community]
  )

  const [showSpinner, setShowSpinner] = useState(!users)

  const [filtersVisibleOnMobile, setFiltersVisibleOnMobile] = useState(false)

  const {
    query = '',
    filters = {},
    page = 1,
  } = useMemo(() => parseSearchQueryObject(queryHash), [queryHash])
  const usersCountText = useMemo(() => {
    if (isLoading && showSpinner) return '-'
    if (usersCount === 1 || usersCount <= DIRECTORY_PAGE_SIZE) return usersCount
    return `${size(users)} of ${usersCount}`
  }, [users, usersCount, isLoading, showSpinner])

  const searchUsers = useCallback(
    (params, { updateUrl = true } = {}) => {
      setShowSpinner(updateUrl || !users) // show spinner if new search or first load
      if (updateUrl) {
        push({
          pathname: `${EDUCATOR_PORTAL_ROUTE}/directory`,
          search: buildSearchQueryString(params),
        })
      }
      const bookmarkedUserIds = map(bookmarks, 'bookmarkableId')
      return fetchUsers(params, bookmarkedUserIds).then(() =>
        setShowSpinner(false)
      )
    },
    [fetchUsers, users, bookmarks]
  )

  useEffect(() => {
    searchUsers({ query, filters, page }, { updateUrl: false })
  }, [])

  const requestAddBookmark = useCallback((userId) => {
    addBookmark({
      bookmarkableId: userId,
      bookmarkableType: Types.BOOKMARKABLE.USER,
    })
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }, [])
  const requestRemoveBookmark = useCallback((bookmarkId) => {
    removeBookmark({ bookmarkId, bookmarkableType: Types.BOOKMARKABLE.USER })
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }, [])

  const requestCreateFlag = useCallback(({ reviewableId, reason }) => {
    createFlag({
      reviewableId,
      reviewableType: Types.REVIEWABLE.PROFILE,
      reason,
    })
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }, [])

  const requestUnflag = useCallback((flagId) => {
    unflag(flagId)
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }, [])

  const requestDisagreeWithFlag = useCallback((flagId) => {
    disagreeWithFlag(flagId)
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }, [])

  useEffect(() => {
    if (!searchOptions)
      fetchSearchOptions({ blockList }, Types.SEARCH_OPTIONS_TYPE.DIRECTORY)
  }, [searchOptions, blockList])

  if (!searchOptions) return <Spinner />

  return (
    <div>
      <button
        type="button"
        className="link-black filter-toggle advanced-filters"
        aria-expanded={filtersVisibleOnMobile}
        aria-controls="filter-content"
        onClick={() => setFiltersVisibleOnMobile(true)}
      >
        Advanced Filters +
      </button>
      <div
        id="educator-search"
        className={classnames({
          'is-active': filtersVisibleOnMobile,
        })}
      >
        <button
          type="button"
          className="link-black close"
          aria-label="Close Filters"
          onClick={() => setFiltersVisibleOnMobile(false)}
        >
          ×
        </button>
        <DirectorySearchForm
          filterOptions={searchOptions}
          initialValues={{
            filters,
          }}
          onSubmit={searchUsers}
          onSubmitFail={displaySubmitFailure}
        />
      </div>
      <div id="directory-profiles">
        <div className="profiles-count">
          <span
            role="status"
            className="visually-hidden"
            aria-live="polite"
            aria-atomic="true"
          >
            {showSpinner
              ? 'Loading'
              : `Loading completed with ${usersCount} ${pluralize(
                  'profile',
                  usersCount
                )}`}
          </span>
          <p>
            <strong>
              Showing {usersCountText} {pluralize('Profile', usersCount)}
            </strong>
          </p>
        </div>
        {showSpinner ? (
          <Spinner />
        ) : (
          <div>
            <ProfilesList
              users={users}
              numPages={directoryPageCount}
              resultsPage={page}
              setResultsPage={(newPage) => {
                return searchUsers({ query, filters, page: newPage })
              }}
              hasLoadFailed={hasLoadFailed}
              bookmarks={bookmarks}
              addBookmark={requestAddBookmark}
              removeBookmark={requestRemoveBookmark}
              isModerator={isModerator}
              requestCreateFlag={requestCreateFlag}
              requestRemoveFlag={
                isModerator ? requestDisagreeWithFlag : requestUnflag
              }
              currentUserId={currentUserId}
            />
          </div>
        )}
      </div>
    </div>
  )
}

Directory.propTypes = propTypes

Directory.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    users: selectors.users(state),
    usersCount: selectors.usersCount(state),
    directoryPageCount: selectors.directoryPageCount(state),
    searchOptions: selectors.searchOptions(state),
    isLoading: apiSelectors.isLoading(state, apiActions.fetchDirectoryUsers),
    hasLoadFailed: apiSelectors.isFailure(
      state,
      apiActions.fetchDirectoryUsers
    ),
    bookmarks: selectors.bookmarks(state),
    isModerator: globalSelectors.isModerator(state),
    currentUserId: globalSelectors.userId(state),
  }
}

const mapDispatchToProps = {
  fetchUsers: apiActions.fetchDirectoryUsers,
  fetchSearchOptions: apiActions.fetchSearchOptions,
  addBookmark: apiActions.addBookmark,
  removeBookmark: apiActions.removeBookmark,
  createFlag: apiActions.createFlag,
  unflag: apiActions.unflag,
  disagreeWithFlag: apiActions.disagreeWithFlag,
  push: routerActions.push,
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Directory)
