import React from 'react'
import PropTypes from 'prop-types'
import { Paginator } from 'lp-components'
import { ErrorLoadState } from 'components'
import { EmptyLoadState, ListPostCard } from './'
import * as Types from 'types'
import { isEmpty } from 'lodash'

const propTypes = {
  posts: PropTypes.arrayOf(Types.post),
  resultsPage: PropTypes.number.isRequired,
  setResultsPage: PropTypes.func.isRequired,
  hasLoadFailed: PropTypes.bool.isRequired,
  openPostForm: PropTypes.func.isRequired,
  numPages: PropTypes.number.isRequired,
  isFiltered: PropTypes.bool.isRequired,
  canPost: PropTypes.bool.isRequired,
}

const defaultProps = {
  posts: null,
}

function PostsList({
  posts,
  resultsPage,
  setResultsPage,
  hasLoadFailed,
  openPostForm,
  numPages,
  isFiltered,
  canPost,
  ...rest
}) {
  if (isEmpty(posts))
    return (
      <div className="results-section">
        {hasLoadFailed ? (
          <ErrorLoadState componentName="Forum Discussions" headerAs="h3" />
        ) : (
          <EmptyLoadState
            openPostForm={openPostForm}
            isFiltered={isFiltered}
            canPost={canPost}
          />
        )}
      </div>
    )

  return (
    <div className="all-results">
      <ul className="result-blocks">
        {posts.map((post) => {
          return (
            <li key={post.id}>
              <ListPostCard post={post} {...rest} />
            </li>
          )
        })}
        <Paginator
          value={resultsPage}
          max={numPages}
          onChange={setResultsPage}
        />
      </ul>
    </div>
  )
}

PostsList.propTypes = propTypes
PostsList.defaultProps = defaultProps

export default PostsList
