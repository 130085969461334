import React from 'react'

function ShareIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#5B5F66" fillRule="nonzero" transform="translate(4 3)">
          <path d="M9.994 9.92c-.501 0-.97.176-1.303.527l-4.78-2.92c.033-.175.067-.316.067-.492s-.034-.317-.067-.492l4.713-2.885c.368.352.836.563 1.37.563 1.103 0 2.006-.95 2.006-2.11C12 .95 11.097 0 9.994 0 8.891 0 7.99.95 7.99 2.11c0 .176.033.317.067.493L3.343 5.487a1.954 1.954 0 00-1.37-.562C.868 4.925 0 5.875 0 7.035c0 1.161.902 2.11 2.006 2.11.534 0 1.002-.21 1.37-.562l4.747 2.92c-.034.14-.067.281-.067.457 0 1.126.869 2.04 1.938 2.04 1.07 0 1.939-.914 1.939-2.04s-.869-2.04-1.939-2.04z"></path>
        </g>
      </g>
    </svg>
  )
}

export default ShareIcon
