import { createAction } from 'redux-actions'
import { push } from 'react-router-redux'
import { SCHOOL_PORTAL_ROUTE } from 'config'

// Global actions

export const clearSchool = createAction('CLEAR_PORTAL_SCHOOL')
export const setIsAdmin = createAction('SET_IS_ADMIN')
export const setAuthenticated = createAction('SET_SCHOOL_AUTHENTICATED')
export const setCurrentSchoolId = createAction('SET_CURRENT_SCHOOL_ID')
export const setSchool = createAction('SET_SCHOOL')
export const setUserDetails = createAction('SET_USER_DETAILS')

export function setAdminUserAndRedirect(token, schoolId) {
  return function (dispatch) {
    dispatch(setAuthenticated({ token }))
    dispatch(setIsAdmin(true))
    dispatch(setCurrentSchoolId(schoolId))
    dispatch(push(SCHOOL_PORTAL_ROUTE))
  }
}

export function setUserAndRedirect({
  token,
  schoolId,
  persistSession,
  redirectUrl,
}) {
  return function (dispatch) {
    dispatch(setAuthenticated({ token, persistSession }))
    dispatch(setCurrentSchoolId(schoolId))
    dispatch(push(redirectUrl || SCHOOL_PORTAL_ROUTE))
  }
}
