import React from 'react'
import PropTypes from 'prop-types'
import { compose, withState } from 'recompose'
import { noop } from 'lodash'
import { Button } from 'lp-components'

const propTypes = {
  submitting: PropTypes.bool.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  onClick: PropTypes.func,
}

const defaultProps = {
  onClick: noop,
}

// This button shows a spinner while its onClick is executing.

function SpinnerButton({ submitting, setSubmitting, onClick, ...rest }) {
  return (
    <Button
      {...rest}
      submitting={submitting}
      onClick={(...args) => {
        setSubmitting(true)
        const value = onClick(...args)
        if (!value || !value.finally) return setSubmitting(false)
        value.finally(() => setSubmitting(false))
      }}
    />
  )
}

SpinnerButton.propTypes = propTypes

SpinnerButton.defaultProps = defaultProps

export default compose(withState('submitting', 'setSubmitting', false))(
  SpinnerButton
)
