import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import {
  ResidencyIcon,
  OutOfSchoolIcon,
  FieldTripIcon,
  InSchoolIcon,
  VisualArtsIcon,
  ProfessionalDevelopmentIcon,
  MediaArtsIcon,
} from 'components/icons/basic'

const propTypes = {
  programName: PropTypes.string.isRequired,
}

const defaultProps = {}

const programIcons = {
  [Types.PROGRAM_TYPES.RESIDENCY]: <ResidencyIcon />,
  [Types.PROGRAM_TYPES.OUT_OF_SCHOOL_TIME]: <OutOfSchoolIcon />,
  [Types.PROGRAM_TYPES.FIELD_TRIP]: <FieldTripIcon />,
  [Types.PROGRAM_TYPES.IN_SCHOOL_PERFORMANCE]: <InSchoolIcon />,
  [Types.PROGRAM_TYPES.RESOURCES]: <VisualArtsIcon />,
  [Types.PROGRAM_TYPES.PROFESSIONAL_DEVELOPMENT]: (
    <ProfessionalDevelopmentIcon />
  ),
  [Types.PROGRAM_TYPES.ONLINE]: <MediaArtsIcon />,
}

function ProgramsTabCardIcon({ programName }) {
  return programIcons[programName] || null
}

ProgramsTabCardIcon.propTypes = exact(propTypes)
ProgramsTabCardIcon.defaultProps = defaultProps

export default pure(ProgramsTabCardIcon)
