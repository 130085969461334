import { handleActions } from 'redux-actions'
import { setOnSuccess } from 'lp-redux-api'
import { selectorForSlice } from 'lp-redux-utils'
import { serializeLocation, set } from 'utils'
import { createSelector } from 'reselect'
import * as actions from './actions'
import * as Types from 'types'
import * as apiActions from 'api-actions'
import { orderBy } from 'lodash'

const reducerKey = 'partners'
const slice = 'root.map.partners'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchPartner]: setOnSuccess('partner'),
    [actions.clearPartner]: (state) => set('partner', null, state),
    [apiActions.fetchActiveAndPublishedSchoolYears]:
      setOnSuccess('schoolYears'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  partner: select('partner'),
  schoolYears: select('schoolYears'),
}

// Computed
selectors.partnerLocations = createSelector(
  [selectors.partner],
  function (partner) {
    if (!partner || !partner.partnerships) return []
    return partner.partnerships.map(serializeLocation(Types.SCHOOL_TYPE))
  }
)

selectors.currentPartnerLocation = createSelector(
  [selectors.partner],
  function (partner) {
    if (!partner) return
    return serializeLocation(Types.PARTNER_TYPE, partner)
  }
)

// Order school years by most recent (DESC)
selectors.orderedSchoolYears = createSelector(
  [selectors.schoolYears],
  function (schoolYears) {
    if (!schoolYears) return
    return orderBy(schoolYears, 'number', 'desc')
  }
)

export { reducer, selectors, reducerKey }
