import deepMapKeys from 'deep-map-keys'

// Takes params and renames keys with array values to "<key>Attributes"
// to play nicely with rails accepts_nested_attributes_for
// Also convert any extra specified keys

function convertToNestedAttributes(params, { extraKeys = [] } = {}) {
  return deepMapKeys(params, (key, value) =>
    Array.isArray(value) || extraKeys.includes(key) ? `${key}Attributes` : key
  )
}

export default convertToNestedAttributes
