import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, HiddenSpan } from 'components'
import * as Types from 'types'
import { CopyPartnershipsForm } from '../forms'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  partnerships: PropTypes.arrayOf(Types.partnership),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  schoolYears: PropTypes.arrayOf(Types.schoolYear).isRequired,
}

const defaultProps = {
  partnerships: [],
}

function CopyPartnershipModal({
  onClose,
  partnerships,
  onSubmit,
  onSubmitSuccess,
  schoolYears,
}) {
  const [submitFailCount, setSubmitFailCount] = useState(0)
  return (
    <Modal onClose={onClose}>
      <h2>Copy Existing Partnership</h2>
      <div className="modal-content">
        <p>
          Please note: when copying existing partnerships from a specific school
          year, you are copying the school partnership and associated programs
          that occurred at the school during the selected year. This is why you
          may see the same school listed more than once (with different school
          years), because program offerings may have been different each year.
        </p>
        <p>
          As an example, you may see Academy for Global Citizenship Elementary
          School (2012-13) and Academy for Global Citizenship Elementary School
          (2011-12) listed. Copying the 2012-13 record will copy over the school
          name and the programming that occurred that year, (example: a
          residency, an in-school performance, and a field trip.) Copying the
          2011-12 record will copy over the school name and associated
          programming for the 2011-12 year, which may be different than the
          2012-13 programs (for example, only an in-school performance.)
        </p>
        {partnerships.length ? (
          <CopyPartnershipsForm
            partnerships={partnerships}
            onSubmit={onSubmit}
            onSubmitSuccess={onSubmitSuccess}
            onSubmitFail={() => {
              setSubmitFailCount((sfc) => sfc + 1)
            }}
            schoolYears={schoolYears}
          >
            {(error) => (
              <div role="alert" aria-atomic>
                {error && (
                  <p className="full-error-message">
                    <span className="visually-hidden">Form invalid: </span>
                    {/* The hidden count will force screen readers to announce
                     the error again even when it's exactly the same */}
                    {error} <HiddenSpan>{submitFailCount}</HiddenSpan>
                  </p>
                )}
              </div>
            )}
          </CopyPartnershipsForm>
        ) : (
          <p>
            <strong>No other partnerships found.</strong>
          </p>
        )}
      </div>
    </Modal>
  )
}

CopyPartnershipModal.propTypes = propTypes
CopyPartnershipModal.defaultProps = defaultProps

export default CopyPartnershipModal
