import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  serializeOptions,
  fieldOptionsType,
  omitLabelProps,
  LabeledField,
  Checkbox,
  replaceEmptyStringValue,
} from 'lp-components'
import { addToArray, removeFromArray } from 'lp-components/lib/utils'
import { size } from 'lodash'

const propTypes = {
  minimumThreshold: PropTypes.number,
  options: fieldOptionsType,
}

const defaultProps = {
  minimumThreshold: 5,
  options: [],
}

function SelectAllCheckboxGroup(props) {
  const {
    input: { value: valueArray, onChange, name },
    meta, // eslint-disable-line no-unused-vars
    options,
    minimumThreshold,
    ...rest
  } = omitLabelProps(props)

  const shouldSelectAll = valueArray.length === options.length
  const [selectAllChecked, setSelectAllChecked] = useState(shouldSelectAll)

  const optionObjects = serializeOptions(options)

  // De-select select all if a checkbox is unchecked (via the component or remotely)
  useEffect(() => {
    if (selectAllChecked && !shouldSelectAll) setSelectAllChecked(false)
    if (!selectAllChecked && shouldSelectAll) setSelectAllChecked(true)
  }, [options, valueArray])

  const handleChange = function (option) {
    return function (checked) {
      const newValueArray = checked
        ? addToArray([option.value], valueArray)
        : removeFromArray([option.value], valueArray)
      return onChange(newValueArray)
    }
  }

  const handleSelectAll = useCallback(() => {
    const selectAll = !selectAllChecked

    if (selectAll) {
      const allValues = optionObjects.map(({ value }) => value)
      onChange(allValues)
    } else {
      onChange([])
    }
  }, [optionObjects, selectAllChecked])

  return (
    <LabeledField className="CheckboxGroup" {...props}>
      {size(optionObjects) >= minimumThreshold && (
        <Checkbox
          input={{
            name: `${name}.selectAll`,
            value: selectAllChecked,
            onChange: handleSelectAll,
          }}
          meta={{}}
        />
      )}
      {optionObjects.map((option) => {
        return (
          <Checkbox
            key={option.value}
            {...{
              input: {
                name: `${name}.${option.value}`,
                value: valueArray.includes(option.value),
                onChange: handleChange(option),
              },
              meta: {},
              label: option.key,
              ...rest,
            }}
          />
        )
      })}
    </LabeledField>
  )
}

SelectAllCheckboxGroup.propTypes = propTypes
SelectAllCheckboxGroup.defaultProps = defaultProps

export default replaceEmptyStringValue([])(SelectAllCheckboxGroup)
