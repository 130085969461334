import * as LS from 'local-storage'

// Auth helpers for partner portal

export function isAuthenticated() {
  return !!LS.getPartnerAuthToken()
}

export function isAdmin() {
  return !!LS.getIsAdmin()
}

export function isAuthenticatedForQualityFramework() {
  if (!process.env.RESTRICT_QUALITY_FRAMEWORK) return true
  const partnerId = LS.getCurrentPartnerId()
  const qualityFrameworkIds = process.env.QUALITY_FRAMEWORK_IDS || ''
  return qualityFrameworkIds.split(' ').includes(partnerId)
}
