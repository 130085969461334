import React from 'react'

function DataIcon() {
  return (
    <svg width="84px" height="84px" viewBox="0 0 84 84" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-247.000000, -570.000000)">
          <g transform="translate(247.000000, 570.000000)">
            <circle fill="#1F4B80" cx="42" cy="42" r="42"></circle>
            <g
              transform="translate(21.666667, 25.466667)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <path d="M40.6875,26.6666667 L5.25,26.6666667 L5.25,1.33333333 C5.25,0.596666667 4.66265625,0 3.9375,0 L1.3125,0 C0.58734375,0 0,0.596666667 0,1.33333333 L0,29.3333333 C0,30.8058333 1.17550781,32 2.625,32 L40.6875,32 C41.4126562,32 42,31.4033333 42,30.6666667 L42,28 C42,27.2633333 41.4126562,26.6666667 40.6875,26.6666667 Z M38.0625,2.66666667 L28.3778906,2.66666667 C26.6240625,2.66666667 25.7455078,4.82083333 26.9858203,6.08083333 L29.6436328,8.78083333 L23.625,14.8958333 L17.6063672,8.78166667 C16.5809766,7.74 14.9190234,7.74 13.8944531,8.78166667 L8.25972656,14.5058333 C7.74703125,15.0266667 7.74703125,15.8708333 8.25972656,16.3916667 L10.1152734,18.2766667 C10.6279687,18.7975 11.4589453,18.7975 11.9716406,18.2766667 L15.75,14.4375 L21.7686328,20.5516667 C22.7940234,21.5933333 24.4559766,21.5933333 25.4805469,20.5516667 L33.3555469,12.5516667 L36.0133594,15.2516667 C37.2536719,16.5116667 39.3741805,15.6191667 39.3741805,13.8375 L39.3741805,4 C39.375,3.26333333 38.7876562,2.66666667 38.0625,2.66666667 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default DataIcon
