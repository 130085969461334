import React from 'react'
// import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { StepNav } from 'components'

const propTypes = {}

const defaultProps = {}

function SurveyStepNav(props) {
  return (
    <StepNav
      steps={[
        { name: 'Staffing & Instruction', path: '/staffing-and-instruction' },
        {
          name: 'Community & Partnerships',
          path: '/community-and-partnerships',
        },
        { name: 'Budget & Planning', path: '/budget-and-planning' },
        { name: 'Review & Submit', path: '/review-and-submit' },
      ]}
      {...props}
    />
  )
}

SurveyStepNav.propTypes = propTypes
SurveyStepNav.defaultProps = defaultProps

export default pure(SurveyStepNav)
