import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'utils'
import * as Types from 'types'
import classnames from 'classnames'

const propTypes = {
  value: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    programTypes: PropTypes.arrayOf(Types.programType).isRequired,
    state: PropTypes.string.isRequired,
  }),
  onDelete: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  type: PropTypes.oneOf([Types.PARTNER_TYPE, Types.SCHOOL_TYPE]).isRequired,
}

const defaultProps = {
  onDelete: noop,
}

function SchoolPartnershipNameCell({
  value,
  data: { id, programTypes, state },
  disabled,
  onDelete,
  // type,
}) {
  const isPending = state === 'pending'
  // disable cell only in school portal
  // const isPartnerSource = (type === Types.PARTNER_TYPE) && (source === Types.PARTNER_SOURCE_TYPE)
  // const disableCell = disabled || isPartnerSource
  return (
    <td className={classnames({ 'disabled-cell': disabled })}>
      {!disabled && (
        <a onClick={() => onDelete(id, isPending)} className="remove-row">
          ×
        </a>
      )}
      <span>{`${value} ${isPending ? '(Pending)' : ''}`}</span>
      {programTypes.length === 0 && (
        <div>
          <br />
          <span className="error-message">Please check at least one type</span>
        </div>
      )}
    </td>
  )
}

SchoolPartnershipNameCell.propTypes = propTypes

SchoolPartnershipNameCell.defaultProps = defaultProps

export default SchoolPartnershipNameCell
