import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { onMount, waitFor, modifyProps } from 'lp-hoc'
import { Link } from 'react-router'
import { SectionBlock, LoadingSpinner, InfoModal } from 'components'
import { BudgetForm, FundingForm, GovernancesForm } from '../forms'
import {
  displaySubmitFailure,
  replaceResources,
  useCommunity,
  useDynamicInfoModal,
  getOtherTypeId,
  isOtherType,
  yankBy,
  combineOtherOption,
  filterDestroyed,
  filterQuestionsByPageAndDisplayComponent,
  findQuestionByPageAndDisplayComponent,
  showQuestion,
} from 'utils'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors as globalSchoolSelectors } from 'school-portal-reducer'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import * as globalActions from 'school-portal-actions'
import * as Types from 'types'
import { SCHOOL_PORTAL_ROUTE } from 'config'
import {
  SurveyStepNav,
  CommunityEnumerableQuestion,
  CommunityQuestion,
  CustomQuestions,
  ScholarshipsQuestion,
  DynamicQuestion,
} from '../components'
import * as flashActions from 'redux-flash'
import back from 'images/back.svg'
import { isEmpty } from 'lodash'

const { GOVERNANCE, DISTRICT_SPENDING } = Types.SURVEY_QUESTIONS

const propTypes = {
  school: Types.school.isRequired,
  setSchool: PropTypes.func.isRequired,
  updateSchool: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  fundingTypes: PropTypes.arrayOf(Types.communityEnumerable).isRequired,
  governanceTypes: PropTypes.arrayOf(Types.communityEnumerable).isRequired,
  surveySchoolYear: Types.schoolYear.isRequired,
  surveyQuestions: PropTypes.arrayOf(Types.surveyQuestion).isRequired,
  definedDisciplines: PropTypes.arrayOf(Types.discipline).isRequired,
}

const defaultProps = {}

function BudgetAndPlanning({
  school,
  updateSchool,
  onSuccess,
  fundingTypes,
  governanceTypes,
  surveySchoolYear,
  surveyQuestions,
  definedDisciplines,
}) {

  const community = useCommunity()
  const surveySchoolYearNumber = surveySchoolYear.number

  const [showInfoModal, infoModalContent, setShowInfoModalWithContent] =
    useDynamicInfoModal()

  const scholarshipsQuestion = findQuestionByPageAndDisplayComponent(
    surveyQuestions,
    Types.SURVEY_PAGES.BUDGET_AND_PLANNING,
    Types.QUESTION_DISPLAY_COMPONENTS.SCHOLARSHIPS_FORM
  )

  const fundingSourcesQuestion = findQuestionByPageAndDisplayComponent(
    surveyQuestions,
    Types.SURVEY_PAGES.BUDGET_AND_PLANNING,
    Types.QUESTION_DISPLAY_COMPONENTS.FUNDING_FORM
  )

  const customQuestions = filterQuestionsByPageAndDisplayComponent(
    surveyQuestions,
    Types.SURVEY_PAGES.BUDGET_AND_PLANNING,
    Types.QUESTION_DISPLAY_COMPONENTS.CUSTOM_QUESTION_FORM
  )

  return (
    <div>
      <SurveyStepNav currentStepIndex={2} />
      <div className="content-block-container">
        {showQuestion(community, GOVERNANCE.NAME, governanceTypes) && (
          <React.Fragment>
            <SectionBlock>
              <CommunityEnumerableQuestion
                questionName={GOVERNANCE.NAME}
                enumerableOptions={governanceTypes}
                setShowInfoModalWithContent={setShowInfoModalWithContent}
                surveySchoolYearNumber={surveySchoolYearNumber}
              >
                {(optionName) => {
                  if (optionName === GOVERNANCE.OPTIONS.GENERAL) {
                    const [otherGovernance, definedGovernances] = yankBy(
                      school.governances,
                      isOtherType
                    )
                    return (
                      <GovernancesForm
                        governanceTypes={governanceTypes}
                        initialValues={{
                          other: {
                            ...otherGovernance,
                            governanceId: getOtherTypeId(governanceTypes),
                          },
                          governances: definedGovernances,
                        }}
                        beforeSubmit={({ other, governances }) => {
                          const resources = replaceResources({
                            old: school.governances,
                            new: combineOtherOption(governances, other),
                          })
                          if (isEmpty(filterDestroyed(resources)))
                            throw new SubmissionError({
                              _error: 'You must select at least one option',
                            })
                          return {
                            governances: resources,
                          }
                        }}
                        onSubmit={updateSchool}
                        onSubmitSuccess={onSuccess}
                        onSubmitFail={displaySubmitFailure}
                      />
                    )
                  }
                }}
              </CommunityEnumerableQuestion>
            </SectionBlock>
          </React.Fragment>
        )}
        {showQuestion(community, DISTRICT_SPENDING.NAME, null) && (
          <React.Fragment>
            <SectionBlock>
              <CommunityQuestion
                questionName={DISTRICT_SPENDING.NAME}
                surveySchoolYearNumber={surveySchoolYearNumber}
              >
                {({ ariaLabelledby }) => (
                  <BudgetForm
                    initialValues={{ detail: school.surveyDetail }}
                    onSubmit={updateSchool}
                    onSubmitSuccess={onSuccess}
                    onSubmitFail={displaySubmitFailure}
                    ariaLabelledby={ariaLabelledby}
                    threshold={school.threshold}
                  />
                )}
              </CommunityQuestion>
            </SectionBlock>
          </React.Fragment>
        )}
        {fundingSourcesQuestion && (
          <DynamicQuestion
            surveyQuestion={fundingSourcesQuestion}
            enumerableOptions={fundingTypes}
            setShowInfoModalWithContent={setShowInfoModalWithContent}
          >
            {(enumerableOptions, { ariaLabelledby }) => {
              return (
                <FundingForm
                  ariaLabelledby={ariaLabelledby}
                  fundingTypes={enumerableOptions}
                  initialValues={{ fundings: school.fundings }}
                  onSubmit={updateSchool}
                  onSubmitSuccess={onSuccess}
                  onSubmitFail={displaySubmitFailure}
                />
              )
            }}
          </DynamicQuestion>
        )}
        {showInfoModal && (
          <InfoModal
            onClose={() => setShowInfoModalWithContent(false)}
            content={infoModalContent.content}
            header={infoModalContent.header}
          />
        )}
        {scholarshipsQuestion && (
          <ScholarshipsQuestion
            question={scholarshipsQuestion}
            disciplines={definedDisciplines}
            scholarships={school.scholarships}
            onSubmit={updateSchool}
            onSubmitSuccess={onSuccess}
            onSubmitFail={displaySubmitFailure}
          />
        )}
        {!isEmpty(customQuestions) && (
          <CustomQuestions
            surveyQuestions={customQuestions}
            onSubmit={updateSchool}
            onSubmitSuccess={onSuccess}
            onSubmitFail={displaySubmitFailure}
            setShowInfoModalWithContent={setShowInfoModalWithContent}
            surveyAnswers={school.surveyAnswers}
          />
        )}
      </div>
      <div className="sticky-buttons">
        <Link
          className="link-black"
          to={SCHOOL_PORTAL_ROUTE + '/survey/community-and-partnerships'}
        >
          <img src={back} alt="" /> Back
        </Link>
        <div className="continue">
          <Link
            className="button-secondary"
            to={SCHOOL_PORTAL_ROUTE + '/survey/review-and-submit'}
          >
            Continue to Next Page
          </Link>
        </div>
      </div>
    </div>
  )
}

BudgetAndPlanning.propTypes = propTypes
BudgetAndPlanning.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    fundingTypes: selectors.fundingTypes(state),
    governanceTypes: selectors.governanceTypes(state),
    school: globalSchoolSelectors.school(state),
    surveySchoolYear: globalSchoolSelectors.surveySchoolYear(state),
    surveyQuestions: selectors.surveyQuestions(state),
    definedDisciplines: globalSelectors.definedDisciplines(state),
  }
}

const mapDispatchToProps = {
  fetchFundingTypes: apiActions.fetchFundingTypes,
  updateSchool: apiActions.updateSchool,
  setSchool: globalActions.setSchool,
  flashSuccessMessage: flashActions.flashSuccessMessage,
}

function modify({
  school,
  setSchool,
  flashSuccessMessage,
  surveySchoolYear,
  updateSchool,
}) {
  return {
    updateSchool: (params) =>
      updateSchool(school.id, surveySchoolYear.id, params),
    onSuccess: (school) => {
      setSchool(school)
      flashSuccessMessage('Responses saved!')
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  onMount('fetchFundingTypes'),
  modifyProps(modify),
  waitFor(['school', 'fundingTypes'], LoadingSpinner)
)(BudgetAndPlanning)
