import { push } from 'react-router-redux'
import * as globalActions from 'school-portal-actions'
import { SCHOOL_PORTAL_ROUTE } from 'config'

export function signOut() {
  return function (dispatch) {
    dispatch(clearDetails())
    dispatch(push(SCHOOL_PORTAL_ROUTE))
  }
}

export function clearDetails() {
  return function (dispatch) {
    dispatch(globalActions.setAuthenticated({ token: null }))
    dispatch(globalActions.setIsAdmin(false))
    dispatch(globalActions.setCurrentSchoolId(null))
    dispatch(globalActions.clearSchool())
  }
}
