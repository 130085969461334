import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withProps } from 'recompose'
import { lpForm } from 'lp-form'
import {
  Field,
  FormSection,
  formValueSelector,
  propTypes as formPropTypes,
} from 'redux-form'
import {
  CheckboxGroup,
  Input,
  InputLabel,
  InputError,
  SubmitButton,
  Textarea,
} from 'lp-components'
import { RequestNameChangeLink } from '../components'
import {
  ProfileMapDisplay,
  SectionBlock,
  SectionHeader,
  CloudinaryFileInput,
  RemovableThumbnail,
  HiddenLegend,
} from 'components'
import lock from 'images/lock.svg'
import {
  formatObjectsToIds,
  parseIdsToObjects,
  replaceResources,
  convertCloudinarySrcUrlToFilename,
  serializeOptions,
  isNoneType,
  getCloudinaryUploadErrorMessage,
  getNoneTypeId,
} from 'utils'
import { set, compact, isEmpty, negate, first } from 'lodash'
import { CLOUD_RESIZE_PRESET } from 'config'

const propTypes = {
  ...formPropTypes,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  leadershipCharacteristics: PropTypes.arrayOf(Types.communityEnumerable)
    .isRequired,
  partnerLocation: Types.location.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  logo: Types.image,
  partnerId: PropTypes.number.isRequired,
}

const defaultProps = {
  logo: null,
}

const NameLabel = (props) => (
  <InputLabel {...props}>
    Organization / Teaching Artist Name{' '}
    <img src={lock} alt="" className="lock" />
  </InputLabel>
)

const WebsiteLabel = (props) => (
  <InputLabel {...props}>
    Website
    <span id="website-instructions" className="input-instructions">
      (full URL e.g., https://www.ingenuity-inc.org/)
    </span>
  </InputLabel>
)

const LogoLabel = ({ image, ...props }) => (
  <InputLabel {...props}>
    Organization Logo / Photo
    <span className="input-instructions">
      (Accepted formats include png, jpg, and jpeg. Your file must be less than
      10 MB and will be cropped to a square when uploaded.)
    </span>
    {!image && (
      <div>
        <span className="badge">
          Personalize your artlook profile by uploading a logo or photo!
        </span>
      </div>
    )}
  </InputLabel>
)

function OrganizationForm({
  disciplines,
  leadershipCharacteristics,
  handleSubmit,
  partnerLocation,
  submitting,
  change,
  flashErrorMessage,
  flashSuccessMessage,
  logo,
  partnerId,
}) {
  const displayDisciplines = disciplines.filter(negate(isNoneType))
  const [uploadError, setUploadError] = useState()

  const uploadPath = `/organizations/logos/${partnerId}`

  const leadershipCharacteristicNoneTypeId = useMemo(
    () => getNoneTypeId(leadershipCharacteristics),
    [leadershipCharacteristics]
  )

  const filterValues = useCallback((values, noneTypeId) => {
    // Remove other values if None type was just selected
    if (first(values) === noneTypeId) {
      // This form currently does not have an other type. If one is created uncomment the code below
      // change('other.otherText', '')
      return [noneTypeId]
    }
    // Otherwsie remove None type
    return values.filter((val) => val !== noneTypeId)
  }, [])

  return (
    <form
      onSubmit={(values) => {
        setUploadError(null)
        handleSubmit(values)
      }}
    >
      <SectionBlock data-cy="profile-details">
        <SectionHeader>
          <h2 id="org-details">Organization Details</h2>
        </SectionHeader>
        <div className="row org-details">
          <fieldset className="outer" aria-labelledby="org-details">
            <div className="col-6">
              <Field
                name="name"
                component={Input}
                labelComponent={NameLabel}
                disabled
              >
                <span className="input-link">
                  <RequestNameChangeLink />
                </span>
              </Field>
              <FormSection name="detail">
                <Field
                  name="website"
                  component={Input}
                  labelComponent={WebsiteLabel}
                  aria-describedby="website-instructions"
                />
                <Field name="phone" component={Input} data-cy="phone-input" />
              </FormSection>
            </div>
            <div className="col-6">
              <FormSection name="logo">
                <Field
                  name="src"
                  accept=".png,.jpg,.jpeg"
                  labelComponent={LogoLabel}
                  image={logo}
                  component={CloudinaryFileInput}
                  uploadPath={uploadPath}
                  uploadPreset={CLOUD_RESIZE_PRESET}
                  onUploadSuccess={({ secureUrl }) => {
                    setUploadError(null)
                    change('logo.src', secureUrl)
                    const newFilename =
                      convertCloudinarySrcUrlToFilename(secureUrl)
                    change('logo.filename', newFilename)
                    // make sure destroy is not true
                    change('logo._destroy', null)
                    flashSuccessMessage(
                      'Organization logo successfully uploaded. Make sure to save your changes.'
                    )
                  }}
                  onUploadFailure={(err) => {
                    const message = getCloudinaryUploadErrorMessage(err)
                    setUploadError(message)
                    flashErrorMessage(message)
                  }}
                  previewComponent={RemovableThumbnail}
                  remove={() => {
                    setUploadError(null)
                    change('logo._destroy', true)
                    flashSuccessMessage(
                      'Existing organization logo successfully removed. Make sure to save your changes.'
                    )
                  }}
                  aria-describedby={uploadError ? 'upload-error' : undefined}
                />
                {uploadError && (
                  <InputError
                    touched
                    invalid
                    error={uploadError}
                    id="upload-error"
                  />
                )}
              </FormSection>
            </div>
          </fieldset>
        </div>
      </SectionBlock>
      <SectionBlock>
        <SectionHeader>
          <h2 id="about-details">About</h2>
        </SectionHeader>
        <fieldset className="outer" aria-labelledby="about-details">
          <FormSection name="detail">
            <Field
              name="description"
              label="About your organization or teaching artist practice:"
              component={Textarea}
            />
          </FormSection>
        </fieldset>
      </SectionBlock>
      <SectionBlock>
        <SectionHeader>
          <h2 id="location-details">Location</h2>
          <div className="location-block">
            <div className="address-block">
              <fieldset
                className="address-form-block outer"
                aria-labelledby="location-details"
              >
                <FormSection name="address">
                  <Field name="street" component={Input} />
                  <div className="row">
                    <Field className="col-5" name="city" component={Input} />
                    <Field className="col-3" name="state" component={Input} />
                    <Field className="col-4" name="zip" component={Input} />
                  </div>
                </FormSection>
              </fieldset>
            </div>
            <div className="map-block">
              <ProfileMapDisplay
                location={partnerLocation}
                zoom={13}
                hidePopup
              />
            </div>
          </div>
        </SectionHeader>
      </SectionBlock>
      <SectionBlock>
        <h2 id="discipline-details">
          Which arts disciplines are addressed by your school programs?
        </h2>
        <p id="discipline-instructions">Please check all that apply</p>
        <div className="response-option-blocks">
          <div className="response-option-block">
            <Field
              aria-labelledby="discipline-details"
              aria-describedby="discipline-instructions"
              name="disciplines"
              labelComponent={HiddenLegend}
              component={CheckboxGroup}
              format={formatObjectsToIds('disciplineId')}
              parse={parseIdsToObjects('disciplineId')}
              options={serializeOptions(displayDisciplines)}
            />
          </div>
        </div>
      </SectionBlock>
      {!isEmpty(leadershipCharacteristics) && (
        <SectionBlock>
          <h2 id="representation-details">
            Which of the following characteristics apply to you or your
            organization?
          </h2>
          <p id="representation-instructions">
            Please select all that apply, if any. Do not select an option if it
            doesn't apply to you. (BIPOC stands for "Black, Indigenous, and/or
            People of Color".)
          </p>
          <Field
            aria-labelledby="representation-details"
            aria-describedby="representation-instructions"
            name="leadershipCharacteristics"
            labelComponent={HiddenLegend}
            component={CheckboxGroup}
            format={formatObjectsToIds('leadershipCharacteristicId')}
            parse={(values) =>
              parseIdsToObjects(
                'leadershipCharacteristicId',
                filterValues(values, leadershipCharacteristicNoneTypeId)
              )
            }
            options={serializeOptions(leadershipCharacteristics)}
          />
        </SectionBlock>
      )}
      <SubmitButton submitting={submitting}>Save</SubmitButton>
    </form>
  )
}

OrganizationForm.propTypes = propTypes
OrganizationForm.defaultProps = defaultProps

const select = formValueSelector('partner-organization')

function mapStateToProps(state) {
  return {
    logo: select(state, 'logo'),
  }
}

function addTag(resources, tag) {
  return resources.map((resource) => ({ ...resource, tag }))
}

function modifyBeforeSubmit({ initialValues }) {
  return {
    beforeSubmit: ({
      disciplines,
      leadershipCharacteristics,
      logo,
      ...rest
    }) => {
      const updatedDisciplines = replaceResources({
        old: initialValues.disciplines,
        new: disciplines,
      })
      const updatedLeadershipCharacteristics = replaceResources({
        old: initialValues.leadershipCharacteristics,
        // 'bipoc' tag is added to allow future compatibility in which we capture
        // similar response options for other identity groups (e.g., disability, LGBTQ+)
        new: addTag(
          leadershipCharacteristics,
          Types.LEADERSHIP_CHARACTERISTIC_TAGS.BIPOC
        ),
      })
      const logos = compact([set(logo, 'category', 'standard')])
      return {
        ...rest,
        disciplines: updatedDisciplines,
        leadershipCharacteristics: updatedLeadershipCharacteristics,
        logos,
      }
    },
  }
}

export default compose(
  connect(mapStateToProps),
  withProps(modifyBeforeSubmit),
  lpForm({
    name: 'partner-organization',
    constraints: {
      name: { presence: true },
      'detail.website': {
        presence: true,
        format: {
          pattern: /^http(s?):\/\/.*/, // must start with http:// or https:// and can be followed by anything
          flags: 'i',
          message: '^Please provide the full url',
        },
      },
      'detail.phone': {
        presence: true,
        format: {
          pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          message: 'must be a valid phone number',
        },
      },
      'detail.description': { presence: true, length: { maximum: 1000 } },
      'address.street': { presence: true },
      'address.city': { presence: true },
      'address.state': { presence: true },
      'address.zip': { presence: true },
    },
    submitFilters: {
      allow: [
        'id',
        'detail',
        'address',
        'disciplines',
        'logos',
        'leadershipCharacteristics',
      ],
    },
    enableReinitialize: true,
  })
)(OrganizationForm)
