import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import ProfileAvatar from './profile-avatar'

const propTypes = {
  userDisplayName: PropTypes.string.isRequired,
  schoolNames: PropTypes.string.isRequired,
  profileImgUrl: PropTypes.string,
  onProfileClick: PropTypes.func,
  nameComponent: PropTypes.func,
  pronouns: PropTypes.string,
}

const defaultProps = {
  profileImgUrl: null,
  onProfileClick: null,
  nameComponent: Name,
  pronouns: null,
}

function Name({ name, pronouns }) {
  return (
    <p>
      <strong>{name}</strong>
      {pronouns && (
        <span>
          <em> ({pronouns})</em>
        </span>
      )}
    </p>
  )
}

function ButtonWrapper({ onProfileClick, children }) {
  return (
    <button
      type="button"
      onClick={onProfileClick}
      className="link-primary flex-horizontal profile-details-button"
    >
      {children}
    </button>
  )
}

function DefaultWrapper({ children }) {
  return <div className="flex-horizontal">{children}</div>
}

function ProfileOverview({
  userDisplayName,
  schoolNames,
  profileImgUrl,
  onProfileClick,
  nameComponent: NameComponent,
  pronouns,
}) {
  const Wrapper = onProfileClick ? ButtonWrapper : DefaultWrapper
  return (
    <Wrapper onProfileClick={onProfileClick}>
      <ProfileAvatar
        imageUrl={profileImgUrl}
        userDisplayName={userDisplayName}
      />
      <span className="profile-header-details">
        <NameComponent name={userDisplayName} pronouns={pronouns} />
        <p>{schoolNames}</p>
      </span>
    </Wrapper>
  )
}

ProfileOverview.propTypes = propTypes
ProfileOverview.defaultProps = defaultProps

export default pure(ProfileOverview)
