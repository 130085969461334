import Raven from 'raven-js'

const nodeEnv = process.env.NODE_ENV
const sentryUrl = process.env.SENTRY_URL

/* eslint-disable no-console */

function initializeSentry() {
  if (Raven.isSetup()) return
  if (!sentryUrl)
    return console.warn(
      'WARNING: Sentry not configured. "SENTRY_URL" environment variable must be defined.'
    )
  return Raven.config(sentryUrl, {
    environment: nodeEnv,
    shouldSendCallback: () => nodeEnv === 'production', // modify this line with any app-specific logic if necessary
  }).install()
}

initializeSentry()

export function logException(err, context) {
  if (!Raven.isSetup()) return
  return Raven.captureException(err, { extra: context })
}
