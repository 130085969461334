import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import {
  ObstaclesTableForm,
  ObstaclesDescriptionForm,
  ObstaclesOvercomeForm,
} from '../forms'
import { useCommunitySurveyQuestionOption, replaceResources } from 'utils'
import { filter, isEmpty, find, get } from 'lodash'
import { SectionBlock } from 'components'

const propTypes = {
  tableObstacleTypes: PropTypes.arrayOf(Types.obstacle),
  describeObstacleType: Types.obstacle,
  overcomeObstacleType: Types.obstacle,
  fetchObstacleTypes: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    obstacles: PropTypes.arrayOf(Types.schoolObstacle),
  }),
  disciplines: PropTypes.arrayOf(Types.discipline),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitFail: PropTypes.func.isRequired,
  surveySchoolYearNumber: PropTypes.string.isRequired,
}

const defaultProps = {}

const { OBSTACLES_SELECT, OBSTACLES_DESCRIBE, OBSTACLES_OVERCOME } =
  Types.SURVEY_QUESTIONS
const QUESTION_NAMES = [
  OBSTACLES_SELECT.NAME,
  OBSTACLES_DESCRIBE.NAME,
  OBSTACLES_OVERCOME.NAME,
]

const [ADDITIONAL_INFORMATION, WHAT_WORKED_WELL] =
  Types.FREE_TEXT_QUESTION_OBSTACLES

function serializeTextReponse(response, obstacleId) {
  return [
    {
      obstacleId,
      otherText: response,
    },
  ]
}

function filterUnselectedObstacles(disciplinesSelectedByObstacle) {
  return filter(
    disciplinesSelectedByObstacle,
    ({ secondaryResource: { disciplines } }) => !isEmpty(disciplines)
  )
}

function groupSelectedDisciplineIds(obstacles) {
  return obstacles.map(({ secondaryResourceable }) => ({
    disciplineId: secondaryResourceable.id,
  }))
}

function groupSelectedDisciplinesByObstacle(obstacles, tableObstacleTypes) {
  if (!tableObstacleTypes) return
  return tableObstacleTypes.map((obstacleType) => {
    const matchingObstacles = filter(obstacles, {
      name: obstacleType.name,
    })
    const { id, displayName, name } = obstacleType
    return {
      displayName,
      name,
      obstacleId: id,
      secondaryResource: {
        disciplines: groupSelectedDisciplineIds(matchingObstacles),
      },
    }
  })
}

function ObstaclesQuestions({
  tableObstacleTypes,
  describeObstacleType,
  overcomeObstacleType,
  fetchObstacleTypes,
  initialValues: { obstacles },
  disciplines,
  onSubmit,
  onSubmitSuccess,
  onSubmitFail,
  surveySchoolYearNumber,
}) {
  useEffect(() => {
    fetchObstacleTypes()
  }, [])
  const [selectQuestionOption, describeQuestionOption, overcomeQuestionOption] =
    QUESTION_NAMES.map(useCommunitySurveyQuestionOption)
  const tableObstacles = useMemo(() => {
    return filter(
      obstacles,
      ({ name }) => !Types.FREE_TEXT_QUESTION_OBSTACLES.includes(name)
    )
  }, [obstacles])
  const getObstacleForQuestion = useCallback(
    (questionName) =>
      find(obstacles, {
        name: questionName,
      }),
    [obstacles]
  )
  const getOtherText = useCallback(
    (questionName) => {
      const matchingObstacle = getObstacleForQuestion(questionName)
      return get(matchingObstacle, 'otherText', '')
    },
    [obstacles]
  )
  const disciplinesSelectedByObstacle = useMemo(
    () =>
      groupSelectedDisciplinesByObstacle(
        obstacles,
        tableObstacleTypes,
        disciplines
      ),
    [obstacles, tableObstacleTypes, disciplines]
  )

  // Since obstacle types can be deactivated in the community admin panels,
  // the obstacle type(s) for each question need also be defined,
  // otherwise obstacles for that question cannot be saved.
  return (
    <React.Fragment>
      {selectQuestionOption && disciplinesSelectedByObstacle && (
        <SectionBlock>
          <h2>
            {selectQuestionOption.getQuestionText({ surveySchoolYearNumber })}
          </h2>
          <ObstaclesTableForm
            disciplines={disciplines}
            initialValues={{ disciplinesSelectedByObstacle }}
            beforeSubmit={({ disciplinesSelectedByObstacle }) => {
              return {
                obstacles: replaceResources({
                  old: tableObstacles,
                  new: filterUnselectedObstacles(disciplinesSelectedByObstacle),
                }),
              }
            }}
            onSubmit={onSubmit}
            onSubmitSuccess={onSubmitSuccess}
            onSubmitFail={onSubmitFail}
          />
        </SectionBlock>
      )}
      {describeQuestionOption && describeObstacleType && (
        <SectionBlock>
          <h2 id="describe-question">
            {describeQuestionOption.getQuestionText({
              surveySchoolYearNumber,
            })}
          </h2>
          <ObstaclesDescriptionForm
            initialValues={{
              description: getOtherText(ADDITIONAL_INFORMATION),
            }}
            beforeSubmit={({ description }) => ({
              obstacles: replaceResources({
                old: [getObstacleForQuestion(ADDITIONAL_INFORMATION)],
                new: serializeTextReponse(description, describeObstacleType.id),
              }),
            })}
            onSubmit={onSubmit}
            onSubmitSuccess={onSubmitSuccess}
            onSubmitFail={onSubmitFail}
          />
        </SectionBlock>
      )}
      {overcomeQuestionOption && overcomeObstacleType && (
        <SectionBlock>
          <h2 id="overcome-question">
            {overcomeQuestionOption.getQuestionText({
              surveySchoolYearNumber,
            })}
          </h2>
          <ObstaclesOvercomeForm
            initialValues={{
              overcome: getOtherText(WHAT_WORKED_WELL),
            }}
            beforeSubmit={({ overcome }) => ({
              obstacles: replaceResources({
                old: [getObstacleForQuestion(WHAT_WORKED_WELL)],
                new: serializeTextReponse(overcome, overcomeObstacleType.id),
              }),
            })}
            onSubmit={onSubmit}
            onSubmitSuccess={onSubmitSuccess}
            onSubmitFail={onSubmitFail}
          />
        </SectionBlock>
      )}
    </React.Fragment>
  )
}

ObstaclesQuestions.propTypes = exact(propTypes)
ObstaclesQuestions.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    tableObstacleTypes: selectors.tableObstacleTypes(state),
    describeObstacleType: selectors.describeObstacleType(state),
    overcomeObstacleType: selectors.overcomeObstacleType(state),
  }
}

const mapDispatchToProps = {
  fetchObstacleTypes: apiActions.fetchObstacleTypes,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ObstaclesQuestions
)
