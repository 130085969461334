import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { connect } from 'react-redux'
import {
  Field,
  propTypes as formPropTypes,
  formValueSelector,
} from 'redux-form'
import { SubmitButton, InputError, ButtonArea } from 'lp-components'
import { CheckboxWithText, CheckboxGroup, CheckboxFieldset } from 'components'
import * as Types from 'types'
import { flow, first } from 'lodash'
import {
  formatObjectsToIds,
  parseIdsToObjects,
  persistSubmitSucceeded,
  getNoneTypeId,
  isNoneType,
  orderLastBy,
  serializeOptions,
  yankBy,
  isOtherType,
} from 'utils'

const propTypes = {
  ...formPropTypes,
  spaceTypes: PropTypes.arrayOf(Types.spaceType).isRequired,
}

const defaultProps = {}

const FORM_NAME = 'school-arts-space'

function ArtsSpaceForm({
  handleSubmit,
  spaceTypes,
  saved,
  submitting,
  change,
  spaces,
  error,
  submitFailed,
}) {
  const [otherSpaceType, definedSpaceTypes] = useMemo(
    () => yankBy(spaceTypes, isOtherType),
    [spaceTypes]
  )
  const noneTypeId = useMemo(() => getNoneTypeId(spaceTypes), [spaceTypes])
  const options = useMemo(
    () => flow([orderLastBy(isNoneType), serializeOptions])(definedSpaceTypes),
    [definedSpaceTypes]
  )

  const filterValues = useCallback(
    (values) => {
      // Remove other values if None type was just selected
      if (first(values) === noneTypeId) {
        change('other.otherText', '')
        return [noneTypeId]
      }
      // Otherwise remove None type
      return values.filter((val) => val !== noneTypeId)
    },
    [noneTypeId, change]
  )
  const deselectNone = useCallback(() => {
    const rest = spaces.filter(({ spaceTypeId }) => spaceTypeId !== noneTypeId)
    change('spaces', rest)
  }, [change, noneTypeId, spaces])

  return (
    <form onSubmit={handleSubmit}>
      <CheckboxFieldset invalid={!!error && submitFailed}>
        <div className="floating-checkboxes">
          <Field
            name="spaces"
            label={false}
            component={CheckboxGroup}
            format={formatObjectsToIds('spaceTypeId')}
            parse={(values) =>
              parseIdsToObjects('spaceTypeId', filterValues(values))
            }
            options={options}
          />
        </div>
        {otherSpaceType && (
          <Field
            name="other.otherText"
            label={otherSpaceType.displayName}
            placeholder="Description..."
            component={CheckboxWithText}
            className="CheckboxGroup full-width-other"
            onChange={deselectNone}
          />
        )}
        {error && submitFailed && <InputError error={error} touched invalid />}
      </CheckboxFieldset>
      <ButtonArea>
        <SubmitButton {...{ pristine: saved, submitting }}>
          Save Response
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

ArtsSpaceForm.propTypes = propTypes
ArtsSpaceForm.defaultProps = defaultProps

const selectFormValue = formValueSelector(FORM_NAME)

function mapStateToProps(state) {
  return {
    spaces: selectFormValue(state, 'spaces'),
  }
}

export default compose(
  connect(mapStateToProps),
  lpForm({
    name: FORM_NAME,
    enableReinitialize: true,
    constraints: {
      'other.otherText': {
        exclusion: {
          within: [' '],
          message: '^Description is required',
        },
      },
    },
  }),
  persistSubmitSucceeded()
)(ArtsSpaceForm)
