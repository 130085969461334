import { handleActions } from 'redux-actions'
import { isAdmin, isAuthenticated } from './auth'
import { selectorForSlice, setState } from 'lp-redux-utils'
import { createSelector } from 'reselect'
import { setOnSuccess } from 'lp-redux-api'
import * as LS from 'local-storage'
import { set, serializeLocation } from 'utils'
import * as apiActions from 'api-actions'
import * as actions from 'school-portal-actions'
import * as Types from 'types'
import { find, first, get, orderBy, toNumber } from 'lodash'
import { compose } from 'recompose'

const reducerKey = 'global'
const slice = 'root.schoolPortal.global'

const initialState = {
  currentSchoolId: Number(LS.getCurrentSchoolId()),
  isAdmin: isAdmin(),
  isAuthenticated: isAuthenticated(),
}

const reducer = handleActions(
  {
    [actions.clearSchool]: () => initialState,
    [actions.setCurrentSchoolId]: (state, { payload: id }) => {
      LS.clearCurrentSchoolId()
      if (!id) return set('currentSchoolId', null, state)
      LS.setCurrentSchoolId(id)
      return set('currentSchoolId', toNumber(id), state)
    },
    [actions.setAuthenticated]: (
      state,
      { payload: { token, persistSession } }
    ) => {
      LS.clearSchoolAuthToken()
      if (!token) return set('isAuthenticated', false, state)
      LS.setSchoolAuthToken(token, persistSession)
      return set('isAuthenticated', true, state)
    },
    [actions.setIsAdmin]: (state, { payload: isAdmin }) => {
      LS.clearIsAdmin()
      if (!isAdmin) return set('isAdmin', false, state)
      LS.setIsAdmin(true)
      return set('isAdmin', true, state)
    },
    [actions.setSchool]: setState('school'),
    [actions.setUserDetails]: setState('userDetails'),
    [apiActions.fetchNotifications]: setOnSuccess('notifications'),
    [apiActions.fetchPartnerOptions]: setOnSuccess('partnerOptions'),
    [apiActions.fetchSchoolAuthorized]: setOnSuccess('school'),
    [apiActions.fetchSchoolOptions]: (state, { payload: { data } }) => {
      if (!data) return state
      const { activeSchools, sso } = data
      return compose(
        set('schoolOptions', activeSchools),
        set('communityConfigSso', sso)
      )(state)
    },
    [apiActions.fetchSurveyAttempt]: setOnSuccess('surveyAttempt'),
    [apiActions.completeSurveyAttempt]: setOnSuccess('surveyAttempt'),
    [apiActions.updateSchool]: setOnSuccess('school'),
    [apiActions.fetchActiveSchoolYears]: setOnSuccess('activeSchoolYears'),
    [apiActions.fetchPositions]: setOnSuccess('positions'),
    [apiActions.fetchCurrentSchoolUserDetails]: setOnSuccess('userDetails'),
    [apiActions.agreeToTerms]: setOnSuccess(
      'userDetails.agreedAt',
      ({ payload: { data } }) => data.agreedAt
    ),
    [apiActions.markAllAsRead]: setOnSuccess('notifications'),
    [apiActions.deleteNotification]: setOnSuccess('notifications'),
    [apiActions.markAsRead]: setOnSuccess('notifications'),
    [apiActions.fetchSchoolTypes]: setOnSuccess('schoolTypes'),
    [apiActions.fetchNotificationPreferences]: setOnSuccess(
      'notificationPreferences'
    ),
    [apiActions.updateNotificationPreferences]: setOnSuccess(
      'notificationPreferences'
    ),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  activeSchoolYears: select('activeSchoolYears'),
  currentSchoolId: select('currentSchoolId'),
  district: select('school.district'),
  userDetails: select('userDetails'),
  isAdmin: select('isAdmin'),
  isAuthenticated: select('isAuthenticated'),
  partnerOptions: select('partnerOptions'),
  partners: select('partners'),
  positions: select('positions'),
  school: select('school'),
  notifications: select('notifications'),
  schoolTypes: select('schoolTypes'),
  schoolOptions: select('schoolOptions'),
  selectOptions: select('selectOptions'),
  surveySubmitted: select('school.surveySubmitted?', false),
  surveyAttempt: select('surveyAttempt'),
  communityConfigSso: select('communityConfigSso'),
  notificationPreferences: select('notificationPreferences'),
}

// Computed

selectors.partnerLocations = createSelector(
  [selectors.partners],
  function (partners) {
    if (!partners) return null
    return partners.map(serializeLocation(Types.PARTNER_TYPE))
  }
)

selectors.currentSchoolLocation = createSelector(
  [selectors.school],
  function (school) {
    if (!school) return null
    return school.address
  }
)

selectors.currentDetail = createSelector([selectors.school], function (school) {
  if (!school) return null
  return school.detail
})

selectors.allLocations = createSelector(
  [selectors.partnerLocations, selectors.currentSchoolLocation],
  function (partnerLocations, currentSchoolLocation) {
    if (!partnerLocations || !currentSchoolLocation) return null
    return [...partnerLocations, currentSchoolLocation]
  }
)

selectors.latestParticipation = createSelector(
  [selectors.school],
  function (school) {
    if (!school || !school.participations) return null
    return first(school.participations)
  }
)

selectors.disableSurvey = createSelector(
  [selectors.surveySubmitted, selectors.isAdmin],
  function (surveySubmitted, isAdmin) {
    if (isAdmin) return false
    return surveySubmitted
  }
)

selectors.surveySchoolYear = createSelector(
  [selectors.surveyAttempt, selectors.activeSchoolYears],
  function (surveyAttempt, activeSchoolYears) {
    if (!surveyAttempt || !activeSchoolYears) return
    const surveySchoolYearId = get(surveyAttempt, 'survey.schoolYearId')
    return activeSchoolYears.find((year) => year.id == surveySchoolYearId)
  }
)

selectors.surveyParticipation = createSelector(
  [selectors.school, selectors.surveySchoolYear],
  function (school, surveySchoolYear) {
    if (!school || !school.participations || !surveySchoolYear) return null
    return find(school.participations, {
      schoolYearId: surveySchoolYear.id,
    })
  }
)

// Order school years by most recent (DESC)
selectors.orderedActiveSchoolYears = createSelector(
  [selectors.activeSchoolYears],
  function (schoolYears) {
    if (!schoolYears) return
    return orderBy(schoolYears, 'number', 'desc')
  }
)

selectors.liaisonPosition = createSelector(
  [selectors.positions],
  function (positions) {
    if (!positions) return null
    return positions.find((position) => position.name == 'arts_liaison')
  }
)

selectors.definedSchoolTypes = createSelector(
  [selectors.schoolTypes],
  (schoolTypes) => {
    if (!schoolTypes) return
    return schoolTypes.filter(({ name }) => name !== Types.ALL_SCHOOLS_TYPE)
  }
)

selectors.educationContactPosition = createSelector(
  [selectors.positions],
  function (positions) {
    if (!positions) return null
    return positions.find((position) => position.name === 'education_contact')
  }
)

export { reducer, reducerKey, selectors }
