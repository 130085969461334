import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import * as Types from 'types'

const propTypes = {
  image: Types.image.isRequired,
  children: PropTypes.node,
}

const defaultProps = {}

function CommunityBackgroundImage({ image, children, ...rest }) {
  const { src, alt, attribution } = image
  return (
    <div {...rest}>
      <div className="background-image-wrapper">
        <span
          className="background-image"
          role="presentation"
          style={{ backgroundImage: `url(${src})` }}
          aria-label={alt}
          // Hide element from screen readers when alt text is not provided
          aria-hidden={!alt}
        />
        {attribution && (
          <span className="image-attribution">{attribution}</span>
        )}
      </div>
      {children}
      {attribution && <span className="image-attribution">{attribution}</span>}
    </div>
  )
}

CommunityBackgroundImage.propTypes = propTypes
CommunityBackgroundImage.defaultProps = defaultProps

export default pure(CommunityBackgroundImage)
