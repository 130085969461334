import React from 'react'
import PropTypes from 'prop-types'
import { PortalHeader } from 'components'
import { useCommunity } from 'utils'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as globalSelectors } from 'educator-portal-reducer'
import { replace } from 'react-router-redux'

const propTypes = {
  children: PropTypes.node.isRequired,
  isModerator: PropTypes.bool.isRequired,
  redirectTo404: PropTypes.func.isRequired,
}

const defaultProps = {}

function Layout({ children, isModerator, redirectTo404 }) {
  const {
    defaultBranding: { dashboardHeader },
  } = useCommunity()

  if (!isModerator) redirectTo404()

  return (
    <div>
      <PortalHeader title="Flagged" backgroundImage={dashboardHeader} />
      <div className="content-block-container">{children}</div>
    </div>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    isModerator: globalSelectors.isModerator(state),
  }
}

const mapDispatchToProps = {
  redirectTo404: () => replace('/not-found'),
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
