import { parsePhoneNumber } from 'awesome-phonenumber'

// Remove all whitespace and formatting characters
function stripFormatting(phoneNumber) {
  return phoneNumber.replace(/[ ()-]/g, '')
}

// Make local numbers international, aka start with [ + country code ]
function makeInternational(phoneNumber) {
  // Return if it's already intl
  if (phoneNumber.startsWith('+')) return phoneNumber
  // Add US country code to 10-digit numbers
  if (phoneNumber.length === 10) return '+1' + phoneNumber
  // Assume other numbers start with country code
  return '+' + phoneNumber
}

function formatPhoneNumber(num) {
  if (!num) return num
  const intlNumber = makeInternational(stripFormatting(String(num)))
  const phoneNumber = parsePhoneNumber(intlNumber)
  if (!phoneNumber) return num
  return phoneNumber.regionCode === 'US'
    ? phoneNumber.number.national
    : phoneNumber.number.international
}

export default formatPhoneNumber
