import { sortBy } from 'lodash'
import isOtherType from './is-other-type'
import orderLastBy from './order-last-by'

// Sort by ascending order, with "Other" last, using specified object key
// Default to alphabetically by displayName
function orderAscendingToOtherByKey(objects, { key = 'displayName' } = {}) {
  if (!objects) return objects
  const sorted = sortBy(objects, key)
  return orderLastBy(isOtherType, sorted)
}

export default orderAscendingToOtherByKey
