import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import {
  Field,
  FieldArray,
  propTypes as formPropTypes,
  formValueSelector,
} from 'redux-form'
import { Tooltip } from 'components'
import { SubmitButton, Checkbox, ButtonArea } from 'lp-components'
import { persistSubmitSucceeded } from 'utils'
import { some, filter, unionBy, includes } from 'lodash'

const propTypes = {
  ...formPropTypes,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
}

const defaultProps = {}

const FORM_NAME = 'school-obstacles-table'
const MAX_OBSTACLES_PER_DISCIPLINE = 3

function addNewDisciplineId(selectedDisciplineIds, disciplineId) {
  return unionBy(selectedDisciplineIds, [{ disciplineId }], 'disciplineId')
}

function removeDisciplineId(selectedDisciplineIds, disciplineIdToRemove) {
  return filter(
    selectedDisciplineIds,
    ({ disciplineId }) => disciplineId !== disciplineIdToRemove
  )
}

function getSelectedObstaclesForDiscipline(
  disciplinesSelectedByObstacle,
  disciplineId
) {
  return filter(disciplinesSelectedByObstacle, ({ secondaryResource }) =>
    some(secondaryResource.disciplines, { disciplineId })
  )
}

function isMaxSelectedForDisciplineId(
  disciplineId,
  disciplinesSelectedByObstacle
) {
  const selectedForDiscipline = getSelectedObstaclesForDiscipline(
    disciplinesSelectedByObstacle,
    disciplineId
  )
  return selectedForDiscipline.length >= MAX_OBSTACLES_PER_DISCIPLINE
}

function getDisabledDisciplineIds(disciplinesSelectedByObstacle, disciplines) {
  const disabledDisciplines = filter(disciplines, ({ id }) =>
    isMaxSelectedForDisciplineId(id, disciplinesSelectedByObstacle)
  )
  return disabledDisciplines.map(({ id }) => id)
}

function ObstacleField({
  field,
  currentObstacle,
  discipline,
  disabledDiscipline,
}) {
  const selectedDisciplineIds = currentObstacle.secondaryResource.disciplines
  const isDisciplineChecked = useCallback(
    () => some(selectedDisciplineIds, { disciplineId: discipline.id }),
    [selectedDisciplineIds, discipline]
  )
  const updateDisciplineIds = useCallback(
    (fieldChecked) => {
      if (fieldChecked)
        return addNewDisciplineId(selectedDisciplineIds, discipline.id)
      return removeDisciplineId(selectedDisciplineIds, discipline.id)
    },
    [selectedDisciplineIds, discipline]
  )
  const disabled = disabledDiscipline && !isDisciplineChecked()
  return (
    <td>
      <Field
        name={field + '.secondaryResource.disciplines'}
        label={false}
        aria-labelledby={`${discipline.name} ${currentObstacle.name}`}
        format={isDisciplineChecked}
        parse={updateDisciplineIds}
        component={Checkbox}
        disabled={disabled}
      />
      {disabled && (
        <Tooltip iconClassName="tooltip-over-cell" icon={false} darkMode>
          Maximum of 3 obstacles per discipline. Please unselect one for this
          discipline to select another.
        </Tooltip>
      )}
    </td>
  )
}

function ObstacleFields({ fields, disciplines, disabledDisciplineIds }) {
  return fields.map((field, i) => {
    const obstacle = fields.get(i)
    return (
      <tr key={obstacle.obstacleId}>
        <td className="long-cell-name" id={obstacle.name}>
          {obstacle.displayName}
        </td>
        {disciplines.map((discipline) => {
          const disabledDiscipline = includes(
            disabledDisciplineIds,
            discipline.id
          )
          return (
            <ObstacleField
              key={`${obstacle.obstacleId}-${discipline.id}`}
              field={field}
              currentObstacle={obstacle}
              discipline={discipline}
              disabledDiscipline={disabledDiscipline}
            />
          )
        })}
      </tr>
    )
  })
}

function ObstaclesTableForm({
  handleSubmit,
  disciplines,
  submitting,
  submitSucceeded,
  pristine,
  disciplinesSelectedByObstacle,
}) {
  const [disabledDisciplineIds, setDisabledDisciplineIds] = useState([])
  useEffect(() => {
    const disabledDisciplineIds = getDisabledDisciplineIds(
      disciplinesSelectedByObstacle,
      disciplines
    )
    setDisabledDisciplineIds(disabledDisciplineIds)
  }, [disciplinesSelectedByObstacle, disciplines, setDisabledDisciplineIds])
  return (
    <form onSubmit={handleSubmit}>
      <table>
        <thead>
          <tr>
            <th aria-label="Obstacle"></th>
            {disciplines.map(({ name, displayName }) => (
              <th key={name} id={name}>
                {displayName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <FieldArray
            name="disciplinesSelectedByObstacle"
            disciplines={disciplines}
            component={ObstacleFields}
            disabledDisciplineIds={disabledDisciplineIds}
            rerenderOnEveryChange
          />
        </tbody>
      </table>
      <ButtonArea>
        <SubmitButton
          {...{ pristine: submitSucceeded && pristine, submitting }}
        >
          Save Response
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

ObstaclesTableForm.propTypes = propTypes
ObstaclesTableForm.defaultProps = defaultProps

const selectFormValue = formValueSelector(FORM_NAME)

function mapStateToProps(state) {
  return {
    disciplinesSelectedByObstacle: selectFormValue(
      state,
      'disciplinesSelectedByObstacle'
    ),
  }
}

export default compose(
  connect(mapStateToProps),
  lpForm({
    name: FORM_NAME,
    enableReinitialize: true,
  }),
  persistSubmitSucceeded()
)(ObstaclesTableForm)
