import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { FilePreviewerThumbnail } from 'react-file-previewer'
import imagePlaceholder from 'images/image-placeholder.jpeg'
import { sanitizeExternalUrl } from 'utils'

const propTypes = {
  fileUrl: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
}

const defaultProps = {}

// file types supported by react-file-previewer
const SUPPORTED_FILE_TYPES = ['pdf', 'png', 'jpeg', 'jpg']

function getFileExtension(fileUrl) {
  // Use a regular expression to match the file extension at end of url
  const regex = /(?:\.([^.]+))?$/
  const extension = regex.exec(fileUrl)[1]
  return extension?.toLowerCase()
}

function fileTypeSupported(fileUrl) {
  const extension = getFileExtension(fileUrl)
  return SUPPORTED_FILE_TYPES.includes(extension)
}

function handleClick(fileUrl) {
  return window.open(sanitizeExternalUrl(fileUrl), '_blank')
}

function AttachmentPreviewThumbnail({ fileUrl, filename }) {
  return (
    <div className="attachment-preview-thumbnail">
      {fileTypeSupported(fileUrl) ? (
        <FilePreviewerThumbnail
          file={{
            url: fileUrl,
            name: filename,
          }}
          onClick={() => handleClick(fileUrl)}
        />
      ) : (
        <img
          className="no-preview"
          src={imagePlaceholder}
          alt="No file preview available"
          onClick={() => handleClick(fileUrl)}
        />
      )}
    </div>
  )
}
AttachmentPreviewThumbnail.propTypes = propTypes
AttachmentPreviewThumbnail.defaultProps = defaultProps

export default pure(AttachmentPreviewThumbnail)
