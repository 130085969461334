import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
}

const defaultProps = {
  checked: false,
}

function ChecklistItem({ checked, children }) {
  const className = checked ? 'checked' : 'unchecked'
  return (
    <li>
      <div className={className}></div>
      <p>{children}</p>
    </li>
  )
}

ChecklistItem.propTypes = propTypes

ChecklistItem.defaultProps = defaultProps

export default ChecklistItem
