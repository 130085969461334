import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'

const propTypes = {
  title: PropTypes.string.isRequired,
  programsCount: PropTypes.number.isRequired,
  accessibleProgramsCount: PropTypes.number.isRequired,
}

const defaultProps = {}

function DisciplineSectionHeader({
  title,
  programsCount,
  accessibleProgramsCount,
}) {
  return (
    <React.Fragment>
      <span>{title}</span>
      <span
        className="section-header-details"
        aria-label={`${accessibleProgramsCount} out of ${programsCount} selected`}
      >
        <em>
          {accessibleProgramsCount} / {programsCount} selected
        </em>
      </span>
    </React.Fragment>
  )
}

DisciplineSectionHeader.propTypes = exact(propTypes)
DisciplineSectionHeader.defaultProps = defaultProps

export default pure(DisciplineSectionHeader)
