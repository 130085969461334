import React, { useState, useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { selectors as apiSelectors } from 'lp-redux-api'
import { selectors as globalSchoolSelectors } from 'school-portal-reducer'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors as surveySelectors } from '../../survey/reducer'
import { selectors } from '../reducer'
import * as Types from 'types'
import { PartnershipTableForm } from '../../survey/forms'
import { PartnershipQuestionForm } from 'forms'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { Spinner } from 'lp-components'
import { SchoolYearTabBar, ExternalLink, ErrorLoadState } from 'components'
import { displaySubmitFailure, useCommunity } from 'utils'
import * as flashActions from 'redux-flash'
import { WHATS_HAPPENING_NOW_TEXT } from 'config'
import { sortBy, get } from 'lodash'

const propTypes = {
  activeSchoolYears: PropTypes.arrayOf(Types.schoolYear).isRequired,
  school: Types.school.isRequired,
  updateSchool: PropTypes.func.isRequired,
  programTypes: PropTypes.array.isRequired,
  detail: Types.detail.isRequired,
  partnerOptions: PropTypes.arrayOf(Types.searchSuggestion),
  partnershipQuestion: Types.partnershipQuestion,
  surveySchoolYear: Types.schoolYear,
  isSurveyOpen: PropTypes.bool.isRequired,
  fetchPartnerOptions: PropTypes.func.isRequired,
  fetchPartnershipQuestion: PropTypes.func.isRequired,
  clearPartnershipQuestion: PropTypes.func.isRequired,
  isLoadingPartnershipQuestion: PropTypes.bool.isRequired,
  hasPartnershipQuestionLoadFailed: PropTypes.bool.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
}

const defaultProps = {
  partnerOptions: null,
  surveySchoolYear: null,
}

function SurveyRedirectNote({ surveySchoolYearNumber }) {
  return (
    <section className="card">
      <p>
        Please list this year's partnerships in the{' '}
        <Link href="/school-portal/survey/community-and-partnerships">
          Community and Partnerships
        </Link>{' '}
        section of the{' '}
        <Link href="/school-portal/survey/">
          {surveySchoolYearNumber} artlook survey
        </Link>
        .
      </p>
    </section>
  )
}

function CustomPartnershipQuestionHeader({
  surveySchoolYearNumber,
  isSurveyOpen,
  supportEmail,
}) {
  return (
    <React.Fragment>
      <h2>
        Which arts organizations / teaching artists is your school partnering
        with this year?
      </h2>
      <p>
        In this section, please list only partnerships that are happening during
        this School Year.
      </p>
      {isSurveyOpen && surveySchoolYearNumber && (
        <p>
          You can list partnerships that happened in your prior School Year in
          your <strong>{surveySchoolYearNumber}</strong> Survey.
        </p>
      )}
      <p>
        Please contact{' '}
        <ExternalLink href={`mailto:${supportEmail}`}>
          {supportEmail}
        </ExternalLink>{' '}
        if you have any questions.
      </p>
    </React.Fragment>
  )
}

function Partnerships({
  activeSchoolYears,
  school,
  updateSchool,
  programTypes,
  detail,
  partnerOptions,
  partnershipQuestion,
  surveySchoolYear,
  isSurveyOpen,
  fetchPartnerOptions,
  fetchPartnershipQuestion,
  clearPartnershipQuestion,
  isLoadingPartnershipQuestion,
  hasPartnershipQuestionLoadFailed,
  flashSuccessMessage,
}) {
  useEffect(() => {
    if (!partnerOptions) fetchPartnerOptions()
  }, [partnerOptions])

  useEffect(() => {
    fetchPartnershipQuestion()

    return () => clearPartnershipQuestion()
  }, [])

  const { partnerships, openToPartnerships } = school
  const currentSchoolYearId = detail.schoolYearId
  const [currentSchoolYearTab, setCurrentSchoolYearTab] =
    useState(currentSchoolYearId)

  const partnershipsForSchoolYear = useMemo(() => {
    const filteredPartnerships = partnerships.filter(
      ({ schoolYearId }) => schoolYearId === currentSchoolYearTab
    )
    return sortBy(filteredPartnerships, 'organizationName')
  }, [partnerships, currentSchoolYearTab])

  const previousPartnerships = useMemo(
    () =>
      partnerships.filter(
        ({ schoolYearId }) => schoolYearId !== currentSchoolYearId
      ),
    [partnerships, currentSchoolYearId]
  )
  const updateSchoolOnSubmit = useCallback(
    (params) => updateSchool(school.id, currentSchoolYearId, params),
    [school, currentSchoolYearId]
  )
  const onSuccess = useCallback(
    () => flashSuccessMessage(Types.SCHOOL_SUCCESS_MESSAGE),
    [flashSuccessMessage]
  )

  const community = useCommunity()

  if (!partnerOptions || isLoadingPartnershipQuestion) return <Spinner />

  return (
    <div>
      <p>{WHATS_HAPPENING_NOW_TEXT}</p>
      {partnershipQuestion && (
        <section className="card">
          <PartnershipQuestionForm
            partnershipQuestion={partnershipQuestion}
            initialValues={{ openToPartnerships }}
            onSubmit={updateSchoolOnSubmit}
            onSubmitSuccess={onSuccess}
            onSubmitFail={displaySubmitFailure}
          />
        </section>
      )}
      {hasPartnershipQuestionLoadFailed && (
        <ErrorLoadState
          componentName="Looking for Partnerships form"
          headerAs="h2"
        />
      )}
      {/* Capture the current year's partnership _only if_ that year differs from the survey,
      otherwise direct user to survey */}
      {surveySchoolYear && surveySchoolYear.id === currentSchoolYearId ? (
        <SurveyRedirectNote surveySchoolYearNumber={surveySchoolYear.number} />
      ) : (
        <div className="partnerships-year-select">
          <SchoolYearTabBar
            schoolYears={activeSchoolYears}
            value={currentSchoolYearTab}
            onChange={setCurrentSchoolYearTab}
          />
          <PartnershipTableForm
            initialValues={{
              partnerships: partnershipsForSchoolYear,
            }}
            onSubmit={updateSchoolOnSubmit}
            onSubmitSuccess={onSuccess}
            onSubmitFail={displaySubmitFailure}
            visibleSchoolYearId={currentSchoolYearTab}
            programTypes={programTypes}
            schoolYears={activeSchoolYears}
            partnerOptions={partnerOptions}
            otherPartnerships={previousPartnerships}
            disabled={currentSchoolYearTab !== currentSchoolYearId}
            surveySchoolYearNumber={get(surveySchoolYear, 'number')}
            headerComponent={({ surveySchoolYearNumber }) => (
              <CustomPartnershipQuestionHeader
                isSurveyOpen={isSurveyOpen}
                surveySchoolYearNumber={surveySchoolYearNumber}
                supportEmail={community.schoolSupportEmail}
              />
            )}
          />
        </div>
      )}
    </div>
  )
}

Partnerships.propTypes = propTypes
Partnerships.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    activeSchoolYears: globalSchoolSelectors.orderedActiveSchoolYears(state),
    detail: globalSchoolSelectors.currentDetail(state),
    school: globalSchoolSelectors.school(state),
    programTypes: globalSelectors.orderedProgramTypes(state),
    partnerOptions: globalSchoolSelectors.partnerOptions(state),
    partnershipQuestion: selectors.partnershipQuestion(state),
    isSurveyOpen: surveySelectors.isSurveyOpen(state),
    surveySchoolYear: globalSchoolSelectors.surveySchoolYear(state),
    isLoadingPartnershipQuestion: apiSelectors.isLoading(
      state,
      apiActions.fetchPartnershipQuestion
    ),
    hasPartnershipQuestionLoadFailed: apiSelectors.isFailure(
      state,
      apiActions.fetchPartnershipQuestion
    ),
  }
}

const mapDispatchToProps = {
  flashSuccessMessage: flashActions.flashSuccessMessage,
  fetchPartnerOptions: apiActions.fetchPartnerOptions,
  fetchPartnershipQuestion: apiActions.fetchPartnershipQuestion,
  clearPartnershipQuestion: actions.clearPartnershipQuestion,
  updateSchool: apiActions.updateSchool,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Partnerships
)
