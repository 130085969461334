import React from 'react'
import PropTypes from 'prop-types'
import PartnerTable from './partner-table'
import PartnerTableLinkCell from './partner-table-link-cell'
import * as Types from 'types'

const propTypes = {
  nameField: PropTypes.string,
  partners: PropTypes.arrayOf(Types.partnership).isRequired,
  type: Types.searchResultType.isRequired,
  schoolYearId: PropTypes.number,
  programNames: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const defaultProps = {
  nameField: 'name',
}

const getPartnershipDisplayName = (partner, type) => {
  return type === Types.SCHOOL_TYPE
    ? partner.schoolName
    : partner.organizationName
}

function MapPartnerTable({
  partners,
  nameField,
  type,
  schoolYearId,
  programNames,
}) {
  return (
    <PartnerTable
      rows={partners.map((p) => ({
        ...p,
        type,
        [nameField]: getPartnershipDisplayName(p, type),
        schoolYearId: schoolYearId,
      }))}
      nameColumn={{ name: nameField, component: PartnerTableLinkCell }}
      programNames={programNames}
    />
  )
}

MapPartnerTable.propTypes = propTypes
MapPartnerTable.defaultProps = defaultProps

export default MapPartnerTable
