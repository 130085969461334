import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { pure } from 'recompose'
import ExternalLink from './external-link'
import { isEmpty } from 'lodash'

const propTypes = {
  remove: PropTypes.func.isRequired,
  attachments: PropTypes.arrayOf(Types.attachmentUpload).isRequired,
}

const defaultProps = {}

function RemovableAttachments({ attachments, remove }) {
  // do not render if there are no attachments uploaded yet
  if (isEmpty(attachments)) return null

  return (
    <div className="attachments-preview">
      {attachments.map(({ src, filename }) => (
        <div key={src}>
          <ExternalLink href={src}>{filename}</ExternalLink>
          <button
            type="button"
            className="button-warn button-small"
            onClick={() => remove(src)}
          >
            Remove
          </button>
        </div>
      ))}
    </div>
  )
}

RemovableAttachments.propTypes = propTypes
RemovableAttachments.defaultProps = defaultProps

export default pure(RemovableAttachments)
