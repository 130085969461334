import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { Textarea, SubmitButton, ButtonArea } from 'lp-components'
import { persistSubmitSucceeded } from 'utils'

const propTypes = {
  ariaLabelledby: PropTypes.string.isRequired,
  ...formPropTypes,
}

const defaultProps = {}

function CustomQuestionForm({
  handleSubmit,
  submitSucceeded,
  pristine,
  submitting,
  ariaLabelledby,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="otherText"
        label={false}
        aria-labelledby={ariaLabelledby}
        component={Textarea}
      />
      <ButtonArea>
        <SubmitButton
          {...{ pristine: submitSucceeded && pristine, submitting }}
        >
          Save Response
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

CustomQuestionForm.propTypes = propTypes
CustomQuestionForm.defaultProps = defaultProps

export default compose(
  lpForm({
    constraints: {
      otherText: {
        length: {
          maximum: 1000,
          message: 'Answer is too long (maximum is 1000 characters)',
        },
      },
    },
  }),
  persistSubmitSucceeded()
)(CustomQuestionForm)
