import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import ExternalLink from './external-link'
import { buildInternalApiUrl, generateMappedFilters } from 'utils'

const propTypes = {
  query: PropTypes.string.isRequired,
  type: Types.searchResultType.isRequired,
  filters: Types.searchFilters.isRequired,
  children: PropTypes.node.isRequired,
  schoolYear: PropTypes.number.isRequired,
}

function ExportLink({ query, type, filters, schoolYear, children, ...rest }) {
  const mappedFilters = generateMappedFilters({ filters, type, schoolYear })
  const queryParams = {
    query,
    type,
    filters: JSON.stringify(mappedFilters),
  }
  const exportLink = buildInternalApiUrl({
    url: 'export.csv',
    query: queryParams,
  })

  return (
    <ExternalLink href={exportLink} {...rest}>
      {children}
    </ExternalLink>
  )
}

ExportLink.propTypes = propTypes

export default ExportLink
