import React from 'react'

function CommentIcon() {
  return (
    <svg width="11" height="10" viewBox="0 0 11 10">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-619 -377)">
          <g transform="translate(619 377)">
            <path d="M0 0L11 0 11 11 0 11z"></path>
            <path
              fill="#484848"
              fillRule="nonzero"
              stroke="#484848"
              strokeWidth="0.5"
              d="M9.1 1H1.9c-.495 0-.9.405-.9.9V10l1.8-1.8h6.3c.495 0 .9-.405.9-.9V1.9c0-.495-.405-.9-.9-.9zm0 6.3H2.8l-.9.9V1.9h7.2v5.4z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CommentIcon
