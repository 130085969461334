import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import * as Types from 'types'
import { CommunityContext } from 'contexts'
import { lazy } from '@loadable/component'
import { get } from 'lodash'
import { Helmet } from 'react-helmet'

// Lazy-loaded styles (fallback to Chicago for application defaults)
const CommunityStyles = lazy.lib(({ stylesheet }) =>
  import(`../../scss/communities/${stylesheet}`).catch(() =>
    import('../../scss/communities/chicago.scss')
  )
)

const propTypes = {
  community: Types.communityConfig,
  children: PropTypes.node,
}

const defaultProps = {
  community: null,
}

// Provides community context and async loads community styles
function CommunityProvider({ community, children }) {
  const favicon = get(community, 'defaultBranding.favicon')
  return (
    <React.Fragment>
      {favicon && (
        <Helmet>
          <link rel="icon" href={favicon} />
        </Helmet>
      )}
      <div className={get(community, 'defaultBranding.className')}>
        <CommunityContext.Provider value={community}>
          <Suspense fallback={null}>
            <CommunityStyles stylesheet={get(community, 'stylesheet')} />
            {children}
          </Suspense>
        </CommunityContext.Provider>
      </div>
    </React.Fragment>
  )
}

CommunityProvider.propTypes = exact(propTypes)
CommunityProvider.defaultProps = defaultProps

export default pure(CommunityProvider)
