import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { waitFor, onMount, toggle } from 'lp-hoc'
import {
  SectionBlock,
  SectionHeader,
  LoadingSpinner,
  LoadingContainer,
  InfoModal,
} from 'components'
import { ProgramBlade, CopyProgramModal, SchoolYearTabBar } from '../components'
import { useDynamicInfoModal } from 'utils'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { selectors } from '../reducer'
import * as globalActions from 'partner-portal-actions'
import { selectors as globalPartnerSelectors } from 'partner-portal-reducer'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors as apiSelectors } from 'lp-redux-api'
import * as Types from 'types'
import { get, isEmpty, first, mapValues, groupBy } from 'lodash'

const propTypes = {
  toggleNewProgramFormShown: PropTypes.func.isRequired,
  setNewProgramFormShown: PropTypes.func.isRequired,
  newProgramFormShown: PropTypes.bool.isRequired,
  partner: Types.partner.isRequired,
  activeSchoolYears: PropTypes.arrayOf(Types.schoolYear).isRequired,
  isLoading: PropTypes.bool.isRequired,
  updatePartner: PropTypes.func.isRequired,
  setPartner: PropTypes.func.isRequired,
  currentSchoolYear: Types.schoolYear.isRequired,
  outcomeTypes: PropTypes.arrayOf(Types.outcomeType).isRequired,
  grades: PropTypes.arrayOf(Types.grade).isRequired,
  programTypes: PropTypes.arrayOf(Types.programType).isRequired,
  financialAssistanceTypes: PropTypes.arrayOf(Types.communityEnumerable)
    .isRequired,
  identityFrequencies: PropTypes.arrayOf(Types.communityEnumerable).isRequired,
  approaches: PropTypes.arrayOf(Types.communityEnumerable).isRequired,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
}

const defaultProps = {}

function Programs({
  currentSchoolYear,
  activeSchoolYears,
  partner: { programs },
  partner,
  toggleNewProgramFormShown,
  setNewProgramFormShown,
  newProgramFormShown,
  isLoading,
  outcomeTypes,
  grades,
  programTypes,
  financialAssistanceTypes,
  identityFrequencies,
  approaches,
  updatePartner,
  setPartner,
  disciplines,
}) {
  const [selectedSchoolYear, setSelectedSchoolYear] =
    useState(currentSchoolYear)
  const [showInfoModal, infoModalContent, setShowInfoModalWithContent] =
    useDynamicInfoModal()
  const [showCopyProgramsModal, setShowCopyProgramsModal] = useState(false)
  const schoolYearsById = useMemo(() => {
    return mapValues(groupBy(activeSchoolYears, 'id'), first)
  }, [activeSchoolYears])

  const editableSchoolYearIds = useMemo(() => {
    return activeSchoolYears.slice(0, 2).map(({ id }) => id)
  }, [activeSchoolYears])

  const updatePartnerForSchoolYear = useCallback((params) => {
    return updatePartner(partner.id, selectedSchoolYear.id, params)
  })

  const programsForSchoolYear = useMemo(() => {
    return programs.filter(
      ({ schoolYearId }) => schoolYearId === selectedSchoolYear.id
    )
  }, [programs, selectedSchoolYear])

  const programsForOtherSchoolYears = useMemo(() => {
    if (!editableSchoolYearIds.includes(selectedSchoolYear.id)) return []
    return programs.filter(({ schoolYearId }) => {
      const existingPosition = get(
        schoolYearsById,
        `${schoolYearId}.position`,
        0
      )
      return existingPosition < selectedSchoolYear.position
    })
  }, [programs, schoolYearsById, selectedSchoolYear, editableSchoolYearIds])

  const isEditable = editableSchoolYearIds.includes(selectedSchoolYear.id)

  return (
    <div>
      <SchoolYearTabBar
        schoolYears={activeSchoolYears}
        schoolYear={selectedSchoolYear}
        onChange={setSelectedSchoolYear}
      />
      <SectionBlock>
        <SectionHeader>
          <h3> List All the Programs You Offer </h3>
          <p>
            {' '}
            Please list all programs your organization offers. Enter overarching
            organization programs (programs such as residencies, in-school
            performances, field trips, etc.) – not an individual school’s
            programs. For example, if you provide a dance residency named
            “Rhythms Around the World” to many schools, you only need to enter
            this program one time, not for each school you’re providing the
            residency to.{' '}
          </p>
          {isEditable && (
            <div className="button-area many-buttons">
              <button
                onClick={toggleNewProgramFormShown}
                className="button-primary-outline"
              >
                {newProgramFormShown ? (
                  <React.Fragment>
                    <span aria-hidden>-</span> Remove New Program{' '}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span aria-hidden>+</span> Add New Program{' '}
                  </React.Fragment>
                )}
              </button>
              <button
                onClick={() => setShowCopyProgramsModal(true)}
                className="button-primary-outline"
              >
                Copy a program from another school year
              </button>
            </div>
          )}
        </SectionHeader>
        {!newProgramFormShown && isEmpty(programsForSchoolYear) ? (
          <p className="spacing-top">
            No programs reported for the {selectedSchoolYear.number} school
            year.
          </p>
        ) : (
          <div className="expandable-section program-expander">
            {newProgramFormShown && isEditable && (
              <ProgramBlade
                onSubmit={(params) =>
                  updatePartnerForSchoolYear({
                    id: partner.id,
                    programs: [params],
                  })
                }
                onSubmitSuccess={(partner) => {
                  setNewProgramFormShown(false)
                  return setPartner(partner)
                }}
                onDelete={() => setNewProgramFormShown(false)}
                setShowInfoModalWithContent={setShowInfoModalWithContent}
                grades={grades}
                outcomeTypes={outcomeTypes}
                programTypes={programTypes}
                financialAssistanceTypes={financialAssistanceTypes}
                identityFrequencies={identityFrequencies}
                approachTypes={approaches}
                disciplines={disciplines}
                isNewProgram
              />
            )}
            {!isEmpty(programsForSchoolYear) && (
              <LoadingContainer isLoading={isLoading}>
                {programsForSchoolYear.map((program) => (
                  <ProgramBlade
                    key={program.id}
                    program={program}
                    onSubmit={(params) =>
                      updatePartnerForSchoolYear({
                        id: partner.id,
                        programs: [params],
                      })
                    }
                    onSubmitSuccess={setPartner}
                    onDelete={() =>
                      updatePartnerForSchoolYear({
                        id: partner.id,
                        programs: [{ ...program, _destroy: true }],
                      })
                    }
                    setShowInfoModalWithContent={setShowInfoModalWithContent}
                    disabled={!isEditable}
                    grades={grades}
                    outcomeTypes={outcomeTypes}
                    programTypes={programTypes}
                    financialAssistanceTypes={financialAssistanceTypes}
                    identityFrequencies={identityFrequencies}
                    approachTypes={approaches}
                    disciplines={disciplines}
                  />
                ))}
              </LoadingContainer>
            )}
          </div>
        )}
      </SectionBlock>
      {showInfoModal && (
        <InfoModal
          onClose={() => setShowInfoModalWithContent(false)}
          content={infoModalContent.content}
          header={infoModalContent.header}
        />
      )}
      {showCopyProgramsModal && (
        <CopyProgramModal
          onClose={() => setShowCopyProgramsModal(false)}
          programs={programsForOtherSchoolYears}
          schoolYears={activeSchoolYears}
          onSubmit={({ programs }) =>
            updatePartnerForSchoolYear({ id: partner.id, programs })
          }
          onSubmitSuccess={(res) => {
            setShowCopyProgramsModal(false)
            return setPartner(res)
          }}
        />
      )}
    </div>
  )
}

Programs.propTypes = propTypes
Programs.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentPartnerId: globalPartnerSelectors.currentPartnerId(state),
    currentSchoolYear: globalSelectors.currentSchoolYear(state),
    selectOptions: globalPartnerSelectors.selectOptions(state),
    partner: globalPartnerSelectors.partner(state),
    disciplines: globalSelectors.definedDisciplines(state),
    programTypes: globalSelectors.orderedProgramTypes(state),
    outcomeTypes: selectors.outcomeTypes(state),
    activeSchoolYears: globalPartnerSelectors.orderedActiveSchoolYears(state),
    grades: globalSelectors.grades(state),
    financialAssistanceTypes: selectors.financialAssistanceTypes(state),
    identityFrequencies: selectors.identityFrequencies(state),
    approaches: selectors.approaches(state),
    currentProgramYearTab: selectors.currentProgramYearTab(state),
    isLoading: apiSelectors.isLoading(state, apiActions.fetchProgramTypes),
  }
}

const mapDispatchToProps = {
  fetchGrades: apiActions.fetchGrades,
  fetchOutcomeTypes: apiActions.fetchOutcomeTypes,
  fetchFinancialAssistanceTypes: apiActions.fetchFinancialAssistanceTypes,
  fetchIdentityFrequencies: apiActions.fetchIdentityFrequencies,
  fetchApproaches: apiActions.fetchApproaches,
  updatePartner: apiActions.updatePartner,
  setCurrentProgramYearTab: actions.setCurrentProgramYearTab,
  setPrograms: actions.setPrograms,
  setPartner: globalActions.setPartner,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  onMount('fetchGrades'),
  onMount('fetchOutcomeTypes'),
  onMount('fetchFinancialAssistanceTypes'),
  onMount('fetchIdentityFrequencies'),
  onMount('fetchApproaches'),
  waitFor(
    [
      'partner',
      'grades',
      'outcomeTypes',
      'financialAssistanceTypes',
      'identityFrequencies',
      'approaches',
    ],
    LoadingSpinner
  ),
  toggle('newProgramFormShown')
)(Programs)
