import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { SubmitButton, ButtonArea, Textarea } from 'lp-components'
import { Field, propTypes as formPropTypes } from 'redux-form'

const propTypes = {
  ...formPropTypes,
  onClose: PropTypes.func.isRequired,
}

const defaultProps = {}

function FlagForm({ handleSubmit, submitting, onClose }) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field
        name="reason"
        label="Please let us know why you are flagging this as inappropriate"
        component={Textarea}
      />
      <ButtonArea>
        <SubmitButton submitting={submitting}>Submit Flag</SubmitButton>
        <button type="button" className="button-grey-light" onClick={onClose}>
          Cancel
        </button>
      </ButtonArea>
    </form>
  )
}

FlagForm.propTypes = propTypes
FlagForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'flag-new',
    constraints: {
      reason: {
        presence: true,
      },
    },
  })
)(FlagForm)
