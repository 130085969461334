import React from 'react'
import PropTypes from 'prop-types'
import { useCommunityText } from 'utils'
import { Content, Header, Footer } from 'components'
import MapPlaceholderCard from './map-placeholder-card'
import SchoolCard from './school-card'
import PartnerCard from './partner-card'

const propTypes = {
  schoolPortalEnabled: PropTypes.bool.isRequired,
  partnerPortalEnabled: PropTypes.bool.isRequired,
}

const defaultProps = {}

function DefaultHome({ schoolPortalEnabled, partnerPortalEnabled }) {
  const t = useCommunityText()

  return (
    <React.Fragment>
      <div className="map">
        <Header hideLogins />
        <Content id="content">
          <header className="map-page-header placeholder-landing">
            <div className="content-block-container">
              <div className="text-block">
                <h1>{t('tagline')}</h1>
                <p>
                  artlook<sup>®</sup> is a data platform that tracks access to
                  arts education for students in our community.
                </p>
                <p>
                  Over the upcoming months, we will launch portals to collect
                  data from schools and outside community arts partners. We will
                  then make this information available through a free, widely
                  accessible map to facilitate joint efforts toward expanding
                  arts education access, quality, and equity for every child, in
                  every grade, in every school.
                </p>
              </div>
            </div>
          </header>
          <div className="cards-block-container">
            <SchoolCard schoolPortalEnabled={schoolPortalEnabled} />
            <PartnerCard partnerPortalEnabled={partnerPortalEnabled} />
            <MapPlaceholderCard />
          </div>
        </Content>
        <Footer hideLogins />
      </div>
    </React.Fragment>
  )
}

DefaultHome.propTypes = propTypes
DefaultHome.defaultProps = defaultProps

export default React.memo(DefaultHome)
