import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import NewPost from '../../views/new-post'
import { Modal } from 'components'
import { Button, Spinner } from 'lp-components'
import { Discussions } from 'educator-portal-components'
import {
  buildSearchQueryString,
  parseSearchQueryObject,
  useCommunity,
  setDiscussionsSortType,
  getBookmarkedIdsByType,
  setCommunityOfPracticeId,
  hasLoadedAssociationForCommunityOfPractice,
} from 'utils'
import {
  DISCUSSIONS_PAGE_SIZE,
  EDUCATOR_PORTAL_ROUTE,
  NON_AFFILIATED_COP_ID,
} from 'config'
import { get, isEmpty, size } from 'lodash'
import * as routerActions from 'react-router-redux'
import * as apiActions from 'api-actions'
import * as flashActions from 'redux-flash'
import { selectors as apiSelectors } from 'lp-redux-api'
import { selectors as globalSelectors } from 'educator-portal-reducer'

const propTypes = {
  posts: PropTypes.arrayOf(Types.post),
  postsCount: PropTypes.number.isRequired,
  fetchPosts: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasLoadFailed: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  postsPageCount: PropTypes.number.isRequired,
  addBookmark: PropTypes.func.isRequired,
  removeBookmark: PropTypes.func.isRequired,
  addLike: PropTypes.func.isRequired,
  destroyLike: PropTypes.func.isRequired,
  bookmarks: PropTypes.arrayOf(Types.bookmark).isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  searchOptions: Types.postsFilterOptions,
  fetchSearchOptions: PropTypes.func.isRequired,
  district: Types.district,
  userDetails: Types.user,
}

const defaultProps = {
  posts: null,
  searchOptions: null,
  district: null,
}

function Forum({
  posts,
  postsCount,
  fetchPosts,
  isLoading,
  hasLoadFailed,
  location: { query: queryHash },
  push,
  postsPageCount,
  addBookmark,
  removeBookmark,
  addLike,
  destroyLike,
  bookmarks,
  flashSuccessMessage,
  flashErrorMessage,
  searchOptions,
  fetchSearchOptions,
  district,
  userDetails,
}) {
  const community = useCommunity()
  const blockList = useMemo(
    () => get(community, 'search.forumFilters.blockList', []),
    [community]
  )
  const hasCorrectPosts = hasLoadedAssociationForCommunityOfPractice(
    posts,
    NON_AFFILIATED_COP_ID
  )
  const [isPostFormOpen, setIsPostFormOpen] = useState(false)
  const [showSpinner, setShowSpinner] = useState(!hasCorrectPosts)
  // Posts are labeled as discussions in the UI
  const postRootPath = `${EDUCATOR_PORTAL_ROUTE}/forum/discussions`

  const {
    query = '',
    filters = {},
    page = 1,
    sortType = Types.LAST_ACTIVITY_SORT_TYPE,
  } = useMemo(() => parseSearchQueryObject(queryHash), [queryHash])
  const postsCountText = useMemo(() => {
    if (isLoading && showSpinner) return '-'
    if (postsCount === 1 || postsCount <= DISCUSSIONS_PAGE_SIZE)
      return postsCount
    return `${size(posts)} of ${postsCount}`
  }, [posts, postsCount, isLoading, showSpinner])

  const searchPosts = useCallback(
    (params, { updateUrl = true } = {}) => {
      setShowSpinner(updateUrl || !hasCorrectPosts) // show spinner if new search or first load
      const paramsWithSort = setDiscussionsSortType(params)
      if (updateUrl) {
        push({
          pathname: `${EDUCATOR_PORTAL_ROUTE}/forum`,
          search: buildSearchQueryString(paramsWithSort),
        })
      }
      // always pass in CoP ID filter
      const paramsWithCoPId = setCommunityOfPracticeId(
        paramsWithSort,
        NON_AFFILIATED_COP_ID
      )
      return fetchPosts(
        paramsWithCoPId,
        getBookmarkedIdsByType(bookmarks, Types.BOOKMARKABLE.POST)
      ).then(() => setShowSpinner(false))
    },
    [fetchPosts, hasCorrectPosts, bookmarks]
  )

  const hasAppliedFilters = !isEmpty(query) || !isEmpty(filters)

  useEffect(() => {
    searchPosts({ query, filters, page, sortType }, { updateUrl: false })
  }, [])

  const requestAddBookmark = useCallback((postId) => {
    addBookmark({
      bookmarkableId: postId,
      bookmarkableType: Types.BOOKMARKABLE.POST,
    })
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }, [])
  const requestRemoveBookmark = useCallback((bookmarkId) => {
    removeBookmark({ bookmarkId, bookmarkableType: Types.BOOKMARKABLE.POST })
      .then(({ successMessage }) => {
        flashSuccessMessage(successMessage)
      })
      .catch(({ errorMessage }) => {
        flashErrorMessage(errorMessage)
      })
  }, [])

  const requestAddLike = useCallback((likeableId) => {
    addLike({
      likeableId,
      likeableType: Types.LIKEABLE.POST,
    }).catch(({ errorMessage }) => {
      flashErrorMessage(errorMessage)
    })
  }, [])

  const requestDestroyLike = useCallback((id) => {
    destroyLike({ id }).catch(({ errorMessage }) => {
      flashErrorMessage(errorMessage)
    })
  }, [])

  useEffect(() => {
    if (!searchOptions && district)
      fetchSearchOptions(
        { blockList },
        Types.SEARCH_OPTIONS_TYPE.FORUM,
        district.id
      )
  }, [searchOptions, blockList, district])

  if (!searchOptions) return <Spinner />

  return (
    <div>
      <div className="heading-container">
        <div className="welcome-message">
          <h2>
            <strong>What's on your mind?</strong>
          </h2>
        </div>
        <div className="new-post-cta">
          <Button onClick={() => setIsPostFormOpen(true)}>
            Start New Discussion
          </Button>
        </div>
      </div>
      <Discussions
        posts={posts}
        numPages={postsPageCount}
        resultsPage={page}
        searchOptions={searchOptions}
        query={query}
        filters={filters}
        searchPosts={searchPosts}
        showSpinner={showSpinner}
        postsCount={postsCount}
        postsCountText={postsCountText}
        sortType={sortType}
        openPostForm={() => setIsPostFormOpen(true)}
        hasLoadFailed={hasLoadFailed}
        isFiltered={hasAppliedFilters}
        bookmarks={bookmarks}
        addBookmark={requestAddBookmark}
        removeBookmark={requestRemoveBookmark}
        addLike={requestAddLike}
        destroyLike={requestDestroyLike}
        postRootPath={postRootPath}
        canPost={true}
        userDetails={userDetails}
      />
      {isPostFormOpen && (
        <Modal onClose={() => setIsPostFormOpen(false)}>
          <NewPost
            onSubmitSuccess={({ id }) => {
              push(`${postRootPath}/${id}`)
              flashSuccessMessage('New discussion created!')
            }}
            onCancel={() => setIsPostFormOpen(false)}
          />
        </Modal>
      )}
    </div>
  )
}

Forum.propTypes = propTypes

Forum.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    isLoading: apiSelectors.isLoading(state, apiActions.fetchPosts),
    hasLoadFailed: apiSelectors.isFailure(state, apiActions.fetchPosts),
    posts: globalSelectors.posts(state),
    postsCount: globalSelectors.postsCount(state),
    postsPageCount: globalSelectors.postsPageCount(state),
    bookmarks: globalSelectors.bookmarks(state),
    searchOptions: globalSelectors.searchOptions(state),
    district: globalSelectors.district(state),
    userDetails: globalSelectors.userDetails(state),
  }
}

const mapDispatchToProps = {
  fetchPosts: apiActions.fetchPosts,
  addBookmark: apiActions.addBookmark,
  removeBookmark: apiActions.removeBookmark,
  addLike: apiActions.addLike,
  destroyLike: apiActions.destroyLike,
  fetchSearchOptions: apiActions.fetchSearchOptions,
  push: routerActions.push,
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Forum)
