import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { ScholarshipsForm } from '../forms'
import { filterUnchanged, useCommunity } from 'utils'
import { uniqBy, isEmpty } from 'lodash'
import { SubmissionError } from 'redux-form'
import ScholarshipsQuestionExample from './scholarships-question-example'
import { SectionBlock } from 'components'

const propTypes = {
  question: Types.surveyQuestion.isRequired,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  scholarships: PropTypes.arrayOf(Types.scholarship).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitFail: PropTypes.func.isRequired,
}

const defaultProps = {}

function compareDisciplineId({ disciplineId }) {
  return parseInt(disciplineId)
}

function convertCentsToDollars(scholarships) {
  return scholarships.map(({ amountCents, ...rest }) => {
    return {
      amountDollars: (amountCents / 100).toFixed(2),
      ...rest,
    }
  })
}

function convertDollarsToCents(scholarships) {
  return scholarships.map(({ amountDollars, ...rest }) => {
    return {
      amountCents: Number(amountDollars) * 100,
      ...rest,
    }
  })
}

function validateDisciplineUniqueness(scholarships) {
  const withoutDuplicates = uniqBy(scholarships, compareDisciplineId)
  if (withoutDuplicates.length === scholarships.length) return

  throw new SubmissionError({
    _error: 'Each discipline can only be selected once',
  })
}

function ScholarshipsQuestion({
  question,
  scholarships,
  onSubmitSuccess,
  ...rest
}) {
  const [editing, setEditing] = useState(false)
  const scholarshipsInDollars = convertCentsToDollars(scholarships)
  const community = useCommunity()
  const canShowScholarshipQuestionExample =
    community.displayText.survey.scholarshipQuestion.displayOptions.showExample

  return (
    <SectionBlock>
      <h2>{question.body}</h2>
      <p className="with-line-breaks">{question.description}</p>
      {isEmpty(scholarships) &&
        !editing &&
        canShowScholarshipQuestionExample && <ScholarshipsQuestionExample />}
      <ScholarshipsForm
        initialValues={{ scholarships: scholarshipsInDollars }}
        beforeSubmit={({ scholarships }) => {
          validateDisciplineUniqueness(scholarships)
          const changedScholarships = filterUnchanged({
            initial: scholarshipsInDollars,
            new: scholarships,
          })
          return { scholarships: convertDollarsToCents(changedScholarships) }
        }}
        onSubmitSuccess={(school) => {
          setEditing(false)
          return onSubmitSuccess(school)
        }}
        editing={editing}
        setEditing={setEditing}
        {...rest}
      />
    </SectionBlock>
  )
}

ScholarshipsQuestion.propTypes = exact(propTypes)
ScholarshipsQuestion.defaultProps = defaultProps

export default React.memo(ScholarshipsQuestion)
