import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Input, Select, SubmitButton, ButtonArea } from 'lp-components'
import { Field, propTypes as formPropTypes } from 'redux-form'

const propTypes = {
  ...formPropTypes,
  folderableOptions: PropTypes.arrayOf(Types.folderableOption).isRequired,
  onCancel: PropTypes.func.isRequired,
}

const defaultProps = {}

function FolderForm({ handleSubmit, submitting, folderableOptions, onCancel }) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field name="name" label="Folder Name" component={Input} required />
      <Field
        name="folderable"
        label="Create In"
        placeholder="Select"
        component={Select}
        options={folderableOptions}
        required
      />
      <ButtonArea>
        <SubmitButton {...{ submitting }}>Create</SubmitButton>
        <button type="button" className="button-grey-light" onClick={onCancel}>
          Cancel
        </button>
      </ButtonArea>
    </form>
  )
}

FolderForm.propTypes = propTypes
FolderForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'folder',
    constraints: {
      name: {
        presence: true,
      },
      folderable: {
        presence: { message: '^Select the location for the folder' },
      },
    },
  })
)(FolderForm)
