import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function HiddenSpan({ children }) {
  return (
    <span className="visually-hidden" aria-hidden>
      {children}
    </span>
  )
}

HiddenSpan.propTypes = propTypes
HiddenSpan.defaultProps = defaultProps

export default pure(HiddenSpan)
