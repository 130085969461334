import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors as globalSchoolSelectors } from 'school-portal-reducer'
import * as Types from 'types'
import { LoadingSpinner, Card, RenderedHtml } from 'components'
// import { format } from 'date-fns'
import { waitFor } from 'lp-hoc'
import { useCommunityText } from 'utils'
import { selectors as surveySelectors } from '../../survey/reducer'
import { get } from 'lodash'
import { ProgramsIcon, SchoolIcon } from 'components/icons'

const propTypes = {
  school: Types.school.isRequired,
  isSurveyOpen: PropTypes.bool.isRequired,
  surveySchoolYear: Types.schoolYear,
  currentSchoolYear: Types.schoolYear.isRequired,
}

const defaultProps = {
  surveySchoolYear: null,
}

function Dashboard({ isSurveyOpen, surveySchoolYear, currentSchoolYear }) {
  const t = useCommunityText()
  const surveySchoolYearNumber = get(surveySchoolYear, 'number')
  return (
    <span>
      <Card>
        <div className="rendered-html">
          <RenderedHtml addAttrs={['target']}>
            {t('schoolPortal.dashboard.welcomeMessage', {
              surveySchoolYearNumber,
            })}
          </RenderedHtml>
        </div>
      </Card>
      <div className="to-do-blocks full-width">
        {isSurveyOpen && (
          <Card>
            {/* <div className="highlight">
              <div className="highlight-inner">
                <div className="progress progress--circle progress--15">
                    <div className="progress-block">
                      <span>20%</span>
                      <p>Complete</p>
                    </div>
                </div>
              </div>
            </div> */}

            <div className="value-block">
              <div className="value">
                <ProgramsIcon />
              </div>
            </div>

            <div className="text-block">
              <h3>{`${surveySchoolYearNumber} Survey`}</h3>
              <p id="continue-survey">
                Click here to complete the artlook Survey! Through this short
                survey, you will share details about your school's arts
                education staffing and instruction, external partnerships, and
                programmatic and resource needs.
              </p>
              <Link
                to="/school-portal/survey"
                className="button-primary button-small"
                aria-describedby="continue-survey"
              >
                Continue
              </Link>
            </div>
          </Card>
        )}
        <Card>
          {/* <div className="highlight">
            <div className="highlight-inner">
              <div className="date">
                <div className="date-block">
                  <span>{ format(updatedAt, 'MM-DD-YY') }</span>
                  <p>Last Updated</p>
                </div>
              </div>
            </div>
          </div> */}

          <div className="value-block">
            <div className="value">
              <SchoolIcon />
            </div>
          </div>
          <div className="text-block">
            <h3>{currentSchoolYear.number} artlook Map Profile</h3>
            <p id="school-updates">
              Click here to provide real-time updates about your school!
            </p>
            <Link
              to="/school-portal/summary"
              className="button-primary button-small"
              aria-describedby="school-updates"
            >
              Update Now
            </Link>
          </div>
        </Card>
      </div>
    </span>
  )
}

Dashboard.propTypes = propTypes
Dashboard.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    school: globalSchoolSelectors.school(state),
    isSurveyOpen: surveySelectors.isSurveyOpen(state),
    surveySchoolYear: globalSchoolSelectors.surveySchoolYear(state),
    currentSchoolYear: globalSelectors.currentSchoolYear(state),
  }
}

export default compose(
  connect(mapStateToProps),
  waitFor('school', LoadingSpinner)
)(Dashboard)
