import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import * as userActions from '../../user/actions'
import * as schoolUserActions from '../../../school-portal/user/actions'
import * as actions from '../actions'
import { connectParams } from 'lp-hoc'
import { Spinner } from 'lp-components'
import * as flashActions from 'redux-flash'
import * as Types from 'types'
import { toLower } from 'lodash'

const propTypes = {
  id: PropTypes.number.isRequired,
  clearDetails: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  signInSuccess: PropTypes.func.isRequired,
  signInSchoolSuccess: PropTypes.func.isRequired,
  switchAccount: PropTypes.func.isRequired,
}

const defaultProps = {}

/**
 * A view that
 * triggers the switchAccount api action and
 * saves the employer selected by the user as
 * the currently active employer for that user
 * which results in an updated route and
 * a flash message displayed to the user.
 */
function SwitchAccount({
  id,
  clearDetails,
  flashErrorMessage,
  flashSuccessMessage,
  signInSuccess,
  signInSchoolSuccess,
  switchAccount,
}) {
  useEffect(() => {
    switchAccount(id)
      .then((userDetails) => {
        const newEmployment = userDetails.employments.find((e) => e.id === id)
        const params = {
          ...userDetails,
          employerId: newEmployment.employable.id,
        }

        if (toLower(newEmployment.employableType) === Types.PARTNER_TYPE) {
          signInSuccess(params)
        } else {
          signInSchoolSuccess(params)
          clearDetails()
        }

        flashSuccessMessage(
          `You are using artlook with your ${newEmployment.employable.name} account.`
        )
      })
      .catch(() => {
        flashErrorMessage(
          'Oops! Something went wrong. Please log out and sign in manually.'
        )
      })
  }, [])

  return <Spinner />
}

SwitchAccount.propTypes = propTypes
SwitchAccount.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  clearDetails: actions.clearDetails,
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  signInSuccess: userActions.signInSuccess,
  signInSchoolSuccess: schoolUserActions.signInSuccess,
  switchAccount: apiActions.switchAccount,
}

export default compose(
  connectParams('id'),
  connect(mapStateToProps, mapDispatchToProps)
)(SwitchAccount)
