import React from 'react'
import PropTypes from 'prop-types'
import ToolbarMenuItem from './toolbar/toolbar-menu-item'
import { pluralize } from 'utils'
import { startCase } from 'lodash'

const propTypes = {
  applyFilter: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  displayName: PropTypes.string,
  getAppliedFilterCount: PropTypes.func.isRequired,
  isFilterUnchanged: PropTypes.func.isRequired,
  isFilterEmpty: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  resetFilter: PropTypes.func.isRequired,
  openFilterId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  openFilter: PropTypes.func.isRequired,
  closeFilter: PropTypes.func.isRequired,
}

const defaultProps = {
  displayName: '',
  openFilterId: null,
}

function FilterMenuItem({
  applyFilter,
  children,
  isFilterUnchanged,
  isFilterEmpty,
  getAppliedFilterCount,
  name,
  resetFilter,
  openFilterId,
  openFilter,
  closeFilter,
  ...rest
}) {
  const appliedCount = getAppliedFilterCount(name)
  return (
    <ToolbarMenuItem
      name={name}
      nameComponent={FilterName}
      disableApply={isFilterUnchanged(name)}
      disableClear={isFilterEmpty(name)}
      onApply={applyFilter}
      onClear={() => resetFilter(name)}
      appliedCount={appliedCount}
      aria-pressed={!!appliedCount}
      isOpen={openFilterId === name}
      open={() => openFilter(name)}
      close={() => closeFilter(name)}
      {...rest}
    >
      {children}
    </ToolbarMenuItem>
  )
}

function FilterName({ name, displayName, appliedCount, isBooleanFilter }) {
  const display = displayName || startCase(name)
  if (!appliedCount) return <span>{display}</span>

  if (isBooleanFilter) {
    return <span aria-label={`${display}: yes applied`}>{display}: Yes</span>
  }

  return (
    <span
      aria-label={`${display}: ${appliedCount} ${pluralize(
        'filter',
        appliedCount
      )} active`}
    >
      {display}: {appliedCount}
    </span>
  )
}

FilterMenuItem.propTypes = propTypes
FilterMenuItem.defaultProps = defaultProps

export default FilterMenuItem
