import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { get, startCase, filter, isEmpty } from 'lodash'
import { InstructorIcon } from 'components/icons'
import { Tooltip } from 'components'

const propTypes = {
  instructors: PropTypes.arrayOf(Types.instructor),
}

const defaultProps = {
  instructors: [],
}

function InstructorCard(instructor) {
  // For the time being, fullTimeEquivalent is not a relevant attribute to use, so only use schedule
  const schedule = startCase(instructor.schedule)
  const primaryFocus = instructor.focuses.find((focus) => focus.primary)
  return (
    <li className="card" key={instructor.id}>
      <InstructorIcon primaryFocusName={get(primaryFocus, 'name')} />
      <div className="text-block">
        <h4>{instructor.displayName}</h4>
        {schedule && <p>{schedule}</p>}
      </div>
    </li>
  )
}

function InstructorList({ instructors }) {
  const artsInstructors = filter(instructors, { nonArts: false })
  const classroomInstructors = filter(instructors, { nonArts: true })
  return (
    <React.Fragment>
      <ul className="instructor-list">{artsInstructors.map(InstructorCard)}</ul>
      {!isEmpty(classroomInstructors) && (
        <React.Fragment>
          <h3>
            Classroom/Non-Arts Instructors
            <Tooltip darkMode>
              A classroom/non-arts instructor is a person who is not primarily
              an arts instructor. This could include, but is not limited to,
              general education teachers who teach the arts to their homeroom
              students.
            </Tooltip>
          </h3>
          <ul className="instructor-list">
            {classroomInstructors.map(InstructorCard)}
          </ul>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

InstructorList.propTypes = propTypes
InstructorList.defaultProps = defaultProps

export default InstructorList
