import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { FilterMenuItem, SearchableCheckboxes, HiddenLegend } from 'components'
import { CheckboxGroup, Checkbox } from 'lp-components'
import { isEmpty } from 'lodash'
import { Field } from 'redux-form'
import { formatOptions, serializeOptions } from 'utils'

const propTypes = {
  filterOptions: PropTypes.object.isRequired,
  applyFilter: PropTypes.func.isRequired,
}

const defaultProps = {}

function PostsFilterFields({ filterOptions, applyFilter, ...filterMenuProps }) {
  return (
    <React.Fragment>
      {!isEmpty(filterOptions.disciplines) && (
        <FilterMenuItem
          name="disciplineIds"
          displayName="Discipline"
          applyFilter={applyFilter}
          {...filterMenuProps}
          className="large-width full-height"
        >
          <Field
            name="disciplineIds"
            component={CheckboxGroup}
            labelComponent={HiddenLegend}
            options={formatOptions(filterOptions.disciplines)}
          />
        </FilterMenuItem>
      )}
      {!isEmpty(filterOptions.topics) && (
        <FilterMenuItem
          name="topicIds"
          displayName="Topic Tag"
          applyFilter={applyFilter}
          {...filterMenuProps}
          className="large-width full-height"
        >
          <SearchableCheckboxes
            fieldName="topicIds"
            options={serializeOptions(filterOptions.topics, {
              key: 'key',
              value: 'value',
            })}
          />
        </FilterMenuItem>
      )}
      {!isEmpty(filterOptions.schoolTypes) && (
        <FilterMenuItem
          name="schoolType"
          applyFilter={applyFilter}
          {...filterMenuProps}
          className="large-width full-height"
        >
          <Field
            name="schoolType"
            component={CheckboxGroup}
            labelComponent={HiddenLegend}
            options={formatOptions(filterOptions.schoolTypes)}
          />
        </FilterMenuItem>
      )}
      {!isEmpty(filterOptions.users) && (
        <FilterMenuItem
          name="userId"
          displayName="Created By"
          applyFilter={applyFilter}
          {...filterMenuProps}
          className="large-width full-height"
        >
          <SearchableCheckboxes
            fieldName="userId"
            options={serializeOptions(filterOptions.users, {
              key: 'key',
              value: 'value',
            })}
          />
        </FilterMenuItem>
      )}
      {!isEmpty(filterOptions.schools) && (
        <FilterMenuItem
          name="userSchoolIds"
          displayName="School"
          applyFilter={applyFilter}
          {...filterMenuProps}
          className="large-width full-height one-column"
        >
          <SearchableCheckboxes
            fieldName="userSchoolIds"
            options={serializeOptions(filterOptions.schools, {
              key: 'key',
              value: 'value',
            })}
          />
        </FilterMenuItem>
      )}
      <div className="bookmark-filter">
        <Field
          name={Types.BOOKMARK_FILTER_NAME}
          component={Checkbox}
          onChange={() => {
            setTimeout(applyFilter, 0)
          }}
        />
      </div>
    </React.Fragment>
  )
}

PostsFilterFields.propTypes = propTypes
PostsFilterFields.defaultProps = defaultProps

export default PostsFilterFields
