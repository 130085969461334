import React, { useMemo, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { SubmitButton, ButtonArea } from 'lp-components'
import { lpForm } from 'lp-form'
import {
  ReadOnlyCellInput,
  NestedAttributesFieldArray,
  SectionBlock,
  SectionHeader,
  InfoModal,
} from 'components'
import { CopyPartnershipModal, AddPartnershipModal } from '../components'
import { useCommunityText } from 'utils/local'
import {
  filterDestroyed,
  persistSubmitSucceeded,
  pluralize,
  useDynamicInfoModal,
  withFormValues,
} from 'utils'
import { sortBy, groupBy, size } from 'lodash'
import * as Types from 'types'
import {
  PARTNERSHIPS_HELPER_TEXT,
  PROGRAM_TYPES_MODAL_CONTENT,
  PROGRAM_TYPES_MODAL_HEADER,
  SUCCESS_FLASH_MODAL_CLOSE_DELAY,
} from 'config'
import * as flashActions from 'redux-flash'
import classnames from 'classnames'
import { selectors as globalSchoolSelectors } from 'school-portal-reducer'
import * as apiActions from 'api-actions'

const propTypes = {
  ...formPropTypes,
  visibleSchoolYearId: PropTypes.number.isRequired,
  programTypes: PropTypes.arrayOf(Types.programType).isRequired,
  partnerOptions: PropTypes.arrayOf(Types.searchSuggestion),
  schoolYears: PropTypes.arrayOf(Types.schoolYear).isRequired,
  otherPartnerships: PropTypes.arrayOf(Types.partnership).isRequired,
  headerComponent: PropTypes.func,
  flashSuccessMessage: PropTypes.func.isRequired,
  educationContactPosition: Types.position,
  fetchPositions: PropTypes.func.isRequired,
}

const defaultProps = {}

function anyProgramsHaveType(programTypes, programType) {
  return programTypes
    .filter((p) => !p._destroy)
    .some((p) => p.programTypeId === programType.id)
}

function destroyProgramsWithType(programs, programType) {
  return programs.map((program) => {
    if (program.programTypeId === programType.id)
      return { ...program, _destroy: true }
    return program
  })
}

function createProgramWithType(programs, programType) {
  return [...programs, { programTypeId: programType.id }]
}

function validatePartnerships(partnerships) {
  if (!partnerships) return
  if (
    partnerships.some(
      (partnership) => !filterDestroyed(partnership.programTypes).length
    )
  )
    return 'Each partner must have at least one program type selected.'
}

function useActivePartnerCallback(partnerOptions) {
  const activePartnerMap = useMemo(
    () => groupBy(partnerOptions, 'id'),
    [partnerOptions]
  )
  return useCallback(
    (organizationId) => {
      return !!activePartnerMap[organizationId]
    },
    [activePartnerMap]
  )
}

function ProgramInput({ input: { value, onChange }, programTypes, disabled }) {
  return (
    <React.Fragment>
      {programTypes.map((programType, i) => {
        const checked = anyProgramsHaveType(value, programType)
        return (
          <td key={i}>
            <input
              type="checkbox"
              disabled={disabled}
              checked={checked}
              onChange={() => {
                const newValue = checked
                  ? destroyProgramsWithType(value, programType)
                  : createProgramWithType(value, programType)
                return onChange(newValue)
              }}
              aria-label={programType.displayName}
            />
          </td>
        )
      })}
    </React.Fragment>
  )
}

function PartnershipFields({ fields, programTypes, disabled }) {
  return (
    <tbody>
      {fields.map((field, i) => {
        const partnership = fields.get(i)
        return (
          <tr
            key={partnership.id || i}
            role="group"
            aria-label={`Progam types provided by ${partnership.organizationName}`}
          >
            <td>
              {!disabled && (
                <button
                  type="button"
                  onClick={() => fields.destroy(i)}
                  className="remove-row"
                  alt={`Remove ${partnership.organizationName} partnership`}
                >
                  ×
                </button>
              )}
              <Field
                name={field + '.organizationName'}
                label={false}
                component={ReadOnlyCellInput}
              />
            </td>
            <Field
              name={field + '.programTypes'}
              component={ProgramInput}
              programTypes={programTypes}
              disabled={disabled}
            />
          </tr>
        )
      })}
    </tbody>
  )
}

function DefaultHeader({ visibleYearNumber }) {
  const schoolYearText = visibleYearNumber || 'the past school year'
  return (
    <React.Fragment>
      <h3>
        Which arts organizations / teaching artists did your school partner with
        in {schoolYearText}?
      </h3>
      <p>
        Please list all the organizations and teaching artists with partnerships
        at your school in {schoolYearText} and the type(s) of program(s)
        provided by each. Only enter organizations that provided direct
        arts-related services, programming, and/or physical resources (e.g.,
        supplies) to your school (exclude money-granting/funding organizations).
      </p>
    </React.Fragment>
  )
}

function PartnershipTableForm({
  programTypes,
  handleSubmit,
  disabled,
  submitting,
  submitFailed,
  reset,
  pristine,
  error,
  schoolYears,
  partnerOptions,
  otherPartnerships,
  values: { partnerships },
  change,
  visibleSchoolYearId,
  headerComponent: QuestionHeader = DefaultHeader,
  flashSuccessMessage,
  fetchPositions,
  educationContactPosition,
}) {
  const isActivePartner = useActivePartnerCallback(partnerOptions)

  const getCommunityText = useCommunityText()
  const helperText =
    getCommunityText('survey.partnershipQuestion.additionalTipsModal') ||
    PARTNERSHIPS_HELPER_TEXT

  // Determine what partnerships exist with _active_ organizations that have not already been added for the current year
  const remainingPreviousPartnerships = useMemo(() => {
    const eligiblePartnerships = otherPartnerships.filter((partnership) => {
      return (
        isActivePartner(partnership.organizationId) &&
        !partnerships.some(
          ({ organizationId }) => organizationId === partnership.organizationId
        )
      )
    })

    return sortBy(eligiblePartnerships, 'organizationName')
  }, [isActivePartner, partnerships.length, otherPartnerships.length])

  const visibleYear = useMemo(() => {
    return schoolYears.find(({ id }) => id === visibleSchoolYearId)
  }, [visibleSchoolYearId, schoolYears])

  const [showInfoModal, infoModalContent, setShowInfoModalWithContent] =
    useDynamicInfoModal()
  const [showAddPartnershipModal, setShowAddPartnershipModal] = useState(false)
  const [showCopyPartnershipModal, setShowCopyPartnershipModal] =
    useState(false)

  useEffect(() => {
    if (!educationContactPosition) fetchPositions()
  }, [educationContactPosition])

  return (
    <SectionBlock>
      <SectionHeader>
        <QuestionHeader visibleYearNumber={visibleYear.number} />
        <p>
          Click{' '}
          <button
            type="button"
            className="link-secondary"
            onClick={() =>
              setShowInfoModalWithContent(PROGRAM_TYPES_MODAL_CONTENT, {
                header: PROGRAM_TYPES_MODAL_HEADER,
              })
            }
            aria-label="View program type definitions for partnerships"
          >
            here
          </button>{' '}
          for a list of program type definitions.
        </p>
        <p>
          Click{' '}
          <button
            type="button"
            className="link-secondary"
            onClick={() =>
              setShowInfoModalWithContent(helperText, {
                header: 'Tips and guidelines for reporting partnerships',
              })
            }
            aria-label="View additional tips and guidelines for partnerships"
          >
            here
          </button>{' '}
          for additional tips and guidelines for reporting partnerships.
        </p>

        {!disabled && (
          <div className="button-area many-buttons">
            <button
              type="button"
              onClick={() => setShowAddPartnershipModal(true)}
              className="button-primary-outline"
            >
              <span aria-hidden>+</span> Add New Partner
            </button>
            <button
              type="button"
              onClick={() => setShowCopyPartnershipModal(true)}
              className="button-primary-outline"
            >
              Copy partners from other school years
            </button>
          </div>
        )}
      </SectionHeader>
      {size(filterDestroyed(partnerships)) > 0 ? (
        <form onSubmit={handleSubmit} disabled={disabled}>
          <table className="school-partner-table">
            <thead>
              <tr>
                <th>Organization Name</th>
                {programTypes.map((programType, i) => (
                  <th key={i}>{programType.displayName}</th>
                ))}
              </tr>
            </thead>
            <NestedAttributesFieldArray
              name="partnerships"
              component={PartnershipFields}
              programTypes={programTypes}
              disabled={disabled}
            />
          </table>
          {/* Submit button is required when a form exists */}
          <div className={classnames({ 'visually-hidden': disabled })}>
            {submitFailed && error && <p className="error-message">{error}</p>}
            <ButtonArea>
              <SubmitButton submitting={submitting} disabled={disabled}>
                Save Response
              </SubmitButton>
              {!pristine && (
                <button
                  type="button"
                  className="button-grey-light"
                  onClick={() => reset()}
                  disabled={disabled}
                >
                  Cancel
                </button>
              )}
            </ButtonArea>
          </div>
        </form>
      ) : (
        <p>
          No partnerships reported for the {visibleYear.number} school year.
        </p>
      )}
      {showAddPartnershipModal && (
        <AddPartnershipModal
          onClose={() => setShowAddPartnershipModal(false)}
          onSubmit={(
            {
              organizationName,
              organizationId,
              organizationContactFirstName,
              organizationContactLastName,
              organizationContactPhone,
              organizationContactEmail,
            },
            pending
          ) => {
            const newPartnership = pending
              ? {
                  organizationName,
                  programTypes: [],
                  contact: {
                    firstName: organizationContactFirstName,
                    lastName: organizationContactLastName,
                    phone: organizationContactPhone,
                    email: organizationContactEmail,
                    positionId: educationContactPosition.id,
                  },
                }
              : { organizationId, organizationName, programTypes: [] }
            change(
              'partnerships',
              sortBy([...partnerships, newPartnership], 'organizationName')
            )
            return newPartnership
          }}
          onSubmitSuccess={({ organizationName }) => {
            setShowAddPartnershipModal(false)
            // Delay to allow modal to close before making announcement (otherwise it will be ignored by VoiceOver)
            setTimeout(
              () =>
                flashSuccessMessage(
                  `Partnership with ${organizationName} added for the ${visibleYear.number} school year`
                ),
              SUCCESS_FLASH_MODAL_CLOSE_DELAY
            )
          }}
          partnerOptions={partnerOptions}
          existingPartnerships={partnerships}
        />
      )}
      {showCopyPartnershipModal && (
        <CopyPartnershipModal
          onClose={() => setShowCopyPartnershipModal(false)}
          onSubmit={({ partnerships: newPartnerships }) => {
            change(
              'partnerships',
              sortBy([...partnerships, ...newPartnerships], 'organizationName')
            )
            return newPartnerships
          }}
          onSubmitSuccess={(partnerships) => {
            setShowCopyPartnershipModal(false)
            const numNewPartnerships = size(partnerships)
            setTimeout(
              () =>
                flashSuccessMessage(
                  `${numNewPartnerships} ${pluralize(
                    'partnership',
                    numNewPartnerships
                  )} copied over for the ${visibleYear.number} school year`
                ),
              SUCCESS_FLASH_MODAL_CLOSE_DELAY
            )
          }}
          partnerships={remainingPreviousPartnerships}
          schoolYears={schoolYears}
        />
      )}
      {showInfoModal && (
        <InfoModal
          onClose={() => setShowInfoModalWithContent(false)}
          content={infoModalContent.content}
          header={infoModalContent.header}
        />
      )}
    </SectionBlock>
  )
}

PartnershipTableForm.propTypes = propTypes
PartnershipTableForm.defaultProps = defaultProps

function convertPendingOrgs({ partnerships }) {
  return {
    partnerships: partnerships.map((partnership) => {
      if (partnership.organizationId) return partnership

      const { organizationName, contact, ...rest } = partnership,
        contactHasInfo =
          contact.firstName ||
          contact.lastName ||
          contact.email ||
          contact.phone

      return {
        ...rest,
        organizationAttributes: {
          name: organizationName,
          contacts: contactHasInfo ? [contact] : [],
        },
      }
    }),
  }
}

function mapStateToProps(state) {
  return {
    educationContactPosition:
      globalSchoolSelectors.educationContactPosition(state),
  }
}

const mapDispatchToProps = {
  flashSuccessMessage: flashActions.flashSuccessMessage,
  fetchPositions: apiActions.fetchPositions,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lpForm({
    name: 'school-partnership-table',
    enableReinitialize: true,
    beforeSubmit: convertPendingOrgs,
    validate: ({ partnerships }) => {
      const error = validatePartnerships(partnerships)
      return { _error: error }
    },
  }),
  withFormValues(),
  persistSubmitSucceeded()
)(PartnershipTableForm)
