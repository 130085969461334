import React from 'react'
import { Route } from 'react-router'
import * as Views from './views'

const Routes = (
  <Route>
    <Route path="sign-in" component={Views.SignIn} />
    <Route path="forgot-password" component={Views.ForgotPassword} />
    <Route
      path="forgot-password-confirmation"
      component={Views.ForgotPasswordConfirmation}
    />
    <Route path="reset-password/:token" component={Views.ResetPassword} />
    <Route exact path="claim-account" component={Views.ClaimAccount} />
    <Route path="claim-account/:token" component={Views.ClaimAccountPassword} />
    <Route
      path="claim-account-confirmation"
      component={Views.ClaimAccountConfirmation}
    />
    <Route path="account-not-found" component={Views.AccountNotFound} />
    <Route path="select-employer" component={Views.SelectEmployer} />
    <Route path="sso-redirect" component={Views.SsoRedirect} />
  </Route>
)

export default Routes
