const KEY_CODES = {
  HOME: '36',
  END: '35',
  LEFT: '37',
  RIGHT: '39',
}

function manageFocus(e) {
  const key = (e.which || e.keyCode || '').toString()

  switch (key) {
    case KEY_CODES.LEFT:
    case KEY_CODES.RIGHT: {
      e.preventDefault()
      const direction = key === KEY_CODES.RIGHT ? 1 : -1
      focusNextControl(e.target, direction)
      break
    }
    case KEY_CODES.HOME:
    case KEY_CODES.END:
      e.preventDefault()
      focusExtremeControl(e.target, key === KEY_CODES.HOME)
      break
    default:
    // do nothing
  }
}

export function focusNextControl(control, direction = 1) {
  const toolbar = getClosestToolbar(control)
  const toolbarItems = getAllToolbarItems(toolbar)

  const currentIndex = toolbarItems.indexOf(control)

  if (currentIndex === -1) return

  const newIndex = getSafeIndex(
    toolbarItems.length,
    currentIndex + 1 * direction
  )

  control.setAttribute('tabindex', '-1')
  focus(toolbarItems[newIndex])
}

// ----- PRIVATE -----
function getClosestToolbar(el) {
  return isToolbar(el) ? el : getClosestToolbar(el.parentElement)
}

function getAllToolbarItems(toolbar) {
  return Array.from(toolbar.querySelectorAll('.toolbar-item'))
}

function isToolbar(el) {
  return el && el.matches('[role="toolbar"]')
}

function focus(el) {
  el.setAttribute('tabindex', '0')
  el.focus()
}

function focusExtremeControl(control, top = true) {
  const toolbar = getClosestToolbar(control)
  const tabs = getAllToolbarItems(toolbar)

  control.setAttribute('tabindex', '-1')
  top ? focus(tabs[0]) : focus(tabs[tabs.length - 1])
}

// Calculate a "safe" index by wrapping around when the array bounds are exceeded
function getSafeIndex(length, newIndex) {
  const maxIndex = length - 1

  if (newIndex < 0) return getSafeIndex(length, length + newIndex)
  if (newIndex > maxIndex) return getSafeIndex(length, length - newIndex)

  return newIndex
}

export default manageFocus
