import React, { useMemo } from 'react'
import {
  Field,
  formValueSelector,
  propTypes as reduxFormPropTypes,
} from 'redux-form'
import { Input, Select, Button, SubmitButton } from 'lp-components'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const formComponentName = 'minutesPerWeekCalculator'

const propTypes = {
  ...reduxFormPropTypes,
  lengthOfPeriod: PropTypes.string.isRequired,
  durationOption: PropTypes.string.isRequired,
  frequencyOption: PropTypes.string.isRequired,
  meetingType: PropTypes.number.isRequired,
  rotationType: PropTypes.number.isRequired,
  result: PropTypes.number.isRequired,
}

const defaultProps = {}

const durationOptions = [
  { key: 'Full year', value: 'full_year' },
  { key: '3 Quarters', value: '3_quarters' },
  { key: '2 Trimesters', value: '2_trimesters' },
  { key: '1 Semester', value: '1_semester' },
  { key: '1 Trimester', value: '1_trimester' },
  { key: '1 Quarter', value: '1_quarter' },
  { key: 'One-fifth of year', value: 'one_fifth_of_year' },
  { key: 'One-sixth of year', value: 'one_sixth_of_year' },
]

const FREQUENCY_DAILY_VALUE = 'daily',
  FREQUENCY_ROTATION_VALUE = 'rotation'

const frequencyOptions = [
  { key: 'Daily', value: FREQUENCY_DAILY_VALUE },
  { key: 'Met as part of rotation', value: FREQUENCY_ROTATION_VALUE },
]

const rotationMeetingTypeOptions = [
  { key: '1 day', value: 1 },
  { key: '2 days', value: 2 },
  { key: '3 days', value: 3 },
  { key: '4 days', value: 4 },
  { key: '5 days', value: 5 },
  { key: '6 days', value: 6 },
  { key: '7 days', value: 7 },
  { key: '8 days', value: 8 },
  { key: '9 days', value: 9 },
]

const rotationFrequencyOptions = [
  { key: '2-day (A|B)', value: 2 },
  { key: '3-day', value: 3 },
  { key: '4-day', value: 4 },
  { key: '5-day', value: 5 },
  { key: '6-day', value: 6 },
  { key: '7-day', value: 7 },
  { key: '8-day', value: 8 },
  { key: '9-day', value: 9 },
  { key: '10-day', value: 10 },
]

const calculateRotationFrequencyCoefficient = (
  rotationMeetingDays,
  rotationFrequency
) => {
  return rotationMeetingDays / rotationFrequency
}

const mapDurationValueToCoefficient = (durationValue) => {
  switch (durationValue) {
    case 'full_year':
      return 1
    case '3_quarters':
      return 0.75
    case '2_trimesters':
    case '1_semester':
      return 0.5
    case '1_trimester':
      return 0.33
    case '1_quarter':
      return 0.25
    case 'one_fifth_of_year':
      return 0.2
    case 'one_sixth_of_year':
      return 0.17
    default:
      return null
  }
}

function CalculationForm({
  lengthOfPeriod,
  durationOption,
  frequencyOption,
  meetingType,
  rotationType,
  result,
  handleSubmit,
  change,
}) {
  const displayRotationFields = frequencyOption === FREQUENCY_ROTATION_VALUE

  const averageMinutesPerWeek = useMemo(() => {
    const classFrequencyCoefficient =
      frequencyOption === FREQUENCY_DAILY_VALUE
        ? 1
        : calculateRotationFrequencyCoefficient(meetingType, rotationType)

    const minutesPerDay = classFrequencyCoefficient * lengthOfPeriod

    const minutesPerWeek = minutesPerDay * 5

    const durationOfClassCoefficient =
      mapDurationValueToCoefficient(durationOption)

    return Math.round(minutesPerWeek * durationOfClassCoefficient)
  }, [
    frequencyOption,
    meetingType,
    rotationType,
    lengthOfPeriod,
    durationOption,
  ])

  return (
    <form id="minutes-per-week-calculator" onSubmit={handleSubmit}>
      <Field
        name="lengthOfPeriod"
        component={Input}
        type="number"
        label="Length of period (in minutes)"
      />
      <Field
        name="durationOption"
        component={Select}
        options={durationOptions}
        label="Duration of class"
      />
      <Field
        name="frequencyOption"
        component={Select}
        options={frequencyOptions}
        label="Frequency"
      />
      {displayRotationFields && (
        <fieldset className="rotation-field-wrapper row">
          <legend> Rotation:</legend>
          <Field
            name="meetingType"
            component={Select}
            options={rotationMeetingTypeOptions}
            className="col-3"
          />
          <Field
            name="rotationType"
            component={Select}
            options={rotationFrequencyOptions}
            className="col-3"
          />
        </fieldset>
      )}
      <Button
        type="button"
        className="link-primary button-primary-outline"
        onClick={() => {
          change('result', averageMinutesPerWeek)
        }}
      >
        Calculate
      </Button>

      <dl aria-atomic="true" aria-live="polite">
        <dt>Minutes per week result</dt>
        <dd> {result} </dd>
      </dl>

      <SubmitButton>Confirm</SubmitButton>
    </form>
  )
}

CalculationForm.propTypes = propTypes
CalculationForm.defaultProps = defaultProps

const calculatorFormValueSelector = formValueSelector(formComponentName)
const mapStateToProps = (state) => {
  return {
    lengthOfPeriod: calculatorFormValueSelector(state, 'lengthOfPeriod'),
    durationOption: calculatorFormValueSelector(state, 'durationOption'),
    frequencyOption: calculatorFormValueSelector(state, 'frequencyOption'),
    meetingType: calculatorFormValueSelector(state, 'meetingType'),
    rotationType: calculatorFormValueSelector(state, 'rotationType'),
    result: calculatorFormValueSelector(state, 'result'),
  }
}

export default compose(
  lpForm({ name: formComponentName }),
  connect(mapStateToProps)
)(CalculationForm)
