import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { startCase } from 'lodash'
import { filterInvalidDOMProps } from 'lp-components/lib/utils'

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

const defaultProps = {
  label: '',
}

function HiddenLabel({ label, name, ...rest }) {
  return (
    <label
      htmlFor={name}
      className="visually-hidden"
      {...filterInvalidDOMProps(rest)}
    >
      {label || startCase(name)}
    </label>
  )
}

HiddenLabel.propTypes = propTypes
HiddenLabel.defaultProps = defaultProps

export default pure(HiddenLabel)
