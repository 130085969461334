import { combineReducers } from 'redux'
import {
  reducer as profileReducer,
  reducerKey as profileReducerKey,
} from './profile'
import { reducer as userReducer, reducerKey as userReducerKey } from './user'
import {
  reducer as globalReducer,
  reducerKey as globalReducerKey,
} from './global-reducer'

const reducerKey = 'partnerPortal'

// Combine reducers
const reducer = combineReducers({
  [profileReducerKey]: profileReducer,
  [userReducerKey]: userReducer,
  [globalReducerKey]: globalReducer,
})

export { reducer, reducerKey }
