import { includes, reduce, toLower, isEmpty, trim } from 'lodash'

function filterOptionGroups(
  optionGroups,
  query,
  { keepEmptyGroups = false } = {}
) {
  // Short circuit logic if empty string
  const normalizedQuery = trim(toLower(query))
  if (normalizedQuery === '') return optionGroups

  return reduce(
    optionGroups,
    (results, options, groupName) => {
      const filteredOptions = options.filter((option) => {
        return includes(toLower(option.key), normalizedQuery)
      })

      if (!keepEmptyGroups && isEmpty(filteredOptions)) return results

      results[groupName] = filteredOptions
      return results
    },
    {}
  )
}

export function createFilterOptionGroupsCallback(optionGroups, options = {}) {
  return (query) => filterOptionGroups(optionGroups, query, options)
}

export default filterOptionGroups
