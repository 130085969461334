import convertCloudinarySrcUrlToFilename from './convert-cloudinary-src-url-to-filename'

// Return attachment object with src and filename for SingleAttachmentInput
// If attachments are undefined, return empty empty strings for src and filename to satisfy prop requirements

function serializeAttachmentForFormField(attachment) {
  if (!attachment) return { src: '', filename: '' }

  const uploadUrl = attachment.uploadUrl
  const filename = convertCloudinarySrcUrlToFilename(uploadUrl)
  return { src: uploadUrl, filename }
}

export default serializeAttachmentForFormField
