import React from 'react'
// import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { onMount } from 'lp-hoc'
import { Content } from 'components'
import * as globalActions from 'partner-portal-actions'

const propTypes = {}

const defaultProps = {}

function AdminRedirect() {
  return <Content>Redirecting...</Content>
}

AdminRedirect.propTypes = propTypes

AdminRedirect.defaultProps = defaultProps

const mapDispatchToProps = {
  setAdminUserAndRedirect: globalActions.setAdminUserAndRedirect,
}

function onComponentDidMount({ location: { query }, setAdminUserAndRedirect }) {
  return setAdminUserAndRedirect(query.token, query.employer_id)
}

export default compose(
  connect(null, mapDispatchToProps),
  onMount(onComponentDidMount)
)(AdminRedirect)
