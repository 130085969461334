import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import * as icons from './basic'
import { get, startCase } from 'lodash'

const propTypes = {
  discipline: PropTypes.string,
}

const defaultProps = {
  discipline: 'programs', // default to a generic icon when no discipline exists
}

function ArtDisciplineIcon({ discipline }) {
  const Icon = get(icons, `${upperCamelCase(discipline)}Icon`)
  return <Icon />
}

// Transforms string to UpperCamelCase (e.g., visual_arts -> VisualArts)
function upperCamelCase(str = '') {
  return startCase(str.trim()).replace(/\s+/, '')
}

ArtDisciplineIcon.propTypes = propTypes
ArtDisciplineIcon.defaultProps = defaultProps

export default pure(ArtDisciplineIcon)
