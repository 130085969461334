import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { Modal } from 'components'
import { FlagForm } from '../forms'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitFail: PropTypes.func.isRequired,
}

const defaultProps = {}

function FlagModal(formProps) {
  return (
    <Modal
      onClose={formProps.onClose}
      hideCloseButton={true}
      shouldCloseOnOverlayClick={false}
    >
      <h2>Flag as Inappropriate</h2>
      <FlagForm {...formProps} />
    </Modal>
  )
}

FlagModal.propTypes = propTypes
FlagModal.defaultProps = defaultProps

export default pure(FlagModal)
