import React from 'react'

/* eslint react/prop-types: 0 no-unused-vars: 0 */

function adaptToReduxForm() {
  return (Wrapped) =>
    function Wrapper({ input: { value, onChange }, meta, ...rest }) {
      return <Wrapped {...{ value, onChange, ...rest }} />
    }
}

export default adaptToReduxForm
