import { compose, withState, withProps } from 'recompose'
import { onUpdate } from 'lp-hoc'

// Enhancement to a form that makes submitSucceeded persist
// even after the form has been reinitialized.

function persistSubmitSucceeded() {
  return compose(
    withState('succeeded', 'setSucceeded', false),
    onUpdate(({ submitSucceeded, succeeded, setSucceeded }) => {
      if (submitSucceeded && !succeeded) return setSucceeded(true)
    }),
    withProps(({ succeeded, pristine }) => {
      return {
        submitSucceeded: succeeded,
        saved: succeeded && pristine,
      }
    })
  )
}

export default persistSubmitSucceeded
