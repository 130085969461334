import { configureApi, composeMiddleware, http } from 'lp-requests'
import { middleware as configureMiddleware } from 'lp-redux-api'
import * as LS from 'local-storage'
import { getCurrentSubdomain, apiUrlBuilder } from 'utils'

export const API_URL = apiUrlBuilder()

/* Configure middleware and api services */

// Before hook - adds auth header if token is included in request options
function addBearerToken({ token }) {
  if (!token) return
  return {
    bearerToken: token,
  }
}

// Before hook - adds subdomain header from LS or url if available
function addSubdomainHeader() {
  const subdomain = getCurrentSubdomain()
  if (!subdomain) return
  return {
    headers: {
      'x-subdomain': subdomain,
    },
  }
}

// If 401 is received, force logout
function onUnauthorized() {
  LS.clearPartnerAuthToken()
  LS.clearSchoolAuthToken()
  window.location.reload()
}

export const config = {
  root: API_URL,
  mode: 'cors',
  before: composeMiddleware(addBearerToken, addSubdomainHeader),
  onUnauthorized,
  successDataPath: 'data',
}

export const middleware = configureMiddleware(http, config)
export const api = configureApi(config)
