import { map, filter } from 'lodash'

// Get IDs of specified type of bookmarked items from array of bookmarks with multiple types

function getBookmarkedIdsByType(bookmarks, bookmarkableType) {
  const bookmarksForType = filter(bookmarks, { bookmarkableType })
  return map(bookmarksForType, 'bookmarkableId')
}

export default getBookmarkedIdsByType
