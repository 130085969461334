import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { lpForm } from 'lp-form'
import { Link } from 'react-router'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { ButtonArea, SubmitButton, Checkbox } from 'lp-components'
import { RenderedHtml, ExternalLink } from 'components'

const propTypes = {
  ...formPropTypes,
  terms: PropTypes.string.isRequired,
  supportEmail: PropTypes.string.isRequired,
  logoutPath: PropTypes.string.isRequired,
}

const defaultProps = {}

function TermsForm({
  handleSubmit,
  submitting,
  terms,
  supportEmail,
  logoutPath,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <h1>Terms of Use</h1>
      <div className="scrollable-content terms-content">
        <RenderedHtml>{terms}</RenderedHtml>
      </div>
      <p>
        You must agree to these terms and conditions in order to access the
        artlook platform. If you have any questions please reach out to{' '}
        <ExternalLink href={`mailto:${supportEmail}`}>
          {supportEmail}
        </ExternalLink>
        .
      </p>
      <Field
        label="I agree to these terms and conditions"
        name="agreeToTerms"
        component={Checkbox}
        data-cy="terms-checkbox"
      />
      <ButtonArea>
        <SubmitButton data-cy="submit-terms" submitting={submitting}>
          Continue
        </SubmitButton>
        <Link to={logoutPath} className="link-secondary">
          Logout
        </Link>
      </ButtonArea>
    </form>
  )
}

TermsForm.propTypes = propTypes
TermsForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'portal-terms',
    constraints: {
      agreeToTerms: {
        presence: { message: '^You must agree to the terms and conditions' },
        inclusion: {
          within: [true],
          message: '^You must agree to the terms and conditions',
        },
      },
    },
  })
)(TermsForm)
