import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { Link } from 'react-router'
import { splitText } from './helpers'

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: Types.searchResultType.isRequired,
  placeholder: PropTypes.bool,
}

const PATHS = {
  [Types.SCHOOL_TYPE]: '/schools',
  [Types.PARTNER_TYPE]: '/partners',
}

function SuggestionListItem({ id, name, type, placeholder }, { query }) {
  const [startText, middleText, endText] = splitText(name, query)
  return placeholder ? (
    <a>
      {name} <strong>"{placeholder}"</strong>
    </a>
  ) : (
    <Link to={`${PATHS[type]}/${id}`} data-cy="suggestion-link">
      {startText}
      <strong>{middleText}</strong>
      {endText}
    </Link>
  )
}

SuggestionListItem.propTypes = propTypes

export default SuggestionListItem
