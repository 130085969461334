import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router'
import { SCHOOL_PORTAL_ROUTE } from 'config'
import * as Types from 'types'

const propTypes = {
  steps: PropTypes.arrayOf(Types.step).isRequired,
  currentStepIndex: PropTypes.number.isRequired,
}

const defaultProps = {}

const isCurrentStep = (index, currentStepIndex) => index === currentStepIndex
const isPreviousStep = (index, currentStepIndex) => currentStepIndex > index
const isLastCompleteStep = (index, currentStepIndex) =>
  currentStepIndex - index === 1

function StepNav({ steps, currentStepIndex }) {
  return (
    <section className="progress-bar">
      <ul>
        {steps.map((step, i) => (
          <li
            key={step.name}
            className={classnames({
              'is-active': isCurrentStep(i, currentStepIndex),
              'is-complete': isPreviousStep(i, currentStepIndex),
              last: isLastCompleteStep(i, currentStepIndex),
            })}
          >
            <Link
              key={step.name}
              to={SCHOOL_PORTAL_ROUTE + '/survey' + step.path}
            >
              <span />
              <p>{step.name}</p>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  )
}

StepNav.propTypes = propTypes
StepNav.defaultProps = defaultProps

export default StepNav
