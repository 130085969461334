import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { modifyProps } from 'lp-hoc'
import { ContentBlock, FormBlock } from 'components'
import { ResetPasswordForm } from '../forms'
import * as apiActions from 'api-actions'
import { SCHOOL_PORTAL_ROUTE } from 'config'
import { displaySubmitFailure } from 'utils'

const propTypes = {
  resetPassword: PropTypes.func.isRequired,
  resetPasswordSuccess: PropTypes.func.isRequired,
}

function ResetPassword({ resetPassword, resetPasswordSuccess }) {
  return (
    <ContentBlock>
      <FormBlock>
        <div className="form-block-inner">
          <h2>Reset Password</h2>
          <ResetPasswordForm
            onSubmit={resetPassword}
            onSubmitSuccess={resetPasswordSuccess}
            onSubmitFail={displaySubmitFailure}
          />
        </div>
      </FormBlock>
    </ContentBlock>
  )
}

ResetPassword.propTypes = propTypes

const mapDispatchToProps = {
  resetPasswordSuccess: () => push(SCHOOL_PORTAL_ROUTE),
  resetPassword: apiActions.resetPassword,
}

function modify({ params: { token }, resetPassword }) {
  return {
    resetPassword: ({ password }) => resetPassword({ password, token }),
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  modifyProps(modify)
)(ResetPassword)
