import React from 'react'

function ThumbsUpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#5B5F66" fillRule="nonzero" transform="translate(3 3)">
          <path d="M14 6.214c0-.722-.573-1.3-1.273-1.3H8.705l.611-2.97a.99.99 0 00-.26-.897L8.38.363 4.194 4.64c-.236.24-.376.565-.376.923v6.5c0 .715.573 1.3 1.273 1.3h5.727c.528 0 .98-.325 1.171-.793l1.922-4.583c.057-.15.089-.305.089-.474v-1.3m-14 7.15h2.545v-7.8H0v7.8z"></path>
        </g>
      </g>
    </svg>
  )
}

export default ThumbsUpIcon
