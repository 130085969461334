import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { pluralize } from 'utils'
import { HeartFilledIcon, HeartOutlineIcon } from 'components/icons/basic'

const propTypes = {
  toggleLike: PropTypes.func.isRequired,
  isLikedbyUser: PropTypes.bool.isRequired,
  likeCount: PropTypes.number.isRequired,
  likeableType: PropTypes.string.isRequired,
  likeableId: PropTypes.number.isRequired,
}

const defaultProps = {}

function LikeButton({
  toggleLike,
  isLikedbyUser,
  likeCount,
  likeableType,
  likeableId,
}) {
  const [liked, setLiked] = useState(isLikedbyUser)
  const [currentLikeCount, setCurrentLikeCount] = useState(likeCount)

  const handleLikeClick = () => {
    setLiked(!liked)
    const countChangeBy = liked ? -1 : 1
    setCurrentLikeCount(currentLikeCount + countChangeBy)
    toggleLike()
  }

  const likesCopy = `${currentLikeCount} ${pluralize('Like', currentLikeCount)}`

  return (
    <div
      role="region"
      id={`like-button-region-${likeableType}-${likeableId}`}
      aria-live="polite"
      aria-atomic="true"
    >
      <button
        type="button"
        className="link-primary like-button"
        onClick={handleLikeClick}
        aria-controls={`like-button-region-${likeableType}-${likeableId}`}
      >
        <React.Fragment>
          {liked ? (
            <HeartFilledIcon aria-label={`Unlike ${likeableType}.`} />
          ) : (
            <HeartOutlineIcon aria-label={`Like ${likeableType}.`} />
          )}
          {currentLikeCount > 0 ? likesCopy : 'Like'}
        </React.Fragment>
      </button>
    </div>
  )
}

LikeButton.propTypes = propTypes
LikeButton.defaultProps = defaultProps

export default pure(LikeButton)
