import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { ContentBlock, LoadingContainer } from 'components'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { connectQuery } from 'lp-hoc'
import { SelectEmployerForm } from '../forms'
import { serializeEmployerOptions, displaySubmitFailure } from 'utils'
import * as actions from '../actions'
import * as Types from 'types'

const propTypes = {
  fetchSchoolUserDetails: PropTypes.func.isRequired,
  signInViaSso: PropTypes.func.isRequired,
  signInSuccess: PropTypes.func.isRequired,
  userDetails: PropTypes.object,
  userId: PropTypes.number,
  token: PropTypes.string,
  employers: PropTypes.arrayOf(Types.employer),
}

const defaultProps = {}

function SelectEmployerContainer({
  employers,
  userDetails,
  signInViaSso,
  token,
  signInSuccess,
  ssoType,
  userId,
}) {
  if (employers.length > 0) {
    return (
      <React.Fragment>
        <p>
          Your account is associated with multiple schools. Please select which
          school you would like to continue signing in with.
        </p>
        {ssoType && (
          <SelectEmployerForm
            onSubmit={(params) =>
              signInViaSso(params).then((response) => ({
                ...params,
                ...response,
                persistSession: true,
              }))
            }
            onSubmitSuccess={signInSuccess}
            onSubmitFail={displaySubmitFailure}
            employers={employers}
            initialValues={{ type: ssoType, userId, token }}
          />
        )}
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      {userDetails && (
        <p>
          We could not find any active schools for this user. Please re-initiate
          the process through your school's portal.
        </p>
      )}
    </React.Fragment>
  )
}

function SelectEmployer({
  fetchSchoolUserDetails,
  userDetails,
  userId,
  signInViaSso,
  token,
  signInSuccess,
}) {
  const ssoType = 'saml'

  useEffect(() => {
    if (userId) fetchSchoolUserDetails(userId)
  }, [fetchSchoolUserDetails, userId])

  const employers = useMemo(
    () => serializeEmployerOptions(userDetails, Types.SCHOOL_TYPE),
    [userDetails]
  )

  return (
    <ContentBlock>
      <h2>Select your School</h2>
      {userId ? (
        <LoadingContainer isLoading={!userDetails}>
          <SelectEmployerContainer
            userDetails={userDetails}
            employers={employers}
            signInViaSso={signInViaSso}
            token={token}
            signInSuccess={signInSuccess}
            ssoType={ssoType}
            userId={userId}
          />
        </LoadingContainer>
      ) : (
        <p>
          We could not find a user by these credentials in our system. Please
          re-initiate the process through your school's portal.
        </p>
      )}
    </ContentBlock>
  )
}

SelectEmployer.propTypes = propTypes
SelectEmployer.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    userDetails: selectors.userDetails(state),
  }
}

const mapDispatchToProps = {
  fetchSchoolUserDetails: apiActions.fetchSchoolUserDetails,
  signInViaSso: apiActions.signInViaSso,
  signInSuccess: actions.signInSuccess,
}

export default compose(
  connectQuery(['userId', 'token']),
  connect(mapStateToProps, mapDispatchToProps)
)(SelectEmployer)
