// App-wide config (routes, env vars) goes here.

export const SCHOOL_PORTAL_PATH = 'school-portal'
export const SCHOOL_PORTAL_ROUTE = '/' + SCHOOL_PORTAL_PATH
export const PARTNER_PORTAL_PATH = 'partner-portal'
export const PARTNER_PORTAL_ROUTE = '/' + PARTNER_PORTAL_PATH
export const EDUCATOR_PORTAL_PATH = 'educator-portal'
export const EDUCATOR_PORTAL_ROUTE = '/' + EDUCATOR_PORTAL_PATH

export const DOMAIN_NAME = process.env.DOMAIN_NAME || 'artlookmap.com'
export const DEFAULT_DOCUMENT_TITLE = 'artlook'

export function isProduction() {
  return process.env.NODE_ENV === 'production'
}

export function isDevelopment() {
  return process.env.NODE_ENV === 'development'
}

export const DEFAULT_CLASS_MONIKER = 'class'

export const SURVEY_ATTEMPT_STATES = {
  ASSIGNED: 'assigned',
  IN_PROGRESS: 'in_progress',
  COMPLETE: 'complete',
}

export const SURVEY_STATES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  ARCHIVED: 'archived',
}

export const INCOMPLETE_SURVEY_SCORE = 5

export const PARTNERSHIPS_HELPER_TEXT = `
  <p>
    Some arts partners may have already reported their partnerships with us, and those will appear here. You may add or delete new partnerships created by you, but not partnerships reported to us by organizations or teaching artists. If you cannot find a partner from our search box or would like to delete a partnership reported by a partner, then please send us an email!
  </p>
  <p>
    The primary focus of this section is to track external arts partnerships. If a teacher at your school or the school itself is providing additional arts programming before or after school, please select "School-Provided Arts Programming" as the name of the partner and choose Out-of-School Time (OST) as the program type. You may select more than one program type, but you may only enter "School-Provided Arts Programming" once.
  </p>
`

export const WHATS_HAPPENING_NOW_TEXT = `
  Use this section any time to keep your school's artlook profile up-to-date! When you share the kinds of arts education programs and resources your school needs most, you're letting others--including arts partners and funders--know how they can help ensure every child has access to a great arts education!
`

export const COURSES_VALIDATION_MESSAGE = `<h2>Notice: This question is incomplete</h2><p> One or more courses are missing data. Your answers have been saved for now, but you will need to return and enter this information before completing the survey.</p>`

export const NONZERO_VALIDATION_MESSAGE = `<h2>Notice: This question is incomplete</h2><p> One or more courses are missing data. Your answers have been saved for now, but you will need to return and enter this information before completing the survey.</p><p>All courses should have non-zero students enrolled and average minutes per week listed. You can remove any rows for courses that your school did not provide during the school year.</p>`

export const COURSE_COURSES_NOTE = `
<p>
<strong>Note on courses:</strong> An arts class meets the following criteria: (1) it is offered to students during the school day, (2) it is categorized as an arts class rather than another content area (e.g., math, science), and (3) it is taught by a certified or endorsed arts instructor.
</p>
`

export const COURSE_MINUTES_NOTE = `
<p>
<strong>Note on minutes:</strong> Please calculate average weekly minutes for elementary classes across the entire school year. If a class only meets for part of the year, include this adjustment in your estimate. For example:
</p>
<p>
<ul class="bulleted-list">
<li>A class that meets for 90 minutes per week in quarters 1 and 2, and not at all in quarters 3 and 4, would have an average of 45 weekly minutes. (90 / 2 = 45)</li>
<li>A 120-minute class that meets once every three weeks across the school year would have an average of 40 weekly minutes. (120 / 3 = 40)</li>
<li>A class that met for 90 minutes per week in semester 1 and 180 minutes per week in semester 2 would have an average of 135 weekly minutes. (90 / 2 + 180 / 2 = 135)</li>
</ul>
</p>
`

export const COURSE_ENROLLMENT_NOTE = `
<p>
<strong>Note on enrollment:</strong> If not pre-populated, please enter the number of students enrolled in this course. If this number has changed over the course of the year, please enter the average number of students in the course across the year, rounded to the nearest whole number.
</p>
`

export const COURSE_INSTRUCTIONAL_DEPTH_NOTE = `
<p>
<strong>Note on instructional depth:</strong> Instructional depth for high school courses refers to the level (beginning, intermediate, or advanced) at which the course is primarily taught. Please select the depth level that best describes each course. AP/IB and Honors courses should be assigned to the appropriate instructional level among these options. Courses for which students receive AP and/or college credit should be considered advanced.
</p>
`

export const COURSE_LISTINGS_NOTE = `
<p>
<strong>Note on course listings:</strong> Full-year courses may only be displayed with the semester 1 course number and name. If the course meets for the full year, you do not need to add the semester 2 course name/number to the list.
</p>
`

export const PROGRAM_TYPES_MODAL_HEADER = `Program Type Definitions`
export const PROGRAM_TYPES_MODAL_CONTENT = `
    <p>
      <strong>Field Trip:</strong> A classroom visit to a cultural
      institution, museum, external arts partner's facilities, or a
      culturally/artistically significant place for an arts-related
      educational experience.
    </p>
    <p>
      <strong>Online / Virtual:</strong> An arts education program,
      resource, or performance that can be accessed or delivered online.
    </p>
    <p>
      <strong>Out-of-School and/or Summer:</strong> Any arts-based
      programming in which students participate outside of traditional
      school hours; this includes programs that take place after school and
      those that are held during the summer months or other breaks.
    </p>
    <p>
      <strong>Performance / Workshop:</strong> A performance, exhibit,
      lecture/demonstration, workshop, or other short-term arts education
      program or event.
    </p>
    <p>
      <strong>Professional Development:</strong> Training for teachers
      and/or administrators that enables staff to learn more about
      arts-based lessons and best practices; often customized to specific
      classroom interests and goals.
    </p>
    <p>
      <strong>Residency:</strong> An artistic program within a school
      through which a teaching artist(s) implements an arts learning
      curriculum over the course of several weeks or months, typically in
      conjunction with classroom instructors.
    </p>
    <p>
      <strong>Resources:</strong> Resources that supplement a school's arts
      education programming (e.g., arts supplies, instruments, lesson plans,
      etc.).
    </p>
    <p>
      <strong>Other:</strong> Services not captured in the above-mentioned
      categories (e.g., parent and community programs and classes, planning
      services, and hosting exhibitions and competitions).
    </p>
`

export const POST_BOOKMARK_MESSAGING = {
  ADD: {
    SUCCESS: 'This discussion was added to your bookmarks.',
    FAIL: 'Oops, unable to add this discussion to your bookmarks.',
  },
  REMOVE: {
    SUCCESS: 'This discussion was removed from your bookmarks.',
    FAIL: 'Oops, unable to remove this discussion from your bookmarks.',
  },
}
export const USER_BOOKMARK_MESSAGING = {
  ADD: {
    SUCCESS: 'This profile was added to your bookmarks.',
    FAIL: 'Oops, unable to add this profile to your bookmarks.',
  },
  REMOVE: {
    SUCCESS: 'This profile was removed from your bookmarks.',
    FAIL: 'Oops, unable to remove this profile from your bookmarks.',
  },
}

export const FLAG_MESSAGE = {
  CREATE: {
    SUCCESS: 'A moderator has been notified you flagged this as inappropriate.',
    FAIL: 'We were unable to notify a moderator, please try again.',
  },
  UNFLAG: {
    SUCCESS: 'The flag has been removed.',
    FAIL: 'There was an issue removing the flag.',
  },
  AGREE: {
    SUCCESS: {
      PROFILE: "The flagged user's access has been suspended.",
      POST: 'The flagged Discussion has been removed.',
      COMMENT: 'The flagged Comment has been removed',
    },
    FAIL: 'There was an issue removing the flagged item.',
  },
  DISAGREE: {
    SUCCESS: {
      PROFILE: 'The flagged user has been marked appropriate.',
      POST: 'The flagged Discussion has been marked appropriate',
      COMMENT: 'The flagged comment has been marked appropriate',
    },
    FAIL: 'There was an issue removing the flag.',
  },
}

export const MEMBERSHIP_MESSAGE = {
  CREATE: {
    SUCCESS: 'Welcome to the Community of Practice',
    FAIL: 'There was an issue granting you access. Please try again.',
  },
  DELETE: {
    SUCCESS: 'You have left the Community of Practice',
    FAIL: 'There was an issue leaving the Community of Practice. Please try again.',
  },
}

export const ANNOUNCEMENT_MESSAGE = {
  DELETE: {
    SUCCESS: 'The announcement has been removed',
    FAIL: 'There was an issue removing the announcement. Please try again.',
  },
}

export const ATTACHMENT_MESSAGE = {
  DELETE: {
    SUCCESS: 'The resource has been removed',
    FAIL: 'There was an issue removing the resource. Please try again.',
  },
}

export const FOLDER_MESSAGE = {
  DELETE: {
    SUCCESS: 'The folder has been removed',
    FAIL: 'There was an issue removing the folder. Please try again.',
  },
}

export const NOTIFICATION_MESSAGE = {
  DELETE: {
    SUCCESS: 'The notification has been deleted',
    FAIL: 'There was a problem deleting the notification. Please try again.',
  },
  MARK_READ: {
    SUCCESS: 'Notification marked as read',
    FAIL: 'There was a problem with your request. Please try again.',
  },
}

export const SEARCH_PAGE_SIZE = 30
export const DIRECTORY_PAGE_SIZE = 30
export const DISCUSSIONS_PAGE_SIZE = 10

export const MAX_CHARACTER_LENGTH = 30000

export const CLOUD_RESIZE_PRESET = 'resize_300_keep_aspect_ratio'

export const SUCCESS_FLASH_MODAL_CLOSE_DELAY = 250

export const NON_AFFILIATED_COP_ID = 0

export const RTE_TOOLBAR_ACTIONS = [
  'bold',
  'italic',
  'strike',
  'link',
  'heading1',
  'bullet',
  'number',
  'outdent',
  'indent',
  'undo',
  'redo',
]
