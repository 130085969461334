import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import * as Types from 'types'

const propTypes = {
  value: PropTypes.string,
  data: PropTypes.shape({
    organizationId: PropTypes.number.isRequired,
    organizationState: PropTypes.string.isRequired,
    schoolId: PropTypes.number.isRequired,
    schoolYearId: PropTypes.number.isRequired,
    type: PropTypes.oneOf([Types.PARTNER_TYPE, Types.SCHOOL_TYPE]).isRequired,
  }),
}

function PartnerTableLinkCell({
  value,
  data: { organizationId, organizationState, schoolId, schoolYearId, type },
}) {
  const url = type === Types.PARTNER_TYPE ? '/partners' : '/schools'
  const id = type === Types.PARTNER_TYPE ? organizationId : schoolId
  return (
    <td>
      {organizationState === 'approved' ? (
        <Link
          to={{
            pathname: `${url}/${id}`,
            search: `?year=${schoolYearId}`,
          }}
          data-cy="partner-table-link"
        >
          {value}
        </Link>
      ) : (
        value
      )}
    </td>
  )
}

PartnerTableLinkCell.propTypes = propTypes

export default PartnerTableLinkCell
