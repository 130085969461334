import React from 'react'
import PropTypes from 'prop-types'
import { Paginator } from 'lp-components'
import { ProfileCard, ErrorFetchState } from './'
import * as Types from 'types'
import { isEmpty } from 'lodash'

const propTypes = {
  users: PropTypes.arrayOf(Types.directoryUser),
  resultsPage: PropTypes.number.isRequired,
  setResultsPage: PropTypes.func.isRequired,
  hasLoadFailed: PropTypes.bool.isRequired,
  numPages: PropTypes.number.isRequired,
  bookmarks: PropTypes.arrayOf(Types.bookmark).isRequired,
  addBookmark: PropTypes.func.isRequired,
  removeBookmark: PropTypes.func.isRequired,
  isModerator: PropTypes.bool.isRequired,
  requestCreateFlag: PropTypes.func.isRequired,
  requestRemoveFlag: PropTypes.func.isRequired,
  currentUserId: PropTypes.number.isRequired,
}

const defaultProps = {
  users: null,
}

function ProfilesList({
  users,
  resultsPage,
  setResultsPage,
  hasLoadFailed,
  numPages,
  currentUserId,
  ...rest
}) {
  if (isEmpty(users))
    return (
      <div className="results-section">
        <div className="empty-state">
          {hasLoadFailed ? (
            <ErrorFetchState />
          ) : (
            <React.Fragment>
              <h3>No Teachers Found</h3>
              <p>Please try another search or different filters.</p>
            </React.Fragment>
          )}
        </div>
      </div>
    )

  return (
    <React.Fragment>
      {users.map((user) => {
        return (
          <ProfileCard
            key={user.id}
            user={user}
            isOwnProfile={user.id === currentUserId}
            {...rest}
          />
        )
      })}
      <Paginator value={resultsPage} max={numPages} onChange={setResultsPage} />
    </React.Fragment>
  )
}

ProfilesList.propTypes = propTypes
ProfilesList.defaultProps = defaultProps

export default ProfilesList
