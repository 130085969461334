import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Checkbox } from 'lp-components'
import * as Types from 'types'
import classnames from 'classnames'

const propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    source: Types.sourceType,
    programs: PropTypes.arrayOf(Types.program).isRequired,
  }).isRequired,
  disableClick: PropTypes.bool.isRequired,
  type: PropTypes.oneOf([Types.PARTNER_TYPE, Types.SCHOOL_TYPE]),
}

const defaultProps = {}

function CheckboxCell({ name, data: { id, programs }, disableClick }) {
  return (
    <td className={classnames({ 'disabled-cell': disableClick })}>
      <Field
        name={`${id}.${name}`}
        label={false}
        component={Checkbox}
        disabled={disableClick}
        className={classnames('checkbox', {
          error: !disableClick && programs.length === 0,
        })}
      />
    </td>
  )
}

CheckboxCell.propTypes = propTypes

CheckboxCell.defaultProps = defaultProps

export default CheckboxCell
