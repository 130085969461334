import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { lpForm } from 'lp-form'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { ButtonArea, SubmitButton, Input, Checkbox } from 'lp-components'
import { PartnerAutoSuggestInput } from 'components'
import * as Types from 'types'
import { Link } from 'react-router'
import { PARTNER_PORTAL_ROUTE } from 'config'

const propTypes = {
  ...formPropTypes,
  partnerOptions: PropTypes.arrayOf(Types.searchSuggestion).isRequired,
}

function SignInForm({ error, handleSubmit, submitting, partnerOptions }) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <h1>Sign in to your partner account</h1>
      {error && <p className="full-error-message">{error}</p>}
      <Field
        name="employerId"
        label="Organization / Teaching Artist Name"
        instructions="Type the first few letters and select the appropriate name from the list"
        component={PartnerAutoSuggestInput}
        suggestions={partnerOptions}
        parse={(option) => (option ? option.id : null)}
      />
      <Field name="email" type="email" component={Input} />
      <Field
        name="password"
        type="password"
        component={Input}
        autoComplete="password"
      />
      <Field
        name="persistSession"
        label="Keep me signed in"
        component={Checkbox}
      />
      <ButtonArea>
        <SubmitButton submitting={submitting}>Sign In</SubmitButton>
        <Link
          to={PARTNER_PORTAL_ROUTE + '/forgot-password'}
          className="link-secondary"
          data-cy="forgot-password-trigger"
        >
          Forgot Password?
        </Link>
      </ButtonArea>
    </form>
  )
}

SignInForm.propTypes = propTypes

export default compose(
  lpForm({
    name: 'partner-portal-sign-in',
    constraints: {
      employerId: { presence: { message: '^Must be a valid organization' } },
      email: { presence: true, email: true },
      password: { presence: true, length: { minimum: 5 } },
    },
    initialValues: {
      persistSession: true,
    },
  })
)(SignInForm)
