import React, { useState, useMemo, useEffect, useRef } from 'react'
import Select from './select'
import { LabeledField, omitLabelProps } from 'lp-components'
import { wrapDisplayName } from 'recompose'
import classnames from 'classnames'
import { format, normalize, serializeOptions } from './helpers'
import { get } from 'lodash'

// Adapter HOC to provide a consistent Field API for react-select
function withLpFormFieldAdapter() {
  return (InputComponent) => {
    function WrappedInputComponent(props) {
      const {
        input: { value, onBlur, onChange },
        options,
        ...rest
      } = props

      const serializedOptions = useMemo(() => {
        return serializeOptions(options)
      }, [options])

      return (
        <InputComponent
          input={{
            ...props.input,
            value: format(serializedOptions, value), // react-select expects the option object(s) passed in
            onChange: (selected) => {
              if (selected === null) {
                const normalizedValue = props.isMulti ? [] : selected
                return onChange(normalizedValue)
              }

              return onChange(normalize(selected))
            }, // only store the selected values in redux
            onBlur: () => onBlur(value), // trigger onBlur with the current normalized value to avoid clearing field state
          }}
          options={serializedOptions} // allow user to pass in options in the standard shapes that other lp-form fields support
          {...rest}
        />
      )
    }
    WrappedInputComponent.displayName = wrapDisplayName(
      InputComponent,
      'withLpFormFieldAdapter'
    )
    return WrappedInputComponent
  }
}

function ComboBox(props) {
  const [isInModal, setIsInModal] = useState(false)
  const { input, className, subLabel, ...rest } = omitLabelProps(props)
  const ref = useRef()
  const labeledFieldId = get(ref, 'current.select.inputRef.id')
  // If no subLabel is provided set the ID to null
  const subLabelId = subLabel ? `${labeledFieldId}-subLabel` : null

  // There are a few issues with the auto-scroll behavior in modals (summarized here: https://github.com/JedWatson/react-select/issues/4088)
  // This is a workaround that adds the recommended props from the official documentation when using in a Portal (https://react-select.com/advanced#portaling)
  useEffect(() => {
    const controlEl = get(ref, 'current.select.controlRef')
    if (!controlEl) return
    setIsInModal(!!controlEl.closest('div.modal'))
  }, [ref])

  const menuProps = isInModal
    ? {
        menuPortalTarget: document.body,
        styles: {
          menuPortal: (base) => ({ ...base, zIndex: 99999999 }),
          menuList: (base) => ({ ...base, maxHeight: '162px' }),
        },
      }
    : {}

  return (
    <LabeledField {...props} id={labeledFieldId}>
      {subLabel && <p id={subLabelId}>{subLabel}</p>}
      <Select
        className={classnames('combo-box', className)}
        aria-describedby={subLabelId}
        ref={ref}
        {...input}
        {...menuProps}
        {...rest}
      />
    </LabeledField>
  )
}

export default withLpFormFieldAdapter()(ComboBox)
