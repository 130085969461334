import React, { useEffect, useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Element, scroller } from 'react-scroll'
import { Content, ExportLink, LoadingSpinner, Footer } from 'components'
import { BaseMapDisplay } from 'components/map-display'
import { SearchResultsList } from '../components'
import { selectors as apiSelectors } from 'lp-redux-api'
import { selectors } from '../reducer'
import { selectors as globalSelectors } from 'global-reducer'
import * as apiActions from 'api-actions'
import * as routerActions from 'react-router-redux'
import {
  buildSearchQueryString,
  displaySubmitFailure,
  parseSearchQueryObject,
  serializeLocation,
  useCommunity,
  useCommunityText,
  pluralize,
} from 'utils'
import { get, size } from 'lodash'
import { LoadingContainer, Select } from 'lp-components'
import { SearchForm } from '../forms'
import { SEARCH_PAGE_SIZE } from 'config'

const propTypes = {
  mostRecentPublishedSchoolYear: Types.schoolYear.isRequired,
  fetchSearchOptions: PropTypes.func.isRequired,
  fetchSearchResults: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(Types.searchResult),
  resultsTotal: PropTypes.number.isRequired,
  searchOptions: PropTypes.object.isRequired,
  isFailedSearch: PropTypes.bool.isRequired,
}

const defaultProps = {}

const resultText = ({ results, total, loading }) => {
  if (loading) return 'Showing - Matches'
  if (total === 1) return `Showing ${total} Match`
  if (total <= SEARCH_PAGE_SIZE) return `Showing ${total} Matches`

  return `Showing ${size(results)} of ${total} Matches`
}

function Search({
  mostRecentPublishedSchoolYear,
  fetchSearchOptions,
  fetchSearchResults,
  isLoading,
  location: { query: queryHash },
  push,
  results,
  resultsTotal,
  searchOptions,
  isFailedSearch,
}) {
  const community = useCommunity()
  const t = useCommunityText()

  const blockList = useMemo(
    () => get(community, 'search.options.blockList', []),
    [community]
  )

  const {
    query = '',
    type = Types.SCHOOL_TYPE,
    filters = {},
    page = 1,
    sortType = Types.RELEVANCE_SORT_TYPE,
    schoolYear = mostRecentPublishedSchoolYear.id,
  } = useMemo(() => parseSearchQueryObject(queryHash), [queryHash])

  const search = useCallback(
    (params, { updateUrl = true } = {}) => {
      if (updateUrl) {
        push({
          pathname: '/search',
          search: buildSearchQueryString(params),
        })
      }

      return fetchSearchResults(params).then(() => {
        scroller.scrollTo('results', { smooth: true })
      })
    },
    [fetchSearchResults]
  )

  // On mount
  useEffect(() => {
    fetchSearchOptions({ blockList })
    search(
      { query, type, filters, page, sortType, schoolYear },
      { updateUrl: false }
    )
  }, [])

  const filtersAvailable = useMemo(
    () => size(searchOptions) > 0,
    [searchOptions]
  )

  const searchResultLocations = useMemo(() => {
    return results.map((result) => serializeLocation(result.type, result))
  }, [results])

  const [selectedLocation, setSelectedLocation] = useState()
  const selectedMapLocationId = get(selectedLocation, 'resourceId')

  if (!filtersAvailable) return <LoadingSpinner />

  return (
    <Content className="all-search-results">
      <div className="map-page-header interior-page short">
        <h1>{t('map.searchHeader')}</h1>
        <SearchForm
          searchOptions={searchOptions}
          initialValues={{
            query,
            type,
            schoolYear,
            filters,
          }}
          name="search-form"
          onSubmit={search}
          onSubmitFail={displaySubmitFailure}
          enableRemoveAll
          searchOnTypeChange
          searchOnYearChange
          hasShownSearchResults={true}
        />
      </div>
      <Element name="results" className="results-page">
        <section className="search-map">
          <LoadingContainer isLoading={isLoading}>
            <BaseMapDisplay
              locations={searchResultLocations}
              autoZoom
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
            />
          </LoadingContainer>
        </section>
        <section id="search-results" className="results-list">
          <div className="actions">
            <span
              role="status"
              className="visually-hidden"
              aria-live="polite"
              aria-atomic="true"
            >
              {isLoading
                ? 'Searching'
                : `Search completed with ${resultsTotal} ${pluralize(
                    'results',
                    resultsTotal
                  )}`}
            </span>
            <p>
              {resultText({
                results: results,
                total: resultsTotal,
                loading: isLoading,
              })}
            </p>
            <div className="additional-options">
              {!isLoading && results.length > 0 && (
                <React.Fragment>
                  <ExportLink
                    query={query}
                    filters={filters}
                    type={type}
                    schoolYear={schoolYear}
                    className="link-black"
                  >
                    Export List
                  </ExportLink>
                  <Select
                    name="resultsSortType"
                    className="sort"
                    label="Sorted by:"
                    options={[
                      { key: 'Relevance', value: Types.RELEVANCE_SORT_TYPE },
                      { key: 'Alphabet', value: Types.ALPHABETICAL_SORT_TYPE },
                    ]}
                    input={{ value: sortType, name: 'resultsSortType' }}
                    onChange={(e) =>
                      search({
                        query,
                        type,
                        filters,
                        schoolYear,
                        sortType: e.target.value,
                      })
                    }
                    meta={{}}
                  />
                </React.Fragment>
              )}
            </div>
          </div>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <SearchResultsList
              isFailedSearch={isFailedSearch}
              results={results}
              searchPage={page}
              setSearchPage={(newPage) => {
                return search({
                  query,
                  type,
                  filters,
                  sortType,
                  schoolYear,
                  page: newPage,
                })
              }}
              schoolYearFilter={schoolYear}
              resultsTotal={resultsTotal}
              selectedResultId={selectedMapLocationId}
            />
          )}
          <Footer />
        </section>
      </Element>
    </Content>
  )
}

Search.propTypes = propTypes
Search.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    mostRecentPublishedSchoolYear:
      globalSelectors.mostRecentPublishedSchoolYear(state),
    isLoading: apiSelectors.isLoading(state, apiActions.fetchSearchResults),
    isFailedSearch: apiSelectors.isFailure(
      state,
      apiActions.fetchSearchResults
    ),
    results: selectors.searchResults(state),
    resultsTotal: selectors.totalSearchResults(state),
    searchOptions: selectors.searchOptions(state),
  }
}

const mapDispatchToProps = {
  fetchSearchOptions: apiActions.fetchSearchOptions,
  fetchSearchResults: apiActions.fetchSearchResults,
  push: routerActions.push,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Search)
