import React from 'react'
import * as Types from 'types'
import { Link } from 'react-router'
import { EDUCATOR_PORTAL_ROUTE } from 'config'
import { formatDate } from 'utils'

const propTypes = {
  communityOfPractice: Types.communityOfPractice.isRequired,
}

const defaultProps = {}

function CommunityOfPracticeCard({ communityOfPractice }) {
  const { id, name, description, lastActivityDate } = communityOfPractice
  return (
    <Link
      to={`${EDUCATOR_PORTAL_ROUTE}/communities-of-practice/${id}`}
      className="link-primary no-underline"
    >
      <div className="card cop-card">
        <h3 className="underline-hover">{name}</h3>
        <p>{description}</p>
        <span className="font-small">
          <em>Last Activity {formatDate(lastActivityDate)}</em>
        </span>
      </div>
    </Link>
  )
}

CommunityOfPracticeCard.propTypes = propTypes
CommunityOfPracticeCard.defaultProps = defaultProps

export default CommunityOfPracticeCard
