import { stringify } from 'query-string'
import { isEmpty, snakeCase, mapKeys } from 'lodash'
import getCurrentSubdomain from './get-current-subdomain'

const { API_HOST, API_PROTOCOL } = process.env
const API_ROOT = buildRootUrl(API_PROTOCOL, API_HOST)

// Builds a url to communicate with community's API
function buildInternalApiUrl(
  { url = '', query = {} } = {},
  { snakeCaseParams = true } = {}
) {
  if (isEmpty(query)) return concatUrl(API_ROOT, url)

  const queryString = buildQueryString(query, snakeCaseParams)
  return concatUrl(API_ROOT, url) + '?' + queryString
}

// ----- PRIVATE -----

// Avoid returning localhost for testing in local development
function buildRootUrl(protocol, host) {
  const safeHost = host.replace(/^localhost/, 'lvh.me')
  const subdomain = getCurrentSubdomain()

  return `${protocol}://${subdomain}.${safeHost}`
}

function buildQueryString(query, snakeCaseParams) {
  const queryToStringify = snakeCaseParams
    ? mapKeys(query, (_, key) => snakeCase(key))
    : query
  return stringify(queryToStringify)
}

function concatUrl(base, url) {
  const safeUrl = url.replace(/^\//, '')
  if (!safeUrl) return base
  return base + '/' + safeUrl
}

export default buildInternalApiUrl
