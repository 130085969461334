import React from 'react'

function BookmarkFilledIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      viewBox="0 0 14 18"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fill="#14A7C2"
          fillRule="nonzero"
          stroke="#14A7C2"
          strokeWidth="1.5"
          className="fill stroke"
        >
          <path d="M2 .75c-.342 0-.65.14-.874.365C.9 1.342.76 1.655.76 2L.75 16.862 7 14.184l6.25 2.679V2c0-.299 0-.591-.3-.885-.3-.294-.528-.365-.933-.365H2z"></path>
        </g>
      </g>
    </svg>
  )
}

export default BookmarkFilledIcon
