import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { getCurrentYear } from 'utils'

const propTypes = {
  hasTerms: PropTypes.bool,
  openTermsModal: PropTypes.func,
}

const defaultProps = {
  hasTerms: false,
  openTermsModal: null,
}

function FooterLegalese({ hasTerms, openTermsModal }) {
  return (
    <p className="copyright">
      © {getCurrentYear()} Parliament. All Rights Reserved.{' '}
      {hasTerms && openTermsModal && (
        <button
          type="button"
          className="link-secondary"
          onClick={openTermsModal}
        >
          Terms & Conditions.
        </button>
      )}
    </p>
  )
}

FooterLegalese.propTypes = exact(propTypes)
FooterLegalese.defaultProps = defaultProps

export default React.memo(FooterLegalese)
