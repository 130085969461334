import serializeAttachmentForFormField from './serialize-attachment-for-form-field'
// Return attachment objects with src and filename for MultiAttachmentInput
// If attachments are undefined, return empty array to satisfy prop requirements

function serializeAttachmentsForFormField(attachments) {
  if (!attachments) return []

  return attachments.map((attachment) => {
    return serializeAttachmentForFormField(attachment)
  })
}

export default serializeAttachmentsForFormField
