import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { compose, withState } from 'recompose'
import { lpForm } from 'lp-form'
import { modifyProps } from 'lp-hoc'
import { Field, FieldArray, propTypes as formPropTypes } from 'redux-form'
import { Select, Input, SubmitButton, ButtonArea } from 'lp-components'
import {
  ReadOnlyCellInput,
  HiddenLabel,
  InteractiveIconInputWithWarnings,
} from 'components'
import * as Types from 'types'
import { startCase, isEmpty, get, range, memoize, includes } from 'lodash'
import {
  filterUnchanged,
  persistSubmitSucceeded,
  serializeOptions,
  useClassMoniker,
  useCommunity,
} from 'utils'
import { MinutesPerWeekCalculatorModal } from '../components'
import classnames from 'classnames'
import CalculatorIcon from 'components/icons/basic/calculator'

const propTypes = {
  ...formPropTypes,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  type: PropTypes.string.isRequired,
  isComboSchool: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  threshold: PropTypes.object,
}

const defaultProps = {}

function serializeInstructionDepthOptions(instructionalDepths) {
  return instructionalDepths.map((name) => ({
    key: startCase(name),
    value: name,
  }))
}

const INTRUCTIONAL_DEPTH_OPTIONS = ['beginning', 'intermediate', 'advanced']

function validateEnrollment(value) {
  if (value < 0) return 'Value cannot be negative'
}

function validateMinutesPerWeek(value) {
  if (!value) return
  if (value < 0 || value > 999) return 'Value must be between 0 and 999'
}

function warnMinutesPerWeek(value, threshold) {
  const { minAverageMinutes, maxAverageMinutes } = threshold
  if (!value || minAverageMinutes == null || maxAverageMinutes == null) return
  if (value < minAverageMinutes || value > maxAverageMinutes)
    return `Value should be between ${minAverageMinutes} and ${maxAverageMinutes}`
}

function isEmptyOrCourseTypeOnly(course) {
  if (isEmpty(course)) return true
  const keys = Object.keys(course)
  if (keys.length === 1 && includes(keys, 'gradesOfferedTo')) return true
}

function filterCourses(courses) {
  return courses.filter((course) => !isEmptyOrCourseTypeOnly(course))
}

const validateCourse = memoize((validator) => {
  return function validate(value, allValues, props, name) {
    const courseKey = name.split('.')[0]
    const course = get(allValues, courseKey)
    if (isEmptyOrCourseTypeOnly(course)) return
    return validator(value, allValues, props, name)
  }
})

const warnCourses = memoize((threshold) => {
  if (!threshold) return
  return function warn(value) {
    return warnMinutesPerWeek(value, threshold)
  }
})

const defaultCourseObject = (type) => ({ gradesOfferedTo: type })

function validatePresence(value) {
  if (!value) return 'Value required'
}

function CoursesFieldsRow({
  field,
  disciplines,
  editing,
  onDelete,
  isHighSchool,
  threshold,
  openCalculator,
  displayOptions,
}) {
  const disciplineOptions = useMemo(
    () => serializeOptions(disciplines),
    [disciplines]
  )

  const calculatorIconAriaText =
    'Average minutes per week calculator for ' + startCase(field)

  return (
    <div className="form-table-row">
      <Field
        name={field + '.gradesOfferedTo'}
        label={false}
        className="hide"
        component={Input}
        hidden
      />
      {displayOptions.showClassNumber && (
        <div className="form-table-cell">
          <Field
            name={field + '.classNumber'}
            validate={validateCourse(validatePresence)}
            labelComponent={HiddenLabel}
            component={editing ? Input : ReadOnlyCellInput}
          />
        </div>
      )}
      <div className="form-table-cell">
        <Field
          name={field + '.name'}
          validate={validateCourse(validatePresence)}
          labelComponent={HiddenLabel}
          component={editing ? Input : ReadOnlyCellInput}
        />
      </div>
      <div className="form-table-cell">
        <Field
          name={field + '.disciplineId'}
          validate={validateCourse(validatePresence)}
          placeholder="Select"
          labelComponent={HiddenLabel}
          options={disciplineOptions}
          component={editing ? Select : ReadOnlyCellInput}
        />
      </div>
      <div className="form-table-cell">
        <Field
          name={field + '.enrollment'}
          validate={validateCourse(validateEnrollment)}
          labelComponent={HiddenLabel}
          type="number"
          component={editing ? Input : ReadOnlyCellInput}
        />
      </div>
      {isHighSchool ? (
        <div className="form-table-cell">
          <Field
            name={field + '.instructionalDepth'}
            placeholder="Select"
            labelComponent={HiddenLabel}
            options={serializeInstructionDepthOptions(
              INTRUCTIONAL_DEPTH_OPTIONS
            )}
            component={editing ? Select : ReadOnlyCellInput}
          />
        </div>
      ) : (
        <div className="form-table-cell">
          <Field
            name={field + '.averageMinutesPerWeek'}
            validate={validateCourse(validateMinutesPerWeek)}
            title={'Average Minutes per Week for ' + startCase(field)}
            warn={warnCourses(threshold)}
            labelComponent={HiddenLabel}
            type="number"
            onIconClick={openCalculator}
            icon={editing ? CalculatorIcon : null}
            iconTitle={calculatorIconAriaText}
            iconAriaLabel={calculatorIconAriaText}
            component={
              editing ? InteractiveIconInputWithWarnings : ReadOnlyCellInput
            }
          ></Field>
        </div>
      )}
      <div className="form-table-cell">
        {editing && (
          <button
            type="button"
            className="remove-row"
            onClick={onDelete}
            aria-label={'Remove ' + startCase(field)}
          >
            ×
          </button>
        )}
      </div>
    </div>
  )
}

function CoursesFields({
  isHighSchool,
  fields,
  disciplines,
  editing,
  type,
  threshold,
  setMinutesPerWeekCalculatorTarget,
  displayOptions,
}) {
  const classText = useClassMoniker()
  return (
    <React.Fragment>
      <div className="scrollable-table">
        <div className="form-table">
          {fields.length > 0 && (
            <div className="form-table-heading">
              <div className="form-table-row">
                {displayOptions.showClassNumber && (
                  <div className="form-table-head">
                    {classText('{{ ClassMoniker }} Number')}
                  </div>
                )}
                <div className="form-table-head class-name-header">
                  {classText('{{ ClassMoniker }} Name')}
                </div>
                <div className="form-table-head">Discipline</div>
                <div className="form-table-head">Students Enrolled</div>
                {isHighSchool ? (
                  <div className="form-table-head">Instructional Depth</div>
                ) : (
                  <>
                    <div className="form-table-head average-minutes-header">
                      Average Minutes per Week
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          <div className="form-table-body">
            {fields.map((field, i) => (
              <CoursesFieldsRow
                key={i}
                field={field}
                disciplines={disciplines}
                editing={editing}
                onDelete={() => fields.remove(i)}
                isHighSchool={isHighSchool}
                threshold={threshold}
                displayOptions={displayOptions}
                openCalculator={() => {
                  setMinutesPerWeekCalculatorTarget(
                    field + '.averageMinutesPerWeek'
                  )
                }}
              />
            ))}
          </div>
        </div>
      </div>
      {editing && (
        <button
          type="button"
          className="link-black"
          onClick={() =>
            range(10).forEach(() => fields.push(defaultCourseObject(type)))
          }
        >
          <span aria-hidden>+</span> Add More Courses
        </button>
      )}
    </React.Fragment>
  )
}

function CoursesForm({
  handleSubmit,
  disciplines,
  saved,
  submitting,
  editing,
  setEditing,
  reset,
  isHighSchool,
  type,
  readOnly,
  threshold,
  change,
}) {
  const showEditButton = !editing && !readOnly
  const community = useCommunity()

  const [minutesPerWeekCalculatorTarget, setMinutesPerWeekCalculatorTarget] =
    useState('')

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} noValidate disabled={!editing}>
        <FieldArray
          name="courses"
          isHighSchool={isHighSchool}
          editing={editing}
          setEditing={setEditing}
          disciplines={disciplines}
          component={CoursesFields}
          type={type}
          threshold={threshold}
          displayOptions={
            community.displayText.survey.staffingQuestion.displayOptions
          }
          setMinutesPerWeekCalculatorTarget={setMinutesPerWeekCalculatorTarget}
        />
        <ButtonArea>
          {/* Submit button is required when a form exists */}
          <div className={classnames({ 'visually-hidden': !editing })}>
            <SubmitButton
              {...{ pristine: saved, submitting }}
              disabled={!editing}
            >
              Save Response
            </SubmitButton>
            <button
              type="button"
              className="button-grey-light"
              onClick={() => {
                reset()
                setEditing(false)
              }}
              disabled={!editing}
            >
              Cancel
            </button>
          </div>
          {showEditButton && (
            <button
              type="button"
              className="button-grey-light"
              onClick={() => setEditing(true)}
            >
              Edit Response
            </button>
          )}
        </ButtonArea>
      </form>

      <MinutesPerWeekCalculatorModal
        className="courses-form-minutes-per-week-calculator-modal"
        isOpen={minutesPerWeekCalculatorTarget !== ''}
        onClose={() => {
          setMinutesPerWeekCalculatorTarget('')
        }}
        onSubmit={({ result }) => {
          return change(minutesPerWeekCalculatorTarget, result)
        }}
        onSubmitSuccess={() => {
          setMinutesPerWeekCalculatorTarget('')
        }}
      />
    </React.Fragment>
  )
}

CoursesForm.propTypes = propTypes
CoursesForm.defaultProps = defaultProps

// Clear out _unchanged_ resources
function modifyBeforeSubmit({ initialValues, type }) {
  return {
    name: `school-courses-${type}`,
    beforeSubmit: ({ courses }) => {
      const changedResources = filterUnchanged({
        initial: initialValues.courses,
        new: filterCourses(courses),
      })
      return { courses: changedResources }
    },
  }
}

// Set editing to false on success
function modifyOnSubmitSuccess({ onSubmitSuccess, setEditing }) {
  return {
    onSubmitSuccess: (...args) => {
      setEditing(false)
      return onSubmitSuccess(...args)
    },
  }
}

export default compose(
  withState('editing', 'setEditing', false),
  modifyProps(modifyBeforeSubmit),
  modifyProps(modifyOnSubmitSuccess),
  lpForm({
    enableReinitialize: true,
  }),
  persistSubmitSucceeded()
)(CoursesForm)
