import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Select, SubmitButton } from 'lp-components'
import { Field } from 'redux-form'

const propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  employers: PropTypes.array,
}

const defaultProps = {
  error: '',
  initialValues: {},
}

function SelectEmployerForm({ error, handleSubmit, submitting, employers }) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      {error && <p className="full-error-mesage">{error}</p>}
      <Field
        name="employeeId"
        label="School Name"
        component={Select}
        options={employers}
        placeholder={'Select'}
        enablePlaceholderOption
        className="select-employer"
      />
      <SubmitButton submitting={submitting}>Complete Sign In</SubmitButton>
    </form>
  )
}

SelectEmployerForm.propTypes = propTypes
SelectEmployerForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'SelectEmployerForm',
    constraints: {
      employeeId: { presence: { message: '^Must select a school' } },
    },
  })
)(SelectEmployerForm)
