import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import {
  CheckboxGroup,
  SubmitButton,
  ButtonArea,
  InputError,
} from 'lp-components'
import {
  Field,
  propTypes as formPropTypes,
  formValueSelector,
} from 'redux-form'
import {
  parseIdsToObjects,
  formatObjectsToIds,
  persistSubmitSucceeded,
  serializeOptions,
  yankBy,
  isOtherType,
  getNoneTypeId,
  orderLastBy,
  isNoneType,
} from 'utils'
import { HiddenLegend, CheckboxFieldset, CheckboxWithText } from 'components'
import { first, flow } from 'lodash'

const propTypes = {
  ...formPropTypes,
  governanceTypes: PropTypes.arrayOf(Types.communityEnumerable),
  governances: PropTypes.arrayOf(Types.schoolGovernance),
}

const defaultProps = {
  governances: undefined,
}

const FORM_NAME = 'school-governances-general'

function GovernancesForm({
  governanceTypes,
  handleSubmit,
  saved,
  submitting,
  change,
  governances,
  error,
  submitFailed,
}) {
  const [otherGovernanceType, definedGovernanceTypes] = useMemo(
    () => yankBy(governanceTypes, isOtherType),
    [governanceTypes]
  )
  const noneTypeId = useMemo(
    () => getNoneTypeId(governanceTypes),
    [governanceTypes]
  )
  const options = useMemo(
    () =>
      flow([orderLastBy(isNoneType), serializeOptions])(definedGovernanceTypes),
    [definedGovernanceTypes]
  )

  const filterValues = useCallback(
    (values) => {
      // Remove other values if None type was just selected
      if (first(values) === noneTypeId) {
        change('other.otherText', '')
        return [noneTypeId]
      }
      // Otherwise remove None Type
      return values.filter((val) => val !== noneTypeId)
    },
    [noneTypeId, change]
  )

  const deselectNone = useCallback(() => {
    const rest = governances.filter(
      ({ governanceId }) => governanceId !== noneTypeId
    )
    change('governances', rest)
  }, [change, noneTypeId, governances])

  return (
    <form onSubmit={handleSubmit} noValidate>
      <CheckboxFieldset invalid={!!error && submitFailed}>
        <Field
          name="governances"
          component={CheckboxGroup}
          options={options}
          labelComponent={HiddenLegend}
          format={formatObjectsToIds('governanceId')}
          parse={(values) =>
            parseIdsToObjects('governanceId', filterValues(values))
          }
        />
        {otherGovernanceType && (
          <Field
            name="other.otherText"
            label={otherGovernanceType.displayName}
            placeholder="Description..."
            component={CheckboxWithText}
            className="CheckboxGroup full-width-other"
            onChange={deselectNone}
          />
        )}
        {error && submitFailed && <InputError error={error} touched invalid />}
      </CheckboxFieldset>
      <ButtonArea>
        <SubmitButton {...{ pristine: saved, submitting }}>
          Save Response
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

GovernancesForm.propTypes = propTypes
GovernancesForm.defaultProps = defaultProps

const selectFormValue = formValueSelector(FORM_NAME)

function mapStateToProps(state) {
  return {
    governances: selectFormValue(state, 'governances'),
  }
}

export default compose(
  connect(mapStateToProps),
  lpForm({
    name: FORM_NAME,
    enableReinitialize: true,
    constraints: {
      'other.otherText': {
        exclusion: {
          within: [' '],
          message: '^Description is required',
        },
      },
    },
  }),
  persistSubmitSucceeded()
)(GovernancesForm)
