import convertToNestedAttributes from './convert-to-nested-attributes'
import addSchoolYearToParams from './add-school-year-to-params'
import toHasManyAttribute from './to-has-many-attribute'
import mapAllocations from './map-allocations'
import { flow } from 'lodash'
import { update, get, set } from 'lodash/fp'
import * as Types from 'types'

function setDistrictFunding(school) {
  const funding = get('detail.districtFunding', school)
  if (!funding) return school
  return set('detail.districtFunding', Number(funding.fractional), school)
}

export function serializeSchoolParams(school, schoolYear) {
  const isRequest = school.type === Types.REQUEST_FORM_TYPE
  return flow(
    setDistrictFunding,
    toHasManyAttribute(['detail']),
    update('partnerships', (partnerships) =>
      partnerships ? partnerships.map(mapAllocations) : []
    ),
    (params) => addSchoolYearToParams(params, schoolYear),
    (params) =>
      mapAllocations(params, { key: isRequest ? 'requests' : 'allocations' }),
    convertToNestedAttributes
  )(school)
}

export default serializeSchoolParams
