import { get, toLower } from 'lodash'
const { PARTNER_PORTAL_OVERRIDE } = process.env

function isPartnerPortalEnabled(communityConfig) {
  return (
    get(communityConfig, 'partnerPortalEnabled') ||
    toLower(PARTNER_PORTAL_OVERRIDE) === 'true'
  )
}

export default isPartnerPortalEnabled
