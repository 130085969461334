import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import Toolbar, { ToolbarItem } from 'components/toolbar'
import SchoolSearchFilterFields from './school-search-filter-fields'
import PartnerSearchFilterFields from './partner-search-filter-fields'
import { get, isEqual, isEmpty, mapValues, pick } from 'lodash'
import { deepCount } from 'utils'
import classnames from 'classnames'

const propTypes = {
  appliedFilters: PropTypes.object,
  applyFilter: PropTypes.func.isRequired,
  enableRemoveAll: PropTypes.bool.isRequired,
  filterOptions: PropTypes.object.isRequired,
  removeAllFilters: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  searchType: PropTypes.string.isRequired,
  selectedFilters: PropTypes.object,
  hasShownSearchResults: PropTypes.bool,
}

const defaultProps = {
  appliedFilters: null,
  selectedFilters: null,
}

function SearchFilterToolbar({
  appliedFilters,
  enableRemoveAll,
  removeAllFilters,
  searchType,
  selectedFilters,
  hasShownSearchResults,
  ...rest
}) {
  const isFilterUnchanged = useCallback(
    (filterName) => {
      return isEqual(
        get(appliedFilters, filterName),
        get(selectedFilters, filterName)
      )
    },
    [appliedFilters, selectedFilters]
  )

  const isFilterEmpty = useCallback(
    (filterName) => {
      return isEmpty(get(selectedFilters, filterName))
    },
    [selectedFilters]
  )

  const appliedFilterCounts = useMemo(() => {
    return mapValues(appliedFilters, deepCount)
  }, [appliedFilters])

  const getAppliedFilterCount = useCallback(
    (filterName, { ignorePaths = [] } = {}) => {
      const filterCount = get(appliedFilterCounts, filterName)
      if (isEmpty(ignorePaths)) return filterCount

      const filtersToIgnore = pick(get(appliedFilters, filterName), ignorePaths)
      return filterCount - deepCount(filtersToIgnore)
    },
    [appliedFilters, appliedFilterCounts]
  )

  return (
    <React.Fragment>
      {/* Render a toolbar for each set of fields to allow for the tabindex on the first item to be set */}
      {searchType === Types.SCHOOL_TYPE ? (
        <FilterToolbar hasShownSearchResults={hasShownSearchResults}>
          {(openFilterId, openFilter, closeFilter) => (
            <React.Fragment>
              <SchoolSearchFilterFields
                getAppliedFilterCount={getAppliedFilterCount}
                isFilterEmpty={isFilterEmpty}
                isFilterUnchanged={isFilterUnchanged}
                openFilterId={openFilterId}
                openFilter={openFilter}
                closeFilter={closeFilter}
                {...rest}
              />
              {enableRemoveAll && (
                <ToolbarItem
                  name="Clear Filters"
                  className="link-black clear"
                  onClick={removeAllFilters}
                />
              )}
            </React.Fragment>
          )}
        </FilterToolbar>
      ) : (
        <FilterToolbar hasShownSearchResults={hasShownSearchResults}>
          {(openFilterId, openFilter, closeFilter) => (
            <React.Fragment>
              <PartnerSearchFilterFields
                getAppliedFilterCount={getAppliedFilterCount}
                isFilterEmpty={isFilterEmpty}
                isFilterUnchanged={isFilterUnchanged}
                openFilterId={openFilterId}
                openFilter={openFilter}
                closeFilter={closeFilter}
                {...rest}
              />
              {enableRemoveAll && (
                <ToolbarItem
                  name="Clear Filters"
                  className="link-black clear"
                  onClick={removeAllFilters}
                />
              )}
            </React.Fragment>
          )}
        </FilterToolbar>
      )}
    </React.Fragment>
  )
}

function FilterToolbar({ hasShownSearchResults, children }) {
  const [openFilterId, setOpenFilterId] = useState(null)

  const openFilter = useCallback((id) => {
    return setOpenFilterId(id)
  }, [])

  const closeFilter = useCallback(
    (id) => {
      if (id !== openFilterId) return
      return setOpenFilterId(null)
    },
    [openFilterId]
  )

  const ariaControls = hasShownSearchResults ? 'search-results' : null

  return (
    <div
      className={classnames('filter-content', {
        'filter-open': !!openFilterId,
      })}
    >
      <Toolbar
        className="filter-wrapper filters"
        aria-label="Search Filtering"
        aria-controls={ariaControls}
        data-cy="search-filters"
      >
        <p>Advanced Filters:</p>
        {children(openFilterId, openFilter, closeFilter)}
      </Toolbar>
    </div>
  )
}

SearchFilterToolbar.propTypes = propTypes
SearchFilterToolbar.defaultProps = defaultProps

export default SearchFilterToolbar
