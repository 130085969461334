import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ExternalLink } from 'components'
import { PaperclipIcon } from 'components/icons/basic'
import { convertCloudinarySrcUrlToFilename } from 'utils'

const propTypes = {
  attachments: PropTypes.arrayOf(Types.attachment).isRequired,
}

const defaultProps = {}

function Attachments({ attachments }) {
  return (
    <div className="attachments">
      <span className="attachments-icon" aria-hidden>
        <PaperclipIcon />
      </span>
      <div className="attachment-links">
        {attachments.map(({ uploadUrl }) => (
          <span key={uploadUrl} className="attachment-link">
            <ExternalLink href={uploadUrl}>
              {convertCloudinarySrcUrlToFilename(uploadUrl)}
            </ExternalLink>
          </span>
        ))}
      </div>
    </div>
  )
}

Attachments.propTypes = exact(propTypes)
Attachments.defaultProps = defaultProps

export default pure(Attachments)
