import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { SubmitButton } from 'lp-components'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { HiddenLegend, RadioGroup } from 'components'
import {
  parseIdToObject,
  formatObjectToId,
  persistSubmitSucceeded,
  serializeOptions,
} from 'utils'

const propTypes = {
  ...formPropTypes,
  standardTypes: PropTypes.arrayOf(Types.communityEnumerable),
  ariaLabelledby: PropTypes.string.isRequired,
}

const defaultProps = {}

function StandardsForm({
  standardTypes,
  handleSubmit,
  submitting,
  saved,
  ariaLabelledby,
}) {
  const options = useMemo(() => {
    return serializeOptions(standardTypes)
  }, [standardTypes])

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field
        name="standard"
        component={RadioGroup}
        options={options}
        labelComponent={HiddenLegend}
        format={formatObjectToId('standardId')}
        parse={parseIdToObject('standardId')}
        ariaLabelledby={ariaLabelledby}
      />
      <div className="button-wrapper">
        <SubmitButton {...{ pristine: saved, submitting }}>
          Save Response
        </SubmitButton>
      </div>
    </form>
  )
}

StandardsForm.propTypes = propTypes
StandardsForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'school-standards-general',
    enableReinitialize: true,
  }),
  persistSubmitSucceeded()
)(StandardsForm)
