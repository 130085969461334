import React from 'react'
import { Content, ExternalLink, FooterLegalese } from 'components'
import { CommunityCard } from '../components'
import { Link } from 'react-scroll'
import Typing from 'react-typing-animation'
import { sortBy } from 'lodash'

import homeHero from 'images/map/landing/home-hero.jpg'
import image1 from 'images/home/image1.jpg'
import image2 from 'images/home/image2.jpg'
import image3 from 'images/home/image3.jpg'

import logo from 'images/artlook-logo-white.svg'
import drivechange from 'images/home/drive-change.svg'
import map from 'images/home/map.svg'
import computer from 'images/home/computer.png'
import drawingillustration from 'images/home/drawingillustration.svg'
import chart from 'images/home/charts.svg'
import puzzle from 'images/home/puzzle.svg'

import {
  Alabama,
  Chicago,
  Houston,
  Miami,
  Oregon,
  Sacramento,
  SouthwesternPA,
  NWArkansas,
  Baltimore,
} from 'community-configs'

const sortedCommunities = sortBy(
  [
    Alabama,
    Chicago,
    Houston,
    Miami,
    Oregon,
    Sacramento,
    SouthwesternPA,
    NWArkansas,
    Baltimore,
  ],
  'position'
)

const propTypes = {}

const defaultProps = {}

function Home() {
  return (
    <Content className="home">
      <nav className="home-navigation">
        <img src={logo} alt="artlook" />
        <ul>
          <li>
            <Link to="how-it-works" smooth hashSpy>
              How it Works
            </Link>
          </li>
          <li>
            <Link to="our-communities" smooth hashSpy>
              Our Communities
            </Link>
          </li>
          <li>
            <Link to="contact" smooth hashSpy>
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
      <header className="home-page-header">
        <div className="content-block-container">
          <div className="text-block">
            <h1 aria-label="A pathway to arts equity for every child, every grade, and every school.">
              A pathway to arts equity for
            </h1>
            <Typing
              className="typewriter-container"
              startDelay={1000}
              speed={100}
              aria-hidden
            >
              <div>Every Child.</div>
              <Typing.Delay ms={500} />
              <div>Every Grade.</div>
              <Typing.Delay ms={500} />
              <div>Every School.</div>
              <Typing.Delay ms={1500} />
            </Typing>
          </div>
        </div>
        <div
          className="image-block"
          style={{ background: `url(${homeHero}) no-repeat center` }}
        ></div>
        <ul className="cb-slideshow">
          <li>
            <span>Image 01</span>
          </li>
          <li>
            <span>Image 02</span>
          </li>
          <li>
            <span>Image 03</span>
          </li>
          <li>
            <span>Image 04</span>
          </li>
          <li>
            <span>Image 05</span>
          </li>
          <li>
            <span>Image 06</span>
          </li>
        </ul>
      </header>
      <section className="track-section">
        <div className="track-block-container">
          <div className="text-block">
            <h2>Tracking Arts for Kids Across America</h2>
            <p>
              artlook<sup>®</sup> is a data platform that helps cities across
              the U.S. track access to the arts for students in their schools.
              Real-time insights help support equity in arts staffing, programs
              and funding for all students.
            </p>
          </div>
        </div>
        <img src={drawingillustration} alt="" />
      </section>

      <section id="how-it-works">
        <div className="how-it-works-block-container">
          <h2>How it Works</h2>
          <ul>
            <li>
              <img src={puzzle} alt="" />
              <div className="text-block">
                <h3>Unify City-Wide Education Data</h3>
                <p>
                  In each partner city, artlook<sup>®</sup> brings together data
                  from district operations, community agencies, and individual
                  schools into a single platform to track growth across schools
                  and time.
                </p>
              </div>
            </li>
            <li>
              <img src={drivechange} alt="" />
              <div className="text-block">
                <h3>Drive change in schools and neighborhoods</h3>
                <p>
                  artlook's<sup>®</sup> free, public mapping tool is a powerful
                  search engine for principals, teachers, community agencies,
                  funders and even parents. Its insightful matching tool links
                  schools to programs and helps to target resources and connect
                  individuals.
                </p>
              </div>
            </li>
            <li>
              <img src={chart} alt="" />
              <div className="text-block">
                <h3>Inform Decision-Making</h3>
                <p>
                  artlook's<sup>®</sup> data gives school and community leaders
                  new insights that help allocate human and capital resources in
                  the arts with greater equity, even targeting neighborhoods and
                  schools with the greatest needs, and tracking progress over
                  time.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section className="partner-wrapper">
        <div className="partner-block-container">
          <div className="header">
            <h2>artlook is Growing</h2>
            <p>
              Launched in 2013 in Chicago Public Schools—the nation's third
              largest school system—artlook<sup>®</sup> has served as a
              springboard to exponential increases in students' arts access.
              Ingenuity is now working with state and national partners to bring
              artlook<sup>®</sup> to communities nationwide.
            </p>
          </div>
        </div>
      </section>

      <section id="our-communities">
        <div className="partner-map-block-container">
          <div className="row">
            <div className="text-block">
              <h2>Partner Communities</h2>
              <p>
                Over the upcoming months, each community shown will compile data
                from public schools, arts partners, and school districts. Each
                participating community will then launch a public-facing map
                providing their stakeholders with free, unlimited access to
                unified and comprehensive arts education data. The goal is to
                expand arts education access, quality, and equity for every
                child, in every grade, in every school.
              </p>
            </div>
            <img
              src={map}
              alt="Map of the artlook's partner communities within the US"
            />
          </div>
        </div>
      </section>

      <section className="community-links">
        <ul>
          {sortedCommunities.map((community) => (
            <CommunityCard
              key={community.name}
              title={community.name}
              imgSrc={community.defaultBranding.homeLogo.src}
              imgAlt={community.defaultBranding.homeLogo.alt}
              subdomain={community.subdomain}
              schoolPortalEnabled={community.schoolPortalEnabled}
              partnerPortalEnabled={community.partnerPortalEnabled}
              mapPortalEnabled={community.mapEnabled}
            />
          ))}
        </ul>
      </section>

      <section className="gallery">
        <div
          className="image-block"
          style={{ background: `url(${image1}) no-repeat center` }}
        ></div>
        <div
          className="image-block"
          style={{ background: `url(${image2}) no-repeat center` }}
        ></div>
        <div
          className="image-block"
          style={{ background: `url(${image3}) no-repeat center` }}
        ></div>
      </section>

      <section className="contact-wrapper" id="contact">
        <div className="contact-block-container">
          <div className="row">
            <img
              src={computer}
              alt="Computer with image of artlook's search experience"
            />
            <div className="text-block">
              <h2>Get artlook for your community</h2>
              <p>
                Contact us at{' '}
                <ExternalLink href="mailto:info@artlookmap.com">
                  info@artlookmap.com
                </ExternalLink>{' '}
                to learn more.
              </p>
              <br />
              <ExternalLink
                href="mailto:info@artlookmap.com"
                className="button-primary"
              >
                Contact Us
              </ExternalLink>
            </div>
          </div>
        </div>
      </section>

      <footer className="home-footer">
        <FooterLegalese />
      </footer>
    </Content>
  )
}

Home.propTypes = propTypes
Home.defaultProps = defaultProps

export default Home
