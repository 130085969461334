import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Checkbox } from 'lp-components'
import { keys } from 'utils'
import classnames from 'classnames'

const propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.shape({
    initialValues: PropTypes.object,
    instructionLevelType: PropTypes.string.isRequired,
    noneOptionKey: PropTypes.string.isRequired,
  }).isRequired,
}

const defaultProps = {
  initialValues: {},
}

function InstructionLevelCheckboxCell({
  name,
  data: { instructionLevelType, initialValues, noneOptionKey },
}) {
  const initialNoneDisciplines = keys(initialValues[noneOptionKey]) // array of disciplines under `None` level
  // disable cell if level is not `None` and the discipline is included in the array of disciplines under `None`
  const disableCell =
    instructionLevelType !== noneOptionKey &&
    initialNoneDisciplines.includes(name)
  return (
    <td className={classnames({ 'disabled-cell': disableCell })}>
      <Field
        name={`${instructionLevelType}.${name}`}
        label={false}
        component={Checkbox}
        disabled={disableCell}
      />
    </td>
  )
}

InstructionLevelCheckboxCell.propTypes = propTypes

InstructionLevelCheckboxCell.defaultProps = defaultProps

export default InstructionLevelCheckboxCell
