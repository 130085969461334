// Returns surveyQuestions that match the provided survey page and display component values

function filterQuestionsByPageAndDisplayComponent(
  questions,
  surveyPage,
  displayComponent
) {
  if (!questions) return questions

  const filteredQuestions = questions.filter(
    (question) =>
      question.surveyPage === surveyPage &&
      question.displayComponent === displayComponent
  )
  return filteredQuestions.sort((q1, q2) => q1.position - q2.position)
}

export default filterQuestionsByPageAndDisplayComponent
