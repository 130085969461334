import * as LS from 'local-storage'

// Auth helpers for educator portal (using school auth for now)

export function isAuthenticated() {
  return !!LS.getSchoolAuthToken()
}

export function isAdmin() {
  return !!LS.getIsAdmin()
}
