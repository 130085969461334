import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { SubmitButton, ButtonArea } from 'lp-components'
import { DateInput, RichTextInput } from 'components'
import { Field, propTypes as formPropTypes } from 'redux-form'

const propTypes = {
  ...formPropTypes,
  isEditing: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

const defaultProps = {}

function AnnouncementForm({ handleSubmit, submitting, isEditing, onCancel }) {
  const today = new Date()

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field
        name="content"
        label="Message"
        component={RichTextInput}
        className="rich-text"
      />
      <Field
        name="expiresAt"
        label="Expires"
        component={DateInput}
        placeholderText="MM/DD/YYYY"
        minDate={today}
        popperPlacement="top"
        className="label-block"
      />
      <ButtonArea>
        <SubmitButton {...{ submitting }}>
          {isEditing ? 'Save Changes' : 'Create Announcement'}
        </SubmitButton>
        <button type="button" className="button-grey-light" onClick={onCancel}>
          Cancel
        </button>
      </ButtonArea>
    </form>
  )
}

AnnouncementForm.propTypes = propTypes
AnnouncementForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'announcement',
    constraints: {
      content: {
        presence: { message: "^Message can't be blank" },
      },
      expiresAt: {
        presence: { message: '^Expires is required' },
      },
    },
  })
)(AnnouncementForm)
