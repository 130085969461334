import { get, split, first, last } from 'lodash'

// Returns capitalized initials using what is available from userDisplayName (could be email)
// If userDisplayName is empty or undefined, returns an empty string

function getProfileInitials(userDisplayName) {
  if (!userDisplayName) return ''

  const names = split(userDisplayName.trim(), ' ')
  const hasOneName = names.length === 1

  const firstName = first(names)
  const firstInitial = get(firstName, 0, '')
  if (hasOneName) return firstInitial.toUpperCase()

  const lastName = last(names)
  const lastInitial = get(lastName, 0, '')

  const initials = firstInitial + lastInitial
  return initials.toUpperCase()
}

export default getProfileInitials
