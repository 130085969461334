import logo from 'images/communities/SouthwesternPA/logo.png'
import dashboardHeaderImage from 'images/communities/SouthwesternPA/banner.jpg'
import mapHeaderImage from 'images/communities/SouthwesternPA/landing.jpg'
import loginBackgroundImg from 'images/communities/SouthwesternPA/background-image.jpg'
import favicon from 'images/communities/SouthwesternPA/favicon.png'
import homeLogo from 'images/home/community-logos/southwestern-pa.png'
import { SURVEY_QUESTIONS } from 'types'

const config = {
  subdomain: 'swpa',
  name: 'Southwestern PA',
  position: 7,
  partnerSupportEmail: 'artlook@artsedcollab.org',
  schoolSupportEmail: 'artlook@artsedcollab.org',
  stylesheet: 'southwestern-pa.scss',
  organizationUrl: 'https://artsedcollab.org/',
  artsEdColab: 'https://artsedcollab.org/artlook/',
  faqUrl:
    'https://drive.google.com/file/d/1nKg9at4oeyNE2qulcul2vbi3VWVO7O6A/view?usp=sharing',
  mapEnabled: true,
  schoolPortalEnabled: true,
  partnerPortalEnabled: true,
  partnerProgramsEnabled: true,
  educatorEnabled: false,
  notificationsEnabled: true,
  notificationListSize: -1,
  dataDashboardEnabled: true,
  id: 43,
}

// ----- SEARCH -----
config.search = {
  options: {
    blockList: ['networks', 'creativeSchoolsCategory'],
  },
  schoolSearchFilters: [
    'network',
    'creativeSchoolsCategory',
    'instructorsTeaching',
    'schoolType',
    'organizationPartnersCount',
    'programsOffered',
    'disciplineInterests',
    'resourceProgramInterests',
    'coursesOffered',
    'studentDemographics',
    'schoolDistrict',
    'openToPartnerships',
  ],
  map: {
    center: { lat: 40.439175, lng: -79.994713 },
    radius: 50, //in miles
  },
}

// ----- BRANDING -----

config.defaultBranding = {
  className: 'swpa-community',
  favicon,
  mapLogo: {
    src: logo,
    alt: "Southwestern Pennsylvania's homepage",
    link: config.organizationUrl,
  },
  homeLogo: {
    src: homeLogo,
    alt: 'Southwestern Pennsylvania',
  },
  communityLogo: {
    src: logo,
    alt: 'Artlook Schools',
  },
  dashboardHeader: {
    src: dashboardHeaderImage,
    alt: '',
  },
  loginBackgroundImg: {
    src: loginBackgroundImg,
    alt: '',
  },
  mapHero: {
    src: mapHeaderImage,
    alt: '',
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

config.displayText = {
  map: {
    title:
      'artlook Map: Unlocking the Transformative Power of Arts Learning in Southwestern PA',
    searchHeader: 'Find Schools & Partners in Southwestern PA',
    welcomeSearchHeader: 'Explore Arts Education in Southwestern PA',
    welcomeHeader: `What is artlook<sup>®</sup> SWPA?`,
    welcomeMessage: `
      <p>
        artlook<sup>®</sup> SWPA is a free online hub created to help educators in our region immerse young people in powerful, personally relevant arts learning opportunities.
      </p>
      <p>
        Developed by Ingenuity in Chicago and customized as a collaboration among local teachers, school and district administrators, arts partners, and AEC staff, artlook<sup>®</sup> provides an up-to-date picture of the people, places, and programs supporting arts learning in Southwestern Pennsylvania. This publicly available and searchable map is the only one of its kind in Pennsylvania to combine arts education data from schools, arts organizations, and teaching artists.
      </p>
      <p>
        AEC invites additional schools and arts partners to join artlook<sup>®</sup> SWPA each year. Learn more about eligibility and how to get on the map at <a href="${config.artsEdColab}" target="_blank" rel="noopener noreferrer">artsedcollab.org/artlook</a>.
      </p>
    `,
  },
  tagline:
    'Collaborating to unlock the transformative power of arts learning for young people.',
  partnerPortal: {
    dashboard: {
      welcomeMessage: `
        <h3>Let’s put arts education on the map!</h3>
        <p>
          <em>artlook<sup>®</sup> SWPA</em> is the only platform of its kind in Pennsylvania bringing together data from schools, artists, and arts organizations. Schools can search for programs like yours and you can search for potential school partners.
        </p>
        <p>
          <strong>Follow the links in the boxes below to share your organization’s school program and partnership information.</strong>
        </p>
        <p>
          If you have any questions or need assistance, please contact <a href="mailto:${config.partnerSupportEmail}" target="_blank" rel="noopener noreferrer">${config.partnerSupportEmail}</a>.
        </p>
      `,
    },
  },
  schoolPortal: {
    dashboard: {
      welcomeMessage: `
        <h3>Let’s put arts education on the map!</h3>
        <p>
          <em>artlook<sup>®</sup> SWPA</em> is the only platform of its kind in Pennsylvania bringing together data from schools, artists, and arts organizations. You can use <em>artlook<sup>®</sup></em> to promote your school’s arts programs and secure partners and resources.
        </p>
        <p>
          <strong>Follow the link(s) below to complete a survey about your school’s arts education offerings last year and to update its <em>artlook<sup>®</sup></em> profile for the current year.</strong>
        </p>
        <p>
          If you have any questions or need assistance, please contact <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a>.
        </p>
      `,
    },
  },
  survey: {
    getStarted: `<h2>Welcome to the {{ surveySchoolYearNumber }} Creative Schools Survey!</h2>
    <p>Through this annual survey, you will be asked to share information about arts education staffing and instruction at your school, partnerships with teaching artists and arts organizations, and how your school budgets and plans for the arts.</p>
    <p>As you work on the {{ surveySchoolYearNumber }} survey, please be sure to save your responses to each question to avoid losing information. You are not required to complete your survey in one session; so long as your question responses are saved, you can return at any time to edit your survey before submitting.</p>
    <p><strong>Please submit your survey no later than {{ expirationDate }}.</strong></p>
    <p>Please feel free to reach out to <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a> with any questions!</p>
    <div class="callout">
      <p><strong>Benefits of completing the survey:</strong></p>
      <ul class="checkmark-list">
        <li><span>Secure partnerships and resources based on individual school needs</span></li>
        <li><span>Build and sustain strong arts programs with public accountability</span></li>
        <li><span>Increase opportunities for collaboration and communication</span></li>
      </ul>
    </div>
    `,
    courses: {
      coursesNote: `
        <p>
          <strong>Note on courses:</strong> An arts or creative learning class meets the following criteria: it is offered to students during the school day and it either 1) is categorized as an arts class by your school OR 2) offers students significant opportunities for creative choice and self-expression through one or more artistic disciplines — including dance, literary arts, media arts, music, theatre, and visual arts.
        </p>
      `,
      instructionalDepthNote: `
      <p>
        <strong>Note on instructional depth:</strong> Instructional depth refers to the level at which the course is taught in relation to the high school performance standards articulated in the National Core Arts Standards. Please select the level that best describes each course.
      </p>
      <p>
        <ul class="bulleted-list">
          <li>
            <strong>Beginning</strong> (or Proficient) courses are designed for students to learn foundational technical skills and understandings in an art form.
          </li>
          <li>
            <strong>Intermediate</strong> (or Accomplished) courses are designed for students to identify and solve artistic problems based on their own interests and to create and refine arts products, performances, or presentations that demonstrate technical proficiency, personal communication, and expression.
          </li>
          <li>
            <strong>Advanced</strong> courses are designed for students to independently identify challenging artistic problems based on their own interests, bring creativity and insight to finding artistic solutions, and use at least one art form as an effective avenue for personal communication while demonstrating a high level of technical and expressive proficiency.
          </li>
        </ul>
      </p>`,
    },
    staffingQuestion: {
      modalMessage: `
        <p>
        <strong>Note on instructors:</strong> When listing instructors in your school, please include only state-certified educators who teach arts courses during the school day. If itinerant teachers teach at your school, please report those individuals as well.
        </p>
        <p>
        <strong>Note on part/full-time status:</strong> Please select Full Time if a teacher had full-time status at your school for the entire school year. If a teacher was part-time at your school for all or part of the year, or full-time for only part of the year, please select Part Time.
        </p>
        <p>
        <strong>Note on disciplines:</strong> The Primary Focus of each instructor is required. Please also select "Classroom/non-arts teacher" if the instructor is not primarily an arts instructor (e.g., non-arts, general education teacher teaching arts courses).
        </p>
        `,
      displayOptions: {
        showClassNumber: true,
      },
    },
    instructorQuestion: {
      displayOptions: {
        showInstructorEmail: false,
      },
    },
    scholarshipQuestion: {
      displayOptions: {
        showExample: true,
      },
    },
    communityEngagement: {
      question:
        'Please select the ways that families or community members engaged in the arts at your school.',
    },
    percentAccess: {
      question: `What percentage of students took at least one arts or creative learning class in any discipline at your school in {{ surveySchoolYearNumber }}?`,
      description: `Please provide the number of students at your school who completed at least one arts or creative learning class during the school day and your school's total elementary student enrollment.`,
      courseEnrollmentLabel: `Students who took an arts or creative learning course`,
      error: `What percentage of students took at least one arts or creative learning class in any discipline at your school (Page 1)?`,
    },
    obstaclesSelect: {
      question: `For each discipline taught at your school in {{ surveySchoolYearNumber }}, indicate the top three obstacles to virtual/remote arts instruction.`,
    },
  },
  terms: `
      <p>
        <strong>Arts Education Collaborative</strong> is fiscally sponsored by
        the Carnegie Museum of Art which is a museum of Carnegie Institute d/b/a
        Carnegie Museums of Pittsburgh (collectively referred to as, "AEC", "us"
        or "we"). We are providing the information and other content on this
        website ("Site") for the purpose of facilitating and improving the
        quality of, and access to, arts education for all school students
        through an array of interrelated strategies including supporting arts
        education policy and advocacy efforts, developing partnerships with
        schools and school systems, disseminating information, convening the
        fields of arts and education, organizing focused task forces, offering
        professional development for teachers and artists, providing technical
        assistance through research and evaluation tools, and hosting
        recognition programs, and other similar activities in support of AEC's
        mission.
      </p>
      <p>
        By accessing this Site, users of the Site ("User(s)", "you" or "your")
        agree to be bound by these Terms of Use ("Terms"). If you do not agree
        with these Terms, please do not use this Site.
      </p>
      <p>
        By using this Site, you acknowledge that you are 18 years of age or
        older, or are an emancipated minor, or possess parental or guardian
        consent to use this Site.
      </p>

      <h2>Acceptance of Terms and Conditions</h2>

      <p>
        <strong>
          By uploading information, accessing, browsing, and/or otherwise using
          this Site, you acknowledge that you have read, understand, are
          authorized to accept and do accept these Terms, and that you agree to
          be bound by these Terms. You agree to be responsible for the
          activities of all Users who access or use the Site through any account
          that you may have and you agree to ensure that any such User will
          comply with these Terms.
        </strong>
        AEC may change these Terms, so Users are encouraged to visit this page
        from time to time to review the most current Terms. Your continued use
        of the Site after new or revised Terms are effective indicates you have
        read and understand and agree to be bound by those new or revised Terms.
      </p>

      <h2>Copyright and Other Proprietary Rights to Content</h2>

      <p>
        Unless otherwise noted, all of the text, images, trademarks, data, film
        and video files and clips, audio files and clips, archival materials,
        software, databases, designs and other information and materials on the
        Site, including Partner Data as described below (collectively,
        "Content") are protected by copyright laws and may also be protected by
        other restrictions. Copyright and other proprietary rights in the
        Content, including contractual rights, may be held by individuals or
        entities other than, or in addition to, AEC.
      </p>

      <p>
        Users may search the Content, review search results, download search
        results and use the information to improve arts learning opportunities
        in Southwestern Pennsylvania. In addition, Users that have established a
        Partner Account as described below may submit and/or upload certain
        types of Content for use on or in connection with the Site.
      </p>

      <h2>Restrictions</h2>

      <p>
        Your use of the Content on this Site is subject to the following
        requirements unless otherwise noted for specific Content: (i) you may
        not remove any copyright, trademark or other proprietary notices,
        including attribution information, credits, and notices, that are placed
        in or near text, images or data; and (ii) you must comply with all terms
        or restrictions, in addition to copyright terms or restrictions,
        including trademark, publicity and privacy rights, or contractual
        restrictions, as may be specified in the metadata or may otherwise apply
        to the Content.
      </p>

      <p>
        You shall not (and shall not grant permission to any third party),
        without the prior written consent of AEC (i) alter, modify or tamper
        with the Site (including any proprietary notices contained therein);
        (ii) attempt to reverse engineer or decompile the Site or any portion
        thereof, or otherwise attempt to derive or modify any information or
        processes embodied or used in, the Site or any portion thereof; or (iii)
        modify, make derivative works of, rent, lease, lend, sell, sublicense,
        assign, distribute, publish, transfer or otherwise make available the
        Site, or any portion thereof, to any third party, including on or in
        connection with the Internet or any time-sharing, service bureau,
        software as a service, cloud or other technology or service.
      </p>

      <p>
        Unless otherwise noted, downloading, copying, transmitting,
        distributing, creating derivative works, modifying, publicly displaying,
        publishing, selling, promoting, or advertising products or services or
        otherwise using the Content on this Site for commercial purposes or for
        any purpose other than as described in these Terms is expressly
        prohibited. If you wish to use the Content for any purpose other than
        the permitted uses discussed in these Terms, you must obtain prior
        written permission from AEC, or other owner of the Content as
        applicable.
      </p>

      <h2>Trademarks</h2>

      <p>
        Many of the names, titles, trademarks, service marks, and logos
        (collectively, the "Trademarks") displayed on the Site, including
        Carnegie Museum of Art, are registered or unregistered marks of AEC
        and/or Carnegie Institute d/b/a Carnegie Museums of Pittsburgh. The
        trademarks of third-parties may also be displayed on the Site. You may
        not use any Trademarks displayed on the Site without the express written
        permission of AEC or the third party that owns the Trademark and you
        acknowledge and agree that nothing on this Site should be construed as
        granting, by implication, estoppel, or otherwise, any right or license
        to use any Trademarks displayed on the Site. Any unauthorized use of the
        Trademarks, except as authorized in these Terms, is expressly
        prohibited.
      </p>

      <h2>Partner Accounts</h2>

      <p>
        Schools, arts organizations, teaching artists and any other Users
        submitting arts education data and other information for use on or in
        connection with the Site (sometimes referred to herein collectively as
        "Partners" and each a "Partner") are required to establish individual
        password protected accounts (collectively "Partner Accounts" and each a
        "Partner Account") to access the portal through which they may submit
        their Partner Data as described below.
      </p>

      <p>
        As a Partner, you are responsible for protecting the login and password
        for your Partner Account from unauthorized use and for all activity that
        occurs on your account. You are prohibited from using another entity's
        or person's account without permission. You agree to notify us
        immediately if you believe your login or password has been used without
        your permission. We are not responsible for losses or damages caused by
        your failure to safeguard your login or password.
      </p>

      <h2>Partner Data</h2>

      <p>
        (a) <strong>General</strong>. Partners are invited to submit data and
        information of various kinds for use on or in connection with the Site,
        such as data and information about
        <strong>
          arts teaching staff, arts disciplines offered, and program types
        </strong>
        (collectively, "Partner Data"). AEC reserves the right, but shall not
        have any obligation, to delete any Partner Data or other Content from
        the Site, at our sole discretion, that we deem inappropriate. The
        posting of Partner Data on this Site does not imply any endorsement of
        (including, without limitation, any opinion on) that Partner by AEC.
      </p>

      <p>The following types of Partner Data are strictly prohibited:</p>
      <ul>
        <li>
          Partner Data that infringes on the rights of a third party such as
          intellectual property rights, publicity rights, or privacy rights;
        </li>

        <li>
          Partner Data that is threatening, defamatory, obscene, abusive, false,
          or misleading or harmful to minors;
        </li>

        <li>
          Partner Data that contains viruses, corrupted files, or other
          materials that may cause damages to another’s computer;
        </li>

        <li>
          Partner Data that violates these Terms or the terms of use of any
          sites owned or operated by others that relate to the use and/or
          operation of this Site;
        </li>

        <li>
          Partner Data that contains advertisements, spam, or other forms of
          solicitation; and/or
        </li>

        <li>Partner Data that could violate any law or regulation.</li>
      </ul>

      <p>
        You are solely responsible for the Partner Data you submit. We make no
        representations that your Partner Data will remain available on the Site
        or otherwise. We do not guarantee any confidentiality with respect to
        your Partner Data. You understand that other Partner Data provided by
        other Partners will be appearing on the Site, that we are under no
        obligation to monitor such Partner Data and assume no responsibility or
        liability arising from such Partner Data, including, but not limited to,
        any defamation, libel, slander, omission, falsehood, obscenity,
        profanity or inaccuracy contained in such Partner Data. While we have
        the right to monitor Partner Data, we assume no responsibility or
        liability for and have no obligation to remove or modify any Partner
        Data.
      </p>

      <p>
        (b) <strong>Grant of Rights</strong>. By submitting Partner Data, you
        hereby grant AEC, a worldwide, non-exclusive, fully paid-up, royalty
        free, perpetual, irrevocable, transferable license with the right to
        grant sublicenses to use, reproduce, distribute, modify (including
        excerpting, editing and/or re-formatting before or after posting),
        prepare derivative works, adapt, display, perform, and otherwise exploit
        your Partner Data in connection with the Site and with AEC's mission.
        This grant includes, without limitation, redistributing some or all of
        the Partner Data in any media and through any media channels now known
        or hereafter discovered. You irrevocably waive any claims and assertions
        of moral rights or attribution with respect to your Partner Data. You
        also grant each User of the Site the non-exclusive license to access
        your Partner Data through the Site and to use, reproduce, distribute,
        modify, prepare derivative works of, adapt, display and perform such
        Partner Data.
      </p>

      <p>
        (c)
        <strong>
          Your Representations and Warranties Regarding Partner Data. You
          understand that your Partner Data will be made available to the
          public.
        </strong>
        You understand and agree that you are solely responsible for your
        Partner Data and the consequences that may arise due to the posting of
        such Partner Data. You affirm, represent and warrant that you have the
        necessary licenses, rights, consents, and permissions to use the Partner
        Data and to authorize AEC to post and otherwise use, and to permit other
        Users, including the public, to use, the Partner Data as set forth in
        these Terms.
      </p>

      <h2>Links to Third-Party Sites</h2>

      <p>
        This Site may provide links to sites that are owned or operated by third
        parties ("Third-Party Sites"). We have no control over such Third-Party
        Sites and cannot guarantee, represent, or warrant that the content on
        those sites is accurate and legal and do not contain viruses or other
        features that may adversely affect your computer. A link from our Site
        to a Third-Party Site does not necessarily imply a relationship with, or
        endorsement of, that site. These Third-Party Sites are governed by their
        own Terms of Use and we encourage you to read those terms if you choose
        to link to and use these sites.
      </p>

      <h2>Digital Millennium Copyright Act</h2>

      <p>
        It is the policy of AEC to respond to notices of copyright infringement
        that comply with the requirements of the Digital Millennium Copyright
        Act (DMCA). Upon receipt of a notice that complies with the DMCA, AEC
        will expeditiously remove or disable access to the content that is the
        subject of the notice. AEC may also, in appropriate circumstances and in
        its discretion, take reasonable steps to disable and/or terminate access
        of Users of this Site who may be repeat infringers of copyright. In
        accordance with the DMCA, if you believe that any Content on this Site
        infringes copyrights that you own, please provide the following
        information to AEC's designated agent set forth below:
      </p>
      <ul>
        <li>
          A physical or electronic signature of the copyright owner or the
          person authorized to act on its behalf;
        </li>

        <li>
          A description of the copyrighted work you claim has been infringed;
        </li>

        <li>
          A description of the infringing material and information reasonably
          sufficient to permit AEC to locate the material;
        </li>

        <li>
          Your contact information, including your address, telephone number,
          and email address; and
        </li>

        <li>
          A statement by you, made under penalty of perjury, that the
          information you have supplied in your notice is accurate, that you
          have a good faith belief that use of the material in the manner
          complained of is not authorized by the copyright owner, its agent, or
          the law and, where applicable, that you are authorized to act on
          behalf of the copyright owner.
        </li>
      </ul>
      <p>
        AEC’s designated agent for receipt of notification of any claimed
        infringement is:
      </p>

      <p>
        <strong>By Email:</strong>
        <a
          href="mailto:dmca-contact@carnegiemuseums.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          dmca-contact@carnegiemuseums.org
        </a>
      </p>

      <p>
        <strong>By Mail:</strong> Office of the General Counsel Carnegie Museums
        of Pittsburgh 4400 Forbes Avenue Pittsburgh, PA 15213
      </p>

      <h2>Disclaimer of Warranties and Limitation of Liability</h2>

      <p>
        AEC disclaims all warranties of any kind, whether express or implied,
        including but not limited to, the implied warranties of merchantability,
        fitness for a particular purpose, copyright ownership and
        non-infringement of copyrights or other third party proprietary rights,
        arising from your use of the Site, the Partner Data or any other
        Content. We do not warrant that the Site will provide continuous,
        prompt, secure, or error-free service and we assume no liability for any
        errors or omissions, including the inaccuracy of any Partner Data or
        other Content or for any damages or losses that you or any third party
        may incur as a result of the unavailability of the Site or any portion
        thereof. AEC assumes no responsibility, and shall not be liable for, any
        damages to, or viruses that may affect your computer equipment or other
        property arising from your use of the Site, the Partner Data or other
        Content. AEC, Carnegie Institute d/b/a Carnegie Museums of Pittsburgh,
        and its affiliates, trustees, directors, officers, employees, and/or
        agents shall have no liability for any damages, including without
        limitation, any direct, indirect, incidental, compensatory, punitive,
        special, or consequential damages, even if advised of the possibility of
        such damages, arising from or related to use of this Site, the Partner
        Data or other Content.
      </p>

      <h2>Termination, Modifications and Content Removal</h2>

      <p>
        We may, at any time: (i) modify, suspend, or terminate the operation of
        or access to the Site or any portion thereof for any reason; (ii) modify
        or change the Site for any reason; (iii) remove or change any Partner
        Data or other Content or on this Site at any time in our sole
        discretion; or (iv) interrupt the operation of the Site for any reason.
        Your access to, and use of the Site, may be terminated by you or by us
        at any time for any reason. Any provision of these Terms which, by its
        nature should survive, will survive termination of these Terms,
        including, but not limited to, all obligations relating to your use of
        the Content and your license to us to use the Partner Data.
      </p>

      <h2>Indemnification</h2>

      <p>
        You agree to indemnify, defend, and hold harmless AEC, Carnegie
        Institute d/b/a Carnegie Museums of Pittsburgh and its affiliates,
        trustees, directors, officers, employees, and agents from and against
        all claims, causes of action, expenses, reasonable attorneys' fees,
        losses, and damages, arising from or relating to your use of the Site or
        any Partner Data or other Content on the Site.
      </p>

      <h2>Jurisdiction, Venue and Choice of Law</h2>

      <p>
        These Terms shall be governed and construed in accordance with the laws
        of the Commonwealth of Pennsylvania, without regard to conflict of law
        principles. Any case, controversy, suit, action, or proceeding arising
        out of, in connection with, or related to these Terms, may only be
        brought in a federal or state court located in Allegheny County,
        Pennsylvania and User hereby waives any objection that User may have to
        personal jurisdiction or venue in these courts.
      </p>

      <h2>Severability and Waiver</h2>

      <p>
        If any provision of these Terms is held to be invalid, void, or
        unenforceable, the remaining provisions shall nevertheless continue in
        full force and effect. AEC's failure to exercise or enforce any rights
        or provision of these Terms shall not constitute a waiver of such right
        or provision.
      </p>

      <h2>Entire Agreement</h2>

      <p>
        These Terms are the sole and entire agreement between AEC and you with
        respect to the subject matter hereof and replace all prior or
        contemporaneous understandings or agreements regarding such subject
        matter.
      </p>

      <h2>Reservation of Rights</h2>

      <p>
        AEC reserves the right to change these Terms, at any time and for any
        reason at its sole discretion. Such changes may be made without notice
        and are effective immediately upon posting, and continued use of the
        Site constitutes acceptance of such changes. Users of the Site should
        visit this page periodically to review the most current Terms.
      </p>
  `,
}

// ----- SURVEY QUESTIONS CONFIG -----

const {
  APPROACHES,
  GOVERNANCE,
  OBSTACLES_SELECT,
  OBSTACLES_DESCRIBE,
  OBSTACLES_OVERCOME,
  DISTRICT_SPENDING,
  COMMUNITY_ENGAGEMENTS,
} = SURVEY_QUESTIONS

config.surveyQuestions = {
  approaches: APPROACHES.OPTIONS.PER_DISCIPLINE,
  governance: GOVERNANCE.OPTIONS.GENERAL,
  obstaclesSelect: OBSTACLES_SELECT.OPTIONS.GENERAL,
  obstaclesDescribe: OBSTACLES_DESCRIBE.OPTIONS.GENERAL,
  obstaclesOvercome: OBSTACLES_OVERCOME.OPTIONS.GENERAL,
  districtSpending: DISTRICT_SPENDING.OPTIONS.GENERAL,
  communityEngagement: COMMUNITY_ENGAGEMENTS.OPTIONS.GENERAL,
}

export default config
