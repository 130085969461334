import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node,
}

function LoadingContainer({ isLoading, children }) {
  return <div style={{ opacity: isLoading ? 0.5 : 1 }}>{children}</div>
}

LoadingContainer.propTypes = propTypes

export default LoadingContainer
