import React from 'react'
import {
  Input,
  generateInputErrorId,
  fieldPropTypes,
  hasInputError,
} from 'lp-components'
import { filterInvalidDOMProps } from 'lp-components/lib/utils'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const propTypes = {
  onIconClick: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  icon: PropTypes.elementType.isRequired,
  iconTitle: PropTypes.string,
  iconAriaLabel: PropTypes.string,
  ...fieldPropTypes,
}

const defaultProps = {
  className: '',
  iconTitle: '',
  iconAriaLabel: '',
}

function InteractiveIconInputWithWarnings({
  className,
  onIconClick,
  meta,
  icon: Icon,
  name,
  iconTitle,
  iconAriaLabel,
  ...rest
}) {
  return (
    <>
      <Input
        className={classnames(
          'interactive-icon-input-with-warnings',
          className
        )}
        meta={meta}
        aria-describedby={
          hasInputError(meta) ? generateInputErrorId(name) : null
        }
        {...rest}
      >
        <button
          title={iconTitle}
          aria-label={iconAriaLabel}
          type="button"
          onClick={onIconClick}
        >
          <span aria-hidden="true">
            <Icon className="interactive-icon" />
          </span>
        </button>
      </Input>
      {!meta.error && meta.warning && (
        <span
          id={generateInputErrorId(name)}
          className={classnames('warning-message', className)}
          {...filterInvalidDOMProps(rest)}
        >
          {meta.warning}
        </span>
      )}
    </>
  )
}

InteractiveIconInputWithWarnings.propTypes = propTypes
InteractiveIconInputWithWarnings.defaultProps = defaultProps

export default InteractiveIconInputWithWarnings
